import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { ReactComponent as Arrow } from 'Assets/icons/arrowRight.svg';
import { ReactComponent as PlusIcon } from 'Assets/icons/plus.svg';

import * as Styled from './styled';

export const Tile = ({ title, links, hrefs, icon, onClick, isNew, cyData }) => (
    <Styled.Wrapper>
        <Styled.Top>
            <Styled.Content>
                {onClick && (
                        <Styled.ButtonIcon onClick={onClick} data-cy={cyData}>
                            {icon}
                        </Styled.ButtonIcon>
                )}
                {links && !onClick && (
                    <Link
                        data-cy={cyData}
                        to={
                            links && typeof links[0] === 'string'
                                ? links[0]
                                : links[0].route
                        }
                    >
                        <Styled.DivIcon>
                            {icon}
                        </Styled.DivIcon>
                    </Link>
                )}
                {hrefs && !onClick && (
                    <a
                        data-cy={cyData}
                        href={hrefs[0]}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Styled.DivIcon>
                            {icon}
                        </Styled.DivIcon>
                    </a>
                )}
                {isNew && (
                    <Styled.NewButton>
                        <PlusIcon />
                    </Styled.NewButton>
                )}
            </Styled.Content>
        </Styled.Top>
        {links && typeof links[0] === 'string' && !onClick && (
            <Styled.RouterLink to={links[0]}>
                <Arrow />
                <Styled.Title>
                    {title}
                </Styled.Title>
            </Styled.RouterLink>
        )}
        {links &&
            typeof links[0] === 'object' &&
            !onClick && 
            links.map((link) => (
                <Styled.RouterLink
                    to={link.route}
                    key={link.title}
                    data-cy={link.cyData}
                >
                    <Arrow />
                    <Styled.Title>
                        {link.title}
                    </Styled.Title>
                </Styled.RouterLink>
            ))}
        {links && onClick && (
            <Styled.RouterLink to={links[0]} onClick={onClick}>
                <Arrow />
                <Styled.Title>
                    {title}
                </Styled.Title>
            </Styled.RouterLink>
        )}
        {hrefs && (
            <Styled.ExternalLink
                href={hrefs[0]}
                target="_blank"
                rel="noopener noreferrer"
            >
                <Arrow />
                <Styled.Title>
                    {title}
                </Styled.Title>
            </Styled.ExternalLink>
        )}
    </Styled.Wrapper>
);

Tile.defaultProps = {
    links: null,
    hrefs: null,
    onClick: null,
    isNew: false,
    cyData: '',
};

Tile.propTypes = {
    onClick: PropTypes.func,
    title: PropTypes.string.isRequired,
    links: PropTypes.array,
    hrefs: PropTypes.array,
    icon: PropTypes.element.isRequired,
    isNew: PropTypes.bool,
    cyData: PropTypes.string,
};
