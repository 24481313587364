import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import PropTypes from 'prop-types';

import { LASTDAY_DATAUSAGE_QUERY } from 'Constants';
import { formatBytesShort } from 'Utils';
import { useMessageSubscription } from 'Hooks';

import * as Styled from './styled';
import * as StyledGeneral from '../styled';

export const LineChartDataReceived = ({ renderEmptyWidget, label, deviceToken, pin, timePeriod }) => {
    const defaultTimePeriod = 1;
    const timePeriodHours = timePeriod / 3600000;

    const searchingInterval = timePeriodHours || defaultTimePeriod;
    const groupBy = searchingInterval > 1 ? 'HOURS' : 'MINUTES';

    const { data: queryData, loading, error, refetch } = useQuery(LASTDAY_DATAUSAGE_QUERY, {
        variables: {
            deviceToken,
            interval: { hours: searchingInterval, groupBy },
            pin,
        },
    });

    useMessageSubscription([deviceToken], pin, refetch);

    const { getDataUsageByInterval } = queryData || {};
    const { Data, Labels } = getDataUsageByInterval || { Data: [], Labels: [] };

    const lineData = useMemo(
        () => Data.map((dataItem, index) => {
            const t = new Date(Labels[index]);
            const y = dataItem;
            return { t, y };
        }),
        [Data, Labels],
    );
    const hasData = useMemo(
        () => Data.filter((dataItem) => dataItem === 0).length !== Data.length,
        [Data],
    );

    if (loading || error || !hasData) {
        return renderEmptyWidget();
    }

    return (
        <Styled.Chart>
            <StyledGeneral.ChartTitle>
                {label}
            </StyledGeneral.ChartTitle>
            <Line
                plugins={[ChartDataLabels]}
                data={{
                    datasets: [{
                        label,
                        fill: true,
                        lineTension: 0,
                        backgroundColor: 'rgba(5,140,199,0.2)',
                        borderColor: 'rgba(5,140,199,1)',
                        borderCapStyle: 'butt',
                        borderJoinStyle: 'mitter',
                        pointBorderColor: 'rgba(5,140,199,1)',
                        pointBackgroundColor: 'rgba(5,140,199,1)',
                        pointBorderWidth: 1,
                        pointHoverRadius: 4,
                        pointHoverBackgroundColor: 'rgba(255,255,255,1)',
                        pointHoverBorderColor: 'rgba(5,140,199,1)',
                        pointHoverBorderWidth: 1,
                        pointRadius: 0,
                        borderWidth: 1,
                        pointHitRadius: 10,
                        data: lineData,
                    }],
                }}
                options={{
                    plugins: {
                        datalabels: {
                            display: false,
                        },
                    },
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            left: 12,
                            right: 12,
                            top: 16,
                            bottom: 5,
                        },
                    },
                    animation: {
                        duration: 400,
                    },
                    legend: {
                        display: false,
                    },
                    tooltips: {
                        displayColors: false,
                        callbacks: {
                            label: (tooltipItem, chartData) => {
                                const initialLabel = chartData.datasets[tooltipItem.datasetIndex].label || '';
                                const resultLabel = `${initialLabel}${initialLabel ? ': ' : ''}${formatBytesShort(tooltipItem.yLabel)}`;

                                return resultLabel;
                            },
                        },
                    },
                    scales: {
                        spanGaps: true,
                        yAxes: [{
                            gridLines: {
                                color: 'rgba(160,160,160,0.25)',
                                lineWidth: 1,
                                drawOnChartArea: true,
                                tickMarkLength: 8,
                            },
                            ticks: {
                                padding: 6,
                                autoSkip: true,
                                maxTicksLimit: 3,
                                maxRotation: 0,
                                callback: (value) => formatBytesShort(value),
                            },
                        }],
                        xAxes: [{
                            type: 'time',
                            distribution: 'linear',
                            gridLines: {
                                color: 'rgba(160,160,160,0.25)',
                                lineWidth: 1,
                                display: true,
                                tickMarkLength: 4,
                                drawOnChartArea: false,
                            },
                            ticks: {
                                fontSize: 11,
                                padding: 2,
                                autoSkip: true,
                                maxTicksLimit: 10,
                                maxRotation: 0,
                            },
                            time: {
                                minUnit: 'minute',
                                tooltipFormat: 'D MMM H:mm A',
                            },
                        }],
                    },
                }}
            />
        </Styled.Chart>
    );
};

LineChartDataReceived.defaultProps = {
    pin: null,
    timePeriod: null,
};

LineChartDataReceived.propTypes = {
    label: PropTypes.string.isRequired,
    renderEmptyWidget: PropTypes.func.isRequired,
    deviceToken: PropTypes.string.isRequired,
    timePeriod: PropTypes.number,
    pin: PropTypes.number,
};
