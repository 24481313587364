import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { useMutation } from '@apollo/client';

import { Button } from 'Components';
import { CHECK_DEV_KIT_STATUS, GET_DEVICE_BY_TOKEN_QUERY } from 'Constants';
import { showToastSuccess, showToastError } from 'Utils';

import * as Styled from './styled';

export const SigfoxDevkit = ({ device }) => {
    const [checkSigfoxStatus, { loading }] = useMutation(
        CHECK_DEV_KIT_STATUS,
        {
            refetchQueries: [GET_DEVICE_BY_TOKEN_QUERY],
        },
    );

    const registrationDotData = useMemo(
        () => {
            let status = null;
            let text = '';

            switch (device.sigfoxDevKit.registrationStatus) {
                case 'registered':
                    status = 'success';
                    text = 'Sigfox device was found on Sigfox backend';
                    break;
                case 'notRegistered':
                    status = 'error';
                    text = 'Sigfox device was not found on Sigfox backend';
                    break;
                default:
                    status = null;
                    text = 'Unknown Sigfox registration status';
                    break;
            }

            return { status, text };
        },
        [device.sigfoxDevKit.registrationStatus],
    );

    const callbackDotData = useMemo(
        () => {
            let status = null;
            let text = 'Callback not created on Sigfox backend';

            if (device.sigfoxDevKit.callbackStatus) {
                status = 'success';
                text = 'Callback was created on Sigfox backend for this Device Type';
            }

            return { status, text };
        },
        [device.sigfoxDevKit.callbackStatus],
    );

    const onClick = () => {
        try {
            checkSigfoxStatus({
                variables: {
                    deviceToken: device.token,
                    sigfoxId: device.sigfoxInfo.id,
                },
                onCompleted: () => {
                    showToastSuccess('Sigfox status updated successfully');
                },
            });
        } catch (error) {
            showToastError(error.message);
        }
    };

    const isButtonDisabled = device.sigfoxInfo.id === 'Unknown';

    return (
        <>
            <Styled.Title>
                Sigfox Registration
            </Styled.Title>
            <Styled.SubTitle>
                Device added as Sigfox DevKit
            </Styled.SubTitle>
            <Styled.DotWrapper>
                <Styled.Dot $status={registrationDotData.status} />
                <Styled.DotLabel>
                    {registrationDotData.text}
                </Styled.DotLabel>
            </Styled.DotWrapper>
            <Styled.DotWrapper>
                <Styled.Dot $status={callbackDotData.status} />
                <Styled.DotLabel>
                    {callbackDotData.text}
                </Styled.DotLabel>
            </Styled.DotWrapper>
            <Styled.ButtonsWrapper>
                <Tooltip
                    title={isButtonDisabled ? 'Update your device with FW updater and reload this page.' : ''}
                >
                    <span>
                        <Button
                            disabled={isButtonDisabled}
                            onClick={onClick}
                            loading={loading}
                        >
                            Check Sigfox status
                        </Button>
                    </span>
                </Tooltip>
                <Styled.DocumentationLink
                    href={process.env.REACT_APP_LINK_DOCS_SIGFOX}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Go to sigfox documentation
                </Styled.DocumentationLink>
            </Styled.ButtonsWrapper>
        </>
    );
};

SigfoxDevkit.propTypes = {
    device: PropTypes.object.isRequired,
};
