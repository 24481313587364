import styled from 'styled-components';

import { IconButton } from 'Components';

export const Wrapper = styled.div`
    box-shadow: 0 2px 5px 0 rgba(50, 50, 50, 0.2);
    padding: 20px;
    margin-top: 20px;
`;

export const Title = styled.div`
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
`;

export const Description = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-size: 16px;
    margin-bottom: 15px;
`;

export const Button = styled(IconButton)`
    margin-bottom: 10px;
`;
