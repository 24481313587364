import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import isEqual from 'lodash/isEqual';

import { removeEmptyProperties } from 'Utils';
import {
    GET_WIFIS,
    GET_LTE_SETTINGS,
    GET_LORA_SETTINGS,
    GET_ETHERNETS,
    GET_SIGFOX_CREDENTIALS,
    WIFI,
    LTE,
    LORA,
    ETHERNET,
} from 'Constants';

import { Button } from '../buttons';
import { Loader } from '../loader';
import { DraggableSort } from './draggableSort';
import { initializeNetworks } from './helpers';
import * as Styled from './styled';

export const NetworksForm = ({
    onSubmit,
    onBack,
    initialValues,
    descriptionRender,
    backText,
    submitText,
    submitButtonColor,
    hideBackButton,
    allowedNetworks,
    initialValid,
    disableLora,
    isLoading,
}) => {
    const { data: wifisData, loading: wifiLoading } = useQuery(GET_WIFIS);
    const { data: loraData, loading: loraLoading } = useQuery(GET_LORA_SETTINGS);
    const { data: lteData, loading: lteLoading } = useQuery(GET_LTE_SETTINGS);
    const { data: ethernetData, loading: ethernetLoading } = useQuery(GET_ETHERNETS);
    const { data: sigfoxData, loading: sigfoxLoading } = useQuery(GET_SIGFOX_CREDENTIALS);

    const wifiList = wifisData?.getSettings.wifiSaved ?? [];
    const loraSettings = loraData?.getLoRaSettings;
    const lteList = lteData?.getLteSettings ?? [];
    const ethernetList = ethernetData?.getAllEthernet ?? [];
    const sigfoxSettings = sigfoxData?.getSigfoxIntegration;

    const [networks, setNetworks] = useState(initializeNetworks(initialValues.networks, allowedNetworks));

    const [formValues, setFormValues] = useState({});

    const isNetworkSelected = useMemo(
        () => networks.some((network) => network.checked),
        [networks],
    );

    const isFormValid = useMemo(() => {
        if (!isNetworkSelected) {
            return false;
        }

        if (initialValid) {
            return true;
        }

        const selectedNetworks = networks
            .filter((network) => network.checked)
            .map((network) => network.key);

        return !isEqual(
            removeEmptyProperties(initialValues),
            removeEmptyProperties({ networks: selectedNetworks, ...formValues }),
        );
    }, [isNetworkSelected, initialValid, networks, formValues]);

    const onSubmitLocal = () => {
        const selectedNetworks = networks
            .filter((network) => network.checked)
            .map((network) => network.key);

        const filledFormValues = {};

        if (selectedNetworks.includes(WIFI)) {
            filledFormValues.wifiId = formValues.wifiId;
        }

        if (selectedNetworks.includes(LTE)) {
            filledFormValues.lteId = formValues.lteId;
        }

        if (selectedNetworks.includes(LORA)) {
            filledFormValues.loraServer = formValues.loraServer;
            filledFormValues.loraType = formValues.loraType;
            filledFormValues.loraRegion = formValues.loraRegion;
        }

        if (selectedNetworks.includes(ETHERNET)) {
            filledFormValues.ethernet = formValues.ethernet;
        }

        onSubmit({
            networks: selectedNetworks,
            ...filledFormValues,
        });
    };

    if (wifiLoading || loraLoading || lteLoading || ethernetLoading || sigfoxLoading) {
        return <Loader />;
    }

    return (
        <>
            <DraggableSort
                initialValues={initialValues}
                networks={networks}
                setNetworks={setNetworks}
                formValues={formValues}
                setFormValues={setFormValues}
                wifiList={wifiList}
                loraSettings={loraSettings}
                sigfoxSettings={sigfoxSettings}
                lteList={lteList}
                ethernetList={ethernetList}
                disableLora={disableLora}
            />
            {descriptionRender}
            <Styled.ButtonsWrapper>
                {!hideBackButton && (
                    <Button
                        buttonType="transparent"
                        onClick={onBack}
                    >
                        {backText}
                    </Button>
                )}
                <Button
                    buttonType={submitButtonColor}
                    onClick={onSubmitLocal}
                    loading={isLoading}
                    disabled={!isFormValid}
                    cyData="networks-form-submit"
                >
                    {submitText}
                </Button>
            </Styled.ButtonsWrapper>
        </>
    );
};

NetworksForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onBack: PropTypes.func,
    initialValues: PropTypes.shape({
        networks: PropTypes.arrayOf(PropTypes.string),
        wifiId: PropTypes.string,
        lteId: PropTypes.string,
        loraServer: PropTypes.string,
        loraType: PropTypes.string,
        loraRegion: PropTypes.string,
    }),
    descriptionRender: PropTypes.node,
    backText: PropTypes.string,
    submitText: PropTypes.string,
    submitButtonColor: PropTypes.string,
    hideBackButton: PropTypes.bool,
    allowedNetworks: PropTypes.arrayOf(PropTypes.string),
    initialValid: PropTypes.bool,
    disableLora: PropTypes.bool,
    isLoading: PropTypes.bool,
};

NetworksForm.defaultProps = {
    initialValues: {
        networks: [],
        wifiId: '',
        lteId: '',
        loraServer: '',
        loraType: '',
        loraRegion: '',
    },
    onBack: () => { },
    descriptionRender: null,
    backText: 'Back',
    submitText: 'Submit',
    submitButtonColor: 'filled',
    hideBackButton: false,
    allowedNetworks: [],
    initialValid: true,
    disableLora: false,
    isLoading: false,
};
