import { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';

import * as Styled from './styled';

function createBreadcrumbs(path, url, labels, entityName, entityNames) {
    const pathArray = path.split('/').filter((item) => !!item);
    const urlArray = url.split('/').filter((item) => !!item);
    const localEntityNames = [...entityNames];

    return (
        <Styled.Wrapper>
            {pathArray.map((pathItem, index) => {
                let label = labels[pathItem];

                if (pathItem.startsWith(':')) {
                    if (localEntityNames.length) {
                        label = localEntityNames.shift();
                    } else {
                        label = entityName;
                    }
                }

                const targetLink = `/${urlArray.slice(0, index + 1).join('/')}`;

                if (!label) {
                    return null;
                }

                if (index >= pathArray.length - 1) {
                    return (
                        <Styled.InactiveBreadcrumb key={pathItem}>
                            {label}
                        </Styled.InactiveBreadcrumb>
                    );
                }

                return (
                    <Fragment key={pathItem}>
                        <Styled.ActiveBreadcrumb to={targetLink}>
                            {label}
                        </Styled.ActiveBreadcrumb>
                        <div>/</div>
                    </Fragment>
                );
            })}
        </Styled.Wrapper>
    );
}

export const Breadcrumbs = ({ labels, entityName, entityNames, className }) => {
    const { path, url } = useRouteMatch();
    const breadcrumbs = useMemo(
        () => createBreadcrumbs(path, url, labels, entityName, entityNames),
        [path, entityName, entityNames],
    );

    return <div className={className}>{breadcrumbs}</div>;
};

Breadcrumbs.propTypes = {
    labels: PropTypes.objectOf(PropTypes.string).isRequired,
    entityName: PropTypes.string,
    entityNames: PropTypes.arrayOf(PropTypes.string),
    className: PropTypes.string,
};

Breadcrumbs.defaultProps = {
    entityName: '',
    entityNames: [],
    className: '',
};
