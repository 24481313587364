import { Field, Form } from 'formik';
import PropTypes from 'prop-types';

import { Checkbox, Select, TextInput, Button } from 'Components';

import * as Styled from '../../../../styled';

import { filterTypeOptions } from './config';
import { LabelWithTooltip } from './labelWithTooltip';

export const FormContent = ({ values, loading, isValid }) => {
    const standardizationTitle = (
        <LabelWithTooltip
            label="Standardization"
            tooltipText="Standardization is rescaling data to have a mean of 0 and a standard deviation of 1"
        />
    );

    const filterTitle = (
        <LabelWithTooltip
            label="Filter"
            tooltipText={`Filters can be low-pass and high-pass. A low-pass filter lets the signals with a frequency lower
                than the cutoff frequency pass and attenuates signals with a frequency greater than the cutoff frequency.
                A high-pass filter lets the signals with a frequency greater than the cutoff frequency pass
                and attenuates signals with a frequency lower than the cutoff frequency`}
        />
    );

    const orderTitle = (
        <LabelWithTooltip
            label="Order"
            tooltipText="The maximum amount of delay used to analyze the data"
        />
    );

    const rmsTitle = (
        <LabelWithTooltip
            label="Enable RMS"
            tooltipText="Root mean square. Is the square root of the arithmetic mean of the squares of the values"
        />
    );

    const fftTitle = (
        <LabelWithTooltip
            label="FFT"
            tooltipText="Fast Fourier transform. FFT applied to a sequence is the discrete Fourier transform of that sequence"
        />
    );

    const fftPeaksUseTitle = (
        <LabelWithTooltip
            label="Use FFT Peaks"
            tooltipText="The number of peaks from FFT values, to be used as features"
        />
    );

    const spectralPowerTitle = (
        <LabelWithTooltip
            label="Spectral Power"
            tooltipText="Mean of the squares of the FFT values, on a frequencies interval"
        />
    );

    const intervalTitle = (
        <LabelWithTooltip
            label="Split on Intervals"
            tooltipText="Compute Spectral Power on each interval, and use the computed values as features"
        />
    );

    return (
        <Form>
            <Styled.ItemInput>
                <Field
                    name="standardization"
                    component={Checkbox}
                    title={standardizationTitle}
                />
            </Styled.ItemInput>
            <Styled.SubInputs>
                <Styled.ItemInput>
                    <Field
                        name="mean"
                        component={Checkbox}
                        title="mean"
                        disabled={!values.standardization}
                    />
                </Styled.ItemInput>
                <Styled.ItemInput>
                    <Field
                        name="variance"
                        component={Checkbox}
                        title="variance"
                        disabled={!values.standardization}
                    />
                </Styled.ItemInput>
            </Styled.SubInputs>
            <Styled.ItemInput>
                <Field
                    name="filter"
                    component={Checkbox}
                    title={filterTitle}
                />
            </Styled.ItemInput>
            <Styled.SubInputs>
                <Styled.ItemLabel>
                    Type
                </Styled.ItemLabel>
                <Styled.ItemInput>
                    <Field
                        name="filterType"
                        component={Select}
                        values={filterTypeOptions}
                        disabled={!values.filter}
                    />
                </Styled.ItemInput>
                <Styled.ItemLabel>
                    Cut-off frequency
                </Styled.ItemLabel>
                <Styled.ItemInput>
                    <Field
                        name="cutOffFrequency"
                        component={TextInput}
                        type="number"
                        disabled={!values.filter}
                    />
                </Styled.ItemInput>
                <Styled.ItemLabel>
                    {orderTitle}
                </Styled.ItemLabel>
                <Styled.ItemInput>
                    <Field
                        name="order"
                        component={TextInput}
                        type="number"
                        disabled={!values.filter}
                    />
                </Styled.ItemInput>
            </Styled.SubInputs>
            <Styled.ItemLabel>
                Features
            </Styled.ItemLabel>
            <Styled.ItemInput>
                <Field
                    name="rms"
                    component={Checkbox}
                    title={rmsTitle}
                />
            </Styled.ItemInput>
            <Styled.ItemLabel>
                {fftTitle}
            </Styled.ItemLabel>
            <Styled.ItemInput>
                <Field
                    name="useFFTPeaks"
                    component={Checkbox}
                    title={fftPeaksUseTitle}
                />
            </Styled.ItemInput>
            <Styled.SubInputs>
                <Styled.ItemLabel>
                    Number of Peaks
                </Styled.ItemLabel>
                <Styled.ItemInput>
                    <Field
                        name="numberOfPeaks"
                        component={TextInput}
                        type="number"
                        disabled={!values.useFFTPeaks}
                    />
                </Styled.ItemInput>
                <Styled.ItemLabel>
                    Peak minimum value
                </Styled.ItemLabel>
                <Styled.ItemInput>
                    <Field
                        name="peakMinimumValue"
                        component={TextInput}
                        type="number"
                        step={0.1}
                        disabled={!values.useFFTPeaks}
                    />
                </Styled.ItemInput>
            </Styled.SubInputs>
            <Styled.ItemInput>
                <Field
                    name="spectralPower"
                    component={Checkbox}
                    title={spectralPowerTitle}
                />
            </Styled.ItemInput>
            <Styled.SubInputs>
                <Styled.ItemLabel>
                    {intervalTitle}
                </Styled.ItemLabel>
                <Styled.ItemInput>
                    <Field
                        name="intervals"
                        component={TextInput}
                        disabled={!values.spectralPower}
                    />
                </Styled.ItemInput>
            </Styled.SubInputs>
            <Button
                type="submit"
                disabled={!isValid}
                loading={loading}
            >
                Process Signal
            </Button>
        </Form>
    );
};

FormContent.propTypes = {
    values: PropTypes.object.isRequired,
    isValid: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
};

FormContent.defaultProps = {
    loading: false,
};
