export const defaultStructures = [
    {
        key: 'MicroPython',
        structure: [
            {
                type: 'file',
                name: 'boot.py',
                isInitial: true,
                path: 'boot.py',
            },
            {
                type: 'file',
                name: 'main.py',
                isInitial: true,
                path: 'main.py',
            },
        ],
    },
    {
        key: 'C++',
        structure: [
            {
                type: 'file',
                name: 'main.c',
                isInitial: true,
                path: 'main.c',
            },
        ],
    },
];

export const syntaxHighlighting = {
    '.py': 'python',
    '.js': 'javascript',
    '.json': 'json',
    '.html': 'html',
    '.crt': 'text',
    '.cet': 'text',
    '.log': 'text',
    '.cpp': 'c_cpp',
    '.c': 'objectivec',
    '.h': 'objectivec',
    '.make': 'makefile',
};

export const ONLINE_DEVICE_STATUS = 'online';
export const PENDING_DEVICE_STATUS = 'ping';
export const OFFLINE_DEVICE_STATUS = 'offline';
export const LANGUAGE_MICROPYTHON = 'MicroPython';
export const LANGUAGE_C = 'C++';
export const BUTTON_MICROPYTHON = 'Save and Export';
export const BUTTON_C = 'Compile and Export';

export const DEVICE_ONLINE_TIMEOUT = 5000;

export const pymakrStorageLimits = {
    warning: 3.9,
    error: 4,
    reset: 3,
    localStorage: 4.9,
};

export const pymakrExportStates = {
    initial: 0,
    upload: 1,
    download: 2,
    releaseSelectProject: 3,
    compilationProject: 4,
};

export const pymakrHeader = {
    title: 'PYMAKR Editor',
    description: 'Online reliable editor for your projects!',
};

export const pymakrConnectionStates = {
    online: 'online',
    offline: 'offline',
    restarting: 'restarting',
};

export const pymakrMarkStatus = {
    noResponse: 'noResponse',
    active: 'active',
    waiting: 'waiting',
};
export const MAIN_FILES = {
    MAIN: 'main.py',
    BOOT: 'boot.py',
};

export const MAX_SIZE_FILE = 3670016;
export const ADDING_DIRECTORY = 'flash';

export const pymakrCompilationStatuses = {
    running: 'running',
    pending: 'pending',
    succeeded: 'succeeded',
    failed: 'failed',
    unknown: 'unknown',
};

export const C_EXTENSIONS_FILES = ['.c', '.cpp'];
export const supportedPymakrExtensions = ['.txt', '.py', '.log', '.json', '.js', '.cet', '.crt', '.html', '.c', '.cpp', '.h'];
export const pymakrFilesExceptions = ['Makefile'];
export const SEQUANS_MANUFACTURER = 'SEQUANS';
export const MURATA_MANUFACTURER = 'MURATA';
