import { useQuery } from '@apollo/client';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { Tile, DevicesStarterPage } from 'Components';
import { GET_USER_PROFILE_QUERY } from 'Constants';

import { linkRows } from './config';

import * as Styled from './styled';

export const CreateIntegration = () => {
    const styledTheme = useContext(ThemeContext);

    const { data: userData } = useQuery(
        GET_USER_PROFILE_QUERY,
        {
            fetchPolicy: 'cache-and-network',
            nextFetchPolicy: 'cache-first',
        },
    );

    const user = userData?.getUserProfile ?? {};
    const hasDevices = user.devices > 0;
    const headerText = hasDevices
        ? 'Fetch your data to one of the leading cloud platforms by using the Integration feature'
        : `Start your journey with ${styledTheme.whiteLabelCompany} by provisioning your
            devices now via USB or the ${styledTheme.whiteLabelCompany} mobile app.`;

    return (
        <>
            <Styled.Description>
                {headerText}
            </Styled.Description>
            {!hasDevices && <DevicesStarterPage />}
            {hasDevices && (
                <>
                    <Styled.TitleBox title="Create an Integration" />
                    <Styled.Tiles>
                        {linkRows.map((tile) => (
                            <li key={tile.title}>
                                <Tile
                                    title={tile.title}
                                    links={tile.links}
                                    icon={<tile.icon />}
                                    isNew
                                />
                            </li>
                        ))}
                    </Styled.Tiles>
                </>
            )}
        </>
    );
};
