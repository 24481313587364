export const getPreProcessingBlock = (model, mlSample) => ({
    block_type: 'pre_processing_block',
    id: 'PP',
    input_blocks: [],
    window_size_ms: model.windowsSize,
    window_step_ms: model.windowsStep,
    sampling_frequency: mlSample.frequency,
    ignore_small_samples: false,
    number_of_axes: 3,
});

export const getSignalProcessingBlock = (mlSample, values, modelDefinition) => ({
    block_type: 'signal_processing_block',
    id: 'SP',
    input_blocks: ['PP'],
    sampling_frequency: mlSample.frequency,
    standardization: {
        mean: values.mean,
        variance: values.variance,
    },
    filter: {
        type: values.filterType,
        cutoff_frequency: +values.cutOffFrequency,
        order: +values.order,
        request_filtered_data: values.filter,
    },
    features: {
        RMS: {
            enable: values.rms,
        },
        fft: {
            short_fft: {
                enable: modelDefinition?.fft || false,
                number_of_intervals: +modelDefinition?.fftIntervals || 2,
            },
            peaks: {
                enable: values.useFFTPeaks,
                peaks_number: +values.numberOfPeaks,
                threshold: +values.peakMinimumValue,
            },
            spectral_energy_on_intervals: {
                enable: values.spectralPower,
                intervals: values.intervals.split(',').map((item) => item * 1),
            },
            request_fft: true,
        },
    },
});

export const transformSamplesForSpectralAnalysis = (samples, mlSampleId) => {
    const result = [];

    samples.forEach((sample) => {
        if (sample.mlSampleId === mlSampleId) {
            try {
                const positions = JSON.parse(sample.message.payload);

                result.push({
                    milliseconds: positions.ms,
                    values: [positions.data[0] * 1, positions.data[1] * 1, positions.data[2] * 1],
                });
            } catch (err) {
                console.log(err);
            }
        }
    });

    return result;
};

export const getSampleData = (samples, mlSample, model) => {
    const data = transformSamplesForSpectralAnalysis(samples, mlSample._id)
        .filter((item) => {
            const t0 = model.lastProcessedData.t0 || 0;
            const areMillisecondsGreater = item.milliseconds >= t0;
            const areMillisecondsLesser = item.milliseconds <= (t0 + model.windowsSize);
            return areMillisecondsGreater && areMillisecondsLesser;
        })
        .sort((a, b) => a.milliseconds - b.milliseconds)
        .map((item) => item.values);

    return {
        samples: [{
            sample_name: mlSample.label,
            label: mlSample.label,
            data,
        }],
    };
 };
