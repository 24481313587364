import styled, { css } from 'styled-components';

export const Container = styled.div`
    display: grid;
    grid-template-columns: 30% 70%;
    height: 100%;
`;

export const Info = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
`;

export const Chart = styled.div`
    height: 100%;
    padding: 30px 0 0;
    position: relative;
`;

export const UnitsWrapper = styled.div`
    display: flex;
    color: #bdbdbd;
    font-size: 24px;
`;

export const UnitSplit = styled.div`
    &::after {
        content: '/';
        margin: 0 5px;
    }
`;

export const Unit = styled.span`
    cursor: pointer;
    transition: color 0.3s;
    ${({ $active }) => $active && `
        color: #000;
    `}
    &:hover {
        color: #000;
    }
`;

export const gaugeLabelStyles = css`
    position: absolute;
    font-size: 12px;
    line-height: 15px;
    color: #BDBDBD;
    &::before {
        position: absolute;
        content: ' ';
        background: #BDBDBD;
    }
`;

export const GaugeLabel = styled.div`
    ${gaugeLabelStyles}
    &:nth-child(1) {
        ${'' /* left label */}
        left: 8%;
        top: calc(50% + 20px);
        transform: translateY(-50%);
        &::before {
            width: 5px;
            height: 1px;
            right: -10px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    &:nth-child(2) {
        ${'' /* top label */}
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        &::before {
            width: 1px;
            height: 5px;
            bottom: -10px;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    &:nth-child(3) {
        ${'' /* right label */}
        right: 8%;
        top: calc(50% + 20px);
        transform: translateY(-50%);
        &::before {
            width: 5px;
            height: 1px;
            left: -10px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
`;
