import PropTypes from 'prop-types';
import { Field } from 'formik';

import { CheckboxGroup } from '../../../../../inputs';

export const DevicesList = ({ allDevices }) => {
    const options = allDevices.map((device) => ({
        label: device.description,
        value: device.token,
        cyData: `add-webhook-device-${device.description}`,
    }));
    
    return (
        <Field
            // eslint-disable-next-line react/no-array-index-key
            name="devices"
            component={CheckboxGroup}
            options={options}
        />
    );
};

DevicesList.propTypes = {
    allDevices: PropTypes.arrayOf(PropTypes.object),
};

DevicesList.defaultProps = {
    allDevices: [],
};
