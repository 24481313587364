import PropTypes from 'prop-types';
import * as Styled from './styled';

export const SimCardEmptyWidget = ({ title }) => (
    <Styled.EmptyWidget>
        {title}
    </Styled.EmptyWidget>
);

SimCardEmptyWidget.propTypes = {
    title: PropTypes.string.isRequired,
};
