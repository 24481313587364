import PropTypes from 'prop-types';
import { Formik } from 'formik';

import { FormContent } from './formContent';

export const LteSelectForm = ({ initialValue, lteList, onChange }) => {
    const onSubmit = (values) => {
        onChange(values);
    };

    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={{ lteId: initialValue || '' }}
        >
            {(props) => <FormContent lteList={lteList} {...props} />}
        </Formik>
    );
};

LteSelectForm.propTypes = {
    onChange: PropTypes.func.isRequired,
    initialValue: PropTypes.string,
    lteList: PropTypes.arrayOf(PropTypes.object),
};

LteSelectForm.defaultProps = {
    initialValue: '',
    lteList: [],
};
