import { useMemo } from 'react';
import { Switch } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { ROUTES, GET_PLATFORM_NETWORKS } from 'Constants';
import { Menu, RouteWrapper, Loader } from 'Components';

import { MainSettings } from './components';
import { baseLinks } from './config';

export const NetworkSettings = () => {
    const { data, loading } = useQuery(GET_PLATFORM_NETWORKS);
    const platformNetworks = data?.getPlatformNetworks?.platformNetworks ?? [];

    const links = useMemo(
        () => baseLinks.filter(
            (link) => platformNetworks.some(
                (platformNetwork) => platformNetwork.name === link.name,
            ),
        ),
        [platformNetworks],
    );

    if (loading) {
        return <Loader />;
    }

    return (
        <>
            <Switch>
                <RouteWrapper
                    exact
                    path={ROUTES.settings.network.main}
                    render={() => <MainSettings />}
                />
                <Menu links={links}>
                    {links.map((link) => {
                        const Component = link.component;

                        return (
                            <RouteWrapper
                                key={link.route}
                                path={link.route}
                                render={() => <Component />}
                            />
                        );
                    })}
                </Menu>
            </Switch>
        </>
    );
};
