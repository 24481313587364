import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';

import { NetworksForm, Loader } from 'Components';
import { PYGATE, LORA, GET_PLATFORM_NETWORKS } from 'Constants';

import { PygateForm } from '../pygateForm';
import * as Styled from './styled';

export const DeviceNetwork = ({
    backStep,
    deviceConfig,
    handleNetwork,
    handlePygateLora,
}) => {
    const { data: platformNetworksData, loading } = useQuery(GET_PLATFORM_NETWORKS);
    const platformNetworks = platformNetworksData?.getPlatformNetworks?.platformNetworks ?? [];

    const isShieldPygate = deviceConfig.shield?.type === PYGATE;

    const [showPygateForm, setShowPygateForm] = useState(isShieldPygate);

    const allowedNetworks = useMemo(
        () => {
            let result = deviceConfig.device?.supportedNetworks ?? [];

            if (deviceConfig.shield?.supportedNetworks?.length) {
                result = [...(new Set([...result, ...deviceConfig.shield.supportedNetworks]))];
            }

            if (deviceConfig.shield?.unsupportedNetworks?.length) {
                result = result.filter(
                    (network) => !deviceConfig.shield?.unsupportedNetworks.includes(network),
                );
            }

            if (isShieldPygate) {
                result = result.filter((network) => network !== LORA);
            }

            return result.filter(
                (network) => platformNetworks.some(
                    (platformNetwork) => platformNetwork.name === network,
                ),
            );
        },
        [deviceConfig, platformNetworks],
    );

    const updateNetwork = (data) => {
        handleNetwork(data);
    };

    const handlePygateForm = (values) => {
        const { loraRegion, loraServer } = values;

        const transformedValues = {
            pygateLora: {
                loraRegion,
                loraProvider: loraServer,
            },
        };

        setShowPygateForm(false);
        handlePygateLora(transformedValues);
    };

    if (loading) {
        return <Loader />;
    }

    if (showPygateForm) {
        return (
            <PygateForm
                onSubmit={handlePygateForm}
                backStep={backStep}
            />
        );
    }

    return (
        <div data-cy="add-device-networks-step">
            <Styled.Wrapper>
                <Styled.Title>
                    Enable Networks
                </Styled.Title>
                <Styled.Description>
                    Drag the right corner of a network type to sort networks
                    by connection priority.
                </Styled.Description>
                <NetworksForm
                    initialValues={{
                        networks: deviceConfig.networksConfig.networks,
                        wifiId: deviceConfig.networksConfig.wifiId,
                        lteId: deviceConfig.networksConfig.lteId,
                        loraServer: deviceConfig.networksConfig.loraServer,
                        loraType: deviceConfig.networksConfig.loraType,
                        loraRegion: deviceConfig.networksConfig.loraRegion,
                    }}
                    allowedNetworks={allowedNetworks}
                    onSubmit={updateNetwork}
                    onBack={backStep}
                    submitText="Next"
                    disableLora={!!deviceConfig.pygateLora}
                />
            </Styled.Wrapper>
        </div>
    );
};

DeviceNetwork.propTypes = {
    backStep: PropTypes.func.isRequired,
    deviceConfig: PropTypes.object.isRequired,
    handleNetwork: PropTypes.func.isRequired,
    handlePygateLora: PropTypes.func.isRequired,
};
