import { useMemo } from 'react';
import { Form, Field } from 'formik';
import PropTypes from 'prop-types';

import { Select, Button, TextInput } from 'Components';
import { SAMPLING_TYPES } from 'Constants';

import * as Styled from '../../styled';

export const FormContent = ({
    isValid,
    devices,
    samplingType,
    submitText,
    disabled,
    loading,
}) => {
    const devicesOptions = useMemo(() => devices.map((device) => ({
        label: device.description,
        value: device.token,
    })));

    const formTitle = samplingType === SAMPLING_TYPES.TRAINING
        ? 'Create Sample'
        : 'Classify new data';

    return (
        <>
            <Styled.ItemLabelDiv>
                {formTitle}
            </Styled.ItemLabelDiv>
            <Styled.InfoSection>
                The sampling should be performed holding the device in a specific position.
                When the model is tested, the board should be held in the same position.
            </Styled.InfoSection>
            <Form>
                {samplingType === SAMPLING_TYPES.TEST && (
                    <>
                        <Styled.ItemLabel>
                            Sample Name
                        </Styled.ItemLabel>
                        <Styled.ItemInput>
                            <Field
                                name="sampleName"
                                component={TextInput}
                            />
                        </Styled.ItemInput>
                    </>
                )}
                <Styled.ItemLabel>
                    Device
                </Styled.ItemLabel>
                <Styled.ItemInput>
                    <Field
                        name="deviceToken"
                        component={Select}
                        values={devicesOptions}
                    />
                </Styled.ItemInput>
                <Styled.ItemLabel>
                    Sensor
                </Styled.ItemLabel>
                <Styled.ItemInput>
                    <Field
                        name="sensor"
                        component={TextInput}
                        disabled
                    />
                </Styled.ItemInput>
                {samplingType === SAMPLING_TYPES.TRAINING && (
                    <>
                        <Styled.ItemLabel>
                            Label
                        </Styled.ItemLabel>
                        <Styled.ItemInput>
                            <Field
                                name="label"
                                component={TextInput}
                                errorPosition="bottom"
                            />
                        </Styled.ItemInput>
                    </>
                )}
                <Styled.ItemLabel>
                    Length (ms)
                </Styled.ItemLabel>
                <Styled.ItemInput>
                    <Field
                        name="length"
                        type="number"
                        component={TextInput}
                        errorPosition="bottom"
                    />
                </Styled.ItemInput>
                <Styled.ItemLabel>
                    Frequency (Hz)
                </Styled.ItemLabel>
                <Styled.ItemInput>
                    <Field
                        name="frequency"
                        component={TextInput}
                        type="number"
                        disabled
                        errorPosition="bottom"
                    />
                </Styled.ItemInput>
                <Button
                    type="submit"
                    disabled={!isValid || disabled}
                    loading={loading}
                >
                    {submitText}
                </Button>
            </Form>
        </>
    );
};

FormContent.propTypes = {
    isValid: PropTypes.bool.isRequired,
    devices: PropTypes.arrayOf(PropTypes.shape({
        description: PropTypes.string.isRequired,
        token: PropTypes.string.isRequired,
    })),
    samplingType: PropTypes.number.isRequired,
    submitText: PropTypes.string,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
};

FormContent.defaultProps = {
    devices: [],
    submitText: 'Create sample',
    disabled: false,
    loading: false,
};
