import styled, { css } from 'styled-components';

const shrinkedTitle = css`
    transform: translate(-10%, -65%) scale(0.8);
`;

export const Label = styled.div`
    position: absolute;
    z-index: 0;
    font-size: 16px;
    top: 0;
    padding: 4px 0;
    transition: transform 0.3s;
    color: ${({ theme }) => theme.colors.text} !important;
    ${({ $scaled }) => $scaled && shrinkedTitle}
`;

export const FieldWrapper = styled.div`
    margin-bottom: 70px;
    position: relative;

    &:last-child {
        margin-bottom: 0;
    }

    .ant-input-affix-wrapper {
        position: relative;
        z-index: 2;
        background: transparent;
        border-bottom: 1px solid #000 !important;
        border-radius: 0;
        padding-left: 0;
        padding-right: 0;
        font-size: 16px;

        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus, 
        input:-webkit-autofill:active{
            -webkit-box-shadow: 0 0 0 30px #FAFAFA inset !important;
        }

        .ant-input-suffix {
            font-size: 20px;

            ${({ $interactiveSuffix }) => $interactiveSuffix && `
                cursor: pointer;
            `}
        }
    }

    .ant-input-affix-wrapper-focused + ${Label} {
        ${shrinkedTitle}
    }

    ${({ $error, theme }) => $error && `
        .ant-input-affix-wrapper {
            border-color: ${theme.colors.error} !important;
        }

        ${Label} {
            color: ${theme.colors.error};
        }
    `}
`;

export const Error = styled.div`
    position: absolute;
    font-size: 12px;
    color: ${({ theme }) => theme.colors.error};
`;
