import styled, { css } from 'styled-components';

export const DashboardWrapper = styled.div`
    position: relative;
    margin-top: 15px;
`;

const resizeButtonStyles = css`
    font-family: "Material Icons";
    border-radius: 0;
    border: none;
    color: darkgrey;
    background: transparent;
    height: 20px;
    width: 20px;
    margin: 0;
    padding: 0;
    position: absolute;
    font-size: 16px;
    line-height: 20px;
    right: 2px;
    bottom: 2px;
    cursor: nwse-resize;
`;

export const DashboardContent = styled.div`
    -ms-transform-origin: top left;
    -webkit-transform-origin: top left;
    transform-origin: top left;
    position: relative;
    margin-left: 0;
    height: 42px;
    bottom: 0;

    .dashboardEditionContainer {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        pointer-events: none;

        &.active {
            pointer-events: auto;
        }
    }

    .dashboardEditionGrid {
        position: absolute;
        border: 1px solid black;
        left: 0;
        top: 0;
        width: 107px;
        height: 107px;
        padding: 6px;
        margin: 0;
        z-index: 1.5;
        opacity: 0.03;
    }

    .dashboardEditionGrid.targeted {
        opacity: 1;
    }

    .dummyContent {
        height: 42px;
        margin-top: auto;
        margin-bottom: auto;

        h2,
        h4 {
            text-align: center !important;
            width: 100% !important;
            margin: 0 !important;
            padding: 0 !important;
            white-space: normal !important;
        }

        h4 {
            margin-top: 22px!important;
        }
    }

    .dummyContainer {
        display: table-cell;
        vertical-align: middle;
    }

    .tabPanelCoreContent {
        display: table;
        margin-top: 20px;
        height: 100%;
        width: 100%;
    }
`;

export const DashboardTab = styled.div`
    position: relative;
    overflow-x: auto;
`;

export const DashboardHeaderPanel = styled.div`
    display: flex;
    padding: 0px 0 10px;

    button {
        margin-right: 15px;
    }
`;

export const WidgetContainer = styled.div`
    position: absolute;
    transition: all 150ms ease;
    border: 1px solid #BABDBE;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 6px;

    .tabPanel {
        height: 100%;
        padding: 0 24px 35px 24px;
    }

    .widgetEditionPanel {
        transition: all linear 100ms;
        background-color: rgba(240, 240, 240, 0.5);
        box-shadow: 0 0 0 0 rgba(50, 50, 50, 0.2);
        border: 2px solid lightgrey;
    }

    .widgetDummyDragButton:hover + .widgetEditionPanel {
        background-color: rgba(255, 255, 255, 1);
        border: 2px solid fade(#999999, 50%);
        box-shadow: 0 2px 5px 0 rgba(50, 50, 50, 0.2);
    }

    .widgetEditionPanel.selected {
        opacity: 0;
    }

    .widgetEditionPanel.overlapped {
        background-color: red !important;
    }

    .mapFooter {
        position: relative;

        button {
            margin: 15px auto;
        }
    }

    .googleMapContainer {
        height: calc(100% - 50px);
    }

    .widgetDummyDragButton {
        position: absolute;
        top: 20px;
        bottom: 20px;
        left: 20px;
        right: 20px;
        background: transparent;
        padding: 0;
        margin: 0;
        border: none;
        width: calc(100% - (20px * 2));
        height: calc(100% - (20px * 2));
        cursor: move;
    }

    .widgetOriginGhost {
        border: 2px dashed fade(#999999, 50%);
        border-radius: 4px;
        background: transparent;
        pointer-events: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 0;
    }

    .widgetProjectionGhost {
        border: 2px solid #999999;
        border-radius: 4px;
        background: fade(white, 40%);
        pointer-events: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        box-shadow: none;

        h2,
        h4 {
            color: #999999 !important;
        }
    }
`;

export const ResizeButton = styled.button`
    ${resizeButtonStyles}
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 10px 10px;
    border-color: transparent transparent  ${({ theme }) => theme.colors.border} transparent;
`;

export const RemoveButton = styled.button`
    ${resizeButtonStyles}
    bottom: 0;
    top: 2px;

    &:before {
        content: "\\00e5cd";
    }

    &:hover:before {
        color: rgba(155,0,0,1);
    }
`;
