import * as Yup from 'yup';

import { ValidationService } from 'Services';

export const initialValues = {
    connectionString: '',
    accessKey: '',
    secretAccessKey: '',
    saveLocally: false,
};

export const validationSchema = Yup.object().shape({
    connectionString: Yup.string()
        .matches(/^HostName=(.*);SharedAccessKeyName=(\w+);SharedAccessKey=(.*)$/, 'Connection string is invalid')
        .required(ValidationService.getRequiredError('connection string')),
});
