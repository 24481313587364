import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { SIM_INFO_COLUMNS_LENGTH } from 'Constants';

import { SimCardWidgetBox } from '../simCardWidgetBox';

import * as Styled from './styled';

export const SimCardInformation = ({ simInfo }) => {
    const columns = useMemo(() => {
        const rowsInColumn = simInfo.length / SIM_INFO_COLUMNS_LENGTH;
        const resultDataRepresentation = [];

        simInfo.forEach(({ label, value }, index) => {
            const currentArrayIndex = Math.floor(index / rowsInColumn);
            const column = resultDataRepresentation[currentArrayIndex] || {};
            column[label] = value;
            resultDataRepresentation[currentArrayIndex] = column;
        });

        const result = resultDataRepresentation.map((column, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Styled.Column key={index}>
                {Object.entries(column).map(([key, value]) => (
                    <Styled.ColumnRow key={key}>
                        <Styled.Label>
                            {key}
                        </Styled.Label>
                        <span>
                            {value}
                        </span>
                    </Styled.ColumnRow>
                ))}
            </Styled.Column>
        ));

        return result;
    }, [simInfo]);

    return (
        <SimCardWidgetBox title="Sim Information">
            <Styled.ColumnsWrapper>
                {columns}
            </Styled.ColumnsWrapper>
        </SimCardWidgetBox>
    );
};

SimCardInformation.propTypes = {
    simInfo: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([
                PropTypes.string, PropTypes.number,
            ]).isRequired,
        }),
    ).isRequired,
};
