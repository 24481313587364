import PropTypes from 'prop-types';

import * as Styled from './styled';

export const MaskedTextInput = ({
    disabled,
    field,
    form: { errors, touched },
    placeholder,
    type,
    errorPosition,
    errorClassName,
    min,
    max,
    step,
    mask,
}) => {
    const conditionalProps = {};
    if (max) {
        conditionalProps.max = max;
    }
    if (step) {
        conditionalProps.step = step;
    }
    return (
        <Styled.Wrapper>
            <Styled.MaskedTextInput
                type={type}
                placeholder={placeholder}
                min={min || 0}
                {...field}
                {...conditionalProps}
                mask={mask}
                disabled={disabled}
            />
            {!disabled && touched[field.name] && (
                <Styled.Error $position={errorPosition} className={errorClassName}>
                    {errors[field.name]}
                </Styled.Error>
            )}
        </Styled.Wrapper>
    );
};

MaskedTextInput.propTypes = {
    disabled: PropTypes.bool,
    form: PropTypes.object.isRequired,
    field: PropTypes.object.isRequired,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    errorPosition: PropTypes.string,
    errorClassName: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    step: PropTypes.number,
    mask: PropTypes.object.isRequired,
};

MaskedTextInput.defaultProps = {
    disabled: false,
    type: 'text',
    placeholder: '',
    errorPosition: 'right',
    errorClassName: '',
    min: 0,
    max: 0,
    step: 1,
};
