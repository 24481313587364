import { CopyToClipboard } from 'react-copy-to-clipboard';
import PropTypes from 'prop-types';

import { Button } from '../../buttons';

import * as Styled from './styled';

export const ClickBoard = ({ text, handleCopy }) => (
    <CopyToClipboard text={text} onCopy={handleCopy}>
        <Styled.ClickboardRow>
            <Styled.Clickboard>
                {text}
            </Styled.Clickboard>
            <Button>
                Copy
            </Button>
        </Styled.ClickboardRow>
    </CopyToClipboard>
);

ClickBoard.propTypes = {
    text: PropTypes.string.isRequired,
    handleCopy: PropTypes.func.isRequired,
};
