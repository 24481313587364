// import React from 'react';
import ReactDOM from 'react-dom';

import { App } from 'App';

import 'antd/dist/antd.css';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

ReactDOM.render(
    //TODO temporary solution for findDomNode
    // <React.StrictMode>
        <App />,
    // </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
