import * as Yup from 'yup';

import { ValidationService } from 'Services';

export const initialValues = {
    name: '',
    messagesTopic: '',
};

export const validationSchema = Yup.object().shape({
    name: Yup.string()
        .matches(/^([A-Za-z0-9]{1,64})$/, 'Name invalid: up to 64 alphanumeric characters.')
        .required(ValidationService.getRequiredError('name')),
    messagesTopic: Yup.string()
        .matches(/^([A-Za-z0-9/_-]{1,256})$/, 'Topic invalid: between 1 and 256 [ alphanumeric - _ /] characters.')
        .required(ValidationService.getRequiredError('messages topic')),
    devices: Yup.array().compact().min(1, 'Please select at least one device'),
});
