export const register = async (email, password, fullName, reCaptchaToken) => {
    const headers = new Headers();
    headers.set('Content-type', 'application/json');
    headers.set('recaptcha-response', reCaptchaToken);

    const response = await fetch(`${process.env.REACT_APP_API_URL}/account/register`, {
        method: 'POST',
        headers,
        credentials: 'include',
        body: JSON.stringify({
            email,
            password,
            fullName,
        }),
    });
    if (response.ok) {
        const res = await response.json();
        return res;
    }

    return { errorMessage: await response.json() };
};
