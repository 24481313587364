import styled from 'styled-components';

export const NoDataWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 49px;
    justify-content: center;
    align-items: center; 

    h2 {
        margin-top: 50px;
    }
`;

export const Icon = styled.div`

    svg {
        font-size: 50px;
    }
`;
