export const xLabelData = {
    value: 'Time (milliseconds)',
    position: 'insideLeft',
    dy: 15,
    stroke: '#C0C7CE',
};

export const transformDataForMap = (samples, mlSampleId) => {
    const result = [];

    samples.forEach((sample) => {
        if (sample.mlSampleId === mlSampleId && sample.message?.payload) {
            try {
                const positions = JSON.parse(sample.message.payload);

                result.push({
                    milliseconds: positions.ms,
                    xValue: positions.data[0] * 1,
                    yValue: positions.data[1] * 1,
                    zValue: positions.data[2] * 1,
                });
            } catch (err) {
                console.log(err);
            }
        }
    });

    result.sort((a, b) => a.milliseconds - b.milliseconds);

    return result;
};
