import PropTypes from 'prop-types';

import * as Styled from './styled';

export const CheckboxGroup = ({
    options,
    field,
    form: { touched, errors },
    disabled,
}) => (
    <>
        {options.map((valueItem, index) => (
            <Styled.Wrapper key={valueItem[index]?.description}>
                <Styled.Container>
                    {valueItem.label}
                    <input
                        value={valueItem.value}
                        name={`${field.name}.${index}`}
                        onChange={field.onChange}
                        onBlur={field.onBlur}
                        type="checkbox"
                        checked={field.value[index]}
                        disabled={disabled}
                        data-cy={valueItem.cyData}
                    />
                    <Styled.Fake />
                </Styled.Container>
            </Styled.Wrapper>
        ))}
        {touched[field.name] && (
            <Styled.Error>
                {errors[field.name]}
            </Styled.Error>
        )}
    </>
);

CheckboxGroup.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        cyData: PropTypes.string,
    })).isRequired,
    field: PropTypes.object,
    form: PropTypes.object,
    disabled: PropTypes.bool,
};

CheckboxGroup.defaultProps = {
    field: null,
    form: {},
    disabled: false,
};
