import PropTypes from 'prop-types';
import { Table, Spin } from 'Components';
import * as Styled from './styled';

export const TableView = ({
    title,
    columns,
    items,
    onSelect,
    onPagination,
    pagination,
    loading,
}) => (
    <Styled.TableWrapper>
        <h2>{title}</h2>
        <Styled.Table>
            <Spin spinning={loading}>
                <Table
                    columns={columns}
                    data={items}
                    onRowClick={onSelect}
                    onPaginationChange={onPagination}
                    pagination={pagination}
                />
            </Spin>
        </Styled.Table>
    </Styled.TableWrapper>
);

TableView.defaultProps = {
    onPagination: () => {},
    pagination: {},
    loading: false,
};

TableView.propTypes = {
    title: PropTypes.string.isRequired,
    columns: PropTypes.array.isRequired,
    items: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired,
    onPagination: PropTypes.func,
    pagination: PropTypes.object,
    loading: PropTypes.bool,
};
