import * as Yup from 'yup';

export const initialValues = {
    port: '',
    speed: 921600,
    deviceType: '',
    firmwareType: '',
    fileSystem: '',
    loraCountry: '',
    loraRegion: '',
    firmwareVersion: '',
    envs: false,
    effs: false,
};

export const validationSchema = Yup.object().shape({
    port: Yup.string().required('Port is required'),
    deviceType: Yup.string().required('Device type is required'),
    firmwareType: Yup.string().required('Firmware type is required'),
    firmwareVersion: Yup.string().required('Firmware type is required'),
    fileSystem: Yup.string().required('File system is required'),
});
