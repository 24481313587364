import styled from 'styled-components';

export const Form = styled.div`
    margin-top: 50px;
`;

export const Title = styled.div`
    color: ${({ theme }) => theme.colors.title};
    font-size: 22px;
    line-height: 27px;
    font-weight: bold;
`;

export const Description = styled.div`
    margin-top: 10px;
    color: ${({ theme }) => theme.colors.text};
    font-size: 16px;
    line-height: 20px;
`;
