import styled from 'styled-components';

export const Wrapper = styled.div`
    max-width: 1122px;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 15px 0 27px;
`;

export const Title = styled.h3`
    font-size: 20px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.text};
    padding-bottom: 6px;
    margin-bottom: 0;
`;

export const IconsWrapper = styled.div`
    display: flex;
`;

export const Icon = styled.div`
    font-size: 20px;
    color: ${({ theme }) => theme.colors.border};
    transition: color 0.3s;
    line-height: 1;
    cursor: pointer;

    ${({ $active, theme }) => $active && `
        color: ${theme.colors.active};
    `}

    &:hover {
        color: ${({ theme }) => theme.colors.active};
    }

    &:first-child {
        margin-right: 18px;
    }
`;

export const List = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    margin-top: 70px;

    & > *:nth-child(2) {
        margin-left: 30px;
    }
`;
