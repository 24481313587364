import styled from 'styled-components';

export const HeartRateContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    margin-top: 15px;
`;

export const Cardiogram = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-end;
    svg {
        font-size: 75.22px;
        color: #CC0011;
    }
`;

export const HeartRate = styled.div`
    font-size: 20px;
    color: black;
    line-height: 1;
    position: absolute;
    left: 0px;
    top: -30px;
    span {
        font-size: 50px;
    }
`;

export const Line = styled.div`
    position: absolute;
    height: 2px;
    background-color: #CC0011;
    bottom: 39px;
    left: -30px;
    right: -30px;
`;
