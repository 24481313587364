import { useContext, useState, useEffect } from 'react';
import { ThemeContext } from 'styled-components';
import { useQuery } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';

import { Loader } from 'Components/loader';
import { getSessionFormApi } from 'Api';
import { GET_USER_PROFILE_QUERY, ROUTES } from 'Constants';
import { GlobalContext } from 'Context';
import { getSSOUrl } from 'Utils';

const isCustomLogin = process.env.REACT_APP_CUSTOM_LOGIN;

export const useAuthenticate = ({ reverseRedirect } = {}) => {
    const history = useHistory();
    const { pathname, search } = useLocation();

    const styledTheme = useContext(ThemeContext);
    const globalContext = useContext(GlobalContext);
    const { session, setSession } = globalContext;

    const [isLoading, setIsLoading] = useState(true);

    useQuery(GET_USER_PROFILE_QUERY, {
        skip: !session,
        onCompleted: () => {
            setIsLoading(false);
        },
    });

    const checkLogin = async () => {
        const localSession = await getSessionFormApi();

        if (localSession && Object.keys(localSession).length) {
            setSession(localSession);

            if (reverseRedirect) {
                history.push(ROUTES.main);
            }

            return;
        }

        if (isCustomLogin === 'true') {
            if ((pathname !== ROUTES.auth.resetPassword && pathname !== ROUTES.auth.confirmEmail) || !search) {
                history.push(ROUTES.auth.login);
            }
            setIsLoading(false);
        } else {
            const ssoUrl = getSSOUrl();
            window.location.href = ssoUrl;
        }
    };

    useEffect(() => {
        checkLogin();
    }, []);

    const loader = <Loader title={`Connecting to ${styledTheme?.whiteLabelCompany}`} />;

    return {
        loader,
        isLoading,
    };
};
