import PropTypes from 'prop-types';
import { Field } from 'formik';

import { TextInput, LoraSelectForm } from 'Components';
import * as Styled from './styled';

export const FormContent = ({
    isGatewayActive,
    toggleGatewayActivity,
    isToggleGatewayLoading,
    isLoading,
    showGatewayConfigFile,
    onLoraSettingsChange,
    onDeploy,
    isShieldPygate,
    values,
}) => (
    <>
        <Styled.FormWrapper>
            <Styled.FormLabel>
                Expansion board type
            </Styled.FormLabel>
            <Field
                name="expansionBoard"
                type="text"
                component={TextInput}
                disabled
            />
            {isShieldPygate && (
                <>
                    <Styled.FormLabel>
                        Gateway Id
                    </Styled.FormLabel>
                    <Field
                        name="gatewayId"
                        type="text"
                        component={TextInput}
                        disabled
                    />

                    <Styled.FormLabel>
                        Turn your gateway {(isGatewayActive ? 'off' : 'on')}
                    </Styled.FormLabel>
                    <Styled.ButtonsWrapper>
                        <Styled.Switch
                            checked={isGatewayActive}
                            onChange={toggleGatewayActivity}
                            loading={isToggleGatewayLoading}
                            data-cy="switch-gateway-activity-button"
                        />
                        <Styled.Button
                            disabled={!values.gatewayId}
                            onClick={showGatewayConfigFile}
                            cyData="get-config-file-button"
                        >
                            Get config file
                        </Styled.Button>
                    </Styled.ButtonsWrapper>

                    <Styled.FormLabel>
                        Lora settings
                    </Styled.FormLabel>
                    <LoraSelectForm
                        onChange={onLoraSettingsChange}
                        loraSettings={values.loraSettings}
                        showLoraType={false}
                    />
                </>
            )}
        </Styled.FormWrapper>
        {isShieldPygate && (
            <Styled.Button
                buttonType="transparent"
                loading={isLoading}
                onClick={() => onDeploy(values)}
                cyData="gateway-save-and-deploy-button"
            >
                {values.gatewayId ? 'Deploy' : 'Save and deploy'}
            </Styled.Button>
        )}
    </>
);

FormContent.propTypes = {
    isGatewayActive: PropTypes.bool.isRequired,
    isToggleGatewayLoading: PropTypes.bool.isRequired,
    toggleGatewayActivity: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    showGatewayConfigFile: PropTypes.func.isRequired,
    onLoraSettingsChange: PropTypes.func.isRequired,
    onDeploy: PropTypes.func.isRequired,
    isShieldPygate: PropTypes.bool.isRequired,
    values: PropTypes.object.isRequired,
};

FormContent.defaultProps = {
    isLoading: false,
};
