// Returns a new columns array
// colsToInsert is an array of objects with 2 params:
// index: index at which we should insert the new column
// column: object to be inserted in the column array
export const insertColumns = (columns, colsToInsert) => {
    const newColumns = [...columns];
    colsToInsert.forEach((col) => {
        newColumns.splice(col.index, 0, col.column);
    });
    return newColumns;
};

// Returns a new columns array by filtering out unwanted columns
// listOfUnwanted is an array of column titles
export const getColumnsWithout = (columns, listOfUnwanted) => [...columns].filter((obj) => !listOfUnwanted.includes(obj.title));
