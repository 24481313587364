import styled from 'styled-components';

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const SelectWrapper = styled.div`
    margin-right: 15px;
    flex: 1;

    &:last-child {
        margin-right: 0;
    }

    select {
        width: 100%;
    }
`;

export const Label = styled.div`
    margin-bottom: 5px;
`;

export const Graph = styled.div`
    margin-top: 30px;
    display: flex;
    justify-content: center;
`;
