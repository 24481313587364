import styled from 'styled-components';

export const Wrapper = styled.div`
    h1 {
        color: ${({ theme }) => theme.colors.title};
        font-weight: 700;
        margin-top: 35px;
        margin-bottom: 0;
    }
`;

export const Table = styled.div`
    margin-top: 20px;
`;

export const Header = styled.h2`
    padding: 30px 30px 0;
`;

export const ReleaseTable = styled.div`
    padding: 0 30px 30px;
`;
