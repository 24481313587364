import { Field, Form } from 'formik';
import PropTypes from 'prop-types';

import { Checkbox } from '../../../inputs';
import { AlertBordered } from '../../../alertBordered';

import * as Styled from './styled';

export const FormContent = ({
    values,
    allDevices,
    prevStep,
    submitText,
    alreadyUsedDevices,
    isEditMode,
    formValues,
    isLoading,
}) => {
    const getIsDeviceDisabled = (token) => {
        if (isEditMode) {
            return false;
        }
        
        return alreadyUsedDevices.includes(token);
    };

    return (
        <Form>
            <Styled.FormContent>    
                <Styled.FormItem>
                    <Styled.ItemLabel>
                        Registry
                    </Styled.ItemLabel>
                    <Styled.ColoredValue>
                        {formValues.registryId}
                    </Styled.ColoredValue>
                </Styled.FormItem>
                <Styled.FormItem>
                    <Styled.ItemLabel>
                        Project
                    </Styled.ItemLabel>
                    <Styled.ColoredValue>
                        {formValues.projectId}
                    </Styled.ColoredValue>
                </Styled.FormItem>
                <Styled.FormItem>
                    <Styled.ItemLabel>
                        Region
                    </Styled.ItemLabel>
                    <Styled.ColoredValue>
                        {formValues.region}
                    </Styled.ColoredValue>
                </Styled.FormItem>
                <Styled.FormItem $checkboxesWrapper>
                    <Styled.ItemLabel>
                        Devices
                    </Styled.ItemLabel>
                    {!!alreadyUsedDevices.length && !isEditMode && (
                        <AlertBordered
                            type="warning"
                            title="Warning"
                            message={`Some devices are using the same registry and region for this project.
                                You won't be able to select those devices for this new integration.`}
                        />
                    )}
                    {values.devices.map((_, index) => (
                        <Field
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            name={`devices.${index}`}
                            component={Checkbox}
                            title={allDevices[index].description}
                            disabled={getIsDeviceDisabled(allDevices[index].token)}
                        />
                    ))}
                </Styled.FormItem>
                <Styled.ButtonsWrapper>
                    <Styled.ButtonBack
                        buttonType="transparent"
                        onClick={prevStep}
                    >
                        Cancel
                    </Styled.ButtonBack>
                    <Styled.ButtonSubmit type="submit" loading={isLoading}>
                        {submitText}
                    </Styled.ButtonSubmit>
                </Styled.ButtonsWrapper>
            </Styled.FormContent>
        </Form>
    );
};

FormContent.propTypes = {
    values: PropTypes.object.isRequired,
    allDevices: PropTypes.arrayOf(PropTypes.object).isRequired,
    prevStep: PropTypes.func.isRequired,
    submitText: PropTypes.string.isRequired,
    alreadyUsedDevices: PropTypes.array.isRequired,
    isEditMode: PropTypes.bool,
    formValues: PropTypes.object,
    isLoading: PropTypes.bool,
};

FormContent.defaultProps = {
    isEditMode: false,
    formValues: {},
    isLoading: false,
};
