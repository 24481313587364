import * as Yup from 'yup';

import { ipRegex } from 'Constants';

export const AUTO_SETTINGS_TYPES = {
    AUTOMATIC: 'AUTOMATIC',
    MANUAL: 'MANUAL',
};

export const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required.'),
    ipAddress: Yup.string().when('autoSettings', {
        is: AUTO_SETTINGS_TYPES.MANUAL,
        then: Yup.string()
                .matches(ipRegex, 'IPv4 format required: XXX.XXX.XXX.XXX')
                .required('IP Address required'),
    }),
    router: Yup.string().when('autoSettings', {
        is: AUTO_SETTINGS_TYPES.MANUAL,
        then: Yup.string()
                .required('Router required')
                .matches(ipRegex, 'IPv4 format required: XXX.XXX.XXX.XXX'),
    }),
    dnsServer: Yup.string().when('autoSettings', {
        is: AUTO_SETTINGS_TYPES.MANUAL,
        then: Yup.string()
                .required('DNS Server required')
                .matches(ipRegex, 'IPv4 format required: XXX.XXX.XXX.XXX'),
    }),
    subnetMask: Yup.string().when('autoSettings', {
        is: AUTO_SETTINGS_TYPES.MANUAL,
        then: Yup.string()
                .required('Subnet Mask required')
                .matches(ipRegex, 'IPv4 format required: XXX.XXX.XXX.XXX'),
    }),
});

export const AUTO_SETTINGS_TYPE = [
    'Automatic (DHCP)',
    'Manual',
];

export const initialValues = {
    name: '',
    autoSettings: AUTO_SETTINGS_TYPES.AUTOMATIC,
    ipAddress: '',
    subnetMask: '',
    router: '',
    dnsServer: '',
    dnsServer2: '',
};
