import styled from 'styled-components';

export const ChartTitle = styled.h3`
    color: ${({ theme }) => theme.colors.title};
    font-size: 23px;
    opacity: 0.89;
`;

export const ChartEmpty = styled.div`
    display: flex;
    align-items: center;
    flex-grow: 1;
    border-color: ${({ theme }) => theme.colors.border};
    border-style: solid;
    border-left-width: 1px;
    border-bottom-width: 1px;

    p {
        width: 100%;
        font-size: 14px;
        font-weight: normal;
        line-height: 17px;
        text-align: center;
        color: ${({ theme }) => theme.colors.title};
    }
`;

export const DataNotValidLabel = styled.div`
    font-size: 12px;
    color: ${({ theme }) => theme.colors.error};
    margin-top: -5px;
`;

export const NotValidWarning = styled.span`
    font-size: 16px;
    line-height: 0;
    margin-left: 10px;
    color: ${({ theme }) => theme.colors.error};
    vertical-align: middle;
`;

export const WidgetWrapper = styled.div`
    width: 100%;
    height: 100%;
`;
