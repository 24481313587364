import gql from 'graphql-tag';

export const GET_USER_PROFILE_QUERY = gql`
    query getUserProfile {
        getUserProfile {
            _id
            owner
            fullName
            countryId
            timezone
            phone
            devices
            companyName
            companyAddress
            billingAddress
            vatNumber
            termsOfServiceUpdated
            breakingChangesUpdate
            featuresUpdated
        }
    }
`;

export const GET_FIRMWARE_QUERY = gql`
  query getListOfFirmware {
    getListOfFirmware
  }
`;

export const GET_USER_LOGS_QUERY = gql`
    query getLogsByUser($size: Int, $page: Int, $filter: LogFilter, $sortBy: SortBy) {
            getLogsByUser(size: $size, page: $page, filter: $filter, sortBy: $sortBy) {
                data {
                    _id
                    user
                    ip
                    queryType
                    requestedCategory
                    status
                    createdAt
                }
                size
                page
                count
            }
        }
`;
