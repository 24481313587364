import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';

import { GET_LORA_SETTINGS, TAB } from 'Constants';
import { hasValueLength } from 'Utils';
import { LoRaModal, Loader } from 'Components';

import { EmptySettings } from '../emptySettings';

export const LoraSettings = () => {
    const [isShowForm, setIsShowForm] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);
    const { data: settings, loading } = useQuery(GET_LORA_SETTINGS);
    const lora = settings?.getLoRaSettings ?? {};

    useEffect(
        () => {
            if (initialLoading && !loading) {
                setInitialLoading(false);
            }
        },
        [loading],
    );

    useEffect(() => {
        if (lora && hasValueLength(lora)) {
            setIsShowForm(true);
        }
    }, [lora]);

    const handleChangeForm = () => {
        setIsShowForm(!isShowForm);
    };

    if (initialLoading) {
        return <Loader />;
    }

    return isShowForm ? (
        <LoRaModal typeOfForm={TAB} />
    ) : (
        <EmptySettings title="LoRa" showForm={handleChangeForm} />
    );
};
