import { useQuery } from '@apollo/client';

import { ROUTES, GET_ALL_DEVICES_QUERY, GET_APPLICATIONS } from 'Constants';
import { ReactComponent as Networks } from 'Assets/icons/wifi.svg';
import { ReactComponent as Device } from 'Assets/icons/cpu.svg';
import { ReactComponent as Project } from 'Assets/icons/computer.svg';
import { ReactComponent as Editor } from 'Assets/icons/pymakr.svg';
import { ReactComponent as Github } from 'Assets/icons/github.svg';
import { ReactComponent as Integration } from 'Assets/icons/complex-integration.svg';
import { ReactComponent as Forum } from 'Assets/icons/forum.svg';
import { ReactComponent as Documentation } from 'Assets/icons/docs.svg';
import { ReactComponent as WebShop } from 'Assets/icons/web-shop.svg';

import { IconCard, CardLink, TextCard } from './components';
import * as Styled from './styled';

export const Home = () => {
    const { data: appData } = useQuery(
        GET_APPLICATIONS,
        { fetchPolicy: 'cache-and-network' },
    );
    const { data: devicesData } = useQuery(
        GET_ALL_DEVICES_QUERY,
        { fetchPolicy: 'cache-and-network' },
    );

    const projectsCount = appData?.getApplications?.length;
    const devicesCount = devicesData?.device?.length;

    const topRow = [
        {
            title: 'Networks',
            links: [ROUTES.settings.main],
            linkTitle: 'Setup',
            icon: Networks,
        },
        {
            title: 'IoT Devices',
            links: [ROUTES.devices.add],
            linkTitle: 'Add new',
            icon: Device,
        },
        {
            title: 'Projects',
            links: [ROUTES.projects.create],
            linkTitle: 'Create new',
            icon: Project,
        },
    ];

    const bottomRow = [
        {
            title: 'Online coding tool',
            links: [ROUTES.pymakr.main],
            linkTitle: 'Go to Pymakr',
            icon: Editor,
        },
        {
            title: 'GitHub Integration',
            links: [ROUTES.settings.integrations.main],
            linkTitle: 'See all',
            icon: Github,
        },
        {
            title: 'Cloud Integrations',
            links: [ROUTES.settings.integrations.main],
            linkTitle: 'See all',
            icon: Integration,
        },
    ];

    const supportLinks = [
        {
            title: 'Forum',
            link: process.env.REACT_APP_LINK_FORUM,
            icon: Forum,
        },
        {
            title: 'Docs',
            link: process.env.REACT_APP_PYBYTES_DOC,
            icon: Documentation,
        },
        {
            title: 'Web Shop',
            link: process.env.REACT_APP_LINK_WEBSHOP,
            icon: WebShop,
        },
    ];

    const textCards = [
        {
            title: devicesCount === 1 ? 'Device' : 'Devices',
            value: devicesCount,
            link: [ROUTES.devices.main],
        },
        {
            title: projectsCount === 1 ? 'Project' : 'Projects',
            value: projectsCount,
            link: [ROUTES.projects.main],
        },
    ];

    return (
        <Styled.Page>
            <Styled.Box>
                <Styled.Title>
                    Quick start
                </Styled.Title>
                <Styled.CardsRow>
                    {topRow.map((item) => (
                        <IconCard
                            key={item.title}
                            title={item.title}
                            link={item.links}
                            linkTitle={item.linkTitle}
                            icon={<item.icon />}
                        />
                    ))}
                </Styled.CardsRow>
            </Styled.Box>
            <Styled.MiddleRow>
                <Styled.Box>
                    <Styled.Title>
                        Devices & Projects
                    </Styled.Title>
                    <Styled.Devices>
                        {textCards.map((item) => (
                            <TextCard
                                key={item.title}
                                value={item.value}
                                title={item.title}
                                link={item.link}
                            />
                        ))}
                    </Styled.Devices>
                </Styled.Box>
                <Styled.Box>
                    <Styled.Title>
                        Support
                    </Styled.Title>
                    <Styled.Support>
                        {supportLinks.map((item) => (
                            <CardLink
                                key={item.title}
                                title={item.title}
                                link={item.link}
                                icon={<item.icon />}
                            />
                        ))}
                    </Styled.Support>
                </Styled.Box>
            </Styled.MiddleRow>
            <Styled.Box>
                <Styled.Title>
                    Recently added
                </Styled.Title>
                <Styled.CardsRow>
                    {bottomRow.map((item) => (
                        <IconCard
                            key={item.title}
                            title={item.title}
                            link={item.links}
                            linkTitle={item.linkTitle}
                            icon={<item.icon />}
                        />
                    ))}
                </Styled.CardsRow>
            </Styled.Box>
        </Styled.Page>
    );
};
