import PropTypes from 'prop-types';

import { CodeContainerEditor } from './codeContainerEditor';
import { CodeContainerTab } from './codeContainerTab';
import { useContextSelector } from '../../../pymakrHooks';
import * as Styled from './styled';

export const CodeContainer = ({ projectKey }) => {
    const tabsSlice = useContextSelector('tabs');
    const { activeTab, tabs } = tabsSlice.state;
    const renderedTabs = tabs.map((tab) => (
        tab && (
            <CodeContainerTab
                key={tab.key}
                tab={tab}
                projectKey={projectKey}
            />
        )
    ));

    return (
        <Styled.Wrapper
            $default={tabs.length}
            $noActive={!tabs.length}
        >
            <Styled.TabsWrapper>
                {renderedTabs}    
            </Styled.TabsWrapper>
            {activeTab && (
                <CodeContainerEditor projectKey={projectKey} />
            )}
        </Styled.Wrapper>
    );
};

CodeContainer.propTypes = {
    projectKey: PropTypes.string.isRequired,
};
