import { Form, Field } from 'formik';
import PropTypes from 'prop-types';

import { ReactComponent as SupportIcon } from 'Assets/icons/support.svg';

import { Button } from '../../../buttons';
import { InfoTooltip } from '../../../infoTooltip';
import { TextInput } from '../../../inputs';
import * as Styled from '../styled';

import { machineLearningDocumentationLink } from './config';

export const FormContent = ({ isValid, onBack, hideBackButton, submitText, loading, dirty }) => (
    <Form>
        <Styled.FormContent>
            <div>
                <Styled.ItemLabel>
                    Name
                </Styled.ItemLabel>
                <Field
                    name="name"
                    component={TextInput}
                    errorPosition="bottom"
                />
            </div>

            <div>
                <Styled.ItemLabel>
                    Description
                </Styled.ItemLabel>
                <Field
                    name="description"
                    component={TextInput}
                />
            </div>

            <div>
                <Styled.ItemLabel>
                    Sample Frequency
                    <InfoTooltip text="The number of data samples per second" />
                </Styled.ItemLabel>
                <Field
                    name="frequency"
                    type="number"
                    component={TextInput}
                    errorPosition="bottom"
                />
            </div>

            <div>
                <Styled.ItemLabel>
                    Windows Step (ms)
                    <InfoTooltip
                        text="The moving step with which the moving window is shifted in time."
                    />
                </Styled.ItemLabel>
                <Field
                    name="windowsStep"
                    type="number"
                    component={TextInput}
                    errorPosition="bottom"
                />
            </div>

            <div>
                <Styled.ItemLabel>
                    Windows Size (ms)
                    <InfoTooltip
                        text={`To analyze the data, a window of a certain size is moved over the data using a moving step.
                            This field defines the size of the moving window`}
                    />
                </Styled.ItemLabel>
                <Field
                    name="windowsSize"
                    type="number"
                    component={TextInput}
                    errorPosition="bottom"
                />
            </div>          

        </Styled.FormContent>
        <Styled.NotificationWrapperBottom>
            <Styled.NotificationIcon>
                <SupportIcon />
            </Styled.NotificationIcon>
            <Styled.NotificationLabel>
                Want to know more about Machine Learning Models, visit our&nbsp; 
                <a
                    href={machineLearningDocumentationLink}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Documentation
                </a>.
            </Styled.NotificationLabel>
        </Styled.NotificationWrapperBottom>
        <Styled.ButtonsWrapper>
            {!hideBackButton && (
                <Button
                    buttonType="transparent"
                    onClick={onBack}
                >
                    Back
                </Button>
            )}
            <Button
                type="submit"
                disabled={!isValid || !dirty}
                loading={loading}
            >
                {submitText}
            </Button>
        </Styled.ButtonsWrapper>
    </Form>
);

FormContent.propTypes = {
    isValid: PropTypes.bool.isRequired,
    onBack: PropTypes.func.isRequired,
    hideBackButton: PropTypes.bool,
    submitText: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    dirty: PropTypes.bool.isRequired,
};

FormContent.defaultProps = {
    hideBackButton: false,
};
