import PropTypes from 'prop-types';

import { ROUTES } from 'Constants';

import * as Styled from './styled';

export const Sigfox = ({ contractType }) => {
    const text = contractType === 'devKit'
        ? 'This application will added as a Sigfox DevKit to Pybytes. You can change this in '
        : 'This application will be added using Custom Contract Sigfox account to Pybytes. You can change this in ';

    return (
        <>
            {text}
            <Styled.Link to={ROUTES.settings.network.sigfox}>
                &nbsp;Sigfox API settings
            </Styled.Link>.
        </>
    );
};

Sigfox.propTypes = {
    contractType: PropTypes.string.isRequired,
};
