import PropTypes from 'prop-types';

import { Modal } from 'Components';

import { ModalContent } from './modalContent';

export const DeployReleaseModal = ({ isOpen, handleClose, projectId, releaseFileId }) => (
    <Modal
        isOpened={isOpen}
        handleClose={handleClose}
    >
        <ModalContent
            handleClose={handleClose}
            projectId={projectId}
            releaseFileId={releaseFileId}
        />
    </Modal>
);

DeployReleaseModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    projectId: PropTypes.string,
    releaseFileId: PropTypes.string,
};

DeployReleaseModal.defaultProps = {
    projectId: '',
    releaseFileId: '',
};
