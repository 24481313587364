import { useHistory } from 'react-router-dom';

import { ROUTES } from 'Constants';
import { NoData, Button } from 'Components';

import * as Styled from './styled';

export const NoModels = () => {
    const history = useHistory();

    const redirectToCreateModel = () => {
        history.push(ROUTES.machineLearning.createModel);
    };

    return (
        <>
            <Styled.Wrapper>
                <NoData 
                    caption="Model list is empty"
                    text="There is no machine learning model yet."
                />               
                <Button onClick={redirectToCreateModel}>
                    Add Machine Learning Model
                </Button>
            </Styled.Wrapper>
        </>
    );
};
