import styled from 'styled-components';

export const ModalTitle = styled.div`
    width: 100%;
    height: 50px;
    padding: 15px 20px;
    background-color: ${({ theme }) => theme.colors.title};
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
`;

export const ModalContent = styled.div`
    padding: 15px 20px 30px 25px;
`;

export const Select = styled.div`
    margin-bottom: 15px;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 15px;
`;
