import { useState, useMemo, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import { ThemeContext } from 'styled-components';

import {
    GET_PYMESH_DATA_BY_DEVICE_LIST_QUERY,
    LAST_SEEN_QUERY,
    PYMESH_NODE_ROLES,
} from 'Constants';
import { ReactComponent as RefreshIcon } from 'Assets/icons/refresh.svg';
import { EmptyPymeshView, Loader, Checkbox, Button, Map, TooltipDeviceMarker } from 'Components';
import { getTimeDiff, showToastSuccess } from 'Utils';

import * as Styled from './styled';

const availableRoles = PYMESH_NODE_ROLES.filter((role) => role.show).map((role) => role.label);

export const PymeshLocation = ({ devicesEdges, pymeshDevices }) => {
    const styledTheme = useContext(ThemeContext);

    const deviceTokenList = useMemo(
        () => pymeshDevices.map((device) => device.token),
        [pymeshDevices],
    );

    const [filters, setFilters] = useState(availableRoles);
    const [allSelected, setAllSelected] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);

    const {
        data: pymeshDataReceived,
        loading: isPymeshDataLoading,
        refetch: refetchPymeshData,
    } = useQuery(
        GET_PYMESH_DATA_BY_DEVICE_LIST_QUERY,
        { variables: { deviceTokenList } },
    );
    const pymeshData = pymeshDataReceived?.getPymeshDataByDeviceList ?? [];

    const { data: lastSeenData, loading: isLastSeenLoading, refetch: refetchLastSeen } = useQuery(LAST_SEEN_QUERY);
    const lastSeen = lastSeenData?.lastSeen ?? [];

    useEffect(
        () => {
            if (initialLoading && !isPymeshDataLoading && !isLastSeenLoading) {
                setInitialLoading(false);
            }
        },
        [isPymeshDataLoading, isLastSeenLoading],
    );

    const handleCheckbox = (e) => {
        const { value } = e.target;
        const processedFilters = [...filters];

        if (processedFilters.includes(value)) {
            processedFilters.splice(processedFilters.indexOf(value), 1);
        } else {
            processedFilters.push(value);
        }

        setFilters(processedFilters);
    };

    const hanleSelectAll = () => {
        if (!allSelected) {
            setFilters(availableRoles);
            setAllSelected(true);
        } else {
            setFilters([]);
            setAllSelected(false);
        }
    };

    const refreshData = () => {
        showToastSuccess('Data has been refreshed');
        refetchPymeshData();
        refetchLastSeen();
    };

    const processedDevices = useMemo(
        () => devicesEdges
            .filter((edge) => deviceTokenList.includes(edge.node.token))
            .map((edge) => {
                const device = cloneDeep(edge.node);
                const deviceLastSeen = lastSeen.find((data) => data.deviceToken === device.token);
                if (deviceLastSeen) {
                    device.lastSeen = getTimeDiff(deviceLastSeen.lastSeen);
                }
                device.monitoringData = pymeshData.find((data) => data.token === device.token) || {};
                return device;
            }),
        [devicesEdges, pymeshDevices, lastSeen, pymeshData],
    );

    const renderMetaInfo = (renderData) => <TooltipDeviceMarker {...renderData} />;

    const positions = useMemo(
        () => processedDevices
            .filter((device) => !isEmpty(device.monitoringData))
            .map((device) => ({
                key: device.token,
                role: PYMESH_NODE_ROLES.find((item) => item.role === device.monitoringData.role).label,
                location: {
                    lat: +device.monitoringData.location.latitude,
                    lng: +device.monitoringData.location.longitude,
                    renderMetaInfo: renderMetaInfo.bind(null, {
                        title: device.description,
                        time: device.lastSeen,
                        colorOfTime: styledTheme.colors.active,
                    }),
                },
            })),
        [processedDevices],
    );

    const filteredPositions = useMemo(
        () => positions.filter((item) => filters.includes(item.role)),
        [positions, filters],
    );

    if (initialLoading) {
        return <Loader />;
    }

    if (!positions.length) {
        return (
            <EmptyPymeshView
                title="Pymesh location data is empty"
                message="There is no location data yet, check if the Pymesh was correctly deployed and the nodes have a proper connection"
            />
        );
    }

    return (
        <Styled.Wrapper>
            <Styled.ContentWrapper>
                <Styled.CheckboxBlockWrapper>
                    <h3>Show on the map</h3>
                    <Checkbox
                        key="selectAll"
                        value="All"
                        title="All"
                        id="selectAll"
                        checked={allSelected}
                        handleCheckbox={hanleSelectAll}
                    />
                    {availableRoles.map((item) => (
                        <Checkbox
                            key={item}
                            value={item}
                            title={item}
                            id={item}
                            checked={filters.includes(item)}
                            handleCheckbox={handleCheckbox}
                        />
                    ))}
                    <Button onClick={refreshData}>
                        Refresh
                        <RefreshIcon />
                    </Button>
                </Styled.CheckboxBlockWrapper>
                <Styled.MapWrapper>
                    {!!filteredPositions.length && (
                        <Map location={filteredPositions[0].location} locations={filteredPositions} />
                    )}
                </Styled.MapWrapper>
            </Styled.ContentWrapper>
        </Styled.Wrapper>
    );
};

PymeshLocation.defaultProps = {
    devicesEdges: [],
    pymeshDevices: [],
};

PymeshLocation.propTypes = {
    devicesEdges: PropTypes.arrayOf(PropTypes.object),
    pymeshDevices: PropTypes.arrayOf(PropTypes.object),
};
