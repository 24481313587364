import { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import isFunction from 'lodash/isFunction';

import { GET_STORED_FCOTA, GET_RELEASE_FILES_QUERY } from 'Constants';
import {
    prepareEditorStructure,
    saveMD5ContentSums,
    getDeviceModeInfo,
    setFCOTAHierarchy,
    getReleaseModeInfo,
    clearLocalStorageByCondition,
} from 'Utils';
import { useContextSelector } from './useContextSelector';

export const useSynchronize = (projectKey, handlers) => {
    const client = useApolloClient();
    const [deviceInfo, setDeviceInfo] = useState(null);
    const [releaseInfo, setReleaseInfo] = useState(false);
    const [callback, setCallback] = useState(null);

    const { setStructure, onSyncModalClose, setSyncModalOpen } = handlers;
    const hierarchySlice = useContextSelector('hierarchy');
    const editorSlice = useContextSelector('editor');

    const { finishSynchronization, startSynchronization } = hierarchySlice.handlers;
    const { isOnline, synchronizing, filesSynchronized, filesCount } = hierarchySlice.state;
    const { refreshComplete } = editorSlice.handlers;

    useEffect(() => {
        setDeviceInfo(getDeviceModeInfo(projectKey));
        setReleaseInfo(getReleaseModeInfo(projectKey));
    }, []);

    useEffect(() => {
        if (deviceInfo && synchronizing && filesCount && filesCount === filesSynchronized.length) {
            finishSynchronization();

            const { title, user } = deviceInfo;
            const synchronizedStructure = prepareEditorStructure(title, filesSynchronized, user);
            saveMD5ContentSums(projectKey, filesSynchronized);
            setStructure(synchronizedStructure);
            if (callback) {
                callback(synchronizedStructure);
            }
            refreshComplete();
            onSyncModalClose();
        }
    }, [filesCount, filesSynchronized]);

    const syncHierarchy = async (callBack = null) => {
        if (isOnline) {
            startSynchronization();
            setSyncModalOpen(true);
            if (isFunction(callBack)) {
                setCallback(() => callBack);
            }
        } else if (deviceInfo) {
            const { data: filesData } = await client.query({
                query: GET_STORED_FCOTA,
                variables: { deviceToken: deviceInfo.deviceToken },
            });
            const files = filesData?.getStoredFCOTA?.files ?? [];
            const splitedHierarchy = deviceInfo.hierarchy?.filter(
                (fileName) => fileName?.indexOf('.') > 0 && !fileName.endsWith('.pymakr'),
            );
            const filteredFCOTAFiles = files.filter((file) => splitedHierarchy?.includes(file.path));
            const synchronizedStructure = prepareEditorStructure(deviceInfo.title, filteredFCOTAFiles, deviceInfo.user);
            setFCOTAHierarchy(projectKey, splitedHierarchy);
            saveMD5ContentSums(projectKey, filteredFCOTAFiles);
            setStructure(synchronizedStructure);

            if (callBack) {
                callBack(synchronizedStructure);
            }
        } else if (releaseInfo) {
            const { applicationId, releaseId, title, user } = releaseInfo;
            clearLocalStorageByCondition(projectKey);

            const { data: getReleaseFilesData } = await client.query({
                query: GET_RELEASE_FILES_QUERY,
                variables: { applicationId, releaseId },
            });

            const synchronizedStructure = prepareEditorStructure(title, getReleaseFilesData.getReleaseFiles, user);
            setStructure(synchronizedStructure);

            if (callBack) {
                callBack(synchronizedStructure);
            }
        }
    };

    return syncHierarchy;
};
