import { Collapsible } from 'Components';
import { AccountAccessKey } from './accountAccessKey';

import * as Styled from './styled';

const securityLabels = [
    {
        title: 'Access Key',
        description: 'Access Key allows you to interact with MQTT. Access Keys pair consists of Access ID and Access Key',
    },
];

export const Security = () => (
    <Styled.Wrapper>
        <Collapsible labels={securityLabels} initialOpenedKeys={['1']}>
            <AccountAccessKey />
        </Collapsible>
    </Styled.Wrapper>
);
