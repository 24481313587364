import { useState, useEffect, useMemo } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
//TODO refactor after implement all functional
import {
    pymakrExportStates,
    GET_ZIP_FILE,
    ROUTES,
    CREATE_COMPILATION,
    pymakrCompilationStatuses,
    MURATA_MANUFACTURER,
} from 'Constants';
import { Loader } from 'Components';
import { capitalizeFirstLetter, prepareMappedHierarchy } from 'Utils';

import { useContextSelector } from '../../../pymakrHooks';
import { ExportToRelease } from '../MicroPython';
// import { ExportUploadDevice } from './exports';
import { cleanStorageOfPymakr, downloadZip } from '../helpers';
import * as Styled from './styled';

const { 
    REACT_APP_SUPPORTED_MANUFACTURES, 
    REACT_APP_SEQUANS_MODEL,
    REACT_APP_MURATA_MODEL,
} = process.env;

export const SaveAndExportС = ({ projectKey, title, onClose }) => {
    const [variantsOfManufactures] = useState(JSON.parse(REACT_APP_SUPPORTED_MANUFACTURES));
    const [createCompilationFile, { data: compilationObject, loading: isLoading }] =
        useMutation(CREATE_COMPILATION);
    const [exportState, setExportState] = useState(pymakrExportStates.initial);
    const [selectedManufacture, setSelectedManufacture] = useState(null);

    const history = useHistory();
    const apolloClient = useApolloClient();

    const editorSlice = useContextSelector('editor');
    const { refreshComplete } = editorSlice.handlers;

    const compilationSlice = useContextSelector('compilation');
    const { setCompilationId, setCompilationInitState } = compilationSlice.handlers;
    const { compilationStatus, compilationFile } = compilationSlice.state;

    const compilation = compilationObject?.createCompilation ?? null;

    useEffect(() => {
        if (!isEmpty(compilation)) {
            setCompilationId(compilation?._id);
        }
    }, [compilation]);

    const disabledOtherFunctionality = useMemo(
        () => compilationStatus === pymakrCompilationStatuses.succeeded,
        [compilationStatus],
    );

    const disabledCompilation = useMemo(
        () => compilationStatus === pymakrCompilationStatuses.running,
        [compilationStatus],
    );

    const downloadFiles = async () => {
        const storedData = JSON.parse(localStorage.getItem(projectKey));
        const mappedHierarchy = prepareMappedHierarchy(storedData.hierarchy, 0);

        const { data } = await apolloClient.query({
            query: GET_ZIP_FILE,
            variables: {
                hierarchy: mappedHierarchy,
            },
        });

        return data?.getZipFileFromHierarchy ?? '';
    };
    
    const compileProject = async (manufacturer) => {
        setSelectedManufacture(manufacturer);
        const storedData = JSON.parse(localStorage.getItem(projectKey));
        const mappedHierarchy = prepareMappedHierarchy(storedData.hierarchy, 0);
        const model = manufacturer === MURATA_MANUFACTURER ? REACT_APP_MURATA_MODEL : REACT_APP_SEQUANS_MODEL;
        
        try {
            await createCompilationFile({
                variables: {
                    hierarchy: mappedHierarchy,
                    options: {
                        model,
                        manufacturer,
                    },
                },
            });
        } catch (error) {
            console.warn(error);
        } finally {
            onClose();
        }
    };

    const onSelectProject = async (project) => {
        const releaseParams = JSON.stringify({ compilationFile });
        cleanStorageOfPymakr(projectKey, refreshComplete);
        localStorage.setItem('pymakr-release-file', releaseParams);
        setExportState(pymakrExportStates.download);
        history.push(
            `${ROUTES.projects.main}/${project._id}${ROUTES.projects.projectDetails.releaseCreate}`,
        );
    };

    const onZipDownload = async () => {
        const file64 = await downloadFiles();
        downloadZip(file64, title);
        cleanStorageOfPymakr(projectKey, refreshComplete);
        setCompilationInitState();
        onClose();
    };

    switch (exportState) {
        case pymakrExportStates.initial:
            return (
                <Styled.Wrapper>
                    <h2>How do you want to export your project?</h2>
                    <Styled.OptionBtn
                        buttonType="transparent"
                        onClick={() =>
                            setExportState(
                                pymakrExportStates.releaseSelectProject,
                            )}
                        disabled={!disabledOtherFunctionality}
                    >
                        Include in Release
                    </Styled.OptionBtn>
                    {variantsOfManufactures.length === 1 && (
                        <Styled.OptionBtn
                            buttonType="transparent"
                            onClick={() => compileProject(variantsOfManufactures[0])}
                        >
                            Compile project
                        </Styled.OptionBtn>
                    )}
                    {variantsOfManufactures.length > 1 && (
                        <Styled.OptionCollapsible
                            destroyInactivePanel
                            expandIconPosition="right"
                        >
                            <Styled.OptionPanel header="Compile project">
                                {variantsOfManufactures.map((manufacturer) => (
                                    <Styled.OptionBtnSmall
                                        key={manufacturer}
                                        buttonType="transparent"
                                        disabled={disabledCompilation}
                                        loading={selectedManufacture === manufacturer && isLoading}
                                        onClick={() => compileProject(manufacturer)}
                                    >
                                        Compile with {capitalizeFirstLetter(manufacturer.toLowerCase())}
                                    </Styled.OptionBtnSmall>
                                ))}
                            </Styled.OptionPanel>
                        </Styled.OptionCollapsible>
                    )}
                    <Styled.OptionBtn
                        buttonType="transparent"
                        onClick={onZipDownload}
                    >
                        Create and download Zip file
                    </Styled.OptionBtn>
                    {/* <Styled.OptionBtn
                        buttonType="transparent"
                        onClick={() =>
                            setExportState(pymakrExportStates.upload)}
                        disabled={!disabledOtherFunctionality}
                    >
                        Upload to Device
                    </Styled.OptionBtn> */}
                </Styled.Wrapper>
            );
        case pymakrExportStates.releaseSelectProject:
            return <ExportToRelease onSelectProject={onSelectProject} />;
        case pymakrExportStates.download:
            return <Loader title="Generating files..." />;
        // case pymakrExportStates.upload:
        //     return (
        //         <ExportUploadDevice
        //             onClose={onClose}
        //             file={compilationFile}
        //         />
        //     );
        default:
            return <div />;
    }
};

SaveAndExportС.propTypes = {
    projectKey: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};
