import PropTypes from 'prop-types';

import { Alert } from 'Components';

import * as Styled from '../../../../styled';

export const AlertMessage = ({ type, title, text }) => {
    const message = (
        <>
            <div>
                {title}
            </div>
            <div>
                {text}
            </div>
        </>
    );

    return (
        <Styled.FormAlertSection>
            <Alert
                type={type}
                message={message}
            />
        </Styled.FormAlertSection>
    );
};

AlertMessage.propTypes = {
    type: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
};

AlertMessage.defaultProps = {
    type: 'warning',
    title: '',
    text: '',
};
