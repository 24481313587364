import styled from 'styled-components';

export const Error = styled.div`
    width: 100%;
    padding: 20px;
    background: ${({ theme }) => theme.colors.boxBackground};
    border: 1px solid ${({ theme }) => theme.colors.border};
    color: ${({ theme }) => theme.colors.title};
`;

export const Title = styled.div`
    margin-bottom: 5px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
`;

export const Description = styled.div`
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 17px;
`;
