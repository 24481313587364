import styled from 'styled-components';
import { Tabs as AntTabs } from 'antd';

export const Tabs = styled(AntTabs)`
    .ant-tabs-nav-wrap {
        margin-top: 20px;
        max-width: 650px;
    }

    .ant-tabs-nav::before {
        display: none;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: ${({ theme }) => theme.colors.active} !important;
    }

    .ant-tabs-tab {
        font-weight: bold;
    }

    .ant-tabs-tab:active {
        color: ${({ theme }) => theme.colors.active} !important;
    }

    .ant-tabs-tab-btn:focus {
        color: ${({ theme }) => theme.colors.active} !important;
    }

    .ant-tabs-tab:hover {
        color: ${({ theme }) => theme.colors.active} !important;
    }

    .ant-tabs-tab-btn {
        font-weight: bold;
    }

    .ant-tabs-ink-bar {
        background-color: ${({ theme }) => theme.colors.active} !important;
    }
`;

export const Wrapper = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
`;
