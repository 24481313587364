import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/client';

import { TEST_AZURE_CONNECTION_QUERY } from 'Constants';
import { showToastError, showToastSuccess } from 'Utils';

import { initialValues, validationSchema } from './config';
import { FormContent } from './formContent';

export const AzureLogin = ({ setConnectionString, nextStep }) => {
    const apolloClient = useApolloClient();

    const onSubmit = async (values) => {
        try {
            const { data } = await apolloClient.query({
                query: TEST_AZURE_CONNECTION_QUERY,
                variables: { iotHubString: values.connectionString },
                fetchPolicy: 'network-only',
            });

            if (!data.testConnection) {
                throw new Error('Connection denied');
            }

            setConnectionString(values.connectionString);
            showToastSuccess('Connected to Azure');

            nextStep();
        } catch (error) {
            showToastError(`Connection failed: ${error.message}`);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            component={FormContent}
        />
    );
};

AzureLogin.propTypes = {
    setConnectionString: PropTypes.func.isRequired,
    nextStep: PropTypes.func.isRequired,
};
