import {
    LORA,
    LORA_CONSTANTS,
    LORA_OTAA,
    LORA_ABP,
} from 'Constants';

const getLteName = (arrayOfLte, id) =>
    arrayOfLte.find((item) => item._id === id).name;

const getChangedNetworks = (networksArr, loraType) => networksArr.map((item) => {
    if (item === LORA) {
        return loraType === LORA_CONSTANTS.RADIO_BUTTONS[0].value
            ? LORA_OTAA
            : LORA_ABP;
    }
    return item;
});

export const prepareForSave = (deviceConfig, lteList) => {
    const { customData, device, coModule, shield, networksConfig } = deviceConfig;
    const { name } = customData;
    const { wifiId, lteId, loraServer, loraRegion, loraType, ethernet } = networksConfig;
    const deviceTemplate = device || coModule;

    const resultData = {
        description: name,
        deviceType: deviceTemplate.type,
        networks: getChangedNetworks(networksConfig.networks, loraType),
        shield: {
            type: shield?.type || null,
            gateway: null,
        },
    };

    if (deviceConfig.pygateLora) {
        resultData.shield.gateway = {
            loraProvider: deviceConfig.pygateLora.loraProvider,
            loraRegion: deviceConfig.pygateLora.loraRegion,
        };
    }

    if (wifiId) {
        resultData.wifiId = wifiId;
    }

    if (lteId) {
        resultData.lte = getLteName(lteList, lteId);
    }

     if (ethernet) {
         resultData.ethernet = ethernet;
     }

    if (loraServer) {
        resultData.loraServer = loraServer;
        resultData.loraRegion = loraRegion;
    }

    return resultData;
};
