import PropTypes from 'prop-types';
import { Form, Field, FieldArray } from 'formik';

import { TextInput, Button } from 'Components';

import * as Styled from '../../../../styled';
import { LayersInput } from '../layersInput';

export const FormContent = ({ loading, isValid }) => (
    <Form>
        <Styled.ItemInput>
            <Styled.ItemLabel>
                Training Epochs
            </Styled.ItemLabel>
            <Field
                name="trainingEpochs"
                component={TextInput}
                placeholder="Enter with the training cycles"
                type="number"
            />
        </Styled.ItemInput>
        <Styled.ItemInput>
            <Styled.ItemLabel>
                Learning Rate
            </Styled.ItemLabel>
            <Field
                name="learningRate"
                component={TextInput}
                placeholder="Enter with the learning rate"
                type="number"
                step={0.0001}
            />
        </Styled.ItemInput>
        <Styled.ItemInput>
            <Styled.ItemLabel>
                Confidence Threshold
            </Styled.ItemLabel>
            <Field
                name="minConfidenceThreshold"
                component={TextInput}
                placeholder="Enter with the minimum confidence rating"
                type="number"
                step={0.1}
            />
        </Styled.ItemInput>
        <h2>NN Layers</h2>
        <Styled.ItemInput>
            <FieldArray
                name="layers"
                component={LayersInput}
            />
        </Styled.ItemInput>
        <Button
            type="submit"
            disabled={!isValid}
            loading={loading}
        >
            Start training
        </Button>
    </Form>
);

FormContent.propTypes = {
    isValid: PropTypes.bool.isRequired,
    loading: PropTypes.bool,
};

FormContent.defaultProps = {
    loading: false,
};
