import PropTypes from 'prop-types';

import * as Styled from './styled';

export const CustomTag = ({ title, fill, border }) => (
    <Styled.Tag $fill={fill} $border={border}>
        {title}
    </Styled.Tag>
);

CustomTag.propTypes = {
    title: PropTypes.string.isRequired,
    fill: PropTypes.string,
    border: PropTypes.bool,
};

CustomTag.defaultProps = {
    fill: null,
    border: false,
};
