import styled from 'styled-components';

export const Body = styled.p`
    text-align: justify;
    overflow: auto;
    padding: 5px;
    width: 700px;
    height: 310px;
    letter-spacing: 0.44px;
    color: #434a4b;
    border: solid 1px ${({ theme }) => theme.colors.border};
    background-color: ${({ theme }) => theme.colors.boxBackground};
    margin-bottom: 15px;
`;
