import PropTypes from 'prop-types';

import { Select } from 'Components';

import * as Styled from './styled';

export const ItemList = ({
    title,
    description,
    image,
    id,
    url,
    setSelectedItem,
    isChecked,
    branches,
    setSelectedBranch,
    selectedBranch,
}) => {
    const handleSelectBranch = (branch) => {
        setSelectedBranch(branch);
    };

    const handleSelect = () => {
        setSelectedItem(id);
    };

    return (
        <Styled.Wrapper onClick={handleSelect} checked={isChecked} id={id}>
            <Styled.Image src={image} />
            <Styled.Content>
                <Styled.Title href={url} target="blank">
                    {title}
                </Styled.Title>
                <Styled.Description>{description}</Styled.Description>
            </Styled.Content>
            {isChecked && branches && (
                <Styled.Branch>
                    <Styled.BranchTitle>Branch: </Styled.BranchTitle>
                    <Select
                        custom
                        values={branches}
                        handleSelect={handleSelectBranch}
                        selectedItem={selectedBranch?.value}
                        placeholder="Select branch"
                    />
                </Styled.Branch>
            )}
        </Styled.Wrapper>
    );
};

ItemList.propTypes = {
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
    setSelectedItem: PropTypes.func.isRequired,
    setSelectedBranch: PropTypes.func.isRequired,
    description: PropTypes.string,
    isChecked: PropTypes.bool,
    branches: PropTypes.array,
    selectedBranch: PropTypes.object,
};

ItemList.defaultProps = {
    description: '',
    isChecked: false,
    branches: null,
    selectedBranch: {},
};
