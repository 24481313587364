export const DEVICE_FIRMWARE_UNKNOWN = 'Unknown';

export const FIRMWARE_STATUS = {
    NO_INFORMATION: 'No Information',
    UPDATING: 'Updating',
    UP_TO_DATE: 'Up To Date',
    UPDATE_AVAILABLE: 'Update Available',
};

export const getDeviceFirmwareStatus = (currentFirmwareVersion, firmwareLatestVersion) => {
    const { version: currentVersion, intVersion: currentIntVersion } = currentFirmwareVersion;
    
    let status = FIRMWARE_STATUS.UP_TO_DATE;

    if (currentVersion === DEVICE_FIRMWARE_UNKNOWN) {
        status = FIRMWARE_STATUS.NO_INFORMATION;        
    } else if (firmwareLatestVersion?.intVersion > currentIntVersion) {
        status = FIRMWARE_STATUS.UPDATE_AVAILABLE;
    }
    return status;
};
