import styled from 'styled-components';

export const Field = styled.input`
    width: 293px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    padding: 9px 35px 9px 15px;
    color: ${({ theme }) => theme.colors.text};
    font-size: 14px;
    line-height: 1.57;

    ${({ type, theme }) => type === 'password' && `
        font: small-caption;
        font-size: 36px;
        color: ${theme.colors.border};
    `}

    &:disabled {
        border: transparent;
        cursor: not-allowed;
        user-select: none;
    }
`;

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    height: 40px;
    position: relative;
`;

export const Error = styled.span`
    position: absolute;
    transition: bottom 0.4ms linear;
    color: ${({ theme }) => theme.colors.error};
    font-size: 12px;

    ${({ $isAnimated }) => $isAnimated && `
        bottom: -15px;
    `}
`;

export const Icon = styled.div`
    position: absolute;
    top: 50%;
    left: 254px;
    height: 34px;
    width: 34px;
    transform: translateY(-50%);
    cursor: pointer;

    ${({ disabled }) => disabled && `
        cursor: not-allowed;
        pointer-events: none;
    `}
`;
