import { PropTypes } from 'prop-types';
import { Notifications, UserProfile } from './components';
import { Logo } from '../logo';
import * as Styled from './styled';

export const Header = ({ showLogo }) => (
    <Styled.Header>
        {showLogo ? (
            <Styled.Logo>
                <Logo />
            </Styled.Logo>
        ) : (
            <div> </div>
        )}
        <Styled.Menu>
            <Notifications />
            <UserProfile />
        </Styled.Menu>
    </Styled.Header>
);

Header.propTypes = {
    showLogo: PropTypes.bool,
};

Header.defaultProps = {
    showLogo: false,
};
