import { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ThemeContext } from 'styled-components';
import {
    ComposedChart,
    Area,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Brush,
} from 'recharts';

import { TIME_SCALE_LABELED, ALL_DEVICES } from 'Constants';
import { generateColors, getChartTicks } from 'Utils';

import { EmptyWidget } from '../emptyWidget';
import { ChartSettings, ChartLayoutSettings } from '../chartSettings';

import * as StyledGeneral from '../styled';
import * as Styled from './styled';

const defaultTimeUnitsLabeled = TIME_SCALE_LABELED[1];

export const LineChartWidget = ({ data, isEmpty, title, deviceNames, setSelectedPullSize, defaultPullSize, pin }) => {
    const styledTheme = useContext(ThemeContext);

    const [drawGrid, setDrawGrid] = useState(false);
    const [steppedLine, setSteppedLine] = useState(false);
    const [fillArea, setFillArea] = useState(false);
    const [displayPoints, setDisplayPoints] = useState(false);

    const [selectedDevice, setSelectedDevice] = useState(ALL_DEVICES);
    const [selectedTimeUnit, setSelectedTimeUnit] = useState(defaultTimeUnitsLabeled);

    const colors = useMemo(() => generateColors(deviceNames, styledTheme.colors.active), [deviceNames]);

    const chartData = useMemo(() => {
        if (selectedDevice.value === ALL_DEVICES.value) {
            return data;
        }
        return data.filter((item) => item.hasOwnProperty(selectedDevice.value));
    }, [data, selectedDevice]);

    const chartDeviceNames = useMemo(() => {
        if (selectedDevice.value === ALL_DEVICES.value) {
            return deviceNames;
        }
        return [deviceNames.find((deviceName) => deviceName === selectedDevice.value)];
    }, [deviceNames, selectedDevice]);

    const tickOptions = useMemo(() =>
        getChartTicks(chartData, selectedTimeUnit.value),
        [chartData, selectedTimeUnit]);

    const CustomTooltip = (parameters) => {
        if (parameters.active) {
            const { payload, label } = parameters;
            const payloadData = payload[0];

            return (
                <div
                    style={{
                        padding: '13px 70px 8px 17px',
                        fontSize: '12px',
                        lineHeight: '1.5',
                        backgroundColor: 'white',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                        borderRadius: '5px',
                    }}
                >
                    <div style={{ fontWeight: 'bold' }}>{payloadData.name}</div>
                    <div style={{ color: styledTheme.colors.active }}>
                        {moment(new Date(label)).format('DD:MM:YY h:mm:s A')}
                    </div>
                    <div>Signal: {pin}</div>
                    <div>Value: {payloadData.value}</div>
                </div>
            );
        }

        return null;
    };

    const getChartProperties = (chartItem) => ({
        dataKey: chartItem,
        key: chartItem,
        stroke: colors[chartItem],
        connectNulls: true,
        type: steppedLine ? 'step' : 'linear',
        dot: displayPoints ? { fill: colors[chartItem], r: 4 } : null,
    });

    const getChartType = (chartItem) => {
        if (fillArea) {
            return (
                <div key={chartItem}>
                    <defs>
                        <linearGradient id={chartItem} gradientTransform="rotate(90)">
                            <stop offset="0%" stopColor={colors[chartItem]} stopOpacity="0.15" />
                            <stop offset="100%" stopColor="#fff" stopOpacity="0" />
                        </linearGradient>
                    </defs>
                    <Area
                        {...getChartProperties(chartItem)}
                        fill={`url('#${chartItem}')`}
                    />
                </div>
            );
        }
        return <Line {...getChartProperties(chartItem)} />;
    };

    const getChartLayoutSettings = () => (
        <ChartLayoutSettings
            drawGrid={drawGrid}
            steppedLine={steppedLine}
            fillArea={fillArea}
            displayPoints={displayPoints}
            setDrawGrid={setDrawGrid}
            setSteppedLine={setSteppedLine}
            setFillArea={setFillArea}
            setDisplayPoints={setDisplayPoints}
        />
    );

    if (isEmpty) {
        return <EmptyWidget title={title} />;
    }

    return (
        <StyledGeneral.WidgetWrapper>
            <StyledGeneral.ChartTitle>
                {title}
            </StyledGeneral.ChartTitle>
            <ChartSettings
                chartLayoutSettings={getChartLayoutSettings()}
                setSelectedDevice={setSelectedDevice}
                setSelectedTimeUnit={setSelectedTimeUnit}
                setSelectedPullSize={setSelectedPullSize}
                deviceNames={deviceNames}
                defaultPullSize={defaultPullSize}
                defaultTimeScale={selectedTimeUnit}
            />
            <Styled.CustomLineChartStyle />
            <ResponsiveContainer
                width="100%"
                height="100%"
                className="line-chart-responsive-container-wrapper"
                debounce={300}
            >
                <ComposedChart data={chartData}>
                    {drawGrid && (
                        <CartesianGrid
                            strokeDasharray="4 3"
                            stroke="#ECF0F4"
                        />
                    )}
                    <XAxis
                        dataKey="date"
                        type="number"
                        domain={['dataMin', 'dataMax']}
                        stroke="#ECF0F4"
                        tickLine={false}
                        tick={{ fill: '#C0C7CE', fontSize: 14 }}
                        scale="time"
                        tickFormatter={(v) => moment(Number(v)).format(tickOptions.tickFormatter)}
                        ticks={tickOptions.ticks}
                    />
                    <YAxis
                        stroke="#ECF0F4"
                        tickLine={false}
                        tick={{ fill: '#C0C7CE', fontSize: 14 }}
                    />
                    <Tooltip
                        content={<CustomTooltip />}
                        cursor={false}
                    />
                    <Legend
                        verticalAlign="top"
                        align="left"
                        iconType="circle"
                        iconSize={14}
                        height={45}
                    />
                    <Brush
                        height={5}
                        travellerWidth={5}
                        endIndex={Math.round((chartData.length / 100) * 25)}
                        fill="currentColor"
                        stroke="currentColor"
                        fillOpacity="1 !important"
                        tickFormatter={() => { }}
                    />
                    {chartDeviceNames.map((item) => getChartType(item))}
                </ComposedChart>
            </ResponsiveContainer>
        </StyledGeneral.WidgetWrapper>
    );
};

LineChartWidget.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        date: PropTypes.number.isRequired,
    })).isRequired,
    deviceNames: PropTypes.arrayOf(PropTypes.string).isRequired,
    title: PropTypes.node.isRequired,
    setSelectedPullSize: PropTypes.func.isRequired,
    defaultPullSize: PropTypes.number.isRequired,
    pin: PropTypes.number.isRequired,
    isEmpty: PropTypes.bool,
};

LineChartWidget.defaultProps = {
    isEmpty: false,
};
