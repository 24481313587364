import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Wrapper = styled.div`
    margin-top: 15px;
    position: relative;
`;

export const List = styled.div`
    position: absolute;
    display: flex;
    width: fit-content;
    right: 0;
    top: 10px;
`;

export const Item = styled(NavLink)`
    display: flex;
    align-items: center;
    padding: 0 10px ;
    color: ${({ theme }) => theme.colors.title};
    font-size: 18px;
    font-weight: bold;
    border-right: 1px solid ${({ theme }) => theme.colors.border};

    svg {
        width: 18px;
        height: 18px;
        margin-right: 10px;
        margin-bottom: 3px;
    }

    &:last-child {
        margin-right: 0;
        border-right: none;
    }

    &:hover {
        color: ${({ theme }) => theme.colors.active};
    }

    &.active {
        color: ${({ theme }) => theme.colors.active};
    }
`;
