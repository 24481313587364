import styled from 'styled-components';

export const Title = styled.div`
    color: ${({ theme }) => theme.colors.title};
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: bold;
`;

export const NotificationWrapper = styled.div`
    margin-bottom: 10px;
`;

export const List = styled.ul`
    padding-left: 30px;
    list-style: disc;
`;

export const FormItem = styled.div`
    margin-bottom: 10px;
    max-width: 640px;

    textarea {
        width: 100%;
    }
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    margin-top: 55px;

    
    & > * {
        margin-right: 30px;
    }
`;
