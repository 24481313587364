import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { GET_APPLICATION_LAST_MESSAGES } from 'Constants';
import { getStringifiedPayload } from 'Utils';
import { useMessageSubscription } from 'Hooks';

import { SignalQualityWidget } from '../../../../widgets';

export const SignalQuality = ({ chartId, title }) => {
    const { data, loading, refetch } = useQuery(
        GET_APPLICATION_LAST_MESSAGES,
        {
            variables: { chartId },
        },
    );
    const lastMessages = data?.getApplicationLastMessages ?? [];

    const subscribeData = useMemo(() => {
        const deviceTokens = lastMessages.map((messageObj) => messageObj.device.token);
        const messagePin = lastMessages[0]?.lastMessage?.messagePin;

        return { deviceTokens, messagePin };
    }, [lastMessages]);

    useMessageSubscription(subscribeData.deviceTokens, subscribeData.messagePin, refetch);

    const signalQuality = useMemo(
        () => (lastMessages.length
            ? Number(getStringifiedPayload(lastMessages[0].lastMessage.payload))
            : null),
        [lastMessages],
    );

    return (
        <SignalQualityWidget
            data={signalQuality}
            title={title}
            isEmpty={loading || (!signalQuality && signalQuality !== 0)}
        />
    );
};

SignalQuality.propTypes = {
    chartId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};
