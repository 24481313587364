import { useState } from 'react';
import { ContextMenu } from 'Components';

export const useContextMenu = ({ parent, functionsSet }) => {
    const [showContextMenu, setShowContextMenu] = useState(false);
    const [menuType, setMenuType] = useState(null);
    const [anchor, setAnchor] = useState({ x: 0, y: 0 });

    const {
        createFolder,
        createFile,
        renameItem,
        initDelete,
    } = functionsSet;

    const contextMenus = {
        primary: [
            {
                title: 'Create Folder',
                action: createFolder,
            },
            {
                title: 'Create File',
                action: createFile,
            },
        ],
        casual: [
            {
                title: 'Create Folder',
                action: createFolder,
            },
            {
                title: 'Create File',
                action: createFile,
            },
            {
                title: 'Rename Folder',
                action: renameItem,
            },
            {
                title: 'Remove Folder',
                action: initDelete,
            },
        ],
        file: [
            {
                title: 'Rename file',
                action: renameItem,
            },
            {
                title: 'Delete file',
                action: initDelete,
            },
        ],
    };

    const onClose = (e) => {
        e.preventDefault();
        setShowContextMenu(false);
    };

    const setContext = (menu, clickAnchor) => {
        setMenuType(menu);
        setAnchor(clickAnchor);
        setShowContextMenu(true);
    };

    const contextMenu = (
        <ContextMenu
            menu={contextMenus[menuType]}
            anchorY={anchor.y}
            anchorX={anchor.x}
            parentRef={parent}
            onClose={onClose}
        />
    );

    return { setContext, contextMenu: showContextMenu ? contextMenu : null };
};
