import { Form, Field } from 'formik';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { Button, Select, Checkbox, BaseFileUploader, Spin } from 'Components';
import { SoftServerService } from 'Services';
import {
    SPEEDS_RATES,
    firmwareTypesOptions,
    FIRMWARE_TYPES_VERSIONS,
    fileSystemOptions,
    FILESYSTEM_TYPES_OBJECT,
    ZIP_EXTENSIONS,
} from 'Constants';

import * as Styled from './styled';

export const FormContent = ({
    values,
    firmwareData,
    getDeviceInfo,
    setFieldValue,
    backStep,
    isValid,
    setFieldError,
    getCustomFile,
    removeFile,
    isLoaded,
    rescanPorts,
    handleReset,
}) => {
    const [firmwareList, setFirmwareList] = useState([]);
    const [allowedFileSystem, setAllowedFileSystem] = useState(fileSystemOptions);

    const handleRegion = () => {
        const [, region] = values.loraCountry?.split('_');
        setFieldValue('loraRegion', region);
    };

    const checkAllowedFileSystem = async () => {
        if (values.firmwareVersion?.length) {
            const { fatfs, lfs } = await JSON?.parse(values.firmwareVersion);

            if (fatfs !== undefined && fatfs !== 1) {
                const result = fileSystemOptions.filter(
                    (item) => item.value === FILESYSTEM_TYPES_OBJECT.FATFS,
                );
                setAllowedFileSystem(result);
            }
            if (lfs !== undefined && lfs !== 1) {
                const result = fileSystemOptions.filter(
                    (item) => item.value === FILESYSTEM_TYPES_OBJECT.LFS,
                );
                setAllowedFileSystem(result);
            }
        }
    };

    const resetHandle = () => {
        handleReset();
        rescanPorts();
    };

    const createFirmwareList = async () => {
        if (FIRMWARE_TYPES_VERSIONS.includes(values.firmwareType)) {
            const arrayOfFirmware = await SoftServerService.getListOfFirmwares(
                //TODO the same as in pybytes-react
                '',
                values.firmwareType.toLowerCase(),
            );
            const result = arrayOfFirmware.map((item) => ({
                label: item.version,
                value: JSON.stringify(item),
            }));
            setFirmwareList(result);
            setFieldValue('firmwareVersion', result[0].value);
        }
        if (values.firmwareType === 'Custom') {
            if (firmwareData.customFile.url?.length) {
                setFieldValue(
                    'firmwareVersion',
                    JSON.stringify({ version: 'Unknown' }),
                );
            } else {
                setFieldValue('firmwareVersion', '');
            }
        }
    };

    useEffect(() => {
        if (!isEmpty(values.port)) {
            getDeviceInfo(values.port);
        }
    }, [values.port]);

    useEffect(() => {
        setFieldValue('deviceType', firmwareData.deviceType?.toUpperCase());
    }, [firmwareData.deviceType]);

    useEffect(() => {
        setFieldValue('loraRegion', firmwareData.region);
    }, [firmwareData.region]);

    useEffect(() => {
        handleRegion();
    }, [values.loraCountry]);

    useEffect(() => {
        createFirmwareList();
    }, [values.firmwareType, firmwareData.customFile]);

    useEffect(() => {
        checkAllowedFileSystem();
    }, [values.firmwareVersion]);

    useEffect(() => {
        if (values.firmwareType !== 'Custom') {
            removeFile();
        }
    }, [values.firmwareType]);

    useEffect(() => {
        const [, region] = values.loraCountry?.split('_');
        if (region !== values.region) {
            setFieldError('loraCountry', 'Select another country for region');
        }
    }, [values.loraRegion]);

    return (
        <Form>
            <Styled.Form>
                <Styled.FormLeft>
                    <Styled.FormItem>
                        <Styled.FormTitle>Serial Port</Styled.FormTitle>
                        <Styled.FormButton>
                            <Field
                                name="port"
                                component={Select}
                                values={firmwareData.ports}
                                placeholder="Select serial port"
                            />
                            <Button onClick={resetHandle}>
                                Rescan
                            </Button>
                        </Styled.FormButton>
                    </Styled.FormItem>
                    {values.port && (
                        <>
                            <Styled.FormItem>
                                <Styled.FormTitle>Speed</Styled.FormTitle>
                                <Field
                                    name="speed"
                                    component={Select}
                                    values={SPEEDS_RATES}
                                />
                            </Styled.FormItem>
                            <Styled.FormItem>
                                <Styled.FormTitle>Device Type</Styled.FormTitle>
                                <Field
                                    disabled
                                    name="deviceType"
                                    component={Select}
                                    values={firmwareData.devicesToForm}
                                />
                            </Styled.FormItem>
                            {firmwareData.enableSelectRegion && (
                                <>
                                    <Styled.FormItem>
                                        <Styled.FormTitle>
                                            Lora Country
                                        </Styled.FormTitle>
                                        <Field
                                            name="loraCountry"
                                            component={Select}
                                            values={
                                                firmwareData.countriesForForm
                                            }
                                            placeholder="Select Country"
                                        />
                                    </Styled.FormItem>

                                    <Styled.FormItem>
                                        <Styled.FormTitle>
                                            Lora Regions
                                        </Styled.FormTitle>
                                        <Field
                                            name="loraRegion"
                                            component={Select}
                                            values={firmwareData.regionList}
                                        />
                                    </Styled.FormItem>
                                </>
                            )}
                        </>
                    )}
                </Styled.FormLeft>
                {values.deviceType &&
                    values.port &&
                    !firmwareData.errorEqualDevice && (
                        <>
                            <div>
                                <Styled.FormItem>
                                    <Styled.FormTitle>
                                        Firmware Type
                                    </Styled.FormTitle>
                                    <Field
                                        disabled={isEmpty(values.deviceType)}
                                        name="firmwareType"
                                        component={Select}
                                        values={firmwareTypesOptions}
                                        placeholder="Select firmware type"
                                    />
                                </Styled.FormItem>
                                {FIRMWARE_TYPES_VERSIONS.includes(
                                    values.firmwareType,
                                ) && (
                                    <Styled.FormItem>
                                        <Styled.FormTitle>
                                            Firmware Version
                                        </Styled.FormTitle>
                                        <Field
                                            name="firmwareVersion"
                                            component={Select}
                                            values={firmwareList}
                                            placeholder="Select firmware version"
                                        />
                                    </Styled.FormItem>
                                )}
                                {values.firmwareType === 'Custom' && (
                                    <Styled.FormItem>
                                        <Styled.FormTitle>
                                            Upload File
                                        </Styled.FormTitle>
                                        <BaseFileUploader
                                            expectedExtensions={`${ZIP_EXTENSIONS.join(', ')}, .gzip`}
                                            onChange={getCustomFile}
                                        />
                                    </Styled.FormItem>
                                )}
                                <Styled.FormItem>
                                    <Styled.FormTitle>
                                        File System
                                    </Styled.FormTitle>
                                    <Field
                                        name="fileSystem"
                                        component={Select}
                                        values={allowedFileSystem}
                                        placeholder="Select file system"
                                    />
                                </Styled.FormItem>
                                <Styled.Checkboxes>
                                    <Field
                                        component={Checkbox}
                                        name="effs"
                                        title="Erase flash file system"
                                    />
                                    <Field
                                        component={Checkbox}
                                        name="envs"
                                        title="Erase NVS"
                                    />
                                </Styled.Checkboxes>
                            </div>
                        </>
                    )}
            </Styled.Form>
            <Spin spinning={isLoaded} />
            <Styled.ButtonsWrapper>
                <Button buttonType="transparent" onClick={backStep}>
                    Back
                </Button>
                <Button type="submit" disabled={!isValid}>
                    Next
                </Button>
            </Styled.ButtonsWrapper>
        </Form>
    );
};

FormContent.propTypes = {
    values: PropTypes.object.isRequired,
    firmwareData: PropTypes.object.isRequired,
    getDeviceInfo: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    backStep: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    setFieldError: PropTypes.func.isRequired,
    getCustomFile: PropTypes.func.isRequired,
    removeFile: PropTypes.func.isRequired,
    isLoaded: PropTypes.bool.isRequired,
    rescanPorts: PropTypes.func.isRequired,
    handleReset: PropTypes.func.isRequired,
};
