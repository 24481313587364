import crypto from 'crypto';
import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import { PYMESH_REGIONS, PYMESH_BANDWIDTHS, PYMESH_SPREAD_FACTORS } from 'Constants';

import { Select } from '../../../select';
import { TextInput } from '../../../inputs';
import { Button } from '../../../buttons';

import { SelectSectionField } from './selectSectionField';
import { tooltipTitles } from './tooltipsInfo';
import { getInterval, formatJoinKey } from './helpers';
import * as Styled from './styled';

export const PymeshNetworkSettingsFormContent = ({ setFieldValue, dirty, values }) => {
    const generateJoinKey = () => {
        const key = crypto.randomBytes(16).toString('hex');
        const formattedJoinKey = formatJoinKey(key);

        setFieldValue('joinKey', formattedJoinKey);
    };

    useEffect(
        () => {
            if (dirty) {
                const interval = getInterval(values.region);
                setFieldValue('frequency', interval[0].min.toFixed(0));
            }
        },
        [values.region],
    );

    useEffect(
        () => {
            const formattedJoinKey = formatJoinKey(values.joinKey);
            setFieldValue('joinKey', formattedJoinKey);
        },
        [values.joinKey],
    );

    const frequencyToolTip = useMemo(() => {
        const interval = getInterval(values.region);
        const singleRange = interval.length === 1;

        return (
            <div style={{ padding: '5px 10px' }}>
                <p>
                    {interval.length} FREQUENCY RANGE{`${singleRange ? '' : 'S'}`}:
                </p>
                {tooltipTitles.frequency}
                {
                    singleRange
                        ? <p> Available range for the selected region is: </p>
                        : <p> The ranges for the selected region are: </p>
                }
                <div>
                    {interval.map((range, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <div key={index} style={{ fontSize: '16px' }}>
                            {`- ${range.min.toFixed(3)} ... ${range.max.toFixed(3)}`}
                        </div>
                    ))}
                </div>
            </div>
        );
    }, [values.region]);

    return (
        <Styled.Content>
            <Styled.SelectSection>
                <SelectSectionField
                    title="Region"
                    tooltip={tooltipTitles.region}
                    name="region"
                    values={PYMESH_REGIONS.map((regionInfo) => regionInfo.region.name)}
                    component={Select}
                />
                <SelectSectionField
                    title="Frequency"
                    tooltip={frequencyToolTip || tooltipTitles.frequency}
                    name="frequency"
                    type="text"
                    placeholder="Enter with the frequency"
                    component={TextInput}
                    extension="MHz"
                    errorPosition="bottom"
                />
                <SelectSectionField
                    title="Bandwidth"
                    tooltip={tooltipTitles.bandwidth}
                    name="bandwidth"
                    values={PYMESH_BANDWIDTHS.map((pymeshBandwidth) => pymeshBandwidth.label)}
                    component={Select}
                    extension="kHz"
                />
                <SelectSectionField
                    title="Spread factor"
                    tooltip={tooltipTitles.region}
                    name="spreadFactor"
                    values={PYMESH_SPREAD_FACTORS}
                    component={Select}
                />
            </Styled.SelectSection>
            <Styled.JoinKeyBox>
                <Styled.JoinKeyTitle>
                    Join Key
                </Styled.JoinKeyTitle>
                <p>
                    Each node in a Pymesh is using the same Join key,
                    as an identification and verification feature. A join key is
                    a 128 bit string which you can make up by yourself of generate
                    with our Pybytes generator.
                </p>
                <p>
                    Generate or type your Pymesh Join Key in the field below
                    in order to bend all selected devices.
                </p>
                <Styled.JoinKeyFieldWrapper>
                    <Field
                        name="joinKey"
                        type="text"
                        placeholder="Enter with the Join Key"
                        component={TextInput}
                        errorPosition="bottom"
                    />
                </Styled.JoinKeyFieldWrapper>

                <Button onClick={generateJoinKey}>
                    Generate join key
                </Button>
            </Styled.JoinKeyBox>
        </Styled.Content>
    );
};

PymeshNetworkSettingsFormContent.defaultProps = {
    dirty: false,
};

PymeshNetworkSettingsFormContent.propTypes = {
    setFieldValue: PropTypes.func.isRequired,
    dirty: PropTypes.bool,
    values: PropTypes.object.isRequired,
};
