import { useState } from 'react';
import { useHistory } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import {
    ROUTES,
    DELETE_DEVICES_MUTATION,
    GET_DEVICES_QUERY,
    GET_USER_PROFILE_QUERY,
} from 'Constants';
import {
    IconButton,
    Modal,
    DeleteModal,
    Table,
    useLastConnection,
    Spin,
    Loader,
} from 'Components';
import { ReactComponent as TrashIcon } from 'Assets/icons/trash.svg';
import { ReactComponent as Device } from 'Assets/icons/app.svg';
import { ReactComponent as USB } from 'Assets/icons/usb.svg';

import { AppModal } from './components';
import { columns } from './columns';
import * as Styled from './styled';

export const DeviceTable = ({
    data,
    onRowClick,
    pagination,
    paginationHandler,
    deviceLoading,
}) => {
    const history = useHistory();
    const { data: dataOfUser } = useQuery(GET_USER_PROFILE_QUERY);
    const user = dataOfUser?.getUserProfile ?? {};
    const [isOpenedDeleteModal, setIsOpenedDeleteModal] = useState(false);
    const [isOpenedAppModal, setIsOpenedAppModal] = useState(false);
    const [deleteDevice, { loading: isLoading }] = useMutation(DELETE_DEVICES_MUTATION, {
        refetchQueries: [GET_DEVICES_QUERY],
        awaitRefetchQueries: true,
    });
    const [selectedIds, setSelectedIds] = useState([]);
    const { data: tableData, loading } = useLastConnection(data);

    const handleClose = () => {
        setIsOpenedDeleteModal(false);
        setIsOpenedAppModal(false);
    };
    const openDeleteModal = () => {
        setIsOpenedDeleteModal(!!selectedIds?.length);
    };
    const openAppModal = () => {
        setIsOpenedAppModal(true);
    };

    const deletesDevice = async () => {
        if (!selectedIds.length) {
            return;
        }

        selectedIds.forEach((id) => {
            deleteDevice({ variables: { idList: id } });
        });
        handleClose();
    };

    const redirectTOAddDevice = () => {
        history.push(ROUTES.devices.add);
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <>
            <Styled.Wrapper data-cy="device-table-wrapper">
                <Styled.Header>
                    <IconButton
                        onClick={redirectTOAddDevice}
                        Icon={USB}
                        cyData="add-device-button"
                    >
                        Add via USB
                    </IconButton>
                    <IconButton
                        //TODO set correct function
                        onClick={openAppModal}
                        Icon={Device}
                    >
                        Add via App
                    </IconButton>
                    <IconButton
                        onClick={openDeleteModal}
                        Icon={TrashIcon}
                        cyData="delete-device-button"
                    >
                        Delete
                    </IconButton>
                </Styled.Header>
                <Spin spinning={deviceLoading}>
                    <Table
                        isRangeShow
                        columns={columns}
                        data={tableData}
                        onSelect={setSelectedIds}
                        onRowClick={onRowClick}
                        pagination={pagination}
                        onPaginationChange={paginationHandler}
                        initialRange={10}
                        labelOfRange="Devices on a page: "
                    />
                </Spin>
            </Styled.Wrapper>
            <Modal isOpened={isOpenedDeleteModal} handleClose={handleClose}>
                <DeleteModal
                    includeInput
                    title="Device deletion"
                    description={`If you really want to delete ${selectedIds.length} devices,
                                please write your username in the following field`}
                    deleteAction={deletesDevice}
                    cancelAction={handleClose}
                    checkingValue={user?.owner}
                    isLoading={isLoading}
                />
            </Modal>
            <Modal isOpened={isOpenedAppModal} handleClose={handleClose}>
                <AppModal />
            </Modal>
        </>
    );
};

DeviceTable.defaultProps = {
    pagination: {},
    paginationHandler: () => {},
};

DeviceTable.propTypes = {
    onRowClick: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    pagination: PropTypes.object,
    paginationHandler: PropTypes.func,
    deviceLoading: PropTypes.bool.isRequired,
};
