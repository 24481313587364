import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';

import {
    GET_ML_SAMPLES_BY_MODEL,
    GET_SAMPLES_BY_MODEL_ID,
    ML_RESPONSE_STATUSES,
    SAMPLING_TYPES,
} from 'Constants';
import { Loader, NoData } from 'Components';

import * as Styled from '../../styled';
import { Sampling } from '../sampling';
import { DataCollection } from '../dataCollection';

import { ClassificationData } from './components';

export const Testing = ({ model, devices }) => {
    const [samplingType] = useState(SAMPLING_TYPES.TEST);
    const [initialLoading, setInitialLoading] = useState(true);

    const { data: mlSamplesData, loading: loadingMlSamples, refetch: refetchMlSamples } = useQuery(
        GET_ML_SAMPLES_BY_MODEL,
        {
            variables: { model: model._id, type: samplingType },
            fetchPolicy: 'cache-and-network',
        },
    );
    const mlSamples = mlSamplesData?.getMLSamplesByModelId ?? [];

    const { data: samplesData, loading: loadingSamples, refetch: refetchSamples } = useQuery(
        GET_SAMPLES_BY_MODEL_ID,
        { variables: { modelId: model._id, type: 2 } },
    );
    const samples = samplesData?.getSamplesByModelId ?? [];

    useEffect(
        () => {
            if (initialLoading && !loadingMlSamples && !loadingSamples) {
                setInitialLoading(false);
            }
        },
        [loadingMlSamples, loadingSamples],
    );

    const refetchAllSamples = () => {
        refetchMlSamples();
        refetchSamples();
    };

    const training = useMemo(
        () => JSON.parse(model.training),
        [model],
    );

    if (initialLoading) {
        return <Loader />;
    }

    if (isEmpty(training) || training.state !== ML_RESPONSE_STATUSES.SUCCESS) {
        return (
            <NoData
                caption="The model needs to be trained"
                text="The training can be done at training tab."
            />
        );
    }

    return (
        <>
            <p>
                For validating your Model you can run a life test, in which created Model will classify a raw data.
            </p>
            <Styled.ModelTabWrapper>
                <Styled.ModelTabLeft>
                    <Sampling
                        model={model}
                        devices={devices}
                        samplingType={samplingType}
                        refetchMlSamples={refetchAllSamples}
                    />
                </Styled.ModelTabLeft>
                <Styled.ModelTabRight>
                    <h2>Data Collection</h2>
                    {mlSamples.length
                        ? (
                            <>
                                <DataCollection
                                    model={model}
                                    samplingType={samplingType}
                                    mlSamples={mlSamples}
                                    samples={samples}
                                    devices={devices}
                                />
                                <ClassificationData
                                    samplingType={samplingType}
                                    model={model}
                                />
                            </>
                        ) : (
                            <NoData 
                                caption="There is no data collected."
                                text="Start from creating a Sample from your device."
                            />
                        )}
                </Styled.ModelTabRight>
            </Styled.ModelTabWrapper>
        </>
    );
};

Testing.propTypes = {
    model: PropTypes.object.isRequired,
    devices: PropTypes.array,
};

Testing.defaultProps = {
    devices: [],
};
