import PropTypes from 'prop-types';

import { NetworksForm } from 'Components';

import * as Styled from './styled';

export const PygateForm = ({ onSubmit, backStep }) => {
    const addShieldNetworks = (values) => {
        onSubmit(values);
    };

    return (
        <Styled.Form
            data-cy="add-device-pygate-step"
        >
            <Styled.Title>
                Pygate Settings
            </Styled.Title>
            <Styled.Description>
                For establishing PyGate as a gateway you need to select one of
                the providers and the region of PyGate location.
            </Styled.Description>
            <NetworksForm
                initialValues={{ networks: [] }}
                allowedNetworks={['lora']}
                onSubmit={addShieldNetworks}
                onBack={backStep}
                submitText="Save configuration"
            />
        </Styled.Form>
    );
};

PygateForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    backStep: PropTypes.func.isRequired,
};
