import styled from 'styled-components';

export const NoDevicesAvailable = styled.div`
    padding: 25px;
    box-shadow: 0 2px 5px 0 rgba(50, 50, 50, 0.2);

    h3 {
        font-weight: bold;
    }
`;
