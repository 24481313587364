import styled from 'styled-components';
import { Button } from '../../buttons';

export const MapContainer = styled.div`
    .googleMapContainer {
        height: 100%;
    }
`;

export const UpdateButton = styled(Button)`
    margin-top: 0.5rem;
`;
