import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { Button as ButtonComponent } from 'Components';

export const Title = styled.div`
    font-size: 36px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.title};
`;

export const HeaderVersion = styled.span`
    margin-left: 5px;
    color: ${({ theme }) => theme.colors.border};
    font-size: 26px;
    font-weight: bold;
`;

export const Description = styled.div`
    margin-top: 20px;
`;

export const Link = styled(RouterLink)`
    margin: 0 5px;
    color: ${({ theme }) => theme.colors.active};

    &:hover {
        color: 'inherit';
    }
`;

export const Button = styled(ButtonComponent)`
    margin-top: 20px;
`;
