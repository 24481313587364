import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';

import { 
    NOTIFICATIONS_QUERY, 
    NOTIFICATIONS_SUBSCRIPTION, 
    MARK_AS_SEEN, 
    MARK_ALL_AS_SEEN,
} from 'Constants';
import { Loader } from 'Components';

import { AlertsList, NotificationsTable } from './components';
import * as Styled from './styled';

export const Notifications = ({ deviceToken, deviceName }) => {
    const [initialLoading, setInitialLoading] = useState(true);

    const { data, loading, subscribeToMore } = useQuery(
        NOTIFICATIONS_QUERY,
        {
            variables: { deviceToken },
            fetchPolicy: 'network-only',
        },
    );
    const notificationsList = data?.getNotificationsByDeviceToken ?? [];

    useEffect(
        () => {
            if (initialLoading && !loading) {
                setInitialLoading(false);
            }
        },
        [loading],
    );

    useEffect(() => {
        subscribeToMore({
            document: NOTIFICATIONS_SUBSCRIPTION,
            variables: { deviceToken },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) {
                    return prev;
                }

                const newItem = subscriptionData.data?.getNotifications;

                const newList = [...prev.getNotificationsByDeviceToken];

                if (!newList.find((item) => item._id === newItem._id)) {
                    newList.unshift(newItem);
                }

                return {
                    getNotificationsByDeviceToken: newList,
                };
            },
        });
    }, []);

    // CLICK ON ROW TO MARK NOTIFICATION AS READ
    const [markAsRead] = useMutation(MARK_AS_SEEN);
    const onRowClick = ({ _id, __typename }) => {
        markAsRead({
            variables: {
                id: _id,
                type: __typename,
                notificationSeen: true,
            },
            refetchQueries: [NOTIFICATIONS_QUERY],
        });
    };

    // MARK ALL NOTIFICATIONS AS READ WHEN LEAVING THE PAGE
    const [markAllAsRead] = useMutation(MARK_ALL_AS_SEEN);
    const onPageLeave = () => {
        const list = notificationsList
            .filter((item) => item.notificationSeen === false)
            .map((item) => ({
                _id: item._id,
                type: item.__typename,
            }));
        markAllAsRead({
            variables: { list },
            refetchQueries: [NOTIFICATIONS_QUERY],
        });
    };
    useEffect(() => () => onPageLeave(), []);

    if (initialLoading) {
        return <Loader />;
    }

    return (
        <Styled.Wrapper>
            <AlertsList deviceToken={deviceToken} deviceName={deviceName} />
            <NotificationsTable list={notificationsList} deviceName={deviceName} onRowClick={onRowClick} />
        </Styled.Wrapper>
    );
};

Notifications.propTypes = {
    deviceToken: PropTypes.string.isRequired,
    deviceName: PropTypes.string,
};

Notifications.defaultProps = {
    deviceName: '',
};
