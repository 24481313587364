import { useMemo, useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import { Table, Modal, DeleteModal, LTEModal, IconButton, Loader } from 'Components';
import { showToastSuccess, showToastError } from 'Utils';
import { GET_LTE_SETTINGS, REMOVE_LTE_SETTINGS } from 'Constants';
import { ReactComponent as LTEIcon } from 'Assets/icons/networks.svg';

import { EmptySettings } from '../emptySettings';

import { getColumns } from './columns';
import * as Styled from './styled';

export const LTESettings = () => {
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [detailsModalOpen, setDetailsModalOpen] = useState(false);
    const [selectedLteId, setSelectedLteId] = useState(null);
    const [initialLoading, setInitialLoading] = useState(true);

    const { data: arrayOfLTE, loading } = useQuery(GET_LTE_SETTINGS);
    const lteSettings = arrayOfLTE?.getLteSettings ?? [];

    useEffect(
        () => {
            if (initialLoading && !loading) {
                setInitialLoading(false);
            }
        },
        [loading],
    );

    const [removeLte, { loading: isLoading }] = useMutation(REMOVE_LTE_SETTINGS, {
        refetchQueries: [GET_LTE_SETTINGS],
        awaitRefetchQueries: true,
    });

    const editLteHandler = (id) => {
        setSelectedLteId(id);
        setDetailsModalOpen(true);
    };

    const deleteLteHandler = (id) => {
        setSelectedLteId(id);
        setDeleteModalOpen(true);
    };

    const openCleanDetailsModal = () => {
        setSelectedLteId(null);
        setDetailsModalOpen(true);
    };

    const columns = useMemo(
        () => getColumns({
            editLte: editLteHandler,
            deleteLte: deleteLteHandler,
        }),
        [],
    );

    const deleteLte = async () => {
        try {
            await removeLte({
                variables: {
                    lteId: selectedLteId,
                },
                update: () => {
                    showToastSuccess('LTE settings deleted successfully');
                    setSelectedLteId(null);
                    setDeleteModalOpen(false);
                },
            });
        } catch (error) {
            showToastError(error.message);
        }
    };

    if (initialLoading) {
        return <Loader />;
    }

    if (!lteSettings.length) {
        return (
            <Styled.Wrapper>
                <EmptySettings title="LTE" />
            </Styled.Wrapper>
        );
    }

    return (
        <Styled.Wrapper>
            <Styled.TableHeader>
                <IconButton
                    onClick={openCleanDetailsModal}
                    Icon={LTEIcon}
                    cyData="button-add-lte"
                >
                    Add LTE Network
                </IconButton>
            </Styled.TableHeader>
            <Table
                data={lteSettings}
                columns={columns}
            />
            <Modal
                handleClose={() => setDetailsModalOpen(false)}
                isOpened={detailsModalOpen}
            >
                <LTEModal
                    handleClose={() => setDetailsModalOpen(false)}
                    id={selectedLteId}
                />
            </Modal>
            <Modal
                handleClose={() => setDeleteModalOpen(false)}
                isOpened={deleteModalOpen}
            >
                <DeleteModal
                    title="Delete LTE settings"
                    description="Are you sure you want to delete this LTE settings?"
                    deleteAction={deleteLte}
                    cancelAction={() => setDeleteModalOpen(false)}
                    isLoading={isLoading}
                />
            </Modal>
        </Styled.Wrapper>
    );
};
