import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { GET_APPLICATION_MESSAGES } from 'Constants';
import { getStringifiedPayload, removeGraphQlTypenames } from 'Utils';
import { useMessageSubscription } from 'Hooks';

import { LineChartWidget } from '../../../../widgets';

export const LineChart = ({
    chartId,
    chartSettings,
    updateApplicationChart,
    title,
}) => {
    const updateChartSettingsLimit = (limit) => {
        const filteredSettings = removeGraphQlTypenames(chartSettings);

        updateApplicationChart({
            variables: {
                chartInput: {
                    _id: chartId,
                    settings: {
                        ...filteredSettings,
                        limit: +limit.value,
                    },
                },
            },
        });
    };

    const { data, error, loading, refetch } = useQuery(
        GET_APPLICATION_MESSAGES,
        {
            variables: { chartId },
        },
    );
    const projectMessages = data?.getApplicationMessages ?? [];

    const subscribeData = useMemo(() => {
        const deviceTokens = projectMessages.map((messageObj) => messageObj.device.token);
        const messagePin = projectMessages[0]?.messages?.[0].messagePin;

        return { deviceTokens, messagePin };
    }, [projectMessages]);

    useMessageSubscription(subscribeData.deviceTokens, subscribeData.messagePin, refetch);

    const parsedMessages = useMemo(
        () => {
            const flattenMessagesData = projectMessages.flatMap((item) => {
                const deviceName = item.device.description;
                return item.messages.map((message) => ({
                    date: new Date(message.createdAt).valueOf(),
                    [deviceName]: Number(getStringifiedPayload(message.payload)),
                }));
            });

            return flattenMessagesData.sort((a, b) => a.date - b.date);
        }, [projectMessages],
    );

    const deviceNames = useMemo(() => projectMessages.map((messages) => messages.device.description), [projectMessages]);

    return (
        <LineChartWidget
            data={parsedMessages}
            pin={chartSettings.pin}
            deviceNames={deviceNames}
            setSelectedPullSize={updateChartSettingsLimit}
            defaultPullSize={chartSettings.limit}
            title={title}
            isEmpty={loading || error || !projectMessages.length}
        />
    );
};

LineChart.propTypes = {
    chartId: PropTypes.string.isRequired,
    chartSettings: PropTypes.shape({
        limit: PropTypes.number.isRequired,
        pin: PropTypes.number.isRequired,
    }).isRequired,
    updateApplicationChart: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
};
