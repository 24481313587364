import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import moment from 'moment';

import { getStringifiedPayload } from 'Utils';
import { GET_APPLICATION_LAST_MESSAGES } from 'Constants';
import { useMessageSubscription } from 'Hooks';

import { TableWidget } from '../../../../widgets';

export const LastValue = ({
    chartId,
    widgetStyles,
    title,
}) => {
    const tableHeader = ['Devices', 'Time', 'Value'];
    const { data: messagesData, loading, refetch } = useQuery(
        GET_APPLICATION_LAST_MESSAGES,
        {
            variables: { chartId },
        },
    );

    const projectMessages = messagesData?.getApplicationLastMessages ?? [];

    const subscribeData = useMemo(() => {
        const deviceTokens = projectMessages.map((messageObj) => messageObj.device.token);
        const messagePin = projectMessages[0]?.lastMessage?.messagePin;

        return { deviceTokens, messagePin };
    }, [projectMessages]);

    useMessageSubscription(subscribeData.deviceTokens, subscribeData.messagePin, refetch);

    const data = useMemo(
        () => projectMessages.map((message) => {
            const devices = message.device.description.toUpperCase();
            const time = moment(message.lastMessage.createdAt).fromNow();
            const value = getStringifiedPayload(message.lastMessage.payload);
            return {
                devices,
                time,
                value,
            };
        }),
        [projectMessages],
    );

    return (
        <TableWidget
            data={data}
            isEmpty={loading || !data.length}
            title={title}
            widgetStyles={widgetStyles}
            tableHeader={tableHeader}
        />
    );
};

LastValue.propTypes = {
    chartId: PropTypes.string.isRequired,
    widgetStyles: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number,
        left: PropTypes.number,
        top: PropTypes.number,
    }).isRequired,
    title: PropTypes.string.isRequired,
};
