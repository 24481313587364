import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import {
    SAVE_SIGFOX_CREDENTIALS,
    MODAL,
    TAB,
    GET_SIGFOX_CREDENTIALS,
} from 'Constants';
import { showToastError, showToastSuccess } from 'Utils';

import { FormContent } from './formContent';
import { validationSchema, initialValues } from './config';

import * as Styled from './styled';

export const SigfoxModal = ({ handleClose, handleSuccess, typeOfForm }) => {
    const [saveSettings, { loading: isLoading }] = useMutation(SAVE_SIGFOX_CREDENTIALS, {
        refetchQueries: [GET_SIGFOX_CREDENTIALS],
        awaitRefetchQueries: true,
    });

    const save = async (values) => {
        try {
            await saveSettings({
                variables: {
                    sigfoxCredentials: {
                        login: values.login,
                        password: values.password,
                    },
                },
                update: () => {
                    showToastSuccess('Sigfox Integration was created successfully');
                    if (typeOfForm === MODAL) {
                        const finalFunc = handleSuccess || handleClose;
                        finalFunc();
                    }
                },
            });
        } catch (error) {
            showToastError(error.message);
        }
    };

    return (
        <Styled.Modal
            $modal={typeOfForm === MODAL}
            $deactivated={typeOfForm === TAB}
        >
            {typeOfForm === MODAL && (
                <Styled.Header>
                    Sigfox API credentials
                </Styled.Header>
            )}
            {typeOfForm === TAB && (
                <div>
                    <Styled.Title>
                        Sigfox API credentials
                    </Styled.Title>
                    <Styled.Description>
                        Define the Sigfox API credentials that you are going to use
                    </Styled.Description>
                    <p>
                        <Styled.DocumentationLink
                            href={process.env.REACT_APP_LINK_DOCS_SIGFOX}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Go to sigfox documentation
                        </Styled.DocumentationLink>
                    </p>
                </div>
            )}
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={save}
            >
                {(props) => (
                    <FormContent
                        {...props}
                        handleClose={handleClose}
                        isLoading={isLoading}
                        typeOfForm={typeOfForm}
                    />
                )}
            </Formik>
        </Styled.Modal>
    );
};

SigfoxModal.propTypes = {
    handleClose: PropTypes.func,
    handleSuccess: PropTypes.func,
    typeOfForm: PropTypes.oneOf([MODAL, TAB]).isRequired,
};

SigfoxModal.defaultProps = {
    handleClose: () => { },
    handleSuccess: null,
};
