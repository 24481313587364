import { useState, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import cloneDeep from 'lodash/cloneDeep';

import {
    GET_PYMESH_DATA_BY_DEVICE_LIST_QUERY,
    PYMESH_NODE_ROLES,
    SET_BORDER_ROUTER,
    GET_PYMESH_BY_APPLICATION_ID,
    REMOVE_DEVICES_FROM_PYMESH,
    ROUTES,
    PYMESH_SUPPORTED_DEVICE_TYPES,
    BR_ENABLED_VALUES,
} from 'Constants';
import { Table, BorderRouterModal, EmptyPymeshView, Loader } from 'Components';
import { showToastError, showToastSuccess } from 'Utils';

import { RemoveDeviceFromMesh, AddDeviceToMesh, DeployPymesh } from './actions';
import { getColumns } from './columns';
import * as Styled from './styled';

export const PymeshStructure = ({ pymeshID, devicesEdges, pymeshDevices }) => {
    const history = useHistory();

    const defaultBrDevicesEnabled = pymeshDevices
        .filter((device) => device.brEnable)
        .map((device) => device.token);

    const [selectedDevicesIds, setSelectedDevicesIds] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [enabledBrDevices, setEnabledBrDevices] = useState(defaultBrDevicesEnabled);
    const [initialLoading, setInitialLoading] = useState(true);

    const deviceTokenList = useMemo(
        () => pymeshDevices.map((device) => device.token),
        [pymeshDevices],
    );

    const { data: pymeshDataReceived, loading } = useQuery(
        GET_PYMESH_DATA_BY_DEVICE_LIST_QUERY,
        { variables: { deviceTokenList } },
    );
    const pymeshData = pymeshDataReceived?.getPymeshDataByDeviceList ?? [];

    useEffect(
        () => {
            if (initialLoading && !loading) {
                setInitialLoading(false);
            }
        },
        [loading],
    );

    const [setBorderRouterMutation] = useMutation(SET_BORDER_ROUTER);
    const [removeDevicesFromPymeshMutation] = useMutation(REMOVE_DEVICES_FROM_PYMESH);

    const deviceEdgesSynchronizedWithPymesh = useMemo(
        () => devicesEdges.filter((edge) => pymeshDevices.find((pymeshDevice) => pymeshDevice.token === edge.node?.token)),
        [devicesEdges, pymeshDevices],
    );

    const processedDevices = useMemo(
        () => deviceEdgesSynchronizedWithPymesh.map((edge) => {
            const device = cloneDeep(edge.node);
            const monitoringData = pymeshData.find((data) => data.token === device.token) || {};

            device.brEnable = enabledBrDevices.includes(device.token);
            device.role = PYMESH_NODE_ROLES.find((item) => item.role === monitoringData.role)?.label || '-';
            device.mac = monitoringData.mac || '-';
            return device;
        }),
        [deviceEdgesSynchronizedWithPymesh, pymeshData, enabledBrDevices],
    );

    const devicesSelected = useMemo(
        () => processedDevices.filter((item) => selectedDevicesIds.includes(item.token)),
        [processedDevices, selectedDevicesIds],
    );

    const handleModalClose = () => setShowModal(false);

    const onSelect = (_ids, selectedDevices) => {
        const deviceTokens = selectedDevices.map((device) => device.token);
        setSelectedDevicesIds(deviceTokens);
    };

    const onRowClick = (device) => history.push(`${ROUTES.devices.main}/${device.token}`);

    const getBrValueByToken = (token) => !!enabledBrDevices.find((deviceToken) => deviceToken === token);

    const handleBRChange = async (token, changeObject) => {
        const devicesWithEnableBr = [...enabledBrDevices];
        const initialLength = devicesWithEnableBr.length;

        const tokenIndex = devicesWithEnableBr.findIndex((item) => item === token);

        if (changeObject.value === BR_ENABLED_VALUES.ENABLED && tokenIndex < 0) {
            devicesWithEnableBr.push(token);
        } else if (changeObject.value === BR_ENABLED_VALUES.DISABLED && tokenIndex >= 0) {
            devicesWithEnableBr.splice(tokenIndex, 1);
        }

        if (devicesWithEnableBr.length !== initialLength) {
            const updateDevice = {
                token,
                brEnable: changeObject.value === BR_ENABLED_VALUES.ENABLED,
            };

            if (devicesWithEnableBr.length) {
                await setBorderRouterMutation({
                    variables: { device: updateDevice, pymeshID },
                    update: (() => {
                        showToastSuccess('Border router updated successfully');
                        setEnabledBrDevices(devicesWithEnableBr);
                    }),
                });
            } else {
                setShowModal(true);
            }
        }
    };

    const checkAddDeviceBtnDisabled = () => {
        const availableDevices = devicesEdges.filter((edge) => {
            const device = edge.node;
            return device.mac && PYMESH_SUPPORTED_DEVICE_TYPES.includes(device.deviceType);
        });

        return (pymeshDevices.length === availableDevices.length);
     };

    const removeDeviceFromPymesh = async () => {
        const borderRouterRemains = processedDevices.find((device) => !selectedDevicesIds.includes(device.token) && device.brEnable);

        if (borderRouterRemains) {
            try {
                await removeDevicesFromPymeshMutation({
                    variables: { devices: selectedDevicesIds, pymeshID },
                    refetchQueries: [GET_PYMESH_BY_APPLICATION_ID],
                    update: (() => {
                        showToastSuccess('Devices removed successfully');
                    }),
                });
            } catch (error) {
                console.error(error);
                showToastError('Device removal failed');
            }
        } else {
            setShowModal(true);
        }
    };

    const columns = getColumns({ getBrValueByToken, handleBRChange });

    if (initialLoading) {
        return <Loader />;
    }

    if (!processedDevices.length) {
        return (
            <EmptyPymeshView
                title="Pymesh is empty"
                message="All devices were removed from the Pymesh"
            />
        );
    }

    return (
        <Styled.Wrapper>
            <Styled.Actions>
                <AddDeviceToMesh
                    pymeshDevices={pymeshDevices}
                    disabled={checkAddDeviceBtnDisabled()}
                    pymeshID={pymeshID}
                />
                <RemoveDeviceFromMesh
                    onClick={removeDeviceFromPymesh}
                    disabled={!selectedDevicesIds.length}
                />
                <DeployPymesh
                    devices={devicesSelected}
                />
            </Styled.Actions>
            <Table
                columns={columns}
                data={processedDevices}
                onSelect={onSelect}
                onRowClick={onRowClick}
            />
            <BorderRouterModal showModal={showModal} onClose={handleModalClose} />
        </Styled.Wrapper>
    );
};

PymeshStructure.defaultProps = {
    devicesEdges: [],
    pymeshDevices: [],
};

PymeshStructure.propTypes = {
    pymeshID: PropTypes.string.isRequired,
    devicesEdges: PropTypes.arrayOf(PropTypes.object),
    pymeshDevices: PropTypes.arrayOf(PropTypes.object),
};
