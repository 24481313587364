import { SoftServerService } from 'Services';

export const DOWNGRADE_LINK = process.env.REACT_APP_LINK_DOWNGRADE;

const getLatestFirmware = async (deviceFirmwareFwtype, deviceMacAddress) => {
    let listOfFirmwares = [];

    if (deviceFirmwareFwtype) {
        const { deviceLongName } = await SoftServerService.getDeviceType(deviceMacAddress);
        const deviceName = deviceLongName?.split(' ').join('+') ?? '';
        const firmwareType = deviceFirmwareFwtype;

        listOfFirmwares = await SoftServerService.getListOfFirmwares(
            deviceName,
            firmwareType,
        );

        let latestFirmwareIndex = 0;
        listOfFirmwares.forEach((firmware, idx) => {
            listOfFirmwares[idx].intVersion = SoftServerService.versionToInt(firmware.version);
            if (listOfFirmwares[latestFirmwareIndex].intVersion < listOfFirmwares[idx].intVersion) {
                latestFirmwareIndex = idx;
            }
        });

        return listOfFirmwares[latestFirmwareIndex]?.version ?? null;
    }

    return null;
};

export const getFirmwareValues = async (currentFirmwareVersion, deviceMacAddress) => {
    const latestFirmwareVersion = await getLatestFirmware(currentFirmwareVersion.fwtype, deviceMacAddress);
    const { version: currentVersion } = currentFirmwareVersion;
    if (latestFirmwareVersion && latestFirmwareVersion !== currentVersion) {
        return [
            { value: currentVersion, label: `${currentVersion} (current)` },
            { value: latestFirmwareVersion, label: `${latestFirmwareVersion} (latest)` },
        ];
    } 
    
    return [{ value: currentVersion, label: currentVersion }];
};
