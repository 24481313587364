import PropTypes from 'prop-types';
import { Form } from 'formik';

import { PymeshNetworkSettingsFormContent } from 'Components';

import { WizardComponentBox } from '../wizardComponentBox';

export const FormContent = ({ values, previousStep, setFieldValue, dirty, isValid }) => (
    <Form>
        <WizardComponentBox
            submit
            title="Pymesh Settings"
            description="All the settings could be changed later on in the Pymesh Settings."
            previousStep={previousStep}
            disableNext={!isValid}
        >
            <PymeshNetworkSettingsFormContent
                values={values}
                setFieldValue={setFieldValue}
                dirty={dirty}
            />
        </WizardComponentBox>
    </Form>
);

FormContent.propTypes = {
    values: PropTypes.shape({
        region: PropTypes.string,
        frequency: PropTypes.string,
        bandwidth: PropTypes.string,
        spreadFactory: PropTypes.string,
        joinKey: PropTypes.string,
    }).isRequired,
    previousStep: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    dirty: PropTypes.bool.isRequired,
};
