export const getFontSize = (length) => {
    let range = 'tiny';

    if (length < 3 && length > 0) {
        range = 'huge';
    } else if (length > 2 && length < 5) {
        range = 'medium';
    } else if (length > 4 && length < 7) {
        range = 'small';
    }

    return range;
};
