import styled from 'styled-components';

import { Button as GeneralButton } from 'Components';

export const Title = styled.div`
    font-size: 22px;
    margin-bottom: 50px;
    text-align: center;
`;

export const Button = styled(GeneralButton)`
    width: 100%;
    height: 56px;
`;

export const TwoCols = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
`;

export const Subtitle = styled.div`
    margin: -20px auto 50px;
    margin-bottom: 50px;
    font-size: 16px;
    text-align: center;
    max-width: 300px;
`;
