import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';

import { ReactComponent as Actions } from 'Assets/icons/actions.svg';
import * as Styled from './styled';

export const Dropdown = ({ actions, menuWidth, Icon, id, className, cyData }) => {
    const propagationStopper = (event, func) => {
        event.stopPropagation();
        func && func(id);
    };

    const menu = (
        <Menu style={{ width: `${menuWidth}px` }}>
            {actions.map((action, index) => (
                <Fragment key={action.title}>
                    <Styled.Menu
                        key={action.title}
                        onClick={({ domEvent }) => propagationStopper(domEvent, action.executedFunction)}
                        data-cy={action.cyData}
                        disabled={action.disabled}
                    >
                        {action.title}
                    </Styled.Menu>
                    {index < actions.length - 1 && (
                        <Styled.Divider />
                    )}
                </Fragment>
            ))}
        </Menu>
    );

    return (
        <Styled.Dropdown
            overlay={menu}
            trigger="click"
            onClick={propagationStopper}
        >
            <Styled.Button icon={<Icon />} className={className} data-cy={cyData} />
        </Styled.Dropdown>
    );
};

Dropdown.propTypes = {
    actions: PropTypes.arrayOf(PropTypes.shape({
        executedFunction: PropTypes.func,
        title: PropTypes.string,
    })).isRequired,
    menuWidth: PropTypes.number,
    Icon: PropTypes.elementType,
    id: PropTypes.string,
    // btnClassName: PropTypes.string,
    className: PropTypes.string,
    cyData: PropTypes.string,
};

Dropdown.defaultProps = {
    menuWidth: 100,
    Icon: Actions,
    id: '',
    // btnClassName: '',
    className: '',
    cyData: null,
};
