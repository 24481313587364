import styled, { css } from 'styled-components';

const bordered = css`
    border: 1px solid ${({ theme }) => theme.colors.border};
`;

const filled = css`
    background-color: ${({ theme, $fill }) => theme.colors[$fill]};
    ${({ $fill, theme }) => ($fill === 'success' || $fill === 'error') && `
        color: ${theme.colors.activeText};
    `}
`;

export const Tag = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px 7px;
    border-radius: 4px;
    white-space: nowrap;
    display: inline-block;
    ${({ $fill }) => $fill !== null && filled}
    ${({ $border }) => $border === true && bordered}
`;
