import isEmpty from 'lodash/isEmpty';

export const getInitialMlSample = (mlSamples, model) => {
    if (!mlSamples.length) {
        return {};
    }

    if (!model.lastProcessedData?.sampleId) {
        return mlSamples[0];
    }

    const lastProcessedSample = mlSamples.find((sample) => sample._id === model.lastProcessedData.sampleId);

    return lastProcessedSample ?? mlSamples[0];
};

export const getModelDefinition = (model) => {
    const parsedModelDefinition = JSON.parse(model.modelDefinition);

    if (isEmpty(parsedModelDefinition)) {
        return {};
    }

    const preProcessingData = JSON.parse(model.preProcessingStatus);
    const spData = parsedModelDefinition.blocks.find((block) => block.id === 'SP');

    if (isEmpty(spData)) {
        return {};
    }

    return {
        preProcessingData,
        modelDefinition: {
            standardization: (spData.standardization.mean || spData.standardization.variance),
            mean: spData.standardization.mean,
            variance: spData.standardization.variance,
            filter: spData.filter.request_filtered_data,
            filterType: spData.filter.type,
            cutOffFrequency: spData.filter.cutoff_frequency,
            order: spData.filter.order,
            rms: spData.features.RMS.enable,
            fft: spData.features.fft.short_fft.enable,
            fftIntervals: spData.features.fft.short_fft.number_of_intervals,
            useFFTPeaks: spData.features.fft.peaks.enable,
            numberOfPeaks: spData.features.fft.peaks.peaks_number,
            peakMinimumValue: spData.features.fft.peaks.threshold,
            spectralPower: spData.features.fft.spectral_energy_on_intervals.enable,
            intervals: spData.features.fft.spectral_energy_on_intervals.intervals.join(','),
        },
    };
};
