import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { ModelConfiguration } from 'Components';

export const ModelConfigurationWrapper = ({ model }) => {
    const initialValues = useMemo(
        () => ({
            processingType: model.processingType,
            learningTechnique: model.learningTechnique,
        }),
        [model],
    );

    const onSubmit = (values) => {
        // TODO: add real request on demand
        console.log(values);
    };

    return (
        <ModelConfiguration
            formValues={initialValues}
            nextStep={onSubmit}
            hideTitle
            hideBackButton
            submitText="Save"
            disabledOnPristine
        />
    );
};

ModelConfigurationWrapper.propTypes = {
    model: PropTypes.object.isRequired,
};
