import { Form, Field } from 'formik';
import PropTypes from 'prop-types';

import { TextInput, Button } from 'Components';
import { ReactComponent as SupportIcon } from 'Assets/icons/support.svg';

import * as Styled from './styled';

export const FormContent = ({ isValid, onClose, isLoading }) => (
    <Form>
        <Styled.FormContent>
            <Styled.FormItem data-cy="edit-signal-name-wrapper">
                <Styled.ItemLabel>
                    Name
                </Styled.ItemLabel>
                <Field
                    name="name"
                    type="text"
                    component={TextInput}
                    errorPosition="right"
                />
            </Styled.FormItem>
            <Styled.FormItem>
                <Styled.ItemLabel>
                    Unit
                </Styled.ItemLabel>
                <Field
                    name="unit"
                    type="text"
                    component={TextInput}
                />
            </Styled.FormItem>
        </Styled.FormContent>
        <Styled.NotificationWrapper>
            <Styled.NotificationIcon>
                <SupportIcon />
            </Styled.NotificationIcon>
            <Styled.NotificationLabel>
                Your data signal variable has to be written in MicroPython  and deployed on  the device.
            </Styled.NotificationLabel>
        </Styled.NotificationWrapper>
        <Styled.ButtonsWrapper>
            <Button
                buttonType="transparent"
                onClick={onClose}
            >
                Cancel
            </Button>
            <Button
                type="submit"
                disabled={!isValid}
                loading={isLoading}
                cyData="edit-signal-submit"
            >
                Submit
            </Button>
        </Styled.ButtonsWrapper>
    </Form>
);

FormContent.defaultProps = {
    isLoading: false,
};

FormContent.propTypes = {
    isValid: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
};
