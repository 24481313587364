import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useApolloClient, useQuery, useMutation } from '@apollo/client';

import { Breadcrumbs, PageHeader, AwsLogin, AwsThing } from 'Components';
import {
    BREADCRUMBS_LABELS,
    ROUTES,
    GET_AWS_THINGS_BY_GROUP,
    GET_USER_PROFILE_QUERY,
    SAVE_AWS_THINGS_MUTATION,
} from 'Constants';
import { showToastError, showToastSuccess, createAWSAttributeName } from 'Utils';
import { GlobalContext } from 'Context';

const STEPS = {
    LOGIN: 1,
    THING_CREATION: 2,
};

export const AwsIntegration = () => {
    const history = useHistory();
    const apolloClient = useApolloClient();
    const globalContext = useContext(GlobalContext);
    const session = globalContext.session;
    const [step, setStep] = useState(STEPS.LOGIN);
    const [connectionMask, setConnectionMask] = useState({});
    const [awsService, setAwsService] = useState({});

    const { data: userData } = useQuery(GET_USER_PROFILE_QUERY);
    const user = userData?.getUserProfile ?? {};
    console.log(session);
    const [saveAwsThingsMutation, { loading: isLoading }] = useMutation(SAVE_AWS_THINGS_MUTATION);

    let content = null;
    let title = '';
    let description = '';

    const nextStep = () => {
        setStep(step + 1);
    };

    const onThingsSubmit = async (values, allDevices) => {
        console.log(session);
        try {
            const deviceAttributes = [];

            values.devices.forEach((item, index) => {
                if (item && allDevices[index]) {
                    deviceAttributes.push({
                        token: allDevices[index].token,
                        description: allDevices[index].description,
                    });
                }
            });
            const groupName = values.thingGroupName;
            const topic = values.messagesTopic;
            
            const thingGroupName = `Pybytes-${session.id.replace(/-/g, '')}-${groupName}`;

            const { data: { getAWSThingsByGroup: thingsByGroup } } = await apolloClient.query({
                query: GET_AWS_THINGS_BY_GROUP,
                variables: {
                    awsGroupName: thingGroupName,
                    region: connectionMask.region,
                    includeDevice: false,
                },
            });

            if (thingsByGroup.length) {
                showToastError('You are trying to use the same Group name in the same region. please change the group name.');
            }

            const thingGroupCreationResult = await awsService.createThingGroup({ thingGroupName });

            await awsService.attachDefaultPolicy(thingGroupCreationResult.thingGroupArn);

            const thingCreationPromises = deviceAttributes.map((item) => awsService.createThing({
                name: item.token,
                attributes: {
                    IoT_Platform: 'Pybytes',
                    original_device_name: createAWSAttributeName(item),
                },
            }));
            const thingCreationResults = await Promise.all(thingCreationPromises);

            const thingGroupingPromises = deviceAttributes.map((item) => awsService.addThingToThingGroup({
                thingName: item.token,
                thingGroupName,
            }));
            await Promise.all(thingGroupingPromises);

            const certificateCreationPromises = deviceAttributes.map(() => awsService.createCertificate());
            const certificateCreationResults = await Promise.all(certificateCreationPromises);

            const thingCertificationPromises = deviceAttributes.map((item, index) => awsService.attachCertificateToThing({
                thingName: item.token,
                principal: certificateCreationResults[index].certificateArn,
            }));
            await Promise.all(thingCertificationPromises);

            const compiledInformations = deviceAttributes.map((item, index) => ({
                owner: user.owner,
                region: connectionMask.region,
                endpointAddress: connectionMask.endpointAddress,
                topic,
                deviceToken: item.token,
                awsThing: {
                    thingName: thingCreationResults[index].thingName,
                    thingArn: thingCreationResults[index].thingArn,
                    thingId: thingCreationResults[index].thingId,
                },
                awsGroup: {
                    groupName: thingGroupCreationResult.thingGroupName,
                    groupArn: thingGroupCreationResult.thingGroupArn,
                    groupId: thingGroupCreationResult.thingGroupId,
                },
                certificate: {
                    id: certificateCreationResults[index].certificateId,
                    arn: certificateCreationResults[index].certificateArn,
                    certPEM: certificateCreationResults[index].certificatePem,
                    publicKey: certificateCreationResults[index].keyPair.PublicKey,
                    privateKey: certificateCreationResults[index].keyPair.PrivateKey,
                },
            }));

            const {
                data: { saveAWSThings: { integrationId } },
            } = await saveAwsThingsMutation({ variables: { awsThings: compiledInformations } });

            showToastSuccess('Integration created successfully');

            history.push(`${ROUTES.settings.integrations.main}/${integrationId}`);
        } catch (error) {
            showToastError(error.message);
        }
    };

    switch (step) {
        case STEPS.THING_CREATION:
            content = (
                <AwsThing
                    connectionMask={connectionMask}
                    awsService={awsService}
                    nextStep={onThingsSubmit}
                    isLoading={isLoading}
                />
            );
            title = 'New AWS Integration';
            break;
        case STEPS.LOGIN:
        default:
            content = (
                <AwsLogin
                    setConnectionMask={setConnectionMask}
                    setAwsService={setAwsService}
                    nextStep={nextStep}
                />
            );
            title = 'Login to AWS';
            description = 'You have to be registrated on Amazon AWS. Learn more about Integrations with external services';
    }

    return (
        <>
            <Breadcrumbs labels={BREADCRUMBS_LABELS.awsIntegration} />
            <PageHeader
                title={title}
                description={[description]}
            />
            {content}
        </>
    );
};
