import { useMemo, useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import { GET_WIFIS, REMOVE_WIFI_MUTATION } from 'Constants';
import { Table, Modal, DeleteModal, WiFiModal, IconButton, Loader } from 'Components';
import { showToastSuccess, showToastError } from 'Utils';
import { ReactComponent as WifiIcon } from 'Assets/icons/net-wifi-plus.svg';

import { EmptySettings } from '../emptySettings';

import { getColumns } from './columns';
import * as Styled from './styled';

export const WifiSettings = () => {
    const [showPasswordsArr, setShowPasswordsArr] = useState([]);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [detailsModalOpen, setDetailsModalOpen] = useState(false);
    const [selectedWifiId, setSelectedWifiId] = useState(null);

    const [initialLoading, setInitialLoading] = useState(true);

    const { data: arrayOfWifi, loading } = useQuery(GET_WIFIS);
    const wifi = arrayOfWifi?.getSettings.wifiSaved ?? [];

    useEffect(
        () => {
            if (initialLoading && !loading) {
                setInitialLoading(false);
            }
        },
        [loading],
    );

    const [removeWiFi, { loading: isLoading }] = useMutation(REMOVE_WIFI_MUTATION, {
        refetchQueries: [GET_WIFIS],
        awaitRefetchQueries: true,
    });

    useEffect(() => {
        const newShowPasswordsArr = wifi.map((_, index) => !!showPasswordsArr[index]);
        setShowPasswordsArr(newShowPasswordsArr);
    }, [wifi]);

    const togglePassword = (index) => {
        const newShowPasswordsArr = [...showPasswordsArr];
        newShowPasswordsArr[index] = !newShowPasswordsArr[index];
        setShowPasswordsArr(newShowPasswordsArr);
    };

    const editWifiHandler = (id) => {
        setSelectedWifiId(id);
        setDetailsModalOpen(true);
    };

    const deleteWifiHandler = (id) => {
        setSelectedWifiId(id);
        setDeleteModalOpen(true);
    };

    const openCleanDetailsModal = () => {
        setSelectedWifiId(null);
        setDetailsModalOpen(true);
    };

    const columns = useMemo(
        () => getColumns({
            editWifi: editWifiHandler,
            deleteWifi: deleteWifiHandler,
            showPasswordsArr,
            togglePassword,
        }),
        [showPasswordsArr],
    );

    const deleteWifi = async () => {
        try {
            await removeWiFi({
                variables: {
                    wifiId: selectedWifiId,
                },
                update: () => {
                    showToastSuccess('Wifi settings deleted successfully');
                    setSelectedWifiId(null);
                    setDeleteModalOpen(false);
                },
            });
        } catch (error) {
            showToastError(error.message);
        }
    };

    if (initialLoading) {
        return <Loader />;
    }

    if (!wifi.length) {
        return (
            <Styled.Wrapper>
                <EmptySettings title="WiFi" />
            </Styled.Wrapper>
        );
    }

    return (
        <Styled.Wrapper>
            <Styled.TableHeader>
                <IconButton
                    onClick={openCleanDetailsModal}
                    Icon={WifiIcon}
                    cyData="button-add-wifi"
                >
                    Add Wifi Network
                </IconButton>
            </Styled.TableHeader>
            <Table
                data={wifi}
                columns={columns}
            />
            <Modal
                handleClose={() => setDetailsModalOpen(false)}
                isOpened={detailsModalOpen}
            >
                <WiFiModal
                    handleClose={() => setDetailsModalOpen(false)}
                    id={selectedWifiId}
                />
            </Modal>
            <Modal
                handleClose={() => setDeleteModalOpen(false)}
                isOpened={deleteModalOpen}
            >
                <DeleteModal
                    title="Delete WiFi settings"
                    description="Are you sure you want to delete this WiFi settings?"
                    deleteAction={deleteWifi}
                    cancelAction={() => setDeleteModalOpen(false)}
                    isLoading={isLoading}
                />
            </Modal>
        </Styled.Wrapper>
    );
};
