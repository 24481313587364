import { HIERARCHY_ACTION_TYPES } from './actionTypes';

export const hierarchyInitialState = {
    synchronizing: false,
    isOnline: false,
    filesSynchronized: [],
    filesCount: 0,
};

export const hierarchyReducer = (state, action) => {
    switch (action.type) {
        case HIERARCHY_ACTION_TYPES.SET_SYNCHRONIZING:
            return {
                ...state,
                synchronizing: action.payload.synchronizing,
            };
        case HIERARCHY_ACTION_TYPES.SET_SYNCHRONIZED_FILES:
            return {
                ...state,
                filesSynchronized: action.payload.files,
            };
        case HIERARCHY_ACTION_TYPES.SET_FILES_COUNT:
            return {
                ...state,
                filesCount: action.payload.count,
            };
        case HIERARCHY_ACTION_TYPES.SET_DEVICE_ONLINE:
            return {
                ...state,
                isOnline: action.payload.isOnline,
            };
        default:
            return state;
    }
};
