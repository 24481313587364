import styled from 'styled-components';

import { IconButton } from 'Components';

export const Wrapper = styled.div`
    margin-top: 20px;
`;

export const Button = styled(IconButton)`
    margin-bottom: 10px;
`;
