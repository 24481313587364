import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { GET_DEVICES_GROUPED_BY_SIGNALS } from 'Constants';
import { useMessageSubscription } from 'Hooks';

import { DoughnutChart } from '../../../../widgets';

export const GroupedDevicesChart = ({ chartId, title, chartSettings }) => {
    const { pin } = chartSettings;

    const { data, loading, refetch } = useQuery(GET_DEVICES_GROUPED_BY_SIGNALS, {
        variables: { chartId },
    });

    const chartData = data?.getDevicesGroupedBySignals?.groups ?? [];
    const devicesTokens = data?.getDevicesGroupedBySignals?.devicesTokens ?? [];

    useMessageSubscription(devicesTokens, pin, refetch);

    return (
        <>
            <DoughnutChart
                isEmpty={loading || !chartData.length}
                data={chartData}
                title={title}
            />
        </>
    );
};

GroupedDevicesChart.propTypes = {
    chartId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    chartSettings: PropTypes.object,
};

GroupedDevicesChart.defaultProps = {
    chartSettings: {},
};
