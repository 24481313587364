import styled from 'styled-components';

import { Button } from '../../buttons';

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }

    input,
    select {
        width: 100%;
        flex: 1;
    }
`;

export const Column = styled.div`
    width: calc((100% - 15px) / 2);
    display: flex;

    & > * {
        flex: 1;
    }
`;

export const PresetWrapper = styled.div`
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 5px 10px;
    flex: 0;
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
    background-color: ${({ theme }) => theme.colors.boxBackground};

    & + * input,
    & + * select {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
`;

export const Title = styled.div`
    font-size: 16px;
    font-weight: bold;
`;

export const DeleteButton = styled.button`
    flex: 0;
    display: flex;
    align-items: center;
    font-size: 16px;
    text-transform: uppercase;
    padding: 5px 10px;
    cursor: pointer;
    background: #fff;
    color: ${({ theme }) => theme.colors.error};
`;

export const AddButton = styled(Button)`
    width: 100%;
    border: none;
    color: ${({ theme }) => theme.colors.active};
    font-weight: 600;
    box-shadow: none;
    &:hover, &:active, &:focus {
        color: ${({ theme }) => theme.colors.active};
        border: none;
    }
    svg {
        margin-right: 0.5rem;
    }
`;

export const Divider = styled.span`
    display: block;
    width: 100%;
    height: 1px;
    background-color: ${({ theme }) => theme.colors.border};
    margin: 1.5rem 0 0.5rem 0;
`;
