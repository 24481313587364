import { useRef, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';

import {
    RUN_MODEL,
    SET_TESTING,
    GET_ML_MODEL_BY_ID_QUERY,
    PROCESSING_STATUS,
    ML_RESPONSE_STATUSES,
} from 'Constants';
import { showToastError } from 'Utils';

import { getSampleTestData } from './helpers';

const setTestingStatuses = [
    ML_RESPONSE_STATUSES.SUCCESS,
    ML_RESPONSE_STATUSES.FAILURE,
    ML_RESPONSE_STATUSES.RETRY,
];

export const useSampleTest = ({ model, mlSamples, samples }) => {
    const apolloClient = useApolloClient();

    const timerRef = useRef({});

    const clearTimer = (id) => {
        if (timerRef.current?.[id]) {
            clearInterval(timerRef.current[id]);
        }
    };

    useEffect(() => () => {
        Object.values(timerRef.current || {}).forEach((intervalRef) => {
            clearInterval(intervalRef);
        });
    }, []);

    const processStatus = async (taskId, sampleId) => {
        const { data: { processingStatus } } = await apolloClient.query({
            query: PROCESSING_STATUS,
            variables: {
                taskId,
            },
            fetchPolicy: 'network-only',
        });
        const processingStatusObj = JSON.parse(processingStatus);

        if (setTestingStatuses.includes(processingStatusObj.state)) {
            await apolloClient.mutate({
                mutation: SET_TESTING,
                variables: {
                    modelId: model._id,
                    testing: processingStatus,
                },
                refetchQueries: [GET_ML_MODEL_BY_ID_QUERY],
            });

            clearTimer(sampleId);
        } else if (processingStatusObj.state === ML_RESPONSE_STATUSES.PENDING) {
            clearTimer(sampleId);
        }
    };

    const testSample = async (id) => {
        const modelDefinition = JSON.parse(model.modelDefinition);
        const trainedNNModel = JSON.parse(model.training).output.NN.model;

        modelDefinition.blocks = modelDefinition.blocks.map((block) => {
            let blockLocal = block;

            if (blockLocal.id === 'NN') {
                blockLocal = {
                    ...blockLocal,
                    command: 'run',
                    trained_nn_model: {
                        topology: trainedNNModel.topology,
                        weights: trainedNNModel.weights,
                        label_to_id: trainedNNModel.label_to_id,
                    },
                };
            }

            return blockLocal;
        });

        const sampleTestData = getSampleTestData({ mlSamples, samples, mlSampleId: id });

        if (!sampleTestData.samples.length) {
            showToastError('There is no samples to test');

            return;
        }

        const { data: { runModel } } = await apolloClient.query({
            query: RUN_MODEL,
            variables: {
               modelDefinition: JSON.stringify(modelDefinition),
               data: JSON.stringify(sampleTestData),
            },
            fetchPolicy: 'network-only',
        });

        await apolloClient.mutate({
            mutation: SET_TESTING,
            variables: {
               modelId: model._id,
               testing: JSON.stringify({ state: 'TESTING' }),
            },
            refetchQueries: [GET_ML_MODEL_BY_ID_QUERY],
        });

        const result = JSON.parse(runModel);

        clearTimer(id);

        timerRef.current[id] = setInterval(
            () => processStatus(result.task_id, id),
            1000,
        );
    };

    return {
        testSample,
    };
};
