import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { ALTITUDE_UNITS, GET_DEVICE_LAST_MESSAGE } from 'Constants';
import { getStringifiedPayload, calculateDataForMeters, calculateDataForYards } from 'Utils';
import { useMessageSubscription } from 'Hooks';

import { AltitudeWidget } from '../../../../widgets';

export const Altitude = ({ chartId, title, chartSettings, deviceToken }) => {
    const { unit, pin } = chartSettings;

    const { data, loading, refetch } = useQuery(
        GET_DEVICE_LAST_MESSAGE,
        {
            variables: { chartId },
        },
    );
    const lastMessage = data?.getDeviceLastMessage;

    useMessageSubscription([deviceToken], pin, refetch);

    const chartData = useMemo(() => {
        if (lastMessage) {
            const rawValue = getStringifiedPayload(lastMessage.payload);

            // recalculate the value depending on the number size
            switch (unit) {
                case ALTITUDE_UNITS.METER:
                    return calculateDataForMeters(rawValue);
                case ALTITUDE_UNITS.YARD:
                    return calculateDataForYards(rawValue);
                default:
                    break;
            }
        }

        return null;
    }, [lastMessage]);

    return (
        <AltitudeWidget
            data={chartData}
            isEmpty={loading || !chartData}
            title={title}
        />
    );
};

Altitude.propTypes = {
    chartId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    chartSettings: PropTypes.object.isRequired,
    deviceToken: PropTypes.string.isRequired,
};
