import { useMemo } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

import {
    PROJECT_CHART_TYPES,
    WEATHER_UNITS,
    TEMPERATURE_UNITS,
    ALTITUDE_UNITS,
} from 'Constants';

import { initialValues, getValidationSchema } from './config';
import { FormContent } from './formContent';

import * as Styled from './styled';

export const ProjectWidgetDescription = ({
    nextStep,
    prevStep,
    widgetInfo,
    widgetType,
    submitText,
    loading,
    isNameIncluded,
}) => {
    const onSubmit = (values) => {
        const returnedValues = { ...values };
        if (values.settings?.timePeriod) {
            returnedValues.settings.timePeriod = parseInt(values.settings.timePeriod, 10);
        }
        if (values.settings?.limit) {
            returnedValues.settings.limit = parseInt(values.settings.limit, 10);
        }
        nextStep({ ...widgetInfo, ...values });
    };

    const transformedInitialValues = useMemo(() => {
        const result = {
            name: widgetInfo.name || initialValues.name,
            settings: {
                ...initialValues.settings,
                ...widgetInfo.settings,
            },
        };

        if (!result.settings.unit) {
            switch (widgetType) {
                case PROJECT_CHART_TYPES.WEATHER:
                    result.settings.unit = WEATHER_UNITS.CELSIUS;
                    break;
                case PROJECT_CHART_TYPES.TEMPERATURE:
                    result.settings.unit = TEMPERATURE_UNITS.CELSIUS;
                    break;
                case PROJECT_CHART_TYPES.ALTITUDE:
                    result.settings.unit = ALTITUDE_UNITS.METER;
                    break;
                default:
                    break;
            }
        }

        return result;
    }, []);

    const validationSchema = useMemo(() => getValidationSchema(widgetType, isNameIncluded), [widgetType]);

    return (
        <>
            <Styled.Title>
                Please fill the data:
            </Styled.Title>
            <Formik
                onSubmit={onSubmit}
                initialValues={transformedInitialValues}
                validationSchema={validationSchema}
                validateOnMount
            >
                {(props) => (
                    <FormContent
                        {...props}
                        onBack={prevStep}
                        widgetType={widgetType}
                        submitText={submitText}
                        loading={loading}
                        isNameIncluded={isNameIncluded}
                    />
                )}
            </Formik>
        </>
    );
};

ProjectWidgetDescription.propTypes = {
    nextStep: PropTypes.func.isRequired,
    prevStep: PropTypes.func.isRequired,
    widgetType: PropTypes.string.isRequired,
    submitText: PropTypes.string,
    widgetInfo: PropTypes.object,
    loading: PropTypes.bool,
    isNameIncluded: PropTypes.bool,
};

ProjectWidgetDescription.defaultProps = {
    loading: false,
    widgetInfo: {},
    submitText: 'Save',
    isNameIncluded: true,
};
