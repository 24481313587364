import PropTypes from 'prop-types';

import { ReactComponent as EmptyIcon } from 'Assets/tiles/empty.svg';
import * as Styled from './styled';

export const NoData = ({ caption, text }) => (
    <>
        <Styled.NoDataWrapper>
            <Styled.Icon>
                <EmptyIcon />
            </Styled.Icon>
            <h2>{caption}</h2>
            <p>{text}</p>
        </Styled.NoDataWrapper>
    </>
);

NoData.propTypes = {
    caption: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
};
