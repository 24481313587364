import moment from 'moment';

export const releaseColumns = [
    {
        title: 'Firmware',
        dataIndex: 'firmware',
    },
    {
        title: 'Release date',
        dataIndex: 'createdAt',
        render: (data) => {
            const newData = moment(data, 'x').format('DD MMM YYYY');
            return newData;
        },
    },
    {
        title: 'Summary',
        dataIndex: 'summary',
    },
];
