import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';

import {
    REMOVE_SIGFOX_CREDENTIALS,
    GET_SIGFOX_CREDENTIALS,
    GET_DEVICE_TYPES,
    SIGFOX_CONTRACT_TYPE_CUSTOM,
} from 'Constants';
import { showToastError, showToastSuccess } from 'Utils';
import { Button, Modal, DeleteModal } from 'Components';

import * as Styled from './styled';
import { SigfoxContractSettingsModal } from './sigfoxContractSettingsModal';
import { getDeviceTypeIdOptions } from './config';

export const SigfoxExists = ({ credentials, other }) => {
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [contractSettingsModalOpen, setContractSettingsModalOpen] = useState(false);

    const { data: deviceTypesData } = useQuery(GET_DEVICE_TYPES);

    const [removeCredentials] = useMutation(REMOVE_SIGFOX_CREDENTIALS, {
        refetchQueries: [GET_SIGFOX_CREDENTIALS],
        awaitRefetchQueries: true,
    });

    const deviceTypeIdOptions = useMemo(() => getDeviceTypeIdOptions(deviceTypesData), [deviceTypesData]);

    const hideConfirmation = () => {
        setConfirmModalOpen(false);
    };

    const showConfirmation = () => {
        setConfirmModalOpen(true);
    };

    const disconnect = () => {
        try {
            removeCredentials({
                update: () => {
                    showToastSuccess('Sigfox Credentials disconnected successfully');
                    hideConfirmation();
                },
            });
        } catch (error) {
            showToastError(error.message);
        }
    };

    return (
        <>
            <Styled.Title>
                Sigfox API credentials
            </Styled.Title>

            <p>
                <Styled.DocumentationLink
                    href={process.env.REACT_APP_LINK_DOCS_SIGFOX}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Go to sigfox documentation
                </Styled.DocumentationLink>
            </p>

            <Styled.StrongText>
                Status
            </Styled.StrongText>
            <Styled.StrongText>
                Sigfox Integration Active
            </Styled.StrongText>

            <Styled.ValueWrapper>
                <Styled.StrongText>
                    Sigfox backend email:
                </Styled.StrongText>
                <span>
                    {other.email}
                </span>

                <Styled.StrongText>
                    Contract type:
                </Styled.StrongText>
                <span data-cy="sigfox-exists-contract-type">
                    {other.contractType}
                </span>

                {(other.contractType === SIGFOX_CONTRACT_TYPE_CUSTOM
                    && (
                        <>
                            <Styled.StrongText>
                                Device Type:
                            </Styled.StrongText>
                            <span data-cy="sigfox-exists-device-type-name">
                                {other.deviceTypeName}
                            </span>
                        </>
                    ))}
            </Styled.ValueWrapper>

            <Styled.SettingsButtonWrapper>
                <Button
                    onClick={() => setContractSettingsModalOpen(true)}
                    buttonType="transparent"
                    cyData="sigfox-contract-settings-button"
                >
                    Settings
                </Button>
            </Styled.SettingsButtonWrapper>

            <Styled.StrongText>
                Credentials
            </Styled.StrongText>

            <Styled.ValueWrapper>
                <Styled.StrongText>
                    Login:
                </Styled.StrongText>
                <span data-cy="sigfox-exists-login">
                    {credentials.login}
                </span>

                <Styled.StrongText>
                    Password:
                </Styled.StrongText>
                <span data-cy="sigfox-exists-password">
                    {credentials.password}
                </span>
            </Styled.ValueWrapper>

            <Button
                onClick={showConfirmation}
                cyData="sigfox-disconnect-button"
            >
                Disconnect
            </Button>

            <Modal isOpened={confirmModalOpen} handleClose={hideConfirmation}>
                <DeleteModal
                    title="Disconnect Sigfox Credentials"
                    description="Are you sure to disconnect Sigfox Credentials?"
                    deleteAction={disconnect}
                    cancelAction={hideConfirmation}
                    submitText="Disconnect"
                />
            </Modal>

            <SigfoxContractSettingsModal
                isShowModal={contractSettingsModalOpen}
                setIsShowModal={setContractSettingsModalOpen}
                deviceTypeId={other.deviceTypeId}
                contractType={other.contractType}
                deviceTypeIdOptions={deviceTypeIdOptions}
            />
        </>
    );
};

SigfoxExists.propTypes = {
    credentials: PropTypes.shape({
        login: PropTypes.string.isRequired,
        password: PropTypes.string.isRequired,
    }).isRequired,

    other: PropTypes.shape({
        contractType: PropTypes.string,
        deviceTypeId: PropTypes.string,
        deviceTypeName: PropTypes.string,
        email: PropTypes.string,
    }).isRequired,
};
