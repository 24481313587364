import styled from 'styled-components';

export const Title = styled.div`
    margin-top: 34px;
    margin-bottom: 25px;
    color: ${({ theme }) => theme.colors.title};
    font-size: 22px;
    line-height: 27px;
    font-weight: bold;
`;

export const StrongText = styled.p`
    color: ${({ theme }) => theme.colors.title};
    font-size: 16px;
    font-weight: bold;
    line-height: 1.38;
`;

export const ValueWrapper = styled.div`
    display: grid;
    grid-template-columns: max-content max-content;
    column-gap: 15px;
`;

export const SettingsButtonWrapper = styled.div`
    margin-bottom: 25px;
`;

export const DocumentationLink = styled.a`
    color: ${({ theme }) => theme.colors.active};
`;
