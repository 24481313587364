export class VaultService {
    constructor(type = 'local') {
        this.storage = type === 'local' ? localStorage : sessionStorage;
    }

    setItem(key, value) {
        const response = this.storage?.setItem(key, JSON?.stringify(value));
        return response;
    }

    getItem(key) {
        const response = JSON?.parse(this.storage?.getItem(key));
        return response;
    }

    clearAll() {
        this.storage.clear();
    }

    updateItem(key, value) {
        const prevValue = JSON?.parse(this.storage?.getItem(key));
        const newValue = { ...prevValue, value };
        const response = this.storage?.setItem(key, JSON?.stringify(newValue));
        return response;
    }

    removeItem(keys) {
        keys?.forEach((key) => {
            this.storage?.removeItem(key);
        });
    }
}
