import styled from 'styled-components';

export const Popover = styled.ul`
    list-style-type: none;
    width: 400px !important;
`;

export const Notification = styled.li`
    padding: 1rem 1.5rem;
    border-left: 5px solid ${({ theme, $type }) => theme.colors[$type]};
    background-color: rgba(0, 0, 0, 0.01);
    &:nth-of-type(2n+1) {
        background-color: rgba(0, 0, 0, 0.028);
    }
`;

export const Title = styled.span`
    display: block;
    font-size: 1rem;
    font-weight: 600;
`;

export const Content = styled.div`
    word-break: break-word;
    word-wrap: wrap;
`;

export const Badge = styled.div`
    position: absolute;
    right: -5px;
    top: -1px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.error};

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    user-select: none;
    & span {
        line-height: 1;
        font-size: 0.75rem;
        color: white;
    }
`;
