import { Form, Field } from 'formik';
import PropTypes from 'prop-types';

import { TextInput, Button } from 'Components';

import * as Styled from './styled';

export const FormContent = ({ goBack }) => (
    <Form>
        <Styled.Form>
            <Styled.FormItem>
                <div>Device Name</div>
                <Field
                    name="name"
                    type="text"
                    component={TextInput}
                    errorPosition="bottom"
                    placeholder="Input device name"
                />
            </Styled.FormItem>
            <Styled.FormItem>
                <div>Description</div>
                <Field
                    name="description"
                    type="text"
                    component={TextInput}
                    errorPosition="bottom"
                    placeholder="Input device description"
                />
            </Styled.FormItem>
            <div />
        </Styled.Form>
        <Styled.Buttons>
            <div>
                <Button buttonType="transparent" onClick={goBack}>
                    Back
                </Button>
            </div>
            <div>
                <Button type="submit" cyData="device-customization-submit">
                    Next
                </Button>
            </div>
        </Styled.Buttons>
    </Form>
);

FormContent.propTypes = {
    goBack: PropTypes.func.isRequired,
};
