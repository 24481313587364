import { useMemo, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Gauge } from '@ant-design/plots';

import { getChartValue, getConfig, getChartLabelsPositions, registerCustomPointer } from './helpers';
import { EmptyWidget } from '../emptyWidget';

import * as StyledGeneral from '../styled';
import * as Styled from './styled';

export const PressureWidget = ({ data, devicesCount, title, isEmpty }) => {
    const isDataBroken = Number.isNaN(+data);
    const chartWrapperRef = useRef(null);
    const [labelPositions, setLabelPositions] = useState({ start: {}, end: {} });

    registerCustomPointer();

    useEffect(() => {
        // top value indicates that real widget was rendered as Ref is not calling rerender
        if (chartWrapperRef.current && !labelPositions.start.top) {
            setLabelPositions(getChartLabelsPositions(chartWrapperRef.current));
        }
    }, [data]);

    const chartValue = useMemo(() => getChartValue(data), [data]);

    const config = useMemo(
        // with 0 value arrow disappears
        () => getConfig({ chartValue: chartValue || 0.001 }),
        [chartValue],
    );

    if (isEmpty || isDataBroken) {
        return <EmptyWidget title={title} isDataBroken={isDataBroken} />;
    }

    return (
        <Styled.Container>
            <Styled.Info>
                <div>
                    <StyledGeneral.ChartTitle>
                        {title}
                    </StyledGeneral.ChartTitle>
                    {devicesCount} device
                    {devicesCount > 1 && 's'}
                </div>
                <Styled.UnitsWrapper>
                    <Styled.Value>
                        {data}
                    </Styled.Value>
                    <Styled.Unit>
                        hPA
                    </Styled.Unit>
                </Styled.UnitsWrapper>
            </Styled.Info>
            <Styled.Chart ref={chartWrapperRef}>
                <Styled.GaugeLabel
                    $top={labelPositions.start.top}
                    $left={labelPositions.start.left}
                >
                    950
                </Styled.GaugeLabel>
                <Styled.GaugeLabel
                    $top={labelPositions.end.top}
                    $right={labelPositions.end.right}
                >
                    1050
                </Styled.GaugeLabel>
                <Gauge {...config} />
            </Styled.Chart>
        </Styled.Container>
    );
};

PressureWidget.propTypes = {
    data: PropTypes.string,
    devicesCount: PropTypes.number,
    title: PropTypes.string.isRequired,
    isEmpty: PropTypes.bool,
};

PressureWidget.defaultProps = {
    isEmpty: false,
    data: null,
    devicesCount: null,
};
