import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { GET_DEVICE_LAST_MESSAGE } from 'Constants';
import { getStringifiedPayload } from 'Utils';
import { useMessageSubscription } from 'Hooks';

import { HeartRateWidget } from '../../../../widgets';

export const HeartRate = ({ chartId, title, deviceToken, pin }) => {
    const { data, loading, refetch } = useQuery(
        GET_DEVICE_LAST_MESSAGE,
        {
            variables: { chartId },
        },
    );
    const lastMessage = data?.getDeviceLastMessage;

    useMessageSubscription([deviceToken], pin, refetch);

    const heartRate = useMemo(() => {
        if (lastMessage) {
            return getStringifiedPayload(lastMessage.payload);
        }

        return null;
    }, [lastMessage]);

    return (
        <HeartRateWidget
            data={heartRate}
            isEmpty={loading || !heartRate}
            title={title}
        />
    );
};

HeartRate.propTypes = {
    chartId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    deviceToken: PropTypes.string.isRequired,
    pin: PropTypes.number.isRequired,
};
