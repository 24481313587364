import styled from 'styled-components';

import { ReactComponent as Deploy } from 'Assets/devices/device.svg';

export const Wrapper = styled.div`
    button {
        text-transform: uppercase;
    }
`;

export const DeployIcon = styled(Deploy)`
    margin-right: 15px;
`;

export const ModalTitle = styled.div`
    width: 100%;
    height: 50px;
    padding: 15px 20px;
    background-color: ${({ theme }) => theme.colors.title};
    color: ${({ theme }) => theme.colors.activeText};
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
`;

export const ModalBody = styled.div`
    padding: 15px 20px 30px 25px;

    button {
        margin-top: 25px;
        text-transform: uppercase;
    }
`;

export const SpinTitle = styled.div`
    margin-right: 40px;
`;

export const SpinBox = styled.div`
    padding: 10px;
    display: flex;
    color: ${({ theme }) => theme.colors.active};
`;
