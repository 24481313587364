import PropTypes from 'prop-types';

import { DIMENSION_MULTIPLIERS } from 'Constants';

export const GridCell = ({ x, y, updateGridLocation }) => (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <div
        className="dashboardEditionGrid"
        style={{
            left: x * DIMENSION_MULTIPLIERS.width,
            top: y * DIMENSION_MULTIPLIERS.height,
        }}
        onMouseOver={() => { updateGridLocation({ x, y }); }}
    />
);

GridCell.propTypes = {
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    updateGridLocation: PropTypes.func.isRequired,
};
