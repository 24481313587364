import { generateDeviceNetworkIcon } from 'Utils';

import * as Styled from './styled';

export const projectDeviceColumns = [
    {
        title: 'Device name',
        dataIndex: 'description',
    },
    {
        title: 'Type',
        dataIndex: 'deviceType',
    },
    {
        title: 'FW',
        dataIndex: 'firmware',
        render: (data) => (data?.version ?? ''),
    },
    {
        title: 'Last online',
        dataIndex: 'lastConnection',
    },
    {
        title: 'Networks',
        dataIndex: 'networks',
        render: (data) => data.map((network) => (
            <Styled.Network key={network}>
                {generateDeviceNetworkIcon(network)}
            </Styled.Network>
        )),
    },
];
