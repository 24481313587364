import PropTypes from 'prop-types';

import { Modal } from '../../../modals';
import { Button } from '../../../buttons';

import * as Styled from './styled';

export const BorderRouterModal = ({ showModal, onClose }) => (
    <Modal
        isOpened={showModal}
        handleClose={onClose}
    >
        <Styled.ModalTitle>
            The Pymesh has no Border Router...
        </Styled.ModalTitle>
        <Styled.ModalBody>
            <p>
                The Pymesh needs at least one border router.
                It takes the traffic from the Pymesh and forwards it to the Cloud.
            </p>
            <p>
                The Border Router node should have connection with the Cloud.
                This should occur using another network interface (other than LoRa-Pymesh),
                for example: BLE, Wifi or Cellular.
            </p>
            <Button onClick={onClose}>
                Got it
            </Button>
        </Styled.ModalBody>
    </Modal>
);

BorderRouterModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};
