import styled from 'styled-components';

export const Wrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    
    ${({ $noActive }) => $noActive && `
        height: calc(100% - 30px);
        margin-top: 30px;
        border-radius: 5px 0 0 0;
        background-color: #fff;
    `}
`;

export const TabsWrapper = styled.div`
    display: flex;
    max-width: calc(95% - 175px);
    height: 30px;
`;
