import styled from 'styled-components';

export const Modal = styled.div`
    width: 100%;
`;

export const Wrapper = styled.div`
    padding: 15px 20px;
`;

export const Title = styled.div`
    width: 100%;
    height: 50px;
    background-color: ${({ theme }) => theme.colors.title};
    padding: 15px 20px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    ${'' /* decrease top margin of nested component */}
    margin-bottom: -30px;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    padding-bottom: 10px;
    
    & > *:first-child {
        margin-right: 30px;
    }
`;
