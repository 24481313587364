export const columns = [
    {
        title: 'Method',
        dataIndex: 'requestedCategory',
    },
    {
        title: 'Status',
        dataIndex: 'status',
    },
    {
        title: 'Date',
        dataIndex: 'createdAt',
    },
    {
        title: 'IP',
        dataIndex: 'ip',
    },
];

export const statuses = {
    SUCCESS: 'success',
    FAILED: 'failure',
    UNKNOWN: 'unknown',
    LIMIT: 'limit exceed',
    NONE: 'none',
};

export const dateRanges = {
    TODAY: 'last 24h',
    YESTERDAY: 'last two days',
    WEEK: 'last week',
    MONTH: 'last month',
    NONE: 'none',
};

export const filterTypes = {
    STATUS: 'status',
    DATE: 'date',
};
