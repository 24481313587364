import { COLORIZER_ACTION_TYPES } from './actionTypes';

export const colorizerInitialState = {
    updatedFiles: [],
    resetMD5: false,
};

export const colorizerReducer = (state, action) => {
    switch (action.type) {
        case COLORIZER_ACTION_TYPES.SET_UPDATED_FILES:
            return {
                ...state,
                updatedFiles: action.payload.files,
            };
        case COLORIZER_ACTION_TYPES.SET_RESET_MD5:
            return {
                ...state,
                resetMD5: action.payload.reset,
            };
        default:
            return state;
    }
};
