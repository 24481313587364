import { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
    SAVE_LICENSE_AGREEMENT,
    AUTHORIZE_PYMESH_DEVICE,
    SAVE_PYMESH,
    ROUTES,
    PYMESH_AUTHORIZATION_STATUSES,
    PYMESH_BANDWIDTHS,
    PYMESH_REGIONS,
} from 'Constants';
import { showToastError, showToastInfo, showToastSuccess } from 'Utils';
import { GlobalContext } from 'Context';

import { WizardComponentBox } from '../wizardComponentBox';

import * as Styled from './styled';

export const PymeshSummary = ({ applicationId, previousStep, values }) => {
    const history = useHistory();

    const globalContext = useContext(GlobalContext);
    const session = globalContext.session;

    const [saveLicenseMutation] = useMutation(SAVE_LICENSE_AGREEMENT);
    const [authorizeDeviceMutation] = useMutation(AUTHORIZE_PYMESH_DEVICE);
    const [savePymeshMutation] = useMutation(SAVE_PYMESH);

    const region = values.region;
    const bandwidth = values.bandwidth;
    const frequency = +values.frequency;
    const spreadFactor = +values.spreadFactor;
    const joinKey = values.joinKey;
    const devices = values.devices.map((device) => ({ token: device.token, brEnable: device.brEnable }));

    const regionValue = PYMESH_REGIONS.find((pymeshRegion) => pymeshRegion.region.name === region).region.value;
    const bandwidthValue = PYMESH_BANDWIDTHS.find((pymesBandwidth) => pymesBandwidth.label === bandwidth).value;

    const savePymesh = async () => {
        const newPymesh = {
            region: +regionValue,
            bandwidth: +bandwidthValue,
            applicationId,
            frequency,
            spreadFactor,
            joinKey,
            devices,
        };

        const licenseAgreement = {
            user: session.name,
            licenseProduct: 'Pymesh firmware',
        };

        try {
            await saveLicenseMutation({
                variables: {
                    licenseAgreement,
                },
            });
        } catch (error) {
            console.error(error);
            showToastError('Failed to save license agreement');
        }

        try {
            await savePymeshMutation({
                variables: { newPymesh },
                update: (() => {
                    showToastSuccess('Pymesh updated successfully');
                }),
            });
        } catch (error) {
            console.error(error);
            showToastError('Failed to save Pymesh');
            return;
        }

        const authorizeDevicesPromises = values.devices.map((device) => authorizeDeviceMutation({
            variables: {
                token: device.token,
                wmac: device.mac.toUpperCase() || undefined,
            },
        }));

        const authorizeDeviceResult = await Promise.allSettled(authorizeDevicesPromises);
        authorizeDeviceResult.forEach((item) => {
            const status = item.value?.data?.authorizePymeshDevice && JSON.parse(item.value?.data?.authorizePymeshDevice) === 200
                ? PYMESH_AUTHORIZATION_STATUSES.AUTHORIZED
                : PYMESH_AUTHORIZATION_STATUSES.FAILED;

            showToastInfo(`authorization status: ${status}`);
        });

        history.push(`${ROUTES.projects.main}/${applicationId}${ROUTES.projects.projectDetails.pymesh}`);
    };

    return (
        <WizardComponentBox
            title="Pymesh Summary"
            // eslint-disable-next-line max-len
            description="After pressing Save button, you will have the Mesh with a basic structure. Further you can add more devices to your Mesh."
            isLastStep
            nextStep={savePymesh}
            previousStep={previousStep}
        >
            <Styled.SummaryBox>
                <Styled.Header>
                    Network settings
                </Styled.Header>
                <Styled.Content>
                    <Styled.ContentColumn>
                        <Styled.ContentItem>
                            <Styled.ContentTitle>
                                Region
                            </Styled.ContentTitle>
                            {region}
                        </Styled.ContentItem>
                        <Styled.ContentItem>
                            <Styled.ContentTitle>
                                Bandwidht
                            </Styled.ContentTitle>
                            {bandwidth}
                        </Styled.ContentItem>
                    </Styled.ContentColumn>
                    <Styled.ContentColumn>
                        <Styled.ContentItem>
                            <Styled.ContentTitle>
                                Frequency
                            </Styled.ContentTitle>
                            {frequency}
                        </Styled.ContentItem>
                        <Styled.ContentItem>
                            <Styled.ContentTitle>
                                Spread Factor
                            </Styled.ContentTitle>
                            {spreadFactor}
                        </Styled.ContentItem>
                    </Styled.ContentColumn>
                </Styled.Content>
                <Styled.Header>
                    Join Key
                </Styled.Header>
                {joinKey}
            </Styled.SummaryBox>
        </WizardComponentBox>
    );
};

PymeshSummary.propTypes = {
    applicationId: PropTypes.string.isRequired,
    previousStep: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
};
