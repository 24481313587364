import { Dropdown, CustomTag } from 'Components';
import { ReactComponent as WifiIcon } from 'Assets/icons/wifi.svg';
import { ReactComponent as EyeOpened } from 'Assets/icons/eye-open.svg';
import { ReactComponent as EyeClosed } from 'Assets/icons/eye-closed.svg';

import { setTagTitle } from './helpers';
import * as Styled from './styled';

export const getColumns = ({
    editWifi,
    deleteWifi,
    showPasswordsArr,
    togglePassword,
}) => {
    const dropdownActions = [
        {
            executedFunction: editWifi,
            title: 'Edit',
        },
        {
            executedFunction: deleteWifi,
            title: 'Delete',
        },
    ];

    return [
        {
            title: '',
            dataIndex: '_id',
            width: '15%',
            render: () => (
                <Styled.IconWrapper>
                    <WifiIcon />
                </Styled.IconWrapper>
            ),
        },
        {
            title: 'SSID',
            dataIndex: 'ssid',
            width: '35%',
        },
        {
            title: 'Security',
            dataIndex: 'password',
            width: '35%',
            render: (value, originItem, index) => {
                if (!value) {
                    return (
                        <CustomTag
                            title={setTagTitle(originItem.secureType?.toLowerCase())}
                            border
                        />
                    );
                }

                return showPasswordsArr[index]
                    ? (
                        <>
                            <Styled.IconWrapper
                                onClick={() => togglePassword(index)}
                            >
                                <EyeOpened />
                            </Styled.IconWrapper>
                            <Styled.PasswordValue>
                                {value}
                            </Styled.PasswordValue>
                        </>
                    )
                    : (
                        <>
                            <Styled.IconWrapper
                                onClick={() => togglePassword(index)}
                            >
                                <EyeClosed />
                            </Styled.IconWrapper>
                            <Styled.PasswordValue>
                                {'*'.repeat(value.length)}
                            </Styled.PasswordValue>
                        </>
                    );
            },
        },
        {
            title: 'Actions',
            dataIndex: '_id',
            width: '15%',
            render: (id) => (
                <Dropdown
                    actions={dropdownActions}
                    id={id}
                />
            ),
        },
    ];
};
