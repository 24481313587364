import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';

import { NetworksForm, Loader } from 'Components';
import { showToastSuccess } from 'Utils';
import {
    UPDATE_DEVICE_NETWORK_MUTATION,
    GET_ALL_FILTERED_DEVICES_QUERY,
    GET_DEVICE_BY_TOKEN_QUERY,
    GET_DEVICE_ALLOWED_NETWORKS,
    GET_LTE_SETTINGS,
    PUBLISH_DEVICE_NETWORKS_MUTATION,
} from 'Constants';

import { getInitialValues } from './config';

export const DeviceNetworksForm = ({ device }) => {
    const { data: lteData, loading } = useQuery(GET_LTE_SETTINGS);
    const lteList = lteData?.getLteSettings ?? [];

    const { data: allowedNetworksData, loading: allowedNetworksLoading } = useQuery(
        GET_DEVICE_ALLOWED_NETWORKS,
        {
            variables: {
                input: {
                    deviceType: device?.deviceType,
                    shieldType: device?.shield?.type,
                },
            },
            skip: !device?.deviceType,
        },
    );
    const allowedNetworks = allowedNetworksData?.getDeviceAllowedNetworks?.allowedNetworks ?? [];
    const [deployNetworkChanges, { loading: isLoadingDeploy }] = useMutation(PUBLISH_DEVICE_NETWORKS_MUTATION);
    const [updateDevice, { loading: isLoading }] = useMutation(
        UPDATE_DEVICE_NETWORK_MUTATION,
        { refetchQueries: [GET_ALL_FILTERED_DEVICES_QUERY, GET_DEVICE_BY_TOKEN_QUERY] },
    );

    if (loading || allowedNetworksLoading) {
        return <Loader />;
    }

    const onSubmit = (values) => {
        let networks = values.networks;
        if (values.networks.includes('lora')) {
            networks = values.networks.map((item) => {
                if (item === 'lora') {
                    return `lora_${values.loraType.split(' ')[1].toLowerCase()}`;
                }
                return item;
            });
        }
        const lte = lteList.find((item) => item._id === values.lteId);
        updateDevice({
            variables: {
                device: {
                    token: device.token,
                    name: device.description,
                    loraRegion: values.loraRegion,
                    loraServerType: values.loraServer,
                    loraType: values.loraType,
                    wifiId: values.wifiId,
                    ethernet: values.ethernet,
                    lte: lte
                        ? {
                            apn: lte.apn,
                            band: lte.band,
                            carrier: lte.carrier,
                            cid: lte.cid,
                            name: lte.name,
                            protocol: lte.protocol,
                            reset: lte.reset,
                        }
                        : null,
                    networks,
                },
            },
            update: () => {
                deployNetworkChanges({
                    variables: { 
                        deviceToken: device.token, 
                    },
                    update: () => {
                        showToastSuccess('Deploy request sent successfully');
                    },
                });
            },
        });
    };

    return (
        <NetworksForm
            initialValues={getInitialValues(device, lteList)}
            onSubmit={onSubmit}
            submitButtonColor="transparent"
            submitText="Save and Deploy"
            hideBackButton
            initialValid={false}
            allowedNetworks={allowedNetworks}
            isLoading={isLoading || isLoadingDeploy}
        />
    );
};

DeviceNetworksForm.propTypes = {
    device: PropTypes.object,
};

DeviceNetworksForm.defaultProps = {
    device: {},
};
