export const columns = [
    {
        title: 'Class',
        dataIndex: 'sample',
    },
    {
        title: 'Nº of moving window',
        dataIndex: 'data',
        render: (value) => value.x.length,
    },
];
