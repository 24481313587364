import { Form, Field } from 'formik';
import PropTypes from 'prop-types';

import { TextInput, Checkbox } from '../../../inputs';
import { Select } from '../../../select';
import { Button } from '../../../buttons';
import { CARRIER, BAND } from './config';

import * as Styled from './styled';

export const FormContent = ({ handleClose, values, isLoading }) => (
    <Form>
        <Styled.Form>
            <div>
                <Styled.Item>
                    <span>Name</span>
                    <Field
                        name="name"
                        type="text"
                        component={TextInput}
                        errorPosition="bottom"
                    />
                </Styled.Item>

                <Styled.Item>
                    <span>Carrier</span>
                    <Field
                        name="carrier"
                        component={Select}
                        values={CARRIER}
                        custom={false}
                    />
                </Styled.Item>
                <Styled.Item>
                    <span>Band</span>
                    <Field
                        name="band"
                        component={Select}
                        values={BAND}
                        custom={false}
                    />
                </Styled.Item>
                <Styled.Item>
                    <span>CID</span>
                    <Field
                        name="cid"
                        component={TextInput}
                        type="number"
                        errorPosition="bottom"
                    />
                </Styled.Item>
                <Styled.Item>
                    <Styled.Checkbox role="presentation">
                        <Field
                            name="checkbox"
                            component={Checkbox}
                            title="Specify APN and Type"
                        />
                    </Styled.Checkbox>
                </Styled.Item>
                <Styled.Item>
                    <span>APN</span>
                    <Field
                        name="apn"
                        component={TextInput}
                        type="text"
                        disabled={!values.checkbox}
                    />
                </Styled.Item>
                <Styled.Item>
                    <span>Type</span>
                    <Field
                        name="protocol"
                        component={Select}
                        values={['IP', 'IPV6V4']}
                        custom={false}
                    />
                </Styled.Item>
                <Styled.Item>
                    <Styled.Checkbox>
                        <Field
                            name="reset"
                            type="checkbox"
                            component={Checkbox}
                            title="Reset modern on disconnect"
                        />
                    </Styled.Checkbox>
                </Styled.Item>
            </div>
        </Styled.Form>
        <Styled.ButtonsWrapper>
            <div>
                <Button
                    buttonType="transparent"
                    onClick={handleClose}
                >
                    Cancel
                </Button>
                <Button type="submit" loading={isLoading}>
                    Save
                </Button>
            </div>
        </Styled.ButtonsWrapper>
    </Form>
);

FormContent.propTypes = {
    handleClose: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
};

FormContent.defaultProps = {
    isLoading: false,
};
