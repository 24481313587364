import { Form, Field } from 'formik';
import PropTypes from 'prop-types';

import { Select, Checkbox, Button } from 'Components';
import { speedRatesOptions, fileSystemOptions } from 'Constants';

import * as Styled from './styled';

export const FormContent = ({ isValid, onClose, dirty }) => (
    <Styled.FormWrapper>
        <Styled.Header>Configurations</Styled.Header>
        <Form>
            <Styled.FormContent>
                <Styled.FormItem>
                    <Styled.FormTitle>Speed</Styled.FormTitle>
                    <Field
                        name="baudRate"
                        component={Select}
                        values={speedRatesOptions}
                        placeholder="Select speed"
                    />
                </Styled.FormItem>
                <Styled.FormItem>
                    <Styled.FormTitle>File System</Styled.FormTitle>
                    <Field
                        name="fileSystem"
                        component={Select}
                        values={fileSystemOptions}
                        placeholder="Select file system"
                    />
                </Styled.FormItem>
                <Styled.Checkboxes>
                    <Field
                        component={Checkbox}
                        name="eraseFs"
                        title="Erase flash file system"
                    />
                    <Field
                        component={Checkbox}
                        name="eraseNvs"
                        title="Erase NVS"
                    />
                </Styled.Checkboxes>
                <Styled.FormButton>
                    <Button buttonType="transparent" onClick={onClose}>Cancel</Button>
                    <Button disabled={!isValid || !dirty} type="submit">Upload</Button>
                </Styled.FormButton>
            </Styled.FormContent> 
        </Form>
    </Styled.FormWrapper>
);

FormContent.defaultProps = {
    isValid: false,
    dirty: false,
};

FormContent.propTypes = {
    isValid: PropTypes.bool,
    dirty: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
};
