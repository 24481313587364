import { CustomTag } from 'Components';
import { getDeviceCertificateTagColor } from 'Utils';

export const columns = [
    {
        title: 'Certificate',
        dataIndex: 'fingerprint',
    },
    {
        title: 'Device',
        dataIndex: 'deviceId',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        render: (data) => (
            <CustomTag 
                title={data}
                fill={getDeviceCertificateTagColor(data)}
            />
        ),
    },
    {
        title: 'Type',
        render: () => 'MQTT',
    },
];
