import styled from 'styled-components';

export const Title = styled.div`
    color: ${({ theme }) => theme.colors.title};
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: bold;
`;

export const SortWrapper = styled.div`
    margin-bottom: 30px;
    display: flex;
`;

export const FirstNotificationWrapper = styled.div`
    margin-bottom: 10px;
`;

export const NotificationWrapper = styled.div`
    display: flex;
`;

export const NotificationIcon = styled.div`
    margin-right: 14px;
    font-size: 30px;
    color: ${({ theme }) => theme.colors.title};
    line-height: 0;

    & + * {
        padding-top: 5px;
    }
`;

export const NotificationLabel = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-size: 16px;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    margin-top: 50px;

    
    & > * {
        margin-right: 30px;
    }
`;

export const DragItemWrapper = styled.div`
    padding: 10px 0;
    display: flex;
    align-items: center;
`;

export const NumberItemsWrapper = styled.div`
    margin-right: 30px;
`;

export const ItemNumber = styled.div`
    height: 60px;
    padding: 10px 0;
    display: flex;
    align-items: center;
`;

export const ThingNetworkLink = styled.div`
    margin: 0 5px;
    color: ${({ theme }) => theme.colors.active};
    transition: color 0.3s;

    &:hover {
        color: ${({ theme }) => theme.colors.text};
    }
`;

export const SelectsWrapper = styled.div`
    display: flex;
    align-items: center;

    select {
        background-color: #fff;
    }
`;

export const Arrow = styled.div`
    margin: 0 25px;
`;

export const LoraFormWrapper = styled.div`
    display: flex;

    select {
        max-width: 225px;
        margin-right: 15px;

        &:last-child {
            margin-right: 0;
        }
    }
`;
