import { useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { pymakrExportStates, GET_ZIP_FILE, ROUTES } from 'Constants';
import { Loader } from 'Components';
import { prepareMappedHierarchy, clearLocalStorageByCondition } from 'Utils';

import { useContextSelector } from '../../../pymakrHooks';
import { ExportToRelease, ExportUploadDevice } from './exports';
import * as Styled from './styled';

export const SaveAndExportMicroPython = ({ projectKey, title, onClose }) => {
    const [exportState, setExportState] = useState(pymakrExportStates.initial);
    const history = useHistory();
    const apolloClient = useApolloClient();

    const editorSlice = useContextSelector('editor');
    const { refreshComplete } = editorSlice.handlers;

    const cleanStorage = () => {
        clearLocalStorageByCondition(projectKey);
        refreshComplete();
    };

    const downloadFiles = async () => {
        setExportState(pymakrExportStates.release);
        const storedData = JSON.parse(localStorage.getItem(projectKey));
        const mappedHierarchy = prepareMappedHierarchy(storedData.hierarchy, 0);

        const { data } = await apolloClient.query({
            query: GET_ZIP_FILE,
            variables: {
                hierarchy: mappedHierarchy,
            },
        });

        return data?.getZipFileFromHierarchy ?? '';
    };

    const onSelectProject = async (project) => {
        const file64 = await downloadFiles();
        cleanStorage();
        localStorage.setItem('pymakr-release-file', JSON.stringify({ hierarchy: file64 }));
        setExportState(pymakrExportStates.download);
        history.push(
            `${ROUTES.projects.main}/${project._id}${ROUTES.projects.projectDetails.releaseCreate}`,
        );
    };

    const onZipDownload = async () => {
        const file64 = await downloadFiles();

        const element = document.createElement('a');
        element.setAttribute('href', `data:text/plain;base64,${file64}`);
        element.setAttribute('download', `pymakr-${title}.zip`);
        element.click();

        cleanStorage();
        onClose();
    };

    switch (exportState) {
        case pymakrExportStates.initial:
            return (
                <Styled.Wrapper>
                    <h2>How do you want to export your project?</h2>
                    <Styled.OptionBtn
                        buttonType="transparent"
                        onClick={() =>
                            setExportState(
                                pymakrExportStates.releaseSelectProject,
                            )}
                    >
                        Include in Release
                    </Styled.OptionBtn>
                    <Styled.OptionBtn
                        buttonType="transparent"
                        onClick={onZipDownload}
                    >
                        Create and download Zip file
                    </Styled.OptionBtn>
                    <Styled.OptionBtn
                        buttonType="transparent"
                        onClick={() =>
                            setExportState(pymakrExportStates.upload)}
                    >
                        Upload to Device
                    </Styled.OptionBtn>
                </Styled.Wrapper>
            );
        case pymakrExportStates.releaseSelectProject:
            return <ExportToRelease onSelectProject={onSelectProject} />;
        case pymakrExportStates.download:
            return <Loader title="Generating files..." />;
        case pymakrExportStates.upload: {
            const storedData = JSON.parse(localStorage.getItem(projectKey));
            const mappedHierarchy = prepareMappedHierarchy(
                storedData.hierarchy,
                0,
            );

            return (
                <ExportUploadDevice
                    cleanStorage={cleanStorage}
                    onClose={onClose}
                    files={mappedHierarchy.filter(
                        (item) => item.content !== undefined,
                    )}
                />
            );
        }
        default:
            return <div />;
    }
};

SaveAndExportMicroPython.propTypes = {
    projectKey: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};
