export const flowers = [
    'alpenrose',
    'amaryllis',
    'beeblossom',
    'begonia',
    'bearberry',
    'bluestar',
    'bluet',
    'camellia',
    'candytuft',
    'carnation',
    'catmint',
    'chrysanthemum',
    'clematis',
    'cliffbush',
    'cockscomb',
    'conebush',
    'coneflower',
    'cranesbill',
    'cyclamen',
    'daffodil',
    'dahlias',
    'feverfew',
    'fiddleneck',
    'fivespot',
    'foxglove',
    'frangipani',
    'freesias',
    'fuchsias',
    'gayfeather',
    'gillyflower',
    'gladioli',
    'hibiscus',
    'hollyhock',
    'honesty',
    'honeycup',
    'hydrangea',
    'larkspur',
    'lisianthus',
    'liverleaf',
    'majorwort',
    'meadowsweet',
    'moonflower',
    'oleander',
    'paperbush',
    'petunia',
    'quesnelia',
    'rosinweed',
    'snapdragon',
    'sneezeweed',
    'snowdrop',
    'speedwell',
    'stonecrop',
    'tulip',
    'wallflower',
];
