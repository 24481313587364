export const firmwareInitialValues = {
    ports: [],
    region: '',
    regionList: [],
    countriesList: [],
    timeOut: [],
    userConfiguration: {},
    errorEqualDevice: false,
    customFile: {},
    devicesToForm: [],
    logs: [],
    firmwareConfig: {},
    firmwareTime: null,
    deviceType: '',
    countriesForForm: [],
    enableSelectRegion: false,
    enableSelectSigfoxRegion: false,
    deviceLongName: null,
    mac: null,
    binary: null,
    remoteConfig: null,
    sigfoxObj: null,
    selectedLoraRegion: null,
    loramac: null,
    sigfoxId: null,
    sigfoxPac: null,
    sigfoxPrivateKey: null,
    sigfoxPublicKey: null,
};

export const DEVICES = {
    LOPY: 'lopy',
    SIPY: 'sipy',
    LOPY4: 'lopy4',
    FIPY: 'fipy',
};
