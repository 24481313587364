import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';

import { GET_CERTIFICATE_BY_OWNER } from 'Constants';
import { Table, Spin, IconButton, Loader } from 'Components';

import { ReactComponent as CPUIcon } from 'Assets/icons/cpu.svg';
import { ReactComponent as DownloadIcon } from 'Assets/icons/download.svg';

import { columns } from './columns';
import * as Styled from './styled';

export const Certificates = () => {
    const [initialLoading, setInitialLoading] = useState(true);
    const [selectedIds, setSelectedIds] = useState([]);
    const [pageState, setPageState] = useState({
        limit: 10,
        offset: 0,
    });

    const { data: certificatesData, loading: isLoadingCertificates } = useQuery(
        GET_CERTIFICATE_BY_OWNER,
        {
            variables: { limit: pageState.limit, offset: pageState.offset },
        },
    );
    const certificates = certificatesData?.getCertificates?.certificates ?? [];

    useEffect(
        () => {
            if (initialLoading && !isLoadingCertificates) {
                setInitialLoading(false);
            }
        },
        [isLoadingCertificates],
    );
    
    const page = pageState.offset / pageState.limit + 1;
    const hasPreviousPage = page > 1;
    const hasNextPage = certificatesData?.getCertificates?.hasNextPage ?? false;

    const paginationHandler = ({ cursors }) => {
        const nextPage = cursors.after === '' ? page + 1 : page - 1;
        const offset = pageState.limit * (nextPage - 1);
        setPageState({ ...pageState, offset });
    };

    const onClickDownload = () => {
        // DISABLED FEATURE ATM - NOT PART OF DOD
        // TODO: write function to download selected certificates
        console.log(selectedIds);
    };
    const onClickRevoke = () => {
        // DISABLED FEATURE ATM - NOT PART OF DOD
        // TODO: write function to revoke selected certificates
        console.log(selectedIds);
    };

    if (initialLoading) {
        return <Loader />;
    }

    return (
        <Styled.Wrapper>
            <Styled.ButtonRow>
                <IconButton
                    Icon={DownloadIcon}
                    onClick={onClickDownload}
                    disabled
                >
                    Download
                </IconButton>
                <IconButton
                    Icon={CPUIcon}
                    onClick={onClickRevoke}
                    disabled
                >
                    Revoke
                </IconButton>
            </Styled.ButtonRow>
            <Spin spinning={isLoadingCertificates}>
                <Table
                    data={certificates}
                    columns={columns}
                    onSelect={setSelectedIds}
                    rowKey="fingerprint"
                    pagination={{ ...pageState, hasPreviousPage, hasNextPage }}
                    onPaginationChange={paginationHandler}
                />
            </Spin>
        </Styled.Wrapper>
    );
};
