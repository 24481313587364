import { generateDeviceNetworkIcon } from 'Utils';

import * as Styled from './styled';

export const projectColumns = [
    {
        title: 'Projects',
        dataIndex: 'name',
    },
    {
        title: 'Number of devices',
        dataIndex: 'numOfDevice',
    },
    {
        title: 'Networks',
        dataIndex: 'networks',
        render: (data) => data.map((network) => (
            <Styled.Network key={network}>
                {generateDeviceNetworkIcon(network)}
            </Styled.Network>
        )),
    },
];
