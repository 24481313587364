import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { ReactComponent as App } from 'Assets/icons/phone.svg';
import { ReactComponent as USB } from 'Assets/icons/usb-top.svg';
import { ROUTES } from 'Constants';

import { Tile } from '../../buttons';
import * as Styled from './styled';

export const DevicesStarterPage = () => {
    const styledTheme = useContext(ThemeContext);

    return (
        <Styled.Tiles data-cy="device-starter-page-wrapper">
            <Tile
                icon={<USB />}
                title="Via USB"
                links={[ROUTES.devices.add]}
                cyData="add-device-button-empty"
                isNew
            />
            <Tile
                icon={<App />}
                title={`Via ${styledTheme.whiteLabelCompany} App`}
                links={['']}
                isNew
            />
        </Styled.Tiles>
    );
};
