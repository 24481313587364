import styled from 'styled-components';
import { Skeleton } from 'antd';

export const Title = styled.div`
    font-size: 22px;
    line-height: 27px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.title};
`;

export const PageContent = styled.div`
    margin-top: 35px;
`;

export const Loading = styled(Skeleton.Button)`
    .ant-skeleton-button {
        height: 60px;
        width: 100%;
        margin: 10px 0;
    }
`;

export const RowSearch = styled.div`
    display: flex;
    align-items: center;
    margin-top: 32px;
    padding-left: 10px;

    svg:first-child {
        font-size: 30px;
        margin-right: 10px;
        color: ${({ theme }) => theme.colors.border};
    }

    svg:last-child {
        color: ${({ theme }) => theme.colors.title};
    }

    & input {
        width: 620px;
        height: 40px;
    }

    & input + div {
        left: 580px;
    }
`;

export const List = styled.div`
    margin: 22px 0;
`;

export const Buttons = styled.div`
    display: flex;

    button:first-child {
        margin-right: 20px;
    }
`;

export const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
`;

export const Range = styled.div`
    display: flex;
    align-items: center;

    & select {
        width: 62px;
    }
`;

export const RangeTitle = styled.div`
    font-size: 14px;
    line-height: 17px;
    margin-right: 8px;
`;

export const Message = styled.div`
    font-size: 18px;
    line-height: 24px;
    margin-top: 10px;
`;
