import PropTypes from 'prop-types';

import { capitalizeFirstLetter } from 'Utils';
import { EmptyWidget } from '../emptyWidget';

import * as Styled from './styled';
import * as StyledGeneral from '../styled';

export const OnlineOfflineWidget = ({ data, isEmpty, title }) => {
    if (!data.length || isEmpty) {
        return <EmptyWidget title={title} />;
    }

    const online = { ...data[0], name: capitalizeFirstLetter(data[0].name) };
    const offline = { ...data[1], name: capitalizeFirstLetter(data[1].name) };

    return (
        <>
            <StyledGeneral.ChartTitle>
                {title}
            </StyledGeneral.ChartTitle>
            <Styled.OnlineContainer>
                <Styled.OnlineCounts>
                    <div>
                        <Styled.OnlineNumber>
                            {online.count}
                        </Styled.OnlineNumber>
                        <div>
                            {online.name}
                        </div>
                    </div>
                    <div>
                        <Styled.OfflineNumber>
                            {offline.count}
                        </Styled.OfflineNumber>
                        <div>
                            {offline.name}
                        </div>
                    </div>
                </Styled.OnlineCounts>
                <Styled.Slider
                    defaultValue={online.count}
                    disabled={false}
                    draggableTrack={true}
                    min={0}
                    max={online.count + offline.count}
                />
            </Styled.OnlineContainer>
        </>
    );
};

OnlineOfflineWidget.propTypes = {
    data: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    isEmpty: PropTypes.bool,
};

OnlineOfflineWidget.defaultProps = {
    isEmpty: false,
};
