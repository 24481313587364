import styled from 'styled-components';

export const Wrapper = styled.div`
    margin-top: 20px;
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
`;
