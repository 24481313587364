import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
    :root {
        --toastify-color-success: ${({ theme }) => theme.colors.success};
        --toastify-color-warning: ${({ theme }) => theme.colors.warning};
        --toastify-color-error: ${({ theme }) => theme.colors.error};
    }

    body,
    html,
    #root {
        background-color: ${({ theme }) => theme.colors.boxBackground};
        height: 100%;
    }

    * {
        padding: 0;
        margin: 0;
        border: 0;
        box-sizing: border-box;
        outline: none;
        text-decoration: none;
        font-family: MainFont;
    }

    ul {
        list-style: none;
        padding: 0;
    }

    a,
    a:hover {
        color: inherit;
    }

    .noselect {
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .ant-popover-inner-content {
        padding: 0;
    }

    /* TODO: Think about how adapting to TVs, ultrawide screens etc
    @media (min-width: 2000px) {
        html {
            font-size: 20px;
        }
    } */
`;
