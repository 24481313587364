import * as Yup from 'yup';

import { ValidationService } from 'Services';
import { HTTP_METHODS, REQUEST_FORMATS } from 'Constants';

export const collapsibleLabels = [
    {
        title: 'General Integration settings',
        description: 'URL and  Request specification',
        cyData: 'webhook-general-settings-label',
    },
    {
        title: 'Body Definition',
        description: 'Main parameters and their values',
        cyData: 'webhook-body-definition-label',
    },
    {
        title: 'Query Parameters',
        description: 'Add as much parameters as you want',
        cyData: 'webhook-query-parameters-label',
    },
    {
        title: 'HTTP Headers',
        description: 'Add as much Headers as you want',
        cyData: 'webhook-http-headers-label',
    },
    {
        title: 'HTTP Authentication (Optional)',
        description: 'The username and  the password',
        cyData: 'webhook-http-authentication-label',
    },
    {
        title: 'Final Request',
        description: 'The Final Request code',
        cyData: 'webhook-final-request-label',
    },
    {
        title: 'Devices for integrations',
        description: 'Choose the devices what you want to integrate',
        cyData: 'webhook-devices-label',
    },
];

export const httpMethodOptions = Object.values(HTTP_METHODS);

export const requestFormatOptions = [
    {
        value: REQUEST_FORMATS.JSON,
        label: 'JSON',
    },
    {
        value: REQUEST_FORMATS.WebForm,
        label: 'Web Form',
    },
    {
        value: REQUEST_FORMATS.Custom,
        label: 'Custom Body',
    },
];

export const PRESET_VARIABLES = {
    MESSAGE_PAYLOAD: 'MESSAGE_PAYLOAD',
    DEVICE_TOKEN: 'DEVICE_TOKEN',
    USER_ID: 'USER_ID',
    WEBHOOK_EVENT_NAME: 'WEBHOOK_EVENT_NAME',
    SIGNAL_NUMBER: 'SIGNAL_NUMBER',
    DEVICE_NAME: 'DEVICE_NAME',
};

export const presetOptions = Object.values(PRESET_VARIABLES);

export const FIXED_HTTP_HEADERS_JSON = [
    {
        propName: 'Content-Type',
        value: 'application/json',
        unchangable: true,
    },
    {
        propName: 'Accept',
        value: 'application/json',
        unchangable: true,
    },
];
  
export const FIXED_HTTP_HEADERS_WEBFORM = [{
    propName: 'Content-Type',
    value: 'application/x-www-form-urlencoded',
    unchangable: true,
}];

export const initialValues = {
    url: '',
    webhookEvent: '',
    requestType: HTTP_METHODS.POST,
    requestFormat: REQUEST_FORMATS.JSON,
    body: [
        {
            propName: 'deviceToken',
            value: PRESET_VARIABLES.DEVICE_TOKEN,
            preset: true,
        },
        {
            propName: 'event',
            value: PRESET_VARIABLES.WEBHOOK_EVENT_NAME,
            preset: true,
        },
        {
            propName: 'signal',
            value: PRESET_VARIABLES.SIGNAL_NUMBER,
            preset: true,
        },
        {
            propName: 'payload',
            value: PRESET_VARIABLES.MESSAGE_PAYLOAD,
            preset: true,
        },
    ],
    customBody: '',
    queryParameters: [],
    httpHeaders: FIXED_HTTP_HEADERS_JSON,
    authenticationUsername: '',
    authenticationPassword: '',
};

export const validationSchema = Yup.object().shape({
    url: Yup.string()
        .url()
        .required(ValidationService.getRequiredError('url')),
    webhookEvent: Yup.string()
        .matches(/^([A-Za-z0-9]{1,64})$/, 'Only letters and numbers allowed, up to 64 characters.')
        .required(ValidationService.getRequiredError('Webhook Event name')),
    devices: Yup.array().compact().min(1, 'Please select at least one device'),
    authenticationUsername: Yup.string()
        .when('authenticationPassword', {
            is: (authenticationPassword) => Boolean(authenticationPassword),
            then: Yup.string().required(ValidationService.getRequiredError('username')),
        }),
    authenticationPassword: Yup.string()
        .when('authenticationUsername', {
            is: (authenticationUsername) => Boolean(authenticationUsername),
            then: Yup.string().required(ValidationService.getRequiredError('password')),
        }),
}, ['authenticationUsername', 'authenticationPassword']);
