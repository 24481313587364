import { useState, useRef, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import mqtt from 'mqtt';

import { GET_USER_PROFILE_QUERY, TYPE_PYMESH_DEPLOY, TYPE_MASK } from 'Constants';
import { Modal, Button, Spin } from 'Components';
import { showToastError, showToastSuccess } from 'Utils';

import * as Styled from './styled';

const USER_SYSTEM_MASK = 0x80; // 1000 0000
let header = USER_SYSTEM_MASK;
// eslint-disable-next-line no-bitwise
header |= (TYPE_PYMESH_DEPLOY & TYPE_MASK);

export const DeployPymesh = ({ devices }) => {
    let client;
    const { data: userData } = useQuery(GET_USER_PROFILE_QUERY);
    const user = userData?.getUserProfile;

    const processTimeoutRef = useRef(null);

    const [showModal, setShowModal] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [updateStatuses, setUpdateStatuses] = useState([]);

    useEffect(() => () => {
        if (processTimeoutRef.current) {
            clearTimeout(processTimeoutRef.current);
        }

        return () => {
            if (client) {
                client.close();
            }
        };
    }, []);

    const handleModalClose = () => setShowModal(false);

    useEffect(() => {
        if (updateStatuses.length && updateStatuses.length === devices.length) {
            const failedUpdates = updateStatuses.filter((status) => status === 0);
            if (failedUpdates.length) {
                showToastError('Pymesh firmware and settings have not been updated on all devices. Check your devices wifi connectivity');
            } else {
                showToastSuccess('Pymesh firmware updated successfully');
            }

            handleModalClose();
            setUpdateStatuses([]);
            setIsUpdating(false);
            clearTimeout(processTimeoutRef.current);
        }
    }, [updateStatuses]);

    const updateFailed = (deviceName, message) => {
        showToastError(`${deviceName}: ${message}`);
        setIsUpdating(false);
        setUpdateStatuses([]);
        clearTimeout(processTimeoutRef.current);
    };

    const updateTimeout = () => {
        showToastError('The update process timed out');
        setIsUpdating(false);
        setUpdateStatuses([]);
        clearTimeout(processTimeoutRef.current);
    };

    const handleDeploy = () => {
        setShowModal(true);
        setIsUpdating(true);

        const { owner } = user;

        devices.forEach((device) => {
            const { token, description } = device;

            const messagePayload = new Uint8Array(1);
            messagePayload[0] = header;

            const mqttClientOptions = {
                clean: true,
                username: owner,
                password: token,
            };

            client = mqtt.connect(process.env.REACT_APP_MQTT_SERVER_AEDES_WEB_SOCKET_URL, mqttClientOptions);

            client.on('connect', () => {
                client.unsubscribe(`u${token}/mesh`);
                client.subscribe(`u${token}/mesh`);
                client.publish(`d${token}`, Buffer.from(messagePayload), { qos: 0 }, (err) => {
                    if (err) {
                        updateFailed(description, `Error: ${err.message}`);
                    }
                });
            });

            client.on('error', () => {
                updateFailed(description, 'Could not establish connection with device');
                client.end();
            });

            client.on('message', async (_topic, receivedMessage) => {
                const status = receivedMessage?.length === 2 ? receivedMessage[1] : 0;
                setUpdateStatuses([...updateStatuses, status]);

                client.end();
            });

           // timeout of 5 minutes
           processTimeoutRef.current = setTimeout(updateTimeout, 300000);
        });
    };

    return (
        <Styled.Wrapper>
            {!isUpdating && (
                <Button buttonType="transparent" disabled={!devices.length} onClick={handleDeploy}>
                    <Styled.DeployIcon />
                    Deploy Pymesh
                </Button>
            )}
            {!!isUpdating && (
                <Styled.SpinBox>
                    <Styled.SpinTitle>Updating Pymesh Firmware</Styled.SpinTitle>
                    <Spin spinning={isUpdating} customSize="45px" />
                </Styled.SpinBox>
            )}
            <Modal isOpened={showModal} handleClose={handleModalClose}>
                <Styled.ModalTitle>
                    Pymesh firmware update in progress...
                </Styled.ModalTitle>
               <Styled.ModalBody>
                    <p>
                        The devices have received the command to download and install the Pymesh firmware version.
                    </p>
                    <p>
                        The process should not be longer than 1 minute.
                        If it takes longer, Pybytes will consider that the update procedure has failed.
                        However, if the network is really slow for the device,
                        the update might eventually come to an end after a few minutes.
                    </p>
                    <p>
                        To deploy the settings correctly, all devices must be connected using wifi.
                        If Pymesh is currently running, nodes connected using LoRa will not be updated.
                    </p>
                    <Button onClick={handleModalClose}>
                        Got it
                    </Button>
               </Styled.ModalBody>
            </Modal>
        </Styled.Wrapper>
    );
};

DeployPymesh.defaultProps = {
    devices: [],
};

DeployPymesh.propTypes = {
    devices: PropTypes.arrayOf(PropTypes.object),
};
