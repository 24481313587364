import gql from 'graphql-tag';

export const GET_ZIP_FILE = gql`
    query getZipFileFromHierarchy($hierarchy: [PymakrFile]) {
        getZipFileFromHierarchy(hierarchy: $hierarchy)
    }
`;

export const UPLOAD_ZIP_FILE = gql`
    query uploadZipFile($zip: String, $name: String) {
        uploadZipFile(zip: $zip, name: $name)
    }
`;

export const CREATE_COMPILATION = gql`
    mutation createCompilation($hierarchy: [PymakrFile], $options: CompilationOptionsInput) {
        createCompilation(hierarchy: $hierarchy, options: $options) {
            _id
            owner
            logs
            createdAt
            status
        }
    }
`;

export const DOWNLOAD_COMPILED_IMAGE = gql`
    mutation downloadCompiledImage($compilationId: String!) {
        downloadCompiledImage(compilationId: $compilationId)
    }
`;

export const COMPILATION_STATUS_SUBSCRIPTION_API = gql`
    subscription compilationStatusSubscription__config__(
        $compilationId: String!,
        $user: String!,
    ) {
        compilationStatusSubscription__config__(compilationId: $compilationId, user: $user) {
            compilationId
            status
            logs
        }
    }
`;

export const GET_DIRECT_ARTIFACT_LINK = gql`
    mutation getDirectArtifactLink($compilationId: String!) {
        getDirectArtifactLink(compilationId: $compilationId)
    }
`;

export const GET_COMPILED_IMAGE_BASE64 = gql`
    mutation getCompiledImageBase64($compilationId: String!, $type: String!) {
        getCompiledImageBase64(compilationId: $compilationId, type: $type)
    }
`;

export const DEPLOY_CUSTOM_FIRMWARE = gql`
    mutation deployCustomFirmware($vars: DeployCustomFirmwareInputValue!){
        deployCustomFirmware(inputValue: $vars)
    }
`;
