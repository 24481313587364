import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { GET_USER_PROFILE_QUERY, defaultStructures } from 'Constants';
import { getDefaultStructure } from 'Utils';

import { PymakrEditor } from '../pymakrEditor';

export const BlankProject = () => {
    const { language } = useParams();

    const { data, loading } = useQuery(GET_USER_PROFILE_QUERY);
    const user = data?.getUserProfile?.owner;

    const title = 'new-project';
    const projectKey = `${user}-${title}-${language}`;

    if (loading || !language || defaultStructures.findIndex((item) => item.key === language) < 0) {
        return null;
    }

    const getStructure = () => ({
        type: 'scope',
        expanded: true,
        hierarchyLevel: 0,
        name: '',
        path: '',
        parent: null,
        files: getDefaultStructure(language, projectKey),
    });

    return (
        <PymakrEditor 
            projectKey={projectKey} 
            title={title} 
            initialStructure={getStructure()} 
            user={user} 
            programmingLanguage={language}
        />
    );
};
