import { createGlobalStyle } from 'styled-components';

export const CustomLineChartStyle = createGlobalStyle`
    .line-chart-responsive-container-wrapper {
        .recharts-default-legend {
            display: flex;
            gap: 100px;
            overflow: auto;
            padding-bottom: 10px !important;

            .recharts-legend-item {
                display: inline-flex !important;
                margin: 0 !important;
                align-items: center;
                svg {
                    margin-right: 11px !important;
                }
                span {
                    font-size: 12px;
                    line-height: 1;
                    color: #383744 !important;
                    white-space: nowrap;
                }
            }
        }

        .recharts-brush-slide {
            color: ${({ theme }) => theme.colors.active};
            fill-opacity: 1;
        }

        .recharts-brush{
            color: #E0E0E0;
        }
    }
`;
