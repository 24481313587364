import styled from 'styled-components';

export const Wrapper = styled.div`
    box-shadow: 0 2px 5px 0 rgba(50, 50, 50, 0.2);
    border-radius: 5px;
    margin-top: 15px;
    padding: 25px;
    white-space: nowrap;
    display: inline-block;
    width: 100%;

    button {
        text-transform: uppercase;
    }
`;

export const Header = styled.h3`
    text-transform: uppercase;
    font-weight: bold;
`;

export const Message = styled.div`
    margin: 20px 0px;
`;
