import PropTypes from 'prop-types';

import * as Styled from './styled';

export const Button = ({
    icon,
    onClick,
    disabled,
    type,
    className,
    loading,
    children,
    buttonType,
    cyData,
}) => {
    const onClickHandler = (event) => {
        if (loading) {
            return;
        }

        onClick && onClick(event);
    };

    return (
        <Styled.Button
            htmlType={type}
            $buttonType={buttonType}
            className={className}
            onClick={onClickHandler}
            disabled={disabled}
            loading={loading}
            data-cy={cyData}
        >
            {icon && (
                <span>
                    <icon />
                </span>
            )}
            {children}
        </Styled.Button>
    );
};

Button.propTypes = {
    children: PropTypes.node,
    icon: PropTypes.element,
    onClick: PropTypes.func,
    buttonType: PropTypes.oneOf(['filled', 'transparent']),
    type: PropTypes.string,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    className: PropTypes.string,
    cyData: PropTypes.string,
};

Button.defaultProps = {
    children: null,
    icon: null,
    onClick: () => { },
    buttonType: 'filled',
    type: 'button',
    disabled: false,
    loading: false,
    className: '',
    cyData: '',
};
