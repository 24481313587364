import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useApolloClient, useMutation, useQuery } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';

import { AwsLogin, AwsThing, Button, DeleteModal, Modal } from 'Components';
import {
    GET_AWS_THINGS_BY_GROUP,
    SEND_AWS_TEST_MESSAGE,
    UPDATE_AWS_GROUP_TOPIC,
    GET_USER_PROFILE_QUERY,
    SAVE_AWS_THINGS_MUTATION,
    GET_INTEGRATION_BY_ID_QUERY,
    REMOVE_AWS_THINGS_MUTATION,
} from 'Constants';
import { showToastError, showToastSuccess, createAWSAttributeName } from 'Utils';

import { DevicesTable } from './devicesTable';
import { DetailsBlock } from './detailsBlock';

import * as Styled from './styled';

const DEFAULT_TEST_MESSAGE = 'Send test message';

export const AwsDetails = ({ details, deleteIntegration, refetchIntegrationDetails, isDeleteLoading }) => {
    const apolloClient = useApolloClient();
    const [isLoading, setIsLoading] = useState(false);
    const [testLoading, setTestLoading] = useState(false);
    const [testMessage, setTestMessage] = useState(DEFAULT_TEST_MESSAGE);
    const [deleteModalOpened, toggleDeleteModal] = useState(false);
    const [changeCredentialsModalOpened, togglechangeCredentialsModal] = useState(false);
    const [isEditionMode, setIsEditionMode] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    const [connectionMask, setConnectionMask] = useState({});
    const [awsService, setAwsService] = useState({});
    const timeoutRef = useRef(null);

    const { data: userData } = useQuery(GET_USER_PROFILE_QUERY);
    const user = userData?.getUserProfile ?? {};

    const [updateTopic] = useMutation(
        UPDATE_AWS_GROUP_TOPIC,
        { refetchQueries: [GET_AWS_THINGS_BY_GROUP] },
    );

    const [saveAwsThings] = useMutation(
        SAVE_AWS_THINGS_MUTATION,
        { refetchQueries: [GET_AWS_THINGS_BY_GROUP, GET_INTEGRATION_BY_ID_QUERY] },
    );

    const [removeAwsThings] = useMutation(
        REMOVE_AWS_THINGS_MUTATION,
        { refetchQueries: [GET_AWS_THINGS_BY_GROUP, GET_INTEGRATION_BY_ID_QUERY] },
    );

    const [getThings, { data, refetch: refetchAwsThings }] = useLazyQuery(
        GET_AWS_THINGS_BY_GROUP,
        {
            variables: {
                awsGroupName: details.name,
                region: connectionMask.region,
                includeDevice: true,
            },
            fetchPolicy: 'cache-and-network',
        },
    );
    const thingsArr = data?.getAWSThingsByGroup ?? [];

    useEffect(() => {
        if (!isEmpty(connectionMask)) {
            getThings();
        }
    }, [connectionMask]);

    useEffect(() => () => clearTimeout(timeoutRef.current), []);

    const onLogin = () => {
        setLoggedIn(true);
    };

    const sendTestMessage = async () => {
        setTestLoading(true);

        try {
            const { data: { sendAwsTestMessage } } = await apolloClient.query({
                query: SEND_AWS_TEST_MESSAGE,
                variables: { integrationId: details._id },
                fetchPolicy: 'network-only',
            });

            setTestMessage(sendAwsTestMessage.message);

            timeoutRef.current = setTimeout(() => setTestMessage(DEFAULT_TEST_MESSAGE), 5000);
        } catch (error) {
            showToastError(error.message);
        } finally {
            setTestLoading(false);
        }
    };

    const changeCredentials = () => {
        togglechangeCredentialsModal(false);
        localStorage.removeItem(`AWSlogin-${details.aws.endpointAddress}`);
        setConnectionMask({});
        setAwsService({});
        setLoggedIn(false);
    };

    const deleteIntegrationHandler = async () => {
        try {
            const certificateDetachPromises = thingsArr.map((item) => awsService.detachCertificatefromThing({
                thingName: item.thing.awsThing.thingName,
                principal: item.thing.certificate.arn,
            }));
            await Promise.all(certificateDetachPromises);

            const certificateDeletionPromises = thingsArr.map(
                (item) => awsService.deleteCertificate(item.thing.certificate),
            );
            await Promise.all(certificateDeletionPromises);

            const thingsDeletionPromises = thingsArr.map(
                (item) => awsService.deleteThing(item.thing.awsThing.thingName),
            );
            await Promise.all(thingsDeletionPromises);

            await awsService.deleteThingGroup(thingsArr[0].thing.awsGroup.groupName);

            await deleteIntegration();

            showToastSuccess('AWS integration was successfully deleted');
        } catch (error) {
            showToastError(error.message);
        }
    };

    const addThings = async (devices, topic) => {
        const awsRef = thingsArr[0]?.thing.awsGroup;

        const thingCreationPromises = devices.map((item) => awsService.createThing({
            name: item.token,
            attributes: {
                IoT_Platform: 'Pybytes',
                original_device_name: createAWSAttributeName(item),
            },
        }));
        const thingCreationResults = await Promise.all(thingCreationPromises);

        const thingGroupingPromises = devices.map((item) => awsService.addThingToThingGroup({
            thingName: item.token,
            thingGroupName: awsRef.groupName,
          }));
        await Promise.all(thingGroupingPromises);

        const certificateCreationPromises = devices.map(() => awsService.createCertificate());
        const certificateCreationResults = await Promise.all(certificateCreationPromises);

        const thingCertificationPromises = devices.map((item, index) => awsService.attachCertificateToThing({
            thingName: item.token,
            principal: certificateCreationResults[index].certificateArn,
        }));
        await Promise.all(thingCertificationPromises);

        const compiledInformations = devices.map((item, index) => ({
            owner: user.owner,
            region: connectionMask.region,
            endpointAddress: connectionMask.endpointAddress,
            topic,
            deviceToken: item.token,
            awsThing: {
                thingName: thingCreationResults[index].thingName,
                thingArn: thingCreationResults[index].thingArn,
                thingId: thingCreationResults[index].thingId,
            },
            awsGroup: {
                groupName: awsRef.groupName,
                groupArn: awsRef.groupArn,
                groupId: awsRef.groupId,
            },
            certificate: {
                id: certificateCreationResults[index].certificateId,
                arn: certificateCreationResults[index].certificateArn,
                certPEM: certificateCreationResults[index].certificatePem,
                publicKey: certificateCreationResults[index].keyPair.PublicKey,
                privateKey: certificateCreationResults[index].keyPair.PrivateKey,
            },
        }));

        await saveAwsThings({
            variables: {
                awsThings: compiledInformations,
                integrationId: details._id,
            },
        });
    };

    const removeThings = async (deviceTokens) => {
        const thingsToRemove = deviceTokens.map(
            (token) => thingsArr.find((item) => item.thing.deviceToken === token),
        );

        const certificateDetachPromises = thingsToRemove.map((item) => awsService.detachCertificatefromThing({
            thingName: item.thing.awsThing.thingName,
            principal: item.thing.certificate.arn,
        }));
        await Promise.all(certificateDetachPromises);

        const certificateDeletionPromises = thingsToRemove.map(
            (item) => awsService.deleteCertificate(item.thing.certificate),
        );
        await Promise.all(certificateDeletionPromises);

        const thingsDeletionPromises = thingsToRemove.map(
            (item) => awsService.deleteThing(item.thing.awsThing.thingName),
        );
        await Promise.all(thingsDeletionPromises);

        await removeAwsThings({
            variables: {
                tokens: deviceTokens,
                integrationId: details._id,
            },
        });
    };

    const editOnSubmit = async (values, allDevices) => {
        setIsLoading(true);
        const { devices, messagesTopic } = values;

        const devicesToAdd = [];
        const devicesToRemove = [];

        allDevices.forEach((device, index) => {
            const { description, token } = device;

            const relatedThing = thingsArr.find((item) => item.device?.token === device.token);

            if (relatedThing && !devices[index]) {
                devicesToRemove.push(token);
            }

            if (!relatedThing && devices[index]) {
                devicesToAdd.push({ description, token });
            }
        });

        try {
            if (messagesTopic !== thingsArr[0]?.thing.topic) {
                await updateTopic({
                    variables: { awsGroupName: details.name, topic: messagesTopic },
                });
            }

            if (devicesToAdd.length) {
                await addThings(devicesToAdd, messagesTopic);
            }

            if (devicesToRemove.length) {
                await removeThings(devicesToRemove);
            }
        } catch (error) {
            // TODO: change success and error messages after fix on BE side
            console.log(error);
        } finally {
            refetchAwsThings();
            refetchIntegrationDetails();

            showToastSuccess('Details updated successfully');
            setIsLoading(false);
            setIsEditionMode(false);
        }
    };

    if (!loggedIn) {
        return (
            <AwsLogin
                setConnectionMask={setConnectionMask}
                setAwsService={setAwsService}
                nextStep={onLogin}
                initialEndpointAddress={details.aws.endpointAddress}
            />
        );
    }

    if (isEditionMode) {
        const initialValues = {
            thingGroupName: thingsArr[0]?.thing.awsGroup.groupName,
            messagesTopic: thingsArr[0]?.thing.topic,
            deviceTokens: thingsArr.map((item) => item.device?.token),
        };

        return (
            <AwsThing
                awsService={awsService}
                connectionMask={connectionMask}
                nextStep={editOnSubmit}
                initialValues={initialValues}
                onCancel={() => setIsEditionMode(false)}
                submitText="Edit"
                isLoading={isLoading}
            />
        );
    }

    return (
        <>
            <DevicesTable thingsArr={thingsArr} />
            <DetailsBlock
                details={details}
                thingsArr={thingsArr}
            />
            <Styled.ButtonsWrapper>
                <Button
                    buttonType="transparent"
                    onClick={() => setIsEditionMode(true)}
                >
                    Edit
                </Button>
                <Button
                    buttonType="transparent"
                    onClick={() => togglechangeCredentialsModal(true)}
                >
                    Change credentials
                </Button>
                <Button onClick={() => toggleDeleteModal(true)}>
                    Delete
                </Button>
            </Styled.ButtonsWrapper>
            <Styled.BigButton
                loading={testLoading}
                onClick={sendTestMessage}
            >
                {testMessage}
            </Styled.BigButton>
            <Modal
                isOpened={deleteModalOpened}
                handleClose={() => toggleDeleteModal(false)}
            >
                <DeleteModal
                    deleteAction={deleteIntegrationHandler}
                    cancelAction={() => toggleDeleteModal(false)}
                    title="Are you sure you want to delete this Integration?"
                    description={`This integration will be deleted from Pybytes AND Amazon Web Services IoT Hub,
                        that means that the messages sent from your devices wont be transmitted to AWS anymore.
                        Apart from the concerned group, things and certificates of your AWS IoT Hub,
                        no other service in AWS will be affected.`}
                    isLoading={isDeleteLoading}
                />
            </Modal>
            <Modal
                isOpened={changeCredentialsModalOpened}
                handleClose={() => togglechangeCredentialsModal(false)}
            >
                <DeleteModal
                    deleteAction={changeCredentials}
                    cancelAction={() => togglechangeCredentialsModal(false)}
                    submitText="Sign out and change credentials"
                    title="Change AWS credentials"
                    description={`Your credentials will be deleted from your browser and you
                        will be signed out of this integration.`}
                />
            </Modal>
        </>
    );
};

AwsDetails.propTypes = {
    details: PropTypes.object,
    deleteIntegration: PropTypes.func.isRequired,
    refetchIntegrationDetails: PropTypes.func.isRequired,
    isDeleteLoading: PropTypes.bool,
};

AwsDetails.defaultProps = {
    details: {},
    isDeleteLoading: false,
};
