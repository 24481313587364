import { SIGFOX_CONTRACT_TYPE_CUSTOM, SIGFOX_CONTRACT_TYPE_DEVKIT } from 'Constants';

const initialValues = {
    contractType: SIGFOX_CONTRACT_TYPE_DEVKIT,
    deviceTypeId: '',
};

export const contractTypeOptions = [{
    value: SIGFOX_CONTRACT_TYPE_DEVKIT,
    label: 'I am using DevKit connectivity which comes with Pycom device',
},
{
    value: SIGFOX_CONTRACT_TYPE_CUSTOM,
    label: 'I am using custom Sigfox contract',
}];

export const getDeviceTypeIdOptions = ((data) =>
    data?.getDeviceTypes?.map((deviceType) => (
        {
            value: deviceType.id,
            label: deviceType.name,
        }
    )) ?? []
);

export const getInitialValues = (contractType, deviceTypeId) => ({
    contractType: contractType ?? initialValues.contractType,
    deviceTypeId: deviceTypeId ?? initialValues.deviceTypeId,
});
