import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSubscription, useMutation } from '@apollo/client';
import {
    FCOTA_PING_SUBSCRIPTION,
    FCOTA_HIERARCHY_SUBSCRIPTION,
    FCOTA_FILE_SUBSCRIPTION,
    SYNC_FCOTA_MUTATION,
    PING_FCOTA_MUTATION,
    GET_FCOTA_FILE_MUTATION,
    pymakrConnectionStates,
    pymakrMarkStatus,
} from 'Constants';
import { Loader } from 'Components';
import { ReactComponent as Bullet } from 'Assets/icons/bullet.svg';

import { useContextSelector } from '../../pymakrHooks';
import * as Styled from './styled';

export const PingMark = ({ deviceToken }) => {
    const [emissionActivity, setEmissionActivity] = useState('');
    const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
    const [connectionState, setConnectionState] = useState(pymakrConnectionStates.offline);
    const [receptionActivity, setReceptionActivity] = useState('');
    const [isPingSent, setIsPingSent] = useState(false);
    const [isPingReceived, setIsPingReceived] = useState(false);

    const hierarchySlice = useContextSelector('hierarchy');
    const { setFilesSynchronized, setDeviceStatusOffline, setDeviceStatusOnline, setFilesCount } = hierarchySlice.handlers;
    const { synchronizing, isOnline, filesSynchronized } = hierarchySlice.state;

    const { data } = useSubscription(FCOTA_PING_SUBSCRIPTION, {
        variables: { deviceToken },
        fetchPolicy: 'network-only',
    });

    const { data: hierarchyData } = useSubscription(FCOTA_HIERARCHY_SUBSCRIPTION, {
        variables: { deviceToken },
        fetchPolicy: 'network-only',
    });

    const { data: filesData } = useSubscription(FCOTA_FILE_SUBSCRIPTION, {
        variables: { deviceToken },
        fetchPolicy: 'network-only',
    });

    const [syncFCOTAMutation, { loading, error }] = useMutation(SYNC_FCOTA_MUTATION, {
        variables: { deviceToken },
    });

    const [pingFCOTAMutation] = useMutation(PING_FCOTA_MUTATION, {
        variables: { deviceToken },
    });

    const [fileFCOTAMutation] = useMutation(GET_FCOTA_FILE_MUTATION, {
        variables: { deviceToken },
    });

    const pingReceivedTimeout = useRef(null);
    const pingSentTimeout = useRef(null);
    const connectionStateTimeout = useRef(null);
    const restartingTimeout = useRef(null);
    const emissionActivityTimeout = useRef(null);
    const receptionActivityTimeout = useRef(null);
    const noResponseTimeout = useRef(null);
    const pingTimeout = useRef(null);

    const waitingForResponse = () => {
        if (connectionState !== pymakrConnectionStates.restarting) {
            clearTimeout(connectionStateTimeout.current);
            setIsWaitingForResponse(true);
            connectionStateTimeout.current = setTimeout(() => {
                setIsWaitingForResponse(false);
                setEmissionActivity('');
                setIsPingSent(false);
                setConnectionState(pymakrConnectionStates.offline);
            }, 8000);
        }
    };

    const sendPing = (activity) => {
        setIsPingReceived(false);
        setIsPingSent(true);

        if (activity && (activity !== 'ping' || emissionActivity === '')) {
            setEmissionActivity(activity);
        }

        waitingForResponse();
    };

    const syncHierarchy = () => {
        sendPing('hierarchy request');
        waitingForResponse();
        syncFCOTAMutation();
    };

    const pingReceived = (activity) => {
        clearTimeout(connectionStateTimeout.current);
        clearTimeout(restartingTimeout.current);
        
        if (activity?.includes(pymakrConnectionStates.restarting)) {
            setConnectionState(pymakrConnectionStates.restarting);
            restartingTimeout.current = setTimeout(() => {
                setConnectionState(pymakrConnectionStates.offline);
            }, 20000);
        } else if (connectionState !== pymakrConnectionStates.online) {
            setConnectionState(pymakrConnectionStates.online);
        }

        setIsPingReceived(true);

        if (activity) {
            setReceptionActivity(activity);
        }

        pingReceivedTimeout.current = setTimeout(() => {
            setEmissionActivity('');
            setIsPingSent(false);
            setIsWaitingForResponse(false);
        }, 4000);
    };

    const pingFCOTA = () => {
        clearTimeout(pingTimeout.current);
        pingTimeout.current = setTimeout(() => {
            setIsPingSent(false);
            sendPing('ping');
            pingFCOTAMutation();

            pingFCOTA();
        }, 10000);
    };

    const applyHierarchy = async (files) => {
        let currentSyncFilesCount = 0; 

        await Promise.all(files.map(async (path) => {
            if (path.indexOf('.') < 0 || path.endsWith('.pymakr')) {
                return;
            }

            currentSyncFilesCount += 1;
            await fileFCOTAMutation({ variables: { path } });
        }));
        setFilesCount(currentSyncFilesCount);
    };

    useEffect(() => {
        if (synchronizing) {
            syncHierarchy();
        }
    }, [synchronizing]);

    useEffect(() => {
        if (data) {
            pingReceived(data?.pingSubscription?.activity);
        }
    }, [data]);

    useEffect(() => {
        if (hierarchyData) {
            const files = hierarchyData.hierarchySubscription?.hierarchy?.split(', ');
            applyHierarchy(files);
        }
    }, [hierarchyData]);

    useEffect(() => {
        if (filesData) {
            const { fileSubscription: file } = filesData; 
            const syncedFiles = [...filesSynchronized, { path: file.path, content: file.content }];
            setFilesSynchronized(syncedFiles);
        }
    }, [filesData]);

    useEffect(() => {
        if (connectionState !== pymakrConnectionStates.online && isOnline) {
            setDeviceStatusOffline();
        }
        if (!isOnline && connectionState === pymakrConnectionStates.online) {
            setDeviceStatusOnline();
        }
    }, [connectionState]);

    useEffect(() => {
        sendPing('ping');
        pingFCOTA();
        pingFCOTAMutation();

        return () => {
            clearTimeout(pingReceivedTimeout.current);
            clearTimeout(pingSentTimeout.current);
            clearTimeout(connectionStateTimeout.current);
            clearTimeout(restartingTimeout.current);
            clearTimeout(emissionActivityTimeout.current);
            clearTimeout(receptionActivityTimeout.current);
            clearTimeout(noResponseTimeout.current);
            clearTimeout(pingTimeout.current);
        };
    }, []);

    if (loading || error) {
        return <div />;
    }

    let markStatus = pymakrMarkStatus.noResponse;

    if (isWaitingForResponse) {
        markStatus = pymakrMarkStatus.waiting;
    }

    if (isPingReceived) {
        markStatus = pymakrMarkStatus.active;
    }

    if (synchronizing) {
        return <Loader title="Synchronization with device..." />;
    }

    return (
        <Styled.Wrapper>
            <Styled.EmissionActivity $active={emissionActivity}>
                {emissionActivity}
            </Styled.EmissionActivity>
            <Styled.ReceptionActivity $active={receptionActivity}>
                {receptionActivity}
            </Styled.ReceptionActivity>
            <Styled.Bullet $active={isPingSent}>
                <Bullet />
            </Styled.Bullet>
            <Styled.Mark $status={markStatus} $connectionState={connectionState}>
                <Bullet />
            </Styled.Mark>
            <Styled.PingWave>
                <Bullet />
            </Styled.PingWave>
            <Styled.ConnectionState $connectionState={connectionState}>
                {connectionState}
            </Styled.ConnectionState>
        </Styled.Wrapper>
    );
};

PingMark.propTypes = {
    deviceToken: PropTypes.string.isRequired,
};
