import * as Yup from 'yup';

import { ValidationService } from 'Services';

export const FILTER_TYPES = {
    NONE: 'none',
    LOWPASS: 'lowpass',
    HIGHPASS: 'highpass',
};

export const filterTypeOptions = Object.values(FILTER_TYPES).map((item) => ({
    value: item,
    label: item,
}));

export const getInitialValues = (modelDefinition) => ({
    standardization: modelDefinition?.standardization || true,
    mean: modelDefinition?.mean || true,
    variance: modelDefinition?.variance || false,
    filter: modelDefinition?.filter || true,
    filterType: modelDefinition?.filterType || FILTER_TYPES.LOWPASS,
    cutOffFrequency: modelDefinition?.cutOffFrequency || 4,
    order: modelDefinition?.order || 5,
    rms: modelDefinition?.rms || true,
    useFFTPeaks: modelDefinition?.useFFTPeaks || true,
    numberOfPeaks: modelDefinition?.numberOfPeaks || 3,
    peakMinimumValue: modelDefinition?.peakMinimumValue || 0.1,
    spectralPower: modelDefinition?.spectralPower || true,
    intervals: modelDefinition?.intervals || '0, 0.3, 1, 10',
});

const intervalsRegExp = /^[0-9,. ]*$/;

export const validationSchema = Yup.object().shape({
    filter: Yup.boolean(),
    cutOffFrequency: Yup.number().when('filter', {
        is: true,
        then: Yup.number()
            .typeError('Should be a number')
            .positive('Should be positive')
            .required(ValidationService.getRequiredError('cut off frequency')),
    }),
    order: Yup.number().when('filter', {
        is: true,
        then: Yup.number()
            .typeError('Should be a number')
            .positive('Should be positive')
            .required(ValidationService.getRequiredError('order')),
    }),
    useFFTPeaks: Yup.boolean(),
    numberOfPeaks: Yup.number().when('useFFTPeaks', {
        is: true,
        then: Yup.number()
            .typeError('Should be a number')
            .positive('Should be positive')
            .required(ValidationService.getRequiredError('number of peaks')),
    }),
    peakMinimumValue: Yup.number().when('useFFTPeaks', {
        is: true,
        then: Yup.number()
            .typeError('Should be a number')
            .positive('Should be positive')
            .required(ValidationService.getRequiredError('peak minimum value')),
    }),
    spectralPower: Yup.boolean(),
    intervals: Yup.string().when('spectralPower', {
        is: true,
        then: Yup.string()
            .matches(intervalsRegExp, 'Should be a float list')
            .required(ValidationService.getRequiredError('intervals')),
    }),
});
