import styled from 'styled-components';

export const PageTitle = styled.p`
    a {
        color: ${({ theme }) => theme.colors.active};
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
`;
