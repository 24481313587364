import PropTypes from 'prop-types';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { resendVerificationEmail } from 'Api';
import { showToastError, showToastSuccess } from 'Utils';

import * as RootStyled from '../styled';
import * as Styled from './styled';

export const ResendVerificationEmail = ({ email, onLogout }) => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    const onResend = async () => {
        let result;

        try {
            if (process.env.REACT_APP_PYCOM_ENABLE_RECAPTCHA === 'true') {
                const recaptchaResult = await executeRecaptcha('resendVerification');
                result = await resendVerificationEmail(email, recaptchaResult);
            } else {
                result = await resendVerificationEmail(email);
            }

            if (result?.status === 'Success') {
                showToastSuccess('New verification mail has been sent');
                onLogout();
            } else {
                showToastError('Resending verification mail has failed');
            }
        } catch (error) {
            showToastError('System error');
        }
    };

    return (
        <>
            <RootStyled.Title>
                User verification
            </RootStyled.Title>
            <Styled.Subtitle>
                Your email <b>{email}</b> is not verified yet.
                <p>
                    Please check your email. Or resend a new one if you did not receive any
                </p>
            </Styled.Subtitle>
            <Styled.ButtonSection>
                <RootStyled.Button onClick={onLogout} buttonType="transparent">
                    Logout
                </RootStyled.Button>
                <RootStyled.Button onClick={onResend}>
                    Resend
                </RootStyled.Button>
            </Styled.ButtonSection>
        </>
    );
};

ResendVerificationEmail.propTypes = {
    email: PropTypes.string.isRequired,
    onLogout: PropTypes.func.isRequired,
};
