import { useRef, useState, useEffect } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';

import { FCOTA_PING_SUBSCRIPTION, PING_FCOTA_MUTATION } from 'Constants';

export const useDevicePing = (startSampling) => {
    const apolloClient = useApolloClient();

    const subscriptionRef = useRef(null);
    const deviceTimeoutRef = useRef(null);

    const [isDevicePinging, setIsDevicePinging] = useState(false);
    const [isDeviceTimeout, setIsDeviceTimeout] = useState(false);

    const [pingFcota] = useMutation(PING_FCOTA_MUTATION);

    const deleteSubscription = () => {
        if (subscriptionRef.current) {
            subscriptionRef.current.unsubscribe();
        }
    };

    const clearDeviceTimeout = () => {
        if (deviceTimeoutRef.current) {
            clearTimeout(deviceTimeoutRef.current);
        }
    };

    useEffect(() => () => {
        setIsDevicePinging(false);
        setIsDeviceTimeout(false);

        clearDeviceTimeout();
        deleteSubscription();
    }, []);

    const createDeviceTimeout = () => {
        clearDeviceTimeout();

        deviceTimeoutRef.current = setTimeout(
            () => {
                setIsDevicePinging(false);
                setIsDeviceTimeout(true);

                deleteSubscription();
            },
            5000,
        );
    };

    const receivePing = (activity, values) => {
        clearDeviceTimeout();

        if (!activity.length) {
            setIsDevicePinging(false);

            deleteSubscription();

            startSampling(values);
        }
    };

    const createSubscription = async (values) => {
        const { deviceToken } = values;

        deleteSubscription();

        subscriptionRef.current = await apolloClient.subscribe({
            query: FCOTA_PING_SUBSCRIPTION,
            variables: { deviceToken },
        }).subscribe({
            next({ data }) {
                receivePing(data.pingSubscription.activity, values);
            },
            error(err) {
                console.error('err', err);
            },
        });
    };

    const pingDevice = async (values) => {
        const { deviceToken } = values;

        setIsDevicePinging(true);
        setIsDeviceTimeout(false);

        await createSubscription(values);

        pingFcota({ variables: { deviceToken } });

        createDeviceTimeout(deviceToken);
    };

    return {
        pingDevice,
        isDeviceTimeout,
        isDevicePinging,
    };
};
