import * as Styled from './styled';

export const TipBoard = () => (
    <Styled.Tip>
        <Styled.Title>
            Tip
        </Styled.Title>
        <Styled.Description>
            For <strong>Expansion Board 2.0</strong> make sure that is in
            firmware update mode.
        </Styled.Description>
        <Styled.Description>
            Press the <strong>RESET</strong> button next to the LED before you
            continue
        </Styled.Description>
        <div>
            <img
                alt="Reset Expansion Board"
                src="/images/devices/img_board.png"
            />
        </div>
    </Styled.Tip>
);
