import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { GET_DEVICE_LAST_MESSAGE } from 'Constants';
import { getStringifiedPayload } from 'Utils';
import { useMessageSubscription } from 'Hooks';

import { SignalQualityWidget } from '../../../../widgets';

export const SignalQuality = ({ chartId, title, deviceToken, pin }) => {
    const { data, loading, refetch } = useQuery(
        GET_DEVICE_LAST_MESSAGE,
        {
            variables: { chartId },
        },
    );
    const lastMessage = data?.getDeviceLastMessage ?? {};

    useMessageSubscription([deviceToken], pin, refetch);

    const signalQuality = useMemo(
        () => (lastMessage?.payload
            ? Number(getStringifiedPayload(lastMessage.payload))
            : null),
        [lastMessage],
    );

    return (
        <SignalQualityWidget
            data={signalQuality}
            title={title}
            isEmpty={loading || !signalQuality}
        />
    );
};

SignalQuality.propTypes = {
    chartId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    deviceToken: PropTypes.string.isRequired,
    pin: PropTypes.number.isRequired,
};
