import moment from 'moment';
import { Tooltip } from 'antd';

import { SAMPLING_TYPES } from 'Constants';
import { Dropdown } from 'Components';

import * as Styled from './styled';

export const getColumns = ({ samplingType, devices, deleteSample, testSample, samples }) => {
    const dropdownActions = [
        {
            executedFunction: testSample,
            title: 'Test sample',
        },
        {
            executedFunction: deleteSample,
            title: 'Delete',
        },
    ];

    const columns = [
        {
            title: 'Created at',
            dataIndex: 'createdAt',
            render: (value, _, index) => `${index + 1} - ${moment(value).format('YYYY-MM-DD')}`,
        },
        {
            title: 'Status',
            dataIndex: 'createdAt',
            render: (value) => {
                const isOldSample = moment().diff(moment(value), 'days') > 30;
                const expiredTitle = 'Data older than one month is removed from the database';

                return (
                    <Tooltip title={isOldSample ? expiredTitle : ''}>
                        <Styled.StatusLabel
                            $valid={!isOldSample}
                        >
                            {isOldSample ? 'expired' : 'valid'}
                        </Styled.StatusLabel>
                    </Tooltip>
                );
            },
        },
        {
            title: 'Label',
            dataIndex: 'label',
        },
        {
            title: 'Device',
            dataIndex: 'device',
            render: (value) => {
                const device = devices.find((item) => item._id === value);

                return device ? device.description : '-';
            },
        },
        {
            title: 'Length (ms)',
            dataIndex: 'length',
        },
        {
            title: 'Actions',
            dataIndex: '_id',
            render: (id) => {
                const localActions = [...dropdownActions];

                if (!samples.find((item) => item.mlSampleId === id)) {
                    localActions[0].disabled = true;
                }

                return (
                    <Dropdown
                        actions={localActions}
                        id={id}
                    />
                );
            },
        },
    ];

    if (samplingType === SAMPLING_TYPES.TEST) {
        columns[2] = {
            title: 'Sample',
            dataIndex: 'sampleName',
        };
    }

    return columns;
};
