import { useMemo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { Table } from 'Components';

import { AlertMessage } from '../alertMessage';

import * as Styled from './styled';

export const ClassificationData = ({ model }) => {
    const { modelDefinition, testing } = useMemo(
        () => ({
            modelDefinition: JSON.parse(model.modelDefinition),
            testing: JSON.parse(model.testing),
        }),
        [model],
    );

    const nnBlock = useMemo(
        () => modelDefinition?.blocks.find((block) => block.id === 'NN'),
        [modelDefinition],
    );

    const { resultColumns, resultData, windowColumns, windowData } = useMemo(() => {
        const result = {
            resultColumns: [],
            resultData: [],
            windowColumns: [],
            windowData: [],
        };

        if (!(testing?.state === 'SUCCESS' && testing?.output?.NN?.samples?.length && nnBlock)) {
            return result;
        }

        const windowsStep = modelDefinition.blocks.find((block) => block.id === 'PP').window_step_ms;
        const minConf = nnBlock.untrained_nn_model.minimum_confidence_threshold;
        
        result.windowData = testing.output.NN.samples.reduce((acc, sample) => [...acc, ...sample.data], []);

        result.windowColumns = Object.keys(result.windowData[0]).map((item) => ({
            title: item,
            dataIndex: item,
            render: (value) => (value ? value.toFixed(4) : '-'),
        }));

        result.resultColumns = [
            {
                title: '',
                dataIndex: 'window',
                render: () => 'Windows',
            },
            ...result.windowColumns.map((column) => ({
                ...column,
                render: (value) => value ?? 0,
            })),
            {
                title: 'uncertain',
                dataIndex: 'uncertain',
                render: (value) => value ?? 0,
            },
        ];

        result.windowColumns = [
            {
                title: '',
                dataIndex: 'windowsStep',
                render: (value) => value ?? 0,
            },
            ...result.windowColumns,
            {
                title: 'Classified as',
                dataIndex: 'result',
                render: (value) => value ?? '-',
            },
        ];

        result.resultData = [{}];

        result.windowData = result.windowData.map((item, index) => {
            const localItem = { ...item };
            const keys = Object.keys(localItem);
            const values = Object.values(localItem);
            const maxValue = Math.max(...values);

            localItem.windowsStep = index * windowsStep;

            if (maxValue > minConf) {
                result.resultData[0][keys[values.indexOf(maxValue)]] =
                    (result.resultData[0]?.[keys[values.indexOf(maxValue)]] || 0) + 1;
                localItem.result = keys[values.indexOf(maxValue)];
            } else {
                result.resultData[0].uncertain = (result.resultData[0]?.uncertain || 0) + 1;
                localItem.result = 'uncertain';
            }

            return localItem;
        });

        return result;
    }, [modelDefinition, testing]);

    if (isEmpty(testing) || !nnBlock) {
        return (
            <AlertMessage
                type="warning"
                title="There is no test data yet"
                text="The test can be done selecting the samples above"
            />
        );
    }

    if (testing.state === 'TESTING') {
        return (
            <AlertMessage
                type="info"
                title="Testing Performance Status: Testing"
                text="The model is being tested..."
            />
        );
    }

    if (testing.state === 'FAILURE') {
        return (
            <AlertMessage
                type="error"
                title="The test has failed"
                text={testing.failure_cause}
            />
        );
    }

    return (
        <Styled.Wrapper>
            <h2>
                {`${testing?.output?.NN?.samples?.[0]?.sample_name} - sample results`}
            </h2>
            <Table
                columns={resultColumns}
                data={resultData}
            />
            <h2>Result for every window in sample</h2>
            <Table
                columns={windowColumns}
                data={windowData}
            />
        </Styled.Wrapper>
    );
};

ClassificationData.propTypes = {
    model: PropTypes.object.isRequired,
};
