import PropTypes from 'prop-types';

import { Block } from '../custom/block';
import { Stepper } from '../stepper';

import * as Styled from './styled';

export const PageHeader = ({ title, description, stepper, step, maxStep, withIcon }) => (
    <div>
        <div>
            <Styled.Item>
                <Styled.Top>
                    <Styled.Title $withIcon={withIcon}>
                        {withIcon && <Block />}
                        <Styled.PageTitle>
                            {title}
                        </Styled.PageTitle>
                    </Styled.Title>
                    {stepper && <Stepper step={step} maxStep={maxStep} />}
                </Styled.Top>
                <Styled.Bottom>
                    <Styled.PageDescription>
                        {description}
                    </Styled.PageDescription>
                </Styled.Bottom>
            </Styled.Item>
        </div>
    </div>
);

PageHeader.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.array,
    stepper: PropTypes.bool,
    step: PropTypes.number,
    maxStep: PropTypes.number,
    withIcon: PropTypes.bool,
};

PageHeader.defaultProps = {
    description: [],
    step: 1,
    maxStep: 4,
    stepper: false,
    withIcon: true,
};
