import gql from 'graphql-tag';

export const GET_APPLICATIONS = gql`
    query getApplications {
        getApplications {
          _id
          networks
          name
          numOfDevice
          __typename
        }
    }
`;

export const CREATE_APPLICATION_MUTATION = gql`
    mutation createApplication($applicationInput: NewApplicationInput!) {
       createApplication(applicationInput: $applicationInput) {
           _id
           name
           networks
       }
    }
`;

export const DELETE_APPLICATION = gql`
    mutation deleteApplication($id: String!) {
        deleteApplication(id: $id) {
            ok
        }
    }
`;

export const GET_APPLICATION_QUERY = gql`
    query getApplication($id: String!) {
        getApplication(id: $id) {
            _id
            name
            description
            deviceTypes
            networks
            ethernetId
            ethernet {
                name
                autoSettings
                ipAddress
                subnetMask
                router
                dnsServer
                dnsServer2
            }
            lte {
                _id
                apn
                band
                carrier
                cid
                protocol
                reset
            }
            loraType
            loraServer
            loraRegion
            wifi {
                _id
               ssid
               password
               secureType
            }
        }
    }
`;

export const UPDATE_APPLICATION_MUTATION = gql`
    mutation updateApplication($application: ApplicationInput) {
        updateApplication(application: $application) {
            n
            ok
        }
    }
`;

export const GET_APPLICATION_TOKEN = gql`
    query getApplicationToken($id: String!) {
        getApplicationToken(id: $id) {
            accessId,
        }
    }
`;

export const GENERATE_APPLICATION_KEYS = gql`
    mutation generateApplicationKeys($applicationId: String!) {
        generateApplicationKeys(applicationId: $applicationId) {
           accessId,
           secret,
       }
    }
`;

export const GET_RELEASES_QUERY = gql`
    query getReleases($applicationId: String!){
      getReleases(applicationId: $applicationId){
        _id
        releaseVersion
        applicationId
        firmware
        customFirmwares {
           firmwareFilename
           firmwareType
           originalFilename
           __typename
        }
        codeFilename
        createdAt
        summary
        __typename
      }
    }
`;

export const GET_RELEASE_FILES_QUERY = gql`
    query getReleaseFiles($applicationId: String!, $releaseId: String!){
      getReleaseFiles(applicationId: $applicationId, releaseId: $releaseId){
        path,
        content
      }
    }
`;

export const DEPLOY_NEW_RELEASE = gql`
    mutation deployNewRelease($releaseId: String!, $applicationId: String!, $listOfDevices: [String!]){
      deployNewRelease(releaseId: $releaseId, applicationId: $applicationId, listOfDevices: $listOfDevices)
    }
`;

export const ADD_RELEASE_MUTATION = gql`
    mutation createRelease($details: ReleaseInput!){
      createRelease(details: $details){
        _id
        applicationId
        networkPreferences
        wifi {
          ssid
          password
          secureType
        }
        firmware
        codeFilename
        createdAt
        summary
      }
    }
`;

export const DELETE_RELEASE = gql`
    mutation deleteRelease($releaseId: String!){
      deleteRelease(releaseId: $releaseId)
    }
`;

export const GET_RELEASE_BY_ID_QUERY = gql`
  query getRelease($id: String!) {
    getRelease(id: $id){
      _id
      releaseVersion
      applicationId
      networkPreferences
      wifi {
          ssid
          password
          secureType
      }
      lte {
          carrier
          band
          cid
          protocol
          reset
          apn
      }
      ethernet {
        autoSettings
        ipAddress
        subnetMask
        router
        dnsServer
        dnsServer2
      }
      loraType
      loraServer
      loraRegion
      firmware
      codeFilename
      customFirmwares {
         firmwareFilename
         firmwareType
         originalFilename
      }
      createdAt
      summary
      originalFilename
    }
  }
`;

export const GET_DEVICE_ACTIVITY = gql`
    query getDevicesActivity($chartId: String!){
        getDevicesActivity(chartId: $chartId) {
            countedDevices {
                name
                count
            }
            devicesTokens
        }
    }
`;

export const GET_DEVICES_GROUPED_BY_SIGNALS = gql`
    query getDevicesGroupedBySignals($chartId: String!){
        getDevicesGroupedBySignals(chartId: $chartId) {
            devicesTokens
            groups {
                name
                count
            }
        }
    }
`;

export const PROJECT_CHARTS_QUERY = gql`
    query getApplicationCharts($applicationId: String!) {
        getApplicationCharts(applicationId: $applicationId) {
              _id
              name
              owner
              applicationId
              type
              widget {
                  _id
                  owner
                  width
                  height
                  x
                  y
                  createdAt
              }
              settings {
                  pin
                  filteredDevices
                  position {
                      lat
                      lng
                  }
                  unit
                  criteria
                  timePeriod
                  limit
              }
              createdAt
        }
    }
`;

export const EDIT_PROJECT_WIDGETS_MUTATION = gql`
    mutation EditProjectWidgets($widgets: [WidgetUpdateInput]!) {
        saveApplicationWidgets(widgets: $widgets) {
            _id
        }
    }
`;

export const GET_WEATHER_BY_LOCATION = gql`
    query getWeather($filter: WeatherFilter!){
        getWeather(filter: $filter) {
            current {
                time
                temp
                main
                description
                icon
            }
            hourly {
                time
                temp
                main
                description
                icon
            }
        }
    }
`;

export const CREATE_APPLICATION_WIDGET = gql`
    mutation createApplicationWidget($widget: WidgetCreateInput!) {
        createApplicationWidget(widget: $widget) {
            _id
        }
    }
`;

export const UPDATE_APPLICATION_CHART = gql`
    mutation updateApplicationChart($chartInput: ApplicationChartUpdateInput!) {
        updateApplicationChart(chartInput: $chartInput) {
            _id
        }
    }
`;

export const CREATE_APPLICATION_CHART = gql`
    mutation createApplicationChart($chartInput: ApplicationChartCreateInput!) {
        createApplicationChart(chartInput: $chartInput) {
            _id
            name
            owner
            applicationId
            type
            widget {
                _id
                owner
                width
                height
                x
                y
                createdAt
            }
            settings {
                pin
                filteredDevices
                position {
                    lat
                    lng
                }
                unit
                timePeriod
                criteria
            }
            createdAt
        }
    }
`;

export const DELETE_APPLICATION_CHART = gql`
    mutation deleteApplicationChart($id: String!) {
        deleteApplicationChart(id: $id)
    }
`;

export const CREATE_DEFAULT_PROJECT_CHARTS = gql`
    mutation createDefaultApplicationCharts($applicationId: String!) {
        createDefaultApplicationCharts(applicationId: $applicationId) {
            _id
            name
            owner
            applicationId
            type
            widget {
                _id
                owner
                width
                height
                x
                y
                createdAt
            }
            settings {
                pin
                filteredDevices
                position {
                    lat
                    lng
                }
                unit
                timePeriod
                criteria
            }
            createdAt
        }
    }
`;
