import styled from 'styled-components';

import { Tag as AntTag } from 'antd';

export const Header = styled.div`
    margin: 20px 0;
    padding-left: 35px;
`;

export const Tag = styled(AntTag)`
    margin: 0 10px;
    border-radius: 20px;
    background: ${({ theme }) => theme.colors.active};
    color: ${({ theme }) => theme.colors.activeText};
    border-color: ${({ theme }) => theme.colors.active};
`;

export const ChartWrapper = styled.div`
    width: 100%;
    height: 100%;

    .recharts-legend-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;
