import PropTypes from 'prop-types';

import * as Styled from './styled';

export const RadioButtonGroup = ({ options, field, checked, onChange }) => (
    <>
        {options.map((valueItem) => (
            <Styled.RadioButton key={valueItem.value}>
                <Styled.Container htmlFor={valueItem.label}>
                    {valueItem.label}
                    {field && Object.values(field).length ? (
                        <input
                            type="radio"
                            id={valueItem.label}
                            value={valueItem.value}
                            name={field.name}
                            onChange={field.onChange}
                            onBlur={field.onBlur}
                            checked={field.value === valueItem.value}
                            data-cy={valueItem.cyData}
                        />
                    ) : (
                        <input
                            type="radio"
                            name="radio"
                            defaultChecked={checked}
                            id={valueItem.label}
                            value={valueItem.value}
                            onChange={onChange}
                            data-cy={valueItem.cyData}
                        />
                    )}
                    <span />
                </Styled.Container>
            </Styled.RadioButton>
        ))}
    </>
);

RadioButtonGroup.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })).isRequired,
    field: PropTypes.object,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
};

RadioButtonGroup.defaultProps = {
    field: null,
    checked: false,
    onChange: () => {},
};
