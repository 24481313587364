import { SOCKET_ACTIONS_TYPES } from './actionTypes';

export const firmwareSocketIniialState = {
    ports: null,
    remoteConfig: null,
    infoAboutDevice: [],
    regionList: null,
    downloadedFirmware: null,
    firmware: null,
    error: null,
};

export const firmwareSocketReducer = (state, action) => {
    switch (action.type) {
        case SOCKET_ACTIONS_TYPES.SCAN_PORTS:
            return {
                ...state,
                ports: action.payload,
            };
        case SOCKET_ACTIONS_TYPES.INFO:
            {
                const { timeoutRef } = action.payload;
                const port = timeoutRef.slice(timeoutRef.lastIndexOf('_'));
                return {
                    ...state,
                    infoAboutDevice: [
                        ...state.infoAboutDevice, 
                        {
                            port,
                            info: action.payload.results,
                        },
                    ],
                };  
            }
        case SOCKET_ACTIONS_TYPES.REMOTE_CONFIG: 
            return {
                ...state,
                remoteConfig: action.payload,
            };
        case SOCKET_ACTIONS_TYPES.REGION_LIST:
            return {
                ...state,
                regionList: action.payload,
            };
        case SOCKET_ACTIONS_TYPES.WRITE_FIRMWARE:
            return {
                ...state,
                firmware: action.payload,
            };
        case SOCKET_ACTIONS_TYPES.DOWNLOAD_FIRMWARE:
            return {
                ...state,
                downloadedFirmware: action.payload,
            };
        case SOCKET_ACTIONS_TYPES.CLEAR_ALL_INFO:
            return {
                ...firmwareSocketIniialState,
            };
        case SOCKET_ACTIONS_TYPES.ERROR: 
            return {
                ...state,
                error: action.payload,
            };
        default:
            return state;
    }
};
