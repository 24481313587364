import styled from 'styled-components';

export const TokenField = styled.div`
    padding: 10px 50px 10px 10px;
    min-width: 300px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 5px;
    border-right: none;
    margin-right: -5px;
`;

export const TokenWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
`;

export const Wrapper = styled.div`
    margin-top: 10px;
    margin-bottom: 30px;

    p {
        font-weight: 600;
        font-size: medium;
        color: ${({ theme }) => theme.colors.title};
        margin-bottom: 5px;
    }
`;
