import moment from 'moment';

import { NOTIFICATIONS_TYPENAMES } from 'Constants';

export const getColumns = (deviceName) => [
    {
        title: 'Date',
        dataIndex: 'createdAt',
        render: (value) => moment(value).format('MMM Do hh:mm A'),
    },
    {
        title: 'Message',
        dataIndex: '_id',
        render: (_, item) => {
            switch (item.__typename) {
                case NOTIFICATIONS_TYPENAMES.LAST_SEEN: {
                    const duration = moment.duration(item.period, 'minutes').humanize();
                    const time = moment(item.createdAt)
                        .subtract(moment.duration(item.period, 'minutes'))
                        .format('hh:mm A');

                    return `${deviceName} wasn't seen during more than ${duration},
                        last recorded activity at ${time}.`;
                }
                case NOTIFICATIONS_TYPENAMES.DATA_USAGE: {
                    const duration = moment.duration(item.period, 'minutes').humanize();

                    return `${item.amount} byte(s) have been received
                        from ${deviceName} in less than ${duration}.`;
                }
                case NOTIFICATIONS_TYPENAMES.BATTERY: {
                    const label = item.value < 10
                        ? 'Critical Battery Level'
                        : 'Low Battery';

                    return `${label} (${item.value} %)`;
                }
                default:
                    return '';
            }
        },
    },
];
