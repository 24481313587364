import { clearLocalStorageByCondition } from 'Utils';

export const cleanStorageOfPymakr = (projectKey, callback) => {
    clearLocalStorageByCondition(projectKey);
    callback();
};

export const downloadZip = (file64, title) => {
    const element = document.createElement('a');
    element.setAttribute('href', `data:text/plain;base64,${file64}`);
    element.setAttribute('download', `pymakr-${title}.zip`);
    element.click();
};
