import styled from 'styled-components';

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
    gap: 1rem;

    width: 100%;

    padding: 1.5rem;
    background-color: #fafafa;
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 0.5rem;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
`;

export const Value = styled.span`
    font-size: 2rem;
    font-weight: 600;
    line-height: 40px;
    color: ${({ theme }) => theme.colors.title};
`;

export const Title = styled.span`
    font-size: 1rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.title};
`;

export const Link = styled.span`
    display: flex;
    align-items: center;
    gap: 0.5rem;

    font-size: 1rem;
    color: ${({ theme }) => theme.colors.active};

    & > svg {
        font-size: 1.5rem;
    }

    &:hover {
        color: ${({ theme }) => theme.colors.active};
        opacity: 0.8;
    }
`;
