import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import { GET_ML_SAMPLES_BY_MODEL, GET_SAMPLES_BY_MODEL_ID, ML_RESPONSE_STATUSES } from 'Constants';
import { Loader, NoData } from 'Components';

import * as Styled from '../../styled';

import { NeuralNetworkSettings, TrainingPerformance } from './components';
import { getModelDetails } from './helpers';

export const Training = ({ model }) => {
    const [logsList, setLogsList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);

    const { data: mlSamplesData, loading: mlSamplesLoading } = useQuery(
        GET_ML_SAMPLES_BY_MODEL,
        {
            variables: { model: model._id, type: 1 },
            fetchPolicy: 'cache-and-network',
        },
    );
    const mlSamples = mlSamplesData?.getMLSamplesByModelId ?? [];

    const { data: samplesData, loading: samplesLoading } = useQuery(
        GET_SAMPLES_BY_MODEL_ID,
        {
            variables: { modelId: model._id, type: 1 },
            fetchPolicy: 'cache-and-network',
        },
    );
    const samples = samplesData?.getSamplesByModelId ?? [];

    useEffect(
        () => {
            if (initialLoading && !loading && !samplesLoading) {
                setInitialLoading(false);
            }
        },
        [mlSamplesLoading, samplesLoading],
    );

    const { preProcessingStatus, modelDefinition, training } = useMemo(
        () => getModelDetails(model),
        [model],
    );

    if (initialLoading) {
        return <Loader />;
    }

    if (preProcessingStatus?.state !== ML_RESPONSE_STATUSES.SUCCESS) {
        return (
            <NoData
                caption="The model needs to be processed"
                text="The pre-processing can be done at processing tab"
            />
        );
    }

    return (
        <Styled.ModelTabWrapper>
            <Styled.ModelTabLeft>
                <NeuralNetworkSettings
                    model={model}
                    mlSamples={mlSamples}
                    samples={samples}
                    logsList={logsList}
                    setLogsList={setLogsList}
                    preProcessingStatus={preProcessingStatus}
                    modelDefinition={modelDefinition}
                    loading={loading}
                    setLoading={setLoading}
                />
            </Styled.ModelTabLeft>
            <Styled.ModelTabRight>
                <TrainingPerformance
                    training={training}
                    logsList={logsList}
                    loading={loading}
                />
            </Styled.ModelTabRight>
        </Styled.ModelTabWrapper>
    );
};

Training.propTypes = {
    model: PropTypes.object.isRequired,
};
