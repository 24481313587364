import styled, { css } from 'styled-components';

import { IconButton } from '../../buttons';

const menuWrapperUnselectedStyles = css`
    svg{
        color: ${({ theme }) => theme.colors.active};
    }
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
`;

const menuWrapperSelectedStyles = css`    
    svg {
        color: #BDBDBD;
    }
    border: 1px solid #E0E0E0;
`;

export const MenuWrapper = styled.div`
    position: absolute;
    right: 10px;
    top: 10px;
    width: 40px;
    height: 40px;
    ${({ selected }) => (selected ? menuWrapperSelectedStyles : menuWrapperUnselectedStyles)}
    border-radius: 20px;
    align-text: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
        font-size: 16px;
    }
`;

export const Button = styled(IconButton)`
    padding: 0px;
    svg {
        color: ${({ theme }) => theme.colors.active};
        font-size: 14px;
    }
`;
