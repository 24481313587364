export const LAYOUTS = {
    MAIN: 'main',
    PYMAKR: 'pymakr',
    AUTH: 'auth',
};

export const ROUTES = {
    main: '/',
    auth: {
        login: '/login',
        register: '/register',
        forgotPassword: '/forgot-password',
        resetPassword: '/reset-password',
        confirmEmail: '/email-confirmation',
    },
    devices: {
        main: '/devices',
        add: '/devices/add',
        firmware: '/devices/firmware/:deviceId',
        createWidget: {
            main: '/devices/:deviceToken/widgets/add',
            add: '/widgets/add',
        },
        signalDetails: {
            main: '/devices/:token/signals/:signalId',
        },
        deviceDetails: {
            main: '/devices/:token',
            dashboard: '/dashboard',
            notifications: '/notifications',
            signals: '/signals',
            provisioning: '/provisioning',
            configuration: '/configuration',
            pymakrOnline: '/pymakr-online',
        },
    },
    projects: {
        main: '/projects',
        create: '/projects/create',
        createFastIot: '/projects/create-fast-iot',
        createWidget: {
            main: '/projects/:id/widgets/add',
            add: '/widgets/add',
        },
        projectDetails: {
            main: '/projects/:id',
            devices: '/devices',
            releases: '/releases',
            releaseCreate: '/releases/create',
            releaseDetails: '/releases/:releaseId',
            configuration: '/configuration',
            dashboard: '/dashboard',
            pymesh: '/pymesh',
        },
    },
    settings: {
        main: '/settings',
        network: {
            main: '/settings/network',
            wifi: '/settings/network/wifi',
            lte: '/settings/network/lte',
            lora: '/settings/network/lora',
            ethernet: '/settings/network/ethernet',
            sigfox: '/settings/network/sigfox',
        },
        simCards: {
            main: '/settings/sim-cards',
            details: '/settings/sim-cards',
        },
        integrations: {
            main: '/settings/integrations',
            create: '/settings/integrations/create',
            createAws: '/settings/integrations/create/aws',
            createWebhook: '/settings/integrations/create/webhook',
            createAzure: '/settings/integrations/create/azure',
            createGoogleCloud: '/settings/integrations/create/google-cloud',
            details: '/settings/integrations/:id',
        },
        openApi: {
            main: '/settings/public-api',
            logs: '/settings/public-api/logs',
            tokens: '/settings/public-api/tokens',
        },
    },
    pymakr: {
        main: '/pymakr',
        blankProject: '/pymakr/blank',
        project: '/pymakr/project',
        cubeMX: '/pymakr/cubemx',
        zipFile: '/pymakr/zip',
        github: '/pymakr/github',
        device: '/pymakr/device',
    },
    machineLearning: {
        main: '/machine-learning',
        createModel: '/machine-learning/add',
        modelDetails: {
            main: '/machine-learning',
            modelId: '/machine-learning/:modelId',
            dataAcquisition: '/dataAcquisition',
            processing: '/processing',
            featureAnalysis: '/feature-analysis',
            training: '/training',
            testing: '/testing',
            deployment: '/deployment',
            configuration: '/configuration',
        },
    },
    accountSection: {
        main: '/account',
        usage: '/usage',
        security: '/security',
        certificates: '/certificates',
    },
    pymesh: {
        add: '/pymesh/add',
        structure: '/structure',
        monitoring: '/monitoring',
        location: '/location',
    },
    support: '/support',
};
