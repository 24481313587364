import { useEffect, useState } from 'react';
import {
    getMD5Hash,
    updateMD5ContentSum,
    getDeviceModeInfo,
} from 'Utils';
import { useContextSelector } from './useContextSelector';

export const useResets = (projectKey, storageKey, initialMD5, handlers) => {
    const [deviceModeInfo, setDeviceModeInfo] = useState(null);
    const colorizerSlice = useContextSelector('colorizer');
    const { finishUpdate } = colorizerSlice.handlers;
    const { resetMD5 } = colorizerSlice.state;

    const editorSlice = useContextSelector('editor');
    const { stopRefreshing } = editorSlice.handlers;
    const { refreshEditorRequired, refreshStorageRequired } = editorSlice.state;

    const tabsSlice = useContextSelector('tabs');
    const { activeTab } = tabsSlice.state;

    const { setInitialMD5, updateMemoryLimiter, setFileData } = handlers;

    useEffect(() => {
        setDeviceModeInfo(getDeviceModeInfo(projectKey));
    }, []);

    useEffect(() => {
        if (resetMD5 && deviceModeInfo) {
            setInitialMD5(getMD5Hash(projectKey, activeTab.path));
            updateMD5ContentSum(projectKey, activeTab.path);
            finishUpdate();
        }
    }, [resetMD5]);

    useEffect(() => {
        updateMemoryLimiter();
        const refreshData = localStorage.getItem(storageKey) || '';
        setFileData(refreshData);
        if (deviceModeInfo) {
            const newInitialMD5 = getMD5Hash(projectKey, activeTab.path);
            if (newInitialMD5 !== initialMD5) {
                setInitialMD5(newInitialMD5);
            }
        }
    }, [storageKey]);

    useEffect(() => {
        if (refreshEditorRequired) {
            const activeFileData = localStorage.getItem(storageKey) || '';
            setFileData(activeFileData);
            stopRefreshing();
        }
        if (refreshStorageRequired) {
            updateMemoryLimiter();
            stopRefreshing();
        }
    }, [refreshEditorRequired, refreshStorageRequired]);
};
