import styled from 'styled-components';

export const Table = styled.div`
    margin-top: 40px;
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    max-width: 440px;
    margin-bottom: 10px;
`;
