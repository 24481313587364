import styled from 'styled-components';

export const WidgetDummyContainer = styled.li`
    list-style-type: none;
    width: 354px;
    max-width: 100%;
    position: relative;
    height: 196px;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid transparent;
    transition: border-color 0.3s;
    padding: 40px 25px;

    ${({ $active, theme }) => $active && `
        border-color: ${theme.colors.active};
    `}

    &:hover {
        border-color: ${({ theme }) => theme.colors.active};
    }
`;

export const WidgetDummyContent = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
`;

export const Title = styled.h3`
    font-size: 20px;
    line-height: 25px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.title};
    flex: 1;
`;

export const IconWrapper = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;

    svg {
        width: auto;
        height: 100px;
    }
`;
