import styled, { css } from 'styled-components';

import { IconButton } from 'Components';

export const Tab = styled.div`
    position: relative;
    flex: 1 1 auto;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.boxBackground};
    max-width: 200px;
    border-radius: 5px 5px 0 0;
    padding: 5px 0 4px 20px;
    cursor: pointer;

    ${({ $active, theme }) => $active && `
        background-color: ${theme.colors.activeText};
        cursor: auto;
    `}

    span {
        position: absolute;
        left: 20px;
        top: 5px;
        max-width: 50%;
        white-space: nowrap;
    }
`;

export const Button = styled(IconButton)`
    position: absolute;
    right: 0;
    top: 2px;
    width: 35px;
    height: 35px;
    padding: 0 5px;
    background-color: transparent;
    z-index: 10;

    svg {
        margin: 0;
        margin-top: -6px;
        width: 25px;
        height: 25px;
    }

    &:hover {
        background-color: transparent;
    }
`;

export const UploadFileButton = styled(IconButton)`
    position: absolute;
    right: 30px;
    top: 3px;
    padding: 0 5px;
    width: 25px;
    height: 25px;
    z-index: 9;
    background-color: transparent;

    svg {
        margin: 0;
        width: 25px;
        height: 25px;
        fill: #c46200;
    }

    &:hover {
        background-color: transparent;
        svg {
            fill: ${({ theme }) => theme.colors.active};
        }
    }
`;

const updatedStyling = css`
    color: #c46200;
`;

export const TabTitle = styled.span`
    ${({ $updated }) => $updated && updatedStyling}
`;
