import { useMemo } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useMutation, useApolloClient, useQuery } from '@apollo/client';

import {
    SAVE_LORA_SETTINGS,
    GET_LORA_SETTINGS,
    MODAL,
    TAB,
    LORA_CONSTANTS,
} from 'Constants';
import { PycomLoraServer } from 'Services';
import { showToastError, showToastSuccess } from 'Utils';

import { FormContent } from './formContent';
import { validationSchema } from './config';

import { getInitialValues, prepareForRequest } from './helpers';
import * as Styled from './styled';

export const LoRaModal = ({ handleClose, handleSuccess, typeOfForm }) => {
    const client = useApolloClient();
    const { data: settingsArray } = useQuery(GET_LORA_SETTINGS);
    const loraSettings = useMemo(
        () => settingsArray?.getLoRaSettings ?? {},
        [settingsArray],
    );

    const [saveSettings, { loading: isLoading }] = useMutation(SAVE_LORA_SETTINGS, {
        refetchQueries: [GET_LORA_SETTINGS],
        awaitRefetchQueries: true,
    });

    const createPycomLoraServerProfile = async (region) => {
        const server = new PycomLoraServer({ client });
        await server.createPycomLoraServerProfile(region);
    };

    const saveLoRaToAPI = async (payload) => {
        try {
            await saveSettings({
                variables: {
                    lora: payload,
                },
                update: () => {
                    showToastSuccess('LoRa settings added successfully');
                    if (typeOfForm === MODAL) {
                        const finalFunc = handleSuccess || handleClose;
                        finalFunc();
                    }
                },
            });
        } catch (error) {
            showToastError(error.message);
        }
    };

    const save = async (values) => {
        const payload = prepareForRequest(values);
        if (values.loraServer === LORA_CONSTANTS.SERVERS.CHIRPSTACK) {
            await createPycomLoraServerProfile(payload.loraRegion);
        }
        await saveLoRaToAPI(payload);
    };

    return (
        <Styled.Modal
            $modal={typeOfForm === MODAL}
            $deactivated={typeOfForm === TAB}
        >
            {typeOfForm === MODAL && (
                <Styled.Header>
                    LoRa Settings
                </Styled.Header>
            )}
            {typeOfForm === TAB && (
                <div>
                    <Styled.Title>
                        LoRa Network
                    </Styled.Title>
                    <Styled.Description>
                        Define the LoRa network that you is going to use
                    </Styled.Description>
                </div>
            )}
            <Formik
                enableReinitialize
                initialValues={getInitialValues(loraSettings)}
                validationSchema={validationSchema}
                onSubmit={save}
            >
                {(props) => (
                    <FormContent
                        {...props}
                        isShowCancel={!(typeOfForm === TAB)}
                        handleClose={handleClose}
                        isLoading={isLoading}
                    />
                )}
            </Formik>
        </Styled.Modal>
    );
};

LoRaModal.propTypes = {
    handleClose: PropTypes.func,
    handleSuccess: PropTypes.func,
    typeOfForm: PropTypes.oneOf([MODAL, TAB]).isRequired,
};

LoRaModal.defaultProps = {
    handleClose: () => {},
    handleSuccess: null,
};
