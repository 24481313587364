import { Field } from 'formik';
import PropTypes from 'prop-types';

import { Select, TextInput } from 'Components';

import { stackdriverOptions } from './config';
import * as Styled from './styled';

export const FormContent = ({ handleSubmit, loading }) => (
    <Styled.FormContent>    
        <Styled.FormItem>
            <Styled.ItemLabel>
                Registry ID
            </Styled.ItemLabel>
            <Field
                name="registryId"
                component={TextInput}
            />
        </Styled.FormItem>
        <Styled.FormItem>
            <Styled.ItemLabel>
                Default telemetry topic
            </Styled.ItemLabel>
            <Field
                name="newTopic"
                component={TextInput}
            />
        </Styled.FormItem>
        <Styled.FormItem>
            <Styled.ItemLabel>
                Subfolder
            </Styled.ItemLabel>
            <Field
                name="subfolder"
                component={TextInput}
            />
        </Styled.FormItem>
        <Styled.FormItem>
            <Styled.ItemLabel>
                Stackdriver logging level
            </Styled.ItemLabel>
            <Field
                name="stackdriver"
                component={Select}
                values={stackdriverOptions}
            />
        </Styled.FormItem>
        <Styled.Button
            onClick={handleSubmit}
            loading={loading}
        >
            Create
        </Styled.Button>
    </Styled.FormContent>
);

FormContent.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};
