import { scaleTime } from 'd3-scale';
import {
    timeSecond,
    timeMinute,
    timeHour,
    timeDay,
    timeWeek,
    timeMonth,
    timeYear,
} from 'd3-time';

import {
    ALL,
    CHART_TYPES,
    COMMON_CHART_TYPES,
    TEMPERATURE_UNITS,
    ALTITUDE_UNITS,
    WEATHER_UNIT_OPTIONS,
    LINE_CHART_TYPES,
    BAR_CHART_TYPES,
    MAP_CHART_TYPES,
    TIME_SCALE,
} from 'Constants';
import { getStringifiedPayload } from './signal';
import { getRandomColor } from './helpers';

const ALL_DEVICE = 'All devices';

export const generateColors = (deviceNames, mainColor) => {
    const colors = {};
    for (let i = 0; i <= deviceNames.length; i++) {
        switch (i) {
            case 0:
                colors[deviceNames[i]] = '#383744';
                break;
            case 1:
                colors[deviceNames[i]] = mainColor;
                break;
            case 2:
                colors[deviceNames[i]] = '#FAC05E';
                break;
            case 3:
                colors[deviceNames[i]] = '#78D64B';
                break;
            default:
                colors[deviceNames[i]] = getRandomColor();
                break;
        }
    }
    return colors;
};

export const getChartTicks = (chartData, selectedTimeUnitValue) => {
    const ticks = {
        tickFormatter: '',
        ticks: [],
    };

    if (!chartData.length) {
        return ticks;
    }

    const startDate = new Date(chartData[0].date);
    const endDate = new Date(chartData[chartData.length - 1].date);

    const domain = scaleTime().domain([startDate, endDate]);
    const defaultTicks = [startDate, endDate];

    switch (selectedTimeUnitValue) {
        case TIME_SCALE.SECOND:
            ticks.ticks = domain.ticks(timeSecond.every(1));
            ticks.tickFormatter = 'hh:mm:ssa';
            break;
        case TIME_SCALE.MINUTE:
            ticks.ticks = domain.ticks(timeMinute.every(1));
            ticks.tickFormatter = 'hh:mma';
            break;
        case TIME_SCALE.HOUR:
            const hourTicks = domain.ticks(timeHour.every(1));
            ticks.ticks = hourTicks.length ? hourTicks : defaultTicks;
            ticks.tickFormatter = 'hha';
            break;
        case TIME_SCALE.DAY:
            const dayTicks = domain.ticks(timeDay.every(1));
            ticks.ticks = dayTicks.length ? dayTicks : defaultTicks;
            ticks.tickFormatter = 'DD:MM:YY';
            break;
        case TIME_SCALE.WEEK:
            const weekTicks = domain.ticks(timeWeek.every(1));
            ticks.ticks = weekTicks.length ? weekTicks : defaultTicks;
            ticks.tickFormatter = 'YY:wo';
            break;
        case TIME_SCALE.MONTH:
            const monthTicks = domain.ticks(timeMonth.every(1));
            ticks.ticks = monthTicks.length ? monthTicks : defaultTicks;
            ticks.tickFormatter = 'MMM YY';
            break;
        case TIME_SCALE.QUARTER:
            const quarterTicks = domain.ticks(timeMonth.every(3));
            ticks.ticks = quarterTicks.length ? quarterTicks : defaultTicks;
            ticks.tickFormatter = 'MMM YY';
            break;
        case TIME_SCALE.YEAR:
            const yearTicks = domain.ticks(timeYear.every(1));
            ticks.ticks = yearTicks.length ? yearTicks : defaultTicks;
            ticks.tickFormatter = 'MMM YY';
            break;
        default:
            ticks.ticks = domain.ticks(timeMinute.every(1));
            ticks.tickFormatter = 'hh:mmA';
            break;
    }

    return ticks;
};

export const BATERRY_LEVEL_CHARGING = {
    ALERT: 'ALERT',
    WARN: 'WARN',
    NORMAL: 'NORMAL',
};
export const COUNT_OF_BAR = 5;
export const ALL_DEVICES = { value: ALL, label: ALL_DEVICE };

export const getDataCountWidgetData = (projectMessages) => {
    const parsedMessages = projectMessages.map((item) => {
        const message = item.lastMessage;
        return getStringifiedPayload(message.payload);
    });

    const calculation = parsedMessages.reduce((accumulator, current) => {
        const currentNumber = Number(current);
        return {
            sum: accumulator.sum + (currentNumber ?? 0),
            min: accumulator.min < currentNumber && accumulator.min !== 0 ? accumulator.min : currentNumber,
            max: accumulator.max > currentNumber ? accumulator.max : currentNumber,
        };
    }, {
        sum: 0,
        min: 0,
        max: 0,
    });

    const devicesCount = parsedMessages.length;
    return {
        // todo: think about precision. Maybe we need 2 numbers after the dot
        avg: Number(devicesCount ? (calculation.sum / devicesCount) : 0).toFixed(),
        min: Number(calculation.min).toFixed(),
        max: Number(calculation.max).toFixed(),
        devicesCount,
    };
};

const METERS_IN_KILOMETER = 1000;
const YARDS_IN_MILE = 1760;

export const calculateDataForMeters = (inMeters) => ({
    value: inMeters < METERS_IN_KILOMETER
        ? inMeters
        : inMeters / METERS_IN_KILOMETER,
    unit: inMeters < METERS_IN_KILOMETER
        ? 'm'
        : 'km',
});

export const calculateDataForYards = (inYards) => ({
    value: inYards < YARDS_IN_MILE
        ? inYards
        : inYards / YARDS_IN_MILE,
    unit: inYards < YARDS_IN_MILE
        ? 'yd'
        : 'mi',
});

export const getAvailableChartTypes = (widgetType) => {
    switch (widgetType) {
        case COMMON_CHART_TYPES.LINE_CHART:
        case CHART_TYPES.DATA_RECEPTION_HOUR:
        case CHART_TYPES.DATA_RECEPTION_LINE_CHART:
        case CHART_TYPES.DATA_RECEPTION_PER_PIN_LINE_CHART:
            return Object.entries(LINE_CHART_TYPES).map(([key, value]) => ({
                value: key,
                label: value,
            }));
        case COMMON_CHART_TYPES.BAR_CHART:
        case CHART_TYPES.DATA_RECEPTION_DAY:
        case CHART_TYPES.DATA_RECEPTION_BAR_CHART:
        case CHART_TYPES.DATA_RECEPTION_PER_PIN_BAR_CHART:
            return Object.entries(BAR_CHART_TYPES).map(([key, value]) => ({
                value: key,
                label: value,
            }));
        case COMMON_CHART_TYPES.MAP:
        case CHART_TYPES.DEVICE_LOCATION:
            return Object.entries(MAP_CHART_TYPES).map(([key, value]) => ({
                value: key,
                label: value,
            }));
        default:
            return [];
    }
};

export const getUnitOptions = (widgetType) => {
    switch (widgetType) {
        case COMMON_CHART_TYPES.TEMPERATURE:
            return Object.values(TEMPERATURE_UNITS).map((item) => ({
                value: item,
                label: item,
            }));

        case COMMON_CHART_TYPES.ALTITUDE:
            return Object.values(ALTITUDE_UNITS).map((item) => ({
                value: item,
                label: item,
            }));

        case COMMON_CHART_TYPES.WEATHER:
            return WEATHER_UNIT_OPTIONS;

        default:
            return [];
    }
};

export const getDataBatteryCount = (projectMessages) => {
    const value = +(getStringifiedPayload(projectMessages));
    const data = {
        countDevice: 1,
    };
    if (value < 0) {
        return { ...data, value: 0 };
    }
    if (value > 100) {
        return { ...data, value: 100 };
    }
    return { ...data, value };
};
