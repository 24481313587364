import { Dropdown } from 'Components';
import { ReactComponent as EthernetIcon } from 'Assets/icons/ethernet.svg';
import { ReactComponent as MarkerIcon } from 'Assets/icons/marker-end.svg';

import * as Styled from './styled';

export const getColumns = ({
    editEthernet,
    deleteEthernet,
    makeDefaultEthernet,
}) => {
    const dropdownActions = [
        {
            executedFunction: makeDefaultEthernet,
            title: 'Set Default',
            cyData: 'ethernet-make-default',
        },
        {
            executedFunction: editEthernet,
            title: 'Edit',
            cyData: 'ethernet-edit',
        },
        {
            executedFunction: deleteEthernet,
            title: 'Delete',
            cyData: 'ethernet-delete',
        },
    ];

    return [
        {
            title: '',
            dataIndex: 'default',
            width: '5%',
            render: (value) => (
                <>
                    { !!value && (
                        <Styled.IconWrapper>
                            <MarkerIcon />
                        </Styled.IconWrapper>
                    )}
                </>
            ),
        },
        {
            title: '',
            dataIndex: 'ethernetId',
            width: '5%',
            render: () => (
                <Styled.IconWrapper>
                    <EthernetIcon />
                </Styled.IconWrapper>
            ),
        },
        {
            title: 'NAME',
            dataIndex: 'name',
            width: '30%',
        },
        {
            title: 'IP ADDRESS',
            dataIndex: 'ipAddress',
            width: '25%',
            render: (value, originItem) => {
                if (originItem.autoSettings) {
                    return 'Automatically';
                }
                return value;
            },
        },
        {
            title: 'DNS Server',
            dataIndex: 'dnsServer',
            width: '25%',
            render: (value, originItem) => {
                if (originItem.autoSettings) {
                    return ' ';
                }
                return value;
            },
        },
        {
            title: 'Actions',
            dataIndex: 'ethernetId',
            width: '10%',
            render: (id) => (
                <Dropdown
                    actions={dropdownActions}
                    id={id}
                    cyData="ethernet-dropdown-button"
                />
            ),
        },
    ];
};
