import PropTypes from 'prop-types';

import * as Styled from './styled';

export const Menu = ({ children, links }) => (
    <Styled.Wrapper>
        <Styled.List>
            {links &&
                links.map((link) => (
                    <Styled.Item
                        key={link.title}
                        to={link.route}
                        data-cy={link.cyData}
                    >
                        <div>{link.title}</div>
                    </Styled.Item>
                ))}
        </Styled.List>
        <Styled.Content>
            {children}
        </Styled.Content>
    </Styled.Wrapper>
);

Menu.propTypes = {
    children: PropTypes.node,
    links: PropTypes.array.isRequired,
};

Menu.defaultProps = {
    children: [],
};
