import { useMemo, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import {
    GET_PYMESH_DATA_BY_DEVICE_LIST_QUERY,
    ROUTES,
} from 'Constants';
import { Table, Spin, EmptyPymeshView, Button, useLastConnection, Loader } from 'Components';
import { showToastSuccess } from 'Utils';

import { columns } from './columns';
import * as Styled from './styled';

export const PymeshMonitoring = ({ devicesEdges, pymeshDevices }) => {
    const history = useHistory();

    const [initialLoading, setInitialLoading] = useState(true);

    const onRowClick = (device) => history.push(`${ROUTES.devices.main}/${device.token}`);

    const deviceTokenList = useMemo(
        () => pymeshDevices.map((device) => device.token),
        [pymeshDevices],
    );

    const { data: pymeshDataReceived, loading, refetch: refetchPymeshData } = useQuery(
        GET_PYMESH_DATA_BY_DEVICE_LIST_QUERY,
        {
            variables: { deviceTokenList },
            fetchPolicy: 'network-only',
        },
    );

    const pymeshData = pymeshDataReceived?.getPymeshDataByDeviceList ?? [];

    const refreshData = () => {
        showToastSuccess('Data has been refreshed');
        refetchPymeshData();
    };

    const filteredDevices = useMemo(
        () => devicesEdges
            .filter((edge) => deviceTokenList.includes(edge.node.token))
            .map((edge) => edge.node),
        [devicesEdges, pymeshDevices],
    );

    const { data: processedDevices, loading: lastConnectionLoading } = useLastConnection(filteredDevices);

    useEffect(
        () => {
            if (initialLoading && !loading && !lastConnectionLoading) {
                setInitialLoading(false);
            }
        },
        [loading, lastConnectionLoading],
    );

    const tableData = useMemo(
        () => {
            if (processedDevices?.length) {
                return processedDevices.map((device) => {
                    const monitoringData = pymeshData.find((deviceData) => deviceData.token === device.token);

                    return {
                        _id: device._id,
                        description: device.description,
                        borderName: monitoringData?.brName || '-',
                        neighborsNum: monitoringData?.nName || '-',
                        lastConnection: device.lastConnection,
                        token: device.token,
                    };
                });
            }
            return [];
        },
        [processedDevices, pymeshData],
    );

    if (initialLoading) {
        return <Loader />;
    }

    if (!tableData.length) {
        return (
            <EmptyPymeshView
                title="Pymesh monitoring data is empty"
                message="There is no monitoring data yet, check if the Pymesh was correctly deployed and the nodes have a proper connection"
            />
        );
    }

    return (
        <Styled.Wrapper>
            <Button onClick={refreshData} buttonType="transparent">
                Refresh
            </Button>
            <Spin loading={loading || lastConnectionLoading}>
                <Table
                    columns={columns}
                    data={tableData}
                    onRowClick={onRowClick}
                />
            </Spin>
        </Styled.Wrapper>
    );
};

PymeshMonitoring.defaultProps = {
    devicesEdges: [],
    pymeshDevices: [],
};

PymeshMonitoring.propTypes = {
    devicesEdges: PropTypes.arrayOf(PropTypes.object),
    pymeshDevices: PropTypes.arrayOf(PropTypes.object),
};
