import { Form, Field } from 'formik';
import PropTypes from 'prop-types';

import { TextInput, MaskedTextInput, RadioButtonGroup } from '../../../inputs';
import { Button } from '../../../buttons';

import { mask } from './helpers';

import {
    AUTO_SETTINGS_TYPES,
    AUTO_SETTINGS_TYPE,
} from './config';

import * as Styled from './styled';

export const FormContent = ({ handleClose, values, isLoading }) => {
    const options = [
        {
            label: AUTO_SETTINGS_TYPE[0],
            value: AUTO_SETTINGS_TYPES.AUTOMATIC,
            cyData: 'ethernet-radio-automatic',
        },
        {
            label: AUTO_SETTINGS_TYPE[1],
            value: AUTO_SETTINGS_TYPES.MANUAL,
            cyData: 'ethernet-radio-manual',
        },
    ];
    return (
        <Form>
            <Styled.Form>
                <Styled.Title>
                    <Styled.MainTitle>
                        New Ethernet Network
                    </Styled.MainTitle>
                    <Styled.Credentials>
                    Editing the Ethernet settings will not update them on your device. For that you need to deploy them on the device.
                    </Styled.Credentials>
                </Styled.Title>
                <div>
                    <Styled.Item>
                        <Styled.InputTitle>
                            Name
                        </Styled.InputTitle>
                        <Field
                            name="name"
                            component={TextInput}
                            errorPosition="bottom"
                        />
                    </Styled.Item>

                    <Field key="autoSettings" role="presentation" name="autoSettings" options={options} component={RadioButtonGroup} />

                    <Styled.Item>
                        <Styled.InputTitle>
                            IP address
                        </Styled.InputTitle>
                        <Field 
                            name="ipAddress" 
                            errorPosition="bottom"
                            mask={mask}
                            disabled={values?.autoSettings === AUTO_SETTINGS_TYPES.AUTOMATIC}
                            component={MaskedTextInput}
                            placeholder="XXX.XXX.XXX.XXX"
                            value={values?.autoSettings === AUTO_SETTINGS_TYPES.AUTOMATIC ? ' ' : values.ipAddress}
                        />
                    </Styled.Item>
                    <Styled.Item>
                        <Styled.InputTitle>
                            Subnet Mask
                        </Styled.InputTitle>
                        <Field 
                            name="subnetMask" 
                            errorPosition="bottom"
                            mask={mask}
                            disabled={values?.autoSettings === AUTO_SETTINGS_TYPES.AUTOMATIC}
                            component={MaskedTextInput}
                            placeholder="XXX.XXX.XXX.XXX"
                        />
                    </Styled.Item>
                    <Styled.Item>
                        <Styled.InputTitle>
                            Router
                        </Styled.InputTitle>
                        <Field 
                            name="router" 
                            errorPosition="bottom"
                            mask={mask}
                            disabled={values?.autoSettings === AUTO_SETTINGS_TYPES.AUTOMATIC}
                            component={MaskedTextInput}
                            placeholder="XXX.XXX.XXX.XXX"
                        />
                    </Styled.Item>
                    <Styled.Item>
                        <Styled.InputTitle>
                            DNS server
                        </Styled.InputTitle>
                        <Field 
                            name="dnsServer" 
                            errorPosition="bottom"
                            mask={mask}
                            disabled={values?.autoSettings === AUTO_SETTINGS_TYPES.AUTOMATIC}
                            component={MaskedTextInput}
                            placeholder="XXX.XXX.XXX.XXX"
                        />
                    </Styled.Item>
                    <Styled.Item>
                        <Styled.InputTitle>
                            DNS server 2
                        </Styled.InputTitle>
                        <Field 
                            name="dnsServer2" 
                            errorPosition="bottom"
                            mask={mask}
                            disabled={values?.autoSettings === AUTO_SETTINGS_TYPES.AUTOMATIC}
                            component={MaskedTextInput}
                            placeholder="XXX.XXX.XXX.XXX"
                        />
                    </Styled.Item>
                </div>
            </Styled.Form>
            <Styled.ButtonsWrapper>
                <div>
                    <Button
                        buttonType="transparent"
                        onClick={handleClose}
                        cyData="button-cancel"
                    >
                        Cancel
                    </Button>
                    <Button 
                        type="submit" 
                        loading={isLoading} 
                        cyData="button-submit"
                    >
                        Save
                    </Button>
                </div>
            </Styled.ButtonsWrapper>
        </Form> 
    );
};

FormContent.propTypes = {
    handleClose: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
};

FormContent.defaultProps = {
    isLoading: false,
};
