import { SOCKET_CONNECTION_ERROR } from 'Constants';

export const SocketService = {
    isSocketOpen: false,
    event: null,
    errorMessage: null,
    onOpen() {
        this.isSocketOpen = true;
    },
    onClose() {
        this.isSocketOpen = false;
    },
    onError() {
        this.isSocketOpen = false;
        this.errorMessage = SOCKET_CONNECTION_ERROR;
    },
    onMessage(event, callback) {
        callback(() => ({ ...this, event }));
    },
};
