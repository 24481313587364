import styled from 'styled-components';

export const Page = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
`;

export const Content = styled.div`
    display: flex;
    flex: 1;
    overflow: hidden;
`;
