/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import { Component } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import isNull from 'lodash/isNull';

import { computeGridChange, clearEmptySpace, clamp, getGridHeight } from 'Utils';
import {
    CHART_PREVIEW_DATA,
    CHART_TYPES,
    DIMENSION_MULTIPLIERS,
    GRID_SIZE,
} from 'Constants';
import { Button } from 'Components';
import { GridCell, WidgetDummy } from './components';

import * as Styled from './styled';

export class DashboardEdit extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dashboard: props.dashboardData || null,
            gridPosition: null,
            dragOffset: null,
            draggedWidgetIndex: null,
            resizedWidgetIndex: null,
            temporaryConfiguration: null,
            blockingIndexes: [],
        };
        this.config = this.state.dashboard;
    }

    componentDidMount() {
        window.onresize = this.updateDimensions;
        document.addEventListener('mouseup', this.handleFreeClick);
    }

    componentWillUnmount() {
        window.onresize = null;
        document.removeEventListener('mouseup', this.handleFreeClick);
        document.body.style.overflowY = 'scroll';
    }

    updateGridLocation = (pos) => {
        const { dashboard, draggedWidgetIndex, resizedWidgetIndex } = this.state;
        let dragOffset = this.state.dragOffset;
        let gridPosition = pos;
        let newWidget = null;
        if (!isNull(draggedWidgetIndex)) {
            if (isNull(dragOffset)) {
                dragOffset = {
                    x: pos.x - dashboard[draggedWidgetIndex].widget.x,
                    y: pos.y - dashboard[draggedWidgetIndex].widget.y,
                };
                this.setState({ dragOffset });
            }

            gridPosition = { x: pos.x - dragOffset.x, y: pos.y - dragOffset.y };
            if (gridPosition.x < 0) { gridPosition.x = 0; }
            if (gridPosition.y < 0) { gridPosition.y = 0; }
        }

        this.setState({ gridPosition });

        if (!isNull(draggedWidgetIndex)) {
            let clampedX = gridPosition.x;
            if (clampedX + dashboard[draggedWidgetIndex].widget.width > GRID_SIZE) {
                clampedX = GRID_SIZE - dashboard[draggedWidgetIndex].widget.width;
            }

            newWidget = {
                _id: dashboard[draggedWidgetIndex].widget._id,
                deviceToken: dashboard[draggedWidgetIndex].widget.deviceToken,
                width: dashboard[draggedWidgetIndex].widget.width,
                height: dashboard[draggedWidgetIndex].widget.height,
                x: clampedX,
                y: gridPosition.y,
            };

            const newConfig = computeGridChange(
                dashboard,
                newWidget,
                [draggedWidgetIndex],
            );
            this.setState({ temporaryConfiguration: newConfig });
        }

        if (!isNull(resizedWidgetIndex)) {
            let minX = 2;
            let minY = 2;

            switch (dashboard[resizedWidgetIndex].type) {
                case CHART_TYPES.TABLE:
                    minX = 4;
                    break;
                case CHART_TYPES.MAP:
                case CHART_TYPES.DEVICE_LOCATION:
                case CHART_TYPES.MAP_HISTORY:
                    minX = 3;
                    minY = 3;
                    break;
                case CHART_TYPES.AIR_QUALITY:
                case CHART_TYPES.ALTITUDE:
                    minX = 4;
                    minY = 3;
                    break;
                default:
                    break;
            }
            const widgetWidth = (gridPosition.x + 1) - dashboard[resizedWidgetIndex].widget.x;
            const widgetHeight = (gridPosition.y + 1) - dashboard[resizedWidgetIndex].widget.y;
            const clampedWidgetWidth = clamp(widgetWidth, minX, GRID_SIZE - dashboard[resizedWidgetIndex].widget.x);
            const clampedWidgetHeight = clamp(widgetHeight, minY, GRID_SIZE);
            newWidget = {
                _id: dashboard[resizedWidgetIndex].widget._id,
                deviceToken: dashboard[resizedWidgetIndex].widget.deviceToken,
                width: clampedWidgetWidth,
                height: clampedWidgetHeight,
                x: dashboard[resizedWidgetIndex].widget.x,
                y: dashboard[resizedWidgetIndex].widget.y,
            };

            const newConfig = computeGridChange(
                dashboard,
                newWidget,
                [resizedWidgetIndex],
            );
            this.setState({ temporaryConfiguration: newConfig });
        }
    }

    dragWidgetDummy = (index) => {
        const { draggedWidgetIndex } = this.state;
        if (isNull(draggedWidgetIndex)) {
            this.setState((prevState) => {
                const { dashboard } = prevState;
                const { x, y } = dashboard[index].widget;
                const gridPosition = { x, y };
                return { draggedWidgetIndex: index, gridPosition, dragOffset: null };
            });
        }
    }

    resizeWidgetDummy = (index) => {
        const { resizedWidgetIndex } = this.state;
        if (isNull(resizedWidgetIndex)) {
            this.setState((prevState) => {
                const { dashboard } = prevState;
                const pos = {
                    x: dashboard[index].widget.x + (dashboard[index].widget.width - 1),
                    y: dashboard[index].widget.y + (dashboard[index].widget.height - 1),
                };
                return { resizedWidgetIndex: index, gridPosition: pos };
            });
        }
    }

    removeWidgetFromDashboard = (index) => {
        const { draggedWidgetIndex, resizedWidgetIndex, dashboard } = this.state;
        if (
            isNull(draggedWidgetIndex) &&
            isNull(resizedWidgetIndex) &&
            !isNull(dashboard[index].widget)
        ) {
            this.setState((prevState) => {
                const dashboardResult = prevState.dashboard;
                dashboardResult[index].widgetID = null;
                dashboardResult.splice(index, 1);
                const newConfig = clearEmptySpace(dashboardResult);
                return { dashboard: newConfig };
            });
        }
    }

    handleFreeClick = () => {
        const { draggedWidgetIndex, resizedWidgetIndex } = this.state;
        if (!isNull(draggedWidgetIndex) || !isNull(resizedWidgetIndex)) {
            this.setState((prevState) => ({
                dashboard: clearEmptySpace(prevState.temporaryConfiguration),
                draggedWidgetIndex: null,
                resizedWidgetIndex: null,
                gridPosition: null,
                temporaryConfiguration: null,
                blockingIndexes: [],
            }));
        }
    }

    renderWidgets = () => {
        const { dashboard, draggedWidgetIndex, resizedWidgetIndex, temporaryConfiguration, blockingIndexes } = this.state;
        const isDragging = !isNull(draggedWidgetIndex) || !isNull(resizedWidgetIndex);
        if (isDragging && !isNull(temporaryConfiguration)) {
            this.config = cloneDeep(temporaryConfiguration);
            if (!isNull(draggedWidgetIndex)) {
                this.config[draggedWidgetIndex] = dashboard[draggedWidgetIndex];
            }
            if (!isNull(resizedWidgetIndex)) {
                this.config[resizedWidgetIndex] = dashboard[resizedWidgetIndex];
            }
        }
        const resultingWidgets = this.config.reduce((acc, configValue, index) => {
            if (!configValue.widget) {
                return acc;
            }
            const chartType = dashboard[index].type;
            const deletionDisabled = !!CHART_PREVIEW_DATA[chartType];
            const subTitle = dashboard[index].name;
            const title = CHART_PREVIEW_DATA[chartType].title;
            const dragged = isDragging && (
                (!isNull(draggedWidgetIndex) && draggedWidgetIndex === index) ||
                (!isNull(resizedWidgetIndex) && resizedWidgetIndex === index)
            );
            acc.push(
                <WidgetDummy
                    key={`WidgetDummy_${dashboard[index].type}_${index}`}
                    widgetConfiguration={this.config[index].widget}
                    title={title}
                    subTitle={subTitle}
                    index={index}
                    dragWidgetDummy={this.dragWidgetDummy}
                    resizeWidgetDummy={this.resizeWidgetDummy}
                    removeWidgetFromDashboard={this.removeWidgetFromDashboard}
                    dragged={dragged}
                    overlapped={blockingIndexes.indexOf(index) !== -1}
                    deletionDisabled={deletionDisabled}
                />,
            );
            return acc;
        }, []);
        return resultingWidgets;
    };

    renderProjection = () => {
        const { dashboard, draggedWidgetIndex, resizedWidgetIndex, gridPosition } = this.state;
        const isDragging = !isNull(draggedWidgetIndex) || !isNull(resizedWidgetIndex);
        if (!isNull(gridPosition) && isDragging) {
            const workingIndex = (
                (!isNull(draggedWidgetIndex) && draggedWidgetIndex) ||
                (!isNull(resizedWidgetIndex) && resizedWidgetIndex)
            );
            const chartType = dashboard[workingIndex]?.type;
            const projectionTitle = dashboard[workingIndex]?.name;
            const projectionSubTitle = CHART_PREVIEW_DATA[chartType]?.title || dashboard[workingIndex]?.pin.name;
            const style = (() => {
                if (!isNull(draggedWidgetIndex)) {
                    const draggedWidget = this.config[draggedWidgetIndex].widget;
                    let clampedX = gridPosition.x;
                    if (clampedX + draggedWidget.width > GRID_SIZE) {
                        clampedX = GRID_SIZE - draggedWidget.width;
                    }
                    return {
                        height: (DIMENSION_MULTIPLIERS.height * draggedWidget.height) - GRID_SIZE,
                        width: (DIMENSION_MULTIPLIERS.width * draggedWidget.width) - GRID_SIZE,
                        left: clampedX * DIMENSION_MULTIPLIERS.width,
                        top: gridPosition.y * DIMENSION_MULTIPLIERS.height,
                    };
                }
                if (!isNull(resizedWidgetIndex)) {
                    let minX = 2;
                    let minY = 2;
                    if (this.config[resizedWidgetIndex].type === CHART_TYPES.TABLE) {
                        minX = 4;
                    } else if (this.config[resizedWidgetIndex].type === CHART_TYPES.MAP) {
                        minX = 3;
                        minY = 3;
                    }
                    const draggedWidget = this.config[resizedWidgetIndex].widget;
                    const widgetWidth = (gridPosition.x + 1) - draggedWidget.x;
                    const widgetHeight = (gridPosition.y + 1) - draggedWidget.y;
                    const clampeWidgetdWidth = clamp(widgetWidth, minX, GRID_SIZE - draggedWidget.x);
                    const clampedWidgetHeight = clamp(widgetHeight, minY, GRID_SIZE);
                    return {
                        height: (DIMENSION_MULTIPLIERS.height * clampedWidgetHeight) - GRID_SIZE,
                        width: (DIMENSION_MULTIPLIERS.width * clampeWidgetdWidth) - GRID_SIZE,
                        left: draggedWidget.x * DIMENSION_MULTIPLIERS.width,
                        top: draggedWidget.y * DIMENSION_MULTIPLIERS.height,
                    };
                }
            })();
            return (
                <Styled.WidgetContainer style={style}>
                    <div className="tabPanel extended heightExtended noMargin widgetProjectionGhost">
                        <div className="tabPanelCoreContent">
                            <div className="dummyContainer">
                                <div className="dummyContent">
                                    <h2>{projectionSubTitle}</h2>
                                    <br />
                                    <h4>{projectionTitle}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </Styled.WidgetContainer>
            );
        }
        return null;
    };

    renderGrid = () => {
        const { dashboard, draggedWidgetIndex, resizedWidgetIndex } = this.state;
        const isDragging = !isNull(draggedWidgetIndex) || !isNull(resizedWidgetIndex);
        const cells = [];
        const gridSize = getGridHeight(dashboard);
        for (let y = 0; y < gridSize; y += 1) {
            for (let x = 0; x < GRID_SIZE; x += 1) {
                cells.push(
                    <GridCell
                        key={`grid_${x}_${y}`}
                        x={x}
                        y={y}
                        updateGridLocation={this.updateGridLocation}
                    />,
                );
            }
        }
        return (
            <div className={`dashboardEditionContainer ${isDragging ? 'active' : ''}`}>
                {cells}
            </div>
        );
    }

    render() {
        const { dashboard } = this.state;
        const { onSave, onCancel, isLoading } = this.props;
        this.config = dashboard;

        if (isNull(dashboard)) { return <div />; }

        const widgets = this.renderWidgets();
        const grid = this.renderGrid();
        const projection = this.renderProjection();

        return (
            <>
                <Styled.DashboardHeaderPanel>
                    <Button onClick={onCancel} buttonType="transparent">
                        Cancel
                    </Button>
                    <Button loading={isLoading} onClick={() => onSave(dashboard)}>
                        Save
                    </Button>
                </Styled.DashboardHeaderPanel>
                <Styled.DashboardContent>
                    {projection}
                    {widgets}
                    {grid}
                </Styled.DashboardContent>
            </>
        );
    }
}

DashboardEdit.propTypes = {
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    dashboardData: PropTypes.array.isRequired,
    isLoading: PropTypes.bool,
};

DashboardEdit.defaultProps = {
    isLoading: false,
};

export default DashboardEdit;
