import styled from 'styled-components';

export const Modal = styled.div`
    width: 700px;
    background-color: #fff;
`;

export const Header = styled.div`
    width: 100%;
    height: 50px;
    padding: 15px 20px;
    background-color: ${({ theme }) => theme.colors.title};
    color: #fff;
    font-size: 16px;
    font-weight: bold;
`;

export const Description = styled.div`
    display: flex;
    align-items: center;
    padding: 20px 15px 0 20px;
    margin-bottom: 25px;

    & > span {
        margin-left: 4px;
        color: ${({ theme }) => theme.colors.text};
        font-size: 16px;
    }
    
    svg {
        ${({ theme }) => theme.colors.text};
        font-size: 20px;
    }
`;

export const Title = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 35px;
`;

export const MainTitle = styled.span`
    color: ${({ theme }) => theme.colors.title};
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: bold;
`;

export const Credentials = styled.span`
    width: 491px;
    color: ${({ theme }) => theme.colors.text};
    font-size: 16px;
`;

export const Form = styled.div`
    padding-left: 53px;
`;

export const Radio = styled.div`
    width: fit-content;
    margin-left: 91px;
`;

export const Item = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    & > span {
        color: ${({ theme }) => theme.colors.title};
        font-size: 16px;
        font-weight: bold;
        line-height: 1.38;
    }
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: center !important;
    padding-bottom: 25px !important;

    & > div {
        width: 216px;
        display: flex;
        justify-content: space-between;

    }
`;

export const InputTitle = styled.span`
    width: 73px;
    margin-right: 15px;
`;
