import { PYMESH_REGIONS } from 'Constants';

export const formatJoinKey = (key = '') => {
    let formattedKey = key;

    if (key.length === 32) {
        formattedKey = key.replace(/(\w{8})(\w{8})(\w{8})(\w{8})/, '$1-$2-$3-$4');
    }

    return formattedKey;
};

export const getInterval = (region) => PYMESH_REGIONS.find((regionInfo) => regionInfo.region.name === region).frequencies_interval;
