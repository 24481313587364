import { SOCKET_ACTIONS_TYPES } from './actionTypes';

export const setPort = (ports) => ({
    type: SOCKET_ACTIONS_TYPES.SCAN_PORTS,
    payload: ports,
});

export const setInfo = (info) => ({
    type: SOCKET_ACTIONS_TYPES.INFO,
    payload: info,
});

export const setRemoteConfig = (remoteConfig) => ({
    type: SOCKET_ACTIONS_TYPES.REMOTE_CONFIG,
    payload: remoteConfig,
});

export const setRegionList = (regionList) => ({
    type: SOCKET_ACTIONS_TYPES.REGION_LIST,
    payload: regionList,
});

export const downloadFirmware = (downloadedFirmware) => ({
    type: SOCKET_ACTIONS_TYPES.DOWNLOAD_FIRMWARE,
    payload: downloadedFirmware,
});

export const writeFirmware = (firmware) => ({
    type: SOCKET_ACTIONS_TYPES.WRITE_FIRMWARE,
    payload: firmware,
});

export const setInitState = () => ({
    type: SOCKET_ACTIONS_TYPES.CLEAR_ALL_INFO,
});

export const setError = (error) => ({
    type: SOCKET_ACTIONS_TYPES.ERROR,
    payload: error,
});
