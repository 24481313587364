export const getInitialValues = (device, lteList) =>
({
    get networks() {
        const currentSetting = device.ttnInfo?.currentSetting
            || device.pycomLoRaServerInfo?.currentSetting || '';
        if (currentSetting) {
            return device.networks.map((item) => {
                if (item === currentSetting) {
                    return 'lora';
                }
                return item;
            });
        }
        return device.networks;
    },
    wifiId: device.wifi?._id,
    ethernet: device.ethernet,
    get lteId() {
        // in device query only lte object is returned, without id
        if (device.lte?.name) {
            return lteList.find((item) => item.name === device.lte.name)?._id;
        }
        return '';
    },
    loraServer: device.loraServer,
    get loraType() {
        if (device.ttnInfo && device.ttnInfo.currentSetting) {
            return `loRa ${device.ttnInfo.currentSetting.split('_')[1].toUpperCase()}`;
        }
        if (device.pycomLoRaServerInfo && device.pycomLoRaServerInfo.currentSetting) {
            return `loRa ${device.pycomLoRaServerInfo.currentSetting.split('_')[1].toUpperCase()}`;
        }
        return '';
    },
    loraRegion: device.ttnInfo?.region ?? '',
});
