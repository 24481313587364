import gql from 'graphql-tag';

export const SAVE_WIFI_MUTATION = gql`
    mutation saveWifiCredentials($wifi: WifiCredentialsInput!) {
        saveWifiCredentials(wifiCredentials: $wifi)
    }
`;

export const REMOVE_WIFI_MUTATION = gql`
    mutation removeWifiCredentials($wifiId: String!) {
        removeWifiCredentials(wifiId: $wifiId)
    }
`;

export const GET_WIFIS = gql`
    query getWifi {
        getSettings {
            _id
            wifiSaved {
                _id
                ssid
                password
                secureType
            }
        }
    }
`;
