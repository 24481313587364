import { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { ThemeContext } from 'styled-components';

import { ReactComponent as MenuSelectorIcon } from 'Assets/widgets/menu/menu.svg';
import { ReactComponent as DeleteIcon } from 'Assets/widgets/menu/delete.svg';
import { ReactComponent as WriteIcon } from 'Assets/icons/write.svg';

import { Modal, DeleteModal } from '../../modals';

import * as Styled from './styled';

export const WidgetMenu = ({ id, onDelete, onEdit, title }) => {
    const styledTheme = useContext(ThemeContext);
    const [isOpenedModal, setIsOpenedModal] = useState(false);
    const [isTooltipShown, setIsTooltipShown] = useState(false);

    const handleClose = () => setIsOpenedModal(false);
    const openModal = () => setIsOpenedModal(true);

    const deleteWidget = async () => {
        onDelete(id);
        handleClose();
    };

    return (
        <>
            <Tooltip
                placement="left"
                trigger={['click']}
                color="white"
                overlayInnerStyle={{
                    color: styledTheme.colors.text,
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    borderRadius: '10px',
                    padding: '20px',
                }}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                title={() => (
                    <div>
                        <Styled.Button
                            onClick={() => onEdit(id)}
                            Icon={WriteIcon}
                        >
                            Edit
                        </Styled.Button>
                        <Styled.Button
                            onClick={openModal}
                            Icon={DeleteIcon}
                        >
                            Delete
                        </Styled.Button>
                    </div>
                )}
                onVisibleChange={(visible) => {
                    setIsTooltipShown(visible);
                }}
            >

                <Styled.MenuWrapper
                    selected={isTooltipShown}
                >
                    <MenuSelectorIcon />
                </Styled.MenuWrapper>
            </Tooltip>
            <Modal isOpened={isOpenedModal} handleClose={handleClose}>
                <DeleteModal
                    title="Device deletion"
                    description={(
                        <>
                            <p>
                                Are you sure you want to delete {title} widget?
                            </p>
                            <p>
                                Once deleted, you will be able to add it again to the Add Widget menu
                            </p>
                        </>
                    )}
                    deleteAction={deleteWidget}
                    cancelAction={handleClose}
                />
            </Modal>
        </>
    );
};

WidgetMenu.propTypes = {
    id: PropTypes.string.isRequired,
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
};
