export const PYMESH_SPREAD_FACTORS = ['7', '8', '9', '10', '11', '12'];

export const PYMESH_SUPPORTED_DEVICE_TYPES = ['fipy', 'lopy4', 'l01', 'l04'];

export const BR_ENABLED_VALUES = {
    ENABLED: 'True',
    DISABLED: 'False',
};

export const PYMESH_AUTHORIZATION_STATUSES = {
    AUTHORIZED: 'Authorized',
    FAILED: 'Failed',
};

export const PYMESH_BANDWIDTHS = [
    { label: '125', value: '0' },
    { label: '250', value: '1' },
    { label: '500', value: '2' },
];

export const PYMESH_NODE_ROLES = [
    { label: 'Disabled', role: 0, show: true },
    { label: 'Detached', role: 1, show: true },
    { label: 'Child', role: 2, show: true },
    { label: 'Router', role: 3, show: true },
    { label: 'Leader', role: 4, show: true },
    { label: 'Single Leader', role: 5, show: true },
];

export const PYMESH_REGIONS = [
    {
       region: { name: 'EU868', value: '5' },
       frequencies_interval: [
          { min: 868, max: 868.6 },
          { min: 868.7, max: 869.2 },
          { min: 869.4, max: 869.65 },
          { min: 869.7, max: 870 },
       ],
    },
    {
       region: { name: 'US915', value: '8' },
       frequencies_interval: [
          { min: 903.9, max: 904.6 },
          { min: 923.3, max: 927.5 },
       ],
    },
    {
       region: { name: 'AU915', value: '1' },
       frequencies_interval: [
          { min: 916.8, max: 917.5 },
          { min: 923.3, max: 927.5 },
       ],
    },
    {
       region: { name: 'AS923', value: '0' },
       frequencies_interval: [
          { min: 921.8, max: 923.2 },
          { min: 923.2, max: 924.8 },
       ],
    },
    {
       region: { name: 'IN865', value: '7' },
       frequencies_interval: [
          { min: 865, max: 866.55 },
       ],
    },
];
