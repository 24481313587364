import { useQuery } from '@apollo/client';

import { ROUTES, GET_ALL_DEVICES_QUERY, GET_APPLICATIONS } from 'Constants';
import { Loader } from 'Components';

import { getFontSize } from './helpers';
import * as Styled from './styled';

export const Usage = () => {
    const { data: appData, loading: loadingApplication } = useQuery(
        GET_APPLICATIONS,
        { fetchPolicy: 'cache-and-network' },
    );
    const { data: devicesData, loading: loadingDevices } = useQuery(
        GET_ALL_DEVICES_QUERY,
        { fetchPolicy: 'cache-and-network' },
    );

    const projectsCount = appData?.getApplications?.length;
    const devicesCount = devicesData?.device?.length;

    const interactiveRow = [
        {
            title: 'Devices',
            link: ROUTES.devices.main,
            count: devicesCount,
            subject: 'devices',
            cyData: 'account-devices-number',
        },
        {
            title: 'Projects',
            link: ROUTES.projects.main,
            count: projectsCount,
            subject: 'projects',
            cyData: 'account-projects-number',
        },
    ];

    if (loadingDevices && loadingApplication) {
        return <Loader />;
    }

    return (
        <Styled.Wrapper>
            <Styled.List>
                {interactiveRow.map((block) => {
                    const fontSize = getFontSize(String(block.count).length);

                    return (
                        <li key={block.subject}>
                            <h3>{block.title}</h3>
                            <Styled.InfoScreen>
                                <Styled.Count
                                    $important={block.important}
                                    $fontSize={fontSize}
                                    data-cy={block.cyData}
                                >
                                    {block.count}
                                </Styled.Count>
                                <Styled.Subject>
                                    {block.subject}
                                </Styled.Subject>
                            </Styled.InfoScreen>
                            <Styled.Link to={block.link}>
                                view all
                            </Styled.Link>
                        </li>
                    );
                })}
            </Styled.List>
        </Styled.Wrapper>
    );
};
