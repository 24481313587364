import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { GET_APPLICATION_LAST_MESSAGES } from 'Constants';
import { getStringifiedPayload } from 'Utils';
import { useMessageSubscription } from 'Hooks';

import { HeartRateWidget } from '../../../../widgets';

export const HeartRate = ({ chartId, title }) => {
    const { data, loading, refetch } = useQuery(
        GET_APPLICATION_LAST_MESSAGES,
        {
            variables: { chartId },
        },
    );
    const lastMessages = data?.getApplicationLastMessages ?? [];

    const subscribeData = useMemo(() => {
        const deviceTokens = lastMessages.map((messageObj) => messageObj.device.token);
        const messagePin = lastMessages[0]?.lastMessage?.messagePin;

        return { deviceTokens, messagePin };
    }, [lastMessages]);

    useMessageSubscription(subscribeData.deviceTokens, subscribeData.messagePin, refetch);

    const heartRate = useMemo(() => {
        if (lastMessages?.length) {
            return getStringifiedPayload(lastMessages[0].lastMessage.payload);
        }
    }, [lastMessages]);

    return (
        <HeartRateWidget
            data={heartRate}
            isEmpty={loading || !lastMessages.length}
            title={title}
        />
    );
};

HeartRate.propTypes = {
    chartId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};
