import styled, { css } from 'styled-components';

import { pymakrCompilationStatuses } from 'Constants';

export const Wrapper = styled.div`
    height: 100%;
    background-color: ${({ theme }) => theme.colors.activeText};
    padding: 2px 5px;
    overflow: auto;
`;

export const NewLine = styled.div`
    white-space: pre-line;
`;

export const Prefix = styled.span`
    margin-right: 5px;
    max-width: 25px;
`;

export const Input = styled.input`
    color: black;
    outline: none;
    width: calc(100% - 30px);
`;

export const History = styled.ul`
    margin: 0;
    padding: 0;
`;

export const Logs = styled.div`
    width: 100%;
    position: relative;
`;

export const pendingStatus = css`
    color: ${({ theme }) => theme.colors.text};
`;
export const runningStatus = css`
    color: ${({ theme }) => theme.colors.info};
`;
export const succeededStatus = css`
    color: ${({ theme }) => theme.colors.success};
`;
export const failedStatus = css`
    color: ${({ theme }) => theme.colors.error};
`;
export const unknownStatus = css`
    color: ${({ theme }) => theme.colors.text};
`;

export const CompilationStatus = styled.div`
    display: flex;    
    position: absolute;
    top: 0;
    right: 0;

    p {
        margin-left: 10px;
        ${({ $status }) => {
            switch ($status) {
                case pymakrCompilationStatuses.pending:
                    return pendingStatus;
                case pymakrCompilationStatuses.running:
                    return runningStatus;
                case pymakrCompilationStatuses.succeeded:
                    return succeededStatus;
                case pymakrCompilationStatuses.failed:
                    return failedStatus;
                case pymakrCompilationStatuses.unknown:
                    return unknownStatus;
                default:
                    return '';
            }
        }}
    }
`;
