import { ALL } from './constants';

export const DEVICE_TEMPLATE_TYPES = {
    DEVICES: 'devices',
    SHIELDS: 'shields',
};

export const BASE_DEVICE_WIZARD_FILTERS = [
    {
        name: ALL,
        label: 'All',
    },
    {
        name: DEVICE_TEMPLATE_TYPES.SHIELDS,
        label: 'Shields',
    },
];

export const CERTIFICATE_STATUS = {
    NOT_SECURED: 'NotSecured',
    SECURED: 'Valid',
    EXPIRED: 'Expired',
    REVOKED: 'Revoked',
};
