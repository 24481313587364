import styled from 'styled-components';

import { Button } from '../../buttons';

export const ModalWrapper = styled.div`
    width: 700px;
    background-color: #fff;
`;

export const ModalHeader = styled.div`
    width: 100%;
    height: 50px;
    padding: 15px 20px;
    background-color: ${({ theme }) => theme.colors.title};

    color: #fff;
    font-size: 16px;
    font-weight: bold;
`;

export const ModalBody = styled.div`
    width: 600px;
    margin: 25px auto;
`;

export const ModalDescription = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 15px 30px 20px;

    & > span {
        margin-left: 4px;
        color: ${({ theme }) => theme.colors.text};
        font-size: 16px;
    }
`;

export const ContentWrapper = styled.div`
    padding: 15px 24px 40px;
`;

export const RowWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
`;

export const ModalKey = styled.span`
    font-weight: bolder;
`;

export const ModalValue = styled.span`
    background-color: rgba(216, 216, 216, 0.815);
    width: 70%;
    text-align: center;
    margin-right: 6em;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
`;

export const InfoBlock = styled.div`
    display: flex;
    justify-content: space-around;
    margin: 40px 0;

    svg {
        font-size: 20px;
        color: ${({ theme }) => theme.colors.text};
    }
`;

export const InfoMessage = styled.div`
    overflow-wrap: normal;
    max-width: 80%;
`;

export const DownloadButton = styled(Button)`
    width: 70%;
    height: 3em;
    margin: 0 auto;
`;
