import PropTypes from 'prop-types';

import * as Styled from './styled';

export const IconButton = ({
    onClick,
    children,
    Icon,
    className,
    buttonType,
    bordered,
    isTextBold,
    cyData,
    disabled,
}) => (
    <Styled.Button
        className={className}
        $buttonType={buttonType}
        $bordered={bordered}
        $textBold={isTextBold}
        onClick={onClick}
        icon={<Icon />}
        data-cy={cyData}
        disabled={disabled}
    >
        {children}
    </Styled.Button>
);

IconButton.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func.isRequired,
    Icon: PropTypes.oneOfType([PropTypes.element, PropTypes.object]).isRequired,
    className: PropTypes.string,
    buttonType: PropTypes.oneOf(['transparent', 'filled']),
    bordered: PropTypes.bool,
    isTextBold: PropTypes.bool,
    cyData: PropTypes.string,
    disabled: PropTypes.bool,
};

IconButton.defaultProps = {
    children: null,
    className: '',
    buttonType: 'transparent',
    bordered: false,
    isTextBold: false,
    cyData: '',
    disabled: false,
};
