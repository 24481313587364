import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useRouteMatch, Switch } from 'react-router-dom';

import { PageHeader, Menu, RouteWrapper } from 'Components';
import { ROUTES } from 'Constants';
import { Usage } from './usage';
import { Security } from './security';
import { Certificates } from './certificates';

const getLinks = (basePath) => [
    { title: 'Usage', route: `${basePath}${ROUTES.accountSection.usage}` },
    { title: 'Security', route: `${basePath}${ROUTES.accountSection.security}` },
    { title: 'Certificates', route: `${basePath}${ROUTES.accountSection.certificates}` },
];

export const Account = () => {
    const styledTheme = useContext(ThemeContext);

    const description = `Plug in your ${styledTheme.whiteLabelCompany} device and start writing your application
        code instantly Questions? Head to Getting Started!`;
    const { path, url } = useRouteMatch();

    return (
        <>
            <PageHeader title="Account" description={[description]} />
            <Menu links={getLinks(url)}>
                <Switch>
                    <RouteWrapper
                        path={`${path}${ROUTES.accountSection.usage}`}
                        render={(props) => <Usage {...props} />}
                    />
                    <RouteWrapper
                        path={`${path}${ROUTES.accountSection.security}`}
                        render={(props) => <Security {...props} />}
                    />
                    <RouteWrapper
                        path={`${path}${ROUTES.accountSection.certificates}`}
                        render={(props) => <Certificates {...props} />}
                    />
                </Switch>
            </Menu>
        </>
    );
};
