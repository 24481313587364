import { useMemo, useContext } from 'react';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { ThemeContext } from 'styled-components';

import { getColors } from './config';
import { EmptyWidget } from '../emptyWidget';

import * as StyledGeneral from '../styled';
import * as Styled from './styled';

export const DoughnutChart = ({ data, title, isEmpty }) => {
    const styledTheme = useContext(ThemeContext);

    const renderDetails = useMemo(() => {
        const colors = getColors(
            styledTheme.colors.active,
            styledTheme.colors.activeText,
            styledTheme.colors.title,
        );
        const labels = [];
        data.forEach((item, index) => {
            labels.push(
                <Styled.LabelMarker
                    key={item.name}
                    $color={colors[index].background}
                />,
            );
            labels.push(
                <Styled.LabelText key={`${item.count} ${item.name}`}>
                    <Tooltip 
                        placement="right" 
                        title={`${item.name}: ${item.count}`}
                        getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    >
                        {`${item.name}`}
                    </Tooltip>
                </Styled.LabelText>,
            );
        });

        return {
            colors,
            labels,
        };
    }, [data]);

    if (!data.length || isEmpty) {
        return <EmptyWidget title={title} />;
    }

    return (
        <Styled.DoughnutContainer>
            <Styled.Info>
                <StyledGeneral.ChartTitle>
                    {title}
                </StyledGeneral.ChartTitle>
                <Styled.LabelsWrapper>
                    <Styled.Labels>
                        {renderDetails.labels.map((coloredLabel) => coloredLabel)}
                    </Styled.Labels>
                </Styled.LabelsWrapper>
            </Styled.Info>
            <Styled.Doughnut>
                <Doughnut
                    plugins={[ChartDataLabels]}
                    data={{
                        labels: data.map((activity) => activity.name),
                        datasets: [{
                            backgroundColor: renderDetails.colors.map((color) => color.background),
                            data: data.map((activity) => activity.count),
                        }],
                    }}
                    options={{
                        cutoutPercentage: 40,
                        legend: {
                            display: false,
                        },
                        plugins: {
                            datalabels: {
                                color: renderDetails.colors.map((color) => color.text),
                                font: {
                                    weight: 'bold',
                                },
                            },
                        },
                        maintainAspectRatio: false,
                        aspectRatio: 1,
                    }}
                />
            </Styled.Doughnut>
        </Styled.DoughnutContainer>
    );
};

DoughnutChart.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        count: PropTypes.number,
    })).isRequired,
    title: PropTypes.string.isRequired,
    isEmpty: PropTypes.bool,
};

DoughnutChart.defaultProps = {
    isEmpty: false,
};
