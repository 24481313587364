import styled from 'styled-components';

const alertColorNames = {
    border: {
        success: 'success',
        info: 'info',
        warning: 'warning',
        error: 'error',
    },
    font: {
        success: 'title',
        info: 'title',
        warning: 'title',
        error: 'error',
    },
};

export const Wrapper = styled.div`
    border-radius: 6px;
    padding: 10px 15px;
    margin-bottom: 15px;
    border: 2px solid ${({ theme, $type }) => theme.colors[alertColorNames.border[$type]]};

    h3 {
        color: ${({ theme, $type }) => theme.colors[alertColorNames.font[$type]]};
    }
`;
