import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useQuery, useMutation } from '@apollo/client';

import { 
    GET_ALL_FILTERED_DEVICES_QUERY, 
    UPDATE_DEVICE_NAME_MUTATION, 
    GET_DEVICE_BY_TOKEN_QUERY,
    GET_USER_PROFILE_QUERY,
} from 'Constants';
import { showToastSuccess } from 'Utils';
import { Loader } from 'Components';

import { initialValues, getValidationSchema } from './config';
import { FormContent } from './formContent';

export const DeviceSettingsForm = ({ device }) => {
    const { data: allDevicesData, loading } = useQuery(GET_ALL_FILTERED_DEVICES_QUERY, { variables: { filter: {} } });
    const { data } = useQuery(GET_USER_PROFILE_QUERY);
    const user = data?.getUserProfile;
    const devices = useMemo(() => (allDevicesData?.getAllDevices ?? []), [allDevicesData]);

    const [updateDevice, { loading: isLoading }] = useMutation(
        UPDATE_DEVICE_NAME_MUTATION,
        { refetchQueries: [GET_ALL_FILTERED_DEVICES_QUERY, GET_DEVICE_BY_TOKEN_QUERY] },
    );

    if (loading) {
        return <Loader />;
    }

    const checkIsNameNotDuplicated = (name) => !devices.some(
        (item) => (item.description === name && item._id !== device._id),
    );

    const onSubmit = (values) => {
        updateDevice({
            variables: { 
                deviceId: device._id,
                owner: user.owner,
                name: values.name, 
            },
            update: () => {
                showToastSuccess('Device settings updated successfully');
            },
        });
    };

    const combinedInitialValues = {
        name: device.description || initialValues.name,
        mac: device.mac || initialValues.mac,
    };

    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={combinedInitialValues}
            validationSchema={getValidationSchema(checkIsNameNotDuplicated)}
            enableReinitialize
        >
            {(props) => (
                <FormContent
                    {...props}
                    isLoading={isLoading}
                />
            )}
        </Formik>
    );
};

DeviceSettingsForm.propTypes = {
    device: PropTypes.object.isRequired,
};
