import styled from 'styled-components';

export const Content = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const SelectSection = styled.div`
    display: flex;
    flex-direction: column;
    width: 40%;
`;

export const SelectSectionRow = styled.div`
    display: flex;
    padding: 10px 5px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
`;

export const SelectionRowTitle = styled.div`
    display: flex;
    align-items: center;
    min-width: 35%;
`;

export const Title = styled.span`
    margin-right: 5px;
    font-weight: bold;
`;

export const ToolTipIcon = styled.span`
    margin-right: 20px;

    svg {
        margin-top: 2px;
        width: 30px;
        height: 30px;
    }
`;

export const FieldWrapper = styled.div`
    position: relative;

`;

export const Extension = styled.span`
    font-weight: bold;
    position: absolute;
    top: 10px;
    right: -40px;
`;

export const JoinKeyBox = styled.div`
    box-shadow: 0 2px 5px 0 rgba(50, 50, 50, 0.2);
    width: 50%;
    height: 100%;
    border: solid 1px ${({ theme }) => theme.colors.border};
    background-color: ${({ theme }) => theme.colors.boxBackground};
    padding: 20px 25px;
    z-index: 10;
    margin-top: -50px;

    button {
        margin-top: 20px;
        text-transform: uppercase;
    }
`;

export const JoinKeyTitle = styled.h3`
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
`;

export const JoinKeyFieldWrapper = styled.div`
    input {
        width: 100%;
        height: 45px;
        border-radius: 5px;
        border: 1px solid ${({ theme }) => theme.colors.border};
        padding: 9px 35px 9px 15px;
        color: ${({ theme }) => theme.colors.text};
        font-size: 16px;
        letter-spacing: 1.2px;
    }
`;
