import { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { Breadcrumbs, PageHeader, GoogleAuth, GoogleIntegrationDefinition } from 'Components';
import { showToastSuccess, showToastError } from 'Utils';
import {
    BREADCRUMBS_LABELS,
    GET_ALL_DEVICES_QUERY,
    SAVE_GOOGLE_DEVS_MUTATION,
    ROUTES,
} from 'Constants';

import { ProjectSetup, RegistrySetup } from './components';

import * as Styled from './styled';

const STEPS = {
    PROJECT_SETUP: 1,
    REGISTRY_SETUP: 2,
    INTEGRATION_DEFINITION: 3,
};

export const GoogleIntegration = () => {
    const history = useHistory();

    const [step, setStep] = useState(STEPS.PROJECT_SETUP);
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [accountData, setAccountData] = useState({});
    const [googleService, setGoogleService] = useState({});
    const [registries, setRegistries] = useState([]);
    const [formValues, setFormValues] = useState({});

    const { data: allDevicesData } = useQuery(
        GET_ALL_DEVICES_QUERY,
        { fetchPolicy: 'cache-and-network' },
    );
    const allDevices = allDevicesData?.device ?? [];

    const [saveGoogleIntegration, { loading: isLoading }] = useMutation(SAVE_GOOGLE_DEVS_MUTATION);

    let description = '';

    if (!isSignedIn) {
        description = 'Please authenticate with a Google account allowed to use Google Cloud IoT services';
    }

    const prevStep = () => {
        setStep(step - 1);
    };

    const nextStep = (values) => {
        setFormValues({ ...formValues, ...values });
        setStep(step + 1);
    };

    const onIntegrationSubmit = async (values) => {
        const { region, projectId, registryId, topic } = formValues;
        const deviceAttributes = [];

        values.devices.forEach((item, index) => {
            if (item && allDevices[index]) {
                deviceAttributes.push({
                    region,
                    projectId,
                    registryId,
                    topic,
                    metadata: {
                        deviceName: allDevices[index].description,
                    },
                    deviceToken: allDevices[index].token,
                });
            }
        });

        try {
            const result = await saveGoogleIntegration({
                variables: { devices: deviceAttributes, accessToken: accountData.accessToken },
            });
            const { integrationRefId } = result.data.saveGoogleDevices[0];

            showToastSuccess('Integration created successfully');

            history.push(`${ROUTES.settings.integrations.main}/${integrationRefId}`);
        } catch (error) {
            showToastError(error.message);
        }
    };

    let content = null;

    switch (step) {
        case STEPS.PROJECT_SETUP:
            content = (
                <ProjectSetup
                    nextStep={nextStep}
                    setGoogleService={setGoogleService}
                    accountData={accountData}
                    setRegistries={setRegistries}
                    formValues={formValues}
                />
            );
            break;
        case STEPS.REGISTRY_SETUP:
            content = (
                <RegistrySetup
                    nextStep={nextStep}
                    prevStep={prevStep}
                    googleService={googleService}
                    registries={registries}
                    setRegistries={setRegistries}
                    formValues={formValues}
                />
            );
            break;
        case STEPS.INTEGRATION_DEFINITION:
            content = (
                <GoogleIntegrationDefinition
                    onSubmit={onIntegrationSubmit}
                    allDevices={allDevices}
                    formValues={formValues}
                    prevStep={prevStep}
                    registries={registries}
                    isLoading={isLoading}
                />
            );
            break;
        default:
            content = null;
    }

    return (
        <>
            <Breadcrumbs labels={BREADCRUMBS_LABELS.googleIntegration} />
            <PageHeader
                title="New Google Cloud integration"
                description={[description]}
            />
            <Styled.ContentWrapper>
                <GoogleAuth
                    isSignedIn={isSignedIn}
                    setIsSignedIn={setIsSignedIn}
                    accountData={accountData}
                    setAccountData={setAccountData}
                />
                {isSignedIn && content}
            </Styled.ContentWrapper>
        </>
    );
};
