import styled from 'styled-components';
import { box } from 'Styles';

export const Box = styled.div`
    ${box}
`;
export const BoxList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;
export const Title = styled.span`
    display: block;
    font-size: 1.5rem;
    font-weight: 600;
`;
export const Description = styled.span`
    display: block;
    font-size: 0.875rem;
    font-weight: 400;
    margin-bottom: 1.5rem;
`;
export const Content = styled.div`
`;
