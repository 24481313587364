import { useState, useRef, useEffect } from 'react';
import { useQuery, useApolloClient } from '@apollo/client';
import mqtt from 'mqtt';

import {
    GET_USER_PROFILE_QUERY,
    PINS_BY_DEVICE_QUERY,
    ADD_ML_SAMPLE_MUTATION,
    COMMAND_CREATE_SAMPLE,
    TYPE_PYBYTES,
    TYPE_MASK,
} from 'Constants';
import { showToastError } from 'Utils';

const aedesWebSockerUrl = process.env.REACT_APP_MQTT_SERVER_AEDES_WEB_SOCKET_URL;

export const useSampling = ({ devices, model, samplingType, refetchMlSamples }) => {
    const apolloClient = useApolloClient();

    const samplingTimeoutRef = useRef(null);
    const refetchSamplesTimeoutRef = useRef(null);
    const mqttClientRef = useRef(null);

    const [isSampling, setIsSampling] = useState(false);
    const [isSamplingTimeout, setIsSamplingTimeout] = useState(false);

    const { data: userData } = useQuery(GET_USER_PROFILE_QUERY);
    const user = userData?.getUserProfile;

    const clearSamplingTimeout = () => {
        if (samplingTimeoutRef.current) {
            clearTimeout(samplingTimeoutRef.current);
        }
    };

    const clearRefetchSamplesTimeoutRef = () => {
        if (refetchSamplesTimeoutRef.current) {
            clearTimeout(refetchSamplesTimeoutRef.current);
        }
    };

    const stopSampling = () => {
        if (mqttClientRef.current) {
            mqttClientRef.current.end();
        }

        clearRefetchSamplesTimeoutRef();
        clearSamplingTimeout();

        setIsSampling(false);
    };

    useEffect(() => () => {
        stopSampling();
    }, []);

    const getAvailablePin = async (deviceToken, label) => {
        const { data } = await apolloClient.query({
            query: PINS_BY_DEVICE_QUERY,
            variables: { deviceToken },
            fetchPolicy: 'network-only',
        });
        const pinsList = data.getPinsByDevice;
  
        const pin = pinsList.find((item) => item.name === label);

        if (pin) {
           return +pin.pin;
        }
  
        for (let i = 1; i <= 255; i++) {
            if (pinsList.some((item) => item.pin === i)) {
                return i;
            }
        }
        return null;
    };

    const saveMLSample = (sample) => apolloClient.mutate({
        mutation: ADD_ML_SAMPLE_MUTATION,
        variables: { sample },
    });

    const startSampling = async (values) => {
        const {
            deviceToken,
            label,
            sensor,
            sampleName,
            length,
            frequency,
        } = values;

        setIsSampling(true);

        clearSamplingTimeout();

        const pin = await getAvailablePin(deviceToken, label);

        const selectedDevice = devices.find((device) => device.token === deviceToken);

        if (!pin || !selectedDevice) {
            stopSampling();

            showToastError('There is no available pin or device');

            return;
        }

        const sample = {
            sensor,
            label: label || 'label',
            sampleName: sampleName || `sample-${Math.round(Math.random() * 100)}`,
            length: +length,
            frequency: +frequency,
            device: selectedDevice._id,
            model: model._id,
            type: samplingType,
        };

        const { data: { addMLSample } } = await saveMLSample(sample);
        const parameters = JSON.stringify({
            ...sample,
            pin,
            mlSample: addMLSample._id,
        });

        const body = new Uint8Array(2 + parameters.length);
        body[0] = COMMAND_CREATE_SAMPLE;
        body[1] = '123'; // check available pin

        for (let i = 0; i < parameters.length; i += 1) {
            body[i + 2] = parameters.charCodeAt(i);
        }

        const messagePayload = new Uint8Array(1 + body.length);

        const USER_SYSTEM_MASK = 0x80; // 1000 0000
        let header = USER_SYSTEM_MASK;

        // eslint-disable-next-line
        header |= (TYPE_PYBYTES & TYPE_MASK);
        messagePayload[0] = header;

        for (let i = 0; i < body.length; i++) {
            messagePayload[i + 1] = body[i];
        }

        const mqttClientOptions = {
            clean: true,
            username: user.owner,
            password: deviceToken,
        };

        mqttClientRef.current = mqtt.connect(aedesWebSockerUrl, mqttClientOptions);

        const mqttClient = mqttClientRef.current;

        mqttClient.on('connect', () => {
            mqttClient.unsubscribe(`u${deviceToken}/sample`);
            mqttClient.subscribe(`u${deviceToken}/sample`);
            mqttClient.publish(`d${deviceToken}`, Buffer.from(messagePayload), { qos: 0 }, (err) => {
                if (err) {
                    console.log(`Error: ${err.message}`);
                }
            });
         });
   
        mqttClient.on('message', (topic, recvMessage) => {
            let timeout = length * (frequency / 10);

            if (timeout > 10000) {
               timeout = 10000;
            }

            if (recvMessage[1] === 2) {
                clearRefetchSamplesTimeoutRef();

                refetchSamplesTimeoutRef.current = setTimeout(() => {
                    refetchMlSamples();
                    stopSampling();
                }, timeout);
            }
        });

        clearSamplingTimeout();

        const timeout = frequency * length;
        samplingTimeoutRef.current = setTimeout(
            () => {
                setIsSamplingTimeout(true);
                stopSampling();
            },
            Math.max(timeout, 15000),
        );
    };

    return {
        isSampling,
        isSamplingTimeout,
        startSampling,
    };
};
