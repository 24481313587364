import { useMemo } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';

import { ReactComponent as Support } from 'Assets/network/support.svg';
import { SAVE_WIFI_MUTATION, GET_WIFIS } from 'Constants';

import { showToastError, showToastSuccess } from 'Utils';
import {
    validationSchema,
} from './config';
import { FormContent } from './formContent';
import { getInitialValues } from './helpers';

import * as Styled from './styled';

export const WiFiModal = ({ handleClose, handleSuccess, id }) => {
    const [saveWifi, { loading: isLoading }] = useMutation(SAVE_WIFI_MUTATION, {
        refetchQueries: [GET_WIFIS],
    });
    const { data: arrayOfWifi } = useQuery(GET_WIFIS);
    const wifis = useMemo(
        () => arrayOfWifi?.getSettings?.wifiSaved ?? [],
        [arrayOfWifi],
    );

    const updateWiFiSettings = async ({ ssid, password, secureType }) => {
        try {
            saveWifi({
                variables: {
                    wifi: {
                        ssid,
                        password,
                        secureType,
                        _id: id,
                    },
                },
                update: () => {
                    showToastSuccess('Wifi settings updated successfully');
                    const finalFunc = handleSuccess || handleClose;
                    finalFunc();
                },
            });
        } catch (error) {
            handleClose();
            showToastError(error.message);
        }
    };

    const createWifiSettings = async ({ ssid, password, secureType }) => {
        try {
            saveWifi({
                variables: {
                    wifi: {
                        ssid,
                        password,
                        secureType,
                        _id: null,
                    },
                },
                update: () => {
                    showToastSuccess('Wifi settings added successfully');
                    const finalFunc = handleSuccess || handleClose;
                    finalFunc();
                },
            });
        } catch (error) {
            handleClose();
            showToastError(error.message);
        }
    };

    const submitClickHandler = id ? updateWiFiSettings : createWifiSettings;

    return (
        <Styled.Modal>
            <Styled.Header>
                Add a new Wi-Fi
            </Styled.Header>
            <Styled.Description>
                <Support />
                <span>
                    A new Wi-Fi Credential will be automatically saved in the
                    General Network Settings.
                </span>
            </Styled.Description>
            <Formik
                enableReinitialize
                initialValues={getInitialValues(wifis, id)}
                validationSchema={validationSchema}
                onSubmit={submitClickHandler}
            >
                {(props) => (
                    <FormContent
                        {...props}
                        handleClose={handleClose}
                        isLoading={isLoading}
                    />
                )}
            </Formik>
        </Styled.Modal>
    );
};

WiFiModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSuccess: PropTypes.func,
    id: PropTypes.string,
};

WiFiModal.defaultProps = {
    handleSuccess: null,
    id: '',
};
