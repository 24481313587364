import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as File } from 'Assets/icons/docs.svg';
import { compareMD5Hash, getDeviceModeInfo } from 'Utils';

import * as Styled from './styled';

export const ExplorerFile = ({
    file,
    input,
    onClick,
    onContextMenu,
    activeKey,
    projectKey,
    updatedFiles,
    setUpdatedFile,
}) => {
    const [isUpdated, setIsUpdated] = useState(false);
    const { value, onChange, inputOnBlur, onKeyDown } = input;

    const deviceModeInfo = getDeviceModeInfo(projectKey);
    if (deviceModeInfo && compareMD5Hash(projectKey, file.path) === false && !isUpdated) {
        setIsUpdated(true);
        setUpdatedFile(updatedFiles, file.path);
    }

    useEffect(() => {
        if (updatedFiles.includes(file.path) && !isUpdated) {
            setIsUpdated(true);
        } else if (!updatedFiles.includes(file.path) && isUpdated) {
            setIsUpdated(false);
        }
    }, [updatedFiles]);

    const displayFileName = file.name
        ? <Styled.FileName $updated={isUpdated}>{file.name}</Styled.FileName>
        : (
            <Styled.FileInput
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={true}
                type="text"
                value={value}
                onChange={onChange}
                onBlur={() => inputOnBlur('file')}
                onKeyDown={(e) => onKeyDown(e, 'file')}
            />
        );

    return (
        <Styled.FileWrapper>
            <Styled.FileItem 
                role="button"
                $active={file.key === activeKey}
                tabIndex={0}
                onKeyDown={() => {}}
                onClick={file.name ? (e) => onClick(e, file) : null}
                onContextMenu={file.name ? (e) => onContextMenu(e, file) : null}
            >
                <Styled.FileIcon>
                    <File />
                </Styled.FileIcon>
                {displayFileName}
            </Styled.FileItem>
        </Styled.FileWrapper>
    );
};

ExplorerFile.defaultProps = {
    activeKey: null,
    updatedFiles: [],
    setUpdatedFile: null,
};

ExplorerFile.propTypes = {
    file: PropTypes.shape({
        type: PropTypes.string,
        name: PropTypes.string,
        isInitial: PropTypes.bool,
        path: PropTypes.string,
        parent: PropTypes.string,
        key: PropTypes.string,
    }).isRequired,
    input: PropTypes.shape({
        value: PropTypes.string,
        onChange: PropTypes.func,
        inputOnBlur: PropTypes.func,
        onKeyDown: PropTypes.func,
    }).isRequired,
    onClick: PropTypes.func.isRequired,
    onContextMenu: PropTypes.func.isRequired,
    activeKey: PropTypes.string,
    projectKey: PropTypes.string.isRequired,
    updatedFiles: PropTypes.array,
    setUpdatedFile: PropTypes.func,
};
