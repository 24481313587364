import PropTypes from 'prop-types';

import { ReactComponent as HeartRateIcon } from 'Assets/widgets/heartRate.svg';

import { EmptyWidget } from '../emptyWidget';

import * as StyledGeneral from '../styled';
import * as Styled from './styled';

export const HeartRateWidget = ({ data, isEmpty, title }) => {
    const isDataBroken = Number.isNaN(+data);

    if (isEmpty || isDataBroken) {
        return <EmptyWidget title={title} isDataBroken={isDataBroken} />;
    }

    return (
        <>
            <StyledGeneral.ChartTitle>
                {title}
            </StyledGeneral.ChartTitle>
            <Styled.HeartRateContainer>
                <Styled.Line />
                <Styled.Cardiogram>
                    <Styled.HeartRate>
                        <span>{data}</span> bmp
                    </Styled.HeartRate>
                    <HeartRateIcon />
                </Styled.Cardiogram>
            </Styled.HeartRateContainer>
        </>
    );
};

HeartRateWidget.propTypes = {
    data: PropTypes.number,
    title: PropTypes.string.isRequired,
    isEmpty: PropTypes.bool,
};

HeartRateWidget.defaultProps = {
    isEmpty: false,
    data: null,
};
