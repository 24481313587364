import styled from 'styled-components';

export const ButtonsWrapper = styled.div`
    display: flex;
    margin-top: 55px;

    
    & > * {
        margin-right: 30px;
    }
`;
