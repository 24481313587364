import PropTypes from 'prop-types';

import { useAuthenticate } from 'Hooks';

import { Header } from '../components';

import * as Styled from './styled';

export const PymakrLayout = ({ children }) => {
    const { loader, isLoading } = useAuthenticate();

    if (isLoading) {
        return loader;
    }

    return (
        <Styled.Page>
            <Header showLogo={true} />
            <Styled.Content>
                <Styled.Container>
                    {children}
                </Styled.Container>
            </Styled.Content>
        </Styled.Page>
    );
};

PymakrLayout.propTypes = {
    children: PropTypes.node.isRequired,
};
