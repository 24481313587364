import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { PREVIEW_PROJECT_WIDGET_CONFIG } from 'Constants';

import { Modal } from '../../../modals';
import { ProjectWidgetDescription } from '../projectWidgetDescription';

import * as Styled from './styled';

export const ProjectWidgetEdit = ({ isOpen, onClose, onSubmit, widgetType, initialValues }) => {
    const selectedWidgetPreview = useMemo(
        () => PREVIEW_PROJECT_WIDGET_CONFIG.find((item) => item.type === widgetType),
        [widgetType],
    );

    return (
        <Modal isOpened={isOpen} handleClose={onClose}>
            <Styled.Modal>
                <Styled.Title>
                    {`Set up ${selectedWidgetPreview?.name} widget`}
                </Styled.Title>
                <Styled.Wrapper>
                    <ProjectWidgetDescription
                        nextStep={onSubmit}
                        prevStep={onClose}
                        widgetType={widgetType}
                        submitText="Save"
                        widgetInfo={initialValues}
                    />
                </Styled.Wrapper>
            </Styled.Modal>
        </Modal>
    );
};

ProjectWidgetEdit.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    widgetType: PropTypes.string.isRequired,
    initialValues: PropTypes.object,
};

ProjectWidgetEdit.defaultProps = {
    initialValues: {},
};
