import styled from 'styled-components';

export const Title = styled.div`
    display: flex;
    align-items: center;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin: 58px 0 37px 0;
    
    & > span {
        display: block;
        margin-bottom: 20px;
        color: ${({ theme }) => theme.colors.title};
        font-size: 20px;
        font-weight: bold;
        line-height: 25px;
    }
`;

export const FiltersWrapper = styled.div`
    display: flex;
`;

export const Filter = styled.div`
    margin-right: 10px;
`;

export const Buttons = styled.div`
    display: flex;
    padding-bottom: 20px;
    
    & > div {
        margin-right: 10px;
    }
`;
