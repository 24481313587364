import styled from 'styled-components';

export const SelectWrapper = styled.div`
    margin-bottom: 20px;
`;

export const ChartWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;

    .recharts-brush-slide {
        color: ${({ theme }) => theme.colors.active};
        fill-opacity: 1;
    }

    .recharts-brush{
        color: #E0E0E0;
    }
`;
