import styled, { keyframes } from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const animation = (theme) => keyframes`
    0% { 
        background-color: ${theme.colors.active};
    }
    50%, 100% { 
        background-color: #fff;
    }
`;

export const Dots = styled.div`
    position: relative;
    margin: 0 20px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.active};
    color: ${({ theme }) => theme.colors.active};

    &::before,
    &::after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
    }

    &::before {
        left: -15px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: ${({ theme }) => theme.colors.active};
        color: ${({ theme }) => theme.colors.active};
    }

    &::after {
        left: 15px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: ${({ theme }) => theme.colors.active};
        color: ${({ theme }) => theme.colors.active};
    }
`;

export const AnimationDots = styled(Dots)`
    animation: ${({ theme }) => animation(theme)} 1s infinite alternate;
    animation-delay: 0.5s;

    &::before {
        animation: ${({ theme }) => animation(theme)} 1s infinite alternate;
        animation-delay: 0s;
    }
    &::after {
        animation: ${({ theme }) => animation(theme)} 1s infinite alternate;
        animation-delay: 1s;
    }
`;

export const DivImage = styled.img`
    height: 60px;
    object-fit: cover;
    width: fix-content;
`;
