import { useState } from 'react';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/client';

import {
    pymakrExportStates,
    GET_COMPILED_IMAGE_BASE64,
} from 'Constants';

import { useContextSelector } from '../../../pymakrHooks';
import { ExportUploadDevice } from './exports';
import { downloadZip } from '../helpers';

import * as Styled from './styled';

export const SaveAndExportCompilation = ({ title, onClose }) => {
    const [exportState, setExportState] = useState(pymakrExportStates.initial);
    const [loadingImage, setLoadingImage] = useState(false);
    const [loadingFile, setIsLoadingFile] = useState(false);
    const apolloClient = useApolloClient();

    const compilationSlice = useContextSelector('compilation');
    const { compilationId } = compilationSlice.state;
    const { setCompilationInitState } = compilationSlice.handlers;

    const download = async (type) => {
        try {
            const { data } = await apolloClient.mutate({
                mutation: GET_COMPILED_IMAGE_BASE64,
                variables: {
                    compilationId,
                    type,
                },
            });

            return data?.getCompiledImageBase64 ?? '';
        } catch (error) {
            console.warn(error);
        }
    };

    const removeCompilation = () => {
        setCompilationInitState();
        onClose();
    };

    const downloadImage = async () => {
        try {
            setLoadingImage(true);
            const base64 = await download('image');
            downloadZip(base64, `${title}-image`);
        } catch (error) {
            console.warn(error);
        } finally {
            setLoadingImage(false);
        }
    };

    const downloadFile = async () => {
        try {
            setIsLoadingFile(true);
            const base64 = await download('file');
            downloadZip(base64, `${title}-file`);
        } catch (error) {
            console.warn(error);
        } finally {
            setIsLoadingFile(false);
        }
    };

    switch (exportState) {
        case pymakrExportStates.initial:
            return (
                <Styled.Wrapper>
                    <h2>What do you want to do with compilation file?</h2>
                    <Styled.OptionBtn
                        buttonType="transparent"
                        onClick={downloadImage}
                        loading={loadingImage}
                    >
                       Create and Download image zip
                    </Styled.OptionBtn>
                    <Styled.OptionBtn
                        buttonType="transparent"
                        onClick={downloadFile}
                        loading={loadingFile}
                    >
                       Create and Download file zip
                    </Styled.OptionBtn>
                    <Styled.OptionBtn
                        buttonType="transparent"
                        onClick={() => (setExportState(pymakrExportStates.upload))}
                    >
                        Upload to Device
                    </Styled.OptionBtn>
                    <Styled.OptionBtn
                        buttonType="transparent"
                        onClick={removeCompilation}
                    >
                        Delete previous compilation
                    </Styled.OptionBtn>
                    <Styled.OptionBtn
                        buttonType="transparent"
                        onClick={() => onClose()}
                    >
                        Skip
                    </Styled.OptionBtn>
                </Styled.Wrapper>
            );
        case pymakrExportStates.upload:
            return (
               <ExportUploadDevice
                   onClose={onClose}
               />
            );
        default:
            return <div />;
    }
};

SaveAndExportCompilation.propTypes = {
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
};
