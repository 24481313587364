import { useState, useContext, useMemo } from 'react';
import moment from 'moment';
import { ThemeContext } from 'styled-components';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from 'recharts';
import PropTypes from 'prop-types';

import { Select } from 'Components';
import { TIME_SCALE } from 'Constants';
import { getChartTicks } from 'Utils';

import { SimCardWidgetBox } from '../simCardWidgetBox';
import { SimCardEmptyWidget } from '../simCardEmptyWidget';

import * as Styled from './styled';

export const ConsumptionChart = ({ consumptionChartInfo }) => {
    const styledTheme = useContext(ThemeContext);

    const { types, graphData, tickFormatter } = consumptionChartInfo;
    const [type, setType] = useState(types[0]);

    const filterOutdated = (data = []) => data.filter((report) => {
        const oneMonthAgoDate = moment(new Date()).subtract(1, 'M');
        const targetDate = moment(new Date(report.date));

        return targetDate.isAfter(oneMonthAgoDate);
    });

    const createGraphData = (dataProperty) => filterOutdated(graphData).map((report) => ({
        date: new Date(report.date).valueOf(),
        y: report[dataProperty],
    }));

    const graphHasData = (dataProperty) => !!filterOutdated(graphData).find((dataPoint) => dataPoint[dataProperty] > 0);

    const selectValues = useMemo(() => types.map((targetType) => targetType.dropDownTitle), [types]);

    const handleSelectType = (chosenTitle) => {
        const targetType = types.find((item) => item.dropDownTitle === chosenTitle.value);
        setType(targetType);
    };

    const renderCustomTooltip = (parameters) => {
        if (parameters.active) {
            const { payload, label } = parameters;
            const payloadData = payload[0];

            return (
                <div
                    style={{
                        padding: '13px 70px 8px 17px',
                        fontSize: '12px',
                        lineHeight: '1.5',
                        backgroundColor: 'white',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                        borderRadius: '5px',
                    }}
                >
                    <div style={{ fontWeight: 'bold' }}>Information:</div>
                    <div>
                        <span>Date: </span>
                        <span style={{ color: styledTheme.colors.active }}>
                            {moment(new Date(label)).format('DD:MM:YY h:mm:s A')}
                        </span>
                    </div>
                    <div>Value: {tickFormatter(payloadData.value)}</div>
                </div>
            );
        }

        return null;
    };

    const chartData = createGraphData(type.dataIndex);
    const tickOptions = getChartTicks(chartData, TIME_SCALE.DAY);

    return (
        <SimCardWidgetBox title="Consumption Chart">
            {!graphData.length && <SimCardEmptyWidget title="No Reports are available" />}
            {!!graphData.length && (
                <Styled.Wrapper>
                    <Styled.Header>
                        <Styled.ChartTitle>
                            {type.title}
                        </Styled.ChartTitle>
                        <Select
                            custom
                            values={selectValues}
                            handleSelect={handleSelectType}
                            selectedItem={type.dropDownTitle}
                            placeholder="Select type"
                        />
                    </Styled.Header>
                    <Styled.ContentWrapper>
                        {graphHasData(type.dataIndex) ? (
                            <ResponsiveContainer width="100%" height="100%">
                                <LineChart
                                    width={500}
                                    height={300}
                                    data={chartData}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="4 3" />
                                    <XAxis
                                        dataKey="date"
                                        type="number"
                                        domain={['dataMin', 'dataMax']}
                                        stroke="#ECF0F4"
                                        tickLine={false}
                                        tick={{ fill: '#C0C7CE', fontSize: 14 }}
                                        scale="time"
                                        tickFormatter={(v) => moment(new Date(v)).format('DD MMM')}
                                        ticks={tickOptions.ticks}
                                    />
                                    <YAxis
                                        stroke="#ECF0F4"
                                        tickLine={false}
                                        tickFormatter={tickFormatter ?? ((v) => v)}
                                        tick={{ fill: '#C0C7CE', fontSize: 14 }}
                                    />
                                    <Tooltip
                                        content={renderCustomTooltip}
                                        cursor={false}
                                    />
                                    <Line dataKey="y" stroke={styledTheme.colors.active} activeDot={{ r: 8 }} />
                                </LineChart>
                            </ResponsiveContainer>
                        ) : (
                            <Styled.EmptyChart>
                                NO LIVE DATA IN LAST MONTH
                            </Styled.EmptyChart>
                        )}
                    </Styled.ContentWrapper>
                </Styled.Wrapper>
            )}
        </SimCardWidgetBox>
    );
};

ConsumptionChart.propTypes = {
    consumptionChartInfo: PropTypes.shape({
        types: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string.isRequired,
                dropDownTitle: PropTypes.string.isRequired,
                dataIndex: PropTypes.string.isRequired,
            }),
        ).isRequired,
        graphData: PropTypes.array.isRequired,
        tickFormatter: PropTypes.func,
    }).isRequired,
};
