import { useMemo, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Loader } from 'Components';
import { GET_STORED_FCOTA, GET_DEVICE_BY_TOKEN_QUERY } from 'Constants';
import { prepareEditorStructure, saveMD5ContentSums, setFCOTAHierarchy } from 'Utils';
import { PymakrEditor } from '../pymakrEditor';

export const PymakrDevice = () => {
    const { deviceToken } = useParams();

    const [initialLoading, setInitialLoading] = useState(true);

    const { data: deviceData, loading: deviceLoading } = useQuery(GET_DEVICE_BY_TOKEN_QUERY, {
        variables: { deviceToken },
        fetchPolicy: 'cache-and-network',
    });

    const { data: filesData, loading: filesLoading } = useQuery(GET_STORED_FCOTA, {
        variables: { deviceToken },
    });

    const device = deviceData?.device?.[0];
    const files = filesData?.getStoredFCOTA?.files || [];
    const hierarchy = filesData?.getStoredFCOTA?.hierarchy || '';
    const user = device?.owner;

    useEffect(
        () => {
            if (initialLoading && !deviceLoading && !filesLoading) {
                setInitialLoading(false);
            }
        },
        [deviceLoading, filesLoading],
    );

    const projectKey = `${user}-${device?.description}`;
    const storedStructure = localStorage.getItem(projectKey);

    const structure = useMemo(() => {
        if (deviceLoading || filesLoading) {
            return {};
        }
        if (storedStructure) {
            return JSON.parse(storedStructure)?.hierarchy;
        }

        if (!storedStructure) {
            const splitedHierarchy = hierarchy.split(', ').filter((fileName) => fileName.indexOf('.') > 0 && !fileName.endsWith('.pymakr'));
            const filteredFCOTAFiles = files.filter((file) => splitedHierarchy.includes(file.path));
            setFCOTAHierarchy(projectKey, splitedHierarchy);
            saveMD5ContentSums(projectKey, filteredFCOTAFiles);
            return prepareEditorStructure(device?.description, filteredFCOTAFiles, user);
        }
    }, [storedStructure, deviceLoading, filesLoading]);

    if (initialLoading) {
        return <Loader />;
    }

    return (
        <PymakrEditor
            projectKey={projectKey}
            title={device?.description}
            initialStructure={structure}
            user={user}
            deviceToken={device?.token}
        />
    );
};
