import { useState } from 'react';
import { Formik } from 'formik';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useHistory } from 'react-router-dom';

import { forgotPassword } from 'Api';
import { ROUTES } from 'Constants';
import { showToastError, showToastSuccess } from 'Utils';

import * as RootStyled from '../styled';

import { validationSchema, initialValues } from './config';
import { FormContent } from './formContent';
import * as Styled from './styled';

export const ForgotPassword = () => {
    const history = useHistory();

    const [isEmailSent, setIsEmailSent] = useState(false);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const goBack = () => history.goBack();

    const onSubmit = async (values) => {
        try {
            let result;
            if (process.env.REACT_APP_PYCOM_ENABLE_RECAPTCHA === 'true') {
                const recaptchaResult = await executeRecaptcha('forgotPassword');
                result = await forgotPassword(values.email, recaptchaResult);
            } else {
                result = await forgotPassword(values.email);
            }
            if (result?.status === 'Success') {
                showToastSuccess('Request has been sent');
                setIsEmailSent(true);
            } else {
                showToastError('Request has failed');
            }
        } catch (error) {
            showToastError('System error');
        }
    };

    if (isEmailSent) {
        return (
            <>
                <RootStyled.Title>
                    Email sent!
                </RootStyled.Title>
                <RootStyled.Subtitle>
                    You will receive an email if we have an account related to provided one
                </RootStyled.Subtitle>
                <RootStyled.Button onClick={goBack}>
                    Go back
                </RootStyled.Button>
            </>
        );
    }

    return (
        <>
            <RootStyled.Title>
                Forgot your password?
            </RootStyled.Title>
            <RootStyled.Subtitle>
                Please enter your email address and we will send you an email with instructions to reset your password.
            </RootStyled.Subtitle>
            <Formik
                onSubmit={onSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
            >
                {(props) => <FormContent {...props} />}
            </Formik>
            <Styled.LinkWrapper>
                Already have an account?
                <Styled.LoginLink to={ROUTES.auth.login}>
                    Login here
                </Styled.LoginLink>
            </Styled.LinkWrapper>
        </>
    );
};
