import { Dropdown } from 'Components';
import { ReactComponent as LTEIcon } from 'Assets/icons/lte.svg';
import * as Styled from './styled';

export const getColumns = ({
    editLte,
    deleteLte,
}) => {
    const dropdownActions = [
        {
            executedFunction: editLte,
            title: 'Edit',
        },
        {
            executedFunction: deleteLte,
            title: 'Delete',
        },
    ];

    return [
        {
            title: '',
            dataIndex: '_id',
            width: '11%',
            render: () => (
                <Styled.IconWrapper>
                    <LTEIcon />
                </Styled.IconWrapper>
            ),
        },
        {
            title: 'Name',
            dataIndex: 'name',
            width: '26%',
        },
        {
            title: 'Carrier',
            dataIndex: 'carrier',
            width: '26%',
        },
        {
            title: 'APN',
            dataIndex: 'apn',
            width: '26%',
        },
        {
            title: 'Actions',
            dataIndex: '_id',
            width: '11%',
            render: (id) => (
                <Dropdown
                    actions={dropdownActions}
                    id={id}
                />
            ),
        },
    ];
};
