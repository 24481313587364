import { Tooltip } from 'antd';
import PropTypes from 'prop-types';

import { ReactComponent as InfoIcon } from 'Assets/icons/support.svg';

export const InfoTooltip = ({ text }) => (
    <Tooltip title={text}>
        <InfoIcon />
    </Tooltip>
);

InfoTooltip.propTypes = {
    text: PropTypes.string,
};

InfoTooltip.defaultProps = {
    text: '',
};
