export const birds = [
    'Ostrich',
    'Rhea',
    'Cassowary',
    'Emu',
    'Kiwi',
    'Moa',
    'Tinamou',
    'Chachalaca',
    'Currasow',
    'Guan',
    'Pheasant',
    'Quail',
    'Mihirung',
    'Screamer',
    'Duck',
    'Geese',
    'Swan',
    'Flamingo',
    'Pigeon',
    'Dove',
    'Sandgrouse',
    'Mesite',
    'Nightjar',
    'Potoo',
    'Frogmouth',
    'Owl',
    'Owlet',
    'Treeswift',
    'Swift',
    'Hummingbird',
    'Cuckoo',
    'Turaco',
    'Hoatin',
    'Crane',
    'Limpkin',
    'Trumpeter',
    'Pinguin',
    'Gull',
    'Eagle',
    'Albatross',
    'Cormorant',
    'Pelican',
    'Heron',
    'Hawk',
    'Buzzard',
    'Harrier',
    'Vulture',
    'Quetzal',
    'Toucan',
    'Woodpecker',
    'Barbet',
    'Kakapo',
    'Kea',
    'Kaka',
    'Raven',
    'Crow',
];
