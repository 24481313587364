import { useMemo, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { confirmEmail, resendEmail } from 'Api';
import { ROUTES } from 'Constants';
import { showToastError, showToastInfo, showToastSuccess } from 'Utils';

import * as RootStyled from '../styled';

export const ConfirmEmail = () => {
    const history = useHistory();
    const [isResendMode, setIsResendMode] = useState(false);
    const { search } = useLocation();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const query = useMemo(() => new URLSearchParams(search), [search]);
    const confirmToken = query.get('token');

    if (!confirmToken) {
        showToastError('Reset token is missing. Please check the link from email');
        history.push(ROUTES.auth.login);
        return;
    }

    const onConfirmEmail = async () => {
        let result;

        try {
            if (process.env.REACT_APP_PYCOM_ENABLE_RECAPTCHA === 'true') {
                const recaptchaResult = await executeRecaptcha('confirmEmail');
                result = await confirmEmail(confirmToken, recaptchaResult);
            } else {
                result = await confirmEmail(confirmToken);
            }

            if (result?.status === 'Success') {
                showToastSuccess('Email confirmed');
                history.push(ROUTES.auth.login);
            } else if (result?.errorMessage?.message === 'EXPIRED') {
                setIsResendMode(true);
            } else {
                showToastError('Email confirmation has failed');
            }
        } catch (error) {
            showToastError('System error');
        }
    };

    const onResendConfirmationMail = async () => {
        try {
            let result;
            if (process.env.REACT_APP_PYCOM_ENABLE_RECAPTCHA === 'true') {
                const recaptchaResult = await executeRecaptcha('resendConfirmation');
                result = await resendEmail(confirmToken, recaptchaResult);
            } else {
                result = await resendEmail(confirmToken);
            }

            if (result?.status === 'Success') {
                showToastSuccess('Success');
                showToastInfo('Check your email to get a new link');
                history.push(ROUTES.auth.login);
            } else {
                showToastError('Change password request has failed');
            }
        } catch (error) {
            showToastError('System error');
        }
    };

    if (isResendMode) {
        return (
            <>
                <RootStyled.Title>
                    Confirmation link has expired
                </RootStyled.Title>
                <RootStyled.Subtitle>
                    Please press the button to resend email confirmation
                </RootStyled.Subtitle>
                <RootStyled.Button onClick={onResendConfirmationMail}>
                    Resend email confirmation
                </RootStyled.Button>
            </>
        );
    }

    return (
        <>
            <RootStyled.Title>
                Email confirmation
            </RootStyled.Title>
            <RootStyled.Subtitle>
                Please press the button to confirm your email
            </RootStyled.Subtitle>
            <RootStyled.Button onClick={onConfirmEmail}>
                Confirm email
            </RootStyled.Button>
        </>
    );
};
