import gql from 'graphql-tag';

export const GET_PLATFORM_NETWORKS = gql`
    query getPlatformNetworks {
        getPlatformNetworks {
            platformNetworks {
                name
                label
            }
        }
    }
`;
