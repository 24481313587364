import styled from 'styled-components';

export const Column = styled.ul`
    display: flex;
    flex-direction: column;
    margin-right: 20%;
    min-width: 10%;
`;

export const ColumnRow = styled.li`
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    &:last-child {
        margin-bottom: 0;
    }
`;

export const Label = styled.span`
    font-weight: bold;
`;

export const ColumnsWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;
