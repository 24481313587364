import PropTypes from 'prop-types';

import { Collapsible } from 'Components';

import { ModelDefinitionWrapper, ModelConfigurationWrapper } from './components';

const collapsibleLabels = [
    {
        title: 'Model Definition',
    },
    {
        title: 'Model Configuration',
    },
];

export const Configuration = ({ model }) => (
    <Collapsible labels={collapsibleLabels}>
        <ModelDefinitionWrapper model={model} />
        <ModelConfigurationWrapper model={model} />
    </Collapsible>
);

Configuration.propTypes = {
    model: PropTypes.object.isRequired,
};
