import styled, { css } from 'styled-components';

export const PasswordRules = styled.ul`
    margin-top: -50px;
    columns: 2;
`;

export const Rule = styled.li`
    color: ${({ theme }) => theme.colors.text};
    opacity: 0.35;
    ${({ $valid }) => $valid && css`opacity: 1;`}

    span {
        display: none;
        margin-right: 0.25rem;
        ${({ $valid }) => $valid && css`display: inline;`}
    }
`;
