import gql from 'graphql-tag';

export const GET_SIGFOX_CREDENTIALS = gql`
    query getSigfoxCredentials {
        getSigfoxIntegration {
            credentials {
                login
                password
            }
            other {
                email
                contractType
                deviceTypeId
                deviceTypeName
            }
        }
    }
`;

export const SAVE_SIGFOX_CREDENTIALS = gql`
    mutation addSigfoxCredentials(
        $sigfoxCredentials: SigfoxCredentialsInputType!
    ) {
        addSigfoxCredentials(sigfoxCredentials: $sigfoxCredentials) {
            credentials {
                login
                password
            }
            other {
                email
                contractType
                deviceTypeId
                deviceTypeName
            }
        }
    }
`;

export const REMOVE_SIGFOX_CREDENTIALS = gql`
    mutation removeSigfoxCredentials {
        removeSigfoxCredentials
    }
`;

export const UPDATE_DEVICE_TYPE_ID = gql`
    mutation updateContractType(
        $deviceTypeId: String!
        $contractType: String!
        $deviceTypeName: String!
    ) {
        updateContractType(
            deviceTypeId: $deviceTypeId
            contractType: $contractType
            deviceTypeName: $deviceTypeName
        )
    }
`;

export const GET_DEVICE_TYPES = gql`
    query getDeviceTypes {
        getDeviceTypes {
            id
            name
        }
    }
`;

export const CHECK_DEV_KIT_STATUS = gql`
    mutation checkSigfoxDevKitStatus($deviceToken: String!, $sigfoxId: String!) {
        checkSigfoxDevKitStatus(deviceToken: $deviceToken, sigfoxId: $sigfoxId) {
            registrationStatus
            callbackStatus
        }
    }
`;

export const SIGFOX_REGISTRATION_STATUS_SUBSCRIPTION = gql`
    subscription sigfoxRegistrationStatus__api__($deviceToken: String!) {
        sigfoxRegistrationStatus__api__(deviceToken: $deviceToken) {
            jobDone
            sigfoxInfo {
                id
                pac
            }
            status {
                errors
                success
            }
        }
    }
`;
