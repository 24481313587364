import { Form, Field } from 'formik';

import { Select, TextInput } from 'Components';

import { regionOptions } from './config';
import * as Styled from './styled';

export const FormContent = () => (
    <Form>
        <Styled.Title>
            Project Details
        </Styled.Title>
        <Styled.FormContent>    
            <Styled.FormItem>
                <Styled.ItemLabel>
                    Region
                </Styled.ItemLabel>
                <Field
                    name="region"
                    component={Select}
                    values={regionOptions}
                    placeholder="Select Region"
                />
            </Styled.FormItem>
            <Styled.FormItem>
                <Styled.ItemLabel>
                    Project ID
                </Styled.ItemLabel>
                <Field
                    name="projectId"
                    component={TextInput}
                />
            </Styled.FormItem>
            <Styled.Button type="submit">
                Next
            </Styled.Button>
        </Styled.FormContent>
    </Form>
);
