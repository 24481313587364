import styled from 'styled-components';

import { Button } from 'Components';
import { ReactComponent as TrashIcon } from 'Assets/icons/trash.svg';

export const Layer = styled.div`
    position: relative;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 7px;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.text};
    padding: 9px 10px;
    text-align: center;
    border: 1px solid ${({ theme }) => theme.colors.border};
    background: ${({ theme }) => theme.colors.boxBackground};
`;

export const AddLayerWrapper = styled.div`
    display: flex;
`;

export const Input = styled.input`
    flex: 1;
    width: 50%;
    background: #fff;
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding: 5px 10px;
    text-align: center;
`;

export const AddButton = styled(Button)`
    flex: 1;
    width: 50%;
    padding-left: 15px;
    padding-right: 15px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
`;

export const RemoveButton = styled(TrashIcon)`
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.text};
`;
