import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import { ModelDefinition } from 'Components';
import { SET_ML_MODEL_MUTATION, GET_ML_MODEL_BY_ID_QUERY } from 'Constants';
import { showToastError, showToastSuccess } from 'Utils';

export const ModelDefinitionWrapper = ({ model }) => {
    const [updateModelDefinition, { loading }] = useMutation(
        SET_ML_MODEL_MUTATION,
        { refetchQueries: [GET_ML_MODEL_BY_ID_QUERY] },
    );

    const initialValues = useMemo(
        () => ({
            name: model.name,
            description: model.description,
            frequency: +model.frequency,
            windowsSize: +model.windowsSize,
            windowsStep: +model.windowsStep,
        }),
        [model],
    );

    const onSubmit = async (values) => {
        try {
            await updateModelDefinition({
                variables: {
                    model: {
                        _id: model._id,
                        ...values,
                    },
                },
            });

            showToastSuccess('Model definition updated successfully');
        } catch (error) {
            showToastError(error.message);
        }
    };

    return (
        <ModelDefinition
            nextStep={onSubmit}
            formValues={initialValues}
            hideTitle
            hideBackButton
            submitText="Save"
            loading={loading}
        />
    );
};

ModelDefinitionWrapper.propTypes = {
    model: PropTypes.object.isRequired,
};
