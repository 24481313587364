import styled from 'styled-components';

export const TooltipOfDevice = styled.div`
    max-width: 162px;
    width: fit-object;
    max-height: 92px;
    heigth: fit-object;
    padding: 10px 15px;
    color: #162337;
`;

export const Title = styled.div`
    margin-bottom: 4px;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
`;

export const Time = styled.div`
    margin-bottom 6px;
    font-size: 12px;
    line-height: 15px;
    color: ${({ $color }) => $color};
`;

export const Location = styled.div`
    font-size: 12px;
    line-height: 15px;
`;
