import { EDITOR_ACTION_TYPES } from './actionTypes';

export const editorInitialState = {
    refreshEditorRequired: false,
    refreshStorageRequired: false,
};

export const editorReducer = (state, action) => {
    switch (action.type) {
        case EDITOR_ACTION_TYPES.SET_REFRESH_EDITOR:
            return {
                ...state,
                refreshEditorRequired: action.payload.refresh,
            };
        case EDITOR_ACTION_TYPES.SET_REFRESH_STORAGE:
            return {
                ...state,
                refreshStorageRequired: action.payload.refresh,
            };
        default:
            return state;
    }
};
