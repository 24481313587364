import { Form, Field } from 'formik';
import PropTypes from 'prop-types';

import { Button, RadioButtonGroup, Select } from 'Components';
import { SIGFOX_CONTRACT_TYPE_CUSTOM } from 'Constants';

import { contractTypeOptions } from '../config';

import * as Styled from './styled';

export const FormContent = ({ deviceTypeIdOptions, closeForm, isLoading, values }) => (
    <Form
        data-cy="sigfox-contract-settings-modal"
    >
        <Field
            options={contractTypeOptions}
            name="contractType"
            component={RadioButtonGroup}
        />
        {values.contractType === SIGFOX_CONTRACT_TYPE_CUSTOM && (
            <Styled.Select>
                <Field
                    name="deviceTypeId"
                    component={Select}
                    values={deviceTypeIdOptions}
                    cyData="device-type-id-select"
                />
            </Styled.Select>
        )}
        <Styled.ButtonsWrapper>
            <Button
                buttonType="transparent"
                onClick={closeForm}
            >
                Cancel
            </Button>
            <Button
                type="submit"
                loading={isLoading}
                cyData="sigfox-contract-settings-save-button"
            >
                Save
            </Button>
        </Styled.ButtonsWrapper>
    </Form>
);

FormContent.propTypes = {
    values: PropTypes.object.isRequired,
    deviceTypeIdOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    closeForm: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
};

FormContent.defaultProps = {
    isLoading: false,
};
