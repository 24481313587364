import { G2 } from '@ant-design/plots';

import { roundNumberToTwoDigits } from 'Utils';

export const chartRange = { min: 950, max: 1050 };

const trimChartValue = (value) => {
    let result = value;
    
    result = Math.max(0.01, result);
    result = Math.min(1, result);

    return result;
};

export const getChartValue = (value) => {
    const trimmedValue = value - chartRange.min;
    const range = chartRange.max - chartRange.min;
    const result = (((100 * trimmedValue) / range) / 100);

    return roundNumberToTwoDigits(trimChartValue(result));
};

export const getConfig = ({ chartValue }) => ({
    percent: chartValue,
    autoFit: true,
    renderer: 'svg',
    startAngle: Math.PI,
    endAngle: 2 * Math.PI,
    innerRadius: 0.85,
    range: {
        ticks: [0, 1],
        width: 8,
        color: '#CC0011',
    },
    axis: {
        tickInterval: 0.166666666,
        label: {
            offset: 8,
            style: {
                opacity: 0,
            },
        },
        subTickLine: {
            style: {
                stroke: '#4E5558',
                lineWidth: 1.36,
            },
            length: -6,
        },
        tickLine: {
            style: {
                stroke: '#4E5558',
                lineWidth: 1.36,
            },
            length: -15,
        },
    },
    indicator: {
        shape: 'custom-gauge-indicator',
        pointer: {
            style: {
                fill: '#4E5558',
            },
        },
    },
});

export const getChartLabelsPositions = (wrapperElement) => {
    const wrapperDimensions = wrapperElement.getBoundingClientRect();

    // element with index 2 represents second g tag that contains gauge chart itself
    const pathWrapper = wrapperElement.getElementsByTagName('svg')[0].children[2];

    const pathDimensions = pathWrapper.getBoundingClientRect();

    // 10 and 12 are offsets from chart dimensions for better view
    const topPosition = pathDimensions.y - wrapperDimensions.y + pathDimensions.height + 10;
    const leftPosition = pathDimensions.x - wrapperDimensions.x - 12;

    return {
        start: { top: topPosition, left: leftPosition },
        end: { top: topPosition, right: leftPosition },
    };
};

export const registerCustomPointer = () => {
    const { registerShape, Util } = G2;

    registerShape('point', 'custom-gauge-indicator', {
        draw(cfg, container) {
        const { indicator, defaultColor } = cfg.customInfo;
        const { pointer } = indicator;
        const group = container.addGroup();

        const center = this.parsePoint({
            x: 0,
            y: 0,
        });

        if (pointer) {
            const { startAngle, endAngle } = Util.getAngle(cfg, this.coordinate);
            const radius = this.coordinate.getRadius();
            const midAngle = (startAngle + endAngle) / 2;
            const { x: x1, y: y1 } = Util.polarToCartesian(center.x, center.y, radius / 15, midAngle + 1 / Math.PI);
            const { x: x2, y: y2 } = Util.polarToCartesian(center.x, center.y, radius / 15, midAngle - 1 / Math.PI);
            const { x, y } = Util.polarToCartesian(center.x, center.y, radius * 0.65, midAngle);
            const path = [['M', center.x, center.y], ['L', x1, y1], ['L', x, y], ['L', x2, y2], ['Z']];

            group.addShape('path', {
                name: 'pointer',
                attrs: {
                    path,
                    fill: defaultColor,
                    ...pointer.style,
                },
            });
        }

        return group;
        },
    });
};
