import styled from 'styled-components';
import Split from 'react-split';

import { IconButton } from 'Components';

export const VerticalSplit = styled(Split)`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

export const HorizontalSplit = styled(Split)`
    flex: 1;
    display: flex;
`;

export const CodeWrapper = styled.div`
    display: flex;
    width: 100vw;
    flex: 1;
    overflow: hidden;
`;

export const MainWrapper = styled.div`
    display: flex;
    flex: 1;
`;
    
export const MiniWrapper = styled.div`
    width: 100%;
    height: 50%;
    overflow: auto;
`;
    
export const EditorWrapper = styled.div`
    width: 100vw;
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: ${({ theme }) => theme.colors.border};
    overflow: hidden;
    padding-bottom: 10px;

    .gutter {
        background-color: ${({ theme }) => theme.colors.border};
    }

    .gutter.gutter-horizontal {
        cursor: col-resize;
    }

    .gutter.gutter-vertical {
        cursor: row-resize;
    }
`;

export const Breadcrumb = styled.ul`
    display: flex;
    padding: 5px 0 0 57px;
`;

export const BreadcrumbItem = styled.li`
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.active};
    margin: 0 10px;
`;

export const ActiveBreadcrumb = styled(BreadcrumbItem)`
    color: ${({ theme }) => theme.colors.text};
`;

export const Header = styled.div`
    position: relative;
`;

export const SaveAndExportBtn = styled(IconButton)`
    position: absolute;
    right: 30px;
    top: 13px;
`;

export const SyncModalWrapper = styled.div`
    padding: 30px;
`;

export const SyncTitle = styled.h1`
    text-align: center;
    font-weight: bold;
    border-bottom: 1px solid ${({ theme }) => theme.colors.active};
`;

export const SyncProgress = styled.div`
    margin-top: 20px;
    font-size: 20px;
    color: ${({ theme }) => theme.colors.title};
    text-align: center;
`;
