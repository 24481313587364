import { useState } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as Eye } from 'Assets/inputs/eye-closed.svg';
import { ReactComponent as EyeOpen } from 'Assets/inputs/eye-open.svg';

import * as Styled from './styled';

export const PasswordInput = ({ disabled, field, form: { errors } }) => {
    const [isShowPassword, setIsShowPassword] = useState(false);

    const showPassword = () => {
        setIsShowPassword(!isShowPassword);
    };

    return (
        <Styled.Wrapper>
            <Styled.Field
                type={!isShowPassword ? 'password' : 'text'}
                disabled={disabled}
                value={field.value}
                name={field.name}
                onChange={field.onChange}
                onBlur={field.onBlur}
            />
            <Styled.Icon
                onClick={showPassword}
                disabled={disabled}
                role="presentation"
            >
                {!isShowPassword ? <Eye /> : <EyeOpen />}
            </Styled.Icon>
            {!disabled && (
                <Styled.Error $isAnimated={!!errors[field.name]}>
                    {errors[field.name]}
                </Styled.Error>
            )}
        </Styled.Wrapper>
    );
};

PasswordInput.propTypes = {
    disabled: PropTypes.bool,
    form: PropTypes.object,
    field: PropTypes.object,
};

PasswordInput.defaultProps = {
    disabled: false,
    form: {},
    field: {},
};
