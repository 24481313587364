import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';

import {
    GET_DEVICES_STANDALONE,
    UPDATE_APPLICATION_ID,
    GET_DEVICES_QUERY,
    GET_APPLICATIONS,
} from 'Constants';
import { Table, Button, Loader } from 'Components';
import { showToastSuccess } from 'Utils';

import { columns } from './columns';

import * as Styled from './styled';

export const ModalContent = ({ handleClose, projectId }) => {
    const [selectedIds, setSelectedIds] = useState([]);
    const { data, loading } = useQuery(GET_DEVICES_STANDALONE, { fetchPolicy: 'cache-and-network' });
    const devices = useMemo(() => (data?.getDevicesStandalone ?? []), [data]);

    const [addDevicesToProject, { loading: isLoading }] = useMutation(
        UPDATE_APPLICATION_ID,
        { refetchQueries: [GET_DEVICES_STANDALONE, GET_DEVICES_QUERY, GET_APPLICATIONS] },
    );

    const onAddClick = () => {
        addDevicesToProject({
            variables: {
                _ids: selectedIds,
                applicationId: projectId,
            },
            update: () => {
                showToastSuccess('Device(s) added successfully');
                handleClose();
            },
        });
    };

    if (loading) {
        return <Loader />;
    }

    const content = devices.length
        ? (
            <Table
                columns={columns}
                data={devices}
                onSelect={setSelectedIds}
            />
        ) : 'You do not have any more standalone devices';

    return (
        <div data-cy="project-add-device-table-wrapper">
            <Styled.Title>
                Choose your devices
            </Styled.Title>
            <Styled.Content>
                {content}
                <Styled.ButtonsWrapper>
                    <Button
                        buttonType="transparent"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={onAddClick}
                        disabled={!selectedIds.length}
                        loading={isLoading}
                        cyData="project-add-device-submit"
                    >
                        Add
                    </Button>
                </Styled.ButtonsWrapper>
            </Styled.Content>
        </div>
    );
};

ModalContent.propTypes = {
    handleClose: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired,
};
