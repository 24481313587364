import { useState } from 'react';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';

import {
    showToastError,
    showToastInfo,
    getTarget,
    setTarget,
    calculateLocalStorageSize,
} from 'Utils';
import { pymakrStorageLimits } from 'Constants';

import { ExplorerFile, ExplorerFolder } from 'Components';
import { useContextSelector } from './useContextSelector';

export const useHierarchy = (initialStructure, key) => {
    let initialHierarchy = initialStructure;
    const storedStructure = JSON.parse(localStorage.getItem(key));

    if (storedStructure && moment(storedStructure.expires).isAfter(moment())) {
        initialHierarchy = storedStructure.hierarchy;
    }

    const [currPath, setCurrPath] = useState('');
    const [hierarchy, setHierarchy] = useState(initialHierarchy);
    const [warningShowed, setWarningShowed] = useState(false);

    const editorSlice = useContextSelector('editor');
    const { refreshStorage } = editorSlice.handlers;

    const colorizerSlice = useContextSelector('colorizer');
    const { updatedFiles } = colorizerSlice.state;
    const { setUpdatedFile } = colorizerSlice.handlers;

    const tabsSlice = useContextSelector('tabs');
    const { activeTab } = tabsSlice.state;

    const checkFreeSpace = () => {
        const storageSize = calculateLocalStorageSize(key);
        const totalStorageSize = calculateLocalStorageSize();

        if (storageSize > pymakrStorageLimits.error || totalStorageSize > pymakrStorageLimits.localStorage) {
            showToastError(
                'Can not apply changes. No memory left. Please, save your changes and refresh an editor.',
            );
            return false;
        }

        if (storageSize > pymakrStorageLimits.warning && !warningShowed) {
            showToastInfo(
                'Warning! Memory is about to finish. Please, make sure to save changes, or try to free some memory.',
            );
            setWarningShowed(true);
        } else if (storageSize <= pymakrStorageLimits.warning && warningShowed) {
            setWarningShowed(false);
        }

        return true;
    };

    const setHierarchySave = (newHierarchy = null) => {
        const structure = cloneDeep(newHierarchy || hierarchy);
        const hierarchyObj = {
            hierarchy: structure,
            expires: moment().add(1, 'days'),
        };

        localStorage.setItem(key, JSON.stringify(hierarchyObj));
        setHierarchy(structure);
        refreshStorage();
    };

    const getPath = () => currPath;

    const setPath = (newPath) => {
        const path = newPath || '';
        setCurrPath(path);
    };

    const getDirectory = (targetPath) => {
        let path = targetPath;
        if (!path && path !== '') {
            path = currPath;
        }
        const newHierarchy = cloneDeep(hierarchy);
        return getTarget(newHierarchy, path);
    };

    const setDirectory = (target, targetPath) => {
        const path = targetPath || currPath;
        const structure = cloneDeep(hierarchy);
        const newHierarchy = setTarget(target, structure, path);
        setHierarchySave(newHierarchy);
    };

    const renderHierarchy = (value, funcSet) => {
        const inputObject = {
            value,
            onChange: funcSet.onChangeInput,
            inputOnBlur: funcSet.inputOnBlur,
            onKeyDown: funcSet.onInputKeyDown,
        };

        return (
            <ul>
                {hierarchy.files.map((item) => {
                    if (item.type === 'file') {
                        return (
                            <ExplorerFile
                                key={item.key || 'tmp'}
                                file={item}
                                input={inputObject}
                                onClick={funcSet.fileOnClick}
                                onContextMenu={funcSet.openContext}
                                activeKey={activeTab?.key}
                                projectKey={key}
                                updatedFiles={updatedFiles}
                                setUpdatedFile={setUpdatedFile}
                            />
                        );
                    }
                    return (
                        <ExplorerFolder
                            key={item.path || 'tmp'}
                            folder={item}
                            filesOnClick={funcSet.fileOnClick}
                            input={inputObject}
                            onExpand={funcSet.expandFolder}
                            onContextMenu={funcSet.openContext}
                            activeKey={activeTab?.key}
                            projectKey={key}
                            updatedFiles={updatedFiles}
                            setUpdatedFile={setUpdatedFile}
                        />
                    );
                })}
            </ul>
        );
    };

    return {
        setPath,
        checkFreeSpace,
        setHierarchy: setHierarchySave,
        getTarget: getDirectory,
        setDirectory,
        getPath,
        renderHierarchy,
    };
};
