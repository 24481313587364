//import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'formik';
import { Select } from 'Components';
import { DEVICE_FIRMWARE_UNKNOWN } from '../../config';

import * as Styled from './styled';

export const FormContent = ({ values, firmwareValues, currentFirmwareVersion, updating, alert, isOnline }) =>
     (
        <Form>
            <Styled.FormLine>
                <span>
                    FW version
                </span>
                <Field
                    name="version"
                    component={Select}
                    values={firmwareValues}
                    disabled={values.version === DEVICE_FIRMWARE_UNKNOWN || updating}
                />
                <Styled.AlertItem>
                    {alert && (alert)}
                </Styled.AlertItem>
            </Styled.FormLine>
            <Styled.Button
                buttonType="transparent"
                type="submit"
                disabled={currentFirmwareVersion === values.version || updating || !isOnline}
                loading={updating}
            >
                Update
            </Styled.Button>
        </Form>
    );
FormContent.propTypes = {
    values: PropTypes.object.isRequired,
    firmwareValues: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
    })).isRequired,
    updating: PropTypes.bool.isRequired,
    isOnline: PropTypes.bool.isRequired,
    currentFirmwareVersion: PropTypes.string.isRequired,
    alert: PropTypes.element,
};

FormContent.defaultProps = {
    alert: null,
};
