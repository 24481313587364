import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';

import { GOOGLE_CLOUD_REGIONS, GOOGLE_CLOUD_STACKDRIVER_LOGGING, GOOGLE_DOMAIN } from 'Constants';

export class GoogleService {
    constructor(options) {
        const { accessToken, projectId, region } = options;

        if (isEmpty(accessToken) || isEmpty(projectId)) {
            throw new Error('Properties required: accessToken, projectId, region');
        }

        const regionCodes = GOOGLE_CLOUD_REGIONS.map((obj) => obj.code);

        if (isEmpty(region) || !regionCodes.includes(region)) {
            throw new Error(`Region must be one of these values: ${regionCodes.join(',')}`);
        }

        this.projectId = projectId;
        this.region = region;
        this.accessToken = accessToken;
        this.googleDomain = GOOGLE_DOMAIN;
    }

    /**
     * It verifies that the level is acceptable
     * @param {string} level a Stackdriver logging level
     */
    static isValidLogLevel(level) {
        const levels = GOOGLE_CLOUD_STACKDRIVER_LOGGING.reduce((accumulator, currentValue) => {
            accumulator.push(currentValue.value);
            return accumulator;
        }, []);
        return levels.includes(level);
    }

    /**
     * It verifies the validity of the ID through a RegExp
     * @param {string} id Registry ID
     */
    static isValidRegistryID(id) {
        return /^[\w\d+\-.%_~]+$/.test(id);
    }

    /**
     * It verifies the validity of a topic through a RegExp
     */
    static isValidTopicName(topic) {
        return /^([\w\d+\-.%_~]){3,255}$/.test(topic) && // alphanumeric or - _ . % ? % (min 3 max 255 chars)
               !topic.startsWith('goog') &&
               /^[\w]$/.test(topic[0]); // starts with a letter
    }

    static isValidSubfolderName(subName) {
        return (/^[\w\d+\-.%_~]+$/).test(subName);
    }

    /**
     * Creates a device registry that contains devices.
     * @param {string} id a valid registry ID
     * @see https://cloud.google.com/iot/docs/reference/cloudiot/rest/v1/projects.locations.registries#DeviceRegistry
     */
    async createRegistry(registry) {
        const { id, subfolderMatches, pubsubTopicName, logLevel } = registry;

        if (isEmpty(id) || !GoogleService.isValidRegistryID(id)) {
            throw new Error(`The registry ID is mandatory nad it muse use only letters, numbers,
                hyphens, and the following characters: + . % _ ~`);
        }

        if (!GoogleService.isValidLogLevel(logLevel)) {
            throw new Error('Invalid log level. It could be NONE, ERROR, INFO or DEBUG');
        }

        if (!GoogleService.isValidTopicName(pubsubTopicName)) {
            throw new Error(`Topic name must start with a letter, and contain only the following characters:
                letters, numbers, dashes (-), periods (.), underscores (_), tildes (~), percents
                (%) or plus signs (+). Cannot start with goog.`);
        }

        if (!isEmpty(subfolderMatches) && !GoogleService.isValidSubfolderName(subfolderMatches)) {
            throw new Error('Subfolder must contain only letters, numbers, hyphens, and the following characters: + . % _ ~');
        }

        const parentName = `/projects/${this.projectId}/locations/${this.region}/registries`;
        const topic = `projects/${this.projectId}/topics/${pubsubTopicName}`;
        const url = `${this.googleDomain}${parentName}`;

        const body = JSON.stringify({
            id,
            mqttConfig: {
                mqttEnabledState: 'MQTT_ENABLED',
            },
            eventNotificationConfigs: [
                {
                    subfolderMatches,
                    pubsubTopicName: topic,
                },
            ],
            logLevel,
        });

        const options = {
            headers: {
                Authorization: `Bearer ${this.accessToken}`,
            },
            method: 'POST',
            body,
            json: true,
        };

        const registeryResponse = await fetch(url, options)
            .then((result) => result.json())
            .catch((error) => {
                console.error(error.message);
                const parts = error.message.split(' - ');
                return JSON.parse(parts[1]);
            });

        if (registeryResponse.error) {
            console.error(registeryResponse);
            return registeryResponse;
        }
        const parentName2 = `https://pubsub.googleapis.com/v1/projects/${this.projectId}/topics/${pubsubTopicName}`;

        const options2 = {
            headers: {
                Authorization: `Bearer ${this.accessToken}`,
            },
            method: 'PUT',
        };

        await fetch(parentName2, options2)
            .then((result) => result.json())
            .catch((error) => {
                console.error(error.message);
                const parts = error.message.split(' - ');
                return JSON.parse(parts[1]);
            });

        return registeryResponse;
    }

    /**
     * Lists device registries.
     * @param {string} pageToken it's used for pagination
     * @see https://cloud.google.com/iot/docs/reference/cloudiot/rest/v1/projects.locations.registries/list
     */
    async listRegistries(pageToken = null) {
        const parentName = `/projects/${this.projectId}/locations/${this.region}/registries`;
        const url = `${this.googleDomain}${parentName}`;
        const options = {
            headers: {
                Authorization: `Bearer ${this.accessToken}`,
            },
            json: true,
        };

        if (!isNull(pageToken)) {
            options.qs = {
                pageToken,
            };
        }

        const registries = [];

        try {
            const resultJson = await fetch(url, options);

            const result = await resultJson.json();

            if (!isEmpty(result.nextPageToken)) {
                Array.prototype.push.apply(registries, await this.listRegistries(result.nextPageToken));
            } else {
                Array.prototype.push.apply(registries, result.deviceRegistries);
            }
        } catch (error) {
            console.error(error.message);
            const parts = error.message.split(' - ');
            return JSON.parse(parts[1]);
        }

        return registries;
    }
}
