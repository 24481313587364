import { useHistory } from 'react-router-dom';
import { useQuery, useApolloClient, useMutation } from '@apollo/client';

import { WebhookForm } from 'Components';
import {
    showToastError,
    showToastSuccess,
    transformWebhookFormToRequest,
} from 'Utils';
import {
    ROUTES,
    BREADCRUMBS_LABELS,
    GET_USER_PROFILE_QUERY,
    GET_ALL_DEVICES_QUERY,
    GET_WEBHOOK_BY_NAME,
    SAVE_WEBHOOK_MUTATION,
} from 'Constants';
import * as Styled from './styled';

export const WebhookIntegration = () => {
    const history = useHistory();
    const apolloClient = useApolloClient();
    const { data: userData } = useQuery(GET_USER_PROFILE_QUERY);
    const user = userData?.getUserProfile ?? {};

    const { data: allDevicesData } = useQuery(
        GET_ALL_DEVICES_QUERY,
        { fetchPolicy: 'cache-and-network' },
    );
    const allDevices = allDevicesData?.device ?? [];
    const [saveWebhookMutation] = useMutation(SAVE_WEBHOOK_MUTATION);

    const onSubmit = async (values) => {
        try {
            const { data: { getWebHookByName } } = await apolloClient.query({
                query: GET_WEBHOOK_BY_NAME,
                variables: {
                    name: values.webhookEvent,
                },
            });

            if (getWebHookByName) {
                showToastError('It already exists a webhook event with this name');
                return;
            }

            const deviceTokens = values.devices.reduce((acc, item, index) => {
                if (item) {
                    acc.push(allDevices[index].token);
                }

                return acc;
            }, []);

            const requestVariables = transformWebhookFormToRequest(null, values, user.owner, deviceTokens);

            const { data: { saveWebHook: webhook } } = await saveWebhookMutation(
                { variables: { webhook: requestVariables } },
            );

            showToastSuccess('Integration created successfully');
            history.push(`${ROUTES.settings.integrations.main}/${webhook.integrationRefId}`);
        } catch (error) {
            showToastError(error.message);
        }
    };

    return (
        <>
            <Styled.Breadcrumbs labels={BREADCRUMBS_LABELS.webhookIntegration} />
            <WebhookForm allDevices={allDevices} onSubmit={onSubmit} />
        </>
    );
};
