import { useState, useMemo, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { ROUTES, GET_DEVICES_QUERY } from 'Constants';
import { deviceColumns } from 'Components';
import { ReactComponent as USB } from 'Assets/devices/usb.svg';

import { FirmwareSocketStoreProvider } from 'MiniStores';

import { UploadToDevice } from './uploadToDevice';
import { TableView } from './components';
import { RedirectView, ErrorView, LoadingView } from '../../MicroPython';

export const ExportUploadDevice = ({ onClose }) => {
    const [selectedIds, setSelectedIds] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);

    const { data, error, loading, refetch: refetchDevices } = useQuery(GET_DEVICES_QUERY);
    const devices = useMemo(
        () => data?.getDevices?.edges.map((item) => item.node) ?? [],
        [data?.getDevices],
    );
    const isDisabled = isEmpty(selectedIds);
    const pagination = data?.getDevices?.pageInfo ?? {};

    useEffect(
        () => {
            if (initialLoading && !loading) {
                setInitialLoading(false);
            }
        },
        [loading],
    );

    const onPagination = (paginationInfo) => {
        refetchDevices({ paging: paginationInfo });
    };

    const continueUploading = () => {
        setIsUploading(!isUploading);
    };

    if (error) {
        return <ErrorView />;
    }

    if (initialLoading) {
        return <LoadingView />;
    }

    if (isUploading) {
        return (
            <FirmwareSocketStoreProvider>
                <UploadToDevice deviceIds={selectedIds} onClose={onClose} />
            </FirmwareSocketStoreProvider>
        );
    }

    if (!devices.length) {
        return (
            <RedirectView
                icon={<USB />}
                title="Add Devices"
                subtitle="Add Device Via USB"
                route={ROUTES.devices.add}
            />
        );
    }

    return (
        <TableView
            title="Choose a device or devices"
            columns={deviceColumns}
            items={devices}
            onSelect={setSelectedIds}
            onPagination={onPagination}
            pagination={pagination}
            onClick={continueUploading}
            disabledButton={isDisabled}
        />
    );
};

ExportUploadDevice.propTypes = {
    onClose: PropTypes.func.isRequired,
};
