import styled, { css } from 'styled-components';
import { Radio } from 'antd';

export const RadioItem = styled(Radio.Button)`
    &.ant-radio-button-wrapper {
        margin: 0 5px 5px;
        border-radius: 3px;
        height: auto;
        padding: 6px;
        line-height: 1;
        background: ${({ theme }) => theme.colors.boxBackground};
        color: ${({ theme }) => theme.colors.text};
        overflow: hidden;
        transition: background 0.3s, color 0.3s;
        border-color: transparent;
        box-shadow: none !important;

        &:hover {
            background: ${({ theme }) => theme.colors.active};
            color: ${({ theme }) => theme.colors.activeText};
        }

        &::before {
            content: none;
        }
    }

    &.ant-radio-button-wrapper-disabled {
        opacity: 0.5;
    }

    &.ant-radio-button-wrapper-checked {
        background: ${({ theme }) => theme.colors.active};
        color: ${({ theme }) => theme.colors.activeText};
        border-color: transparent !important;
    }
`;

const errorRightStyles = css`
    margin-left: 10px;
    right: 40px;
`;

const errorBottomStyles = css`
    left: 0;
    bottom: -15px;
`;

export const Error = styled.span`
    position: absolute;
    margin-left: 0;
    transition: margin-left 0.4ms linear;
    transition: bottom 0.4s linear;
    color: ${({ theme }) => theme.colors.active};
    font-size: 12px;

    ${({ $position }) => {
        if ($position === 'right') {
            return errorRightStyles;
        }

        if ($position === 'bottom') {
            return errorBottomStyles;
        }

        return '';
    }}
`;
