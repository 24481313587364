import { Tabs } from 'antd';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useQuery } from '@apollo/client';

import { GET_DEVICE_BY_TOKEN_QUERY, SIGFOX } from 'Constants';

import { DeviceProvisioningOnline } from './deviceProvisioningOnline';
import { DeviceProvisioningOffline } from './deviceProvisioningOffline';
import { DeviceProvisioningPymakr } from './deviceProvisioningPymakr';
import { DeviceConnection } from './deviceConnection';
import { SigfoxInfo } from './sigfoxInfo';
import * as Styled from './styled';

const { TabPane } = Tabs;

const baseTabs = [
    {
        title: 'Browser wizard',
        Component: DeviceProvisioningOnline,
        id: 0,
    },
    {
        title: 'Desktop application',
        Component: DeviceProvisioningOffline,
        id: 1,
    },
];

const pymakrTab = {
    title: 'Pymakr plugin',
    Component: DeviceProvisioningPymakr,
    id: 2,
};

export const DeviceProvisioning = ({ deviceToken }) => {
    const { data: deviceData } = useQuery(GET_DEVICE_BY_TOKEN_QUERY, {
        fetchPolicy: 'cache-and-network',
        variables: {
            deviceToken,
        },
    });
    const device = deviceData?.device?.[0] ?? {};
    const { lte, wifi, mac } = device;

    const tabs = useMemo(() => {
        if (isEmpty(lte) && isEmpty(wifi)) {
            return baseTabs;
        }
        return [...baseTabs, pymakrTab];
    }, [device]);

    const isSigfox = useMemo(() => device.networks.includes(SIGFOX), [device]);

    const renderComponent = (Component) => {
        const additionalProps = {};
        additionalProps.deviceToken = deviceToken;

        return <Component {...additionalProps} />;
    };

    return (
        <>
            <Styled.Wrapper>
                <Styled.Tabs>
                    {tabs.map(({ title, Component, id: componentId }) => (
                        <TabPane tab={title.toUpperCase()} key={componentId}>
                            {renderComponent(Component)}
                        </TabPane>
                    ))}
                </Styled.Tabs>
                <DeviceConnection
                    deviceToken={deviceToken}
                    macAddressFromApi={mac}
                    sigfoxInfo={device.sigfoxInfo}
                />
            </Styled.Wrapper>
            {isSigfox && <SigfoxInfo device={device} />}
        </>
    );
};

DeviceProvisioning.propTypes = {
    deviceToken: PropTypes.string.isRequired,
};
