import styled from 'styled-components';

export const Title = styled.div`
    color: ${({ theme }) => theme.colors.title};
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: bold;
`;

export const NotificationWrapper = styled.div`
    display: flex;
    margin-bottom: 35px;
`;

export const FirstNotificationWrapper = styled(NotificationWrapper)`
    margin-bottom: 10px;
`;

export const NotificationIcon = styled.div`
    margin-right: 14px;
    font-size: 20px;
    color: ${({ theme }) => theme.colors.title};
    line-height: 0;
`;

export const NotificationLabel = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-size: 16px;
`;

export const ThingNetworkLink = styled.a`
    margin: 0 5px;
    color: ${({ theme }) => theme.colors.active};
    transition: color 0.3s;

    &:hover {
        color: ${({ theme }) => theme.colors.text};
    }
`;
