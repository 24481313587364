import { useState } from 'react';
import { useHistory } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';

import { PageHeader, Breadcrumbs, Loader } from 'Components';
import {
    BREADCRUMBS_LABELS,
    REGISTER_DEVICE_MUTATION,
    GET_WIFIS,
    GET_LORA_SETTINGS,
    GET_LTE_SETTINGS,
    ROUTES,
    GET_DEVICE_TEMPLATES,
    DEVICE_TEMPLATE_TYPES,
} from 'Constants';
import { showToastError, showToastSuccess } from 'Utils';

import { initialDeviceConfig } from './config';
import {
    DeviceNetwork,
    DeviceCustomization,
    DeviceSummary,
    DeviceConfiguration,
} from './components';
import { prepareForSave } from './helpers';

export const DeviceAddingModule = () => {
    const history = useHistory();
    const { data: deviceTemplatesData, loading: deviceTemplatesLoading } = useQuery(GET_DEVICE_TEMPLATES);
    const { data: wifisData } = useQuery(GET_WIFIS);
    const { data: loraData } = useQuery(GET_LORA_SETTINGS);
    const { data: lteData } = useQuery(GET_LTE_SETTINGS);
    const deviceTemplates = deviceTemplatesData?.getDeviceTemplates ?? {};
    const wifiList = wifisData?.getSettings?.wifiSaved ?? [];
    const loraSettings = loraData?.getLoRaSettings ?? {};
    const lteList = lteData?.getLteSettings ?? [];

    const allNetworks = {
        wifi: wifiList,
        lora: loraSettings,
        lte: lteList,
    };

    const [step, setStep] = useState(1);
    const [deviceConfig, setDeviceConfig] = useState(initialDeviceConfig);

    const [save, { loading: isLoading }] = useMutation(REGISTER_DEVICE_MUTATION);

    const nextStep = () => {
        setStep(step + 1);
    };

    const backStep = () => {
        setStep(step - 1);
    };

    const handleDevice = (type, deviceTemplate) => {
        const newDeviceConfig = { ...deviceConfig };

        switch (type) {
            case DEVICE_TEMPLATE_TYPES.SHIELDS:
                newDeviceConfig.shield = deviceTemplate;
                break;

            default:
                newDeviceConfig.device = deviceTemplate;
        }

        setDeviceConfig(newDeviceConfig);

        if (newDeviceConfig.device && (newDeviceConfig.device.selfContained || newDeviceConfig.shield)) {
            nextStep();
        }
    };

    const resetDeviceTemplateSelection = () => {
        setDeviceConfig({
            ...initialDeviceConfig,
            device: null,
            coModule: null,
            shield: null,
        });
    };

    const handlePygateLora = (values) => {
        setDeviceConfig({
            ...deviceConfig,
            ...values,
        });
    };

    const handleNetwork = (payload) => {
        setDeviceConfig({
            ...deviceConfig,
            networksConfig: payload,
        });
        nextStep();
    };

    const handleCustomData = (values) => {
        setDeviceConfig({
            ...deviceConfig,
            customData: values,
        });
        nextStep();
    };

    const saveDevice = async (values) => {
        const deviceConfigLocal = {
            ...deviceConfig,
            ...values,
        };

        const devicePayload = prepareForSave(deviceConfigLocal, lteList);

        try {
            await save({
                variables: {
                    device: devicePayload,
                    saveWifi: false,
                },
                update: () => {
                    showToastSuccess('Device added successfully');
                    history.push(ROUTES.devices.main);
                },
            });
        } catch (error) {
            let message = error.message;

            try {
                message = JSON.parse(error.message).message ?? 'Server error';
            } catch (err) {
                // ignore parse error
            }

            showToastError(message);
        }
    };

    const backToDeviceConfiguration = () => {
        resetDeviceTemplateSelection();
        backStep();
    };

    if (deviceTemplatesLoading) {
        return <Loader />;
    }

    let content = null;

    switch (step) {
        case 2:
            content = (
                <DeviceNetwork
                    backStep={backToDeviceConfiguration}
                    deviceConfig={deviceConfig}
                    handleNetwork={handleNetwork}
                    handlePygateLora={handlePygateLora}
                />
            );
            break;
        case 3:
            content = (
                <DeviceCustomization
                    backStep={backStep}
                    deviceConfig={deviceConfig}
                    handleCustomData={handleCustomData}
                />
            );
            break;
        case 4:
            content = (
                <DeviceSummary
                    backStep={backStep}
                    saveDevice={saveDevice}
                    allNetworks={allNetworks}
                    deviceConfig={deviceConfig}
                    isLoading={isLoading}
                />
            );
            break;
        case 1:
        default:
            content = (
                <DeviceConfiguration
                    deviceConfig={deviceConfig}
                    handleDevice={handleDevice}
                    deviceTemplates={deviceTemplates}
                    resetDeviceTemplateSelection={resetDeviceTemplateSelection}
                />
            );
    }

    return (
        <>
            <Breadcrumbs labels={BREADCRUMBS_LABELS.devices} />
            <div>
                <PageHeader
                    stepper
                    maxStep={4}
                    step={step}
                    title="Add device"
                    description={[
                        'You can use the examples of the simple Application from our documentation for an easy and quick start. ',
                    ]}
                />
            </div>
            <div>{content}</div>
        </>
    );
};
