import { createContext, useReducer, useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';
import isEmpty from 'lodash/isEmpty';

import { showToastError } from 'Utils';

import { getFirmwareSocketHandlers } from './actions';
import { firmwareSocketReducer, firmwareSocketIniialState } from './reducer';
import { SOCKET_ACTIONS_TYPES } from './actionTypes';

const { REACT_APP_FIRMWARE_SOCKET } = process.env;
let webSocket = new WebSocket(REACT_APP_FIRMWARE_SOCKET);

const FirmwareSocketStore = createContext(webSocket);
const { Provider } = FirmwareSocketStore;

const FirmwareSocketStoreProvider = ({ children }) => {
    const [socket] = useState(webSocket);
    const [firmwareSocketState, firmwareSocketDispatch] = useReducer(firmwareSocketReducer, firmwareSocketIniialState);
    const firmwareSocketHandlers = useMemo(() => getFirmwareSocketHandlers(firmwareSocketDispatch), []);

    const {
        setFirmwareSocketPorts,
        setFirmwareSocketInfo,
        setFirmwareSocketRegionList,
        setFirmwareSocketInit,
        setFirmwareSocketError,
        setFirmwareSocketRemoteConfig,
        downloadFirmware,
    } = firmwareSocketHandlers;

    const seperateSetToStore = ({ action, results, timeoutRef }) => {
        //TODO slace for other actions of socket
        switch (action) {
            case SOCKET_ACTIONS_TYPES.SCAN_PORTS: {
                if (isEmpty(results)) {
                    showToastError('No available ports');
                    return setFirmwareSocketError('empty port');
                }
                return setFirmwareSocketPorts(results);
            }
            case SOCKET_ACTIONS_TYPES.INFO: {
                return setFirmwareSocketInfo({ results, timeoutRef });
            }
            case SOCKET_ACTIONS_TYPES.REGION_LIST: {
                return setFirmwareSocketRegionList(results);
            }
            case SOCKET_ACTIONS_TYPES.DOWNLOAD_FIRMWARE:
                return downloadFirmware(results);
            case SOCKET_ACTIONS_TYPES.REMOTE_CONFIG:
                return setFirmwareSocketRemoteConfig(results);
            case SOCKET_ACTIONS_TYPES.ERROR: {
                showToastError(results);
                return setFirmwareSocketError(results);
            }
            default:
                return null;
        }
    };

    socket.onclose = () => {
        socket.close();
    };

    socket.onmessage = (message) => {
        const data = JSON.parse(message?.data);
        seperateSetToStore(data);
    };

    socket.onerror = (error) => {
        showToastError(error);
        socket.close();
    };

    useEffect(() => {
        webSocket = new WebSocket(REACT_APP_FIRMWARE_SOCKET);
        () => {
            socket.close();
            setFirmwareSocketInit();
        };
    }, []);

    const sendWebSocket = async (action, params = {}, message = null) => {
        let timeoutReference = `${action}_${uuid()}`;
        if (params.hasOwnProperty('port')) {
            timeoutReference = `${timeoutReference}_${params?.port}`;
        }
        const options = {
            action,
            message,
            timeoutReference,
            params,
        };

        if (socket.readyState === 1) {
            await socket.send(JSON.stringify(options));
        }

        return timeoutReference;
    };

    return (
        <Provider
            value={{
                socket,
                sendWebSocket,
                state: firmwareSocketState,
                handlers: firmwareSocketHandlers,
           }}
        >
            {children}
        </Provider>
    );
};

FirmwareSocketStoreProvider.defaultProps = {
    children: null,
};

FirmwareSocketStoreProvider.propTypes = {
    children: PropTypes.node,
};

export { FirmwareSocketStore, FirmwareSocketStoreProvider };
