import styled from 'styled-components';

import { hexToRgb } from 'Utils';

export const Wrapper = styled.div`
    padding-top: 50px;
`;

export const ContentWrapper = styled.div`
    display: flex;
    border: 2px solid ${({ theme }) => theme.colors.border};
    background: ${({ theme }) => theme.colors.boxBackground};
    padding: 10px 15px;
    margin-bottom: 30px;
`;

export const CheckboxBlockWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px 15px;
    width: 20%;

    h3 {
        text-transform: uppercase;
        font-weight: bold;
        margin-bottom: 15px;
    }

    div {
        margin-bottom: 10px;
    }

    button {
        margin-top: 15px;
        text-transform: uppercase;

        svg {
            margin-left: 10px;
            margin-bottom: 2px;
        }
    }
`;

export const MapWrapper = styled.div`
    width: 80%;
    padding: 20px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 15px;
    margin-left: 10px;
    background: rgba(${({ theme }) => hexToRgb(theme.colors.activeText)}, 0.4);

    .googleMapContainer {
        height: 100%;
    }
`;
