import gql from 'graphql-tag';
// CODE STRAIGHT FROM PYBYTES REACT
export const ADD_SIM_CARDS = gql`
   mutation addSimCards($orderNumber: Int!) {
      addSimCards(orderNumber: $orderNumber)
   }
`;

export const GET_SIM_CARD_PROVIDER = gql`
   query getSimCardProvider($iccid: String!) {
      getSimCardProvider(iccid: $iccid)
   }
`;

export const GET_NCE_SIM_CARD_DETAIL = gql`
   query getNCESimDetails($iccid: String!) {
      getNCESimDetails(iccid: $iccid) {
        iccid
        status
        msisdn
        imsi
        imei
        activation_date
        ip_address
        current_quota
        currentStatus {
            status
            location
            operator
            latitude
            longitude
            technology
            pdp_context {
                created
                duration
            }
        }
        quotaDetails {
            volume
            total_volume
            expiry_date
            last_status_change_date
            threshold_percentage
        }
        usageHistory {
            stats {
                date
                data {
                    traffic_type {
                        description
                    }
                    volume
                    volume_rx
                    volume_tx
                }
            }
        }
      }
   }
`;

export const GET_SIM_CARD_DETAIL = gql`
   query getSimCardDetail($iccid: String!) {
      getSimCardDetail(iccid: $iccid) {
         deviceId
         customerServiceProfile
         imei
         state
         deviceInformationList
         baseCountry
         error
         geolocation {
               location {
                  lat
                  lng
               }
               address
         }
      }
   }
`;

export const GET_SIM_CARD_HISTORY = gql`
   query getSimCardHistory($iccid: String!) {
      getSimCardHistory(iccid: $iccid) {
         _id
         timestamp
         customerServiceProfileName
         state
         imei
         baseCountry
      }
   }
`;

export const SET_SIM_CARD_DETAIL = gql`
   mutation setSimCardDetail($simCardDetails: setSimCardDetailInput!) {
      setSimCardDetail(simCardDetails: $simCardDetails) {
         majorReturnCode
         minorReturnCode
      }
   }
`;

export const GET_SIM_CARDS = gql`
   query getSimCards {
      getSimCards {
         imsi
         provider
         iccid
         state
      }
   }
`;

export const GET_DATA_REPORTS = gql`
   query getDataReports($iccid: String!) {
      getDataReports(iccid: $iccid) {
         _id
         Day
         Sum_Bytes_upload
         Sum_Bytes_download
         Sum_Total_Kbytes
         Sum_Bytes_upload_Test
         Sum_Bytes_download_Test
         Sum_Total_KbytesTest
      }
   }
`;
