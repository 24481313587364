import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { GET_RELEASE_BY_ID_QUERY } from 'Constants';
import { ReleaseDetails as ReleaseDetaisView, Loader } from 'Components';

import * as Styled from './styled';

export const ReleaseDetails = () => {
    const history = useHistory();
    const { releaseId } = useParams();
    const { data, loading } = useQuery(GET_RELEASE_BY_ID_QUERY, { variables: { id: releaseId } });
    const release = data?.getRelease || {};
    
    if (loading) {
        return <Loader />;
    }

    return (
        <>
            <Styled.Title>
                Release
                <Styled.HeaderVersion>
                    {release.codeFilename ? release.releaseVersion : 'N/A'}
                </Styled.HeaderVersion>
            </Styled.Title>
            <Styled.Description>
                You can use the examples of the simple applicaton from our
                documentation for an easy and quick start.
                <Styled.Link to="#test">
                    Getting Started!
                </Styled.Link>
            </Styled.Description>
            <ReleaseDetaisView release={release} />
            <Styled.Button onClick={history.goBack}>
                OK
            </Styled.Button>
        </>
    );
};
