import styled from 'styled-components';

import { ReactComponent as Remove } from 'Assets/icons/trash.svg';

export const Wrapper = styled.div`
    button {
        text-transform: uppercase;
    }
`;

export const RemoveIcon = styled(Remove)`
    margin-right: 15px;
`;
