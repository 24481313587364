import styled from 'styled-components';

export const Content = styled.div`
    margin-top: 35px;
`;

export const ContentTitle = styled.div`
    font-weight: bold;
    font-size: 22px;
    line-height: 27px;
    color: ${({ theme }) => theme.colors.title};
`;

export const ContentDescription = styled.div`
    display: flex;
    margin-top: 24px;
    margin-bottom: 75px;

    & svg {
        width: 20px;
        height: 20px;
        margin-right: 14px;
    }

    & div {
        font-size: 16px;
        line-height: 20px;
        color: ${({ theme }) => theme.colors.text};
    }
`;

export const File = styled.div`
    margin-bottom: 25px;

    & div:nth-child(2) {
        flex-direction: row-reverse;
        justify-content: flex-end;

        & button {
            margin-left: 37px;
        }
    }
`;

export const FileTitle = styled.div`
    margin-bottom: 2px;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: ${({ theme }) => theme.colors.title};
`;
