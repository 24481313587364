import PropTypes from 'prop-types';

import { Button } from 'Components';
import { CERTIFICATE_STATUS } from 'Constants';

import * as Styled from './styled';

export const DeviceCertificate = ({
    certificate,
    onClickGenerate,
    onClickRevoke,
    isLoadingCertificate,
}) => (
    <Styled.Wrapper>
        <Styled.Row>
            <Button
                onClick={onClickGenerate}
                loading={isLoadingCertificate}
                disabled={certificate?.status === CERTIFICATE_STATUS.SECURED}
                buttonType="transparent"
            >
                Generate and Deploy
            </Button>
            <Button
                buttonType="transparent"
                onClick={onClickRevoke}
                disabled={certificate?.status !== CERTIFICATE_STATUS.SECURED}
            >
                Revoke
            </Button>
        </Styled.Row>
    </Styled.Wrapper>
);

DeviceCertificate.propTypes = {
    certificate: PropTypes.object.isRequired,
    onClickGenerate: PropTypes.func.isRequired,
    onClickRevoke: PropTypes.func.isRequired,
    isLoadingCertificate: PropTypes.bool,
};

DeviceCertificate.defaultProps = {
    isLoadingCertificate: false,
};
