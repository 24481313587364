import styled, { css } from 'styled-components';

import { Button as ButtonComponent } from 'Components';

export const DetailsWrapper = styled.div`
    margin-top: 30px;
`;

const detailsLineStyles = css`
    display: flex;
    justify-content: space-between;
    padding: 10px 15px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-top: none;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;
    background-color: #fff;
    
    &:hover {
        background-color: ${({ theme }) => theme.colors.boxBackground};
    }

    &:first-child {
        border-top: 1px solid ${({ theme }) => theme.colors.border};
    }
`;

export const disabledLineStyles = css`
    cursor: default;

    &:hover {
        background-color: #fff;
}
`;

export const DetailsHead = styled.div`
    ${detailsLineStyles}

    font-size: 22px;

    ${({ disabled }) => disabled && disabledLineStyles}
`;

export const DetailsLine = styled.button`
    ${detailsLineStyles}

    ${({ disabled }) => disabled && disabledLineStyles}
`;

export const FormItem = styled.div`
    margin-bottom: 10px;
    max-width: 640px;
`;

export const ItemLabel = styled.div`
    color: ${({ theme }) => theme.colors.title};
    padding-top: 8px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.38;
    margin-bottom: 5px;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    margin-top: 30px;

    & > * {
        margin-right: 20px;

        &:last-child {
            margin-right: 0;
        }
    }
`;

export const BigButton = styled(ButtonComponent)`
    margin-top: 30px;
    width: 100%;
`;

export const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;

    svg {
        margin-right: 10px;
        font-size: 20px;
        line-height: 0;
    }
`;
