import { Select } from 'Components';
import { BR_ENABLED_VALUES } from 'Constants';

export const getColumns = ({ getBrValueByToken, handleBRChange }) => ([
    {
        title: 'Device Name',
        dataIndex: 'description',
    },
    {
        title: 'Role',
        dataIndex: 'role',
    },
    {
        title: 'Border Router',
        dataIndex: 'token',
        render: (token) => {
            const selectedItem = `${getBrValueByToken(token)}`;
            const capitalizedItem = `${selectedItem[0].toUpperCase()}${selectedItem.slice(1)}`;

            return (
                <Select
                    custom
                    handleSelect={(value) => handleBRChange(token, value)}
                    values={Object.values(BR_ENABLED_VALUES)}
                    selectedItem={capitalizedItem}
                />
            );
        },
    },
    {
        title: 'LoRa Mac',
        dataIndex: 'mac',
    },
]);
