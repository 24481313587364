import PropTypes from 'prop-types';

import { IconButton, ProjectWidgetContainer } from 'Components';
import { ReactComponent as WidgetIcon } from 'Assets/icons/tile.svg';
import { ReactComponent as OrganizeIcon } from 'Assets/icons/settings.svg';

import * as Styled from '../styled';

export const DashboardView = ({ dashboardData, setEditMode, project, goCreateWidgetPage }) => (
    <>
        <Styled.DashboardHeaderPanel>
            <IconButton 
                onClick={goCreateWidgetPage}
                Icon={WidgetIcon}
                buttonType="transparent"
                cyData="project-dashboard-add-widget"
            >
                Add Widget
            </IconButton>
            <IconButton
                onClick={setEditMode}
                Icon={OrganizeIcon}
                buttonType="transparent"
            >
                Organize
            </IconButton>
        </Styled.DashboardHeaderPanel>
        <Styled.DashboardContent>
            {dashboardData.map((chartData) => (
                <ProjectWidgetContainer
                    key={chartData._id}
                    chartId={chartData._id}
                    chartType={chartData.type}
                    widgetConfig={chartData.widget}
                    projectId={project._id}
                    title={chartData.name}
                    settings={chartData.settings}
                />
            ))}
        </Styled.DashboardContent>
    </>
);

DashboardView.propTypes = {
    project: PropTypes.object.isRequired,
    dashboardData: PropTypes.array.isRequired,
    setEditMode: PropTypes.func.isRequired,
    goCreateWidgetPage: PropTypes.func.isRequired,
};
