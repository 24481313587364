import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';

import { NetworksForm, Loader } from 'Components';
import { showToastSuccess } from 'Utils';
import {
    UPDATE_APPLICATION_MUTATION,
    GET_APPLICATIONS,
    GET_APPLICATION_QUERY,
    GET_PLATFORM_NETWORKS,
} from 'Constants';

export const ProjectNetworksForm = ({ project }) => {
    const { data, loading } = useQuery(GET_PLATFORM_NETWORKS);
    const platformNetworks = data?.getPlatformNetworks?.platformNetworks ?? [];

    const allowedNetworks = useMemo(
        () => platformNetworks.map((platformNetwork) => platformNetwork.name) ?? [],
        [platformNetworks],
    );

    const [updateProject, { loading: isLoading }] = useMutation(
        UPDATE_APPLICATION_MUTATION,
        { refetchQueries: [GET_APPLICATIONS, GET_APPLICATION_QUERY] },
    );

    if (loading) {
        return <Loader />;
    }

    const initialValues = {
        networks: project.networks,
        wifiId: project.wifi?._id,
        lteId: project.lte?._id,
        loraServer: project.loraServer,
        loraType: project.loraType,
        loraRegion: project.loraRegion,
        ethernet: project.ethernetId,
    };

    const onSubmit = (values) => {
        const newValues = { ...values };

        if (newValues.ethernet) {
            newValues.ethernetId = newValues.ethernet;
            delete newValues.ethernet;
        }

        updateProject({
            variables: { application: { _id: project._id, ...newValues } },
            update: () => {
                showToastSuccess('Project networks updated successfully');
            },
        });
    };

    return (
        <NetworksForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            submitButtonColor="transparent"
            submitText="Save"
            hideBackButton
            initialValid={false}
            isLoading={isLoading}
            allowedNetworks={allowedNetworks}
        />
    );
};

ProjectNetworksForm.propTypes = {
    project: PropTypes.object,
};

ProjectNetworksForm.defaultProps = {
    project: {},
};
