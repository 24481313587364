import { useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import { pymeshNetworkSettingsValidationSchema } from 'Components';
import { JOIN_KEY_EXISTS_QUERY } from 'Constants';
import { showToastError } from 'Utils';

import { getInitialValues } from './config';
import { FormContent } from './formContent';

export const PymeshNetworkSettings = ({ nextStep, previousStep, saveValues, values }) => {
    const client = useApolloClient();

    const checkJoinKeyExists = async (joinKey) => {
        const { data: { joinKeyExist } } = await client.query({
            query: JOIN_KEY_EXISTS_QUERY,
            variables: { joinKey },
        });

        return joinKeyExist;
    };

    const handleNext = async (formValues) => {
        const joinKeyExist = await checkJoinKeyExists(formValues.joinKey);

        if (joinKeyExist) {
            return showToastError('The join key already exists, please generate another one');
        }

        saveValues({ ...values, ...formValues });
        nextStep();
    };

    return (
        <Formik
            initialValues={getInitialValues(values)}
            validationSchema={pymeshNetworkSettingsValidationSchema}
            onSubmit={handleNext}
        >
            {(props) => (
                <FormContent
                    {...props}
                    previousStep={previousStep}
                />
            )}
        </Formik>
    );
};

PymeshNetworkSettings.propTypes = {
    nextStep: PropTypes.func.isRequired,
    previousStep: PropTypes.func.isRequired,
    saveValues: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
};
