import { useState } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as ArrowLeft } from 'Assets/icons/arrowLeft.svg';
import { ReactComponent as ArrowRight } from 'Assets/icons/arrowRight.svg';

import * as Styled from './styled';

export const LogsPagination = ({ filter, sortBy, pagination, onChange }) => {
    const [paginationSize, setPaginationSize] = useState(pagination.size);

    const paginationOptions = ['5', '10', '30', '50'];
    const parameters = {
        size: paginationSize,
        sortBy,
        filter,
    };

    const onPrev = () => {
        const page = pagination.page - 1;
        onChange({ ...parameters, page });
    };

    const onNext = () => {
        const page = pagination.page + 1;
        onChange({ ...parameters, page });
    };

    const onChangeSize = (size) => {
        const page = 1;

        setPaginationSize(size);
        onChange({ ...parameters, size: +size, page });
    };

    const hasNextPage = (pagination.count - (pagination.size * pagination.page)) > 0;
    const hasPreviousPage = pagination.page !== 1;

    const dropdownIcon = () => (
        <div>{paginationSize}</div>
    );

    return (
        <Styled.PaginationWrapper>
            <Styled.PaginationButton
                disabled={!hasPreviousPage}
                onClick={onPrev}
            >
                <ArrowLeft />
                Prev
            </Styled.PaginationButton>
            <Styled.PaginationButton
                disabled={!hasNextPage}
                onClick={onNext}
            >
                Next
                <ArrowRight />
            </Styled.PaginationButton>
            <Styled.PageSizeWrapper>
                <span>Logs on the page:</span>
                <Styled.DropdownButton
                    id="pagination-size"
                    actions={paginationOptions.map((opt) => ({
                        title: opt,
                        executedFunction: () => onChangeSize(opt),
                    }))}
                    Icon={() => dropdownIcon()}
                />
            </Styled.PageSizeWrapper>
        </Styled.PaginationWrapper>
    );
};

LogsPagination.defaultProps = {
    filter: null,
};

LogsPagination.propTypes = {
    onChange: PropTypes.func.isRequired,
    filter: PropTypes.object,
    sortBy: PropTypes.object.isRequired,
    pagination: PropTypes.shape({
        size: PropTypes.number,
        count: PropTypes.number,
        page: PropTypes.number,
    }).isRequired,
};
