import { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';

import {
    ONLINE_DEVICE_STATUS,
    PENDING_DEVICE_STATUS,
    OFFLINE_DEVICE_STATUS,
    DEVICE_ONLINE_TIMEOUT,
    pymakrCompilationStatuses,
} from 'Constants';

import { useContextSelector } from '../../../pymakrHooks';
import { useBash } from '../../../pymakrHooks/useBash';
import * as Styled from './styled';

export const TerminalContainer = ({
    history,
    prefix,
    deviceToken,
    userName,
    compilationLogs,
    compilationStatus,
}) => {
    const inputRef = useRef(null);
    const deviceTimeoutRef = useRef(null);
    const [currentHistory, setCurrentHistory] = useState(cloneDeep(history));
    const [deviceStatus, setDeviceStatus] = useState(PENDING_DEVICE_STATUS);
    const [mqttResponseValue, setMqttResponseValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [displayInput, setDisplayInput] = useState(true);

    const hierarchySlice = useContextSelector('hierarchy');
    const { isOnline } = hierarchySlice.state;

    const mqttResponseCallback = (mqttResponse) => {
        setMqttResponseValue(mqttResponse.trim());
    };

    const pingCallback = () => {
        setDeviceStatus(ONLINE_DEVICE_STATUS);
    };

    const bash = useBash({
        deviceToken,
        userName,
        mqttResponseCallback,
        pingCallback,
    });

    useEffect(() => {
        if (mqttResponseValue) {
            const newHistory = cloneDeep(currentHistory);
            newHistory.push({ value: mqttResponseValue });
            setCurrentHistory(newHistory);
            setDisplayInput(true);
        }
    }, [mqttResponseValue]);

    useEffect(() => {
        if (
            compilationStatus && (
                compilationStatus !== pymakrCompilationStatuses.succeeded ||
                compilationStatus !== pymakrCompilationStatuses.failed
            )
        ) {
            setDisplayInput(false);
        }
    }, [compilationStatus]);

    useEffect(() => {
        deviceTimeoutRef.current = setTimeout(() => {
            if (deviceStatus === PENDING_DEVICE_STATUS) {
                setDeviceStatus(OFFLINE_DEVICE_STATUS);
            }
        }, DEVICE_ONLINE_TIMEOUT);

        return () => {
            clearTimeout(deviceTimeoutRef.current);
        };
    }, []);

    const onChange = (event) => {
        setInputValue(event.target.value);
    };

    const onKeyDown = (e) => {
        if (e.ctrlKey && e.keyCode === 'L'.charCodeAt(0)) {
            e.preventDefault();
            setCurrentHistory([]);
        } else if (e.ctrlKey && e.keyCode === 'C'.charCodeAt(0)) {
            setInputValue('');
        } else if (e.key === 'ArrowUp' && bash.hasPreviousCommand()) {
            setInputValue(bash.getPreviousCommand());
        } else if (e.key === 'ArrowDown') {
            const value = bash.hasNextCommand() ? bash.getNextCommand() : '';
            setInputValue(value);
        } else if (e.key === 'Enter') {
            if (isOnline) {
                const terminalState = { deviceStatus: ONLINE_DEVICE_STATUS, currentHistory };
                const newState = bash.execute(inputValue, terminalState);
                setDisplayInput(false);
                setInputValue('');
                setCurrentHistory(newState.currentHistory);
            } else {
                const connectionError =
                    'ECONNREFUSED: Could not establish connection with device.';
                setCurrentHistory([
                    ...currentHistory,
                    { isCommand: true, value: inputValue },
                    { value: connectionError },
                ]);
            }
        }
    };

    const renderHistory = () => (
        <Styled.History>
            {currentHistory.map((item, key) => {
                const currentPrefix = item.isCommand ? (
                    <Styled.Prefix>{prefix}</Styled.Prefix>
                ) : undefined;
                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <Styled.NewLine key={key}>
                        {currentPrefix}
                        {item.value}
                    </Styled.NewLine>
                );
            })}
        </Styled.History>
    );

    const renderLogs = () => (
        <Styled.Logs>
           {compilationStatus && (
                <Styled.CompilationStatus $status={compilationStatus}>
                    Status of compilation: <p>{compilationStatus}</p>
                </Styled.CompilationStatus>
           )}
            {compilationLogs.map((log) => (
                <Styled.NewLine key={log}>{log}</Styled.NewLine>
            ))}
        </Styled.Logs>
    );

    return (
        <Styled.Wrapper onClick={() => inputRef?.current?.focus()}>
            {renderHistory()}
            {renderLogs()}
            {displayInput && (
                <>
                    <Styled.Prefix>{prefix}</Styled.Prefix>
                    <Styled.Input
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus={true}
                        ref={inputRef}
                        type="text"
                        value={inputValue}
                        onChange={onChange}
                        autoComplete="off"
                        onKeyDown={onKeyDown}
                    />
                </>
            )}
        </Styled.Wrapper>
    );
};

TerminalContainer.defaultProps = {
    history: [],
    prefix: '>>>',
    deviceToken: null,
    compilationLogs: [],
    compilationStatus: '',
};

TerminalContainer.propTypes = {
    history: PropTypes.array,
    prefix: PropTypes.string,
    userName: PropTypes.string.isRequired,
    deviceToken: PropTypes.string,
    compilationLogs: PropTypes.array,
    compilationStatus: PropTypes.string,
};
