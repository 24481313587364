export const vodafoneSimLocationColumns = [
    {
        title: 'Date Time',
        dataIndex: 'timestamp',
    },
    {
        title: 'Bundle',
        dataIndex: 'customerServiceProfileName',
    },
    {
        title: 'State',
        dataIndex: 'state',
    },
    {
        title: 'IMEI',
        dataIndex: 'imei',
    },
    {
        title: 'Location',
        dataIndex: 'baseCountry',
    },
];
