import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1.5rem 2rem;
`;

export const Title = styled.h1`
    font-size: 2.5rem;
    color: ${({ theme }) => theme.colors.title};
    font-weight: 700;

    margin-bottom: 0;
`;

export const Subtitle = styled.h2`
    color: ${({ theme }) => theme.colors.title};
    font-size: 1rem;
`;

export const Images = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;

    width: 65%;
    align-self: center;
    margin: 2rem 0;

    img {
        width: 100%;
        height: auto;
    }
`;

export const Links = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
`;

export const ExtLink = styled.a`
    display: block;
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.active};

    &:hover {
        text-decoration: none;
        color: ${({ theme }) => theme.colors.active};
    }
`;

export const Doc = styled.span`
`;
