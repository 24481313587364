import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Table } from 'Components';

import { getColumns } from './columns';
import * as Styled from '../../styled';

export const NotificationsTable = ({ list, deviceName, onRowClick }) => {
    const columns = useMemo(
        () => getColumns(deviceName),
        [deviceName],
    );

    return (
        <Styled.NotificationsWrapper>
            <Styled.Title>
                Notifications
            </Styled.Title>
            <Table 
                data={list} 
                columns={columns}
                addClassToRow={(record) => (record.notificationSeen === false ? 'notification-unread' : '')}
                onRowClick={onRowClick}
            />
        </Styled.NotificationsWrapper>
    );
};

NotificationsTable.propTypes = {
    list: PropTypes.array.isRequired,
    deviceName: PropTypes.string.isRequired,
    onRowClick: PropTypes.func.isRequired,
};
