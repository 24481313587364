import styled from 'styled-components';

import { ReactComponent as WiFiIcon } from 'Assets/network/wifi.svg';

export const Settings = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 145px;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Description = styled.div`
    color: ${({ theme }) => theme.colors.text};
    margin: 50px 0 20px;
    font-size: 16px;
`;

export const Icon = styled(WiFiIcon)`
    width: 46px;
    height: 35px;
`;
