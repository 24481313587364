import { HTTP_METHODS, REQUEST_FORMATS } from 'Constants';

/**
 * Replaces all accented chars with regular ones
 * @param {String} str a string
 */
 export const replaceAccents = (str) => {
    if (str.search(/[\xC0-\xFF]/g) === -1) {
        return str;
    }

    // Verifies if the String has accents and replace them
    return str
        .replace(/[\xC0-\xC5]/g, 'A')
        .replace(/[\xC6]/g, 'AE')
        .replace(/[\xC7]/g, 'C')
        .replace(/[\xC8-\xCB]/g, 'E')
        .replace(/[\xCC-\xCF]/g, 'I')
        .replace(/[\xD0]/g, 'D')
        .replace(/[\xD1]/g, 'N')
        .replace(/[\xD2-\xD6\xD8]/g, 'O')
        .replace(/[\xD9-\xDC]/g, 'U')
        .replace(/[\xDD]/g, 'Y')
        .replace(/[\xDE]/g, 'P')
        .replace(/[\xE0-\xE5]/g, 'a')
        .replace(/[\xE6]/g, 'ae')
        .replace(/[\xE7]/g, 'c')
        .replace(/[\xE8-\xEB]/g, 'e')
        .replace(/[\xEC-\xEF]/g, 'i')
        .replace(/[\xF1]/g, 'n')
        .replace(/[\xF2-\xF6\xF8]/g, 'o')
        .replace(/[\xF9-\xFC]/g, 'u')
        .replace(/[\xFE]/g, 'p')
        .replace(/[\xFD\xFF]/g, 'y');
  };

export const createAWSAttributeName = (obj) => {
    const { description } = obj;

    const newName = replaceAccents(description)
        .replace(/[^A-Z0-9]+/ig, '_');

    const attributeName = `${newName}`;

    return attributeName;
};

export const transformWebhookObjToArr = (headers) => {
    const keys = Object.keys(headers || {});

    return keys.map((key) => ({
        propName: key,
        value: headers[key],
    }));
};

export const transformWebhookArrToObj = (propsArr) => propsArr.reduce((obj, { propName, value }) => {
    if (!(propName && value)) {
        return obj;
    }

    return {
        ...obj,
        [propName]: value,
    };
}, {});

export const getWebhookBody = (webhook) => {
    const { requestType, requestFormat, customBody, formFields } = webhook;

    if (requestType === HTTP_METHODS.GET) {
        return [];
    }

    switch (requestFormat) {
        case REQUEST_FORMATS.JSON: {
            const parsedBody = JSON.parse(customBody);
            return transformWebhookObjToArr(parsedBody);
        }

        case REQUEST_FORMATS.WebForm: {
            return transformWebhookObjToArr(formFields);
        }

        default:
            return [];
    }
};

export const transformWebhookFormToRequest = (hookId, values, owner, deviceTokens) => {
    const {
        webhookEvent,
        url,
        requestType,
        requestFormat,
        authenticationUsername,
        authenticationPassword,
        body,
        customBody,
        httpHeaders,
        queryParameters,
    } = values;

    const result = {
        owner,
        eventName: webhookEvent,
        endpointAddress: url,
        requestType,
        requestFormat,
        formFields: {},
        deviceTokens,
    };

    if (hookId) {
        result._id = hookId;
    }

    if (authenticationUsername && authenticationPassword) {
        result.basicAuth = {
           username: authenticationUsername,
           password: authenticationPassword,
        };
    }

    if (requestType !== HTTP_METHODS.GET) {
        switch (requestFormat) {
            case REQUEST_FORMATS.JSON: {
                const finalBody = transformWebhookArrToObj(body);
                result.customBody = JSON.stringify(finalBody);
                break;
            }
            case REQUEST_FORMATS.WebForm: {
                const finalBody = transformWebhookArrToObj(body);
                result.formFields = finalBody;
                break;
            }
            default:
                result.customBody = customBody;
        }
    }

    if (httpHeaders.length) {
        result.headers = transformWebhookArrToObj(httpHeaders);
    }

    if (queryParameters.length) {
        result.queryParams = transformWebhookArrToObj(queryParameters);
    }

    return result;
};

export const transformWebhookToForm = (webhook) => {
    const {
        endpointAddress,
        eventName,
        requestType,
        requestFormat,
        queryParams,
        headers,
        basicAuth,
        customBody,
    } = webhook;

    return {
        url: endpointAddress,
        webhookEvent: eventName,
        requestType,
        requestFormat,
        body: getWebhookBody(webhook),
        customBody,
        queryParameters: queryParams ? transformWebhookObjToArr(queryParams) : [],
        httpHeaders: transformWebhookObjToArr(headers),
        authenticationUsername: basicAuth?.username || '',
        authenticationPassword: basicAuth?.password || '',
    };
};
