import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { PULL_SIZE_LABELED, TIME_SCALE_LABELED, ALL_DEVICES } from 'Constants';
import * as Styled from './styled';

import { SelectWithPopup } from '../../selectWithPopup';

export const ChartSettings = ({
    chartLayoutSettings,
    setSelectedDevice,
    setSelectedTimeUnit,
    setSelectedPullSize,
    deviceNames,
    defaultPullSize,
    defaultTimeScale,
}) => {
    const deviceDropDown = useMemo(
        () => [
            ALL_DEVICES,
            ...deviceNames.map(
                (deviceName) => ({ label: deviceName, value: deviceName }),
            ),
        ],
        [deviceNames],
    );

    return (
        <Styled.Menu>
            {chartLayoutSettings && (chartLayoutSettings)}
            {deviceNames.length && (
                <Styled.SelectWithPopup>
                    <SelectWithPopup
                        initValue={ALL_DEVICES}
                        values={deviceDropDown}
                        handleSelect={setSelectedDevice}
                    />
                </Styled.SelectWithPopup>
            )}
            <Styled.SelectWithPopup>
                <SelectWithPopup
                    initValue={PULL_SIZE_LABELED.find((item) => item.value === defaultPullSize) ?? PULL_SIZE_LABELED[0]}
                    values={PULL_SIZE_LABELED}
                    handleSelect={setSelectedPullSize}
                    cyData="pull-size-selected-value"
                />
            </Styled.SelectWithPopup>
            <Styled.SelectWithPopup>
                <SelectWithPopup
                    initValue={defaultTimeScale}
                    values={TIME_SCALE_LABELED}
                    handleSelect={setSelectedTimeUnit}
                />
            </Styled.SelectWithPopup>
        </Styled.Menu>
    );
};

ChartSettings.propTypes = {
    chartLayoutSettings: PropTypes.node,
    setSelectedDevice: PropTypes.func,
    setSelectedTimeUnit: PropTypes.func.isRequired,
    setSelectedPullSize: PropTypes.func.isRequired,
    deviceNames: PropTypes.arrayOf(PropTypes.string),
    defaultPullSize: PropTypes.number.isRequired,
    defaultTimeScale: PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    }).isRequired,
};

ChartSettings.defaultProps = {
    chartLayoutSettings: null,
    deviceNames: [],
    setSelectedDevice: () => { },
};
