import styled from 'styled-components';
import AceEditor from 'react-ace';
import 'brace/ext/language_tools';
import 'ace-builds/webpack-resolver';

export const Wrapper = styled.div`
    position: relative;
    background-color: #fff;
    overflow: hidden;
    height: calc(100% - 30px);
`;

export const Ace = styled(AceEditor)`
    margin-top: 12px;
    overflow: hidden;

    * {
        font-family: monospace !important;
    }
`;
