import { ReactComponent as ExcellentIcon } from 'Assets/widgets/airQuality/excellent.svg';
import { ReactComponent as GoodIcon } from 'Assets/widgets/airQuality/good.svg';
import { ReactComponent as LightlyPollutedIcon } from 'Assets/widgets/airQuality/lightlyPolluted.svg';
import { ReactComponent as ModeratelyPollutedIcon } from 'Assets/widgets/airQuality/moderatelyPolluted.svg';
import { ReactComponent as HeavilyPollutedIcon } from 'Assets/widgets/airQuality/heavilyPolluted.svg';
import { ReactComponent as SeverelyPollutedIcon } from 'Assets/widgets/airQuality/severelyPolluted.svg';
import { ReactComponent as ExtremelyPollutedIcon } from 'Assets/widgets/airQuality/extremelyPolluted.svg';

const AIR_CONDITION_LEVELS = {
    EXCELLENT: 'EXCELLENT',
    GOOD: 'GOOD',
    LIGHTLY_POLLUTED: 'LIGHTLY_POLLUTED',
    MODERATELY_POLLUTED: 'MODERATELY_POLLUTED',
    HEAVILY_POLLUTED: 'HEAVILY_POLLUTED',
    SEVERELY_POLLUTED: 'SEVERELY_POLLUTED',
    EXTREMELY_POLLUTED: 'EXTREMELY_POLLUTED',
};

const airConditionInformation = {
    [AIR_CONDITION_LEVELS.EXCELLENT]: {
        index: '0 - 50',
        airQuality: 'Excellent',
        impact: 'Pure air; best for well-being',
        suggestedAction: 'No measures needed',
        icon: ExcellentIcon,
        color: '#78D64B',
    },
    [AIR_CONDITION_LEVELS.GOOD]: {
        index: '51 - 100',
        airQuality: 'Good',
        impact: 'No irritation or impact on well-being',
        suggestedAction: 'No measures needed',
        icon: GoodIcon,
        color: '#ADE591',
    },
    [AIR_CONDITION_LEVELS.LIGHTLY_POLLUTED]: {
        index: '101 - 150',
        airQuality: 'Lightly polluted',
        impact: 'Reduction of well-being possible',
        suggestedAction: 'Ventilation suggested',
        icon: LightlyPollutedIcon,
        color: '#FFEA7C',
    },
    [AIR_CONDITION_LEVELS.MODERATELY_POLLUTED]: {
        index: '151 - 200',
        airQuality: 'Moderately polluted',
        impact: 'More significant irritation possible',
        suggestedAction: 'Increase ventilation with clean air',
        icon: ModeratelyPollutedIcon,
        color: '#FFC20F',
    },
    [AIR_CONDITION_LEVELS.HEAVILY_POLLUTED]: {
        index: '201 - 250',
        airQuality: 'Heavily polluted',
        impact: 'Exposition might lead to effects like headache depending on type of VOCs',
        suggestedAction: 'Optimize ventilation',
        icon: HeavilyPollutedIcon,
        color: '#FF701A',
    },
    [AIR_CONDITION_LEVELS.SEVERELY_POLLUTED]: {
        index: '251 - 350',
        airQuality: 'Severely polluted',
        impact: 'More severe health issue possible if harmful VOC present',
        suggestedAction: 'Contamination should be identified if level is reached even w/o presence of people; '
            + 'maximize ventilation & reduce attendance',
        icon: SeverelyPollutedIcon,
        color: '#CC0011',
    },
    [AIR_CONDITION_LEVELS.EXTREMELY_POLLUTED]: {
        index: '> 351',
        airQuality: 'Extremely polluted',
        impact: 'Headaches, additional neurotoxic effects possible',
        suggestedAction: 'Contamination needs to be identified; avoid presence in room and maximize ventilation',
        icon: ExtremelyPollutedIcon,
        color: '#6B3077',
    },
};

export const getAirConditionInformation = (value) => {
    let airInfo;

    switch (true) {
        case (value >= 0 && value <= 50):
            airInfo = airConditionInformation[AIR_CONDITION_LEVELS.EXCELLENT];
            break;
        case (value >= 51 && value <= 100):
            airInfo = airConditionInformation[AIR_CONDITION_LEVELS.GOOD];
            break;
        case (value >= 101 && value <= 150):
            airInfo = airConditionInformation[AIR_CONDITION_LEVELS.LIGHTLY_POLLUTED];
            break;
        case (value >= 151 && value <= 200):
            airInfo = airConditionInformation[AIR_CONDITION_LEVELS.MODERATELY_POLLUTED];
            break;
        case (value >= 201 && value <= 250):
            airInfo = airConditionInformation[AIR_CONDITION_LEVELS.HEAVILY_POLLUTED];
            break;
        case (value >= 251 && value <= 350):
            airInfo = airConditionInformation[AIR_CONDITION_LEVELS.SEVERELY_POLLUTED];
            break;
        case (value >= 351):
            airInfo = airConditionInformation[AIR_CONDITION_LEVELS.EXTREMELY_POLLUTED];
            break;
        default:
            break;
    }

    return airInfo;
};
