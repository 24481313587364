import styled from 'styled-components';
import { Form as FormikForm } from 'formik';

export const Modal = styled.div`
    width: 100%;
`;

export const Wrapper = styled.div`
    padding: 15px 20px;
`;

export const Title = styled.div`
    width: 100%;
    height: 50px;
    background-color: ${({ theme }) => theme.colors.title};
    padding: 15px 20px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
`;

export const Description = styled.div`
    color: ${({ theme }) => theme.colors.title};
    font-size: 16px;
    font-weight: bold;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
    padding-bottom: 10px;
    
    & > div {
        margin-right: 15px;
    }
`;

export const Form = styled(FormikForm)`
    margin-top: 10px;
`;

export const FormLabel = styled.div`
    color: ${({ theme }) => theme.colors.active};
    font-size: 16px;
    font-weight: bold;
`;
