import styled from 'styled-components';

export const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
`;

export const HeaderRightBlock = styled.div`
    font-size: 14px;
    color: ${({ theme }) => theme.colors.title};
    display: flex;
    align-items: center;
`;

export const DeviceInfo = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: row;

    li {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 21px;

        svg {
            font-size: 30px;
        }

        &:nth-child(2) svg {
            font-size: 20px;
        }

        span {
            padding: 0 16px;
        }      
    }
`;
