import styled, { css } from 'styled-components';

const infoSectionStyles = css`
    text-align: center;
    box-shadow: 0 2px 5px 0 rgba(50, 50, 50, 0.2) inset;
    padding: 20px 15px;
`;

export const Wrapper = styled.div`
    box-shadow: 0 2px 5px 0 rgba(50, 50, 50, 0.2);
    padding: 20px 15px;
    width: 100%;
`;

export const InfoContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 20px 0 0;
    font-weight: bold;
`;

export const InfoSection = styled.div`
    ${infoSectionStyles}
`;

export const DownloadCsv = styled.div`
    ${infoSectionStyles}

    &:hover {
        background-color: lightgrey;
        color: white;
    }
`;

export const Total = styled.span`
    margin-right: 10px;
`;
