import { useMemo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Plot from 'react-plotly.js';

import { NoData, Select } from 'Components';

import * as Styled from './styled';

export const FeatureView = ({ modelFeatures, axisValues, setAxisValues, datasets }) => {
    const selectOptions = useMemo(
        () => modelFeatures?.output?.SP.labels_for_selected_features.map((feature) => ({
            label: feature,
            value: feature,
        })) ?? [],
        [modelFeatures],
    );

    const { traces, layout } = useMemo(
        () => {
            const result = {};

            result.traces = datasets.map((dataset) => ({
                x: dataset.data.x,
                y: dataset.data.y,
                z: dataset.data.z,
                name: dataset.sample,
                mode: 'markers',
                marker: {
                    size: 8,
                    symbol: 'circle',
                    line: {
                        color: 'rgb(204, 204, 204)',
                        width: 1,
                    },
                    opacity: 0.8,
                },
                type: 'scatter3d',
            }));

            result.layout = {
                showlegend: true,
                legend: {
                    x: 0,
                    y: -0.05,
                    orientation: 'v',
                },
                autosize: true,
                height: 650,
                scene: {
                    xaxis: {
                        title: {
                            text: `accX ${axisValues.xAxis}`,
                        },
                    },
                    yaxis: {
                        title: {
                            text: `accY ${axisValues.yAxis}`,
                        },
                    },
                    zaxis: {
                        title: {
                            text: `accZ ${axisValues.zAxis}`,
                        },
                    },
                },
                margin: {
                    l: 20,
                    r: 20,
                    b: 20,
                    t: 20,
                },
            };

            return result;
        },
        [datasets, axisValues],
    );

    const onChange = ({ type, value }) => {
        const newAxisValues = { ...axisValues };
        newAxisValues[type] = value;

        setAxisValues(newAxisValues);
    };

    if (isEmpty(modelFeatures)) {
        return (
            <>
                <h2>Feature Analysis</h2>
                <NoData
                    caption="No features to analyse"
                    text="Process to analyse the features in the graphic."
                /> 
            </>
        );
    }

    return (
        <>
            <h2>Feature Analysis</h2>
            <p>
                This Model recognizes and classifies the data from the accelerometers
            </p>
            <Styled.Header>
                <Styled.SelectWrapper>
                    <Styled.Label>
                        X axis
                    </Styled.Label>
                    <Select
                        name="xAxis"
                        type="xAxis"
                        selectedItem={axisValues.xAxis}
                        values={selectOptions}
                        handleSelect={onChange}
                        custom
                    />
                </Styled.SelectWrapper>
                <Styled.SelectWrapper>
                    <Styled.Label>
                        Y axis
                    </Styled.Label>
                    <Select
                        name="yAxis"
                        type="yAxis"
                        selectedItem={axisValues.yAxis}
                        values={selectOptions}
                        handleSelect={onChange}
                        custom
                    />
                </Styled.SelectWrapper>
                <Styled.SelectWrapper>
                    <Styled.Label>
                        Z axis
                    </Styled.Label>
                    <Select
                        name="zAxis"
                        type="zAxis"
                        selectedItem={axisValues.zAxis}
                        values={selectOptions}
                        handleSelect={onChange}
                        custom
                    />
                </Styled.SelectWrapper>
            </Styled.Header>
            <Styled.Graph>
                <Plot
                    data={traces}
                    layout={layout}
                />
            </Styled.Graph>
        </>
    );
};

FeatureView.propTypes = {
    modelFeatures: PropTypes.object.isRequired,
    axisValues: PropTypes.object.isRequired,
    setAxisValues: PropTypes.func.isRequired,
    datasets: PropTypes.array,
};

FeatureView.defaultProps = {
    datasets: [],
};
