import { useEffect, useContext, useState } from 'react';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { fileToBase64, showToastError } from 'Utils';
import { UPLOAD_FW_FILE } from 'Constants';

import { FirmwareContext } from '../../context';
import { FormContent } from './formContent';
import { initialValues, validationSchema } from './config';
import { firmwareInitialValues } from '../../config';

import * as Styled from './styled';

export const FirmwareConfiguration = ({
    getDeviceInfo,
    scanPorts,
    getRegionList,
    backStep,
    nextStep,
    isLoaded,
    setIsLoaded,
}) => {
    const { firmwareData, setFirmwareData } = useContext(FirmwareContext);
    const [initValues, setInitValues] = useState(initialValues);
    const [uploadFile] = useMutation(UPLOAD_FW_FILE);

    const setupComponent = () => {
        scanPorts();
    };

    useEffect(() => {
        setupComponent();
    }, []);

    useEffect(() => {
        const { userConfiguration } = firmwareData;
        if (!isEmpty(userConfiguration)) {
            setInitValues({
                ...initialValues,
                ...firmwareData.userConfiguration,
            });
        }
    }, []);

    const rescanPorts = async () => {
        setIsLoaded(true);
        setFirmwareData({ ...firmwareData, ...firmwareInitialValues });
        await scanPorts();
    };

    useEffect(() => {
        const { ports } = firmwareData;
        if (!isEmpty(ports)) {
            getRegionList();
        }
    }, [firmwareData.ports]);

    const saveForm = (values) => {
        setFirmwareData({
            ...firmwareData,
            userConfiguration: values,
            firmwareVersionObject: JSON.parse(values.firmwareVersion),
        });
        nextStep();
    };

    const getCustomFile = async (file) => {
        try {
            const convertFile = await fileToBase64(file);
            const response = await uploadFile({
                variables: {
                    fileDetails: {
                        fileBase64: convertFile,
                        fileName: file.name,
                    },
                },
            });
            setFirmwareData({
                ...firmwareData,
                customFile: {
                    file,
                    url: response?.data?.uploadFile,
                },
            });
        } catch (error) {
            showToastError(error);
        }
    };

    const removeFile = () => {
        setFirmwareData({ ...firmwareData, customFile: {} });
    };

    return (
        <div>
            <Styled.Title>Configuration</Styled.Title>
            <Formik
                enableReinitialize
                initialValues={initValues}
                validationSchema={validationSchema}
                onSubmit={saveForm}
            >
                {(props) => (
                    <FormContent
                        {...props}
                        firmwareData={firmwareData}
                        getDeviceInfo={getDeviceInfo}
                        scanPorts={scanPorts}
                        rescanPorts={rescanPorts}
                        backStep={backStep}
                        getCustomFile={getCustomFile}
                        removeFile={removeFile}
                        isLoaded={isLoaded}
                    />
                )}
            </Formik>
        </div>
    );
};

FirmwareConfiguration.propTypes = {
    scanPorts: PropTypes.func.isRequired,
    getDeviceInfo: PropTypes.func.isRequired,
    getRegionList: PropTypes.func.isRequired,
    backStep: PropTypes.func.isRequired,
    nextStep: PropTypes.func.isRequired,
    isLoaded: PropTypes.bool.isRequired,
    setIsLoaded: PropTypes.func.isRequired,
};
