import styled from 'styled-components';

import { Button } from 'Components';
import { ReactComponent as SupportIcon } from 'Assets/network/support.svg';

export const Wrapper = styled.div`
    margin-bottom: 15px;
    display: flex;
    justify-content: flex-start;
`;

export const TokenWrapper = styled.div`
    width: 50%;
    max-height: 40px;
    margin-right: 30px;
    display: flex;
    justify-content: flex-start;
`;

export const TokenField = styled.div`
    padding: 10px 50px 10px 10px;
    flex-grow: 1;
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 5px;
    border-right: none;
    margin-right: -5px;
`;

export const GenerateBtn = styled(Button)`
    min-width: 30%;
`;

export const InfoBlockWrapper = styled.div`
    width: 40%;
    max-height: 35px;
    margin-right: 20px;
    display: flex;
    justify-content: flex-start;
`;

export const InfoIcon = styled(SupportIcon)`
    min-width: 25px;
    margin-right: 10px;
    font-size: 20px;
`;

export const InfoMessage = styled.div`
    overflow-wrap: normal;
    font-size: 0.9em;
`;
