import { useMemo } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

import { REQUEST_FORMATS } from 'Constants';

import {
    initialValues,
    validationSchema,
    FIXED_HTTP_HEADERS_JSON,
    FIXED_HTTP_HEADERS_WEBFORM,
} from './config';
import { FormContent } from './formContent';

export const WebhookForm = ({
    allDevices,
    onSubmit,
    initialValues: parentInitialValues,
    submitText,
    onCancel,
    initialDevices,
    isLoading,
}) => {
    const combinedInitialValues = useMemo(() => {
        const devices = allDevices.map((device) => {
            if (initialDevices.find((item) => item.token === device.token)) {
                return true;
            }

            return false;
        });
        const parentInitialValuesCopy = { ...parentInitialValues };

        if (parentInitialValuesCopy.requestFormat === REQUEST_FORMATS.JSON) {
            parentInitialValuesCopy.httpHeaders = parentInitialValuesCopy.httpHeaders.map(
                (header) => (
                    FIXED_HTTP_HEADERS_JSON.find((item) => item.propName === header.propName)
                        ? { ...header, unchangable: true }
                        : header
                ),
            );
        }

        if (parentInitialValuesCopy.requestFormat === REQUEST_FORMATS.WebForm) {
            parentInitialValuesCopy.httpHeaders = parentInitialValuesCopy.httpHeaders.map(
                (header) => (
                    FIXED_HTTP_HEADERS_WEBFORM.find((item) => item.propName === header.propName)
                        ? { ...header, unchangable: true }
                        : header
                ),
            );
        }

        return { ...initialValues, ...parentInitialValuesCopy, devices };
    }, [allDevices]);

    return (
        <Formik
            initialValues={combinedInitialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {(props) => (
                <FormContent
                    {...props}
                    allDevices={allDevices}
                    submitText={submitText}
                    onCancel={onCancel}
                    isLoading={isLoading}
                />
            )}
        </Formik>
    );
};

WebhookForm.propTypes = {
    allDevices: PropTypes.array,
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    submitText: PropTypes.string,
    onCancel: PropTypes.func,
    initialDevices: PropTypes.array,
    isLoading: PropTypes.bool,
};

WebhookForm.defaultProps = {
    allDevices: [],
    initialValues: {},
    initialDevices: [],
    submitText: 'Create',
    onCancel: null,
    isLoading: false,
};
