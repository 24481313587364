import PropTypes from 'prop-types';
import { useState } from 'react';

import { Modal, WiFiModal, LTEModal, EthernetModal, Button } from 'Components';

import * as Styled from './styled';

export const EmptySettings = ({ title, showForm }) => {
    const [isOpenedModal, setIsOpenModal] = useState(false);

    const handleModal = () => {
        setIsOpenModal(!isOpenedModal);
    };

    const handleCloseModal = () => {
        setIsOpenModal(false);
    };

    const renderNetworkModal = () => {
        switch (title) {
            case 'WiFi':
                return <WiFiModal handleClose={handleCloseModal} />;
            case 'LTE':
                return <LTEModal handleClose={handleCloseModal} />;
            case 'Ethernet':
                return <EthernetModal handleClose={handleCloseModal} />;
            default:
                return null;
        }
    };
    return (
        <Styled.Settings>
            <Styled.Content data-cy={`empty-button-${title.toLowerCase()}`}>
                <Styled.Icon />
                <Styled.Description>
                    You currently have no {title} settings to use with your IoT
                    devices.
                </Styled.Description>
                <Button onClick={title === 'LoRa' ? showForm : handleModal}>
                    {`Add ${title} Network`}
                </Button>
            </Styled.Content>
            {isOpenedModal && (
                <Modal handleClose={handleCloseModal} isOpened={isOpenedModal}>
                    <div>{renderNetworkModal()}</div>
                </Modal>
            )}
        </Styled.Settings>
    );
};

EmptySettings.propTypes = {
    title: PropTypes.string.isRequired,
    showForm: PropTypes.func,
};

EmptySettings.defaultProps = {
    showForm: () => {},
};
