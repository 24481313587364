import PropTypes from 'prop-types';

import { PymakrContextProvider } from '../pymakrStore';
import { PymakrEditorApp } from './pymakrEditorApp';

export const PymakrEditor = ({ projectKey, title, initialStructure, user, deviceToken, programmingLanguage }) => (
    <PymakrContextProvider>
        <PymakrEditorApp
            projectKey={projectKey}
            title={title}
            initialStructure={initialStructure}
            user={user}
            deviceToken={deviceToken}
            programmingLanguage={programmingLanguage}
        />
    </PymakrContextProvider>
);

PymakrEditor.defaultProps = {
    deviceToken: null,
    programmingLanguage: '',
};

PymakrEditor.propTypes = {
    title: PropTypes.string.isRequired,
    initialStructure: PropTypes.object.isRequired,
    projectKey: PropTypes.string.isRequired,
    user: PropTypes.string.isRequired,
    deviceToken: PropTypes.string,
    programmingLanguage: PropTypes.string,
};
