import cloneDeep from 'lodash/cloneDeep';
import { setTabs, setActiveTab } from './actionCreators';

export const getTabsHandlers = (dispatch) => ({
    openTab: (tabs, tabToOpen) => {
        dispatch(setActiveTab(tabToOpen));

        if (tabs.findIndex((i) => i.key === tabToOpen.key) < 0) {
            const newTabs = cloneDeep(tabs);
            newTabs.push(tabToOpen);
            dispatch(setTabs(newTabs));
        }
    },
    closeTab: (tabs, tabToClose) => {
        if (tabs.length === 1) {
            dispatch(setTabs([]));
            dispatch(setActiveTab(null));
        } else {
            const newTabs = cloneDeep(tabs);
            const closeIndex = tabs.findIndex((i) => i.key === tabToClose.key);
            newTabs.splice(closeIndex, 1);

            const newActiveIndex = closeIndex ? closeIndex - 1 : closeIndex;
            dispatch(setActiveTab(newTabs[newActiveIndex]));
            dispatch(setTabs(newTabs));
        }
    },
    updateTabs: (tabs, activeTab, initial, current) => {
        const existingIndex = tabs.findIndex((i) => i.key === initial.key);
        if (existingIndex >= 0) {
            const newTabs = cloneDeep(tabs);
            newTabs.splice(existingIndex, 1, current);
            dispatch(setTabs(newTabs));
        }

        if (activeTab.key === initial.key) {
            dispatch(setActiveTab(current));
        }
    },
    setTabs: (tabs) => dispatch(setTabs(tabs)),
    setActiveTab: (activeTab) => dispatch(setActiveTab(activeTab)),
});
