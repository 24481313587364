import PropTypes from 'prop-types';
import { createContext, useReducer, useMemo } from 'react';

import { editorReducer, editorInitialState, getEditorHandlers } from './editor';
import { colorizerReducer, colorizerInitialState, getColorizerHandlers } from './colorizer';
import { hierarchyReducer, hierarchyInitialState, getHierarchyHandlers } from './hierarchy';
import { tabsReducer, tabsInitialState, getTabsHandlers } from './tabs';
import { compilationReducer, compilationInitialState, getCompilationHandlers } from './compilation';

const PymakrContext = createContext();
const { Provider } = PymakrContext;

const PymakrContextProvider = ({ children }) => {
  const [editorState, editorDispatch] = useReducer(editorReducer, editorInitialState);
  const editorHandlers = useMemo(() => getEditorHandlers(editorDispatch), []);

  const [colorizerState, colorizerDispatch] = useReducer(colorizerReducer, colorizerInitialState);
  const colorizerHandlers = useMemo(() => getColorizerHandlers(colorizerDispatch), []);

  const [hierarchyState, hierarchyDispatch] = useReducer(hierarchyReducer, hierarchyInitialState);
  const hierarchyHandlers = useMemo(() => getHierarchyHandlers(hierarchyDispatch), []);

  const [tabsState, tabsDispatch] = useReducer(tabsReducer, tabsInitialState);
  const tabsHandlers = useMemo(() => getTabsHandlers(tabsDispatch), []);

  const [compilationState, compilationDispatch] = useReducer(compilationReducer, compilationInitialState);
  const compilationHandlers = useMemo(() => getCompilationHandlers(compilationDispatch), []);

  return (
    <Provider
        value={{
            editor: {
                state: editorState,
                handlers: editorHandlers,
            },
            colorizer: {
                state: colorizerState,
                handlers: colorizerHandlers,
            },
            hierarchy: {
                state: hierarchyState,
                handlers: hierarchyHandlers,
            },
            tabs: {
                state: tabsState,
                handlers: tabsHandlers,
            },
            compilation: {
                state: compilationState,
                handlers: compilationHandlers,
            },
        }}
    >
      {children}
    </Provider>
  );
};

PymakrContextProvider.defaultProps = {
    children: null,
};

PymakrContextProvider.propTypes = {
    children: PropTypes.node,
};

export { PymakrContext, PymakrContextProvider };
