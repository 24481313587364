import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { GET_SIM_CARD_PROVIDER, SIM_CARD_PROVIDERS, BREADCRUMBS_LABELS } from 'Constants';
import { Breadcrumbs, Loader } from 'Components';

import { VodafoneSimDetails, OnceSimDetails } from './providers';

import * as Styled from './styled';

export const SimCardDetails = () => {
    const { iccid } = useParams();

    const [initialLoading, setInitialLoading] = useState(true);

    const { data, loading } = useQuery(GET_SIM_CARD_PROVIDER, {
        variables: { iccid },
    });
    const simCardProvider = data?.getSimCardProvider ?? '';

    useEffect(
        () => {
            if (initialLoading && !loading) {
                setInitialLoading(false);
            }
        },
        [loading],
    );

    if (initialLoading) {
        return <Loader />;
    }

    let content = null;

    switch (simCardProvider) {
        case SIM_CARD_PROVIDERS.VODAFONE:
            content = <VodafoneSimDetails iccid={iccid} />;
            break;
        case SIM_CARD_PROVIDERS.ONCE:
            content = <OnceSimDetails iccid={iccid} />;
            break;
        default:
            break;
    }

    return (
        <Styled.Wrapper>
            <Breadcrumbs
                labels={BREADCRUMBS_LABELS.simCards}
                entityName={`${simCardProvider} ${iccid}`}
            />
            {content}
        </Styled.Wrapper>
    );
};
