import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import { NoData, Loader } from 'Components';
import { SAMPLING_TYPES, GET_ML_SAMPLES_BY_MODEL, GET_SAMPLES_BY_MODEL_ID } from 'Constants';
import { ReactComponent as SupportIcon } from 'Assets/icons/support.svg';

import { PYCOM_GITHUB_LIBRARIES } from '../../config';
import * as Styled from '../../styled';
import { DataCollection } from '../dataCollection';
import { Sampling } from '../sampling';

export const DataAcquisition = ({ model, devices }) => {
    const [samplingType] = useState(SAMPLING_TYPES.TRAINING);
    const [initialLoading, setInitialLoading] = useState(true);

    const { data: mlSamplesData, loading: loadingMlSamples, refetch: refetchMlSamples } = useQuery(
        GET_ML_SAMPLES_BY_MODEL,
        {
            variables: { model: model._id, type: samplingType },
            fetchPolicy: 'cache-and-network',
        },
    );
    const mlSamples = mlSamplesData?.getMLSamplesByModelId ?? [];

    const { data: samplesData, loading: loadingSamples, refetch: refetchSamples } = useQuery(
        GET_SAMPLES_BY_MODEL_ID,
        { variables: { modelId: model._id, type: 2 } },
    );
    const samples = samplesData?.getSamplesByModelId ?? [];

    useEffect(
        () => {
            if (initialLoading && !loadingMlSamples && !loadingSamples) {
                setInitialLoading(false);
            }
        },
        [loadingMlSamples, loadingSamples],
    );

    const refetchAllSamples = () => {
        refetchMlSamples();
        refetchSamples();
    };

    if (initialLoading) {
        return <Loader />;
    }

    return (
        <>
            <Styled.InfoWrapper>
                <SupportIcon />
                The accelerometer sensor is only available for <strong>Pytrack</strong> and <strong>Pysense</strong>.<br />
                <strong>Required libraries:</strong> LIS2HH12.py, pysense.py, pytrack.py, pycoproc.py. (
                <a 
                    href={`${PYCOM_GITHUB_LIBRARIES}/tree/master/shields`} 
                    target="_blank" 
                    rel="noopener noreferrer"
                >
                    downloads
                </a>)
            </Styled.InfoWrapper>
            
            <Styled.ModelTabWrapper>
                <Styled.ModelTabLeft>
                    <Sampling
                        model={model}
                        devices={devices}
                        samplingType={samplingType}
                        refetchMlSamples={refetchAllSamples}
                    />
                </Styled.ModelTabLeft>
                <Styled.ModelTabRight>
                    <h2>Data Collection</h2>
                    {mlSamples.length
                        ? (
                            <DataCollection
                                model={model}
                                samplingType={samplingType}
                                mlSamples={mlSamples}
                                samples={samples}
                                devices={devices}
                            />
                        ) : (
                            <NoData 
                                caption="There is no data collected."
                                text="Start from creating a Sample from your device."
                            />
                        )}
                </Styled.ModelTabRight>
            </Styled.ModelTabWrapper>
        </>
    );
};

DataAcquisition.propTypes = {
    model: PropTypes.object.isRequired,
    devices: PropTypes.arrayOf(PropTypes.object),
};

DataAcquisition.defaultProps = {
    devices: [],
};
