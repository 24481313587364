import styled from 'styled-components';

import { Button } from 'Components';

export const Wrapper = styled.div`
    margin-top: 20px;
`;

export const Redirect = styled.div`
    border: 2px solid ${({ theme }) => theme.colors.error};
    padding: 10px 15px;
    border-radius: 10px;

    h3 {
        font-weight: bold;
    }

    button {
        margin-top: 20px;
        text-transform: uppercase;
    }
`;

export const ButtonsSection = styled.div`
    margin-top: 30px;
    display: flex;

    button {
        margin-right: 20px;
        text-transform: uppercase
    }
`;

export const DeleteButton = styled(Button)`
    background: ${({ theme }) => theme.colors.error};
    border: none;

    &:hover {
        background: ${({ theme }) => theme.colors.error};
        border: none;
    }

    &:focus {
        background: ${({ theme }) => theme.colors.error};
        border: none;
    }
`;

export const Confirmation = styled.p`
    font-weight: normal;
`;
