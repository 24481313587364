import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Button as GeneralButton } from 'Components';

export const Options = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 2rem;
    margin-bottom: 1rem;
`;

export const ForgotLink = styled(Link)`
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.text};

    &:hover {
        color: ${({ theme }) => theme.colors.active};
    }
`;

export const Register = styled.button`
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.text};
    background-color: transparent;
    cursor: pointer;

    &:hover {
        color: ${({ theme }) => theme.colors.active};
    }
`;

export const Divider = styled.div`
    margin: 35px 0;
    font-size: 16p;
    color: ${({ theme }) => theme.colors.border};
    text-transform: uppercase;
    text-align: center;
    position: relative;

    &::before,
    &::after {
        position: absolute;
        content: " ";
        top: 50%;
        height: 1px;
        width: calc((100% - 59px) / 2);
        background: ${({ theme }) => theme.colors.border};
    }

    &::before {
        left: 0;
    }

    &:after {
        right: 0;
    }
`;

export const SsoButton = styled(GeneralButton)`
    width: 100%;
    border-radius: 30px;
    height: 56px;
`;
