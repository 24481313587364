import * as Yup from 'yup';

import { TIME_OPTIONS, CHART_TYPES, PULL_SIZE_OPTIONS } from 'Constants';

export const settingsByField = {
    pin: [
        CHART_TYPES.HUMIDITY,
        CHART_TYPES.TEMPERATURE,
        CHART_TYPES.MAP,
        CHART_TYPES.MAP_HISTORY,
        CHART_TYPES.LINE_CHART,
        CHART_TYPES.BAR_CHART,
        CHART_TYPES.DATA_RECEPTION_PER_PIN_BAR_CHART,
        CHART_TYPES.DATA_RECEPTION_PER_PIN_LINE_CHART,
        CHART_TYPES.TABLE,
        CHART_TYPES.PRESSURE,
        CHART_TYPES.AIR_QUALITY,
        CHART_TYPES.HEART_RATE,
        CHART_TYPES.ALTITUDE,
        CHART_TYPES.SIGNAL_QUALITY,
        CHART_TYPES.BATTERY_LEVEL,
    ],
    unit: [
        CHART_TYPES.WEATHER,
        CHART_TYPES.TEMPERATURE,
        CHART_TYPES.ALTITUDE,
    ],
    position: [CHART_TYPES.WEATHER],
    timePeriod: [
        CHART_TYPES.MAP_HISTORY,
        CHART_TYPES.LINE_CHART,
        CHART_TYPES.BAR_CHART,
        CHART_TYPES.DATA_RECEPTION_BAR_CHART,
        CHART_TYPES.DATA_RECEPTION_LINE_CHART,
        CHART_TYPES.DATA_RECEPTION_PER_PIN_BAR_CHART,
        CHART_TYPES.DATA_RECEPTION_PER_PIN_LINE_CHART,
    ],
    type: [
        CHART_TYPES.LINE_CHART,
        CHART_TYPES.BAR_CHART,
        CHART_TYPES.MAP,
        CHART_TYPES.DATA_RECEPTION_BAR_CHART,
        CHART_TYPES.DATA_RECEPTION_LINE_CHART,
        CHART_TYPES.DATA_RECEPTION_PER_PIN_BAR_CHART,
        CHART_TYPES.DATA_RECEPTION_PER_PIN_LINE_CHART,
        CHART_TYPES.DEVICE_LOCATION,
        CHART_TYPES.DATA_RECEPTION_HOUR,
        CHART_TYPES.DATA_RECEPTION_DAY,
    ],
    limit: [
        CHART_TYPES.LINE_CHART,
        CHART_TYPES.BAR_CHART,
        CHART_TYPES.DATA_RECEPTION_BAR_CHART,
        CHART_TYPES.DATA_RECEPTION_LINE_CHART,
        CHART_TYPES.DATA_RECEPTION_PER_PIN_BAR_CHART,
        CHART_TYPES.DATA_RECEPTION_PER_PIN_LINE_CHART,
        CHART_TYPES.DATA_RECEPTION_HOUR,
        CHART_TYPES.DATA_RECEPTION_DAY,
    ],
};

export const initialValues = {
    name: '',
    type: '',
    settings: {
        pin: 0,
        unit: '',
        position: {
            lat: '',
            lng: '',
        },
        timePeriod: TIME_OPTIONS.FIVE_MINUTES,
        limit: PULL_SIZE_OPTIONS.TWENTY,
    },
};

export const getValidationSchema = (widgetType, isNameIncluded, hasModes) => Yup.object().shape({
    name: Yup.string().when('settings', {
        is: () => isNameIncluded,
        then: Yup.string().required(),
    }),
    type: Yup.string().when('name', {
        is: () => hasModes,
        then: Yup.string().required(),
    }),
    settings: Yup.object().shape({
        // name check is added just to run `when` rule
        pin: Yup.number().nullable().when('name', {
            is: () => settingsByField.pin.includes(widgetType),
            then: Yup.number().required(),
        }),
        unit: Yup.string().nullable().when('name', {
            is: () => settingsByField.unit.includes(widgetType),
            then: Yup.string().required(),
        }),
        position: Yup.object().nullable().when('name', {
            is: () => settingsByField.position.includes(widgetType),
            then: Yup.object().shape({
                lat: Yup.string().required(),
                lng: Yup.string().required(),
            }),
        }),
        timePeriod: Yup.number().nullable().when('name', {
            is: () => settingsByField.timePeriod.includes(widgetType),
            then: Yup.number().required(),
        }),
        limit: Yup.number().nullable().when('name', {
            is: () => settingsByField.limit.includes(widgetType),
            then: Yup.number().required(),
        }),
    }),
});
