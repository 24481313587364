import * as Yup from 'yup';

import { PYMESH_REGIONS } from 'Constants';

export const pymeshNetworkSettingsValidationSchema = Yup.object().shape({
    region: Yup.string(),
    frequency: Yup
        .number()
        .typeError('Frequency value should be a number')
        .test(
            'oneOfRequired',
            'Invalid frequency. Use tooltip to see available ranges.',
            // eslint-disable-next-line func-names
            function (value) {
                const region = this.parent.region;
                const intervals = PYMESH_REGIONS.find((regionInfo) => regionInfo.region.name === region).frequencies_interval;
                return !!intervals.find((interval) => interval.min <= value && value <= interval.max);
            },
        ),
    bandwidth: Yup.string(),
    spreadFactor: Yup.string(),
    joinKey: Yup
        .string()
        .required('Join Key should not be empty')
        .test(
            'length',
            'Invalid Join Key',
            (value) => {
                if (value) {
                    const formattedValue = value.replaceAll('-', '');
                    return formattedValue.length === 32;
                }
            },
        ),
});
