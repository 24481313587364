import PropTypes from 'prop-types';

import { capitalizeAllLetters } from 'Utils';

import * as Styled from './styled';

export const FeatureDisabled = ({ featureName }) => (
    <Styled.Wrapper>
        {`${capitalizeAllLetters(featureName.split('-').join(' '))} feature is disabled`}
    </Styled.Wrapper>
);

FeatureDisabled.propTypes = {
    featureName: PropTypes.string.isRequired,
};
