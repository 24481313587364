import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import { UPDATE_FCOTA_FILE_MUTATION, DELETE_FCOTA_FILE_MUTATION } from 'Constants';
import { ReactComponent as Upload } from 'Assets/icons/upload.svg';
import { ReactComponent as Close } from 'Assets/icons/close.svg';
import { compareMD5Hash, getDeviceModeInfo, getRenamingItems, showToastError } from 'Utils';

import { useContextSelector } from '../../../../pymakrHooks';
import * as Styled from './styled';

export const CodeContainerTab = ({ tab, projectKey }) => {
    const [isChanged, setIsChanged] = useState(false);
    const [updateFile] = useMutation(UPDATE_FCOTA_FILE_MUTATION);
    const [deleteFile] = useMutation(DELETE_FCOTA_FILE_MUTATION);
    const deviceModeInfo = getDeviceModeInfo(projectKey);

    const colorizerSlice = useContextSelector('colorizer');
    const { updatedFiles } = colorizerSlice.state;
    const { setUpdatedFile, unsetUpdatedFile } = colorizerSlice.handlers;

    const tabsSlice = useContextSelector('tabs');
    const { activeTab, tabs } = tabsSlice.state;
    const { openTab, closeTab } = tabsSlice.handlers;

    const hierarchySlice = useContextSelector('hierarchy');
    const { isOnline } = hierarchySlice.state;

    useEffect(() => {
        if (updatedFiles.includes(tab.path) && !isChanged) {
            setIsChanged(true);
        } else if (!updatedFiles.includes(tab.path) && isChanged) {
            setIsChanged(false);
        }
    }, [updatedFiles]);

    const onClose = (e, tabToClose) => {
        e.stopPropagation();
        closeTab(tabs, tabToClose);
    };

    const onFileUpload = (fileTab, deviceInfo) => {
        if (!deviceInfo || !isOnline) {
            return showToastError(
                'Upload failed. Device is offline',
            );
        }

        const renamingItems = getRenamingItems(projectKey);
        const renamingIndex = renamingItems.findIndex((item) => item.current === fileTab.path);
        if (renamingIndex >= 0) {
            deleteFile({
                variables: {
                    deviceToken: deviceInfo.deviceToken,
                    path: renamingItems[renamingIndex].initialPath,
                },
            });
        }

        const content = localStorage.getItem(fileTab.key);
        updateFile({
            variables: {
                deviceToken: deviceInfo.deviceToken,
                path: fileTab.path,
                content,
            },
        });
        unsetUpdatedFile(updatedFiles, fileTab.path, true);
    };

    if (deviceModeInfo && compareMD5Hash(projectKey, tab.path) === false && !isChanged) {
        setIsChanged(true);
        setUpdatedFile(updatedFiles, tab.path);
    }

    const isActive = tab?.key === activeTab?.key;

    return (
        <Styled.Tab
            key={tab.key}
            $active={isActive}
            onClick={() => openTab(tabs, tab)}
            tabIndex={0}
            role="button"
            onKeyDown={() => {}}
        >
            <Styled.TabTitle $updated={isChanged}>
                {tab.name}
            </Styled.TabTitle>
            {isChanged && (
                <Styled.UploadFileButton
                    onClick={() => onFileUpload(tab, deviceModeInfo)}
                    Icon={Upload}
                />
            )}
            <Styled.Button
                Icon={Close}
                onClick={(e) => onClose(e, tab)}
            />
        </Styled.Tab>
    );
};

CodeContainerTab.propTypes = {
    tab: PropTypes.shape({
        name: PropTypes.string,
        type: PropTypes.string,
        path: PropTypes.string,
        parent: PropTypes.string,
        isInitial: PropTypes.bool,
        key: PropTypes.string,
    }).isRequired,
    projectKey: PropTypes.string.isRequired,
};
