import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import { Modal, DeleteModal } from 'Components';
import { showToastSuccess } from 'Utils';
import { GET_RELEASES_QUERY, DELETE_RELEASE } from 'Constants';

export const DeleteReleaseModal = ({ isOpen, handleClose, releaseId }) => {
    const [deleteRelease, { loading: isLoading }] = useMutation(DELETE_RELEASE, { refetchQueries: [GET_RELEASES_QUERY] });

    const onDelete = () => {
        deleteRelease({
            variables: { releaseId },
            update: () => {
                showToastSuccess('Release removed successfully');
                handleClose();
            },
        });
    };

    return (
        <Modal
            isOpened={isOpen}
            handleClose={handleClose}
        >
            <DeleteModal
                title="Delete Release"
                description="Beware that this release and all related data will be deleted. This action cannot be undone!"
                cancelAction={handleClose}
                deleteAction={onDelete}
                isLoading={isLoading}
            />
        </Modal>
    );
};

DeleteReleaseModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    releaseId: PropTypes.string,
};

DeleteReleaseModal.defaultProps = {
    releaseId: '',
};
