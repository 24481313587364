import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { Table, Modal } from 'Components';
import { EDIT_PIN_MUTATION, PINS_BY_DEVICE_QUERY } from 'Constants';

import { EditionSignalModal } from '../editionSignalModal';
import { getColumns } from './columns';

import * as Styled from '../../styled';

export const SignalsTable = ({ pins, lastMessages }) => {
    const history = useHistory();
    const { pathname } = useLocation();

    const [editPinMutation, { loading: isEditLoading }] = useMutation(
        EDIT_PIN_MUTATION,
        { refetchQueries: [PINS_BY_DEVICE_QUERY] },
    );

    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedPin, setSelectedPin] = useState(null);

    const tableData = useMemo(
        () => pins.map((pin) => {
            const lastMessage = lastMessages.find((message) => message.messagePin === pin.pin);

            if (lastMessage) {
                return { ...pin, lastMessage };
            }

            return pin;
        }),
        [pins, lastMessages],
    );

    const closeEditModal = () => {
        setShowEditModal(false);
        setSelectedPin(null);
    };

    const editSignal = (id) => {
        const pin = pins.find((item) => item._id === id);

        setSelectedPin(pin);
        setShowEditModal(true);
    };

    const editSignalHandler = (variables) => {
        editPinMutation({
            variables,
            onCompleted: () => {
                closeEditModal();
            },
        });
    };

    const columns = useMemo(() => getColumns({ editSignal }), [pins]);

    const goSignalDetails = (pin) => {
        const editSignalRoute = `${pathname}/${pin._id}`;
        history.push(editSignalRoute);
    };

    return (
        <div>
            <Styled.SignalsContainer>
                <Table
                    data={tableData}
                    columns={columns}
                    onRowClick={goSignalDetails}
                />
            </Styled.SignalsContainer>
            <Modal
                handleClose={closeEditModal}
                isOpened={showEditModal}
            >
                <EditionSignalModal
                    pins={pins}
                    currentPin={selectedPin}
                    onSubmit={editSignalHandler}
                    onClose={closeEditModal}
                    isLoading={isEditLoading}
                />
            </Modal>
        </div>
    );
};

SignalsTable.propTypes = {
    pins: PropTypes.array.isRequired,
    lastMessages: PropTypes.array.isRequired,
};
