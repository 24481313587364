import styled from 'styled-components';

import { TitleBox as TitleBoxComponent } from 'Components';

export const Settings = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 50px;
`;

export const Button = styled.div`
    margin-top: 14px;
`;

export const TitleBox = styled(TitleBoxComponent)`
    width: 214px;
`;
