import styled from 'styled-components';

export const Container = styled.div`
    display: grid;
    grid-template-columns: 30% 70%;
    height: 100%;
`;

export const Info = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    h3 {
        white-space: nowrap;
    }
`;

export const Chart = styled.div`
    height: 100%;
    padding: 30px 0 0;
    position: relative;
`;

export const UnitsWrapper = styled.div`
    display: flex;
    color: #bdbdbd;
    font-size: 24px;
`;

export const Unit = styled.span`
    cursor: pointer;
    transition: color 0.3s;

    ${({ $active }) => $active && `
        color: #000;
    `}

    &:hover {
        color: #000;
    }
`;
