import { LORA_CONSTANTS } from 'Constants';

export const getInitialValues = (deviceConfig) => {
    const { networksConfig, customData } = deviceConfig;

    const initialValues = {
        name: customData.name,
        description: customData.description,
    };

    if (networksConfig.wifiId) {
        initialValues.wifiId = networksConfig.wifiId;
    }

    if (networksConfig.lteId) {
        initialValues.lteId = networksConfig.lteId;
    }

    if (networksConfig.loraServer) {
        if (networksConfig.loraType === LORA_CONSTANTS.RADIO_BUTTONS[0].value) {
            initialValues.lora = LORA_CONSTANTS.RADIO_BUTTONS[0].label;
        } else {
            initialValues.lora = LORA_CONSTANTS.RADIO_BUTTONS[1].label;
        }
    }

    return initialValues;
};
