import styled, { css } from 'styled-components';
import { Button as AntButton } from 'antd';

const borderedStyles = css`
    border: 1px solid ${({ theme }) => theme.colors.border};

    &:hover,
    &:focus {
        color: ${({ theme }) => theme.colors.title};
        border-color: ${({ theme }) => theme.colors.border};
    }
`;

const filledButtonStyles = css`
    background: ${({ theme }) => theme.colors.active};
    border: 1px solid ${({ theme }) => theme.colors.active};
    color: ${({ theme }) => theme.colors.activeText};

    &:hover,
    &:focus {
        background: ${({ theme }) => theme.colors.active};
        color: ${({ theme }) => theme.colors.activeText};
        border-color: ${({ theme }) => theme.colors.active};
    }
`;

const boldTextStyles = css`
    font-weight: bold;
`;

export const Button = styled(AntButton)`
    height: auto;
    display: flex;
    align-items: center;
    padding: 7px 15px;
    color: ${({ theme }) => theme.colors.title};
    transition: color 0.3s;
    font-size: 14px;
    cursor: pointer;
    background: transparent;
    border-radius: 4px;
    border-color: transparent;
    box-shadow: none;

    svg {
        margin-right: 10px;
        font-size: 20px;
    }

    &:hover,
    &:focus {
        color: ${({ theme }) => theme.colors.active};
        border-color: transparent;
        background-color: transparent;
        box-shadow: none;
    }

    ${({ $buttonType }) => $buttonType === 'filled' && filledButtonStyles}
    ${({ $bordered }) => $bordered && borderedStyles}
    ${({ $textBold }) => $textBold && boldTextStyles}
`;
