import styled from 'styled-components';
import { Switch as AntSwitch } from 'antd';

import { Button as ButtonComponent } from 'Components';

export const FormWrapper = styled.div`
    display: grid;
    grid-template-columns: min-content min-content;
    gap: 10px 15px;
    button {
        margin-top: 0 !important
    }
`;

export const FormLabel = styled.div`
    color: ${({ theme }) => theme.colors.title};
    white-space: nowrap;
    padding-top: 8px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.38;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
`;

export const Button = styled(ButtonComponent)`
    margin-top: 11px;
`;

export const Switch = styled(AntSwitch)`
    &.ant-switch-checked {
        background-color: ${({ theme }) => theme.colors.active};
    }
`;

export const ModalTitle = styled.div`
    width: 100%;
    height: 50px;
    padding: 15px 20px;
    background-color: ${({ theme }) => theme.colors.title};
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
`;

export const ModalDescription = styled.div`
    color: ${({ theme }) => theme.colors.title};
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 10px;
`;

export const ModalContent = styled.div`
    padding: 15px 20px 30px 25px;
`;

export const Code = styled.pre`
    padding: 10px 20px;
    background: ${({ theme }) => theme.colors.boxBackground};
`;
