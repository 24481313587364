import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'formik';

import { LORA_CONSTANTS, SERVER_OPTIONS } from 'Constants';
import { getRegionOptions } from 'Utils';

import { Select } from '../../select';

import * as Styled from '../styled';

export const FormContent = ({
    values,
    handleSubmit,
    setFieldValue,
    dirty,
    disableLora,
    showLoraType,
}) => {
    useEffect(() => {
        if (!dirty) {
            return;
        }

        if (values.loraServer === LORA_CONSTANTS.SERVERS.TTN) {
            setFieldValue('loraRegion', LORA_CONSTANTS.REGIONS.TTN[0].value);
        } else {
            setFieldValue('loraRegion', LORA_CONSTANTS.REGIONS.CHIRPSTACK[0].value);
        }
    }, [values.loraServer]);

    useEffect(() => {
        handleSubmit();
    }, [values]);

    return (
        <Form>
            <Styled.LoraFormWrapper>
                <Field
                    name="loraServer"
                    component={Select}
                    values={SERVER_OPTIONS}
                    disabled={disableLora}
                />
                {showLoraType && (
                    <Field
                        name="loraType"
                        component={Select}
                        values={LORA_CONSTANTS.RADIO_BUTTONS}
                        disabled={disableLora}
                    />
                )}
                <Field
                    name="loraRegion"
                    component={Select}
                    values={getRegionOptions(values.loraServer)}
                    disabled={disableLora}
                />

            </Styled.LoraFormWrapper>
        </Form>
    );
};

FormContent.propTypes = {
    values: PropTypes.shape({
        loraServer: PropTypes.string,
        loraType: PropTypes.string,
        loraRegion: PropTypes.string,
    }).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    dirty: PropTypes.bool.isRequired,
    disableLora: PropTypes.bool.isRequired,
    showLoraType: PropTypes.bool.isRequired,
};
