import { Form } from 'formik';
import PropTypes from 'prop-types';

import { AuthInput } from 'Components';

import { PasswordValidation } from '../passwordValidation';
import * as RootStyled from '../styled';
import * as Styled from './styled';

export const FormContent = ({ values, isValid }) => (
    <Form>
        <Styled.AuthInput>
            <AuthInput
                name="password"
                label="New password"
                type="password"
                showPassEye
            />
        </Styled.AuthInput>
        <Styled.AuthInput>
            <AuthInput
                name="passwordConfirmation"
                label="Repeat password"
                type="password"
                showPassEye
            />
        </Styled.AuthInput>
        <Styled.ValidatorWrapper>
            <PasswordValidation password={values.password} />
        </Styled.ValidatorWrapper>
        <Styled.ButtonWrapper>
            <RootStyled.Button type="submit" disabled={!isValid}>
                Reset password
            </RootStyled.Button>
        </Styled.ButtonWrapper>
    </Form>
);

FormContent.propTypes = {
    isValid: PropTypes.bool.isRequired,
    values: PropTypes.object,
};

FormContent.defaultProps = {
    values: {},
};
