import { LORA_CONSTANTS } from 'Constants';

import { initialValues } from './config';

export const getInitialValues = (loraSettings) => {
    let result = initialValues;
    if (loraSettings !== null && Object.values(loraSettings).length) {
        result = {
            loraServer: loraSettings.loraServer ?? LORA_CONSTANTS.SERVERS.TTN,
            usingABP: loraSettings.usingABP
                ? LORA_CONSTANTS.RADIO_BUTTONS[1].value
                : LORA_CONSTANTS.RADIO_BUTTONS[0].value,
            loraRegion: loraSettings.loraRegion ?? LORA_CONSTANTS.REGIONS.TTN[0],
        };
    }
    return result;
};

export const prepareForRequest = (values) => {
    const payload = {
        ...values,
        usingABP: values.usingABP === LORA_CONSTANTS.RADIO_BUTTONS[1].value,
    };

    return payload;
};
