import { useMemo } from 'react';

import { getInitialValues, getValidationSchema } from '../config';

export const useFormConfig = (samplingType, devices, formValues) => {
    const { initialValues, validationSchema } = useMemo(
        () => ({
            initialValues: getInitialValues({
                samplingType,
                deviceToken: devices?.[0]?.token,
                frequency: formValues.frequency,
            }),
            validationSchema: getValidationSchema(samplingType),
        }),
        [samplingType],
    );

    return { initialValues, validationSchema };
};
