import { TABS_ACTION_TYPES } from './actionTypes';

export const setTabs = (tabs) => ({
    type: TABS_ACTION_TYPES.SET_TABS,
    payload: { tabs },
});

export const setActiveTab = (activeTab) => ({
    type: TABS_ACTION_TYPES.SET_ACTIVE_TAB,
    payload: { activeTab },
});
