import PropTypes from 'prop-types';

import * as Styled from './styled';

export const CollapsibleTag = ({ title, subTitle, type }) => (
    <Styled.Tag $type={type}>
        {title}
        { subTitle && (
            <Styled.Subtitle>{subTitle}</Styled.Subtitle>
        )}
    </Styled.Tag>
);

CollapsibleTag.propTypes = {
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
    type: PropTypes.oneOf(['error', 'success', 'warning', 'info']),
};

CollapsibleTag.defaultProps = {
    type: 'error',
    subTitle: null,
};
