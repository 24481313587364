import styled from 'styled-components';

export const Title = styled.div`
    color: ${({ theme }) => theme.colors.title};
    margin-top: 40px;
    margin-bottom: 14px;
    font-size: 22px;
    font-weight: bold;
`;

export const Tip = styled.div`
    margin-bottom: 55px;
    color: #828282;
    display: flex;
    vertical-align: middle;
    font-size: 16px;

    svg {
        line-height: 1;
        margin-right: 14px;
        font-size: 20px;
    }
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    margin-top: 110px;
    
    & > *:nth-child(2) {
        margin-left: 30px;
    }
`;
