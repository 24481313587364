import PropTypes from 'prop-types';

import { CUSTOM_PAGINATION } from 'Constants';

import { Select } from '../select';

import * as Styled from './styled';

export const RangeDisplayItems = ({
    title,
    handleRange,
    selectedItem,
    limits,
}) => (
    <Styled.Range>
        <Styled.RangeTitle>{title}</Styled.RangeTitle>
        <Select
            custom
            size="small"
            values={limits}
            selectedItem={selectedItem}
            handleSelect={handleRange}
            borderColor="grey"
        />
    </Styled.Range>
);

RangeDisplayItems.propTypes = {
    title: PropTypes.string.isRequired,
    handleRange: PropTypes.func.isRequired,
    selectedItem: PropTypes.number,
    limits: PropTypes.array,
};

RangeDisplayItems.defaultProps = {
    selectedItem: 10,
    limits: CUSTOM_PAGINATION,
};
