import styled from 'styled-components';

export const EmptyPymesh = styled.div`
    margin: 50px 0px;
    box-shadow: 0 2px 5px 0 rgba(50, 50, 50, 0.2);
    border-radius: 5px;
    padding: 20px;
    white-space: nowrap;
    display: inline-block;
    width: 100%;

    h3 {
        font-weight: bold;
    }
`;
