import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { v4 } from 'uuid';

import { showToastError, showToastSuccess } from 'Utils';
import {
    SET_ACTIVATION_TOKEN,
    GET_ACTIVATION_TOKEN_QUERY,
    TYPE_GENERATION,
    OFFLINE,
} from 'Constants';

import { Button } from '../../buttons';
import * as Styled from './styled';

const ACTIVATION_TOKEN_EXPIRED = 'Activation token expired';
const ACTIVATION_STRING_EXPIRED = 'Activation string expired';
const GENERATION_TOKEN = 'Generate token';
const GENERATION_STRING = 'Generate string';

export const GenerationToken = ({ deviceToken, type }) => {
    const [generateToken, { loading: isLoading }] = useMutation(SET_ACTIVATION_TOKEN, {
        refetchQueries: [GET_ACTIVATION_TOKEN_QUERY],
    });

    const text =
        type === TYPE_GENERATION.OFFLINE
            ? ACTIVATION_TOKEN_EXPIRED
            : ACTIVATION_STRING_EXPIRED;

    const buttonText =
        type === TYPE_GENERATION.OFFLINE ? GENERATION_TOKEN : GENERATION_STRING;

    const actionGenerateToken = async () => {
        const activationToken = v4();
        try {
            generateToken({
                variables: {
                    activationToken,
                    deviceToken,
                },
                update: () => {
                    showToastSuccess(`${buttonText} was successful.`);
                },
            });
        } catch (error) {
            showToastError(error.message);
        }
    };

    return (
        <Styled.Generation>
            <Styled.Text>
                {text}
            </Styled.Text>
            <Button onClick={actionGenerateToken} loading={isLoading}>
                {buttonText}
            </Button>
        </Styled.Generation>
    );
};

GenerationToken.propTypes = {
    deviceToken: PropTypes.string.isRequired,
    type: PropTypes.string,
};

GenerationToken.defaultProps = {
    type: OFFLINE,
};
