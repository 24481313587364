import styled from 'styled-components';

export const Item = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Top = styled.div`
    display: flex;
    align-items: center;
`;

export const Title = styled.div`
    display: flex;
    align-items: center;

    & div:last-child {
        margin: 0 71px 0 20px
    }

    ${({ $withIcon }) => !$withIcon && `
        & div:last-child {
            margin-left: 0; 
        }
    `}
`;

export const Bottom = styled.div`
    margin-top: 20px;
`;

export const PageTitle = styled.div`
    color: ${({ theme }) => theme.colors.title};
    font-size: 36px;
    font-weight: bold;
`;

export const PageDescription = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-size: 16px;
`;
