import PropTypes from 'prop-types';

import * as Styled from './styled';

export const TextArea = ({
    disabled,
    field,
    form: { errors, touched },
    placeholder,
    errorPosition,
    rows,
}) => (
    <Styled.Wrapper>
        <Styled.Field
            placeholder={placeholder}
            value={field.value}
            name={field.name}
            onChange={field.onChange}
            onBlur={field.onBlur}
            disabled={disabled}
            rows={rows}
        />
        {!disabled && touched[field.name] && (
            <Styled.Error $position={errorPosition}>
                {errors[field.name]}
            </Styled.Error>
        )}
    </Styled.Wrapper>
);

TextArea.propTypes = {
    disabled: PropTypes.bool,
    form: PropTypes.object,
    field: PropTypes.object,
    placeholder: PropTypes.string,
    errorPosition: PropTypes.string,
    rows: PropTypes.number,
};

TextArea.defaultProps = {
    disabled: false,
    form: {},
    field: {},
    placeholder: '',
    errorPosition: 'right',
    rows: 1,
};
