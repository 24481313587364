import PropTypes from 'prop-types';
import { Switch } from 'antd';

import * as Styled from './styled';

export const SwitchButton = ({ title, checked, onChange }) => (
    <Styled.Wrapper>
        <Switch checked={checked} onChange={onChange} />
        <Styled.Title>
            {title}
        </Styled.Title>
    </Styled.Wrapper>
);

SwitchButton.defaultProps = {
    checked: false,
};

SwitchButton.propTypes = {
    title: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
};
