import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';

import { GET_APPLICATIONS } from 'Constants';
import { Loader } from 'Components';

import { ProjectsTable, AddScreen } from './components';

export const AllProjects = () => {
    const [initialLoading, setInitialLoading] = useState(true);

    const { data, loading } = useQuery(GET_APPLICATIONS, { fetchPolicy: 'cache-and-network' });
    const projects = data?.getApplications ?? [];

    useEffect(
        () => {
            if (initialLoading && !loading) {
                setInitialLoading(false);
            }
        },
        [loading],
    );

    if (initialLoading) {
        return <Loader />;
    }

    let content = null;

    if (!projects.length) {
        content = <AddScreen />;
    } else {
        content = <ProjectsTable data={projects} />;
    }

    return (
        <div data-cy="projects-page-wrapper">
            {content}
        </div>
    );
};
