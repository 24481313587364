import styled from 'styled-components';

export const ModalTitle = styled.div`
    width: 100%;
    height: 50px;
    padding: 15px 20px;
    background-color: ${({ theme }) => theme.colors.title};
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
`;

export const ModalBody = styled.div`
    padding: 15px 20px 30px 25px;

    button {
        margin-top: 25px;
        text-transform: uppercase;
    }
`;
