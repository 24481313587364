import * as Yup from 'yup';

import { ValidationService } from 'Services';

export const initialValues = {
    name: '',
};

export const getValidationSchema = (checkIsNameNotDuplicated) => Yup.object().shape({
    name: Yup.string()
        .test('name-duplication', ValidationService.duplicatedNameError, checkIsNameNotDuplicated)
        .required(ValidationService.getRequiredError('name')),
});
