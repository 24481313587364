import { ReactComponent as Forum } from 'Assets/icons/forum.svg';
import { ReactComponent as Docs } from 'Assets/icons/docs.svg';
import { ReactComponent as WebShop } from 'Assets/icons/web-shop.svg';
import { ReactComponent as TermsOfService } from 'Assets/icons/terms-service.svg';
import { ReactComponent as PrivacyPolicy } from 'Assets/icons/privacy.svg';

export const linkRow = [
    {
        title: 'Forum',
        hrefs: [process.env.REACT_APP_LINK_FORUM],
        icon: Forum,
    },
    {
        title: 'Docs',
        hrefs: [process.env.REACT_APP_LINK_DOCS],
        icon: Docs,
    },
    {
        title: 'Web Shop',
        hrefs: [process.env.REACT_APP_LINK_WEBSHOP],
        icon: WebShop,
    },
    {
        title: 'Terms of Service',
        hrefs: [process.env.REACT_APP_LINK_TERMS_OF_SERVICE],
        icon: TermsOfService,
    },
    {
        title: 'Privacy Policy',
        hrefs: [process.env.REACT_APP_LINK_PRIVACY_POLICY],
        icon: PrivacyPolicy,
    },
];
