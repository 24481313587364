import * as uuid from 'uuid';

import { fileToBase64 } from 'Utils';
import { DEFAULT_FIRMWARE_FILENAME } from 'Constants';

export const transformReleaseDataForCreation = async (project, formValues) => {
    const { ssid, password, secureType } = project.wifi || {};
    const { carrier, cid, band, apn, reset, protocol } = project.lte || {};

    let fileb64 = formValues.isBase64 ? formValues.file : null;
    let originalFilename = null;

    if (formValues.file) {
        if (!formValues.isBase64) {
            fileb64 = await fileToBase64(formValues.file);
        }

        originalFilename = formValues.file.name || 'pymakr.zip';
    }

    let customFirmwares = [];

    if (formValues.customFirmwares || formValues.compilationFile) {
        const customBase64FilesPromises = formValues.customFirmwares.map(
            (fileData) => {
                if (fileData.fileb64 && formValues.compilationFile?.fileb64 === fileData.fileb64) {
                    return fileData.fileb64;
                }
                return fileToBase64(fileData.file);
            },
        );

        const customBase64Files = await Promise.all(customBase64FilesPromises);
        customFirmwares = formValues.customFirmwares.map((fileData, index) => {
            const firmware = {
                fileb64: customBase64Files[index],
                firmwareFilename: uuid.v4(),
                firmwareType: fileData.deviceType,
                originalFilename: fileData.file.name || DEFAULT_FIRMWARE_FILENAME,
            };

            if (formValues.compilationId && firmware.fileb64 === formValues.compilationFile) {
                firmware.compilationId = formValues.compilationId;
            }
            return firmware;
        });
    }

    return {
        applicationId: project._id,
        networkPreferences: project.networks,
        wifi: project.wifi ? { ssid, password, secureType } : null,
        lte: project.lte
            ? {
                carrier,
                cid,
                band,
                apn,
                reset: !!reset,
                protocol,
            } : null,
        loraType: project.loraType,
        loraServer: project.loraServer,
        // loraRegion: project.loraRegion || null,
        firmware: formValues.firmware || '',
        summary: formValues.description,
        fileb64,
        originalFilename,
        codeFilename: uuid.v4(),
        customFirmwares,
    };
};

export const getCustomFirmwareObject = (compiledFile) => {
    if (!compiledFile) {
        return null;
    }

    return {
        fileb64: compiledFile,
        firmwareFilename: uuid.v4(),
        firmwareType: 'custom',
        originalFilename: 'app.bin',
        file: {
            name: 'app.bin',
        },
    };
};
