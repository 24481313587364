import * as Yup from 'yup';

import { ValidationService } from 'Services';

export const initialValues = {
    thingGroupName: '',
    messagesTopic: '',
};

export const validationSchema = Yup.object().shape({
    thingGroupName: Yup.string()
        .matches(/^([A-Za-z0-9:_-]{1,128})$/, 'Thing Group Name invalid: between 1 and 128 [ alphanumeric : _ / ] characters.')
        .required(ValidationService.getRequiredError('things group name')),
    messagesTopic: Yup.string()
        .matches(/^([A-Za-z0-9/_-]{1,256})$/, 'Topic invalid: between 1 and 256 [ alphanumeric - _ /] characters.')
        .required(ValidationService.getRequiredError('messages topic')),
    devices: Yup.array().compact().min(1, 'Please select at least one device'),
});
