import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    position: relative;
`;

const errorRightStyles = css`
    margin-left: 10px;
`;

const errorBottomStyles = css`
    left: 0;
    bottom: -15px;
`;

export const Error = styled.span`
    position: absolute;
    margin-left: 0;
    transition: margin-left 0.4ms linear;
    transition: bottom 0.4s linear;
    color: ${({ theme }) => theme.colors.error};
    font-size: 12px;

    ${({ $position }) => {
        if ($position === 'right') {
            return errorRightStyles;
        }

        if ($position === 'bottom') {
            return errorBottomStyles;
        }

        return '';
    }}
`;

export const Field = styled.textarea`
    width: 293px;
    min-height: 40px;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    padding: 9px 15px;
    color: ${({ theme }) => theme.colors.text};
    resize: none;
    font-size: 14px;
    height: 1.4;

    ${({ disabled }) => disabled && `
        border: transparent;
        cursor: not-allowed;
    `}
`;
