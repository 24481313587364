import { Formik } from 'formik';
import PropTypes from 'prop-types';

import { GoogleService } from 'Services';
import { showToastError } from 'Utils';

import { initialValues, validationSchema } from './config';
import { FormContent } from './formContent';

export const ProjectSetup = ({
    nextStep,
    setGoogleService,
    accountData,
    setRegistries,
    formValues,
}) => {
    const combinedInitialValues = {
        region: formValues.region || initialValues.region,
        projectId: formValues.projectId || initialValues.projectId,
    };

    const onSubmit = async (values) => {
        try {
            const { projectId, region } = values;

            const googleService = new GoogleService({
                accessToken: accountData.accessToken,
                projectId,
                region,
            });

            setGoogleService(googleService);

            const registries = await googleService.listRegistries();

            if (registries.error) {
                throw new Error(registries.error);
            }

            setRegistries(registries);

            nextStep(values);
        } catch (error) {
            showToastError(error.message);
        }
    };

    return (
        <Formik
            initialValues={combinedInitialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {(props) => <FormContent {...props} />}
        </Formik>
    );
};

ProjectSetup.propTypes = {
    nextStep: PropTypes.func.isRequired,
    setGoogleService: PropTypes.func.isRequired,
    accountData: PropTypes.object,
    setRegistries: PropTypes.func.isRequired,
    formValues: PropTypes.object,
};

ProjectSetup.defaultProps = {
    accountData: {},
    formValues: {},
};
