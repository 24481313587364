import { PYMESH_REGIONS, PYMESH_BANDWIDTHS } from 'Constants';

export const getInitialValues = (pymesh) => {
    if (!pymesh) {
        return {};
    }

    const regionInfo = PYMESH_REGIONS.find((item) => item.region.value === `${pymesh.region}`);
    const bandwidthInfo = PYMESH_BANDWIDTHS.find((item) => item.value === `${pymesh.bandwidth}`);

    return {
        region: regionInfo.region.name,
        frequency: `${pymesh.frequency}`,
        bandwidth: bandwidthInfo.label,
        spreadFactor: `${pymesh.spreadFactor}`,
        joinKey: pymesh.joinKey,
    };
};
