import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    margin-top: 20px;
`;

export const HeaderText = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-size: 16px;
`;

export const ButtonsWrapper = styled.div`
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
    a {
        margin-right: 1%;
    }
`;

export const IconWrapper = styled.span`
    font-size: 20px;
    vertical-align: middle;
    cursor: pointer;
`;

export const PasswordValue = styled.span`
    margin-left: 5px;
    vertical-align: middle;
`;

export const NoSimCardsBlock = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
        margin-top: 50px;
    }

    & > button {
        margin-top: 49px;
    }
`;

// BLOCK ADAPTED FROM Button component stylesheet (this link button does not exist in the app yet) ==> TO DO
/* const LinkColors = {
    background: {
        filled: 'active',
        transparent: 'transparent',
    },
    border: {
        filled: 'active',
        transparent: 'border',
    },
    text: {
        filled: 'activeText',
        transparent: 'title',
    },
}; */
/* const colorStyles = css`
    background-color: ${({ theme, $buttonType }) => theme.colors[LinkColors.background[$buttonType]]
        || $buttonType};
    border: 1px solid ${({ theme, $buttonType }) => theme.colors[LinkColors.border[$buttonType]]};
    color: ${({ theme, $buttonType }) => theme.colors[LinkColors.text[$buttonType]]};
`; */

export const BuySimCardLink = styled.a`
    height: auto;
    display: flex;
    align-items: center;
    padding: 10px 30px;
    border-radius: 4px;

    /* should use  rules above */
    background-color: transparent;
    border: 1px solid ${({ theme }) => theme.colors.border};

    cursor: pointer;
    justify-content: space-between;
    font-size: 14px;
    svg { font-size: 20px; margin-left: 5px; }
`;
