import { useMemo, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useQuery } from '@apollo/client';
import mqtt from 'mqtt';

import { NoData, ModelDeviceSelection } from 'Components';
import { showToastSuccess, showToastError } from 'Utils';
import {
    GET_USER_PROFILE_QUERY,
    COMMAND_DEPLOY_MODEL,
    TYPE_PYBYTES,
    TYPE_MASK,
} from 'Constants';

const aedesWebSockerUrl = process.env.REACT_APP_MQTT_SERVER_AEDES_WEB_SOCKET_URL;

export const Deployment = ({ model }) => {
    const { data } = useQuery(GET_USER_PROFILE_QUERY);
    const user = data?.getUserProfile ?? {};

    const [isLoading, setIsLoading] = useState(false);
    const uploadsRef = useRef([]);
    const socketsRef = useRef([]);
    const timeoutsRef = useRef([]);

    const tfLiteModel = useMemo(
        () => {
            const modelDefinition = JSON.parse(model.modelDefinition);
            const nnBlock = modelDefinition?.blocks.find((b) => b.id === 'NN');

            if (!nnBlock?.trained_nn_model) {
                return {};
            }

            return nnBlock.trained_nn_model.tf_lite_model;
        },
        [model],
    );

    const clearTimeoutRef = (index) => {
        const selectedTimeout = timeoutsRef.current?.[index];

        if (selectedTimeout) {
            clearTimeout(selectedTimeout);
        }
    };

    const closeSocketConnection = (index) => {
        const selectedSocket = socketsRef.current?.[index];

        if (selectedSocket) {
            selectedSocket.end();
        }
    };

    useEffect(
        () => () => socketsRef.current?.map((_, index) => closeSocketConnection(index)),
        [],
    );

    const addUploadFinish = (index) => {
        uploadsRef.current[index] = true;

        if (uploadsRef.current.every((finished) => finished)) {
            setIsLoading(false);
        }
    };

    const onDeploy = ({ devices }) => {
        setIsLoading(true);

        const name = user.owner;
        const parameters = JSON.stringify({ modelId: model._id });

        const body = new Uint8Array(2 + parameters.length);
        body[0] = COMMAND_DEPLOY_MODEL;

        for (let i = 0; i < parameters.length; i += 1) {
            body[i + 2] = parameters.charCodeAt(i);
        }

        const messagePayload = new Uint8Array(1 + body.length);

        const USER_SYSTEM_MASK = 0x80; // 1000 0000
        let header = USER_SYSTEM_MASK;
        // eslint-disable-next-line no-bitwise
        header |= (TYPE_PYBYTES & TYPE_MASK);
        messagePayload[0] = header;

        for (let i = 0; i < body.length; i++) {
            messagePayload[i + 1] = body[i];
        }

        uploadsRef.current = new Array(devices.length).fill(false);

        devices.forEach((device, index) => {
            const token = device.token;
            const mqttClientOptions = {
                clean: true,
                username: name,
                password: token,
            };
            const client = mqtt.connect(aedesWebSockerUrl, mqttClientOptions);

            socketsRef.current[index] = client;

            client.on('connect', () => {
                client.unsubscribe(`u${token}/deploymlmodel`);
                client.subscribe(`u${token}/deploymlmodel`);
                client.publish(`d${token}`, Buffer.from(messagePayload), { qos: 0 }, (err) => {
                   if (err) {
                      console.log(`Error: ${err.message}`);
                   }
                });
            });

            client.on('message', async (topic, recvMessage) => {
                if (recvMessage[1] === 2) {
                    showToastSuccess(`Model deployed to ${device.token} device successfully`);
                } else {
                    showToastError(`Model not deployed to ${device.token} device`);
                }

                addUploadFinish(index);

                clearTimeoutRef(index);
                client.end();
            });

            clearTimeoutRef();

            timeoutsRef.current[index] = setTimeout(
                () => {
                    showToastError(`Model not deployed to ${device.token} device. Check your device connectivity`);
                    clearTimeoutRef(index);
                    closeSocketConnection(index);
                },
                120000,
            );
        });
    };

    if (isEmpty(tfLiteModel)) {
        return (
            <NoData
                caption="The model is not available yet"
                text="Follow the steps - Data acquisition, training, testing - in order to deploy the model."
            />
        );
    }

    return (
        <ModelDeviceSelection
            nextStep={onDeploy}
            formValues={{}}
            isLoading={isLoading}
            excludeDevices={model.devices}
            hideBackButton
            hideTitle
        />
    );
};

Deployment.propTypes = {
    model: PropTypes.object.isRequired,
};
