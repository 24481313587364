import styled from 'styled-components';

import { hexToRgb } from 'Utils';

export const Title = styled.div`
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: ${({ theme }) => theme.colors.title};
`;

export const Wrapper = styled.div`
    border: 1px solid
        rgba(${({ theme }) => hexToRgb(theme.colors.border)}, 0.89);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 15px 22px;
    margin-top: 10px;
`;

export const Header = styled.div`
    display: flex;
    align-item: center;
    justify-content: space-between;
`;

export const Connection = styled.div`
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: rgba(${({ theme }) => hexToRgb(theme.colors.active)}, 0.89);
`;

export const ConnectionSuccess = styled.div`
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    color: rgba(${({ theme }) => hexToRgb(theme.colors.success)}, 0.89);
`;

export const Description = styled.div`
    font-size: 14px;
    line-height: 17px;
    color: ${({ theme }) => theme.colors.title};
`;

export const Info = styled.div`
    padding: 10px;
    background: ${({ theme }) => theme.colors.tableHeader};
    max-width: 480px;
    margin: 5px 0;
`;

export const DataFirmware = styled.div`
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
`;

export const MacAddress = styled.div`
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
`;
