export const positiveAdjectives = [
    'Abiding',
    'Abounding',
    'Academic',
    'Accepting',
    'Accessible',
    'Acclaimed',
    'Accommodating',
    'Accountable',
    'Accurate',
    'Actionable',
    'Active',
    'Actual',
    'Adaptive',
    'Adequate',
    'Admirable',
    'Adored',
    'Advantageous',
    'Advisable',
    'Affectionate',
    'Agreeable',
    'Alert',
    'Alluring',
    'Amazed',
    'Amazing',
    'Amicable',
    'Amusing',
    'Appealing',
    'Appetizing',
    'Appreciative',
    'Approachable',
    'Appropriate',
    'Ardent',
    'Artful',
    'Artistic',
    'Aspiring',
    'Assertive',
    'Assured',
    'Assuring',
    'Astonishing',
    'Astounding',
    'Astronomical',
    'Astute',
    'Athletic',
    'Attainable',
    'Attractive',
    'Auspicious',
    'Authoritative',
    'Avid',
    'Awake',
    'Aware',
    'Banging',
    'Beaming',
    'Bedazzled',
    'Bedazzling',
    'Believable',
    'Beneficial',
    'Bewildering',
    'Bewitching',
    'Bilingual',
    'Blameless',
    'Blazing',
    'Blessed',
    'Blissful',
    'Blooming',
    'Blossoming',
    'Blushing',
    'Bodacious',
    'Boisterous',
    'Bold',
    'Bouncy',
    'Boundless',
    'Boyish',
    'Brainy',
    'Brave',
    'Brawny',
    'Brazen',
    'Breathtaking',
    'Breezy',
    'Bright',
    'Buoyant',
    'Bustling',
    'Calming',
    'Capable',
    'Captivated',
    'Captivating',
    'Carefree',
    'Careful',
    'Caring',
    'Cautious',
    'Ceaseless',
    'Celebrated',
    'Celestial',
    'Centered',
    'Cerebral',
    'Ceremonial',
    'Certified',
    'Changeable',
    'Charismatic',
    'Charitable',
    'Charmed',
    'Charming',
    'Chatty',
    'Cheeky',
    'Cheerful',
    'Cheery',
    'Chivalrous',
    'Choice',
    'Chuckling',
    'Cinematic',
    'Civilized',
    'Classic',
    'Classical',
    'Clean',
    'Clear',
    'Clearheaded',
    'Clever',
    'Close',
    'Coachable',
    'Cognitive',
    'Coherent',
    'Cohesive',
    'Collaborative',
    'Collegial',
    'Colloquial',
    'Colorful',
    'Comfortable',
    'Comforted',
    'Comforting',
    'Commanding',
    'Commemorative',
    'Commendable',
    'Committed',
    'Communicative',
    'Compact',
    'Compassionate',
    'Compelling',
    'Competent',
    'Complete',
    'Completed',
    'Complimentary',
    'Comprehensive',
    'Conceivable',
    'Concerted',
    'Concise',
    'Confidential',
    'Confirmable',
    'Confirmed',
    'Congruent',
    'Connected',
    'Conscientious',
    'Conscious',
    'Conservative',
    'Consistent',
    'Consoling',
    'Conspiratorial',
    'Constant',
    'Constructive',
    'Contemplative',
    'Contemporary',
    'Contributive',
    'Convenient',
    'Conventional',
    'Conversational',
    'Convincing',
    'Coolest',
    'Cooperative',
    'Coordinated',
    'Copious',
    'Coquettish',
    'Correctable',
    'Corresponding',
    'Cosmic',
    'Courageous',
    'Courteous',
    'Coveted',
    'Creative',
    'Credible',
    'Crisp',
    'Crucial',
    'Crunchy',
    'Cuddly',
    'Cultured',
    'Cunning',
    'Curable',
    'Curative',
    'Curious',
    'Current',
    'Customary',
    'Cute',
    'Dainty',
    'Dapper',
    'Daring',
    'Dashing',
    'Dazzling',
    'Dear',
    'Debonair',
    'Decadent',
    'Decisive',
    'Decorative',
    'Decorous',
    'Dedicated',
    'Deep',
    'Defendable',
    'Defensible',
    'Definable',
    'Definitive',
    'Delectable',
    'Deliberate',
    'Deliberative',
    'Delicious',
    'Delighted',
    'Delightful',
    'Demonstrative',
    'Demure',
    'Dependable',
    'Designer',
    'Desirous',
    'Detailed',
    'Devoted',
    'Devout',
    'Didactic',
    'Dignified',
    'Diplomatic',
    'Direct',
    'Disciplined',
    'Distinct',
    'Distinguished',
    'Distracting',
    'Diverse',
    'Diversified',
    'Dizzying',
    'Doable',
    'Dogged',
    'Dogmatic',
    'Domestic',
    'Dominant',
    'Doting',
    'Dreamy',
    'Dressy',
    'Driven',
    'Dutiful',
    'Dynamic',
    'Early',
    'Earnest',
    'Earthy',
    'Eccentric',
    'Economical',
    'Ecstatic',
    'Edgy',
    'Educated',
    'Educational',
    'Effervescent',
    'Effortless',
    'Effusive',
    'Egalitarian',
    'Elaborate',
    'Electric',
    'Electrifying',
    'Elegant',
    'Elevated',
    'Eligible',
    'Elite',
    'Eloquent',
    'Emblematic',
    'Emboldened',
    'Empirical',
    'Enamored',
    'Enchanted',
    'Enchanting',
    'Encouraged',
    'Encouraging',
    'Endearing',
    'Endurable',
    'Enduring',
    'Energizing',
    'Enforceable',
    'Engrossing',
    'Enhanced',
    'Enigmatic',
    'Enjoyable',
    'Enlightened',
    'Enough',
    'Enterprising',
    'Entertained',
    'Entertaining',
    'Enthralled',
    'Enthused',
    'Enthusiastic',
    'Enticing',
    'Entire',
    'Entranced',
    'Entrepreneurial',
    'Enumerable',
    'Enviable',
    'Equitable',
    'Essential',
    'Established',
    'Eternal',
    'Ethereal',
    'Ethical',
    'Euphoric',
    'Even',
    'Eventful',
    'Everlasting',
    'Evocative',
    'Exact',
    'Exalted',
    'Exceptional',
    'Excitable',
    'Excited',
    'Exciting',
    'Exclusive',
    'Executable',
    'Exhaustive',
    'Exhilarated',
    'Exhilarating',
    'Exotic',
    'Expansive',
    'Expectant',
    'Expected',
    'Expedient',
    'Expeditious',
    'Expensive',
    'Expert',
    'Express',
    'Expressionistic',
    'Expressive',
    'Exquisite',
    'Extensive',
    'Extraordinary',
    'Extravagant',
    'Exuberant',
    'Fabled',
    'Factual',
    'Famous',
    'Fascinated',
    'Fascinating',
    'Fashionable',
    'Fastidious',
    'Fateful',
    'Fatherly',
    'Fathomable',
    'Favorable',
    'Fearless',
    'Fertile',
    'Fervent',
    'Fetching',
    'Fierce',
    'Fiery',
    'Fine',
    'Firm',
    'First',
    'Fit',
    'Flamboyant',
    'Flashy',
    'Flattered',
    'Flattering',
    'Flavorful',
    'Flawless',
    'Flexible',
    'Flowery',
    'Fluent',
    'Fluffy',
    'Focused',
    'Fond',
    'Foreseeable',
    'Forgivable',
    'Forgiving',
    'Formal',
    'Formative',
    'Formidable',
    'Forthright',
    'Fortuitous',
    'Fortunate',
    'Forward',
    'Foxy',
    'Fragrant',
    'Frank',
    'Fraternal',
    'Free',
    'Fresh',
    'Friendly',
    'Frisky',
    'Fulfilled',
    'Fulfilling',
    'Full',
    'Fun',
    'Functional',
    'Fundamental',
    'Futuristic',
    'Gainful',
    'Gallant',
    'Game',
    'Generous',
    'Genial',
    'Genuine',
    'Giddy',
    'Gifted',
    'Giggling',
    'Giving',
    'Glaring',
    'Glittering',
    'Glorious',
    'Glossy',
    'Glowing',
    'Golden',
    'Graceful',
    'Gracious',
    'Grandiose',
    'Gratified',
    'Gratifying',
    'Greatest',
    'Grinning',
    'Gripping',
    'Groomed',
    'Groovy',
    'Guaranteed',
    'Guiltless',
    'Gutsy',
    'Hallowed',
    'Handsome',
    'Hardworking',
    'Hardy',
    'Harmless',
    'Harmonic',
    'Harmonious',
    'Heady',
    'Hearty',
    'Heavenly',
    'Helpful',
    'Heroic',
    'Historical',
    'Holistic',
    'Homey',
    'Honorable',
    'Hopeful',
    'Huggable',
    'Human',
    'Humble',
    'Idealistic',
    'Illuminating',
    'Illustrious',
    'Immaculate',
    'Immense',
    'Immortal',
    'Immovable',
    'Impartial',
    'Impassioned',
    'Impenetrable',
    'Impervious',
    'Important',
    'Impressive',
    'Improved',
    'Improving',
    'Inalienable',
    'Incandescent',
    'Inclusive',
    'Incomparable',
    'Incontrovertible',
    'Industrious',
    'Influential',
    'Informative',
    'Informed',
    'Ingenuous',
    'Inhabitable',
    'Innovative',
    'Inquisitive',
    'Insightful',
    'Invaluable',
    'Inviting',
    'Iridescent',
    'Joyful',
    'Kindhearted',
    'Knowing',
    'Laudable',
    'Learned',
    'Likeable',
    'Loveable',
    'Lovely',
    'Loving',
    'Maternal',
    'Meaningful',
    'Meditative',
    'Merry',
    'Miraculous',
    'Motivational',
    'Moving',
    'Naughty',
    'Neighborly',
    'Notable',
    'Nutritious',
    'Observant',
    'Optimistic',
    'Parental',
    'Paternal',
    'Patient',
    'Peerless',
    'Perceptive',
    'Personable',
    'Phenomenal',
    'Playful',
    'Precocious',
    'Premium',
    'Pretty',
    'Productive',
    'Proud',
    'Pure',
    'Resilient',
    'Reverent',
    'Romantic',
    'Scrupulous',
    'Seemly',
    'Sociable',
    'Stimulating',
    'Straightforward',
    'Suave',
    'Successful',
    'Talented',
    'Tasteful',
    'Thrifty',
    'Upright',
    'Valiant',
    'Winning',
    'Zestful',
];
