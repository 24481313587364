import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client'; 

import { Table, Button, LogsPagination, Loader } from 'Components';
import { GET_USER_LOGS_QUERY } from 'Constants';
import { ReactComponent as Arrow } from 'Assets/icons/arrowRight.svg';

import { columns, statuses, dateRanges, filterTypes } from './constants';
import { getFilterDropdownOptions, createFilter } from './helpers';
import * as Styled from './styled';

export const UserLogs = () => {
    const [sortBy, setSortBy] = useState({ createdAt: -1 });
    const [filters, setFilters] = useState({
        status: statuses.NONE,
        date: dateRanges.NONE,
    });
    const [initialLoading, setInitialLoading] = useState(true);

    const { data, loading, refetch } = useQuery(GET_USER_LOGS_QUERY, { fetchPolicy: 'network-only' });
    const logs = data?.getLogsByUser?.data;
    const size = data?.getLogsByUser?.size || 10;
    const page = data?.getLogsByUser?.page || 1;
    const count = data?.getLogsByUser?.count || 0;

    useEffect(
        () => {
            if (initialLoading && !loading) {
                setInitialLoading(false);
            }
        },
        [loading],
    );

    const onFilterSelect = (targetFilter, filterOption) => {
        const currentFilters = { ...filters };

        if (targetFilter === filterTypes.STATUS) {
            currentFilters.status = filterOption;
        } else if (targetFilter === filterTypes.DATE) {
            currentFilters.date = filterOption;
        }

        setFilters(currentFilters);

        const filter = createFilter(currentFilters);

        const parameters = {
            size,
            page,
            sortBy,
            filter,
        };

        refetch(parameters);
    };

    const onSort = () => {
        const newSort = sortBy.createdAt * -1;

        const parameters = {
            size,
            page: 1,
            sortBy: { createdAt: newSort },
            filter: createFilter(filters),
        };

        setSortBy(parameters.sortBy);
        refetch(parameters);
    };

    const statusDropdownOptions = getFilterDropdownOptions(filterTypes.STATUS, statuses, onFilterSelect);
    const datesDropdownOptions = getFilterDropdownOptions(filterTypes.DATE, dateRanges, onFilterSelect);

    if (initialLoading) {
        return <Loader />;
    }

    const dropdownBtn = (type) => (
        <Styled.DropdownIcon>
            <Styled.DropdownTitle>
                {filters[type]}
            </Styled.DropdownTitle>
            <Styled.ArrowDown>
                <Arrow />
            </Styled.ArrowDown>
        </Styled.DropdownIcon>
    );

    const pagination = {
        size,
        page,
        count,
    };

    return (
        <>
            <Styled.FiltersBar>
                <Styled.FilterWrapper>
                    <Styled.FilterTitle>
                        <h3>Status:</h3>
                    </Styled.FilterTitle>
                    <Styled.Dropdown
                        id="status"
                        actions={statusDropdownOptions}
                        Icon={() => dropdownBtn(filterTypes.STATUS)}
                        className="dropdownBtn"
                    />
                </Styled.FilterWrapper>
                <Styled.FilterWrapper>
                    <Styled.FilterTitle>
                        <h3>Date:</h3>
                    </Styled.FilterTitle>
                    <Styled.Dropdown
                        id="date"
                        actions={datesDropdownOptions}
                        Icon={() => dropdownBtn(filterTypes.DATE)}
                        className="dropdownBtn"
                    />
                </Styled.FilterWrapper>
                <Styled.SortButton>
                    <Button
                        onClick={onSort}
                        buttonType="transparent"
                    >
                        Sort by Date
                    </Button>
                </Styled.SortButton>
            </Styled.FiltersBar>
            <Styled.Table>
                <Table
                    columns={columns}
                    data={logs}
                />
                <LogsPagination
                    filter={createFilter(filters)}
                    sortBy={sortBy}
                    onChange={refetch}
                    pagination={pagination}
                />
            </Styled.Table>
        </>
    );
};
