import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { PageHeader, Tile } from 'Components';

import { linkRow as links } from './config';

import * as Styled from './styled';

export const Support = () => {
    const styledTheme = useContext(ThemeContext);

    return (
        <>
            <PageHeader
                title="Support"
                description={[
                    `Plug in your ${styledTheme.whiteLabelCompany} device and start writing your
                        application code instantly Questions? Head to Getting Started!`,
                ]}
            />
            <Styled.Tiles>
                {links.map((tile) => (
                    <li key={tile.title}>
                        <Tile
                            key={tile.title}
                            title={tile.title}
                            hrefs={tile.hrefs}
                            icon={<tile.icon />}
                        />
                    </li>
                ))}
            </Styled.Tiles>
        </>
    );
};
