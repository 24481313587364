import styled from 'styled-components';

export const CountWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    font-size: 18px;
    flex-grow: 1;
`;

export const Counter = styled.div`
    span {
        font-size: 60px;
        line-height: 1;
        &::after {
            content: " ";
        }
    }
`;
