import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DEVICE_MESSAGES_BY_CHART_WITH_PIN_QUERY } from 'Constants';

import { getStringifiedPayload, getNetworkIcon } from 'Utils';
import { useMessageSubscription } from 'Hooks';

import { TableWidget } from '../../../../widgets';

export const VirtualList = ({
    chartId,
    widgetStyles,
    pin,
    title,
    deviceToken,
}) => {
    const tableHeader = ['Network', 'Time', 'Value', 'Size'];

    const { data: messagesData, loading, refetch } = useQuery(
        DEVICE_MESSAGES_BY_CHART_WITH_PIN_QUERY,
        {
            variables: {
                chartId,
            },
        },
    );

    useMessageSubscription([deviceToken], pin, refetch);

    const data = useMemo(
        () => {
            if (messagesData?.getDeviceMessagesByChartWithPin?.length) {
                return messagesData.getDeviceMessagesByChartWithPin.map((message) => {
                    const time = moment(message.createdAt).fromNow();
                    const value = getStringifiedPayload(message.payload);
                    const Icon = getNetworkIcon(message.networkType);

                    return {
                        size: message.messageSize,
                        network: <Icon />,
                        time,
                        value,
                    };
                });
            }

            return [];
        },
        [messagesData],
    );

    return (
        <TableWidget
            data={data}
            isEmpty={loading || !data.length}
            title={title}
            widgetStyles={widgetStyles}
            tableHeader={tableHeader}
        />
    );
};

VirtualList.propTypes = {
    pin: PropTypes.number.isRequired,
    widgetStyles: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    deviceToken: PropTypes.string.isRequired,
    chartId: PropTypes.string.isRequired,
};
