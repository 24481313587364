import PropTypes from 'prop-types';

import { Button, ReleaseDetails } from 'Components';
import { DEFAULT_FIRMWARE_FILENAME } from 'Constants';

import * as Styled from './styled';

export const Summary = ({ prevStep, nextStep, formValues, project, isLoading }) => {
    const releaseDetails = {
        networks: project.networks,
        summary: formValues.description,
        firmware: formValues.firmware,
        originalFilename: formValues.file?.name,
    };

    if (project.wifi) {
        const { ssid, password, secureType } = project.wifi;
        releaseDetails.wifi = { ssid, password, secureType };
    }

    if (project.lte) {
        const { apn, carrier, cid, protocol, reset } = project.lte;
        releaseDetails.lte = { apn, carrier, cid, protocol, reset };
    }

    if (project.loraServer) {
        releaseDetails.loraServer = project.loraServer;
        releaseDetails.loraType = project.loraType;

        if (project.loraRegion) {
            releaseDetails.loraRegion = project.loraRegion;
        }
    }

    if (project.ethernet) {
        const { name, autoSettings, ipAddress, subnetMask, router, dnsServer, dnsServer2 } = project.ethernet;
        if (autoSettings) {
            releaseDetails.ethernet = { name, autoSettings };
        } else {
            releaseDetails.ethernet = { name, autoSettings, ipAddress, subnetMask, router, dnsServer, dnsServer2 };
        }
    }

    if (formValues.customFirmwares?.length) {
        releaseDetails.customFirmwares = formValues.customFirmwares.map((fileData) => ({
            originalFilename: fileData.file?.name || DEFAULT_FIRMWARE_FILENAME,
        }));
    }

    if (formValues.isBase64 && formValues.file) {
        releaseDetails.originalFilename = 'pymakr.zip';
    }

    return (
        <>
            <ReleaseDetails release={releaseDetails} />
            <Styled.ButtonsWrapper>
                <Button
                    buttonType="transparent"
                    onClick={prevStep}
                >
                    Back
                </Button>
                <Button onClick={nextStep} loading={isLoading}>
                    Finish
                </Button>
            </Styled.ButtonsWrapper>
        </>
    );
};

Summary.propTypes = {
    prevStep: PropTypes.func.isRequired,
    nextStep: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    project: PropTypes.object,
    isLoading: PropTypes.bool,
};

Summary.defaultProps = {
    project: {},
    isLoading: false,
};
