import gql from 'graphql-tag';

export const CHART_QUERY = gql`
    query getChartByID($chartID: String!) {
        getChartByID(chartID: $chartID) {
          _id
          name
          userName
          deviceToken
          chart
          widgetID
          pinID
          pullSize
          type
          minTimeUnit
          stacked
          drawGridOnGraph
          steppedLine
          fill
          displayPoints
          createdAt
        }
    }
`;

export const DEVICE_CHARTS_QUERY = gql`
    query getChartsByDevice($deviceToken: String!, $pinID: String) {
        getChartsByDevice(deviceToken: $deviceToken, pinID: $pinID) {
          chart {
              _id
              name
              userName
              deviceToken
              chart
              widgetID
              pinID
              pullSize
              type
              minTimeUnit
              stacked
              drawGridOnGraph
              steppedLine
              fill
              displayPoints
              createdAt
          }
          widget {
              _id
               userName
               deviceToken
               width
               height
               x
               y
               createdAt
          }
          pin {
             _id
              userName
              deviceToken
              name
              unit
              pin
              createdAt
          }
        }
    }
`;

export const SAVE_CHART_MUTATION = gql`
    mutation saveChart(
      $_id: String,
      $name: String,
      $userName: String,
      $deviceToken: String!,
      $chart: ChartEnum!,
      $widgetID: String,
      $pinID: String,
      $pullSize: Int,
      $type: String,
      $minTimeUnit: String,
      $stacked: Boolean,
      $drawGridOnGraph: Boolean,
      $steppedLine: Boolean,
      $fill: Boolean,
      $displayPoints: Boolean) {
        saveChart(object: {
          _id: $_id,
          name: $name,
          userName: $userName,
          deviceToken: $deviceToken,
          chart: $chart,
          widgetID: $widgetID,
          pinID: $pinID,
          pullSize: $pullSize,
          type: $type,
          minTimeUnit: $minTimeUnit,
          stacked: $stacked,
          drawGridOnGraph: $drawGridOnGraph,
          steppedLine: $steppedLine,
          fill: $fill,
          displayPoints: $displayPoints}) {
            _id
            name
            userName
            deviceToken
            chart
            widgetID
            pinID
            pullSize
            type
            minTimeUnit
            stacked
            drawGridOnGraph
            steppedLine
            fill
            displayPoints
            createdAt
        }
    }
`;

export const DELETE_CHART_MUTATION = gql`
    mutation deleteChartById($id: String!) {
        deleteChartById(id: $id)
    }
`;

export const DELETE_CHART_WIDGET_MUTATION = gql`
    mutation deleteChartWidget($chartID: String!) {
        deleteChartWidget(chartID: $chartID)
    }
`;

export const CREATE_WIDGET_MUTATION = gql`
    mutation createWidget($width: Int!, $height: Int!, $x: Int!, $y: Int!, $chartID: String) {
        saveWidget(widget: {width: $width, height: $height, x: $x, y: $y}, chartID: $chartID) {
            _id
        }
    }
`;

export const EDIT_WIDGET_MUTATION = gql`
    mutation EditWidget($_id: String!, $width: Int!, $height: Int!, $x: Int!, $y: Int!) {
        saveWidget(widget: {_id: $_id, width: $width, height: $height, x: $x, y: $y}) {
            _id
        }
    }
`;

export const EDIT_MULTIPLE_WIDGETS_MUTATION = gql`
    mutation EditMultipleWidgets($widgets: [WidgetInput]!) {
        saveWidgets(widgets: $widgets)
    }
`;
