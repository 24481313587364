import { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { Breadcrumbs, PageHeader, AzureIntegrationDefinition } from 'Components';
import { showToastError, showToastSuccess, replaceAccents } from 'Utils';
import {
    BREADCRUMBS_LABELS,
    GET_USER_PROFILE_QUERY,
    GET_ALL_DEVICES_QUERY,
    SAVE_AZURE_DEVICES_MUTATION,
    ROUTES,
} from 'Constants';

import { AzureLogin } from './components';

const STEPS = {
    LOGIN: 1,
    DEFINITION: 2,
};

export const AzureIntegration = () => {
    const history = useHistory();

    const [step, setStep] = useState(STEPS.LOGIN);
    const [connectionString, setConnectionString] = useState({});

    const { data: userData } = useQuery(GET_USER_PROFILE_QUERY);
    const user = userData?.getUserProfile ?? {};

    const { data: allDevicesData } = useQuery(
        GET_ALL_DEVICES_QUERY,
        { fetchPolicy: 'cache-and-network' },
    );
    const allDevices = allDevicesData?.device ?? [];

    const [saveAzureDevices, { loading: isLoading }] = useMutation(SAVE_AZURE_DEVICES_MUTATION);

    let content = null;
    let title = '';

    const nextStep = () => {
        setStep(step + 1);
    };

    const onIntegrationSubmit = async (values) => {
        const { devices, name, messagesTopic } = values;

        try {
            const devicesInfo = [];

            devices.forEach((item, index) => {
                if (item) {
                    const selectedDevice = allDevices[index];
                    const devName = replaceAccents(selectedDevice.description).replace(/([^a-z0-9]+)/gi, '-');
                    const deviceId = `${devName}-${selectedDevice.token}`;

                    devicesInfo.push({
                        deviceId,
                        owner: user.owner,
                        topic: messagesTopic,
                        deviceToken: selectedDevice.token,
                    });
                }
            });

            const { data: { saveAzureDevices: { integrationId } } } = await saveAzureDevices({
                variables: {
                    devices: devicesInfo,
                    integrationName: name,
                    iotHubString: connectionString,
                },
            });

            showToastSuccess('Integration created successfully');

            history.push(`${ROUTES.settings.integrations.main}/${integrationId}`);
        } catch (error) {
            showToastError(error.message);
        }
    };

    switch (step) {
        case STEPS.DEFINITION:
            content = (
                <AzureIntegrationDefinition
                    connectionString={connectionString}
                    allDevices={allDevices}
                    onSubmit={onIntegrationSubmit}
                    isLoading={isLoading}
                />
            );
            title = 'New Azure Integration';
            break;
        case STEPS.LOGIN:
        default:
            content = (
                <AzureLogin
                    setConnectionString={setConnectionString}
                    nextStep={nextStep}
                />
            );
            title = 'Login to Azure';
    }

    return (
        <>
            <Breadcrumbs labels={BREADCRUMBS_LABELS.azureIntegration} />
            <PageHeader
                title={title}
                description={['IoT Hub Connection String']}
            />
            {content}
        </>
    );
};
