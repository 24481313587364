import { useEffect } from 'react';
import { Form } from 'formik';
import PropTypes from 'prop-types';

import { HTTP_METHODS, REQUEST_FORMATS } from 'Constants';

import { RequestCode } from '../requestCode';
import { Button } from '../../../buttons';
import { BoxList } from '../../../boxList';

import {
    collapsibleLabels,
    initialValues,
    FIXED_HTTP_HEADERS_JSON,
    FIXED_HTTP_HEADERS_WEBFORM,
} from './config';
import {
    GeneralSettings,
    BodyDefinition,
    QueryParameters,
    HttpHeaders,
    HttpAuthentication,
    DevicesList,
} from './components';

import * as Styled from './styled';

export const FormContent = ({
    values,
    setFieldValue,
    allDevices,
    dirty,
    submitText,
    onCancel,
    isLoading,
}) => {
    useEffect(() => {
        if (!dirty) {
            return;
        }

        if (values.requestType === HTTP_METHODS.GET) {
            setFieldValue(
                'queryParameters',
                [
                    ...values.body.map((item) => ({ ...item, bodyField: true })),
                    ...values.queryParameters,
                ],
            );
            setFieldValue('body', []);
            return;
        }

        const bodyFields = [];
        const queryParamsFields = [];

        values.queryParameters.forEach((item) => {
            const { bodyField, ...rest } = item;
            if (bodyField) {
                bodyFields.push(rest);
                return;
            }

            queryParamsFields.push(rest);
        });

        setFieldValue('body', bodyFields || initialValues.body);
        setFieldValue('queryParameters', queryParamsFields);
    }, [values.requestType]);

    useEffect(() => {
        if (!dirty) {
            return;
        }

        const customHeaders = values.httpHeaders.filter((item) => !item.unchangable);
        let newBaseHeaders = [];
        
        switch (values.requestFormat) {
            case REQUEST_FORMATS.JSON:
                newBaseHeaders = FIXED_HTTP_HEADERS_JSON;
                break;
            case REQUEST_FORMATS.WebForm:
                newBaseHeaders = FIXED_HTTP_HEADERS_WEBFORM;
                break;
            default:
                break;
        }

        setFieldValue('httpHeaders', [...newBaseHeaders, ...customHeaders]);
        setFieldValue('customBody', '');
    }, [values.requestFormat]);

    return (
        <Form>
            <Styled.Content>
                <BoxList labels={collapsibleLabels}>
                    <GeneralSettings />
                    <BodyDefinition values={values} />
                    <QueryParameters />
                    <HttpHeaders />
                    <HttpAuthentication />
                    <RequestCode values={values} />
                    <DevicesList allDevices={allDevices} values={values} />
                </BoxList>
                <Styled.ButtonsWrapper>
                    {onCancel && (
                        <Button
                            buttonType="transparent"
                            onClick={onCancel}
                        >
                            Cancel
                        </Button>
                    )}
                    <Styled.Button
                        type="submit"
                        loading={isLoading}
                        cyData="create-webhook-submit"
                    >
                        {submitText}
                    </Styled.Button>
                </Styled.ButtonsWrapper>
            </Styled.Content>
        </Form>
    );
};

FormContent.propTypes = {
    values: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    allDevices: PropTypes.arrayOf(PropTypes.object),
    dirty: PropTypes.bool.isRequired,
    submitText: PropTypes.string.isRequired,
    onCancel: PropTypes.func,
    isLoading: PropTypes.bool,
};

FormContent.defaultProps = {
    allDevices: [],
    onCancel: null,
    isLoading: false,
};
