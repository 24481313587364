import PropTypes from 'prop-types';
import { Field } from 'formik';

import { ReactComponent as DeleteIcon } from 'Assets/icons/delete.svg';
import { ReactComponent as PlusIcon } from 'Assets/icons/plus.svg';

import { Select } from '../../select';
import { TextInput } from '../textInput';
import { Checkbox } from '../checkbox';

import * as Styled from './styled';

export const ObjectBuilder = ({ form, name, push, remove, presetOptions }) => {
    const fieldValues = form.values[name] || [];

    const onElementDelete = (index) => remove(index);

    const addNewElement = () => {
        const newElement = { value: '', name: '' };

        if (presetOptions) {
            newElement.preset = false;
        }

        push(newElement);
    };

    const renderValueInput = (item, index) => (item.preset
        ? (
            <Field
                name={`${name}.${index}.value`}
                component={Select}
                values={presetOptions}
                disabled={item.unchangable}
            />
        )
        : (
            <Field
                name={`${name}.${index}.value`}
                component={TextInput}
                disabled={item.unchangable}
            />
        ));

    return (
        <>
            {!!fieldValues.length && (
                <Styled.Row>
                    <Styled.Column>
                        <Styled.Title>
                            Parameter
                        </Styled.Title>
                    </Styled.Column>
                    <Styled.Column>
                        <Styled.Title>
                            Value
                        </Styled.Title>
                    </Styled.Column>
                </Styled.Row>
            )}
            {fieldValues.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Styled.Row key={`${item.name}_${index}`}>
                    <Styled.Column>
                        <Field
                            name={`${name}.${index}.propName`}
                            component={TextInput}
                            disabled={item.unchangable}
                        />
                    </Styled.Column>
                    :
                    <Styled.Column>
                        {presetOptions && (
                            <Styled.PresetWrapper>
                                <Field
                                    name={`${name}.${index}.preset`}
                                    component={Checkbox}
                                    title="use preset"
                                />
                            </Styled.PresetWrapper>
                        )}
                        {renderValueInput(item, index)}
                        {!item.unchangable && (
                            <Styled.DeleteButton onClick={() => onElementDelete(index)}>
                                <DeleteIcon />
                            </Styled.DeleteButton>
                        )}
                    </Styled.Column>
                </Styled.Row>
            ))}
            <Styled.Divider />
            <Styled.AddButton
                buttonType="transparent"
                onClick={addNewElement}
            >
                <PlusIcon />
                Add New Field
            </Styled.AddButton>
        </>
    );
};

ObjectBuilder.propTypes = {
    form: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    push: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
    presetOptions: PropTypes.array,
};

ObjectBuilder.defaultProps = {
    presetOptions: null,
};
