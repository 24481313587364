import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const BodyWrapper = styled.div`
    display: flex;
    flex-direction: column;

    svg {
        font-size: 20px;
    }
`;

export const DescriptionWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const Header = styled.h3`
    text-transform: uppercase;
    font-weight: bold;
`;

export const Description = styled.p`
    margin: 20px 10px;
`;

export const NavButtons = styled.div`
    margin-top: 30px;
    display: flex;

    button {
        margin-right: 20px;
    }
`;
