export const TYPE_PING = 0x00;
export const TYPE_INFO = 0x01;
export const TYPE_NETWORK_INFO = 0x02;
export const TYPE_SCAN_INFO = 0x03;
export const TYPE_BATTERY_INFO = 0x04;
export const TYPE_OTA = 0x05;
export const TYPE_PYMESH_DEPLOY = 0x0D;
export const TYPE_PYBYTES = 0x0E;
export const TYPE_ML = 0x0F;

export const NETWORK_TYPE_MASK = 0x30;
export const TYPE_MASK = 0xF;

export const TERMINAL_PIN = 255;

export const COMMAND_PIN_MODE = 0;
export const COMMAND_DIGITAL_READ = 1;
export const COMMAND_DIGITAL_WRITE = 2;
export const COMMAND_ANALOG_READ = 3;
export const COMMAND_ANALOG_WRITE = 4;
export const COMMAND_CUSTOM_METHOD = 5;
export const COMMAND_CUSTOM_LOCATION = 6;
export const COMMAND_CREATE_SAMPLE = 7;
export const COMMAND_DEPLOY_MODEL = 8;
export const COMMAND_UPDATE_MODEM = 9;

export const PIN_TYPES = {
    STRING: 'STRING',
    FLOAT32: 'FLOAT32',
    INT32: 'INT32',
    TUPLE: 'TUPLE',
};

export const SIGNAL_TYPES = {
    INTEGER: 0,
    FLOAT: 1,
    TUPLE: 51,
    STRING: 51,
};
