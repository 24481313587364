import styled from 'styled-components';

export const TableWrapper = styled.div`
    margin-top: 40px;
`;

export const TableControls = styled.div`
    margin-bottom: 10px;
    display: flex;

    & > * {
        margin-right: 15px;
    }

    & > *:last-child {
        margin-right: 0;
    }
`;
