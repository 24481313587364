import { ROUTES } from 'Constants';
import { ReactComponent as AwsIcon } from 'Assets/tiles/aws.svg';
import { ReactComponent as WebHookIcon } from 'Assets/tiles/webhook.svg';
import { ReactComponent as AzureIcon } from 'Assets/tiles/azure.svg';
import { ReactComponent as GoogleCloudIcon } from 'Assets/tiles/google-cloud.svg';

export const linkRows = [
    {
        title: 'Amazon AWS',
        links: [ROUTES.settings.integrations.createAws],
        icon: AwsIcon,
    },
    {
        title: 'Web Hook',
        links: [ROUTES.settings.integrations.createWebhook],
        icon: WebHookIcon,
    },
    {
        title: 'Microsoft Azure',
        links: [ROUTES.settings.integrations.createAzure],
        icon: AzureIcon,
    },
    {
        title: 'Google Cloud',
        links: [ROUTES.settings.integrations.createGoogleCloud],
        icon: GoogleCloudIcon,
    },
];
