import { useParams, useRouteMatch, Switch, Redirect } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { ReactComponent as DeviceIcon } from 'Assets/devices/device.svg';
import { ReactComponent as ClockIcon } from 'Assets/devices/clock.svg';
import { ReactComponent as FirmwareIcon } from 'Assets/devices/firmware.svg';
import {
    ROUTES,
    BREADCRUMBS_LABELS,
    GET_DEVICE_BY_TOKEN_QUERY,
    DEVICE_FIRMWARE_QUERY,
    FIRMWARE_UPDATED_SUBSCRIPTION,
} from 'Constants';
import {
    Breadcrumbs,
    Menu,
    PageHeader,
    LastConnectionDevice,
    RouteWrapper,
    Loader,
} from 'Components';
import {
    Dashboard,
    DeviceConfiguration,
    DeviceProvisioning,
    Signals,
    PymakrOnline,
    Notifications,
} from './components';

import * as Styled from './styled';

const getLinks = (basePath) => [
    { title: 'Dashboard', route: `${basePath}${ROUTES.devices.deviceDetails.dashboard}` },
    { title: 'Signals', route: `${basePath}${ROUTES.devices.deviceDetails.signals}` },
    { title: 'Provisioning', route: `${basePath}${ROUTES.devices.deviceDetails.provisioning}` },
    { title: 'Pymakr', route: `${basePath}${ROUTES.devices.deviceDetails.pymakrOnline}` },
    { title: 'Notifications', route: `${basePath}${ROUTES.devices.deviceDetails.notifications}` },
    { title: 'Configuration', route: `${basePath}${ROUTES.devices.deviceDetails.configuration}` },
];

export const DeviceDetails = () => {
    const { token: deviceToken } = useParams();
    const { path, url } = useRouteMatch();

    const [initialLoading, setInitialLoading] = useState(true);

    // queries
    const { data, loading, subscribeToMore: subscribeDevice } = useQuery(GET_DEVICE_BY_TOKEN_QUERY,
        {
            variables: { deviceToken },
            fetchPolicy: 'cache-and-network',
        });
    const { data: firmwareQueryData, subscribeToMore: subscribeFirmware } = useQuery(
        DEVICE_FIRMWARE_QUERY,
        { variables: { deviceToken } },
    );

    // queries parsed data
    const device = data?.device?.[0];
    const firmwareVersion = firmwareQueryData?.getFirmware?.version ?? 'Unknown';

    useEffect(
        () => {
            if (initialLoading && !loading) {
                setInitialLoading(false);
            }
        },
        [loading],
    );

    // subscriptions
    useEffect(() => {
        subscribeFirmware({
            document: FIRMWARE_UPDATED_SUBSCRIPTION,
            variables: { deviceToken },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) {
                    return prev;
                }
                const newFirmware = subscriptionData.data.newFirmwareUpdated;
                return {
                    getFirmware: {
                        ...prev.getFirmware,
                        ...newFirmware,
                    },
                };
            },
        });
        subscribeDevice({
            document: FIRMWARE_UPDATED_SUBSCRIPTION,
            variables: { deviceToken },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) {
                    return prev;
                }
                const newFirmware = subscriptionData.data.newFirmwareUpdated;
                const newDeviceFirmware = {
                    firmware: {
                        ...prev.device[0].firmware,
                        ...{
                            version: newFirmware.version,
                            intVersion: newFirmware.intVersion,
                        },
                    },
                };
                return {
                    device: [{
                        ...prev.device[0],
                        ...newDeviceFirmware,
                    }],
                };
            },
        });
    }, []);

    if (initialLoading) {
        return <Loader />;
    }

    if (!device) {
        return 'There is no such device';
    }

    return (
        <>
            <Breadcrumbs
                labels={BREADCRUMBS_LABELS.devices}
                entityName={device.description}
            />
            <Styled.HeaderWrapper>
                <PageHeader title={device.description} withIcon={false} />
                <Styled.HeaderRightBlock>
                    <Styled.DeviceInfo>
                        <li>
                            <DeviceIcon />
                            <span>{device.deviceType}</span>
                        </li>
                        <li>
                            <ClockIcon />
                            <span>
                                <LastConnectionDevice deviceToken={deviceToken} />
                            </span>
                        </li>
                        <li>
                            <FirmwareIcon />
                            <span>{firmwareVersion}</span>
                        </li>
                    </Styled.DeviceInfo>
                </Styled.HeaderRightBlock>
            </Styled.HeaderWrapper>
            <Menu links={getLinks(url)}>
                <Switch>
                    <Redirect exact from={path} to={`${path}${ROUTES.devices.deviceDetails.dashboard}`} />
                    <RouteWrapper
                        path={`${path}${ROUTES.devices.deviceDetails.dashboard}`}
                        render={() => <Dashboard deviceToken={deviceToken} />}
                    />
                    <RouteWrapper
                        path={`${path}${ROUTES.devices.deviceDetails.signals}`}
                        render={() => <Signals deviceToken={deviceToken} />}
                    />
                    <RouteWrapper
                        path={`${path}${ROUTES.devices.deviceDetails.provisioning}`}
                        render={() => <DeviceProvisioning deviceToken={deviceToken} />}
                    />
                    <RouteWrapper
                        path={`${path}${ROUTES.devices.deviceDetails.pymakrOnline}`}
                        render={() => <PymakrOnline device={device} />}
                    />
                    <RouteWrapper
                        path={`${path}${ROUTES.devices.deviceDetails.notifications}`}
                        render={() => (
                            <Notifications
                                deviceToken={deviceToken}
                                deviceName={device.description}
                            />
                        )}
                    />
                    <RouteWrapper
                        path={`${path}${ROUTES.devices.deviceDetails.configuration}`}
                        render={() => <DeviceConfiguration device={device} />}
                    />
                </Switch>
            </Menu>
        </>
    );
};
