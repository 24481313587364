import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';

import { LIST_OF_DEVICES_BY_APP_ID, DEPLOY_NEW_RELEASE } from 'Constants';
import { Button, Table, Loader } from 'Components';
import { showToastSuccess, showToastError } from 'Utils';

import { columns } from './columns';

import * as Styled from './styled';

export const ModalContent = ({ projectId, releaseFileId, handleClose }) => {
    const [selectedTokens, setSelectedTokens] = useState([]);
    const { data, loading } = useQuery(LIST_OF_DEVICES_BY_APP_ID, { variables: { applicationId: projectId } });
    const devices = data?.getDevicesByApplicationID ?? [];

    const [deployRelease, { loading: isLoading }] = useMutation(DEPLOY_NEW_RELEASE);

    useEffect(() => {
        setSelectedTokens(devices.map((device) => device.token));
    }, [devices.length]);

    const onDeployClick = () => {
        deployRelease({
            variables: {
                releaseId: releaseFileId,
                applicationId: projectId,
                listOfDevices: selectedTokens,
            },
            update: (_, { data: { deployNewRelease } }) => {
                if (deployNewRelease !== 'false') {
                    handleClose();
                    showToastSuccess(
                        'The Deployment has started! To see which Device was updated, check the Release ID in Devices’ table.',
                    );
                } else {
                    showToastError('Something went wrong with deploying the release');
                }
            },
        });
    };

    if (loading) {
        return <Loader />;
    }

    const title = devices.length ? 'Choose the devices for deploing a new release' : 'Warning';

    const content = devices.length
        ? (
            <Table
                columns={columns}
                data={devices}
                selectedIds={selectedTokens}
                onSelect={setSelectedTokens}
                selectedRowKeys={selectedTokens}
                rowKey="token"
            />
        ) : 'You need to add at least one device to the project before deploying a release';

    return (
        <>
            <Styled.Title>
                {title}
            </Styled.Title>
            <Styled.Content>
                {content}
                <Styled.ButtonsWrapper>
                    <Button
                        buttonType="transparent"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={onDeployClick}
                        disabled={!selectedTokens.length}
                        loading={isLoading}
                    >
                        Deploy
                    </Button>
                </Styled.ButtonsWrapper>
            </Styled.Content>
        </>
    );
};

ModalContent.propTypes = {
    projectId: PropTypes.string.isRequired,
    releaseFileId: PropTypes.string,
    handleClose: PropTypes.func.isRequired,
};

ModalContent.defaultProps = {
    releaseFileId: '',
};
