import { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'formik';

import { PymeshNetworkSettingsFormContent, Button, Modal, DeleteModal } from 'Components';

import * as Styled from './styled';

export const FormContent = ({ values, setFieldValue, dirty, isValid, deletePymesh }) => {
    const [showModal, setShowModal] = useState(false);

    const initiateDeletion = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    return (
        <Form>
            <Styled.Wrapper>
                <PymeshNetworkSettingsFormContent
                    values={values}
                    setFieldValue={setFieldValue}
                    dirty={dirty}
                />
                <Styled.ButtonsSection>
                    <Button type="submit" disabled={!isValid}>
                        Save
                    </Button>
                    <Styled.DeleteButton onClick={initiateDeletion}>
                        Delete
                    </Styled.DeleteButton>
                </Styled.ButtonsSection>
            </Styled.Wrapper>
            <Modal isOpened={showModal} handleClose={handleClose}>
                <DeleteModal
                    title="Device deletion"
                    description={(
                        <>
                            <p>
                                The Pymesh Settings will be deleted from Pybytes.
                                After that, you will not see the Pymesh&apos;s nodes on the Pymesh tab.
                            </p>
                            <Styled.Confirmation> Do you want to delete Pymesh Settings anyway?</Styled.Confirmation>
                        </>
                    )}
                    deleteAction={deletePymesh}
                    cancelAction={handleClose}
                />
            </Modal>
        </Form>
    );
};

FormContent.propTypes = {
    values: PropTypes.shape({
        region: PropTypes.string,
        frequency: PropTypes.string,
        bandwidth: PropTypes.string,
        spreadFactory: PropTypes.string,
        joinKey: PropTypes.string,
    }).isRequired,
    deletePymesh: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    dirty: PropTypes.bool.isRequired,
};
