import styled from 'styled-components';

export const Modal = styled.div`
    ${({ $modal }) => $modal && `
        width: 700px;
        background-color: #fff;
    `}

    ${({ $deactivated }) => $deactivated && `
        width: 100%;
        margin-top: 34px;
        background-color: transparent;
    `}
`;

export const Title = styled.div`
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.title};
    font-size: 22px;
    line-height: 27px;
    font-weight: bold;
`;

export const Description = styled.p`
    color: ${({ theme }) => theme.colors.title};
    font-size: 14px;
    line-height: 17px;
`;

export const Header = styled.div`
    width: 100%;
    height: 50px;
    padding: 15px 20px;
    background-color: ${({ theme }) => theme.colors.title};
    color: #fff;
    font-size: 16px;
    font-weight: bold;
`;

export const Form = styled.div`
    ${({ $form }) => $form && `
        padding: 30px 0 0 20px;
    `}

    ${({ $padded }) => $padded && `
        padding: 30px 0 0 0;
    `}
`;

export const Item = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 22px;

    & > span {
        width: 88px;
        display: block;
        color: ${({ theme }) => theme.colors.title};
        margin-right: 10px;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.38;
    }
`;

export const ButtonsWrapper = styled.div`
    ${({ $normal }) => $normal && `
        display: flex;
        justify-content: center;
        margin-top: 50px;
        padding-bottom: 27px;
    `}    

    ${({ $left }) => $left && `
        display: flex;
        justify-content: flex-start;
        margin-top: 66px;
        gap: 15px;
    `}

    & > div {
        width: auto;
        display: flex;
        justify-content: space-between;
        gap: 15px;
    }
`;

export const DocumentationLink = styled.a`
    color: ${({ theme }) => theme.colors.active};
`;
