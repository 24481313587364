import { CustomTag, deviceColumns } from 'Components';
import { insertColumns } from 'Utils';

const newColumnsList = [
    {
        index: 4,
        column: {
            title: 'Connection',
            dataIndex: 'hasValidCertificate',
            render: (data) => (
                <CustomTag
                    title={data ? 'Certificate' : 'Device Token'}
                    fill={data ? 'success' : 'border'} 
                />
            ),
        },
    },
];

export const columns = insertColumns(deviceColumns, newColumnsList);
