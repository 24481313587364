import PropTypes from 'prop-types';
import { Collapse } from 'antd';

import * as Styled from './styled';

const { Panel } = Collapse;

export const ReleaseDetails = ({ release }) => {
    const collapseConfigurationHeader = (
        <Styled.CollapseHeader>
            Check the Project configuration
        </Styled.CollapseHeader>
    );

    const config = { networks: release.networks || release.networkPreferences };

    if (release.wifi) {
        const { ssid, password, secureType } = release.wifi;
        config.wifi = { ssid, password, secureType };
    }

    if (release.lte?.carrier) {
        const { apn, carrier, cid, protocol, reset } = release.lte;
        config.lte = { apn, carrier, cid, protocol, reset };
    }

    if (release.loraServer) {
        config.loraServer = release.loraServer;
        config.loraType = release.loraType;

        if (release.loraRegion) {
            config.loraRegion = release.loraRegion;
        }
    }

    if (release.ethernet?.ipAddress || release.ethernet?.autoSettings) {
        const { name, autoSettings, ipAddress, subnetMask, router, dnsServer, dnsServer2 } = release.ethernet;
        if (autoSettings) {
            config.ethernet = { name, autoSettings };
        } else {
            config.ethernet = { name, autoSettings, ipAddress, subnetMask, router, dnsServer, dnsServer2 };
        }
    }

    return (
        <>
            <Styled.Title>
                Release summary
            </Styled.Title>
            {release.codeFilename && (
                <Styled.FormItem>
                    <Styled.ItemLabel>
                        Release version
                    </Styled.ItemLabel>
                    {release.releaseVersion || 'N/A'}
                </Styled.FormItem>
            )}
            <Styled.FormItem>
                <Styled.ItemLabel>
                    Release summary
                </Styled.ItemLabel>
                {release.summary || 'N/A'}
            </Styled.FormItem>
            <Styled.FormItem>
                <Styled.ItemLabel>
                    Release configuration
                </Styled.ItemLabel>
                <Styled.Collapse ghost>
                    <Panel
                        header={collapseConfigurationHeader}
                        showArrow={false}
                        key="1"
                    >
                        <Styled.Code>
                            {JSON.stringify(config, null, 2)}
                        </Styled.Code>
                    </Panel>
                </Styled.Collapse>
            </Styled.FormItem>
            <Styled.FormItem>
                <Styled.ItemLabel>
                    Device Firmware
                </Styled.ItemLabel>
                {release.firmware || 'N/A'}
            </Styled.FormItem>
            {!!release.customFirmwares?.length && (
                <Styled.FormItem>
                    <Styled.ItemLabel>
                        Custom firmware(s)
                    </Styled.ItemLabel>
                    {release.customFirmwares.map((fileData) => (
                        <Styled.FirmwareFilename key={fileData.originalFilename}>
                            {fileData.originalFilename}
                        </Styled.FirmwareFilename>
                    ))}
                </Styled.FormItem>
            )}
            <Styled.FormItem>
                <Styled.ItemLabel>
                    MicroPython code
                </Styled.ItemLabel>
                {release.originalFilename || 'N/A'}
            </Styled.FormItem>
        </>
    );
};

ReleaseDetails.propTypes = {
    release: PropTypes.object,
};

ReleaseDetails.defaultProps = {
    release: {},
};
