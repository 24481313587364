import PropTypes from 'prop-types';
import moment from 'moment';

import { AWS_REGIONS } from 'Constants';
import { ReactComponent as AwsIcon } from 'Assets/tiles/aws.svg';

import * as Styled from './styled';

export const DetailsBlock = ({ details, thingsArr }) => {
    const regionLabel = AWS_REGIONS.filter((item) => item.code === details.aws.region)?.[0]?.name;
    const creationDate = moment(details.createdAt).format('D MMM YYYY');
    const topic = thingsArr[0]?.thing?.topic;

    return (
        <Styled.DetailsWrapper>
            <Styled.FormItem>
                <Styled.ItemLabel>
                    Service
                </Styled.ItemLabel>
                <Styled.IconWrapper>
                    <AwsIcon />
                    Amazon Web Services IoT Hub ( AWS )
                </Styled.IconWrapper>
            </Styled.FormItem>
            <Styled.FormItem>
                <Styled.ItemLabel>
                    AWS IoT Group Name
                </Styled.ItemLabel>
                {details.name}
            </Styled.FormItem>
            <Styled.FormItem>
                <Styled.ItemLabel>
                    Endpoint Address
                </Styled.ItemLabel>
                {details.aws.endpointAddress}
            </Styled.FormItem>
            <Styled.FormItem>
                <Styled.ItemLabel>
                    Region
                </Styled.ItemLabel>
                {regionLabel}
            </Styled.FormItem>
            <Styled.FormItem>
                <Styled.ItemLabel>
                    Messages Topic
                </Styled.ItemLabel>
                {topic}
            </Styled.FormItem>
            <Styled.FormItem>
                <Styled.ItemLabel>
                    Devices Count
                </Styled.ItemLabel>
                {details.devices}
            </Styled.FormItem>
            <Styled.FormItem>
                <Styled.ItemLabel>
                    Creation Date
                </Styled.ItemLabel>
                {creationDate}
            </Styled.FormItem>
        </Styled.DetailsWrapper>
    );
};

DetailsBlock.propTypes = {
    details: PropTypes.object.isRequired,
    thingsArr: PropTypes.array.isRequired,
};
