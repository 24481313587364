import styled from 'styled-components';

export const AltitudeContainer = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
`;

export const AltitudeUnit = styled.div`
    font-size: 24px;
    line-height: 1;
    align-self: end;
`;

export const AltitudeValue = styled.div`
    font-size: 50px;
    line-height: 1;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    span {
        font-size: 22px;
        &::before {
            content: " ";
        }
    }
    svg {
        font-size: 140px;
        color: #E0E0E0;
        ${''/*shift bottom, because the icon is not a square and has its own margins*/}
        margin-bottom: -30px;
    }
`;
