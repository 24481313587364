import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';

import { GET_ML_SAMPLES_BY_MODEL, GET_SAMPLE_BY_ML_SAMPLE_ID } from 'Constants';
import { Loader, NoData } from 'Components';

import * as Styled from '../../styled';

import { RawData, SpectralAnalysisForm, SpectralAnalysisCharts } from './components';
import { getInitialMlSample, getModelDefinition } from './helpers';

export const Processing = ({ model }) => {
    const [selectedMlSample, setSelectedMlSample] = useState({});
    const [initialLoading, setInitialLoading] = useState(true);

    const { data: mlSamplesData, loading: mlSamplesLoading } = useQuery(
        GET_ML_SAMPLES_BY_MODEL,
        {
            variables: { model: model._id, type: 1 },
            fetchPolicy: 'cache-and-network',
        },
    );
    const mlSamples = mlSamplesData?.getMLSamplesByModelId ?? [];

    const { data: samplesData, loading: samplesLoading } = useQuery(
        GET_SAMPLE_BY_ML_SAMPLE_ID,
        {
            variables: { mlSampleId: selectedMlSample._id },
            skip: !selectedMlSample._id,
        },
    );
    const samples = samplesData?.getSamplesByMLSampleId ?? [];

    useEffect(
        () => {
            if (initialLoading && !mlSamplesLoading && !samplesLoading) {
                setInitialLoading(false);
            }
        },
        [mlSamplesLoading, samplesLoading],
    );

    useEffect(() => {
        if (isEmpty(selectedMlSample) && mlSamples.length) {
            const selectedItem = getInitialMlSample(mlSamples, model);
            setSelectedMlSample(selectedItem);
        }
    }, [mlSamples]);

    const { preProcessingData, modelDefinition } = useMemo(() => getModelDefinition(model), [model]);

    if (initialLoading) {
        return <Loader />;
    }

    if (!mlSamples.length) {
        return (
            <NoData
                caption="There is no samples yet"
                text="Generate your samples at Data Acquisition tab."
            />
        );
    }

    return (
        <>
            <RawData
                model={model}
                mlSamples={mlSamples}
                samples={samples}
                selectedMlSample={selectedMlSample}
                setSelectedMlSample={setSelectedMlSample}
                loading={samplesLoading}
            />
            <Styled.ModelMargined>
                <Styled.ModelTabWrapper>
                    <Styled.ModelTabLeft>
                        <SpectralAnalysisForm
                            model={model}
                            mlSample={selectedMlSample}
                            samples={samples}
                            modelDefinition={modelDefinition}
                        />
                    </Styled.ModelTabLeft>
                    <Styled.ModelTabRight>
                        <SpectralAnalysisCharts
                            model={model}
                            preProcessingData={preProcessingData}
                            loading={samplesLoading}
                        />
                    </Styled.ModelTabRight>
                </Styled.ModelTabWrapper>
            </Styled.ModelMargined>
        </>
    );
};

Processing.propTypes = {
    model: PropTypes.object.isRequired,
};
