import { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { Warning } from 'Components';
import { DELETE_DEVICE_MUTATION, GET_DEVICES_QUERY, ROUTES } from 'Constants';
import { showToastSuccess } from 'Utils';

import * as Styled from './styled';

export const DeviceDeleteForm = ({ device }) => {
    const history = useHistory();
    const [deleteDevice, { loading: isLoading }] = useMutation(DELETE_DEVICE_MUTATION, { refetchQueries: [GET_DEVICES_QUERY] });

    const title = `I understand that device ${device.description} and all received signal data will be deleted`;
    const warningMessage = 'Beware that this device and all received signal data will be deleted. '
        + 'This action cannot be undone!';

    const [agreedForDeletion, setAgreedForDeletion] = useState(false);

    const handleCheckbox = (event) => {
        const checked = event.target.checked;

        setAgreedForDeletion(checked);
    };

    const onClick = () => {
        deleteDevice({
            variables: { deviceId: device._id },
            update: () => {
                history.push(ROUTES.devices.main);
                showToastSuccess('Device was deleted successfully');
            },
        });
    };

    return (
        <>
            <Warning
                warn={warningMessage}
                checked={agreedForDeletion}
                handleCheckbox={handleCheckbox}
                title={title}
            />
            <Styled.Button
                disabled={!agreedForDeletion}
                onClick={onClick}
                loading={isLoading}
            >
                Delete device
            </Styled.Button>
        </>
    );
};

DeviceDeleteForm.propTypes = {
    device: PropTypes.object.isRequired,
};
