import styled from 'styled-components';

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    width: 214px;
    padding: 10px 5px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    box-sizing: border-box;
    border-radius: 5px;
    cursor: pointer;
    background-color: #fff;

    &:hover {
        border: 1px solid ${({ theme }) => theme.colors.active};
    }
`;

export const Title = styled.div`
    padding: 0 10px;
    color: ${({ theme }) => theme.colors.title};
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;
`;

export const Description = styled.div`
    margin-top: 15px;
`;

export const List = styled.div`
    padding-left: 25px;

    & img {
        float: right;
        width: 115px;
        height: 105px;
        object-fit: contain;
    }

    & ul {
        list-style-type: square;

        & li:last-child {
            word-break: break-word;
        }
    }
`;

export const ListItem = styled.li`
    margin-bottom: 5px;
    color: ${({ theme }) => theme.colors.title};
    word-break: break-all;
    font-size: 12px;
    line-height: 15px;
`;

export const Networks = styled.div`
    display: flex;
    margin-top: auto;
    padding-left: 7px;
`;

export const Network = styled.div`
    display: flex;
    align-items: center;
    font-size: 16px;
    color: ${({ theme }) => theme.colors.title};
    margin-right: 5px;
`;
