import gql from 'graphql-tag';

export const PINS_BY_DEVICE_QUERY = gql`
    query getPinsByDevice($deviceToken: String!) {
        getPinsByDevice(deviceToken: $deviceToken) {
            _id
            name
            unit
            pin
            createdAt
        }
    }
`;

export const PIN_QUERY = gql`
    query getPinsByDevice($id: String!) {
        getPinById(id: $id) {
            _id
            name
            unit
            pin
            createdAt
        }
    }
`;

export const CREATE_PIN_MUTATION = gql`
    mutation CreatePin($userName: String!, $deviceToken:String!, $name: String!, $unit:String!, $pinNumber:Int!) {
        savePin(pin: { userName: $userName, deviceToken: $deviceToken, name: $name, unit: $unit, pin: $pinNumber }) {
          _id
          name
        }
    }
`;

export const EDIT_PIN_MUTATION = gql`
    mutation EditPin($_id: String!, $name: String!, $unit:String!, $pinNumber:Int!) {
        savePin(pin: {_id: $_id, name: $name, unit: $unit, pin: $pinNumber}) {
          _id
          name
        }
    }
`;

export const DELETE_PIN_MUTATION = gql`
    mutation deletePinById($_id: String!) {
        deletePinById(id: $_id)
    }
`;

export const PIN_CREATED_BY_MESSAGE_SUBSCRIPTION = gql`
    subscription pinCreatedByMessage($deviceToken: String!) {
        pinCreatedByMessage(deviceToken: $deviceToken)
    }
`;
