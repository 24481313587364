export const onceSimConsumptionColumns = [
    {
        title: 'Date Time',
        dataIndex: 'date',
    },
    {
        title: 'Traffic Type',
        dataIndex: 'dataUsageTrafficType',
    },
    {
        title: 'Data Usage RX',
        dataIndex: 'dataUsageRx',
    },
    {
        title: 'Data Usage TX',
        dataIndex: 'dataUsageTx',
    },
    {
        title: 'Total Data Usage',
        dataIndex: 'totalDataUsage',
    },
];
