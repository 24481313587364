import styled from 'styled-components';
import { Slider as AntSlider } from 'antd';

export const OnlineContainer = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: flex-end;
    font-size: 18px;
`;

export const OnlineNumber = styled.div`
    font-size: 57px;
    line-height: 1;
    color: ${({ theme }) => theme.colors.active};
`;

export const OfflineNumber = styled.div`
    font-size: 57px;
    line-height: 1;
    text-align: right;
    color: #828282;
`;

export const OnlineCounts = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 3px;
`;

export const Slider = styled(AntSlider)`
    margin-left: 0px !important;
    margin-right: 0px !important;

    .ant-slider-track,
    .ant-slider-rail {
        height: 12px;
        border-radius: 5px;
    }

    .ant-slider-handle {
        display: none;
    }

    &:hover .ant-slider-handle:not(.ant-tooltip-open) {
        border-color: ${({ theme }) => theme.colors.active};
    }

    &:hover .ant-slider-track,
    .ant-slider-track {
        background-color: ${({ theme }) => theme.colors.active};
    }

    .ant-slider-rail {
        height: 12px;
        background-color: #e0e0e0;
    }
`;
