import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { GET_RELEASES_QUERY, ROUTES } from 'Constants';
import { Table, Loader } from 'Components';
import { ReactComponent as FileIcon } from 'Assets/icons/pymakr.svg';

import { DeployReleaseModal } from '../deployReleaseModal';
import { DeleteReleaseModal } from '../deleteReleaseModal';
import { getColumns } from './columns';

import * as Styled from './styled';

export const ProjectReleases = ({ project }) => {
    const history = useHistory();
    const { url } = useRouteMatch();
    const [isDeployModalOpened, setDeployModalOpened] = useState(false);
    const [isDeleteModalOpened, setDeleteModalOpened] = useState(false);
    const [selectedReleaseFileId, setSelectedReleaseFileId] = useState(null);
    const [selectedReleaseId, setSelectedReleaseId] = useState(null);
    const [initialLoading, setInitialLoading] = useState(true);

    const { data, loading } = useQuery(
        GET_RELEASES_QUERY,
        {
            variables: { applicationId: project._id },
            fetchPolicy: 'cache-and-network',
        },
    );
    const releases = data?.getReleases ?? [];

    useEffect(
        () => {
            if (initialLoading && !loading) {
                setInitialLoading(false);
            }
        },
        [loading],
    );

    const deployRelease = (id) => {
        const selectedRelease = releases.find((item) => item._id === id);
        setSelectedReleaseFileId(selectedRelease.codeFilename);
        setDeployModalOpened(true);
    };

    const deleteRelease = (id) => {
        setSelectedReleaseId(id);
        setDeleteModalOpened(true);
    };

    const redirectToCreateRelease = () => {
        history.push(`${url.split('/').slice(0, -1).join('/')}${ROUTES.projects.projectDetails.releaseCreate}`);
    };

    const redirectToReleaseDetails = (release) => {
        history.push(`${url.split('/').slice(0, -1).join('/')}${ROUTES.projects.projectDetails.releases}/${release._id}`);
    };

    const columns = getColumns({ deployRelease, deleteRelease });

    if (initialLoading) {
        return <Loader />;
    }

    return (
        <Styled.Wrapper>
            <Styled.Button
                buttonType="filled"
                Icon={FileIcon}
                onClick={redirectToCreateRelease}
            >
                Create release
            </Styled.Button>
            <Table
                columns={columns}
                data={releases}
                onRowClick={redirectToReleaseDetails}
            />
            <DeployReleaseModal
                isOpen={isDeployModalOpened}
                handleClose={() => setDeployModalOpened(false)}
                projectId={project._id}
                releaseFileId={selectedReleaseFileId}
            />
            <DeleteReleaseModal
                isOpen={isDeleteModalOpened}
                handleClose={() => setDeleteModalOpened(false)}
                releaseId={selectedReleaseId}
            />
        </Styled.Wrapper>
    );
};

ProjectReleases.propTypes = {
    project: PropTypes.object,
};

ProjectReleases.defaultProps = {
    project: {},
};
