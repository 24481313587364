import gql from 'graphql-tag';

export const GET_INTEGRATIONS_QUERY = gql`
    query getIntegrations {
        getIntegrations {
            _id
            owner
            serviceName
            name
            devices
            createdAt
            aws {
                endpointAddress
                region
            }
            webhook {
                hookId
            }
            azure {
                connectionString
            }
        }
    }
`;

export const GET_AWS_THINGS_BY_ENDPOINT = gql`
    query getAwsThingsByEndpoint($endpointAddress: String!, $excludedIntegrationId: String) {
       getAwsThingsByEndpoint(endpointAddress: $endpointAddress, excludedIntegrationId: $excludedIntegrationId)
    }
`;

export const GET_AWS_THINGS_BY_GROUP = gql`
    query getAWSThingsByGroup($awsGroupName: String!, $region: String!, $includeDevice: Boolean!) {
      getAWSThingsByGroup(awsGroupName: $awsGroupName, region:$region, includeDevice: $includeDevice) {
        thing {
            _id
            owner
            region
            endpointAddress
            integrationRefId
            topic
            awsThing {
                thingName
                thingArn
                thingId
            }
            awsGroup {
                groupName
                groupArn
                groupId
            }
            certificate {
                id
                arn
                certPEM
                publicKey
                privateKey
            }
            deviceToken
            createdAt
        }
        device {
            _id
            token
            description
        }
      }
  }
`;

export const SAVE_AWS_THINGS_MUTATION = gql`
    mutation saveAWSThings($awsThings: [AWSThingDetailsInput]!, $integrationId: String) {
      saveAWSThings(awsThings: $awsThings, integrationId: $integrationId) {
        awsThings {
            _id
            owner
            region
            endpointAddress
            integrationRefId
            topic
            awsThing {
                thingName
                thingArn
                thingId
            }
            awsGroup {
                groupName
                groupArn
                groupId
            }
            certificate {
                id
                arn
                certPEM
                publicKey
                privateKey
            }
            deviceToken
            createdAt
        }
        integrationId
      }
    }
`;

export const GET_WEBHOOK_BY_NAME = gql`
    query getWh($name: String!, $hookId: String) {
        getWebHookByName(name: $name, hookId: $hookId)
    }
`;

export const SAVE_WEBHOOK_MUTATION = gql`
    mutation saveWebHook($webhook: WebHookInput!, $integrationId: String) {
        saveWebHook(webhook: $webhook, integrationId: $integrationId) {
            _id
            owner
            eventName
            endpointAddress
            requestType
            requestFormat
            customBody
            formFields
            headers
            queryParams
            basicAuth {
                username
            }
            deviceTokens
            integrationRefId
            createdAt
        }
    }
`;

export const TEST_AZURE_CONNECTION_QUERY = gql`
    query testConnection($iotHubString: String!) {
        testConnection(iotHubString: $iotHubString)
    }
`;

export const SAVE_AZURE_DEVICES_MUTATION = gql`
    mutation saveAzureDevices($devices: [AzureDeviceInput]!, $integrationName: String, $integrationId: String, $iotHubString: String!) {
        saveAzureDevices(devices: $devices, integrationName: $integrationName, integrationId: $integrationId, iotHubString: $iotHubString) {
            azureDevices {
                _id
                owner
                integrationRefId
                deviceId
                hostName
                topic
                connectionString
                deviceToken
                createdAt
            }
            integrationId
        }
    }
`;

export const GET_INTEGRATION_BY_ID_QUERY = gql`
    query getIntegrationById($integrationId: String!) {
        getIntegrationById(integrationId: $integrationId) {
            _id
            owner
            serviceName
            name
            devices
            createdAt
            aws {
                endpointAddress
                region
            }
            webhook {
                hookId
            }
            azure {
                connectionString
            }
            google {
                registryId
            }
        }
    }
`;

export const DELETE_INTEGRATION_MUTATION = gql`
    mutation deleteIntegration($owner: String!, $integrationId: String!, $serviceName: String!, $data: JSON) {
        deleteIntegration(owner: $owner, integrationId: $integrationId, serviceName: $serviceName, data: $data)
    }
`;

export const SEND_AWS_TEST_MESSAGE = gql`
    query sendAwsTestMessage($integrationId: String!) {
        sendAwsTestMessage(integrationId: $integrationId) {
            statusCode
            message
        }
    }
`;

export const UPDATE_AWS_GROUP_TOPIC = gql`
    mutation updateAWSGroupTopic($awsGroupName: String!, $topic: String!) {
        updateAWSGroupTopic(awsGroupName: $awsGroupName, topic: $topic)
    }
`;

export const REMOVE_AWS_THINGS_MUTATION = gql`
    mutation removeAWSThings($tokens: [String]!, $integrationId: String!) {
        removeAWSThings(tokens: $tokens, integrationId: $integrationId)
    }
`;

export const GET_WEBHOOK_QUERY = gql`
    query getWebHook($hookId: String!) {
        getWebHook(hookId: $hookId) {
          webhook {
            _id
            owner
            eventName
            endpointAddress
            requestType
            requestFormat
            customBody
            formFields
            headers
            queryParams
            basicAuth {
              username
            }
            deviceTokens
            integrationRefId
            createdAt
          }
          devices {
            _id
            token
            description
          }
        }
    }
`;

export const SEND_WEBHOOK_TEST_MESSAGE = gql`
    query sendWebhookTestMessage($integrationId: String!) {
        sendWebhookTestMessage(integrationId: $integrationId) {
          statusCode
          message
        }
    }
`;

export const GET_AZURE_DEVICE_BY_INTEGRATION_ID = gql`
    query getAzureDevicesByIntegrationId($integrationId: String!, $includeDevice: Boolean!) {
       getAzureDevicesByIntegrationId(integrationId: $integrationId, includeDevice: $includeDevice) {
          azureDevice {
              _id
              integrationRefId
              owner
              deviceId
              hostName
              topic
              connectionString
              deviceToken
              createdAt
          }
          device {
              _id
              token
              description
          }
       }
    }
`;

export const SEND_AZURE_TEST_MESSAGE = gql`
    query sendAzureTestMessage($integrationId: String!) {
        sendAzureTestMessage(integrationId: $integrationId) {
          statusCode
          message
        }
    }
`;

export const UPDATE_AZURE_HUB_TOPIC = gql`
    mutation updateAzureHubTopic($integrationId: String!, $topic: String!) {
      updateAzureHubTopic(integrationId: $integrationId, topic: $topic)
    }
`;

export const REMOVE_AZURE_DEVICES_MUTATION = gql`
    mutation removeAzureDevices($tokens: [String]!, $integrationId: String!, $iotHubString: String!) {
        removeAzureDevices(tokens: $tokens, integrationId: $integrationId, iotHubString: $iotHubString)
    }
`;

export const GET_AZURE_DEVICE_BY_IOT_HUB_STRING = gql`
    query getAzureDevicesByIotHubString($iotHubString: String!, $excludedIntegrationId: String) {
       getAzureDevicesByIotHubString(iotHubString: $iotHubString, excludedIntegrationId: $excludedIntegrationId)
    }
`;

export const GET_GOOGLE_DEV_TOKENS_BY_REGISTRY = gql`
    query getDevTokensByRegistryId($registryId: String!, $excludedIntegrationId: String) {
        getDevTokensByRegistryId(registryId: $registryId, excludedIntegrationId: $excludedIntegrationId)
    }
`;

export const SAVE_GOOGLE_DEVS_MUTATION = gql`
    mutation saveGoogleDevices($devices: [GoogleDeviceinput]!, $accessToken: String!, $integrationId: String) {
        saveGoogleDevices(devices: $devices, accessToken: $accessToken, integrationId: $integrationId) {
            _id
            owner
            integrationRefId
            googleIdentifiers {
                id
                name
                numId
            }
            publicKey
            privateKey
            metadata {
                deviceName
            }
            projectId
            region
            registryId
            topic
            deviceToken
            createdAt
        }
    }
`;

export const REMOVE_GOOGLE_DEVICES_MUTATION = gql`
    mutation removeGoogleDevices($tokens: [String]!, $accessToken: String!, $integrationId: String!) {
        removeGoogleDevices(tokens: $tokens, accessToken: $accessToken, integrationId: $integrationId)
    }
`;

export const GET_GOOGLE_DEVICE_BY_INTEGRATION_ID = gql`
    query getGoogleDevicesByIntegrationId($integrationId: String!) {
       getGoogleDevicesByIntegrationId(integrationId: $integrationId) {
            _id
            owner
            integrationRefId
            googleIdentifiers {
                id
                name
                numId
            }
            publicKey
            privateKey
            metadata {
                deviceName
            }
            projectId
            region
            registryId
            topic
            deviceToken
            createdAt
       }
    }
`;

export const SEND_GOOGLE_TEST_MESSAGE = gql`
    query sendGoogleTestMessage($integrationId: String!) {
        sendGoogleTestMessage(integrationId: $integrationId) {
            statusCode
            message
        }
    }
`;
