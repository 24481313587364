import { renderToStaticMarkup } from 'react-dom/server';

import { ReactComponent as MarkerStart } from 'Assets/icons/marker-start.svg';
import { ReactComponent as MarkerEnd } from 'Assets/icons/marker-end.svg';
import { ReactComponent as MarkerBetween } from 'Assets/icons/marker-intermediate.svg';

const encodeSvg = (reactElement) =>
  `data:image/svg+xml;charset=utf-8,${
    encodeURIComponent(renderToStaticMarkup(reactElement))}`;

export const getIconOptions = (Icon, color, size = { width: 30, height: 30 }) => ({
        // eslint-disable-next-line react/jsx-filename-extension
        url: encodeSvg(<Icon color={color} />),
        scaledSize: size,
        anchor: { x: size.width / 2, y: size.height / 2 },
    });

export const typesOfIcons = {
    start: MarkerStart,
    end: MarkerEnd,
    between: MarkerBetween,
};

export const getBounds = (allLocations) => {
    const bounds = new window.google.maps.LatLngBounds();
    if (Array.isArray(allLocations)) {
        for (const coordinate of allLocations) {
            bounds.extend(coordinate);
        }
    } else {
        bounds.extend(allLocations);
    }
    return bounds;
};
