/* eslint-disable prefer-template */

import { TEMPERATURE_UNITS } from 'Constants';
import { roundNumberToTwoDigits } from 'Utils';

// linear gradient for left half of gauge graph
const firstGradientValues = [
    { percent: 0, color: '#029EC6' },
    { percent: 0.6, color: '#05CDFF' },
    { percent: 1, color: '#78D64B' },
];

// linear gradient for right half of gauge graph
const secondGradientValues = [
    { percent: 0, color: '#78D64B' },
    { percent: 0.2, color: '#F5DD02' },
    { percent: 0.5, color: '#FFC700' },
    { percent: 0.9, color: '#DB0000' },
];

export const chartRanges = {
    [TEMPERATURE_UNITS.FAHRENHEIT]: {
        min: 32,
        max: 104,
    },
    [TEMPERATURE_UNITS.CELSIUS]: {
        min: 0,
        max: 40,
    },
};

export const defaultChartRange = { min: 0, max: 0 };

const trimChartValue = (value) => {
    let result = value;

    result = Math.max(0, result);
    result = Math.min(1, result);

    return result;
};

export const chartLabels = {
    [TEMPERATURE_UNITS.FAHRENHEIT]: [50, 68, 86],
    [TEMPERATURE_UNITS.CELSIUS]: [10, 20, 30],
};

export const farenheitToCelsius = (value) => roundNumberToTwoDigits((value - 32) * (5 / 9));

export const celciusToFarenheit = (value) => roundNumberToTwoDigits((value * (9 / 5)) + 32);

export const getChartValue = (value, unit) => {
    const chartRange = chartRanges[unit] || defaultChartRange;
    const trimmedValue = value - chartRange.min;
    const range = chartRange.max - chartRange.min;
    const result = (((100 * trimmedValue) / range) / 100);

    return roundNumberToTwoDigits(trimChartValue(result));
};

export const getRange = (value) => {
    const ticks = [];
    let firstGradient = '';
    let secondGradient = '';
    const greyColor = '#82828240';
    const colorsArray = [];

    switch (true) {
        case (value === 0): {
            ticks.push(0, 1);
            colorsArray.push(greyColor);
            break;
        }

        case (value <= 0.5): {
            ticks.push(0, value, 1);
            const valueForGradient = value * 2;

            const rangeIndex = firstGradientValues.findIndex((gradient) => valueForGradient <= gradient.percent);

            const slicedArray = firstGradientValues.slice(0, rangeIndex + 1);

            firstGradient = 'l(270) ' + slicedArray
                .map((gradient) => `${gradient.percent}:${gradient.color}`)
                .join(' ');

            colorsArray.push(firstGradient, greyColor);
            break;
        }

        case (value > 0.5): {
            ticks.push(0, 0.5, value, 1);
            const valueForGradient = (value - 0.5) * 2;

            firstGradient = 'l(270) ' + firstGradientValues
                .map((gradient) => `${gradient.percent}:${gradient.color}`)
                .join(' ');

            let rangeIndex = secondGradientValues.findIndex((gradient) => valueForGradient <= gradient.percent);

            if (rangeIndex === -1) {
                rangeIndex = secondGradientValues.length;
            }

            const slicedArray = secondGradientValues.slice(0, rangeIndex);

            secondGradient = 'l(90)' + slicedArray
                .map((gradient) => {
                    const newPercent = trimChartValue((1 / valueForGradient) * gradient.percent);

                    return `${newPercent}:${gradient.color}`;
                }).join(' ');

            colorsArray.push(firstGradient, secondGradient, greyColor);
            break;
        }

        default:
            break;
    }

    return {
        ticks,
        color: colorsArray,
    };
};

export const getConfig = ({ chartValue, range, value, statisticFontSize }) => ({
    percent: chartValue,
    autoFit: true,
    renderer: 'svg',
    startAngle: (-7.5 / 6) * Math.PI,
    endAngle: (1.5 / 6) * Math.PI,
    range,
    axis: {
        label: null,
        subTickLine: null,
        tickLine: null,
    },
    indicator: {
        pointer: {
            style: {
                stroke: '#BDBDBD',
                lineWidth: 3,
            },
        },
        pin: {
            style: {
                stroke: '#BDBDBD',
                fill: '#BDBDBD',
                r: 7,
            },
        },
    },
    statistic: {
        content: {
            offsetY: 30,
            content: `${value}°`,
            style: {
                fontSize: `${statisticFontSize}px`,
                lineHeight: 1,
                fontFamily: 'Arial',
                color: '#000',
                opacity: 1,
            },
        },
    },
    gaugeStyle: {
        lineCap: 'round',
    },
});
