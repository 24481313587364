import styled from 'styled-components';

export const Button = styled.div`
    cursor: pointer;
`;

export const Details = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
`;

export const Image = styled.img`
    object-fit: cover;
    width: 140px;
    height: 140px;
    border-radius: 0.75rem;
    border: 1px solid ${({ theme }) => theme.colors.border};
`;

export const Link = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    font-size: 1rem;
    & svg {
        font-size: 1.5rem;
    }
    & + ${Details} {
        margin-top: 1.5rem;
    }
`;

export const Card = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1.5rem;

    padding: 1rem;
    border-radius: 1rem;
    background-color: #FAFAFA;

    ${Link} {
        margin-top: 1rem;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;

    width: 600px;
    margin: 0 auto;
    padding: 2rem 0;

    ${Card} + ${Card} {
        margin-top: 2rem;
    }
`;

export const Title = styled.p`
    font-size: 1.25rem;
    font-weight: 800;
    margin-bottom: 0;

    color: ${({ theme }) => theme.colors.title};
`;

export const Log = styled.p`
    font-size: 0.75rem;
    font-weight: 300;
    margin-bottom: 0.5rem;

    color: ${({ theme }) => theme.colors.title};
    opacity: 0.4;
`;

export const Text = styled.div`
    font-size: 0.875rem;
    font-weight: 300;

    color: ${({ theme }) => theme.colors.text};
    opacity: 0.6;
`;

export const Header = styled.div`
    width: 100%;
    height: 50px;
    padding: 15px 20px;
    background-color: ${({ theme }) => theme.colors.title};
    color: #fff;
    font-size: 16px;
    font-weight: bold;
`;
