import { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import { showToastInfo, showToastError, showToastSuccess } from 'Utils';
import {
    GET_DEVICE_LOCATION_QUERY,
    DEVICES_LOCATION_SUBSCRIPTION,
    UPDATE_LOCATION_MUTATION,
    GET_USER_PROFILE_QUERY,
} from 'Constants';

import { TooltipDeviceMarker } from '../../../../tooltipDeviceMarker';
import { MapWidget } from '../../../../widgets';

const SUBSCRIPTION_STATUSES = {
    OK: 200,
    NOT_FOUND: 404,
};

export const DeviceLocation = ({ title, device }) => {
    const deviceToken = device.token;

    // User data
    const { data: userData } = useQuery(GET_USER_PROFILE_QUERY);
    const userName = userData?.getUserProfile?.owner || '';

    const [location, setLocation] = useState(null);

    const { data, loading, error, subscribeToMore, variables } = useQuery(GET_DEVICE_LOCATION_QUERY, {
        variables: { deviceToken },
        skip: !deviceToken,
    });

    const renderMetaInfo = (renderData) => <TooltipDeviceMarker {...renderData} />;

    const setLocationWithRenderMeta = (currentLocation) => setLocation({
        ...currentLocation,
        renderMetaInfo: renderMetaInfo.bind(null, {
            title: device.description,
        }),
    });

    const locationData = data?.getLocation;

    const [updateDeviceLocationMutation] = useMutation(UPDATE_LOCATION_MUTATION, {
        refetchQueries: [GET_DEVICE_LOCATION_QUERY],
    });

    const updateDeviceLocation = async () => {
        try {
            updateDeviceLocationMutation({
                variables: {
                    deviceToken,
                    userName,
                },
                update: () => {
                    showToastSuccess('Device location updated successfully');
                },
            });
        } catch (e) {
            showToastError(e.message);
        }
    };

    useEffect(() => {
        const isLocationDiffer = data?.getLocation !== location;
        if (!loading && !error && isLocationDiffer) {
            setLocationWithRenderMeta(locationData);
        }
    }, [locationData]);

    useEffect(() => {
        if (subscribeToMore && variables) {
            subscribeToMore({
                document: DEVICES_LOCATION_SUBSCRIPTION,
                variables,
                updateQuery: (_, { subscriptionData: { data: { locationSubscription } } }) => {
                    const { status, location: subscriptionLocation, error: subscriptionError } = locationSubscription;
                    switch (status) {
                        case (SUBSCRIPTION_STATUSES.OK):
                            if (!loading && !error) {
                                setLocationWithRenderMeta(subscriptionLocation);
                            }
                            break;
                        case (SUBSCRIPTION_STATUSES.NOT_FOUND):
                            const notFoundText = `
                                Google could not locate your device from the provided Wi-Fi scan.
                                Move your device to a different location and try again.
                            `;
                            showToastInfo(notFoundText);
                            break;
                        default:
                            showToastError(`${status} ${subscriptionError.message}`);
                            break;
                    }
                },
                onError: (err) => (console.error(err)),
            });
        }
    }, [subscribeToMore, variables]);

    return (
        <MapWidget
            location={location}
            title={title}
            isEmpty={loading || !location}
            onUpdateLocation={updateDeviceLocation}
        />
    );
};

DeviceLocation.propTypes = {
    title: PropTypes.string.isRequired,
    device: PropTypes.shape({
        token: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
    }).isRequired,
};
