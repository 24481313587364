import { useMemo } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useQuery } from '@apollo/client';

import { pascalCaseToString } from 'Utils';
import { GET_AZURE_DEVICE_BY_IOT_HUB_STRING } from 'Constants';

import { initialValues, validationSchema } from './config';
import { FormContent } from './formContent';

import * as Styled from './styled';

export const AzureIntegrationDefinition = ({
    connectionString,
    allDevices,
    onSubmit,
    submitText,
    onCancel,
    initialValues: parentInitialValues,
    isLoading,
}) => {
    const { data: azureDevicesData } = useQuery(
        GET_AZURE_DEVICE_BY_IOT_HUB_STRING,
        { variables: { iotHubString: connectionString } },
    );
    const alreadyUsedDevices = azureDevicesData?.getAzureDevicesByIotHubString ?? [];

    const parts = connectionString.split(';');
    const [hostname, url] = parts[0].split('=');
    const [keytype, keyname] = parts[1].split('=');

    const combinedInitialValues = useMemo(() => {
        const { deviceTokens = [], ...rest } = parentInitialValues;
        const devices = allDevices.map((device) => deviceTokens.includes(device.token));

        return {
            ...initialValues,
            devices,
            ...rest,
        };
    }, [allDevices]);

    return (
        <>
            <Styled.ConnectionLinesWrapper>
                <Styled.ConnectionLine>
                    <Styled.ConnectionLabel>
                        {pascalCaseToString(hostname)}
                    </Styled.ConnectionLabel>
                    <Styled.ConnectionValue>
                        {url}
                    </Styled.ConnectionValue>
                </Styled.ConnectionLine>
                <Styled.ConnectionLine>
                    <Styled.ConnectionLabel>
                        {pascalCaseToString(keytype)}
                    </Styled.ConnectionLabel>
                    <Styled.ConnectionValue>
                        {keyname}
                    </Styled.ConnectionValue>
                </Styled.ConnectionLine>
            </Styled.ConnectionLinesWrapper>
            <Formik
                initialValues={combinedInitialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {(props) => (
                    <FormContent
                        {...props}
                        allDevices={allDevices}
                        submitText={submitText}
                        onCancel={onCancel}
                        isEditMode={!isEmpty(parentInitialValues)}
                        alreadyUsedDevices={alreadyUsedDevices}
                        isLoading={isLoading}
                    />
                )}
            </Formik>
        </>
    );
};

AzureIntegrationDefinition.propTypes = {
    connectionString: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
    allDevices: PropTypes.array,
    submitText: PropTypes.string,
    onCancel: PropTypes.func,
    initialValues: PropTypes.object,
    isLoading: PropTypes.bool,
};

AzureIntegrationDefinition.defaultProps = {
    allDevices: [],
    submitText: 'Create',
    onCancel: null,
    initialValues: {},
    isLoading: false,
};
