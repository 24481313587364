import { useEffect, useMemo, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'formik';

import {
    Button,
    Checkbox,
    RadioButtonGroup,
    Select,
    BaseFileUploader,
    AlertBordered,
} from 'Components';

import { typeOptions, typeValues } from './config';

import * as Styled from './styled';

export const FormContent = ({
    prevStep,
    values,
    setValues,
    firmwareList,
    deviceTypes,
    customFiles,
    setCustomFiles,
    compiledFileProvided,
}) => {
    const [compiledFile, setCompiledFile] = useState(compiledFileProvided);
    const firmwareOptions = useMemo(() => firmwareList.map((firmware) => ({
        value: firmware,
        label: firmware,
    })), [firmwareList]);

    const fileOnChange = (index, file) => {
        const newFiles = [...customFiles];

        if (newFiles[index]) {
            newFiles[index].file = file;
        } else {
            newFiles.push({
                deviceType: deviceTypes[index],
                file,
            });
        }
        
        setCustomFiles(newFiles);
    };

    useEffect(() => {
        if (values.changeFirmware && compiledFile) {
            setValues({
                ...values,
                type: typeValues.custom,
            });
        } else if (values.changeFirmware && !compiledFile) {
            setValues({
                ...values,
                type: typeValues.default,
                version: firmwareOptions[0]?.value ?? '',
            });
        } else {
            setValues({ changeFirmware: values.changeFirmware });
            setCompiledFile(null);
        }
    }, [values.changeFirmware]);

    useEffect(() => {
        const newValues = { ...values };

        if (values.type === typeValues.custom) {
            delete newValues.version;
            newValues.file = '';
        }

        if (values.type === typeValues.default) {
            delete newValues.file;
            newValues.version = firmwareOptions[0]?.value ?? '';
        }

        setValues(newValues);
    }, [values.type]);

    return (
        <Form>
            <Styled.FormWrapper>
                <Styled.FormItem>
                    <Field
                        name="changeFirmware"
                        component={Checkbox}
                        title="Change device firmware"
                    />
                </Styled.FormItem>
                {values.type && (
                    <Styled.FormItem>
                        <Styled.ItemLabel as="div">
                            Firmware type
                        </Styled.ItemLabel>
                        <Field
                            name="type"
                            component={RadioButtonGroup}
                            options={typeOptions}
                        />
                    </Styled.FormItem>
                )}
                {values.version && (
                    <Styled.FormItem>
                        <Styled.ItemLabel>
                            Firmware Version
                        </Styled.ItemLabel>
                        <Field
                            name="version"
                            component={Select}
                            values={firmwareOptions}
                        />
                    </Styled.FormItem>
                )}
                {values.type === typeValues.custom && !!deviceTypes.length && (
                    <Styled.FormItem>
                        <Styled.ItemLabel as="div">
                            Upload custom firmware(s)
                        </Styled.ItemLabel>
                        {deviceTypes.map((deviceType, index) => (
                            <Fragment key={deviceType}>
                                <Styled.ItemLabel as="div">
                                    {deviceType}
                                </Styled.ItemLabel>
                                <BaseFileUploader
                                    expectedExtensions=".bin"
                                    maxSize={4194304}
                                    defaultName="pymakr.bin"
                                    pymakrUpload={compiledFile}
                                    onChange={(file) => fileOnChange(index, file)}
                                />
                            </Fragment>
                        ))}
                    </Styled.FormItem>
                )}
                {values.type === typeValues.custom && !deviceTypes.length && (
                    <AlertBordered
                        message="To deploy custom firmware the project needs to have devices."
                    />
                )}
                <Styled.ButtonsWrapper>
                    <Button
                        buttonType="transparent"
                        onClick={prevStep}
                    >
                        Back
                    </Button>
                    <Button type="submit">
                        Next
                    </Button>
                </Styled.ButtonsWrapper>
            </Styled.FormWrapper>
        </Form>
    );
};

FormContent.propTypes = {
    prevStep: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
    setValues: PropTypes.func.isRequired,
    firmwareList: PropTypes.arrayOf(PropTypes.string).isRequired,
    deviceTypes: PropTypes.arrayOf(PropTypes.string),
    customFiles: PropTypes.array.isRequired,
    setCustomFiles: PropTypes.func.isRequired,
    compiledFileProvided: PropTypes.bool,
};

FormContent.defaultProps = {
    deviceTypes: [],
    compiledFileProvided: false,
};
