import { useMemo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { NoData, Alert, Table, Loader } from 'Components';
import { ML_RESPONSE_STATUSES } from 'Constants';

import * as StyledGlobal from '../../../../styled';

import * as Styled from './styled';

export const TrainingPerformance = ({ training, loading, logsList }) => {
    const { logColumns, logsForTable } = useMemo(
        () => {
            const columnsResult = logsList[0]?.map((label) => ({
                title: label.trim(),
                dataIndex: label.trim(),
            }));

            const logsResult = logsList.slice(1).map((log) => {
                const result = log.reduce((transformedLog, value, index) => ({
                    ...transformedLog,
                    [columnsResult[index].dataIndex]: value,
                }), {});

                return result;
            });

            return { logColumns: columnsResult, logsForTable: logsResult };
        },
        [logsList],
    );

    const { testsColumns, testsForTable } = useMemo(
        () => {
            const labelsObject = training?.output?.NN?.tests?.id_2_label ?? {};

            const columnsResult = Object.values(labelsObject).map((label) => ({
                title: label,
                dataIndex: label,
            }));
            columnsResult.unshift({ title: '', dataIndex: 'name' });

            const testsResult = training?.output?.NN?.tests?.confusion?.map((item, index) => {
                const result = {
                    name: columnsResult[index + 1].title,
                };

                item.forEach((subItem, subIndex) => {
                    result[columnsResult[subIndex + 1].title] = subItem;
                });

                return result;
            });

            return { testsColumns: columnsResult, testsForTable: testsResult };
        },
        [training],
    );

    const isEnoughData = useMemo(
        () => {
            const { train } = training?.output?.NN ?? {};

            return train?.size_of_train_data_set >= train?.trainable_weights * 2;
        },
        [training],
    );

    if (isEmpty(training) && !loading) {
        return (
            <NoData
                caption="Training Output"
                text="Start training your neural network."
            />
        );
    }

    const logsTable = (
        <Styled.LogsWrapper>
            <Styled.LogsTitle>
                Training logs
                {loading && (
                    <Styled.LoaderWrapper>
                        <Loader overlay size={20} />
                    </Styled.LoaderWrapper>
                )}
            </Styled.LogsTitle>
            {!logsList.length && (
                <StyledGlobal.FormAlertSection>
                    <Alert
                        type="info"
                        message="The model is being trained..."
                    />
                </StyledGlobal.FormAlertSection>
            )}
            {!!logsList.length && (
                <Table
                    columns={logColumns}
                    data={logsForTable}
                    rowKey={logColumns[0]?.dataIndex}

                />
            )}
        </Styled.LogsWrapper>
    );

    const trainingTable = (
        <>
            <h2>Training Performance</h2>
            <Styled.ResultsWrapper>
                <Styled.Result>
                    <Styled.ResultValue>
                        {training?.output?.NN?.tests?.accuracy?.toFixed(2)}
                    </Styled.ResultValue>
                    <Styled.ResultLabel>
                        Accuracy
                    </Styled.ResultLabel>
                </Styled.Result>
                <Styled.Result>
                    <Styled.ResultValue>
                        {training?.output?.NN?.tests?.loss?.toFixed(2)}
                    </Styled.ResultValue>
                    <Styled.ResultLabel>
                        Loss
                    </Styled.ResultLabel>
                </Styled.Result>
            </Styled.ResultsWrapper>
            <Table
                columns={testsColumns}
                data={testsForTable}
                rowKey="name"
            />
            <StyledGlobal.FormAlertSection>
                <Alert
                    type={isEnoughData ? 'success' : 'warning'}
                    message={isEnoughData
                        ? 'The model contains enough data to train properly'
                        : 'The model does not contain enough data to train properly'}
                />
            </StyledGlobal.FormAlertSection>
        </>
    );

    const renderWarning = () => {
        let alertType = '';

        switch (training?.state) {
            case ML_RESPONSE_STATUSES.FAILURE:
            case ML_RESPONSE_STATUSES.RETRY:
                alertType = 'error';
                break;
            case ML_RESPONSE_STATUSES.PENDING:
                alertType = 'warning';
                break;
            default:
                break;
        }

        const message = (
            <>
                <div>Training Performance Status: {training?.state}</div>
                <div>Failure cause: {training?.failure_cause}</div>
            </>
        );

        return (
            <StyledGlobal.FormAlertSection>
                <Alert
                    type={alertType}
                    message={message}
                />
            </StyledGlobal.FormAlertSection>
        );
    };

    return (
        <>
            {(loading || !!logsList.length) && logsTable}
            {training?.state === ML_RESPONSE_STATUSES.SUCCESS && !loading && trainingTable}
            {training?.state !== ML_RESPONSE_STATUSES.SUCCESS && !loading && renderWarning()}
        </>
    );
};

TrainingPerformance.propTypes = {
    training: PropTypes.object,
    loading: PropTypes.bool,
    logsList: PropTypes.array,
};

TrainingPerformance.defaultProps = {
    training: {},
    loading: false,
    logsList: [],
};
