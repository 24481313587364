import { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useHistory, useParams, useLocation } from 'react-router-dom';

import { Breadcrumbs, PageHeader, Loader } from 'Components';
import { showToastSuccess, showToastError } from 'Utils';
import {
    BREADCRUMBS_LABELS,
    GET_APPLICATION_QUERY,
    ADD_RELEASE_MUTATION,
    GET_RELEASES_QUERY,
} from 'Constants';

import {
    ProjectConfiguration,
    FirmwareVersion,
    ProjectCode,
    ReleaseDescription,
    Summary,
} from './components';
import { transformReleaseDataForCreation, getCustomFirmwareObject } from './helpers';

export const CreateProjectRelease = () => {
    const history = useHistory();
    const { id } = useParams();
    const location = useLocation();

    const [compilationFile, setCompilationFile] = useState(null);
    const [providedHierarchy, setProvidedHierarchy] = useState(null);

    const [formValues, setFormValues] = useState({});
    const [step, setStep] = useState(1);

    const { data, loading } = useQuery(GET_APPLICATION_QUERY, { variables: { id } });
    const project = data?.getApplication;

    useEffect(() => {
        const storedReleaseFile = localStorage.getItem('pymakr-release-file');
        if (storedReleaseFile) {
            try {
                const parsedFile = JSON.parse(storedReleaseFile);
                const customFirmwareFile = getCustomFirmwareObject(parsedFile.compilationFile);
                setCompilationFile(customFirmwareFile);
                setProvidedHierarchy(parsedFile.hierarchy);
            } catch (error) {
                // empty catch to avoid JSON.parse() failure crash
            }
        }

        return () => localStorage.removeItem('pymakr-release-file');
    }, []);

    const [createRelease, { loading: isLoading }] = useMutation(
        ADD_RELEASE_MUTATION,
        { refetchQueries: [GET_RELEASES_QUERY] },
    );

    const redirectToPrevPage = () => history.goBack();

    const stepBack = () => {
        setStep(step - 1);
    };

    const stepForward = (values) => {
        setFormValues(values);
        setStep(step + 1);
    };

    const onReleaseCreate = async () => {
        const releaseData = await transformReleaseDataForCreation(project, formValues);

        try {
            await createRelease({
                variables: { details: releaseData },
                update: () => {
                    showToastSuccess('Release created successfully');
                    history.push(location.pathname.split('/').slice(0, -1).join('/'));
                },
            });
        } catch (error) {
            showToastError(error.message);
        }
    };

    if (loading) {
        return <Loader />;
    }

    let content = null;

    switch (step) {
        case 2:
            content = (
                <FirmwareVersion
                    nextStep={stepForward}
                    prevStep={stepBack}
                    formValues={formValues}
                    projectId={project._id}
                />
            );
            break;

        case 3:
            content = (
                <ProjectCode
                    nextStep={stepForward}
                    prevStep={stepBack}
                    formValues={formValues}
                />
            );
            break;

        case 4:
            content = (
                <ReleaseDescription
                    nextStep={stepForward}
                    prevStep={stepBack}
                    formValues={formValues}
                />
            );
            break;

        case 5:
            content = (
                <Summary
                    nextStep={onReleaseCreate}
                    prevStep={stepBack}
                    formValues={formValues}
                    project={project}
                    isLoading={isLoading}
                />
            );
            break;

        case 1:
        default:
            content = (
                <ProjectConfiguration
                    nextStep={() => stepForward({ compilationFile, file: providedHierarchy })}
                    prevStep={redirectToPrevPage}
                    project={project}
                />
            );
    }

    return (
        <>
            <Breadcrumbs
                labels={BREADCRUMBS_LABELS.createProjectRelease}
                entityName={project.name}
            />
            <PageHeader
                title="Create Release"
                step={step}
                maxStep={5}
                stepper
            />
            {content}
        </>
    );
};
