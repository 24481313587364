import crypto from 'crypto';
import { CHECK_OR_CREATE_PYLS_PROFILE } from 'Constants';

export class PycomLoraServer {
    constructor(props) {
        this.client = props.client;
    }

    getHexKey(length = 16) {
        return crypto.randomBytes(length).toString('hex');
    }

    async createPycomLoraServerProfile(loraRegion) {
        await this.client.mutate({
            variables: {
                loraRegion,
            },
            mutation: CHECK_OR_CREATE_PYLS_PROFILE,
        });
    }
}
