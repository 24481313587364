import styled from 'styled-components';

export const FormContent = styled.div`
    max-width: 293px;
`;

export const Title = styled.div`
    color: ${({ theme }) => theme.colors.title};
    margin-top: 40px;
    margin-bottom: 14px;
    font-size: 22px;
    font-weight: bold;
`;

export const FormItem = styled.div`
    margin-bottom: 10px;

    & > span {
        color: ${({ theme }) => theme.colors.title};
        padding-top: 8px;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.38;
    }

    input,
    textarea {
        width: 100%;
    }
`;

export const ItemLabel = styled.span`
    display: block;
    margin-bottom: 4px;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    margin-top: 55px;
    
    & > *:nth-child(2) {
        margin-left: 30px;
    }
`;
