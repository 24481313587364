export const onceSimSessionColumns = [
    {
        title: 'Operator',
        dataIndex: 'operator',
    },
    {
        title: 'Country',
        dataIndex: 'location',
    },
    {
        title: 'Technology',
        dataIndex: 'technology',
    },
    {
        title: 'Started',
        dataIndex: 'created',
    },
    {
        title: 'Duration',
        dataIndex: 'duration',
    },
    {
        title: 'Status',
        dataIndex: 'status',
    },
];
