import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { GET_APPLICATION_LAST_MESSAGES } from 'Constants';
import { getStringifiedPayload } from 'Utils';
import { useMessageSubscription } from 'Hooks';

import { TooltipDeviceMarker } from '../../../../tooltipDeviceMarker';
import { MapWidget } from '../../../../widgets';

export const Map = ({ chartId, title }) => {
    const { data, error, loading, refetch } = useQuery(
        GET_APPLICATION_LAST_MESSAGES,
        {
            variables: { chartId },
        },
    );
    const projectMessages = data?.getApplicationLastMessages ?? [];

    const subscribeData = useMemo(() => {
        const deviceTokens = projectMessages.map((messageObj) => messageObj.device.token);
        const messagePin = projectMessages[0]?.lastMessage?.messagePin;

        return { deviceTokens, messagePin };
    }, [projectMessages]);

    useMessageSubscription(subscribeData.deviceTokens, subscribeData.messagePin, refetch);

    const parsedMessages = useMemo(
        () => projectMessages.map((item) => {
            const message = item.lastMessage;

            return {
                ...message,
                value: getStringifiedPayload(message.payload),
                date: new Date(message.createdAt),
                device: item.device.description,
            };
        }),
        [projectMessages],
    );

    const renderMetaInfo = (renderData) => <TooltipDeviceMarker {...renderData} />;

    const { locations, isSomeDataBroken } = useMemo(
        () => parsedMessages.reduce((accumulator, message) => {
            const matched = (/((-)?([0-9.]*))##((-)?([0-9.]*))/).test(message.value);

            if (matched) {
                const positions = message.value.split('##');
                accumulator.locations.push({
                    key: accumulator.locations.length + 1,
                    location: {
                        lat: +positions[0],
                        lng: +positions[1],
                        renderMetaInfo: renderMetaInfo.bind(null, {
                            title: message.device,
                        }),
                    },
                });
            } else {
                accumulator.isSomeDataBroken = true;
            }

            return accumulator;
        }, { locations: [], isSomeDataBroken: false }),
        [parsedMessages],
    );

    return (
        <MapWidget
            locations={locations}
            title={title}
            isEmpty={loading || error}
            isSomeDataBroken={isSomeDataBroken}
            isDataBroken={!locations.length && isSomeDataBroken}
        />
    );
};

Map.propTypes = {
    chartId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};
