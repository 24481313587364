import { useContext, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { Button, FlashAnimation } from 'Components';
import { displayDuration } from 'Utils';
import { SoftServerService } from 'Services';

import { FirmwareContext } from '../../context';

import * as Styled from './styled';

const { REACT_APP_API_URL } = process.env;
const UNKNOWN = 'Unknown';
const CUSTOM = 'Custom';

export const FirmwareUpload = ({
    backStep,
    downloadFileFirmware,
    addLogs,
    cleanLogs,
    logs,
    disabled,
    nextStep,
}) => {
    const { firmwareData, setFirmwareData } = useContext(FirmwareContext);
    const [firmwareConfig, setFirmwareConfig] = useState(null);

    const [timer, setTimer] = useState(0);
    const interval = useRef();

    const generateFirmwareConfig = async () => {
        let firmwareCurrentConfig;
        const { customFile, deviceType } = firmwareData;
        if (!isEmpty(customFile)) {
            firmwareCurrentConfig = {
                fileName: `/tmp/${deviceType}_${customFile?.file?.name}`,
                file: `${REACT_APP_API_URL}/${customFile?.url}`,
            };
        } else {
            const {
                firmwareVersionObject: { version, type },
            } = firmwareData;
            const configurationFromAPI =
                await SoftServerService.getFirmwareDownloadDetails(
                    deviceType,
                    type.toLowerCase(),
                    version,
                );
            firmwareCurrentConfig = {
                ...configurationFromAPI,
                local_file: false,
                fileName: `/tmp/${deviceType}_${version}`,
            };
            const message = `Getting firmware ${version} details for ${deviceType}...`;
            addLogs(message);
        }
        setFirmwareConfig(firmwareCurrentConfig);
        setFirmwareData({ ...firmwareData, firmwareConfig: firmwareCurrentConfig });
    };

    const downloadFile = async () => {
        const version =
            firmwareData.firmwareVersionObject.version === UNKNOWN
                ? CUSTOM
                : firmwareData.firmwareVersionObject.version;
        addLogs(
            `Downloading ${version} firmware for ${firmwareData.deviceType}...`,
        );
        await downloadFileFirmware(firmwareConfig);
    };

    const startTimer = () => {
        interval.current = setInterval(() => {
            setTimer((prevState) => prevState + 100);
        }, 100);
    };

    const endTimer = () => {
        clearInterval(interval.current);
    };

    useEffect(() => {
        startTimer();
        cleanLogs();
        generateFirmwareConfig();
        return () => endTimer();
    }, []);

    useEffect(() => {
        if (!disabled) {
            endTimer();
            setFirmwareData({ ...firmwareData, firmwareTime: timer });
        }
    }, [disabled]);

    useEffect(() => {
        if (!isEmpty(firmwareConfig)) {
            downloadFile();
            setFirmwareData({ ...firmwareData, firmwareConfig });
        }
    }, [firmwareConfig]);

    return (
        <div>
            <Styled.Title>Deploying Firmware</Styled.Title>
            <Styled.Header>
                <div>
                    <Styled.InfoItem>
                        <span>Device: </span>
                        <span>{firmwareData.deviceType?.toUpperCase()}</span>
                    </Styled.InfoItem>
                    <Styled.InfoItem>
                        <span>Version: </span>
                        <span>
                            {firmwareData.firmwareVersionObject?.version}
                        </span>
                    </Styled.InfoItem>
                    {timer && (
                        <Styled.InfoItem>
                            <span>Total time: </span>
                            <span>{displayDuration(timer)}</span>
                        </Styled.InfoItem>
                    )}
                </div>
                <FlashAnimation isLoaded={disabled} />
            </Styled.Header>
            <Styled.Log>
                {logs.map((item) => (
                    <Styled.LogItem key={item}>{item}</Styled.LogItem>
                ))}
            </Styled.Log>
            <Styled.ButtonWrapper>
                <Button buttonType="transparent" onClick={backStep}>
                    Back
                </Button>
                <Button
                    disabled={disabled}
                    onClick={nextStep}
                >
                    Next
                </Button>
            </Styled.ButtonWrapper>
        </div>
    );
};

FirmwareUpload.propTypes = {
    backStep: PropTypes.func.isRequired,
    downloadFileFirmware: PropTypes.func.isRequired,
    addLogs: PropTypes.func.isRequired,
    cleanLogs: PropTypes.func.isRequired,
    logs: PropTypes.array.isRequired,
    disabled: PropTypes.bool.isRequired,
    nextStep: PropTypes.func.isRequired,
};
