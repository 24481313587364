import styled from 'styled-components';
import { Collapse as AntCollapse } from 'antd';

export const Title = styled.div`
    color: ${({ theme }) => theme.colors.title};
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: bold;
`;

export const FormItem = styled.div`
    margin-bottom: 10px;
    max-width: 640px;
`;

export const ItemLabel = styled.div`
    color: ${({ theme }) => theme.colors.title};
    padding-top: 8px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.38;
    margin-bottom: 5px;
`;

export const Collapse = styled(AntCollapse)`
    .ant-collapse-header {
        padding: 0 !important;
    }

    .ant-collapse-content-box {
        padding: 0 !important;
    }
`;

export const CollapseHeader = styled.div`
    transition: 0.3s;
    color: ${({ theme }) => theme.colors.active};

    &:hover {
        color: inherit;
    }
`;

export const Code = styled.pre`
    padding: 10px 20px;
    background: #fff;
    border: 1px solid ${({ theme }) => theme.colors.border};
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    margin-top: 55px;
    
    & > * {
        margin-right: 30px;
    }
`;

export const FirmwareFilename = styled.p`
    display: block;
    margin-bottom: 0;
`;
