import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import { GET_APPLICATIONS, ROUTES } from 'Constants';
import { ReactComponent as AddProjectIcon } from 'Assets/icons/computer.svg';
import { projectColumns } from 'Components';

import { RedirectView, TableView, ErrorView, LoadingView } from './tableViews';

export const ExportToRelease = ({ onSelectProject }) => {
    const { data, loading, error } = useQuery(GET_APPLICATIONS);
    const applications = data?.getApplications ?? [];

    if (loading) {
        return <LoadingView />;
    }

    if (error) {
        return <ErrorView />;
    }

    if (!applications.length) {
        return (
            <RedirectView
                title="No projects in work"
                subtitle="Create Project"
                route={ROUTES.projects.create}
                icon={<AddProjectIcon />}
            />
        );
    }

    return (
        <TableView
            title="Choose a project"
            columns={projectColumns}
            items={applications}
            onSelect={onSelectProject}
        />
    );
};

ExportToRelease.propTypes = {
    onSelectProject: PropTypes.func.isRequired,
};
