import styled from 'styled-components';

export const ButtonSection = styled.div`
    display: flex;

    button {
        margin-right: 15px;
    }
`;

export const Subtitle = styled.div`
    margin: -20px auto 50px;
    margin-bottom: 50px;
    font-size: 16px;
    text-align: center;
    max-width: 500px;
`;
