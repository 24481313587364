export const columns = [
    {
        title: 'Name',
        dataIndex: 'description',
    },
    {
        title: 'Type',
        dataIndex: 'deviceType',
    },
    {
        title: 'FW',
        dataIndex: 'firmware',
        render: (data) => (data?.version ?? ''),
    },
];
