import PropTypes from 'prop-types';

import { SessionTable } from './sessionTable';
import { SessionMap } from './sessionMap';
import { SimCardWidgetBox } from '../simCardWidgetBox';

export const SimCardSessions = ({ columns, tableData, geoData }) => (
    <SimCardWidgetBox title="Sessions">
        {columns && <SessionTable columns={columns} tableData={tableData} />}
        {!!geoData.lat && !!geoData.lng && <SessionMap geoData={geoData} />}
    </SimCardWidgetBox>
);

SimCardSessions.defaultProps = {
    tableData: [],
    columns: [],
    geoData: null,
};

SimCardSessions.propTypes = {
    columns: PropTypes.array,
    tableData: PropTypes.array,
    geoData: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
    }),
};
