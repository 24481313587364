import { useQuery } from '@apollo/client';

import { GET_SIGFOX_CREDENTIALS, TAB } from 'Constants';
import { SigfoxModal, Loader } from 'Components';

import { SigfoxExists } from './sigfoxExists';

export const SigfoxSettings = () => {
    const { data, loading } = useQuery(GET_SIGFOX_CREDENTIALS);

    const { credentials, other } = data?.getSigfoxIntegration || {};

    if (loading) {
        return <Loader />;
    }

    if (credentials?.login && credentials?.password) {
        return <SigfoxExists credentials={credentials} other={other} />;
    }

    return (
        <SigfoxModal typeOfForm={TAB} />
    );
};
