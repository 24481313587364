import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory/*, useParams, useLocation */ } from 'react-router-dom';

import {
    Breadcrumbs,
    PageHeader,
    ModelDeviceSelection,
    ModelDefinition,
    ModelConfiguration,
} from 'Components';
import { showToastSuccess, showToastError } from 'Utils';
import {
    BREADCRUMBS_LABELS,
    ADD_ML_MODEL_MUTATION,
    ROUTES,
} from 'Constants';

export const CreateModel = () => {
    const history = useHistory();
    // todo: maybe refetch models here
    const [createModel, { loading: isLoading }] = useMutation(ADD_ML_MODEL_MUTATION/*, { refetchQueries: [GET_APPLICATIONS] }*/);
    const [formValues, setFormValues] = useState({});
    const [step, setStep] = useState(1);

    const redirectToModelsPage = () => history.goBack();

    const stepBack = (values) => {
        setFormValues(values);
        setStep(step - 1);
    };

    const stepForward = (values) => {
        setFormValues(values);
        setStep(step + 1);
    };

    const onModelCreate = async (values) => {       
        try {
            await createModel({
                variables: { model: values },
                update: (cache, { data: { addMlModel } }) => {
                    showToastSuccess('Model created successfully');
                    const { modelDetails: { main, dataAcquisition } } = ROUTES.machineLearning;
                    history.push(`${main}/${addMlModel._id}${dataAcquisition}`);
                },
            });
        } catch (error) {
            showToastError(error.message);
        }
    };

    let content = null;

    switch (step) {
        case 2:
            content = (
                <ModelConfiguration
                    nextStep={stepForward}
                    prevStep={stepBack}
                    formValues={formValues}
                />
            );
            break;

        case 3:
            content = (
                <ModelDeviceSelection
                    nextStep={onModelCreate}
                    prevStep={stepBack}
                    formValues={formValues}
                    isLoading={isLoading}
                />
            );
            break;

        case 1:
        default:
            content = (
                <ModelDefinition
                    nextStep={stepForward}
                    prevStep={redirectToModelsPage}
                    formValues={formValues}
                />
            );
    }

    return (
        <>
            <Breadcrumbs labels={BREADCRUMBS_LABELS.machineLearning} />
            <PageHeader
                title="Create Model"
                step={step}
                maxStep={3}
                stepper
            />
            {content}
        </>
    );
};
