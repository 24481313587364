import gql from 'graphql-tag';

export const GET_STORED_FCOTA = gql`
    query getStoredFCOTA($deviceToken: String!) {
        getStoredFCOTA(deviceToken: $deviceToken) {
          _id
          userName
          deviceToken
          hierarchy
          files {
              content
              path
              size
              lastSync
          }
          lastHierarchySync
        }
    }
`;

export const PING_FCOTA_MUTATION = gql`
    mutation pingFCOTA($deviceToken: String!) {
        pingFCOTA(deviceToken: $deviceToken)
    }
`;

export const SYNC_FCOTA_MUTATION = gql`
    mutation syncFCOTA($deviceToken: String!) {
        syncFCOTA(deviceToken: $deviceToken)
    }
`;

export const GET_FCOTA_FILE_MUTATION = gql`
    mutation syncFile($deviceToken: String!, $path: String!) {
        syncFile(deviceToken: $deviceToken, path: $path)
    }
`;

export const UPDATE_FCOTA_FILE_MUTATION = gql`
    mutation updateFile($deviceToken: String!, $path: String!, $content: String!) {
        updateFile(deviceToken: $deviceToken, path: $path, content: $content)
    }
`;

export const UPDATE_FCOTA_FILE_MUTATION_NO_RESET = gql`
    mutation updateFileNoReset($deviceToken: String!, $path: String!, $content: String!) {
        updateFileNoReset(deviceToken: $deviceToken, path: $path, content: $content)
    }
`;

export const DELETE_FCOTA_FILE_MUTATION = gql`
    mutation deleteFile($deviceToken: String!, $path: String!) {
        deleteFile(deviceToken: $deviceToken, path: $path)
    }
`;

export const FCOTA_HIERARCHY_SUBSCRIPTION = gql`
    subscription hierarchySubscription($deviceToken: String!) {
      hierarchySubscription(deviceToken: $deviceToken) {
        hierarchy
      }
    }
`;

export const FCOTA_FILE_SUBSCRIPTION = gql`
    subscription fileSubscription($deviceToken: String!) {
        fileSubscription(deviceToken: $deviceToken) {
            content
            path
            size
            lastSync
            deviceToken
        }
    }
`;

export const FCOTA_PING_SUBSCRIPTION = gql`
    subscription pingSubscription($deviceToken: String!) {
      pingSubscription(deviceToken: $deviceToken) {
        activity
      }
    }
`;
