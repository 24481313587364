import styled from 'styled-components';

export const RadioButton = styled.div`
    width: fit-content;
`;

export const Container = styled.label`
    position: relative;
    width: fit-content;
    padding-left: 31px;
    margin-bottom: 12px;
    display: flex;
    cursor: pointer;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    -ms-user-select: none;
    color: ${({ theme }) => theme.colors.text};
    font-size: 14px;
    line-height: 1.57;

    & input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    & input:checked~span:after {
        display: block;
        background-color: ${({ theme }) => theme.colors.active};
        position: absolute;
        top: 2px;
        left: 2px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
    }

    & span {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: solid 1px ${({ theme }) => theme.colors.title};
        background-color: ${({ theme }) => theme.colors.activeText};

        &::after {
            content: "";
            display: none;
        }
    }
`;
