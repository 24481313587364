import { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { PageHeader, Table, IconButton, projectColumns } from 'Components';
import { ROUTES, DELETE_APPLICATION, GET_APPLICATIONS, ADD_PROJECT_TYPES } from 'Constants';
import { ReactComponent as ProjectIcon } from 'Assets/icons/projects.svg';
import { ReactComponent as TrashIcon } from 'Assets/icons/trash.svg';

import { AddProjectSelection } from '../addProjectSelection';

import * as Styled from './styled';

export const ProjectsTable = ({ data }) => {
    const history = useHistory();
    const [addProjectModalOpen, setAddProjectModalOpen] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [deleteProject] = useMutation(DELETE_APPLICATION, { refetchQueries: [GET_APPLICATIONS] });

    const deleteProjects = async () => {
        if (!selectedIds.length) {
            return;
        }

        // TODO: change to one endpoint to delete several projects
        selectedIds.forEach((id) => {
            deleteProject({ variables: { id } });
        });
    };

    const onRowClick = (record) => {
        history.push(`${ROUTES.projects.main}/${record._id}/dashboard`);
    };

    const handleAddProjectSelectionClick = (type) => {
        switch (type) {
            case ADD_PROJECT_TYPES.FAST_IOT:
                history.push(ROUTES.projects.createFastIot);
                break;
            case ADD_PROJECT_TYPES.DEFAULT:
                history.push(ROUTES.projects.create);
                break;
            default:
                break;
        }
    };

    return (
        <>
            <PageHeader
                title="All Projects"
                description={[
                    'Group devices together to manage them easily',
                ]}
                stepper={false}
            />
            <Styled.TableWrapper data-cy="project-table-wrapper">
                <Styled.TableControls>
                    <IconButton
                        onClick={() => setAddProjectModalOpen(true)}
                        Icon={ProjectIcon}
                        cyData="add-project-button"
                    >
                        Add Project
                    </IconButton>
                    <IconButton
                        onClick={deleteProjects}
                        Icon={TrashIcon}
                        cyData="delete-project-button"
                    >
                        Delete Project
                    </IconButton>
                </Styled.TableControls>
                <Table
                    columns={projectColumns}
                    data={data}
                    onSelect={setSelectedIds}
                    onRowClick={onRowClick}
                />
            </Styled.TableWrapper>
            <AddProjectSelection
                modalOpen={addProjectModalOpen}
                onModalClose={() => setAddProjectModalOpen(false)}
                handleOnModalBtnClick={handleAddProjectSelectionClick}
            />
        </>
    );
};

ProjectsTable.propTypes = {
    data: PropTypes.array.isRequired,
};
