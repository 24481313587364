import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';

import {
    GET_PYMESH_BY_APPLICATION_ID,
    DELETE_PYMESH_MUTATION,
    JOIN_KEY_EXISTS_QUERY,
    UPDATE_PYMESH_MUTATION,
    ROUTES,
    PYMESH_REGIONS,
    PYMESH_BANDWIDTHS,
} from 'Constants';
import { Button, pymeshNetworkSettingsValidationSchema, Loader } from 'Components';
import { showToastError, showToastSuccess } from 'Utils';

import { FormContent } from './formContent';
import { getInitialValues } from './config';
import * as Styled from './styled';

export const PymeshSettings = ({ applicationId }) => {
    const history = useHistory();
    const client = useApolloClient();

    const [initialLoading, setInitialLoading] = useState(true);

    const { data, loading } = useQuery(GET_PYMESH_BY_APPLICATION_ID, {
        variables: { applicationId },
        fetchPolicy: 'cache-and-network',
        skip: !applicationId,
    });
    const pymesh = data?.getPymeshByApplicationId;

    useEffect(
        () => {
            if (initialLoading && !loading) {
                setInitialLoading(false);
            }
        },
        [loading],
    );

    const [updatePymeshMutation] = useMutation(UPDATE_PYMESH_MUTATION, {
        update: (() => {
            showToastSuccess('Pymesh updated successfully');
        }),
    });

    const [deletePymeshMutation] = useMutation(DELETE_PYMESH_MUTATION, {
        refetchQueries: [GET_PYMESH_BY_APPLICATION_ID],
        update: (() => {
            showToastSuccess('Pymesh removed successfully');
        }),
    });

    const handleGoToPymesh = () => history.push(`${ROUTES.projects.main}/${applicationId}${ROUTES.projects.projectDetails.pymesh}`);

    const savePymesh = async (values) => {
        const { data: { joinKeyExist } } = await client.query({
            query: JOIN_KEY_EXISTS_QUERY,
            variables: { joinKey: values.joinKey },
        });

        if (joinKeyExist) {
            return showToastError('The join key already exists, please generate another one');
        }
        const regionInfo = PYMESH_REGIONS.find((item) => item.region.name === values.region);
        const bandwidthInfo = PYMESH_BANDWIDTHS.find((item) => item.label === values.bandwidth);

        const pymeshObject = {
            _id: pymesh._id,
            applicationId,
            region: +regionInfo.region.value,
            frequency: +values.frequency,
            bandwidth: +bandwidthInfo.value,
            spreadFactor: +values.spreadFactor,
            joinKey: values.joinKey,
            devices: pymesh.devices.map((device) => ({ token: device.token, brEnable: device.brEnable })),
        };

        try {
            await updatePymeshMutation({ variables: { pymesh: pymeshObject } });
        } catch (error) {
            showToastError('Pymesh updating has been failed');
        }
    };

    const deletePymesh = async () => {
        try {
            await deletePymeshMutation({ variables: { pymeshID: pymesh._id } });
        } catch (error) {
            showToastError('Pymesh removal failed');
        }
    };

    if (initialLoading) {
        return <Loader />;
    }

    if (!pymesh && !loading) {
        return (
            <Styled.Redirect>
                <h3>There is no Mesh created for the current Project</h3>
                <div> You can create pymesh if you have devices of supported types, added to your project</div>
                <Button onClick={handleGoToPymesh}>
                    Go to pymesh
                </Button>
            </Styled.Redirect>
        );
    }

    return (
        <Formik
            initialValues={getInitialValues(pymesh)}
            validationSchema={pymeshNetworkSettingsValidationSchema}
            onSubmit={savePymesh}
        >
            {(props) => (
                <FormContent
                    {...props}
                    deletePymesh={deletePymesh}
                />
            )}
        </Formik>
    );
};

PymeshSettings.defaultProps = {
    applicationId: null,
};

PymeshSettings.propTypes = {
    applicationId: PropTypes.string,
};
