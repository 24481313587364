import { v4 as uuid } from 'uuid';

const { REACT_APP_FIRMWARE_SOCKET } = process.env;

export const sendWebSocket = async (
    ws,
    action,
    params = {},
    message = null,
) => {
    const timeoutReference = `${action}_${uuid()}`;
    const options = {
        action,
        message,
        timeoutReference,
        params,
    };
    if (ws?.readyState === 1) {
        await ws.send(JSON.stringify(options));
    }

    return timeoutReference;
};

export const openWebSocket = (callback, args) => {
    const ws = new WebSocket(REACT_APP_FIRMWARE_SOCKET);
    ws.onmessage = (event) => {
        args.onMessage(event, callback);
    };

    ws.onopen = () => {
        args.onOpen();
    };

    ws.onclose = () => {
        args.onClose();
    };

    ws.onerror = (event) => {
        console.warn(event);
        args.onError();
    };
    return ws;
};
