import PropTypes from 'prop-types';

import { SwitchButton } from 'Components';

export const SimCardActivator = ({ simCardStateActive, handleActivate }) => {
    const radioButtonTitle = simCardStateActive ? 'SIM is Active' : 'SIM is Inactive';
    return <SwitchButton title={radioButtonTitle} checked={simCardStateActive} onChange={handleActivate} />;
};

SimCardActivator.defaultProps = {
    handleActivate: () => {},
};

SimCardActivator.propTypes = {
    simCardStateActive: PropTypes.bool.isRequired,
    handleActivate: PropTypes.func,
};
