import { useMemo, useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CellMeasurerCache } from 'react-virtualized';

import { EmptyWidget } from '../emptyWidget';

import * as StyledGeneral from '../styled';
import * as Styled from './styled';

export const TableWidget = ({
    data,
    isEmpty,
    widgetStyles,
    title,
    tableHeader,
}) => {
    const refreshRowTimeout = useRef(null);

    const cache = useMemo(() => new CellMeasurerCache({
        defaultHeight: 31,
        fixedWidth: true,
    }), []);

    const listRef = useRef(null);
    const refreshRowHeight = useCallback(() => {
        cache.clearAll();
        listRef?.forceUpdateGrid && listRef.forceUpdateGrid();
    }, [listRef, cache]);

    useEffect(() => () => {
        clearTimeout(refreshRowTimeout.current);
    }, []);

    useEffect(() => {
        clearTimeout(refreshRowTimeout.current);
        refreshRowTimeout.current = setTimeout(refreshRowHeight, 300);
    }, [widgetStyles.width]);

    if (!data.length || isEmpty) {
        return <EmptyWidget title={title} />;
    }

    const renderRow = (row, index) => (
        <Styled.TableRow key={index}>
            {tableHeader.map((item) => {
                // key should be transformed to match row values with table header inputs
                const key = item.split(' ').join('_').toLowerCase();
                return <span key={key}>{row[key]}</span>;
            })}
        </Styled.TableRow>
    );

    const renderTable = () => (
        <>
            <Styled.HeaderBlock>
                {tableHeader.map((val) => <span key={val}>{val}</span>)}
            </Styled.HeaderBlock>
            <Styled.TableBody>
                {data.map((item, index) => renderRow(item, index))}
            </Styled.TableBody>
        </>
    );

    return (
        <>
            <StyledGeneral.ChartTitle>
                {title}
            </StyledGeneral.ChartTitle>
            {renderTable()}
        </>
    );
};

TableWidget.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object.isRequired),
    widgetStyles: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number,
        left: PropTypes.number,
        top: PropTypes.number,
    }).isRequired,
    title: PropTypes.string.isRequired,
    isEmpty: PropTypes.bool,
    tableHeader: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TableWidget.defaultProps = {
    data: [],
    isEmpty: false,
};
