import { useMemo } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import { GET_GOOGLE_DEV_TOKENS_BY_REGISTRY } from 'Constants';

import { FormContent } from './formContent';
import { validationSchema } from './config';

export const GoogleIntegrationDefinition = ({
    onSubmit,
    formValues,
    initialValues: parentInitialValues,
    prevStep,
    submitText,
    allDevices,
    isLoading,
}) => {
    const { data: googleDevicesData } = useQuery(
        GET_GOOGLE_DEV_TOKENS_BY_REGISTRY,
        { variables: { registryId: formValues.registryId } },
    );
    const alreadyUsedDevices = googleDevicesData?.getAlreadyUsedDevicesQuery ?? [];

    const initialValues = useMemo(() => {
        const { deviceTokens = [] } = parentInitialValues;
        const devices = allDevices.map((device) => deviceTokens.includes(device.token));

        return { devices };
    }, [allDevices]);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            enableReinitialize
        >
            {(props) => (
                <FormContent
                    {...props}
                    allDevices={allDevices}
                    prevStep={prevStep}
                    submitText={submitText}
                    alreadyUsedDevices={alreadyUsedDevices}
                    isEditMode={!!parentInitialValues.deviceTokens?.length}
                    formValues={formValues}
                    isLoading={isLoading}
                />
            )}
        </Formik>
    );
};

GoogleIntegrationDefinition.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    prevStep: PropTypes.func.isRequired,
    allDevices: PropTypes.array,
    submitText: PropTypes.string,
    formValues: PropTypes.object,
    isLoading: PropTypes.bool,
};

GoogleIntegrationDefinition.defaultProps = {
    allDevices: [],
    initialValues: {},
    submitText: 'Create',
    formValues: {},
    isLoading: false,
};
