import PropTypes from 'prop-types';

import * as Styled from './styled';

export const BoxList = ({ children, labels }) => (
    <Styled.BoxList>
        {children.map((child, index) => (
            <Styled.Box data-cy={labels[index].cyData} key={labels[index].name}>
                <Styled.Title>
                    {labels[index].title}
                </Styled.Title>
                <Styled.Description>
                    {labels[index].description}
                </Styled.Description>
                <Styled.Content>
                    {child}
                </Styled.Content>
            </Styled.Box>
        ))}
    </Styled.BoxList>
);

BoxList.propTypes = {
    children: PropTypes.array.isRequired,
    labels: PropTypes.array.isRequired,
};
