export const vodafoneSimConsumptionColumns = [
    {
        title: 'Date Time',
        dataIndex: 'date',
    },
    {
        title: 'Download',
        dataIndex: 'download',
    },
    {
        title: 'Upload',
        dataIndex: 'upload',
    },
    {
        title: 'Total Consumption',
        dataIndex: 'total',
    },
];
