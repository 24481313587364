import PropTypes from 'prop-types';

import { EmptyWidget } from '../emptyWidget';

import * as StyledGeneral from '../styled';
import * as Styled from './styled';

export const CountWidget = ({
    data,
    isEmpty,
    title,
}) => {
    if (isEmpty) {
        return <EmptyWidget title={title} />;
    }

    return (
        <>
            <StyledGeneral.ChartTitle>
                {title}
            </StyledGeneral.ChartTitle>
            <Styled.CountWrapper>
                <Styled.Counter>
                    <span>
                        {data}
                    </span>
                    device
                    {data > 1 && 's'}
                </Styled.Counter>
            </Styled.CountWrapper>
        </>
    );
};

CountWidget.propTypes = {
    data: PropTypes.number.isRequired,
    isEmpty: PropTypes.bool,
    title: PropTypes.string.isRequired,
};

CountWidget.defaultProps = {
    isEmpty: false,
};
