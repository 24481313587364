import { Dropdown, Menu } from 'antd';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { logout } from 'Api';
import { showToastError } from 'Utils';
import { ROUTES, GET_USER_PROFILE_QUERY, CUSTOM_LOGIN } from 'Constants';
import { ReactComponent as LogOutIcon } from 'Assets/icons/logout.svg';
import { ReactComponent as AccountIcon } from 'Assets/icons/user.svg';

import * as User from './styled';

import * as Header from '../../styled';

const isCustomLogin = process.env.REACT_APP_CUSTOM_LOGIN;

export const UserProfile = () => {
    const history = useHistory();
    const { data } = useQuery(GET_USER_PROFILE_QUERY);
    const user = data?.getUserProfile ?? {};

    const onLogout = async () => {
        try {
            const result = await logout();
            if (result?.status === 'Success') {
                if (isCustomLogin === 'true' && result.sessionType === CUSTOM_LOGIN) {
                    history.push(ROUTES.auth.login);
                } else {
                    window.location.href = `${process.env.REACT_APP_PYCOM_SSO_URL}/logout`;
                }
            } else {
                showToastError('Error, during logging out');
            }
        } catch (err) {
            showToastError('System error');
        }
    };

    const MenuItems = (
        <User.Menu>
            <Menu.Item>
                <User.Account
                    to={`${ROUTES.accountSection.main}${ROUTES.accountSection.usage}`}
                    data-cy="account-button"
                >
                    <AccountIcon />
                    My profile
                </User.Account>
            </Menu.Item>
            <User.Divider />
            <Menu.Item>
                <User.LogOut
                    data-cy="logout-button"
                    onClick={onLogout}
                >
                    <LogOutIcon />
                    Log out
                </User.LogOut>
            </Menu.Item>
        </User.Menu>
    );

    return (
        <Dropdown overlay={MenuItems}>
            <User.Owner data-cy="account-button-open">
                <Header.CircleIcon>
                    <AccountIcon />
                </Header.CircleIcon>
                <User.UserInfo>
                    <User.UserName>
                        {user?.fullName?.split(' ')[0]}
                    </User.UserName>
                    <User.UserMail>
                        {user?.owner}
                    </User.UserMail>
                </User.UserInfo>
            </User.Owner>
        </Dropdown>
    );
};
