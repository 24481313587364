import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import {
    ComposedChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
} from 'recharts';

import { GET_SAMPLE_BY_ML_SAMPLE_ID } from 'Constants';
import { Loader } from 'Components';

import { transformDataForMap, xLabelData } from '../chartHelpers';

import * as Styled from './styled';

export const SamplesChart = ({ mlSample, devices }) => {
    const [initialLoading, setInitialLoading] = useState(true);

    const { data, loading } = useQuery(
        GET_SAMPLE_BY_ML_SAMPLE_ID,
        { variables: { mlSampleId: mlSample._id } },
    );
    const samples = data?.getSamplesByMLSampleId ?? [];

    useEffect(
        () => {
            if (initialLoading && !loading) {
                setInitialLoading(false);
            }
        },
        [loading],
    );

    const selectedDevice = useMemo(
        () => devices.find((item) => item._id === mlSample.device) || {},
        [devices, mlSample],
    );

    const chartData = useMemo(
        () => transformDataForMap(samples, mlSample._id),
        [samples, mlSample._id],
    );

    if (initialLoading) {
        return <Loader />;
    }

    return (
        <Styled.ChartWrapper>
            <Styled.Header>
                {selectedDevice.description}
                <Styled.Tag>
                    {mlSample.label}
                </Styled.Tag>
                {moment(mlSample.createdAt).format('YYYY-MM-DD HH:mm')}
            </Styled.Header>
            <ResponsiveContainer
                width="100%"
                height={300}
                className="line-chart-responsive-container-wrapper"
            >
                <ComposedChart data={chartData} margin={{ bottom: 10 }}>
                    <CartesianGrid
                        strokeDasharray="4 3"
                        stroke="#ECF0F4"
                    />
                    <XAxis
                        dataKey="milliseconds"
                        type="number"
                        domain={['dataMin', 'dataMax']}
                        stroke="#ECF0F4"
                        tickLine={false}
                        tick={{ fill: '#C0C7CE', fontSize: 14 }}
                        scale="time"
                        label={xLabelData}
                    />
                    <YAxis
                        stroke="#ECF0F4"
                        tickLine={false}
                        tick={{ fill: '#C0C7CE', fontSize: 14 }}
                    />
                    <Legend
                        verticalAlign="bottom"
                        align="center"
                        iconType="circle"
                        iconSize={14}
                        height={45}
                        margin={{ top: 15 }}
                    />
                    <Line
                        dataKey="xValue"
                        dot={null}
                        stroke="rgb(66,75,90)"
                    />
                    <Line
                        dataKey="yValue"
                        dot={null}
                        stroke="rgb(0,215,237)"
                    />
                    <Line
                        dataKey="zValue"
                        dot={null}
                        stroke="rgb(0,204,150)"
                    />
                </ComposedChart>
            </ResponsiveContainer>
        </Styled.ChartWrapper>
    );
};

SamplesChart.propTypes = {
    mlSample: PropTypes.object.isRequired,
    devices: PropTypes.arrayOf(PropTypes.object),
};

SamplesChart.defaultProps = {
    devices: [],
};
