import { useMemo } from 'react';
import { Form, Field } from 'formik';
import PropTypes from 'prop-types';

import { TIME_OPTIONS_LABELED, PULL_SIZE_LABELED } from 'Constants';
import { getUnitOptions, getAvailableChartTypes } from 'Utils';

import { TextInput } from '../../../inputs';
import { Select } from '../../../select';
import { Button } from '../../../buttons';
import { settingsByField } from './config';

import * as Styled from './styled';

export const FormContent = ({
    isValid,
    onBack,
    baseWidgetType,
    submitText,
    loading,
    isNameIncluded,
    hasModes,
    values,
    editMode,
}) => {
    const chartTypePlaceholder = 'Select type';
    const widgetType = useMemo(() => values.type || baseWidgetType, [values.type]);

    return (
        <Form>
            <Styled.FormContent>
                {isNameIncluded && (
                    <Styled.FormItem>
                        <Styled.ItemLabel>
                            Name
                        </Styled.ItemLabel>
                        <Field
                            name="name"
                            type="text"
                            component={TextInput}
                            errorPosition="right"
                        />
                    </Styled.FormItem>
                )}
                {hasModes && !editMode && (
                    <Styled.FormItem>
                        <Styled.ItemLabel>
                            Chart type
                        </Styled.ItemLabel>
                        <Field
                            placeholder={chartTypePlaceholder}
                            name="type"
                            values={getAvailableChartTypes(widgetType)}
                            component={Select}
                        />
                    </Styled.FormItem>
                )}
                {settingsByField.pin.includes(widgetType) && (
                    <Styled.FormItem>
                        <Styled.ItemLabel>
                            Pin
                        </Styled.ItemLabel>
                        <Field
                            name="settings.pin"
                            component={TextInput}
                            type="number"
                            min={0}
                            max={254}
                            step={1}
                            errorPosition="right"
                        />
                    </Styled.FormItem>
                )}
                {settingsByField.timePeriod.includes(widgetType) && (
                    <Styled.FormItem>
                        <Styled.ItemLabel>
                            Time period
                        </Styled.ItemLabel>
                        <Field
                            name="settings.timePeriod"
                            values={TIME_OPTIONS_LABELED}
                            component={Select}
                        />
                    </Styled.FormItem>
                )}
                {settingsByField.limit.includes(widgetType) && (
                    <Styled.FormItem>
                        <Styled.ItemLabel>
                            Pull size
                        </Styled.ItemLabel>
                        <Field
                            name="settings.limit"
                            values={PULL_SIZE_LABELED}
                            component={Select}
                        />
                    </Styled.FormItem>
                )}
                {settingsByField.unit.includes(widgetType) && (
                    <Styled.FormItem>
                        <Styled.ItemLabel>
                            Unit
                        </Styled.ItemLabel>
                        <Field
                            name="settings.unit"
                            values={getUnitOptions(widgetType)}
                            component={Select}
                        />
                    </Styled.FormItem>
                )}
                {settingsByField.position.includes(widgetType) && (
                    <>
                        <Styled.Title>
                            Geolocation
                        </Styled.Title>
                        <Styled.FormItem>
                            <Styled.ItemLabel>
                                Lat
                            </Styled.ItemLabel>
                            <Field
                                name="settings.position.lat"
                                component={TextInput}
                                type="text"
                                placeholder="latitude"
                            />
                        </Styled.FormItem>
                        <Styled.FormItem>
                            <Styled.ItemLabel>
                                Lng
                            </Styled.ItemLabel>
                            <Field
                                name="settings.position.lng"
                                component={TextInput}
                                type="text"
                                errorPosition="right"
                                placeholder="longtitude"
                            />
                        </Styled.FormItem>
                    </>
                )}
            </Styled.FormContent>
            <Styled.ButtonsWrapper>
                <Button
                    buttonType="transparent"
                    onClick={onBack}
                >
                    Back
                </Button>
                <Button
                    type="submit"
                    disabled={!isValid}
                    loading={loading}
                >
                    {submitText}
                </Button>
            </Styled.ButtonsWrapper>
        </Form>
    );
};

FormContent.propTypes = {
    isValid: PropTypes.bool.isRequired,
    onBack: PropTypes.func.isRequired,
    baseWidgetType: PropTypes.string.isRequired,
    submitText: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    isNameIncluded: PropTypes.bool.isRequired,
    hasModes: PropTypes.bool.isRequired,
    values: PropTypes.object.isRequired,
    editMode: PropTypes.bool.isRequired,
};
