export const chartRange = { min: 55, max: 105 };

export const getChartValue = (value) => {
    const absValue = Math.abs(value);

    if (absValue > chartRange.max) {
        return 1;
    }

    if (absValue <= chartRange.min) {
        return 0;
    }

    const percent = ((absValue - chartRange.min) * 100) / (chartRange.max - chartRange.min);
    return percent / 100;
};

export const getConfig = ({ chartValue, statisticFontSize, strength }) => ({
    percent: chartValue,
    autoFit: true,
    renderer: 'svg',
    startAngle: (-7.5 / 6) * Math.PI,
    endAngle: (1.5 / 6) * Math.PI,
    range: {
        color: [
            'l(270) 0:#6ace60 1:#fbfb42',
            'l(324) 0:#fbfb42 1:#f7ba30',
            'l(18) 0:#f7ba30 1:#ec031d',
            'l(72) 0:#ec031d 1:#ab0412',
        ],
        ticks: [0, 1 / 4, 2 / 4, 3 / 4, 1],
    },
    axis: {
        label: null,
        subTickLine: null,
        tickLine: null,
    },
    indicator: {
        pointer: {
            style: {
                stroke: '#BDBDBD',
                lineWidth: 3,
            },
        },
        pin: {
            style: {
                stroke: '#BDBDBD',
                fill: '#BDBDBD',
                r: 7,
            },
        },
    },
    statistic: {
        content: {
            offsetY: 30,
            content: strength,
            style: {
                fontSize: `${statisticFontSize}px`,
                lineHeight: 1,
                fontFamily: 'Arial',
                color: '#000',
                opacity: 1,
            },
        },
    },
    gaugeStyle: {
        lineCap: 'round',
    },
});

const SIGNAL_CONDITION_LEVELS = {
    EXCELLENT: 'EXCELLENT',
    GOOD: 'GOOD',
    FAIR: 'FAIR',
    POOR: 'POOR',
    NO_SIGNAL: 'NO_SIGNAL',
};

const signalConditionInformation = {
    [SIGNAL_CONDITION_LEVELS.EXCELLENT]: {
        strength: 'Excellent',
    },
    [SIGNAL_CONDITION_LEVELS.GOOD]: {
        strength: 'Good',
    },
    [SIGNAL_CONDITION_LEVELS.FAIR]: {
        strength: 'Fair',
    },
    [SIGNAL_CONDITION_LEVELS.POOR]: {
        strength: 'Poor',
    },
    [SIGNAL_CONDITION_LEVELS.NO_SIGNAL]: {
        strength: 'No signal',
    },
};

export const getSignalConditionInformation = (value) => {
    let signalInfo;

    switch (true) {
        case (value > -65):
            signalInfo = signalConditionInformation[SIGNAL_CONDITION_LEVELS.EXCELLENT];
            break;
        case (value > -75 && value <= -65):
            signalInfo = signalConditionInformation[SIGNAL_CONDITION_LEVELS.GOOD];
            break;
        case (value > -85 && value <= -75):
            signalInfo = signalConditionInformation[SIGNAL_CONDITION_LEVELS.FAIR];
            break;
        case (value > -95 && value <= -85):
            signalInfo = signalConditionInformation[SIGNAL_CONDITION_LEVELS.POOR];
            break;
        case (value <= -95):
            signalInfo = signalConditionInformation[SIGNAL_CONDITION_LEVELS.NO_SIGNAL];
            break;
        default:
            break;
    }

    return signalInfo;
};
