import styled from 'styled-components';

export const Card = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    gap: 1rem;

    width: 100%;
    cursor: pointer;
    &:hover h1 {
        color: ${({ theme }) => theme.colors.active};
    }
    &:hover > div {
        border-color: ${({ theme }) => theme.colors.active_light};
        background-color: ${({ theme }) => theme.colors.active_light};
    }
    &:hover svg {
        color: ${({ theme }) => theme.colors.active};
    }
`;

export const Icon = styled.div`
    position: relative;
    flex-shrink: 0;
    width: 100%;
    height: 5.5rem;
    border-radius: 0.5rem;
    background-color: ${({ theme }) => theme.colors.boxBackground};
    border: 1px solid ${({ theme }) => theme.colors.border};

    & svg {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        font-size: 3rem;
        color: ${({ theme }) => theme.colors.text};
    }
`;

export const Title = styled.h1`
    font-size: 1rem;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.title};
`;
