/* eslint-disable no-await-in-loop */
import isEmpty from 'lodash/isEmpty';
import { showToastError } from 'Utils';

const GITHUB_API = 'https://api.github.com';
const MAX_SIZE = 4194304;
const TYPES_OF_FILE = {
    TREE: 'tree',
    BLOB: 'blob',
};
export class GithubService {
    static async getRepositories(searchString, page = 1, counter = 10) {
        try {
            const updatedString = searchString.trim();
            const response = await fetch(
                `${GITHUB_API}/search/repositories?sort=stars&order=desc&q=${updatedString}&per_page=${counter}&page=${page}`,
                {
                    method: 'GET',
                    headers: {
                        'User-Agent': 'pycom',
                    },
                },
            );

            const result = await response.json();
            if (!response.ok) {
                const message = result?.message;
                throw new Error(message);
            }
            const { items, total_count: total } = result;
            return { items, total };
        } catch (error) {
            console.warn(error);
            throw error;
        }
    }

    static async getBranches(name) {
        try {
            const response = await fetch(
                `${GITHUB_API}/repos/${name}/branches?sort=created`,
                {
                    method: 'GET',
                    headers: {
                        'User-Agent': 'pycom',
                    },
                },
            );

            const result = await response.json();
            if (!response.ok) {
                const message = result?.message;
                throw new Error(message);
            }
            return result;
        } catch (error) {
            console.warn(error);
            throw error;
        }
    }

    static async getResponseOfFile(url) {
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'User-Agent': 'pycom',
                },
            });
            const result = await response.json();
            if (!response.ok) {
                const message = result?.message;
                throw new Error(message);
            }
            return result;
        } catch (error) {
            console.warn(error);
            throw error;
        }
    }

    static async getContentOfFile(file) {
        const { path, size, url, parent } = file;
        try {
            const response = await this.getResponseOfFile(url);
            if (!isEmpty(response)) {
                const { content, encoding } = response;
                const bufferFile = Buffer.from(content, encoding);
                return {
                    size,
                    path,
                    parent,
                    content: bufferFile.toString(),
                };
            }
        } catch (error) {
            console.warn(error);
            throw error;
        }
    }

    static checkFileSize(size) {
        if (size > MAX_SIZE) {
            showToastError(
                'The repository takes up more memory than the memory on the device',
            );
        }
        return size > MAX_SIZE;
    }

    static async recursiveGetBlobOfFiles(treeObject, res = [], size = 0) {
        let result = [...res];
        let sizeFiles = size;

        if (!isEmpty(treeObject?.tree)) {
            const files = treeObject.tree;
            for (const file of files) {
                let parent = file?.parent || '/';
                switch (file?.type) {
                    case TYPES_OF_FILE.BLOB: {
                        const contentOfFile = await this.getContentOfFile(file);
                        if (isEmpty(contentOfFile)) {
                            return;
                        }
                        sizeFiles += contentOfFile.size;
                        if (this.checkFileSize(sizeFiles)) {
                            return;
                        }
                        result = [
                            ...result,
                            {
                                ...contentOfFile,
                                parent,
                            },
                        ];
                        break;
                    }
                    case TYPES_OF_FILE.TREE: {
                        parent = file?.path;
                        const newTree = await this.getResponseOfFile(file?.url);
                        if (isEmpty(newTree)) {
                            return;
                        }
                        newTree.tree?.forEach((element) => {
                            files.push({
                                ...element,
                                path: `${file?.path}/${element.path}`,
                                parent: file?.path,
                            });
                        });
                        break;
                    }
                    default:
                        return result;
                }
            }
        }
        switch (treeObject?.type) {
            case TYPES_OF_FILE.BLOB: {
                const contentOfFile = await this.getContentOfFile(
                    treeObject,
                    sizeFiles,
                );
                if (isEmpty(contentOfFile)) {
                    return;
                }
                sizeFiles += contentOfFile.size;
                if (this.checkFileSize(sizeFiles)) {
                    return;
                }
                result.push({ ...contentOfFile, path: treeObject.path });
                break;
            }
            case TYPES_OF_FILE.TREE: {
                const tree = await this.getResponseOfFile(treeObject?.url);
                if (isEmpty(tree)) {
                    return;
                }
                this.recursiveGetBlobOfFiles(tree, result, sizeFiles);
                break;
            }
            default:
                return result;
        }
        return result;
    }

    static async getTreeOfRepository(owner, repository, tree) {
        try {
            const response = await fetch(
                `${GITHUB_API}/repos/${owner}/${repository}/git/trees/${tree}`,
                {
                    method: 'GET',
                    headers: {
                        'User-Agent': 'pycom',
                    },
                },
            );

            const result = await response.json();
            if (!response.ok) {
                const message = result?.message;
                throw new Error(message);
            }
            const files = await this.recursiveGetBlobOfFiles(result);
            return files;
        } catch (error) {
            console.warn(error);
            throw error;
        }
    }
}
