import { Field, Form } from 'formik';

import { TextInput } from 'Components';

import * as Styled from './styled';

export const FormContent = () => (
    <Form>
        <Styled.FormContent>    
            <Styled.FormItem>
                <Styled.ItemLabel>
                    Connection string
                </Styled.ItemLabel>
                <Field
                    name="connectionString"
                    component={TextInput}
                />
            </Styled.FormItem>
            <Styled.Button type="submit">
                Login
            </Styled.Button>
        </Styled.FormContent>
    </Form>
);
