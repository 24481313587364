import { Switch } from 'react-router-dom';

import { ROUTES } from 'Constants';
import { RouteWrapper } from 'Components';

import {
    IntegrationsList,
    CreateIntegration,
    IntegrationDetails,
    AwsIntegration,
    WebhookIntegration,
    AzureIntegration,
    GoogleIntegration,
} from './components';

export const Integrations = () => (
    <Switch>
        <RouteWrapper exact path={ROUTES.settings.integrations.main} component={IntegrationsList} />
        <RouteWrapper exact path={ROUTES.settings.integrations.create} component={CreateIntegration} />
        <RouteWrapper exact path={ROUTES.settings.integrations.details} component={IntegrationDetails} />
        <RouteWrapper exact path={ROUTES.settings.integrations.createAws} component={AwsIntegration} />
        <RouteWrapper exact path={ROUTES.settings.integrations.createWebhook} component={WebhookIntegration} />
        <RouteWrapper exact path={ROUTES.settings.integrations.createAzure} component={AzureIntegration} />
        <RouteWrapper exact path={ROUTES.settings.integrations.createGoogleCloud} component={GoogleIntegration} />
    </Switch>
);
