import styled from 'styled-components';

export const Wrapper = styled.div`
    margin-top: 36px;
`;

export const HeaderText = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-size: 16px;
    margin-bottom: 36px;
`;

export const TitleBox = styled.div`
    margin-top: 35px;
`;

export const TilesWrapper = styled.div`
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
`;

export const Tile = styled.div`
    margin-bottom: 25px;
    font-size: 30px;
    line-height: 0;
`;

export const PlusIconWrapper = styled.div`
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: ${({ theme }) => theme.colors.active};
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        font-size: 26px;
        color: ${({ theme }) => theme.colors.activeText};
    }
`;
