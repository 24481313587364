import { Form } from 'formik';
import { PropTypes } from 'prop-types';
import { AuthInput } from 'Components';
import { PasswordValidation } from '../passwordValidation';

import * as RootStyled from '../styled';

import * as Styled from './styled';

export const FormContent = ({ values, handleRedirectToLogin }) => (
    <Form>
        <div>
            <RootStyled.TwoCols>
                <AuthInput
                    name="firstName"
                    label="First Name"
                />
                <AuthInput
                    name="lastName"
                    label="Last Name"
                />
            </RootStyled.TwoCols>

            <AuthInput
                name="email"
                label="Email"
                type="email"
            />
            <AuthInput
                name="password"
                type="password"
                label="Password"
                showPassEye
            />
            <PasswordValidation password={values.password} />
        </div>
        <Styled.Button onClick={handleRedirectToLogin}>Already have an account ?</Styled.Button>
        <RootStyled.Button type="submit">
            Create new account
        </RootStyled.Button>
    </Form>
);

FormContent.propTypes = {
    handleRedirectToLogin: PropTypes.func.isRequired,
    values: PropTypes.object,
};

FormContent.defaultProps = {
    values: {},
};
