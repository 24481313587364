import styled from 'styled-components';

import { ReactComponent as DownloadIcon } from 'Assets/icons/download.svg';

export const Tip = styled.div`
    max-width: 459px;
    width: 100%;
    padding: 20px;
    background: ${({ theme }) => theme.colors.boxBackground};
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.title};
`;

export const Title = styled.div`
    margin-bottom: 5px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
`;

export const Description = styled.div`
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 17px;
`;

export const Firmware = styled.div`
    display: flex;
    align-items: center;

    & > a {
        margin-left: 15px;
        color: ${({ theme }) => theme.colors.active};
        font-weight: bold;

        &:hover {
            color: ${({ theme }) => theme.colors.active};
        }
    }
`;

export const Download = styled(DownloadIcon)`
    width: 24px;
    height: 24px;
`;
