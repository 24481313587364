/* eslint-disable react/jsx-filename-extension */
import { ReactComponent as LTE } from 'Assets/devices/lte.svg';
import { ReactComponent as WiFi } from 'Assets/devices/wifi.svg';
import { ReactComponent as LoRa } from 'Assets/devices/lora.svg';
import { ReactComponent as Bluetooth } from 'Assets/devices/ble.svg';
import { ReactComponent as Ethernet } from 'Assets/devices/ethernet.svg';
import { ReactComponent as Sigfox } from 'Assets/network/sigfox.svg';
import {
    BLUETOOTH,
    LORA,
    WIFI,
    ETHERNET,
    LTE as LTE_NETWORK,
    DEVICE_TEMPLATE_TYPES,
    CERTIFICATE_STATUS,
    SIGFOX as SIGFOX_NETWORK,
} from 'Constants';

export const generateDeviceNetworkIcon = (title) => {
    const copyTitle = title.includes('lora') ? LORA : title;
    switch (copyTitle) {
        case WIFI:
            return <WiFi key={title} />;
        case LTE_NETWORK:
            return <LTE key={title} />;
        case LORA:
            return <LoRa key={title} />;
        case ETHERNET:
            return <Ethernet key={title} />;
        case BLUETOOTH:
            return <Bluetooth key={title} />;
        case SIGFOX_NETWORK:
            return <Sigfox key={title} />;
        default:
            return null;
    }
};

export const createConfigurationToDeploy = (device) => {
    const configurationsToDeploy = {
        networkPreferences: device.networks,
    };
    if (device.loraServer) {
        configurationsToDeploy.loraServer = device.loraServer;
    }

    if (device.lte && device.lte.band) {
        configurationsToDeploy.lte = {
            apn: device.lte.apn,
            band: device.lte.band,
            carrier: device.lte.carrier,
            reset: device.lte.reset,
            cid: device.lte.cid,
            type: device.lte.protocol,
        };
    }

    if (device.wifi && device.wifi.ssid) {
        configurationsToDeploy.wifi = {
            ssid: device.wifi.ssid,
            password: device.wifi.password,
        };
    }

    return JSON.stringify(configurationsToDeploy, null, 2);
};

export const createCertificateToDeploy = (certificate) => {
    const certificateToDeploy = {
        cert: '',
        key: '',
    };
    if (certificate) {
        certificateToDeploy.cert = certificate.certificate;
        certificateToDeploy.key = certificate.publicKey;
    }
    
    return JSON.stringify(certificateToDeploy, null, 2);
};

export const getDeviceTemplateDescription = (type, device) => {
    if (type === DEVICE_TEMPLATE_TYPES.SHIELDS || (device?.selfContained && device.sensors?.length)) {
        return device?.sensors;
    }

    return [
        `${device?.system}®`,
        `Flash ${device?.flash}MB`,
        `RAM ${device?.ram}MB`,
    ];
};

export const getDeviceCertificateTagColor = (status) => {
    if (status === CERTIFICATE_STATUS.SECURED) {
        return 'success';
    }
    return 'border';
};

export const getDeviceCertificateTagTitle = (certificate) => {
    if (!certificate?.status) { 
        return 'No certificate configured';
    }
    if (certificate.status === CERTIFICATE_STATUS.SECURED) {
        return 'Fingerprint: ';
    }
    return 'No certificate configured';
};
