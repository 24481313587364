import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'formik';

import { Select } from '../../select';

export const FormContent = ({ wifiList, values, handleSubmit, setFieldValue }) => {
    useEffect(() => {
        if (!values.wifiId && wifiList.length) {
            setFieldValue('wifiId', wifiList[0]?._id);
        }

        handleSubmit();
    }, [values.wifiId]);

    return (
        <Form>
            <Field
                name="wifiId"
                component={Select}
                type="WiFi"
                values={wifiList}
                cyData="wifi-id-select"
            />
        </Form>
    );
};

FormContent.propTypes = {
    wifiList: PropTypes.arrayOf(PropTypes.object),
    values: PropTypes.shape({
        wifiId: PropTypes.string,
    }).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
};

FormContent.defaultProps = {
    wifiList: [],
};
