import { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';

import { ReactComponent as ArrowDown } from 'Assets/icons/arrowRight.svg';
import { ReactComponent as Folder } from 'Assets/icons/folder.svg';
import { ReactComponent as OpenedFolder } from 'Assets/icons/open-folder.svg';

import { ExplorerFile } from '../explorerFile';
import * as Styled from './styled';

export const ExplorerFolder = ({
    folder,
    onExpand,
    onContextMenu,
    input,
    filesOnClick,
    activeKey,
    projectKey,
    updatedFiles,
    setUpdatedFile,
}) => {
    const [isUpdated, setIsUpdated] = useState(false);

    useEffect(() => {
        const updatedIndex = updatedFiles.findIndex((item) => item.startsWith(folder.path));

        if (updatedIndex >= 0 && !isUpdated) {
            setIsUpdated(true);
        } else if (updatedIndex < 0 && isUpdated) {
            setIsUpdated(false);
        }
    }, [updatedFiles]);

    const { name, files, expanded, hierarchyLevel, path } = folder;
    const { value, onChange, inputOnBlur, onKeyDown } = input;

    const folderMarginLeft = `${hierarchyLevel + 20}px`;
    const folderIcon = expanded ? <OpenedFolder /> : <Folder />;
    const displayTitle = name 
        ? <Styled.FolderName $updated={isUpdated}>{name}</Styled.FolderName>
        : (
            <Styled.FolderInput
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={true}
                type="text"
                value={value}
                onChange={onChange}
                onBlur={() => inputOnBlur('folder')}
                onKeyDown={(e) => onKeyDown(e, 'folder')}
            />
        );

    return (
        <div
            style={{ marginLeft: folderMarginLeft }}
            onClick={(e) => onExpand(e, path)}
            tabIndex={0}
            role="button"
            onKeyDown={() => {}}
            onContextMenu={(e) => onContextMenu(e, folder)}
        >
            <Styled.FolderRow>
                <Styled.ExpansionArrow $expanded={expanded}>
                    <ArrowDown />
                </Styled.ExpansionArrow>
                <Styled.FolderIcon>
                    {folderIcon}
                </Styled.FolderIcon>
                <Styled.FolderTitle>
                    {displayTitle}
                </Styled.FolderTitle>
            </Styled.FolderRow>
            {files?.length > 0 && expanded && (
                <ul>
                    {files.map((file) => {
                        if (file.type === 'file') {
                            return (
                                <ExplorerFile
                                    key={file.key || 'tmp'}
                                    file={file}
                                    input={input}
                                    onClick={filesOnClick}
                                    onContextMenu={onContextMenu}
                                    activeKey={activeKey}
                                    projectKey={projectKey}
                                    updatedFiles={updatedFiles}
                                    setUpdatedFile={setUpdatedFile}
                                />
                            );
                        }
                        return (
                            <ExplorerFolder
                                key={file.path || 'tmp'}
                                folder={file}
                                input={input}
                                filesOnClick={filesOnClick}
                                onExpand={onExpand}
                                onContextMenu={onContextMenu}
                                activeKey={activeKey}
                                projectKey={projectKey}
                                updatedFiles={updatedFiles}
                                setUpdatedFile={setUpdatedFile}
                            />
                        );
                    })}
                </ul>
            )}
        </div>
    );
};

ExplorerFolder.defaultProps = {
    activeKey: null,
    updatedFiles: [],
    setUpdatedFile: null,
};

ExplorerFolder.propTypes = {
    folder: PropTypes.shape({
        name: PropTypes.string,
        files: PropTypes.array,
        expanded: PropTypes.bool,
        hierarchyLevel: PropTypes.number,
        path: PropTypes.string,
        parent: PropTypes.string,
        type: PropTypes.string,
    }).isRequired,
    input: PropTypes.shape({
        value: PropTypes.string,
        onChange: PropTypes.func,
        inputOnBlur: PropTypes.func,
        onKeyDown: PropTypes.func,
    }).isRequired,
    onExpand: PropTypes.func.isRequired,
    onContextMenu: PropTypes.func.isRequired,
    filesOnClick: PropTypes.func.isRequired,
    activeKey: PropTypes.string,
    projectKey: PropTypes.string.isRequired,
    updatedFiles: PropTypes.array,
    setUpdatedFile: PropTypes.func,
};
