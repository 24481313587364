import moment from 'moment';
import { dateRanges, statuses } from './constants';

export const countDateRanges = (range) => {
    const now = moment();
    const ranges = {
        start: null,
        end: null,
    };

    switch (range) {
        case dateRanges.TODAY:
            ranges.start = now.subtract(1, 'days');
            break;
        case dateRanges.YESTERDAY:
            ranges.start = now.subtract(2, 'days');
            break;
        case dateRanges.WEEK:
            ranges.start = now.subtract(7, 'days');
            break;
        case dateRanges.MONTH:
            ranges.start = now.subtract(1, 'months');
            break;
        default:
            break;
    }

    return ranges;
};

export const getFilterDropdownOptions = (targetFilter, filterOptions, optionsHandler) => {
    const actions = [];
    Object.values(filterOptions).forEach((optionValue) => {
        const option = {
            title: optionValue,
            executedFunction: () => optionsHandler(targetFilter, optionValue),
        };

        actions.push(option);
    });

    return actions;
};

export const createFilter = (currentFilters) => {
    const filter = {};
    const { start, end } = countDateRanges(currentFilters.date);

    if (currentFilters.status !== statuses.NONE) {
        filter.status = currentFilters.status;
    }

    if (currentFilters.date !== dateRanges.NONE) {
        if (start) {
            filter.startRange = start;
        }
        if (end) {
            filter.startRange = end;
        }
    }

    const result = Object.keys(filter).length ? filter : null;
    return result;
};
