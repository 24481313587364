import { useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { GoogleLogin, GoogleLogout } from 'react-google-login';

import { showToastError } from 'Utils';
import {
    GOOGLE_LOGIN_SCOPES,
    GOOGLE_LOGIN_ERRORS,
    GOOGLE_LOGIN_DOCS,
    GOOGLE_LOGIN_STORAGE_KEY,
} from 'Constants';

import { GoogleAccountInfo } from '../googleAccountInfo';

import * as Styled from './styled';

const { REACT_APP_GOOGLE_CLIENT_ID } = process.env;

export const GoogleAuth = ({
    isSignedIn,
    setIsSignedIn,
    accountData,
    setAccountData,
    hasOnlyButtons,
    isAlignedLeft,
}) => {
    useEffect(() => {
        const gcloudData = localStorage.getItem(GOOGLE_LOGIN_STORAGE_KEY);
        if (gcloudData) {
            const data = JSON.parse(gcloudData);

            if (moment(data.expireTime).isAfter()) {
                setAccountData(data);
                setIsSignedIn(true);
            }
        }
    }, []);

    const getApiErrorText = (errorType) => {
        switch (errorType) {
            case GOOGLE_LOGIN_ERRORS.ACCESS_DENIED.TYPE:
                return GOOGLE_LOGIN_ERRORS.ACCESS_DENIED.TEXT;
            case GOOGLE_LOGIN_ERRORS.CLOSED_BY_USER.TYPE:
                return GOOGLE_LOGIN_ERRORS.CLOSED_BY_USER.TEXT;
            default:
                return GOOGLE_LOGIN_ERRORS.BASE.TEXT;
        }
    };

    const onLoginSuccess = (response) => {
        const { profileObj, uc, Zi, tokenObj } = response;
        const finalTokenObject = uc || Zi || tokenObj;
        const { access_token: accessToken, expires_at: expireTime } = finalTokenObject;
        const { email, name, imageUrl } = profileObj;

        const newAccountData = { accessToken, expireTime, email, name, imageUrl };

        localStorage.setItem(GOOGLE_LOGIN_STORAGE_KEY, JSON.stringify(newAccountData));

        setAccountData(newAccountData);
        setIsSignedIn(true);
    };

    const onLoginFailure = (response) => {
        showToastError(getApiErrorText(response.error));

        setIsSignedIn(false);
    };

    const onLogoutSuccess = () => {
        localStorage.removeItem(GOOGLE_LOGIN_STORAGE_KEY);

        setIsSignedIn(false);
    };

    if (isSignedIn) {
        return (
            <Styled.LoggedWrapper $isAlignedLeft={isAlignedLeft}>
                {!hasOnlyButtons && <GoogleAccountInfo accountData={accountData} />}
                <Styled.LogoutButtonWrapper>
                    <GoogleLogout
                        clientId={REACT_APP_GOOGLE_CLIENT_ID}
                        discoveryDocs={[GOOGLE_LOGIN_DOCS]}
                        scope={GOOGLE_LOGIN_SCOPES.join(' ')}
                        onLogoutSuccess={onLogoutSuccess}
                        onFailure={() => {}}
                        isSignedIn={isSignedIn}
                    />
                </Styled.LogoutButtonWrapper>
            </Styled.LoggedWrapper>
        );
    }
    
    return (
        <Styled.UnloggedWrapper $isAlignedLeft={isAlignedLeft}>
            <GoogleLogin
                clientId={REACT_APP_GOOGLE_CLIENT_ID}
                discoveryDocs={[GOOGLE_LOGIN_DOCS]}
                scope={GOOGLE_LOGIN_SCOPES.join(' ')}
                onSuccess={onLoginSuccess}
                onFailure={onLoginFailure}
                isSignedIn={isSignedIn}
            />
        </Styled.UnloggedWrapper>
    );
};

GoogleAuth.propTypes = {
    isSignedIn: PropTypes.bool.isRequired,
    setIsSignedIn: PropTypes.func.isRequired,
    accountData: PropTypes.object.isRequired,
    setAccountData: PropTypes.func.isRequired,
    hasOnlyButtons: PropTypes.bool,
    isAlignedLeft: PropTypes.bool,
};

GoogleAuth.defaultProps = {
    hasOnlyButtons: false,
    isAlignedLeft: false,
};
