import * as Styled from './styled';

export const TipFirmware = () => (
    <Styled.Tip>
        <Styled.Title>
            Tip
        </Styled.Title>
        <Styled.Description>
            For <strong>Pytrack</strong> and <strong>Pysense</strong> you may
            need manually download the firmware:
        </Styled.Description>
        <div>
            <Styled.Firmware>
                <Styled.Download />
                <a href="/" target="_blank" rel="noreferrer">
                    Pytrack Firmware
                </a>
            </Styled.Firmware>
            <Styled.Firmware>
                <Styled.Download />
                <a href="/" target="_blank" rel="noreferrer">
                    Pysense Firmware
                </a>
            </Styled.Firmware>
        </div>
    </Styled.Tip>
);
