import { useContext } from 'react';

import { PymakrContext } from '../pymakrStore';

export const useContextSelector = (sliceSelector) => {
    const store = useContext(PymakrContext);

    if (!sliceSelector) {
        return store;
    }

    return store[sliceSelector];
};
