import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;

    .ant-switch-checked {
        background-color: ${({ theme }) => theme.colors.active};
    }
`;

export const Title = styled.h3`
    margin-left: 10px;
`;
