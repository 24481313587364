import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { GET_DEVICE_LAST_MESSAGE } from 'Constants';
import { getStringifiedPayload } from 'Utils';
import { useMessageSubscription } from 'Hooks';

import { TooltipDeviceMarker } from '../../../../tooltipDeviceMarker';
import { MapWidget } from '../../../../widgets';

export const Map = ({ chartId, title, pin, device }) => {
    const deviceToken = device.token;

    const { data, loading, refetch } = useQuery(
        GET_DEVICE_LAST_MESSAGE,
        {
            variables: { chartId },
        },
    );

    useMessageSubscription([deviceToken], pin, refetch);

    const lastMessage = data?.getDeviceLastMessage;
    const parsedMessage = useMemo(
        () => {
            if (lastMessage) {
                return {
                    ...lastMessage,
                    value: getStringifiedPayload(lastMessage.payload),
                    date: new Date(lastMessage.createdAt),
                    device: device.description,
                };
            }

            return null;
        },
        [lastMessage, device],
    );

    const renderMetaInfo = (renderData) => <TooltipDeviceMarker {...renderData} />;

    const { location, isDataBroken } = useMemo(
        () => {
            const result = { location: null, isDataBroken: false };

            // additional check needed, because signal could not contain match method (ex: number value)
            if (parsedMessage) {
                const matched = parsedMessage.value.match && parsedMessage.value.match(/([0-9.]*)##([0-9.]*)/);
                if (matched) {
                    result.location = {
                        lat: +matched[1],
                        lng: +matched[2],
                        renderMetaInfo: renderMetaInfo.bind(null, {
                            title: parsedMessage.device,
                        }),
                    };
                } else {
                    result.isDataBroken = true;
                }
            }

            return result;
        },
        [parsedMessage],
    );

    return (
        <MapWidget
            location={location}
            title={title}
            isEmpty={loading}
            isDataBroken={isDataBroken}
        />
    );
};

Map.propTypes = {
    chartId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    pin: PropTypes.number.isRequired,
    device: PropTypes.shape({
        token: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
    }).isRequired,
};
