import PropTypes from 'prop-types';

import * as Styled from './styled';

export const PasswordValidation = ({ password }) => (
    <Styled.PasswordRules>
        <Styled.Rule $valid={/(?=.*[a-z])/.test(password)}>
            <span>✔</span>
            One lowercase character
        </Styled.Rule>
        <Styled.Rule $valid={/(?=.*[A-Z])/.test(password)}>
            <span>✔</span>
            One uppercase character
        </Styled.Rule>
        <Styled.Rule $valid={/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(password)}>
            <span>✔</span>
            One special character
        </Styled.Rule>
        <Styled.Rule $valid={/(?=.*[0-9])/.test(password)}>
            <span>✔</span>
            One number
        </Styled.Rule>
        <Styled.Rule $valid={password.length >= 8}>
            <span>✔</span>
            8 characters minimum
        </Styled.Rule>
    </Styled.PasswordRules>
);

PasswordValidation.propTypes = {
    password: PropTypes.string.isRequired,
};
