import PropTypes from 'prop-types';
import { useContext, useMemo } from 'react';
import { ThemeContext } from 'styled-components';
import { useQuery } from '@apollo/client';

import { ReactComponent as SupportIcon } from 'Assets/icons/support.svg';
import { GET_PLATFORM_NETWORKS } from 'Constants';

import { Loader } from '../../../loader';
import { NetworksForm } from '../../../networksForm';

import * as Styled from './styled';

export const ProjectAddNetworksStep = ({ nextStep, prevStep, formValues, isLoading, submitText }) => {
    const styledTheme = useContext(ThemeContext);
    const { data, loading } = useQuery(GET_PLATFORM_NETWORKS);
    const platformNetworks = data?.getPlatformNetworks?.platformNetworks ?? [];

    const allowedNetworks = useMemo(
        () => platformNetworks.map((platformNetwork) => platformNetwork.name) ?? [],
        [platformNetworks],
    );

    const onNext = (values) => {
        const newValues = { ...values };

        if (newValues.ethernet) {
            newValues.ethernetId = newValues.ethernet;
            delete newValues.ethernet;
        }

        nextStep({
            ...formValues,
            ...newValues,
        });
    };

    if (loading) {
        return <Loader />;
    }

    const descriptionRender = (
        <>
            <Styled.FirstNotificationWrapper>
                <Styled.NotificationIcon>
                    <SupportIcon />
                </Styled.NotificationIcon>
                <Styled.NotificationLabel>
                    Terminal and Maps will not work on LoRa or Sigfox.
                </Styled.NotificationLabel>
            </Styled.FirstNotificationWrapper>
            <Styled.NotificationWrapper>
                <Styled.NotificationIcon>
                    <SupportIcon />
                </Styled.NotificationIcon>
                <Styled.NotificationLabel>
                    <div>
                        {styledTheme.whiteLabelCompany} uses
                        <Styled.ThingNetworkLink
                            href="https://www.thethingsnetwork.org"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            The Thing Network
                        </Styled.ThingNetworkLink>
                        for LoRa connections.
                    </div>
                    <div>
                        If your device is in the range of The Things Network gateway, LoRa will work out of the box.
                    </div>
                </Styled.NotificationLabel>
            </Styled.NotificationWrapper>
        </>
    );

    return (
        <div data-cy="add-project-networks-step">
            <Styled.Title>
                Network Configuration
            </Styled.Title>
            <Styled.NotificationWrapper>
                Drag the right corner of a network type to sort networks by connection priority.
            </Styled.NotificationWrapper>
            <NetworksForm
                onBack={prevStep}
                onSubmit={onNext}
                initialValues={{
                    networks: formValues.networks,
                    wifiId: formValues.wifiId,
                    lteId: formValues.lteId,
                    loraServer: formValues.loraServer,
                    loraType: formValues.loraType,
                    loraRegion: formValues.loraRegion,
                    ethernet: formValues.ethernetId,
                }}
                descriptionRender={descriptionRender}
                submitText={submitText}
                isLoading={isLoading}
                allowedNetworks={allowedNetworks}
            />
        </div>
    );
};

ProjectAddNetworksStep.propTypes = {
    nextStep: PropTypes.func.isRequired,
    prevStep: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    submitText: PropTypes.string,
};

ProjectAddNetworksStep.defaultProps = {
    isLoading: false,
    submitText: 'Create',
};
