import PropTypes from 'prop-types';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { Button } from 'Components';
import { ReactComponent as ComputerIcon } from 'Assets/icons/computer.svg';
import { ReactComponent as DeviceIcon } from 'Assets/icons/cpu.svg';

import { TipBoard, TipFirmware } from '../tips';

import * as Styled from './styled';

const {
    REACT_APP_PYCOM_FIRMWARE_UPDATER_WINDOWS_DOWNLOAD_LINK_ONLINE,
    REACT_APP_PYCOM_FIRMWARE_UPDATER_MAC_OS_DOWNLOAD_LINK_ONLINE,
    REACT_APP_PYCOM_FIRMWARE_UPDATER_LINUX_DOWNLOAD_LINK_ONLINE,
} = process.env;

export const FirmwareUpdaterIntro = ({ nextStep, goBack }) => {
    const styledTheme = useContext(ThemeContext);

    const listDescriptionText = `For the first time, you would need to download the
        Firmware Updater in order to be able to see your USB
        port from ${styledTheme.whiteLabelCompany} platform.`;

    return (
        <Styled.Content>
            <Styled.Intro>
                <Styled.Title>Setup instructions</Styled.Title>
                <Styled.List>
                    <Styled.ListItem>
                        <Styled.Text>
                            <Styled.ListNumber>1.</Styled.ListNumber>
                            <Styled.ListDescription>
                                {listDescriptionText}
                            </Styled.ListDescription>
                        </Styled.Text>
                        <Styled.Firmware>
                            <Styled.Download />
                            <a
                                href={
                                    REACT_APP_PYCOM_FIRMWARE_UPDATER_WINDOWS_DOWNLOAD_LINK_ONLINE
                                }
                                target="_blank"
                                rel="noreferrer"
                            >
                                Download Windows Firmware Updater
                            </a>
                        </Styled.Firmware>
                        <Styled.Firmware>
                            <Styled.Download />
                            <a
                                href={
                                    REACT_APP_PYCOM_FIRMWARE_UPDATER_MAC_OS_DOWNLOAD_LINK_ONLINE
                                }
                                target="_blank"
                                rel="noreferrer"
                            >
                                Download MacOS Firmware Updater
                            </a>
                        </Styled.Firmware>
                        <Styled.Firmware>
                            <Styled.Download />
                            <a
                                href={
                                    REACT_APP_PYCOM_FIRMWARE_UPDATER_LINUX_DOWNLOAD_LINK_ONLINE
                                }
                                target="_blank"
                                rel="noreferrer"
                            >
                                Download Debian Firmware Updater
                            </a>
                        </Styled.Firmware>
                    </Styled.ListItem>
                    <Styled.ListItem>
                        <Styled.Text>
                            <Styled.ListNumber>2.</Styled.ListNumber>
                            <Styled.ListDescription>
                                Plug-in your Device to your computer via USB
                            </Styled.ListDescription>
                        </Styled.Text>
                        <Styled.Connection>
                            <Styled.IconWrapper>
                                <DeviceIcon />
                            </Styled.IconWrapper>
                            <Styled.ConnectionUsb />
                            <Styled.IconWrapper>
                                <ComputerIcon />
                            </Styled.IconWrapper>
                        </Styled.Connection>
                    </Styled.ListItem>
                    <Styled.ListItem>
                        <Styled.Text>
                            <Styled.ListNumber>3.</Styled.ListNumber>
                            <Styled.ListDescription>
                                Please, close Atom, Visual Studio or any other IDE
                                that might use the serial port.
                            </Styled.ListDescription>
                        </Styled.Text>
                    </Styled.ListItem>
                    <Styled.ButtonsWrapper>
                        <Button buttonType="transparent" onClick={goBack}>
                            Back
                        </Button>
                        <Button onClick={nextStep}>
                            Next
                        </Button>
                    </Styled.ButtonsWrapper>
                </Styled.List>
            </Styled.Intro>
            <Styled.ContentRight>
                <Styled.ContentMargin>
                    <TipFirmware />
                </Styled.ContentMargin>
                <TipBoard />
            </Styled.ContentRight>
        </Styled.Content>
    );
};

FirmwareUpdaterIntro.propTypes = {
    nextStep: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
};
