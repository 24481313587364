import styled from 'styled-components';

export const SummaryBox = styled.div`
    width: 100%;
    box-shadow: 0 2px 5px 0 rgba(50, 50, 50, 0.2);
    border: solid 1px ${({ theme }) => theme.colors.border};
    background-color: ${({ theme }) => theme.colors.boxBackground};
    padding: 20px 25px;
`;

export const Header = styled.h3`
    text-transform: uppercase;
    font-size: 22px;
    font-weight: bold;
`;

export const Content = styled.div`
    display: flex;
`;

export const ContentColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-bottom: 10px;
`;

export const ContentItem = styled.div`
    margin-bottom: 30px;
    width: 30%;
`;

export const ContentTitle = styled.div`
    font-weight: bold;
    border-bottom: solid 1px ${({ theme }) => theme.colors.border};
`;
