import { HIERARCHY_ACTION_TYPES } from './actionTypes';

export const setSynchronizing = (synchronizing) => ({
    type: HIERARCHY_ACTION_TYPES.SET_SYNCHRONIZING,
    payload: { synchronizing },
});

export const setSynchronizedFiles = (files) => ({
    type: HIERARCHY_ACTION_TYPES.SET_SYNCHRONIZED_FILES,
    payload: { files },
});

export const setFilesCount = (count) => ({
    type: HIERARCHY_ACTION_TYPES.SET_FILES_COUNT,
    payload: { count },
});

export const setDeviceOnline = (isOnline) => ({
    type: HIERARCHY_ACTION_TYPES.SET_DEVICE_ONLINE,
    payload: { isOnline },
});
