import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import { DEVICE_MESSAGES_PER_RANGE_QUERY } from 'Constants';
import { getStringifiedPayload } from 'Utils';
import { useMessageSubscription } from 'Hooks';

import { LineChartWidget } from '../../../../widgets';

export const LineChart = ({ chartId, chartSettings, title, updateChartSettingsLimit, device }) => {
    const { pin, limit } = chartSettings;
    const deviceToken = device.token;

    const { data: messagesData, error, loading, refetch } = useQuery(
        DEVICE_MESSAGES_PER_RANGE_QUERY,
        {
            variables: {
                chartId,
            },
        },
    );

    useMessageSubscription([deviceToken], pin, refetch);

    const messages = messagesData?.getDeviceMessagesPerRange || [];
    const parsedMessages = useMemo(
        () => {
            if (device.description) {
                return messages.map(
                    (message) => ({
                        [device.description]: Number(getStringifiedPayload(message.payload)),
                        date: new Date(message.createdAt).valueOf(),
                    }),
                ).sort((a, b) => a.date - b.date);
            }
            return [];
        },
        [messages],
    );

    return (
        <LineChartWidget
            data={parsedMessages}
            title={title}
            pin={pin}
            defaultPullSize={limit}
            deviceNames={[device.description]}
            setSelectedPullSize={updateChartSettingsLimit}
            isEmpty={loading || error || !parsedMessages.length}
        />
    );
};

LineChart.propTypes = {
    chartId: PropTypes.string.isRequired,
    chartSettings: PropTypes.shape({
        limit: PropTypes.number.isRequired,
        pin: PropTypes.number.isRequired,
    }).isRequired,
    updateChartSettingsLimit: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    device: PropTypes.shape({
        token: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
    }).isRequired,
};
