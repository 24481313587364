import { TABS_ACTION_TYPES } from './actionTypes';

export const tabsInitialState = {
    tabs: [],
    activeTab: null,
};

export const tabsReducer = (state, action) => {
    switch (action.type) {
        case TABS_ACTION_TYPES.SET_TABS:
            return {
                ...state,
                tabs: action.payload.tabs,
            };
        case TABS_ACTION_TYPES.SET_ACTIVE_TAB:
            return {
                ...state,
                activeTab: action.payload.activeTab,
            };
        default:
            return state;
    }
};
