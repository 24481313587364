import { useState, useEffect, useMemo, useCallback } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';

import {
    PROJECT_CHARTS_QUERY,
    EDIT_PROJECT_WIDGETS_MUTATION,
    DIMENSION_MULTIPLIERS,
    GRID_SCREEN_WIDTH,
    ROUTES,
} from 'Constants';
import { getGridHeight } from 'Utils';
import { Loader } from 'Components';

import { DashboardView, DashboardEdit } from './components';

import * as Styled from './styled';

export const ProjectDashboard = ({ project }) => {
    const { _id: applicationId } = project;
    const history = useHistory();
    const [isEditMode, setIsEditMode] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.document.documentElement.clientWidth);
    const [dashboardData, setDashboardData] = useState([]);
    const [originalDashboardData, setOriginalDashboardData] = useState([]);
    const [initialLoading, setInitialLoading] = useState(true);

    const goCreateWidgetPage = useCallback(() => {
        history.push(`${ROUTES.projects.main}/${applicationId}${ROUTES.projects.createWidget.add}`);
    }, []);

    const { data, loading } = useQuery(
        PROJECT_CHARTS_QUERY,
        {
            variables: { applicationId },
            fetchPolicy: 'network-only',
        },
    );
    const chartData = data?.getApplicationCharts;

    useEffect(
        () => {
            if (initialLoading && !loading) {
                setInitialLoading(false);
            }
        },
        [loading],
    );

    const [editProjectWidgetsMutation] = useMutation(EDIT_PROJECT_WIDGETS_MUTATION);

    const startEdition = useCallback(() => {
        setIsEditMode(true);
        setOriginalDashboardData(dashboardData);
    }, [setIsEditMode, setOriginalDashboardData, dashboardData]);

    const cancelEdition = useCallback(() => {
        setIsEditMode(false);
        setOriginalDashboardData([]);
    }, [setIsEditMode, setOriginalDashboardData]);

    const saveEdition = useCallback((dashboard) => {
        setIsLoading(true);

        if (dashboard !== originalDashboardData) {
            setDashboardData(dashboard);
            const widgets = dashboard
                .filter((widgetData) => !!widgetData?.widget)
                .map((widgetData) => {
                    const { _id, width, height, x, y } = widgetData.widget;
                    return {
                        _id,
                        width,
                        height,
                        x,
                        y,
                    };
                });
            editProjectWidgetsMutation({
                variables: { widgets },
            }).catch((err) => {
                console.log('Error during editing widgets: ', err);
            });
        }
        setIsLoading(false);
        cancelEdition([]);
    }, [
        editProjectWidgetsMutation,
        originalDashboardData,
        cancelEdition,
    ]);

    useEffect(() => {
        if (chartData?.length) {
            const dashboard = chartData
                .filter((chartItem) => !!chartItem.widget)
                .map((chartItem) => cloneDeep(chartItem));
            setDashboardData(dashboard);
        }
    }, [chartData]);

    const updateDimensions = () => setWindowWidth(window.document.documentElement.clientWidth);

    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    const dashboardStyles = useMemo(() => {
        const gridSize = getGridHeight(dashboardData);
        let dashboardHeight = gridSize * DIMENSION_MULTIPLIERS.height;
        const defaultPadding = 65;
        const defaultScale = 0.6;
        if (((windowWidth - defaultPadding)) <= GRID_SCREEN_WIDTH) {
            let scaleRatio = ((windowWidth - defaultPadding)) / GRID_SCREEN_WIDTH;
            if (scaleRatio < defaultScale) {
                scaleRatio = defaultScale;
            }
            dashboardHeight *= scaleRatio;
        }
        return { height: dashboardHeight };
    }, [dashboardData]);

    if (initialLoading) {
        return <Loader />;
    }

    if (!dashboardData?.length) {
        return null;
    }

    return (
        <Styled.DashboardWrapper
            style={dashboardStyles}
        >
            {isEditMode && (
                <DashboardEdit
                    dashboardData={dashboardData}
                    onCancel={cancelEdition}
                    onSave={saveEdition}
                    isLoading={isLoading}
                />
            )}
            {!isEditMode && (
                <DashboardView
                    dashboardData={dashboardData}
                    setEditMode={startEdition}
                    goCreateWidgetPage={goCreateWidgetPage}
                    project={project}
                />
            )}
        </Styled.DashboardWrapper>
    );
};

ProjectDashboard.propTypes = {
    project: PropTypes.object.isRequired,
};
