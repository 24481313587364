import PropTypes from 'prop-types';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { PREVIEW_DEVICE_WIDGET_CONFIG } from 'Constants';
import { DeviceWidgetDummy, RadioButtonGroup, Button } from 'Components';
import { ReactComponent as TileIcon } from 'Assets/icons/tile.svg';
import { ReactComponent as ListIcon } from 'Assets/icons/list.svg';

import { Wrapper, Title, List, Header, IconsWrapper, Icon, ButtonsWrapper } from './styled';

const radioOptions = PREVIEW_DEVICE_WIDGET_CONFIG.map((item) => ({
    value: item.type,
    label: item.name,
}));

const VIEW_TYPES = {
    TILES: 'tiles',
    LIST: 'list',
};

export const DeviceWidgetList = ({ nextStep, setWidgetType }) => {
    const history = useHistory();

    const [viewType, setViewType] = useState(VIEW_TYPES.TILES);
    const [radioType, setRadioType] = useState('');

    const onSelect = (type) => {
        setWidgetType(type);
        nextStep();
    };

    const onRadioSelect = (event) => {
        const { value } = event.target;
        setRadioType(value);
    };

    const changeView = (type) => {
        setViewType(type);
        setRadioType('');
    };

    const nextOnClick = () => {
        onSelect(radioType);
    };

    return (
        <Wrapper>
            <Header>
                <Title>Please choose widget type:</Title>
                <IconsWrapper>
                    <Icon
                        $active={viewType === VIEW_TYPES.TILES}
                        onClick={() => changeView(VIEW_TYPES.TILES)}
                    >
                        <TileIcon />
                    </Icon>
                    <Icon
                        $active={viewType === VIEW_TYPES.LIST}
                        onClick={() => changeView(VIEW_TYPES.LIST)}
                    >
                        <ListIcon />
                    </Icon>
                </IconsWrapper>
            </Header>
            {viewType === VIEW_TYPES.TILES && (
                <List>
                    {PREVIEW_DEVICE_WIDGET_CONFIG.map(({ type }) => (
                        <DeviceWidgetDummy
                            key={type}
                            type={type}
                            onClick={onSelect}
                        />
                    ))}
                </List>
            )}
            {viewType === VIEW_TYPES.LIST && (
                <RadioButtonGroup
                    options={radioOptions}
                    onChange={onRadioSelect}
                />
            )}
            <ButtonsWrapper>
                <Button
                    buttonType="transparent"
                    onClick={history.goBack}
                >
                    Cancel
                </Button>
                {viewType === VIEW_TYPES.LIST && (
                    <Button
                        onClick={nextOnClick}
                        disabled={!radioType}
                    >
                        Next
                    </Button>
                )}
            </ButtonsWrapper>
        </Wrapper>
    );
};

DeviceWidgetList.propTypes = {
    nextStep: PropTypes.func.isRequired,
    setWidgetType: PropTypes.func.isRequired,
};
