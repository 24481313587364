import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import AWS from 'aws-sdk';
import debug from 'debug';

import {
  AWS_API_VERSION,
  AWS_DEFAULT_POLICY_NAME,
  AWS_DEFAULT_POLICY_DOCUMENT,
} from 'Constants';

const log = debug('integrations:AWS');

log.log = console.log.bind(console);

export class AwsService {
    constructor(options) {
        const { accessKeyId, secretAccessKey, region } = options;
        if (isEmpty(accessKeyId) || isEmpty(secretAccessKey) ||
            isEmpty(region)) {
            throw new Error('Missing credentials and/or region');
        }

        AWS.config.setPromisesDependency(Promise);
        this.credentials = new AWS.Credentials(accessKeyId, secretAccessKey);
        this.region = region;
        this.awsiot = new AWS.Iot({
          apiVersion: AWS_API_VERSION,
          credentials: this.credentials,
          region: this.region,
        });
    }

    /**
    * Custom IoT endpoint used by AWS IoT SDK to publish data to Amazon
    * @param {Object}
    * @return {String} It returns a string corresponding to the AWS MQTT broker
    */
    async describeEndpoint() {
        const endpoint = await this.awsiot.describeEndpoint().promise();
        return endpoint.endpointAddress;
    }

    /**
    * Create a thing group. This is a control plane operation.
    * https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/Iot.html#createPolicy-property
    * @param {Object}
    * @return {Promise}
    */
    createPolicy(params) {
        if (!params.policyDocument) {
            throw new Error('AWSManager.createPolicy: parameter policyDocument is required');
        }

        /* eslint-disable no-param-reassign */
        params.policyDocument = JSON.stringify(params.policyDocument);
        if (!params.policyName) {
            throw new Error('AWSManager.createPolicy: parameter policyName is required');
        }
        return this.awsiot.createPolicy(params).promise();
    }

    /**
     * It attaches a policy to a target
     * @param  {Object}  options it must have the following required fields:
     * - policyName (String): name of the policy
     * - target (String): ARN of the target (usually of the certificate)
     * @return {Promise}        It returns an empty object
     */
    async attachPolicy(options) {
        if (isEmpty(options) || isEmpty(options.policyName) ||
            isEmpty(options.target)) {
            throw new Error('Missing policyName and/or target');
        }

        return this.awsiot.attachPolicy({
            policyName: options.policyName,
            target: options.target,
        }).promise();
    }

    /**
    * Get a policy.
    * https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/Iot.html#getPolicy-property
    * @param {Object}
    * @return {Promise}
    */
    getPolicy(policyName) {
        return new Promise((resolve, reject) => (
            this.awsiot.getPolicy({ policyName })
                .on('complete', (response) => {
                    log(response);

                    const { httpResponse, data, error } = response;
                    if (httpResponse.statusCode === undefined || httpResponse.statusCode === 404) {
                        return resolve(undefined);
                    }

                    if (httpResponse.statusCode === 200) {
                        return resolve(data);
                    }
                    return reject(error);
                })
                .send()
        ));
    }

    async attachDefaultPolicy(thingGroupArn) {
        return new Promise((resolve, reject) => (
            this.getPolicy(AWS_DEFAULT_POLICY_NAME)
                .then(async (data) => {
                    if (data === undefined) {
                        log('Policy doesn\'t exists');

                        await this.createPolicy({
                          policyName: AWS_DEFAULT_POLICY_NAME,
                          policyDocument: AWS_DEFAULT_POLICY_DOCUMENT,
                        });

                        return this.attachPolicy({
                          policyName: AWS_DEFAULT_POLICY_NAME,
                          target: thingGroupArn,
                        }).then(() => resolve());
                    }

                    log('Policy found:', data);
                    return this.attachPolicy({
                      policyName: AWS_DEFAULT_POLICY_NAME,
                      target: thingGroupArn,
                    }).then(() => resolve());
                })
                .catch((e) => {
                    console.error('getPolicy exception:', e);
                    return reject(e);
                })
        ));
    }

    /**
    * It creates an AWS Thing
    * 0fb86a1 @chrisvoo chrisvoo Moved awsthing
    * https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/Iot.html#createThing-property
    *
    * @param  {Object}  device It has the following properties:
    * - name (String, required): device's name
    * - type (String): device's type
    * - attributes (Array): it's an array of key-value pairs object
    * @return {Promise}        [description]
    */
    createThing(device) {
        return new Promise(async (resolve, reject) => {
            if (isEmpty(device) || isEmpty(device.name)) {
                const error = new Error('Device name must not be empty');
                console.error(error);
                return reject(error);
            }

            const params = { thingName: device.name };

            if (!isEmpty(device.type)) {
                params.thingTypeName = device.type;
                const thingType = await this.awsiot.createThingType({ thingTypeName: device.type }).promise();
                const { thingTypeName, thingTypeArn, thingTypeId } = thingType;
                log(`ThingType ${thingTypeName} with ARN ${thingTypeArn} and ID ${thingTypeId} created`);
            }

            if (!isEmpty(device.attributes) && isObject(device.attributes)) {
                params.attributePayload = {
                  attributes: device.attributes,
                };
            }

            return this.awsiot.createThing(params).promise()
                .then((data) => {
                    const { thingName, thingArn, thingId } = data;
                    log(`Thing ${thingName} with ARN ${thingArn} and ID ${thingId} creation response:`, data);

                    return resolve(data);
                })
                .catch((e) => {
                    console.error(e);
                    return reject(e);
                });
        });
    }

    /**
    * It deletes an AWS Thing
    * https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/Iot.html#createThing-property
    *
    * @param  {string}  thingName the name of the thing in AWS
    * @return {Promise} [description]
    */
    deleteThing(thingName) {
        return new Promise((resolve, reject) => (
            this.awsiot.deleteThing({ expectedVersion: 1, thingName }).promise()
                .then((data) => resolve(data))
                .catch((e) => {
                    console.error(e);
                    return reject(e);
                })
        ));
    }

    /**
     * It creates a new certificate and all the keys
     * @return {Promise} an object with the following details:
     * - certificateArn
     * - certificateId
     * - certificatePem
     * - keyPair: {
     *  - PublicKey
     *  - PrivateKey
     * }
     */
    async createCertificate() {
        const pyCertificate = await this.awsiot.createKeysAndCertificate({ setAsActive: true }).promise();
        const { certificateArn, certificateId, certificatePem, keyPair } = pyCertificate;
        log(`Certificate ${certificateArn} with ID ${certificateId} created\n
                    - Cert: ${certificatePem.substring(0, 10)}\n
                    - Public Key: ${keyPair.PublicKey.substring(0, 10)}\n
                    - Private Key: ${keyPair.PrivateKey.substring(0, 10)}\n`);
        return pyCertificate;
    }

    /**
     * It deletes a certificate
     * @param  {String}  certArn certificate's ARN
     * @return {Promise}        empty object
     */
    async deleteCertificate({ arn, id }) {
        const arnId = arn.split('/')[1];

        if (isEmpty(id) || id !== arnId) {
            console.error(`Invalid cert ARN [ ${arnId} ] or ID [ ${id} ]`);
            throw new Error('you haven\'t passed a valid certificate ARN');
        }

        await Promise.all([
            this.awsiot.updateCertificate({ certificateId: id, newStatus: 'REVOKED' }).promise(),
            // this.awsiot.detachPrincipalPolicy({ policyName: DEFAULT_POLICY_NAME, principal: certArn }).promise(),
        ]);
        return this.awsiot.deleteCertificate({ certificateId: id, forceDelete: true }).promise();
    }

    /**
     * It attaches a certificate to a thing.
     * @param  {Object}  options it contains the following required fields:
     * - principal (String) the certificate ARN
     * - thingName (String) the name of a thing
     * @return {Promise}         It returns an empty object
     */
    async attachCertificateToThing(options) {
        if (isEmpty(options) || isEmpty(options.principal) ||
            isEmpty(options.thingName)) {
            throw new Error('attachCertificateToThing: Missing parameters (thingName and/or principal)');
        }

        return this.awsiot.attachThingPrincipal({
            principal: options.principal,
            thingName: options.thingName,
        }).promise();
    }

    async detachCertificatefromThing({ principal, thingName }) {
        if (isEmpty(principal) || isEmpty(thingName)) {
            throw new Error('attachCertificateToThing: Missing parameters (thingName and/or principal)');
        }

        return this.awsiot.detachThingPrincipal({
            principal,
            thingName,
        }).promise();
    }

    /**
    * Create a thing group. This is a control plane operation.
    * https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/Iot.html#createThingGroup-property
    * @param {Object}
    * @return {Promise}
    */
    createThingGroup(params) {
        if (!params.thingGroupName) {
            throw new Error('AWSManager.createThingGroup: parameter thingGroupName is required');
        }

        return this.awsiot.createThingGroup(params).promise();
    }

    /**
    * Deletes a thing group.
    * https://docs.aws.amazon.com/iot/latest/apireference/API_DeleteThingGroup.html
    * @param {Object}
    * @return {Promise}
    */
    deleteThingGroup(thingGroupName) {
        return new Promise((resolve, reject) => (
            this.awsiot.deleteThingGroup({ expectedVersion: 1, thingGroupName }).promise()
                .then((data) => {
                    log('deleteThingGroup response', data);
                    return resolve(data);
                })
                .catch((e) => {
                    console.error(e);
                    return reject(e);
                })
        ));
    }

    /**
    * Adds a thing to a thing group.
    * https://docs.aws.amazon.com/iot/latest/apireference/API_AddThingToThingGroup.html
    * @param {Object}
    * @return {Promise}
    */
    addThingToThingGroup(params) {
        log(`thingName: ${params.thingName}, thingGroupName: ${params.thingGroupName}`);
        return new Promise((resolve, reject) => (
            this.awsiot.addThingToThingGroup(params).promise()
                .then((data) => {
                    log('addThingToThingGroup response', data);
                    return resolve(data);
                })
                .catch((e) => {
                    console.error(e);
                    return reject(e);
                })
        ));
    }

    /**
    * Remove the specified thing from the specified group.
    * https://docs.aws.amazon.com/iot/latest/apireference/API_RemoveThingFromThingGroup.html
    * @param {Object}
    * @return {Promise}
    */
    removeThingFromThingGroup(thingName, thingGroupName) {
        return new Promise((resolve, reject) => (
            this.awsiot.removeThingFromThingGroup({ thingName, thingGroupName }).promise()
                .then((data) => {
                    log('removeThingFromThingGroup response', data);
                    return resolve(data);
                })
                .catch((e) => {
                    console.error(e);
                    return reject(e);
                })
        ));
    }

    /**
    * List the thing groups in your account.
    * https://docs.aws.amazon.com/iot/latest/apireference/API_ListThingGroups.html
    * @param {Object} params
    *   - namePrefixFilter: 'STRING_VALUE',
    *   - nextToken: token of previous list if the number of result was exceeding maxResults or 250 entries,
    *   - maxResults: 0,
    *   - parentGroup: 'STRING_VALUE',
    *   - recursive: boolean
    * @return {Promise} ThingGroups
    */
    listThingGroups(params) {
        return this.awsiot.listThingGroups(params).promise();
    }

    /**
    * Lists your things.
    * Use the attributeName and attributeValue parameters to filter your things.
    * For example, calling ListThings with attributeName=Color and attributeValue=Red retrieves all
    * things in the registry that contain an attribute Color with the value Red.
    * https://docs.aws.amazon.com/iot/latest/apireference/API_ListThings.html
    * @param {Object} params
    *   - attributeName: 'STRING_VALUE',
    *   - attributeValue: 'STRING_VALUE',
    *   - maxResults: 0,
    *   - nextToken: 'STRING_VALUE',
    *   - thingTypeName: 'STRING_VALUE'
    * @return {Promise}
    */
     listThings(params) {
        return this.awsiot.listThings(params).promise();
    }
}
