import styled from 'styled-components';

export const Title = styled.div`
    font-size: 22px;
    color: ${({ theme }) => theme.colors.title};
    margin-bottom: 28px;
`;

export const ListWrapper = styled.div`
    display: flex;
    max-width: 740px;
    flex-wrap: wrap;
    justify-content: space-between;
`;

export const ListItem = styled.div`
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    background: #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    width: calc((100% - 30px) / 2);
    padding: 20px 40px;
    font-size: 22px;
    letter-spacing: 0.36px;
    margin-bottom: 15px;
    cursor: pointer;
    border: 1px solid transparent;
    transition: border-color 0.3s;

    &:hover {
        border-color: ${({ theme }) => theme.colors.active};
    }

    svg {
        font-size: 30px;
        color: ${({ theme }) => theme.colors.active};
        margin-right: 25px;
    }
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    margin-top: 55px;

    
    & > * {
        margin-right: 30px;
    }
`;
