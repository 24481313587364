import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { ThemeContext } from 'styled-components';
import { useSubscription, useQuery } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';

import {
    USERS_DEVICE_ACTIVATED_SUBSCRIPTION,
    LAST_SEEN_SUBSCRIPTION,
    DEVICE_ACTIVATED_SUBSCRIPTION,
    GET_DEVICE_STATUS,
} from 'Constants';
import { Loader } from 'Components';
import { GlobalContext } from 'Context';
import { showToastSuccess } from 'Utils';

import * as Styled from './styled';

export const DeviceConnection = ({ deviceToken, macAddressFromApi, sigfoxInfo }) => {
    const [isActivated, setIsActivated] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const globalContext = useContext(GlobalContext);
    const session = globalContext.session;

    const { data: getDeviceStatusData, loading } = useQuery(GET_DEVICE_STATUS, {
        variables: { deviceToken },
    });

    const { data: lastSeen } = useSubscription(LAST_SEEN_SUBSCRIPTION, {
        variables: {
            deviceToken,
        },
    });

    const { data: userSubscription } = useSubscription(
        USERS_DEVICE_ACTIVATED_SUBSCRIPTION,
        {
            variables: {
                userName: session.name,
            },
        },
    );

    const { data: deviceSubscription } = useSubscription(
        DEVICE_ACTIVATED_SUBSCRIPTION,
        {
            variables: {
                deviceToken,
            },
        },
    );

    const lastSeenFromApi = getDeviceStatusData?.getDeviceStatus?.lastSeen;
    const titleOfDevice =
        userSubscription?.userDeviceActivated__api__?.deviceName ?? '';
    const deviceMacAddress =
        deviceSubscription?.deviceActivated__api__?.deviceMacAddress ?? '';
    const lastSeenFromSubscription = lastSeen?.lastSeenUpdated?.lastSeen ?? [];

    useEffect(() => {
        if (isEmpty(lastSeenFromSubscription)) {
            const foundedLastTime = lastSeenFromApi;
            setIsActivated(!!foundedLastTime);
        } else {
            setIsActivated(!isEmpty(lastSeenFromSubscription));
        }
    }, [lastSeenFromSubscription, lastSeenFromApi]);

    useEffect(() => {
        if (!isEmpty(titleOfDevice)) {
            showToastSuccess(
                `${titleOfDevice}  was successfully connected to firmware updater.`,
            );
        }
    }, [titleOfDevice]);

    useEffect(() => {
        setIsVisible(!!deviceMacAddress || !!macAddressFromApi);
    }, [deviceMacAddress, macAddressFromApi]);

    const styledTheme = useContext(ThemeContext);

    const descriptionText = `Your device does not connected to
        ${styledTheme.whiteLabelCompany} platform yet.`;
    const infoText = `If your device won't connect to
        ${styledTheme.whiteLabelCompany}, connect your device to
        Atom with Pymakr plugin and check out device output.`;
    const successTest = `Your device connected ${styledTheme.whiteLabelCompany} to  platform.`;

    if (loading) {
        return <Loader />;
    }

    let content;

    switch (true) {
        case isActivated && isVisible:
            content = (
                <Styled.Wrapper>
                    <Styled.Header>
                        <Styled.Title>Device status</Styled.Title>
                        <Styled.ConnectionSuccess>
                            connected
                        </Styled.ConnectionSuccess>
                    </Styled.Header>
                    <Styled.Description>{successTest}</Styled.Description>
                    {(deviceMacAddress || macAddressFromApi) && (
                        <Styled.MacAddress>
                            Wireless Mac Address:
                            {deviceMacAddress || macAddressFromApi}
                        </Styled.MacAddress>
                    )}
                    {sigfoxInfo?.id && sigfoxInfo.id !== 'Unknown' && (
                        <Styled.MacAddress>
                            Sigfox ID
                            <span>
                                {sigfoxInfo.id}
                            </span>
                        </Styled.MacAddress>
                    )}
                    {sigfoxInfo?.pac && (
                        <Styled.MacAddress>
                            Sigfox PAC
                            <span>
                                {sigfoxInfo.pac}
                            </span>
                        </Styled.MacAddress>
                    )}
                </Styled.Wrapper>
            );
            break;
        case !isActivated && isVisible:
            content = (
                <Styled.Wrapper>
                    <Styled.Header>
                        <Styled.Title>Device status</Styled.Title>
                        <Styled.Connection>not connected</Styled.Connection>
                    </Styled.Header>
                    <Styled.Description>{descriptionText}</Styled.Description>
                    <Styled.Info>{infoText}</Styled.Info>
                    <Styled.DataFirmware>
                        Data read by Firmware updater
                    </Styled.DataFirmware>
                    <Styled.MacAddress>
                        Wireless Mac Address:
                        {deviceMacAddress || macAddressFromApi}
                    </Styled.MacAddress>
                    {sigfoxInfo?.id && sigfoxInfo.id !== 'Unknown' && (
                        <Styled.MacAddress>
                            Sigfox ID
                            {sigfoxInfo.id}
                        </Styled.MacAddress>
                    )}
                    {sigfoxInfo?.pac && (
                        <Styled.MacAddress>
                            Sigfox PAC
                            {sigfoxInfo.pac}
                        </Styled.MacAddress>
                    )}
                </Styled.Wrapper>
            );
            break;
        default:
            content = <div />;
            break;
    }

    return <div>{content}</div>;
};

DeviceConnection.propTypes = {
    deviceToken: PropTypes.string.isRequired,
    macAddressFromApi: PropTypes.string,
    sigfoxInfo: PropTypes.shape({
        id: PropTypes.string,
        pac: PropTypes.string,
    }),
};

DeviceConnection.defaultProps = {
    macAddressFromApi: '',
    sigfoxInfo: null,
};
