import styled from 'styled-components';

import { Button as ButtonComponent } from 'Components';

export const FormContent = styled.div`
    margin-top: 75px;
    max-width: 500px;
`;

export const FormItem = styled.div`
    display: flex;
    margin-bottom: 10px;

    & > span {
        color: ${({ theme }) => theme.colors.title};
        padding-top: 8px;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.38;
    }

    & > div {
        flex: 1;
    }

    input[type=text],
    select {
        width: 100%;
    }
`;

export const ItemLabel = styled.span`
    width: 150px;
    margin-right: 15px;
`;

export const CheckboxWrapper = styled.div`
    margin-top: 25px;
`;

export const Button = styled(ButtonComponent)`
    margin-top: 45px;
    width: 190px;
    max-width: 100%;
`;
