import PropTypes from 'prop-types';

import { Modal } from 'Components';
import { ADD_PROJECT_TYPES } from 'Constants';
import { ReactComponent as FastIotIcon } from 'Assets/icons/fast-iot.svg';
import { ReactComponent as ListIcon } from 'Assets/icons/list2.svg';
import { ReactComponent as PlusIcon } from 'Assets/icons/plus.svg';

import * as Styled from './styled';

export const AddProjectSelection = ({ modalOpen, onModalClose, handleOnModalBtnClick }) => (
    <Modal isOpened={modalOpen} handleClose={onModalClose} width={880}>
        <Styled.ModalWrapper>
            <h1>What project do you want to create?</h1>
            <Styled.ModalButtonsWrapper>
                <Styled.ButtonWrapper
                    onClick={() => handleOnModalBtnClick(ADD_PROJECT_TYPES.FAST_IOT)}
                    data-cy="add-fast-iot-project-button-modal"
                >
                    <Styled.IconWrapper>
                        <FastIotIcon />
                        <Styled.NewButton>
                            <PlusIcon />
                        </Styled.NewButton>
                    </Styled.IconWrapper>
                    <span>Fast IoT Project</span>
                </Styled.ButtonWrapper>
                <Styled.ButtonWrapper
                    onClick={() => handleOnModalBtnClick(ADD_PROJECT_TYPES.DEFAULT)}
                    data-cy="add-default-project-button-modal"
                >
                    <Styled.IconWrapper>
                        <ListIcon />
                        <Styled.NewButton>
                            <PlusIcon />
                        </Styled.NewButton>
                    </Styled.IconWrapper>
                    <span>Other Project</span>
                </Styled.ButtonWrapper> 
            </Styled.ModalButtonsWrapper>
            <Styled.ModalCloseBtn
                buttonType="transparent"
                onClick={onModalClose}
            >
                Close
            </Styled.ModalCloseBtn>
        </Styled.ModalWrapper>
    </Modal>
);

AddProjectSelection.propTypes = {
    modalOpen: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    handleOnModalBtnClick: PropTypes.func.isRequired,
};
