import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
`;

export const HomeIcon = styled.span`
    &::after {
        content: " "
    }
    font-size: 16px;
    color: #BDBDBD;
    margin-right: 11px;
`;

export const Header = styled.div`
    display: flex;
    align-items: baseline;
    position: relative;
    h3 {
        margin-bottom: 0px;
    }
`;

export const TooltipHeader = styled.div`
    color: #828282;
`;

// I defined styles for a span inside Actions component, 
// because the content is wrapped with tooltip
// and the tooltip generate extra span around the content
export const Actions = styled.div`    
    span {
        position: absolute;
        right: 40px;
        top: -3px;
        display: flex;
        column-gap: 10px;
        align-items: center;
        font-size: 14px;
        line-height: 1;
        color: #828282;
        svg {
            font-size: 16px;
            color: ${({ svgColor }) => svgColor};
        }
    }
`;

export const InfoWrapper = styled.div`    
    display: flex;
    column-gap: 26px;
    align-items: center;
    height: 35%;
    svg {
        font-size: 75px;
        color: ${({ svgColor }) => svgColor};
    }
`;

export const Quality = styled.div`
    font-size: 20px;
    color: ${({ color }) => color};
`;
