import { useApolloClient, useQuery } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useMemo, useState, useRef } from 'react';

import { getTimeDiff } from 'Utils';
import {
    LAST_SEEN_QUERY,
    LAST_SEEN_SUBSCRIPTION,
} from 'Constants';

export const useLastConnection = (devices) => {
    const client = useApolloClient();
    const [loadingTable, setLoadingTable] = useState(true);
    const [tableData, setTableData] = useState(null);
    const [lastConnectionRecalculation, triggerLastConnectionRecalculation] = useState({});
    const lastConnectionsRef = useRef({});
    const subscriptionRef = useRef(null);
    const { data: lastSeenQueryData, loading: lastSeenQueryLoading } = useQuery(LAST_SEEN_QUERY);

    const lastSeenListData = useMemo(
        () => lastSeenQueryData?.lastSeen ?? [],
        [lastSeenQueryData],
    );

    const getLastLocationsSubscription = ({ token }) => {
        const subscriptionDevice = client.subscribe({
            query: LAST_SEEN_SUBSCRIPTION,
            variables: {
                deviceToken: token,
            },
        });
        const observer = subscriptionDevice.subscribe({
            next({ data }) {
                const lastConnection = data?.lastSeenUpdated?.lastSeen;

                // last connection state is cached so we need to mutate ref and just trigger recalculation
                lastConnectionsRef.current[token] = lastConnection;
                triggerLastConnectionRecalculation({});
            },
        });
        subscriptionRef.current = observer;
    };

    const createAllSubscriptions = () => {
        for (const device of devices) {
            getLastLocationsSubscription(device);
        }
    };

    useEffect(() => {
        createAllSubscriptions();
    }, [devices]);

    const createTableData = () => {
        const newTableData = devices.map((device) => {
            let lastConnection = lastConnectionsRef.current[device.token];

            if (!lastConnection && lastSeenListData) {
                lastConnection = lastSeenListData.find(
                    (listItem) => listItem.deviceToken === device?.token,
                )?.lastSeen;
            }
            return {
                ...device,
                time: lastConnection,
                lastConnection: getTimeDiff(lastConnection, 'Never connected'),
            };
        });
        setTableData(newTableData);
        setLoadingTable(false);
    };

    const updateLastLocation = () => {
        if (tableData?.length) {
            const newDeviceArray = tableData.map((device) => ({
                ...device,
                lastConnection: getTimeDiff(device?.time, 'Never connected'),
            }));
            setTableData(newDeviceArray);
        }
    };

    useEffect(() => {
        const interval = setInterval(() => updateLastLocation(), 1000);
        return () => {
            clearInterval(interval);
        };
    }, [tableData]);

    useEffect(() => {
        if (!lastSeenQueryLoading) {
            createTableData();
        }
    }, [devices, lastSeenListData, lastConnectionRecalculation, lastSeenQueryLoading]);

    useEffect(
        () => () => {
            if (!isEmpty(subscriptionRef.current)) {
                subscriptionRef.current.unsubscribe();
            }
        },
        [],
    );

    return { data: tableData, loading: loadingTable };
};
