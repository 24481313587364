import { Field } from 'formik';

import { TextInput } from '../../../../../inputs';
import { Select } from '../../../../../select';

import * as Styled from '../../styled';
import { httpMethodOptions, requestFormatOptions } from '../../config';

export const GeneralSettings = () => (
    <Styled.FormContent>
        <Styled.FormItem>
            <Styled.ItemLabel>
                URL
            </Styled.ItemLabel>
            <Field
                name="url"
                component={TextInput}
            />
        </Styled.FormItem>
        <Styled.FormItem>
            <Styled.ItemLabel>
                Webhook Event name
            </Styled.ItemLabel>
            <Field
                name="webhookEvent"
                component={TextInput}
            />
        </Styled.FormItem>
        <Styled.FormItem>
            <Styled.ItemLabel>
                HTTP Method
            </Styled.ItemLabel>
            <Field
                name="requestType"
                component={Select}
                values={httpMethodOptions}
            />
        </Styled.FormItem>
        <Styled.FormItem>
            <Styled.ItemLabel>
                Request Format
            </Styled.ItemLabel>
            <Field
                name="requestFormat"
                component={Select}
                values={requestFormatOptions}
            />
        </Styled.FormItem>
    </Styled.FormContent>
);
