import { Formik } from 'formik';
import PropTypes from 'prop-types';

import { initialValues, getValidationSchema } from './config';
import { FormContent } from './formContent';

import * as Styled from './styled';

export const ProjectDescription = ({ projects, nextStep, prevStep, formValues }) => {
    const checkIsNameNotDuplicated = (name) => !projects.some((project) => project.name === name);

    const onSubmit = (values) => {
        nextStep({ ...formValues, ...values });
    };

    const combinedInitialValues = {
        name: formValues.name || initialValues.name,
        description: formValues.description || initialValues.description,
    };

    return (
        <div data-cy="add-project-description-step">
            <Styled.Title>
                Project
            </Styled.Title>
            <Formik
                onSubmit={onSubmit}
                initialValues={combinedInitialValues}
                validationSchema={getValidationSchema(checkIsNameNotDuplicated)}
                validateOnMount
            >
                {(props) => (
                    <FormContent
                        {...props}
                        onBack={prevStep}
                    />
                )}
            </Formik>
        </div>
    );
};

ProjectDescription.propTypes = {
    projects: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
    })),
    nextStep: PropTypes.func.isRequired,
    prevStep: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
};

ProjectDescription.defaultProps = {
    projects: [],
};
