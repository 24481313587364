import styled from 'styled-components';

export const Network = styled.span`
    font-size: 16px;
    color: ${({ theme }) => theme.colors.title};
    margin-right: 5px;

    &:last-child {
        margin-right: 0;
    }
`;
