import styled from 'styled-components';

export const Tag = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px 7px;
    margin-left: 15px;
    font-size: 14px;
    color: #fff;
    border-radius: 4px;
    white-space: nowrap;
    display: inline-block;
    background-color: ${({ theme, $type }) => theme.colors[$type]};
    border: 1px solid ${({ theme, $type }) => theme.colors[$type]};

    ${({ $type }) => ($type === 'warning' || $type === 'border') && `
        color: inherit !important;
    `}
`;

export const Subtitle = styled.span`
    font-weight: 300;
`;
