import { BR_ENABLED_VALUES } from 'Constants';

import { Select } from '../select';

export const getPymeshDeviceSelectionColumns = ({ getBrValueByToken, handleBRChange }) => ([
    {
        title: 'Name',
        dataIndex: 'description',
    },
    {
        title: 'Border Router',
        dataIndex: 'token',
        render: (token) => {
            const selectedItem = `${getBrValueByToken(token)}`;
            const capitalizedItem = `${selectedItem[0].toUpperCase()}${selectedItem.slice(1)}`;

            return (
                <Select
                    custom
                    handleSelect={(value) => handleBRChange(token, value)}
                    values={Object.values(BR_ENABLED_VALUES)}
                    selectedItem={capitalizedItem}
                />
            );
        },
    },
    {
        title: 'Type',
        dataIndex: 'deviceType',
    },
    {
        title: 'Networks',
        dataIndex: 'networks',
    },
]);
