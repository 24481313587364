import { useMemo, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import {
    BREADCRUMBS_LABELS,
    GET_DEVICE_BY_TOKEN_QUERY,
    PIN_QUERY,
    GET_DEVICE_MESSAGES,
} from 'Constants';
import { Breadcrumbs, Loader, PageHeader, Table, Spin } from 'Components';

import { getColumns } from './columns';
import * as Styled from './styled';

export const SignalDetails = () => {
    // Device token, signal id
    const { token: deviceToken, signalId: pinId } = useParams();

    const [initialLoading, setInitialLoading] = useState(true);

    // Device data
    const { data: deviceData } = useQuery(GET_DEVICE_BY_TOKEN_QUERY, { variables: { deviceToken } });
    const device = deviceData?.device?.[0];

    // Pin data
    const { data: pinData, loading: pinLoading } = useQuery(PIN_QUERY, { variables: { id: pinId } });
    const pin = pinData?.getPinById;

    const { data: messagesData, loading: messagesLoading, refetch: refetchMessages } = useQuery(
        GET_DEVICE_MESSAGES,
        {
            variables: { deviceToken, messagePin: pin?.pin },
            skip: !Number.isInteger(pin?.pin),
        },
    );

    const cursorPagination = messagesData?.getDeviceMessages?.pageInfo ?? {};

    const messages = useMemo(
        () => messagesData?.getDeviceMessages?.edges.map((item) => item.node) ?? [],
        [messagesData],
    );

    useEffect(
        () => {
            if (initialLoading && !pinLoading && !messagesLoading) {
                setInitialLoading(false);
            }
        },
        [pinLoading, messagesLoading],
    );

    const columns = useMemo(() => getColumns(pin), [pin]);

    const paginationHandler = ({ cursors, items }) => {
        refetchMessages({ paging: { items, cursors } });
    };

    if (initialLoading) {
        return <Loader />;
    }

    return (
        <>
            <Breadcrumbs
                labels={BREADCRUMBS_LABELS.createSignal}
                entityNames={[device?.description, pin?.name]}
            />
            <PageHeader title={pin.name} />
            <Styled.TableWrapper>
                <Spin spinning={messagesLoading}>
                    <Table
                        data={messages}
                        columns={columns}
                        pagination={cursorPagination}
                        onPaginationChange={paginationHandler}
                        cyData="signal-messages-table"
                    />
                </Spin>
            </Styled.TableWrapper>
        </>
    );
};
