import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { GET_WEATHER_BY_LOCATION } from 'Constants';

import { WeatherWidget } from '../../../../widgets';

export const Weather = ({ chartSettings, title }) => {
    const { position: { lat, lng }, unit } = chartSettings;
    const { data, loading } = useQuery(
        GET_WEATHER_BY_LOCATION,
        {
            variables: {
                filter: {
                    latitude: lat,
                    longitude: lng,
                    units: unit,
                },
            },
        },
    );

    const weatherData = data?.getWeather || {};
    const { current, hourly } = weatherData;

    return (
        <WeatherWidget
            data={weatherData}
            unit={unit}
            title={title}
            isEmpty={loading || !current || !hourly.length}
        />
    );
};

Weather.propTypes = {
    chartSettings: PropTypes.shape({
        position: PropTypes.shape({
            lat: PropTypes.string.isRequired,
            lng: PropTypes.string.isRequired,
        }).isRequired,
        unit: PropTypes.string.isRequired,
    }).isRequired,
    title: PropTypes.string.isRequired,
};
