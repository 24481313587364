import moment from 'moment';

export const getTimeDiff = (date, errorText) => {
    const transformedDate = new Date(date);
    const isValidDate = date && (transformedDate.toString() !== 'Invalid Date');

    if (!isValidDate) {
        return errorText;
    }

    let difference = moment(transformedDate).fromNow();
    if (difference.startsWith('an')) {
        difference = `1${difference.slice(2)} `;
    }
    if (difference.startsWith('a') && !difference.includes('few')) {
        difference = `1${difference.slice(1)} `;
    }

    return difference;
};

export const displayDuration = (timer) => {
    let displayTime;
    const seconds = (timer / 1000) % 60;
    const minutes = (timer / 1000 - seconds) / 60;

    const dValue = minutes <= 1 ? 'minute' : 'minutes';
    const secondValue = seconds <= 1 ? 'second' : 'seconds';
    if (minutes < 1) {
        displayTime = `${seconds.toFixed(0)} ${secondValue}`;
    } else {
        displayTime = `${minutes.toFixed(0)} ${dValue} - ${seconds.toFixed(
            0,
        )} ${secondValue}`;
    }
    return displayTime;
};
