import { useRouteMatch, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ReactComponent as StructureIcon } from 'Assets/icons/integrations.svg';
import { ReactComponent as MonitoringIcon } from 'Assets/icons/monitoring.svg';
import { ReactComponent as LocationIcon } from 'Assets/icons/map.svg';
import { Loader, RouteWrapper } from 'Components';
import { ROUTES } from 'Constants';

import { PymeshStructure, PymeshMonitoring, PymeshLocation } from './pymeshViews';
import * as Styled from './styled';

const getLinks = (basePath) => [
    { title: 'Structure', icon: <StructureIcon />, route: `${basePath}${ROUTES.pymesh.structure}` },
    { title: 'Monitoring', icon: <MonitoringIcon />, route: `${basePath}${ROUTES.pymesh.monitoring}` },
    { title: 'Location', icon: <LocationIcon />, route: `${basePath}${ROUTES.pymesh.location}` },
];

export const PymeshTable = ({ deviceEdges, isLoading, pymeshDevices, pymeshID }) => {
    const { path, url } = useRouteMatch();

    if (isLoading) {
        return <Loader title="Loading devices..." />;
    }

    return (
        <Styled.Wrapper>
            <Styled.List>
                {getLinks(url).map((link) => (
                        <Styled.Item
                            key={link.title}
                            to={link.route}
                        >
                            {link.icon}
                            <div>{link.title}</div>
                        </Styled.Item>
                ))}
            </Styled.List>
            <div>
                <Switch>
                    <Redirect exact from={path} to={`${path}${ROUTES.pymesh.structure}`} />
                    <RouteWrapper
                        path={`${path}${ROUTES.pymesh.structure}`}
                        render={() => (
                            <PymeshStructure
                                devicesEdges={deviceEdges}
                                pymeshDevices={pymeshDevices}
                                pymeshID={pymeshID}
                            />
                        )}
                    />
                    <RouteWrapper
                        path={`${path}${ROUTES.pymesh.monitoring}`}
                        render={() => (
                            <PymeshMonitoring
                                devicesEdges={deviceEdges}
                                pymeshDevices={pymeshDevices}
                                pymeshID={pymeshID}
                            />
                        )}
                    />
                    <RouteWrapper
                        path={`${path}${ROUTES.pymesh.location}`}
                        render={() => (
                            <PymeshLocation
                                devicesEdges={deviceEdges}
                                pymeshDevices={pymeshDevices}
                            />
                        )}
                    />
                </Switch>
            </div>
        </Styled.Wrapper>
    );
};

PymeshTable.defaultProps = {
    deviceEdges: [],
    isLoading: false,
};

PymeshTable.propTypes = {
    deviceEdges: PropTypes.arrayOf(PropTypes.object),
    isLoading: PropTypes.bool,
    pymeshDevices: PropTypes.arrayOf(PropTypes.object).isRequired,
    pymeshID: PropTypes.string.isRequired,
};
