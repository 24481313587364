import styled from 'styled-components';

export const Wrapper = styled.div`
    margin-top: 15px;
`;

export const Header = styled.h1`
    font-weight: bold;
`;

export const BoxWrapper = styled.div`
    box-shadow: 0 2px 5px 0 rgba(50, 50, 50, 0.2);
    background: white;
    border-radius: 5px;
    padding: 25px 30px 30px 30px;
    display: flex;
    flex-direction: column;
`;
