import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { GET_DEVICE_LAST_MESSAGE } from 'Constants';
import { getStringifiedPayload } from 'Utils';
import { useMessageSubscription } from 'Hooks';

import { AirQualityWidget } from '../../../../widgets';

export const AirQuality = ({ chartId, title, deviceToken, pin }) => {
    const { data, loading, refetch } = useQuery(
        GET_DEVICE_LAST_MESSAGE,
        {
            variables: { chartId },
        },
    );
    const lastMessage = data?.getDeviceLastMessage;

    useMessageSubscription([deviceToken], pin, refetch);

    const value = useMemo(() => {
        if (!loading && lastMessage) {
            return getStringifiedPayload(lastMessage.payload);
        }

        return null;
    }, [lastMessage]);

    return (
        <AirQualityWidget
            data={value}
            title={title}
            isEmpty={loading || !lastMessage}
        />
    );
};

AirQuality.propTypes = {
    chartId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    deviceToken: PropTypes.string.isRequired,
    pin: PropTypes.number.isRequired,
};
