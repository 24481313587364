import styled, { css } from 'styled-components';
import { hexToRgb } from 'Utils';

export const FileWrapper = styled.li`
    margin: 0;
    padding: 0;
`;

export const FileItem = styled.div`
    height: 30px;
    width: 100%;
    padding-left: 53px;
    padding-top: 4px;
    position: relative;
    cursor: pointer;

    span {
        position: absolute;
        top: 20%;
        color: ${({ theme }) => theme.colors.title};
        white-space: nowrap;
    }

    &:hover {
        background-color: ${({ theme }) => theme.colors.boxBackground};
    }

    ${({ $active, theme }) => $active && `
        background-color: rgba(${hexToRgb(theme.colors.boxBackground)}, 0.5);
    `}
`;

export const FileIcon = styled.div`
    opacity: 30%;
    display: inline-block;
    margin-right: 10px;

    svg {
        vertical-align: middle;
        width: 15px;
        height: 15px;
    }
`;

export const FileInput = styled.input`
    border: 1px solid rgba(${({ theme }) => hexToRgb(theme.colors.title)}, 0.5);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    height: 18px;
    position: absolute;
    overflow: hidden;
    max-width: 100px;
    top: 8px;
`;

const updatedStyling = css`
    color: #c46200 !important;
`;

export const FileName = styled.span`
    ${({ $updated }) => $updated && updatedStyling}
`;
