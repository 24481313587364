import cloneDeep from 'lodash/cloneDeep';
import {
    defaultStructures,
    MAIN_FILES,
    ADDING_DIRECTORY,
    C_EXTENSIONS_FILES,
    supportedPymakrExtensions,
    pymakrFilesExceptions,
} from 'Constants';
import { setStoredMeta, getStoredMeta } from './storage';

export const getDeviceModeInfo = (projectKey) => getStoredMeta(projectKey)?.deviceModeInfo;
export const getReleaseModeInfo = (projectKey) => getStoredMeta(projectKey)?.releaseModeInfo;

export const setDeviceModeInfo = (projectKey, title, deviceToken, user) => {
    const deviceMeta = getDeviceModeInfo(projectKey);
    setStoredMeta(projectKey, { deviceModeInfo: { ...deviceMeta, title, deviceToken, user } });
};

export const setReleaseModeInfo = (projectKey, applicationId, releaseId, title, user) => {
    const deviceMeta = getDeviceModeInfo(projectKey);
    setStoredMeta(projectKey, { releaseModeInfo: { ...deviceMeta, applicationId, releaseId, title, user } });
};

export const checkItemExists = (targetDir, value, type) => {
    const index = targetDir.findIndex((i) => i.name === value && i.type === type);
    return index >= 0;
};

export const sortByType = (array, type) => {
    const newArr = cloneDeep(array);

    newArr.sort((a, b) => {
        let titleA;
        let titleB;

        if (a.type === type && b.type === type) {
            titleA = a.name?.toLowerCase();
            titleB = b.name?.toLowerCase();
        }

        if (titleA > titleB) {
            return 1;
        }
        if (titleA < titleB) {
            return -1;
        }
        return 0;
    });

    return newArr;
};

export const getTarget = (structure, path) => {
    if (!path) {
        return structure;
    }

    const directories = path.split('/');

    let target = cloneDeep(structure);

    directories.forEach((dir) => {
        const targetArray = [...target.files];
        const targetIndex = targetArray.findIndex((i) => i.name === dir);
        target = target.files[targetIndex];
    });

    return target;
};

export const setTarget = (target, struct, path) => {
    const structure = cloneDeep(struct);

    if (structure.path === target.path && target.path === '') {
        return cloneDeep(target);
    }

    const itemIndex = structure.files.findIndex((i) => path.startsWith(i.path));
    if (structure.files[itemIndex].path === path) {
        structure.files[itemIndex] = target;
        return structure;
    }

    structure.files[itemIndex] = setTarget(target, structure.files[itemIndex], path);
    return structure;
};

const insertFileToDirectory = (folder, incomingDirectories, key) => {
    const directories = incomingDirectories.split('/');
    const currentFolder = cloneDeep(folder);
    const currentPath = `${currentFolder.type !== 'scope' ? `${currentFolder.path}/` : ''}`;
    let targetFolder = {};

    if (directories.length === 1) {
        const file = {
            type: 'file',
            name: directories[0],
            path: `${currentPath}${directories[0]}`,
            parent: currentFolder.path,
            key,
        };
        currentFolder.files.push(file);
        currentFolder.files = sortByType(currentFolder.files, 'file');
        return currentFolder;
    }

    const currentDir = directories.shift();
    const existingInd = currentFolder.files.findIndex((item) => item.name === currentDir && item.type === 'folder');

    if (existingInd >= 0) {
        targetFolder = insertFileToDirectory(currentFolder.files[existingInd], directories.join('/'), key);
        currentFolder.files[existingInd] = targetFolder;
    } else {
        const newFolder = {
            name: currentDir,
            files: [],
            expanded: false,
            hierarchyLevel: currentFolder.hierarchyLevel + 1,
            path: `${currentPath}${currentDir}`,
            parent: currentFolder.path,
            type: 'folder',
        };
        
        targetFolder = insertFileToDirectory(newFolder, directories.join('/'), key);
        currentFolder.files.unshift(targetFolder);
        currentFolder.files = sortByType([...currentFolder.files], 'folder');
    }

    return currentFolder;
};

export const prepareEditorStructure = (title, files, user) => {
    let scope = {
        type: 'scope',
        expanded: true,
        hierarchyLevel: 0,
        name: title,
        path: '',
        parent: '',
        files: [],
    };

    if (files?.length) {
        files.forEach(({ path, content }) => {
            const file = path.split('/')[path.split('/').length - 1];
            const extension = file.slice(file.lastIndexOf('.'));
            if (supportedPymakrExtensions.includes(extension) || pymakrFilesExceptions.includes(file)) {
                const key = `${user}-${title}-${path}`;
                localStorage.setItem(key, content);
                scope = insertFileToDirectory(scope, path, key);
            }
        });
    }
    return scope;
};

export const getDefaultStructure = (lang, key) => {
    const structure = defaultStructures.filter((i) => i.key === lang)[0].structure;
    return structure.map((i) => {
        const processedItem = { ...i };
        if (processedItem.type === 'file') {
            processedItem.parent = `${processedItem.parent ? processedItem.parent : ''}`;
            processedItem.key = `${key}-${processedItem.path}`;
            processedItem.path = `${processedItem.path ? processedItem.path : ''}`;
        }
        return processedItem;
    });
};

export const prepareMappedHierarchy = (dir, depth) => {
    const result = [];

    dir.files.forEach((item) => {
        const newItem = {
            name: item.name,
            parent: item.parent,
            depth,
            content: item.type === 'file' ? localStorage.getItem(item.key) || '' : undefined,
            path: item.path,
        };

        result.push(newItem);

        if (item.files?.length) {
            const nextLevelFiles = prepareMappedHierarchy(item, depth + 1);
            result.push(...nextLevelFiles);
        }
    });

    return result;
};

export const setFCOTAHierarchy = (projectKey, hierarchy) => {
    const deviceMeta = getDeviceModeInfo(projectKey);
    setStoredMeta(projectKey, { deviceModeInfo: { ...deviceMeta, hierarchy } });
};

export const getRenamingItems = (projectKey) => {
    const renamingItems = getStoredMeta(projectKey)?.renamingItems || [];
    const { hierarchy } = getDeviceModeInfo(projectKey);
    return renamingItems.filter((item) => hierarchy.includes(item.initialPath));
};

const getBootFile = () => ({
    ...defaultStructures[0].structure[0],
    content: '',
    depth: 0,
});

const getMainFile = () => ({
    ...defaultStructures[0].structure[1],
    content: '',
    depth: 0,
});

const prepareFilesWithFlashDirectory = (currentProject) => ({
    ...currentProject,
    files: currentProject.files?.map((file) => {
        if (file.path.split('/')[0] === ADDING_DIRECTORY && file.parent === ADDING_DIRECTORY) {
            return {
                ...file,
                depth: file.depth - 1 <= 0 ? 0 : file.depth - 1,
                path: file.path.slice(file.path.indexOf('/') + 1),
                parent: file.parent === ADDING_DIRECTORY ? '' : file.parent,
            };
        }
        return file;
    }),
});

export const prepareFiles = (project) => {
    const addingFiles = [];
    const currentProject = prepareFilesWithFlashDirectory(project);
    const isExistCFiles = currentProject.files?.find((file) => C_EXTENSIONS_FILES.includes(file.name.slice(file.name.indexOf('.'))));
    const isBootFileExist = currentProject?.files?.find(
        (file) => file.depth === 0 && file.name === MAIN_FILES.BOOT,
    );
    if (!isBootFileExist) {
        addingFiles.push(getBootFile());
    }

    const isMainFileExist = currentProject?.files?.find(
        (file) => file.depth === 0 && file.name === MAIN_FILES.MAIN,
    );
    if (!isMainFileExist) {
        addingFiles.push(getMainFile());
    }

    const preparedFiles = currentProject?.files?.map((file) => ({
        content: file.content,
        path: file.path,
    })) || [];

    if (!isExistCFiles) {
        return [...preparedFiles, ...addingFiles];
    } 
    return [...preparedFiles];
};
