import styled from 'styled-components';

import { TitleBox as TitleBoxComponent, Button } from 'Components';

export const Wrapper = styled.div`
    h1 {
        color: ${({ theme }) => theme.colors.title};
        font-weight: 700;
        margin-top: 35px;
        margin-bottom: 0;
    }
`;

export const TitleBox = styled(TitleBoxComponent)`
    width: calc(100% - 20px);
    padding-right: 10px;
`;

export const TileWrapper = styled.div`
    margin-top: 14px;
    margin-bottom: 35px;
`;

export const PymakrList = styled.ul`
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
`;

export const ModalWrapper = styled.div`
    padding: 32px 0;
    background-color: ${({ theme }) => theme.colors.boxBackground};

    h1 {
        color: ${({ theme }) => theme.colors.text};
        font-family: MainFont;
        font-weight: 700;
        line-height: 46.97px;
        text-align: center;
    }
`;

export const ModalButtonsWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

export const ModalBtn = styled.button`
    background-color: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, .25);
    min-height: 200px;
    min-width: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-top: 40px;
    margin: 0 35px;
    cursor: pointer;
    

    span {
        font-size: 18px;
        font-weight: 400;
        display: block;
        text-align: center;
        min-height: 40%;
    }
`;

export const MicroPython = styled(ModalBtn)`
    svg {
        width: 76px;
        height: 76px;
    }
`;

export const CPlusPlus = styled(ModalBtn)`
    svg {
        width: 93px;
        height: 82px;
    }
`;

export const ModalCloseBtn = styled(Button)`
    width: 20%;
    margin: 33px auto 15px;
`;
