import { useMemo } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';

import {
    GET_AWS_THINGS_BY_ENDPOINT,
    GET_ALL_DEVICES_QUERY,
} from 'Constants';
import { Loader } from '../../../loader';

import { initialValues, validationSchema } from './config';
import { FormContent } from './formContent';
import * as Styled from './styled';

export const AwsThing = ({
    connectionMask,
    nextStep,
    initialValues: parentInitialValues,
    onCancel,
    submitText,
    isLoading,
}) => {
    const { data: allDevicesData, loading } = useQuery(
        GET_ALL_DEVICES_QUERY,
        { fetchPolicy: 'cache-and-network' },
    );
    const allDevices = allDevicesData?.device ?? [];

    const { data: awsThingsData } = useQuery(
        GET_AWS_THINGS_BY_ENDPOINT,
        { variables: { endpointAddress: connectionMask.endpointAddress } },
    );
    const alreadyUsedDevices = awsThingsData?.getAwsThingsByEndpoint ?? [];

    const onSubmit = (values) => {
        nextStep(values, allDevices);
    };

    const combinedInitialValues = useMemo(() => {
        const { deviceTokens = [], ...rest } = parentInitialValues;
        const devices = allDevices.map((device) => deviceTokens.includes(device.token));

        return {
            ...initialValues,
            devices,
            ...rest,
        };
    }, [allDevices]);

    if (loading) {
        return <Loader />;
    }

    return (
        <>
            <Styled.EndpointLabel>
                AWS Endpoint
            </Styled.EndpointLabel>
            <Styled.EndpointValue>
                {connectionMask.endpointAddress}
            </Styled.EndpointValue>
            <Formik
                initialValues={combinedInitialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {(props) => (
                    <FormContent
                        {...props}
                        allDevices={allDevices}
                        onCancel={onCancel}
                        submitText={submitText}
                        alreadyUsedDevices={alreadyUsedDevices}
                        editMode={!isEmpty(parentInitialValues)}
                        isLoading={isLoading}
                    />
                )}
            </Formik>
        </>
    );
};

AwsThing.propTypes = {
    connectionMask: PropTypes.object.isRequired,
    awsService: PropTypes.object.isRequired,
    nextStep: PropTypes.func.isRequired,
    initialValues: PropTypes.object,
    onCancel: PropTypes.func,
    submitText: PropTypes.string,
    isLoading: PropTypes.bool,
};

AwsThing.defaultProps = {
    initialValues: {},
    onCancel: null,
    submitText: 'Create',
    isLoading: false,
};
