import gql from 'graphql-tag';

export const GET_LTE_SETTINGS = gql`
    query getLteSettings {
        getLteSettings {
            _id
            name
            created
            carrier
            band
            cid
            apn
            protocol
            reset
            default
        }
    }
`;

export const GET_PYCOM_LORA_SERVER_PROFILE = gql`
    query getPycomLoraServerProfile {
        getPycomLoraServerProfile {
            applicationID
            organizationID
            userID
            deviceprofileABPID
            deviceprofileOTAAID
        }
    }
`;

export const SET_LTE_DEFAULT = gql`
    mutation setLteDefault($lteId: String!) {
        setLteDefault(lteId: $lteId) {
            _id
            created
            name
            carrier
            band
            cid
            apn
            protocol
            reset
            default
        }
    }
`;

export const CHECK_LTE_DEFAULT = gql`
    mutation checkLteDefault {
        checkLteDefault {
            _id
            created
            name
            carrier
            band
            cid
            apn
            protocol
            reset
            default
        }
    }
`;

export const REMOVE_LTE_SETTINGS = gql`
    mutation removeLteSettings($lteId: String!) {
        removeLteSettings(lteId: $lteId) {
            _id
            created
            name
            carrier
            band
            cid
            apn
            protocol
            reset
            default
        }
    }
`;

export const SAVE_LTE_SETTINGS = gql`
    mutation saveLteSettings($lteSettings: LteSettingsInputType!) {
        saveLteSettings(lteSettings: $lteSettings) {
            _id
            created
            name
            carrier
            band
            cid
            apn
            protocol
            reset
            default
        }
    }
`;
