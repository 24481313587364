import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import { GET_SIM_CARDS, ADD_SIM_CARDS } from 'Constants';

import { showToastError, showToastSuccess } from 'Utils';
import { validationSchema, initialValues } from './config';
import { FormContent } from './formContent';

import * as Styled from './styled';

export const AddSimCardModal = ({ handleClose, handleSuccess }) => {
    const [addSimCardOrderNumber, { loading: isLoadingAddOrderNumber }] = useMutation(ADD_SIM_CARDS, {
        refetchQueries: [GET_SIM_CARDS],
    });
    const addNewSimCardOrderNumber = async ({
        orderNumber,
    }) => {
        const localOrderNumber = Number(orderNumber);
        try {
            const { data: { addSimCards: result } } = await addSimCardOrderNumber({
                variables: {
                    orderNumber: localOrderNumber,
                },
            });

            if (!result) {
                showToastError('Sim Card order validation failed');
                return handleClose();
            }

            showToastSuccess('Sim Card added successfully');
            const finalFunc = handleSuccess || handleClose;
            finalFunc();
        } catch (error) {
            showToastError(error.message);
        }
    };

    return (
        <Styled.Modal data-cy="addSimCard-modal">
            <Styled.Header>
                Add a Sim Card
            </Styled.Header>
            <Styled.Description>
                <span>
                    In order to get your SIM-cards *here*,
                    please write your Order number in the field below.
                </span>
            </Styled.Description>

            <Formik
                validationSchema={validationSchema}
                onSubmit={addNewSimCardOrderNumber}
                initialValues={initialValues}
            >
                {(props) => (
                    <FormContent
                        {...props}
                        handleClose={handleClose}
                        isLoading={isLoadingAddOrderNumber}
                    />
                )}
            </Formik>
        </Styled.Modal>
    );
};

AddSimCardModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSuccess: PropTypes.func,
    orderNumber: PropTypes.string,
};

AddSimCardModal.defaultProps = {
    handleSuccess: null,
    orderNumber: '',
};
