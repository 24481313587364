import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import { Button, Loader, projectDeviceColumns, Table, Spin, useLastConnection } from 'Components';
import { GET_DEVICES_QUERY, PROJECT_CHART_TYPES } from 'Constants';
import { ReactComponent as InfoIcon } from 'Assets/icons/support.svg';

import * as Styled from './styled';

export const WidgetDevices = ({ prevStep, nextStep, projectId, widgetType, loading, widgetInfo }) => {
    const [selectedTokens, setSelectedTokens] = useState([]);
    const [initialLoading, setInitialLoading] = useState(true);

    const { data, loading: queryLoading } = useQuery(
        GET_DEVICES_QUERY,
        {
            variables: { applicationId: projectId },
            skip: !projectId,
        },
    );
    const devices = useMemo(() => (data?.getDevices.edges.map((edge) => edge.node) ?? []), [data]);

    const { data: tableData, loading: tableLoading } = useLastConnection(devices);

    useEffect(
        () => {
            if (initialLoading && !queryLoading) {
                setInitialLoading(false);
            }
        },
        [queryLoading],
    );

    const tableSelectionType = useMemo(() => {
        switch (widgetType) {
            case PROJECT_CHART_TYPES.AIR_QUALITY:
            case PROJECT_CHART_TYPES.HEART_RATE:
            case PROJECT_CHART_TYPES.SIGNAL_QUALITY:
            case PROJECT_CHART_TYPES.ALTITUDE:
            case PROJECT_CHART_TYPES.BATTERY_LEVEL:
                return 'radio';

            default:
                return 'checkbox';
        }
    }, [widgetType]);

    const onSubmit = () => {
        const newValues = { ...widgetInfo };

        if (selectedTokens.length) {
            newValues.settings.filteredDevices = selectedTokens;
        }

        nextStep(newValues);
    };

    const onDeviceSelect = (selectedIds) => {
        const tokens = selectedIds.map((id) => {
            const device = devices.find((item) => item._id === id);

            return device?.token;
        });

        setSelectedTokens(tokens);
    };

    if (initialLoading) {
        return <Loader />;
    }

    return (
        <>
            <Styled.Title>
                Please select devices:
            </Styled.Title>
            <Styled.Tip>
                <InfoIcon />
                You can select the devices from which you want to display information on this widget.
            </Styled.Tip>
            <Spin spinning={queryLoading || tableLoading}>
                <Table
                    columns={projectDeviceColumns}
                    data={tableData}
                    onSelect={onDeviceSelect}
                    selectionType={tableSelectionType}
                    cyData="widget-wizard-device-table"
                />
            </Spin>
            <Styled.ButtonsWrapper>
                <Button
                    buttonType="transparent"
                    onClick={prevStep}
                >
                    Back
                </Button>
                <Button
                    onClick={onSubmit}
                    loading={loading}
                    cyData="project-widget-add-device-submit-button"
                >
                    Add Widget
                </Button>
            </Styled.ButtonsWrapper>
        </>
    );
};

WidgetDevices.propTypes = {
    prevStep: PropTypes.func.isRequired,
    nextStep: PropTypes.func.isRequired,
    projectId: PropTypes.string,
    loading: PropTypes.bool,
    widgetType: PropTypes.string,
    widgetInfo: PropTypes.object.isRequired,
};

WidgetDevices.defaultProps = {
    projectId: '',
    loading: false,
    widgetType: '',
};
