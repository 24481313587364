import styled from 'styled-components';

export const Wrapper = styled.div`
    margin-top: 15px;
`;

export const Actions = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-bottom: 15px;

    button {
        margin-right: 10px;
    }
`;
