import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import {
    PROJECT_CHART_TYPES,
    DIMENSION_MULTIPLIERS,
    UPDATE_APPLICATION_CHART,
    GET_APPLICATION_MESSAGES,
    PROJECT_CHARTS_QUERY,
    DELETE_APPLICATION_CHART,
} from 'Constants';
import { showToastSuccess, removeGraphQlTypenames } from 'Utils';

import { WidgetMenu } from '../../../widgets';
import { ProjectWidgetEdit } from '../projectWidgetEdit';

import {
    OnlineOfflineChart,
    GroupedDevicesChart,
    Map,
    LastValue,
    Humidity,
    Temperature,
    SignalQuality,
    MapHistory,
    Count,
    Weather,
    AirQuality,
    HeartRate,
    Pressure,
    LineChart,
    BarChart,
    Altitude,
    BatteryLevel,
} from './components';

import * as Styled from './styled';

export const ProjectWidgetContainer = (props) => {
    const { chartId, title, chartType, widgetConfig, settings } = props;
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    const [updateApplicationChart] = useMutation(UPDATE_APPLICATION_CHART, {
        refetchQueries: [GET_APPLICATION_MESSAGES],
    });

    const [deleteWidgetMutation] = useMutation(DELETE_APPLICATION_CHART, {
        refetchQueries: [PROJECT_CHARTS_QUERY],
        awaitRefetchQueries: true,
    });

    const widgetStyles = useMemo(() => {
        const { width: widthMultiplier, height: heightMultiplier } = DIMENSION_MULTIPLIERS;
        const height = (heightMultiplier * widgetConfig.height) - 12;
        const width = ((widthMultiplier * widgetConfig.width) - 12);
        const left = widgetConfig.x * widthMultiplier;
        const top = widgetConfig.y * heightMultiplier;
        return { height, width, left, top };
    }, [widgetConfig]);

    const onWidgetDelete = (id) => {
        deleteWidgetMutation({
            variables: {
                id,
            },
            update: () => {
                showToastSuccess('The widget was removed successfully');
            },
        });
    };

    const onEdit = (values) => {
        const filteredValues = removeGraphQlTypenames(values);

        updateApplicationChart({
            variables: {
                chartInput: {
                    _id: chartId,
                    ...filteredValues,
                },
            },
            refetchQueries: [GET_APPLICATION_MESSAGES, PROJECT_CHARTS_QUERY],
            update: () => {
                setIsEditModalOpen(false);
                showToastSuccess('Widget edited successfully');
            },
        });
    };

    const renderWidget = () => {
        switch (chartType) {
            case PROJECT_CHART_TYPES.ONLINE_OFFLINE:
                return (
                    <OnlineOfflineChart
                        chartId={chartId}
                        title={title}
                    />
                );
            case PROJECT_CHART_TYPES.GROUPED_DEVICES:
                return (
                    <GroupedDevicesChart
                        chartId={chartId}
                        title={title}
                        chartSettings={settings}
                    />
                );
            case PROJECT_CHART_TYPES.HUMIDITY:
                return (
                    <Humidity
                        chartId={chartId}
                        title={title}
                    />
                );
            case PROJECT_CHART_TYPES.TEMPERATURE:
                return (
                    <Temperature
                        chartId={chartId}
                        title={title}
                        chartSettings={settings}
                    />
                );
            case PROJECT_CHART_TYPES.SIGNAL_QUALITY:
                return (
                    <SignalQuality
                        chartId={chartId}
                        title={title}
                        chartSettings={settings}
                    />
                );
            case PROJECT_CHART_TYPES.MAP:
                return (
                    <Map
                        chartId={chartId}
                        title={title}
                    />
                );
            case PROJECT_CHART_TYPES.MAP_HISTORY:
                return (
                    <MapHistory
                        chartId={chartId}
                        chartSettings={settings}
                        updateApplicationChart={updateApplicationChart}
                        title={title}
                    />
                );
            case PROJECT_CHART_TYPES.COUNT:
                return (
                    <Count
                        chartId={chartId}
                        title={title}
                        chartSettings={settings}
                    />
                );
            case PROJECT_CHART_TYPES.TABLE:
                return (
                    <LastValue
                        chartId={chartId}
                        widgetStyles={widgetStyles}
                        title={title}
                    />
                );
            case PROJECT_CHART_TYPES.WEATHER:
                return (
                    <Weather
                        chartSettings={settings}
                        title={title}
                    />
                );
            case PROJECT_CHART_TYPES.AIR_QUALITY:
                return (
                    <AirQuality
                        chartId={chartId}
                        title={title}
                    />
                );
            case PROJECT_CHART_TYPES.HEART_RATE:
                return (
                    <HeartRate
                        chartId={chartId}
                        title={title}
                    />
                );
            case PROJECT_CHART_TYPES.PRESSURE:
                return (
                    <Pressure
                        chartId={chartId}
                        title={title}
                    />
                );
            case PROJECT_CHART_TYPES.LINE_CHART:
                return (
                    <LineChart
                        chartId={chartId}
                        chartSettings={settings}
                        updateApplicationChart={updateApplicationChart}
                        title={title}
                    />
                );
            case PROJECT_CHART_TYPES.BAR_CHART:
                return (
                    <BarChart
                        chartId={chartId}
                        chartSettings={settings}
                        updateApplicationChart={updateApplicationChart}
                        title={title}
                    />
                );
            case PROJECT_CHART_TYPES.ALTITUDE:
                return (
                    <Altitude
                        chartId={chartId}
                        title={title}
                        chartSettings={settings}
                    />
                );
            case PROJECT_CHART_TYPES.BATTERY_LEVEL:
                return (
                    <BatteryLevel
                        chartId={chartId}
                        title={title}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <Styled.WidgetContainer
            style={widgetStyles}
            data-cy={`project-widget-container-${title.replaceAll(/\s+/g, '_')}`}
        >
            <div className="tabPanel extended heightExtended noMargin">
                <WidgetMenu
                    id={chartId}
                    onDelete={onWidgetDelete}
                    onEdit={() => setIsEditModalOpen(true)}
                    title={title}
                />
                {renderWidget()}
            </div>
            <ProjectWidgetEdit
                isOpen={isEditModalOpen}
                onClose={() => setIsEditModalOpen(false)}
                onSubmit={onEdit}
                widgetType={chartType}
                initialValues={{ name: title, settings }}
            />
        </Styled.WidgetContainer>
    );
};

ProjectWidgetContainer.propTypes = {
    title: PropTypes.string.isRequired,
    chartType: PropTypes.string.isRequired,
    widgetConfig: PropTypes.object.isRequired,
    chartId: PropTypes.string.isRequired,
    settings: PropTypes.object.isRequired,
};
