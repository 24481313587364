/* eslint-disable jsx-a11y/control-has-associated-label */
import PropTypes from 'prop-types';

import { DIMENSION_MULTIPLIERS } from 'Constants';
import * as Styled from '../../../styled';

export const WidgetDummy = ({
    dragged,
    overlapped,
    index,
    deletionDisabled,
    dragWidgetDummy,
    resizeWidgetDummy,
    removeWidgetFromDashboard,
    title,
    subTitle,
    widgetConfiguration,
}) => {
    const { height: heightFromConfig, width: widthFromConfig, x, y } = widgetConfiguration;
    const { width: widthMultiplier, height: heightMultiplier } = DIMENSION_MULTIPLIERS;
    const containerStyles = {
        height: (heightMultiplier * heightFromConfig) - 12,
        width: (widthMultiplier * widthFromConfig) - 12,
        left: x * widthMultiplier,
        top: y * heightMultiplier,
    };
    const highlightenClass = dragged ? 'selected' : '';
    const overlappedClass = overlapped ? 'overlapped' : '';

    return (
        <Styled.WidgetContainer className="noselect" style={containerStyles}>
            {dragged && (
                <div className="widgetOriginGhost" />
            )}
            <button className="widgetDummyDragButton" onMouseDown={() => dragWidgetDummy(index)} />
            <div className={`tabPanel extended heightExtended noMargin widgetEditionPanel ${highlightenClass} ${overlappedClass}`}>
                <div className="tabPanelCoreContent">
                    <div className="dummyContainer">
                        <div className="dummyContent">
                            <h2>{title}</h2>
                            <h4>{subTitle}</h4>
                        </div>
                        <Styled.ResizeButton onMouseDown={() => resizeWidgetDummy(index)} />
                        {!deletionDisabled && (
                            <Styled.RemoveButton onClick={() => removeWidgetFromDashboard(index)} />
                        )}
                    </div>
                </div>
            </div>
        </Styled.WidgetContainer>
    );
};

WidgetDummy.defaultProps = {
    widgetConfiguration: {
        height: 1,
        width: 2,
        x: 0,
        y: 0,
    },
    subTitle: null,
    dragged: false,
    overlapped: false,
    deletionDisabled: true,
};

WidgetDummy.propTypes = {
    widgetConfiguration: PropTypes.object,
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
    dragged: PropTypes.bool,
    index: PropTypes.number.isRequired,
    dragWidgetDummy: PropTypes.func.isRequired,
    resizeWidgetDummy: PropTypes.func.isRequired,
    removeWidgetFromDashboard: PropTypes.func.isRequired,
    overlapped: PropTypes.bool,
    deletionDisabled: PropTypes.bool,
};
