export const LORA = 'lora';
export const WIFI = 'wifi';
export const LTE = 'lte';
export const ETHERNET = 'ethernet';
export const SIGFOX = 'sigfox';
export const BLUETOOTH = 'bluetooth';
export const PYGATE = 'pygate';
export const LORA_OTAA = 'lora_otaa';
export const LORA_ABP = 'lora_abp';
export const WIPY = 'wipy';
export const F01 = 'f01';
export const ALL = 'all';

export const CUSTOM_LOGIN = 'CUSTOM_LOGIN';
export const SSO_LOGIN = 'PYCOM_SSO';

export const ALL_DEVICES = { value: ALL, label: 'All devices' };

export const LORA_CONSTANTS = {
    SERVERS: {
        TTN: 'TTN Server',
        CHIRPSTACK: 'Pycom ChirpStack Server',
    },
    RADIO_BUTTONS: [
        {
            value: 'loRa OTAA',
            label: 'LoRa OTAA (Recommended)',
        },
        {
            value: 'loRa ABP',
            label: 'LoRa ABP',
        },
    ],
    REGIONS: {
        TTN: [
            { value: 'eu1.cloud.thethings.network', label: 'Europe 1' },
            { value: 'nam1.cloud.thethings.network', label: 'North America 1' },
            { value: 'au1.cloud.thethings.network', label: 'Australia 1' },
        ],
        CHIRPSTACK: [
            { value: 'chirpstack-network-server-868', label: 'EU868' },
            { value: 'chirpstack-network-server-915_0', label: 'US915_0' },
            { value: 'chirpstack-network-server-915_1', label: 'US915_1' },
        ],
    },
    PYCOM: 'chirpstack-network-server',
};

export const SERVER_OPTIONS = Object.values(LORA_CONSTANTS.SERVERS);

export const DEFAULT_FIRMWARE_FILENAME = 'firmware.bin';

export const MODAL = 'Modal';
export const TAB = 'Tab';
export const CURRENT_DEVICE = {
    DESCRIPTION: 'Description',
    WIFI: 'Wifi',
    DEVICE_TYPE: 'Device Type',
    SHIELD_TYPE: 'Shield type',
    SHIELD_GATEWAY: 'Shield gateway',
};
export const OFFLINE = 'offline';
export const ONLINE = 'online';
export const PYMAKR = 'pymark';
export const TYPE_GENERATION = {
    OFFLINE,
    PYMAKR,
};
export const ACTIVATION_OVER_WIFI = 'Activation over wifi';
export const ACTIVATION_OVER_LTE = 'Activation over lte';
export const CUSTOM_PAGINATION = [6, 8, 10, 20];

export const SAMPLING_TYPES = {
    TRAINING: 1,
    TEST: 2,
};

export const SIGFOX_CONTRACT_TYPE_DEVKIT = 'devKit';
export const SIGFOX_CONTRACT_TYPE_CUSTOM = 'custom';
export const ZIP_EXTENSIONS = ['.7z', '.s7z', '.zip'];
