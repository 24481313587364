export const trees = [
    'Osier',
    'Alder',
    'Furze',
    'Gorse',
    'Ash',
    'Aspen',
    'Hawthorn',
    'Beech',
    'Snowberry',
    'Hazel',
    'Holly',
    'Spindle',
    'Blackthorn',
    'Honeysuckle',
    'Box',
    'Hornbeam',
    'Broom',
    'Sycamore',
    'Ivy',
    'Buddleia',
    'Juniper',
    'Laburnum',
    'Lilac',
    'Walnut',
    'Whin',
    'Whitebeam',
    'May',
    'Dogwood',
    'Elder',
    'Rhododendron',
    'Yew',
    'Rowan',
];
