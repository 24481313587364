import { Field } from 'formik';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

import { TextInput } from '../../inputs';

import * as Styled from './styled';

export const FormContent = ({ checkingValue, handleDisable, values }) => {
    useEffect(() => {
        if (values.username === checkingValue) {
            handleDisable(false);
        } else {
            handleDisable(true);
        }
    }, [values.username]);

    return (
        <Styled.Form>
            <Styled.FormLabel>
                Enter your username
            </Styled.FormLabel>
            <Field
                name="username"
                type="text"
                placeholder="Enter your username"
                component={TextInput}
            />
        </Styled.Form>
    );
};

FormContent.propTypes = {
    checkingValue: PropTypes.string,
    values: PropTypes.object.isRequired,
    handleDisable: PropTypes.func,
};

FormContent.defaultProps = {
    checkingValue: '',
    handleDisable: () => {},
};
