export const SIM_CARD_PROVIDERS = {
    VODAFONE: 'VODAFONE',
    ONCE: '1NCE',
};

export const notAvailableStatus = 'N/A';

export const MAJOR_CODE_SUCCESS = '000';
export const MINOR_CODE_SUCCESS = '0000';
export const MAJOR_CODE_REQUEST_FAILED = '200';
export const MAJOR_CODE_VODAFONE_ERROR = '999';
export const MINOR_CODE_SIM_SAME_STATE = '5141';
export const MINOR_CODE_STATE_CHANGE_FAILED = '7200';

export const SIM_INFO_COLUMNS_LENGTH = 3;

export const VODAFONE_CONSUMPTION_CHART_TYPES = [
    {
        title: 'SIM card upload data by ICCID (Live state)',
        dropDownTitle: 'Upload data chart',
        dataIndex: 'Sum_Bytes_upload',
    },
    {
        title: 'SIM card download data by ICCID (Live state)',
        dropDownTitle: 'Download data chart',
        dataIndex: 'Sum_Bytes_download',
    },
    {
        title: 'SIM card data usage by ICCID (Live state)',
        dropDownTitle: 'Total usage data chart',
        dataIndex: 'Sum_Total_Kbytes',
    },
];

export const ONCE_CONSUMPTION_CHART_TYPES = [
    {
        title: 'SIM card data usage RX',
        dropDownTitle: 'RX data usage chart',
        dataIndex: 'dataUsageRx',
    },
    {
        title: 'SIM card data usage TX',
        dropDownTitle: 'TX data usage chart',
        dataIndex: 'dataUsageTx',
    },
    {
        title: 'SIM card total data usage',
        dropDownTitle: 'Total data usage chart',
        dataIndex: 'totalDataUsage',
    },
];
