import styled from 'styled-components';
import { Collapse } from 'antd';

import { Button } from 'Components';
import { hexToRgb } from 'Utils';
import { pymakrCompilationStatuses } from 'Constants';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 25px 15px;

    h2 {
        margin: 10px auto;
        font-weight: 700;
        color: ${({ theme }) => theme.colors.title};
    }
`;

export const OptionBtn = styled(Button)`
    width: 80%;
    margin: 5px auto;
    position: relative;

    &:hover {
        background-color: rgba(${({ theme }) => hexToRgb(theme.colors.title)}, 0.05);
    }
`;

export const OptionBtnSmall = styled(OptionBtn)`
    width: 90%;
`;

export const OptionCollapsible = styled(Collapse)`
    background: transparent;
    width: 80%;
    margin: 0 auto;
    border-radius: 4px;
    cursor: pointer;
    justify-content: center;
    font-size: 14px;
    align-items: center;

    & .ant-collapse-header {
        display: block !important;
        text-align: center;
    }

    &:hover {
        background-color: rgba(${({ theme }) => hexToRgb(theme.colors.title)}, 0.05);
    }
`;

export const OptionPanel = styled(Collapse.Panel)``;

export const Title = styled.div`
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
`;

export const Row = styled.div`
    width: 100px;
    margin-top: 10px;
`;

export const Content = styled.div`
    display: flex;
`;

export const ContentTitle = styled.div`
    width: 250px;
    font-size: 14px;
    font-weight: bold;
    margin-left: 10px;
`;

export const Status = styled.div`
    margin-left: 5px;
    color: ${({ $status, theme }) =>
        ($status === pymakrCompilationStatuses.succeeded
            ? theme.colors.success
            : theme.colors.error)};
`;
