import styled from 'styled-components';

export const Fake = styled.span`
    position: absolute;
    left: 0;
    width: 18px;
    height: 18px;
    border-radius: 2px;
    border: solid 1px ${({ theme }) => theme.colors.border};
    background-color: #fff;

    &::after {
        content: "";
        position: absolute;
        display: none;
        left: 6px;
        top: 2px;
        transform: translate(-50%, -50%);
        width: 5px;
        height: 10px;
        border: solid ${({ theme }) => theme.colors.activeText};
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
`;

export const Wrapper = styled.div`
    & input {
        opacity: 0;
    }

    & input:checked~${Fake} {
        background-color: ${({ theme }) => theme.colors.active};
    }

    & input:checked~${Fake}::after {
        display: block;
    }
`;

export const Container = styled.label`
    display: flex;
    align-items: center;
    padding-left: 31px;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: ${({ theme }) => theme.colors.text};
    font-size: 14px;
    line-height: 1.57;

    ${({ disabled }) => disabled && `
        text-decoration: line-through;
        cursor: default;
    `}
`;
