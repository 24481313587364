import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';

import { GET_ML_SAMPLES_BY_MODEL, GET_SAMPLES_BY_MODEL_ID, ML_RESPONSE_STATUSES } from 'Constants';
import { Loader, NoData } from 'Components';

import * as Styled from '../../styled';

import { TrainingSet, FeatureView } from './components';

export const FeatureAnalysis = ({ model }) => {
    const [initialLoading, setInitialLoading] = useState(true);
    const [axisValues, setAxisValues] = useState({ xAxis: 'RMS', yAxis: 'RMS', zAxis: 'RMS' });

    const { data: mlSamplesData, loading: mlSamplesLoading } = useQuery(
        GET_ML_SAMPLES_BY_MODEL,
        {
            variables: {
                model: model._id,
                type: 1,
            },
        },
    );
    const mlSamples = mlSamplesData?.getMLSamplesByModelId ?? [];

    const { data: samplesData, loading: samplesLoading } = useQuery(
        GET_SAMPLES_BY_MODEL_ID,
        {
            variables: {
                modelId: model._id,
                type: 1,
            },
        },
    );
    const samples = samplesData?.getSamplesByModelId ?? [];

    useEffect(
        () => {
            if (initialLoading && !mlSamplesLoading && !samplesLoading) {
                setInitialLoading(false);
            }
        },
        [mlSamplesLoading, samplesLoading],
    );

    const mlSampleNames = useMemo(
        () => uniq(mlSamples.map((mlSample) => mlSample.label)),
        [mlSamples],
    );

    const preProcessingStatus = useMemo(() => JSON.parse(model.preProcessingStatus), [model]);

    const modelFeatures = useMemo(() => JSON.parse(model.features), [model]);

    const datasets = useMemo(
        () => {
            const result = [];

            if (isEmpty(modelFeatures)) {
                return result;
            }

            const xAxisFeature = modelFeatures.output.SP.labels_for_selected_features
                .findIndex((feature) => feature === axisValues.xAxis);
            const yAxisFeature = modelFeatures.output.SP.labels_for_selected_features
                .findIndex((feature) => feature === axisValues.yAxis);
            const zAxisFeature = modelFeatures.output.SP.labels_for_selected_features
                .findIndex((feature) => feature === axisValues.zAxis);

            mlSampleNames.forEach((mlSampleName) => {
                let data = [];

                const dataSets = modelFeatures.output.SP.samples.filter((sample) => sample.sample_name === mlSampleName);

                dataSets.forEach((item) => {
                    data = data.concat(item.data);
                });

                let x = [];
                let y = [];
                let z = [];

                data.forEach((d) => {
                    x = x.concat(d.features[xAxisFeature][0]);
                    y = y.concat(d.features[yAxisFeature][1]);
                    z = z.concat(d.features[zAxisFeature][2]);
                });
                result.push({ sample: mlSampleName, data: { x, y, z } });
            });

            return result;
        },
        [modelFeatures, mlSampleNames],
    );

    if (initialLoading) {
        return <Loader />;
    }

    if (!mlSamples.length) {
        return (
            <NoData
                caption="There are no samples yet"
                text="Generate your samples at Data Acquisition tab."
            />
        );
    }

    if (preProcessingStatus?.state !== ML_RESPONSE_STATUSES.SUCCESS) {
        return (
            <NoData
                caption="The model needs to be processed"
                text="The pre-processing can be done at processing tab"
            />
        );
    }

    return (
        <Styled.ModelTabWrapper>
            <Styled.ModelTabLeft>
                <TrainingSet
                    model={model}
                    mlSamples={mlSamples}
                    samples={samples}
                    datasets={datasets}
                    mlSampleNames={mlSampleNames}
                />
            </Styled.ModelTabLeft>
            <Styled.ModelTabRight>
                <FeatureView
                    modelFeatures={modelFeatures}
                    datasets={datasets}
                    axisValues={axisValues}
                    setAxisValues={setAxisValues}
                />
            </Styled.ModelTabRight>
        </Styled.ModelTabWrapper>
    );
};

FeatureAnalysis.propTypes = {
    model: PropTypes.object.isRequired,
};
