import { useMemo, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
    GET_NCE_SIM_CARD_DETAIL,
    ONCE_CONSUMPTION_CHART_TYPES,
    notAvailableStatus,
} from 'Constants';
import { getTimeDiff } from 'Utils';
import { Loader, onceSimConsumptionColumns, onceSimSessionColumns } from 'Components';

import { checkNullablePerformance, formatBytes } from './helpers';
import { SimCardWidgetsContainer } from '../widgetsContainer';

import * as Styled from './styled';

const simCardActiveMarker = 'Activated';

export const OnceSimDetails = ({ iccid }) => {
    const [initialLoading, setInitialLoading] = useState(true);

    const { data, loading } = useQuery(GET_NCE_SIM_CARD_DETAIL, { variables: { iccid } });

    const simCardDetails = data?.getNCESimDetails ?? {};

    useEffect(
        () => {
            if (initialLoading && !loading) {
                setInitialLoading(false);
            }
        },
        [loading],
    );

    const simCardStateActive = simCardDetails.status === simCardActiveMarker;
    const simCardUsageHistory = simCardDetails.simCardUsageHistory?.stats || [];
    const totalUsage = simCardUsageHistory.pop();

    const reports = useMemo(() => {
        if (!checkNullablePerformance(totalUsage)) {
            return simCardUsageHistory
                .filter((record) => !checkNullablePerformance(record))
                .map((record, index) => ({
                    _id: index,
                    date: moment(new Date(record.date)).format('D MMM YYYY'),
                    dataUsageTrafficType: record.data?.traffic_type?.description || notAvailableStatus,
                    dataUsageRx: formatBytes(parseInt(record.data?.volume_rx, 10), 1048576) || notAvailableStatus,
                    dataUsageTx: formatBytes(parseInt(record.data?.volume_tx, 10), 1048576) || notAvailableStatus,
                    totalDataUsage: formatBytes(parseInt(record.data?.volume, 10), 1048576) || notAvailableStatus,
                }));
        }

        return [];
    }, [totalUsage]);

    const processedGraphData = useMemo(() => {
        if (!checkNullablePerformance(totalUsage)) {
            const reportArray = simCardUsageHistory
                .filter((record) => !checkNullablePerformance(record))
                .map((record, index) => ({
                    _id: index,
                    date: new Date(record.date),
                    dataUsageRx: parseInt(record.data?.volume_rx, 10),
                    dataUsageTx: parseInt(record.data?.volume_tx, 10),
                    totalDataUsage: parseInt(record.data?.volume, 10),
                }))
                .sort((a, b) => a.date - b.date);

            if (!moment(new Date(reportArray[reportArray.length - 1]).date).isSame(new Date(), 'day')) {
                reportArray.push({
                    _id: '_1',
                    date: new Date(),
                    dataUsageRx: 0,
                    dataUsageTx: 0,
                    totalDataUsage: 0,
                });
            }
            return reportArray;
        }

        return [];
    }, [totalUsage]);

    const simInfo = useMemo(() => ([
        {
            label: 'SIM ID',
            value: simCardDetails.iccid,
        },
        {
            label: 'IMEI',
            value: simCardDetails.imei || notAvailableStatus,
        },
        {
            label: 'IMSI',
            value: simCardDetails.imsi || notAvailableStatus,
        },
        {
            label: 'Last Detected IP',
            value: simCardDetails.ip_address || notAvailableStatus,
        },
        {
            label: 'Current Status',
            value: simCardDetails.currentStatus?.status || notAvailableStatus,
        },
        {
            label: 'Activation Date',
            value: moment(new Date(simCardDetails.activation_date)).format('D MMM YYYY - H:mm a'),
        },
        {
            label: 'End Date',
            value: moment(new Date(simCardDetails.quotaDetails?.expiry_date)).format('D MMM YYYY - H:mm a'),
        },
        {
            label: 'Country',
            value: simCardDetails.currentStatus?.location || notAvailableStatus,
        },
        {
            label: 'Quota',
            value: `${simCardDetails.current_quota ? `${simCardDetails.current_quota} MB` : notAvailableStatus}`,
        },
        {
            label: 'Left',
            value: `${simCardDetails.quotaDetails?.volume ? `${simCardDetails.quotaDetails.volume} MB` : notAvailableStatus}`,
        },
    ]), [simCardDetails]);

    const sessions = [{
        _id: 1,
        operator: simCardDetails.currentStatus?.operator || notAvailableStatus,
        location: simCardDetails.currentStatus?.location || notAvailableStatus,
        technology: simCardDetails.currentStatus?.technology || notAvailableStatus,
        created: getTimeDiff(new Date(simCardDetails.currentStatus?.pdp_context?.created)) || notAvailableStatus,
        duration: simCardDetails.currentStatus?.pdp_context?.duration || notAvailableStatus,
        status: (
            <Styled.Badge $active={simCardDetails.currentStatus?.status === 'Online'}>
                {simCardDetails.currentStatus?.status}
            </Styled.Badge>
        ),
    }];

    const tableData = simCardDetails.currentStatus && Object.keys(simCardDetails.currentStatus).length ? sessions : [];
    const sessionInfo = { columns: onceSimSessionColumns, tableData };

    const geoData = {
        lat: parseFloat(simCardDetails.currentStatus?.latitude),
        lng: parseFloat(simCardDetails.currentStatus?.longitude),
    };

    const tickFormatter = (value) => formatBytes(value, 1048576);

    const simReportsInfo = {
        columns: onceSimConsumptionColumns,
        reports,
        total: formatBytes(totalUsage?.data?.volume, 1048576),
    };
    const consumptionChartInfo = { types: ONCE_CONSUMPTION_CHART_TYPES, graphData: processedGraphData, tickFormatter };

    if (initialLoading) {
        return <Loader />;
    }

    return (
        <SimCardWidgetsContainer
            iccid={iccid}
            simCardStateActive={simCardStateActive}
            simInfo={simInfo}
            sessionInfo={sessionInfo}
            geoData={geoData}
            simReportsInfo={simReportsInfo}
            consumptionChartInfo={consumptionChartInfo}
        />
    );
};

OnceSimDetails.propTypes = {
    iccid: PropTypes.string.isRequired,
};
