import { useMemo, useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import { GET_ETHERNETS, DELETE_ETHERNET_MUTATION, DEFAULT_ETHERNET_MUTATION } from 'Constants';
import { Table, Modal, DeleteModal, EthernetModal, IconButton, Loader } from 'Components';
import { showToastSuccess, showToastError } from 'Utils';
import { ReactComponent as EthernetIcon } from 'Assets/icons/net-plus.svg';

import { EmptySettings } from '../emptySettings';
import { getColumns } from './columns';

import * as Styled from './styled';

export const EthernetSettings = () => {
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [detailsModalOpen, setDetailsModalOpen] = useState(false);

    const [selectedEthernetId, setSelectedEthernetId] = useState(null);
    const [initialLoading, setInitialLoading] = useState(true);

    // const { data: arrayOfEthernet, refetch: refetchEthernet } = useQuery(GET_ETHERNETS);
    const { data: arrayOfEthernet, loading } = useQuery(GET_ETHERNETS);
    const ethernet = useMemo(
        () => arrayOfEthernet?.getAllEthernet ?? [],
        [arrayOfEthernet],
    );

    useEffect(
        () => {
            if (initialLoading && !loading) {
                setInitialLoading(false);
            }
        },
        [loading],
    );

    const [removeEthernet, { loading: isLoading }] = useMutation(DELETE_ETHERNET_MUTATION, {
        refetchQueries: [GET_ETHERNETS],
        awaitRefetchQueries: true,
    });

    const [setDefaultEthernet] = useMutation(DEFAULT_ETHERNET_MUTATION, {
        refetchQueries: [GET_ETHERNETS],
        awaitRefetchQueries: true,
    });

    const editEthernetHandler = (id) => {
        setSelectedEthernetId(id);
        setDetailsModalOpen(true);
    };

    const deleteEthernetHandler = (id) => {
        setSelectedEthernetId(id);
        setDeleteModalOpen(true);
    };

    const openCleanDetailsModal = () => {
        setSelectedEthernetId(null);
        setDetailsModalOpen(true);
    };

    const setDefaultEthernetHandler = async (id) => {
        try {
            await setDefaultEthernet({
                variables: {
                    ethernetId: id,
                },
                update: () => {
                    showToastSuccess('Ethernet set as default');
                    setSelectedEthernetId(null);
                },
            });
        } catch (error) {
            showToastError(error.message);
        }
    };

    const columns = useMemo(
        () => getColumns({
            editEthernet: editEthernetHandler,
            deleteEthernet: deleteEthernetHandler,
            makeDefaultEthernet: setDefaultEthernetHandler,
        }),
        [arrayOfEthernet],
    );

    const deleteEthernet = async () => {
        try {
            await removeEthernet({
                variables: {
                    ethernetId: selectedEthernetId,
                },
                update: () => {
                    showToastSuccess('Ethernet settings deleted successfully');
                    setSelectedEthernetId(null);
                    setDeleteModalOpen(false);
                },
            });
        } catch (error) {
            showToastError(error.message);
        }
    };

    // ********************************
    // ** WAITING FOR BACKEND PAGING **
    // ********************************
    //
    // const cursorPagination = useMemo(
    //     () => arrayOfEthernet?.getAllEthernet?.pageInfo ?? {},
    //     [arrayOfEthernet],
    // );

    // const paginationHandler = ({ cursors, items }) => {
    //     refetchEthernet({ paging: { items, cursors } });
    // };

    if (initialLoading) {
        return <Loader />;
    }

    if (!ethernet.length) {
        return (
            <Styled.Wrapper>
                <EmptySettings title="Ethernet" />
            </Styled.Wrapper>
        );
    }

    return (
        <Styled.Wrapper data-cy="ethernet-table">
            <Styled.TableHeader>
                <IconButton
                    onClick={openCleanDetailsModal}
                    Icon={EthernetIcon}
                    cyData="button-add-ethernet"
                >
                    Add Ethernet Network
                </IconButton>
            </Styled.TableHeader>
            <Table
                data={ethernet}
                columns={columns}
                rowKey="ethernetId"
                // pagination={cursorPagination}
                // paginationHandler={paginationHandler}
            />
            <Modal
                handleClose={() => setDetailsModalOpen(false)}
                isOpened={detailsModalOpen}
            >
                <EthernetModal
                    handleClose={() => setDetailsModalOpen(false)}
                    id={selectedEthernetId}
                />
            </Modal>
            <Modal
                handleClose={() => setDeleteModalOpen(false)}
                isOpened={deleteModalOpen}
            >
                <DeleteModal
                    title="Delete Ethernet settings"
                    description="Are you sure you want to delete this Ethernet settings?"
                    deleteAction={deleteEthernet}
                    cancelAction={() => setDeleteModalOpen(false)}
                    isLoading={isLoading}
                />
            </Modal>
        </Styled.Wrapper>
    );
};
