import * as Yup from 'yup';

import { AWS_REGIONS } from 'Constants';
import { ValidationService } from 'Services';

export const regionOptions = AWS_REGIONS.map((region) => ({ value: region.code, label: region.name }));

export const initialValues = {
    region: '',
    accessKey: '',
    secretAccessKey: '',
    saveLocally: false,
};

export const validationSchema = Yup.object().shape({
    region: Yup.string().required(ValidationService.getRequiredError('region')),
    accessKey: Yup.string()
        .matches(/(^|[^A-Z0-9])[A-Z0-9]{20}(?![A-Z0-9])/i, 'Access Key ID invalid.')
        .required(ValidationService.getRequiredError('access key ID')),
    secretAccessKey: Yup.string()
        .matches(/(^|[^A-Za-z0-9/+=])[A-Za-z0-9/+=]{40}(?![A-Za-z0-9/+=])/i, 'Secret Access Key invalid')
        .required(ValidationService.getRequiredError('secret access key')),
});
