import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { GET_DEVICES_QUERY, GET_PYMESH_BY_APPLICATION_ID } from 'Constants';
import { Loader } from 'Components';

import { CreatePymesh } from './createPymesh';
import { PymeshTable } from './pymeshTable';
import * as Styled from './styled';

export const Pymesh = ({ applicationId }) => {
    const [initialLoading, setInitialLoading] = useState(true);

    const { data, loading } = useQuery(GET_PYMESH_BY_APPLICATION_ID, {
        variables: { applicationId },
        fetchPolicy: 'cache-and-network',
    });

    const pymesh = data?.getPymeshByApplicationId;

    const { data: devicesData, loading: getDevicesLoading } = useQuery(GET_DEVICES_QUERY, {
        variables: { applicationId, paging: { items: 10, cursors: {} } },
        fetchPolicy: 'cache-and-network',
    });

    const edges = devicesData?.getDevices?.edges ?? [];

    useEffect(
        () => {
            if (initialLoading && !loading && !getDevicesLoading) {
                setInitialLoading(false);
            }
        },
        [loading, getDevicesLoading],
    );

    const notFoundDisplay = !getDevicesLoading && !edges.length;
    const createPymeshDisplay = !loading && !pymesh?._id;

    if (initialLoading) {
        return <Loader />;
    }

    return (
        <Styled.Wrapper>
            {
                createPymeshDisplay || notFoundDisplay
                    ? <CreatePymesh isEmpty={notFoundDisplay} applicationId={applicationId} />
                    : (
                        <PymeshTable
                            deviceEdges={edges}
                            isLoading={getDevicesLoading || loading}
                            pymeshDevices={pymesh.devices}
                            pymeshID={pymesh._id}
                        />
                    )
            }
        </Styled.Wrapper>
    );
};

Pymesh.propTypes = {
    applicationId: PropTypes.string.isRequired,
};
