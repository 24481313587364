import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useMutation } from '@apollo/client';

import {
    TOGGLE_DEVICE_GATEWAY,
    UPDATE_DEVICE_GATEWAY,
    MANAGE_LORA_GATEWAY,
    DEPLOY_DEVICE_GATEWAY,
    PYGATE,
} from 'Constants';
import { showToastSuccess, showToastError } from 'Utils';

import { ConfigFileModal } from './configFileModal';
import { CreateChirpstackProfileNotificationModal } from './createChirpstackProfileNotificationModal';

import { initialValues, getGatewayConfigFile } from './config';
import { FormContent } from './formContent';

export const DeviceExpansionBoard = ({ device }) => {
    const shieldType = device.shield?.type;
    const deviceToken = device.token;
    const deviceId = device._id;
    const gatewayActive = device.shield?.gateway?.active;
    const isShieldPygate = shieldType === PYGATE;

    const [isGetawayActive, setIsGetawayActive] = useState(Boolean(gatewayActive));
    const [loraSettings, setLoraSettings] = useState({
        loraServer: device.shield?.gateway?.loraProvider,
        loraRegion: device.shield?.gateway?.loraRegion,
    });
    const [gatewayId, setGatewayId] = useState(device.shield?.gateway?.gatewayId);

    const [isShowGatewayConfigModal, setIsShowGatewayConfigModal] = useState(false);
    const [isShowCreateProfileModal, setShowCreateProfileModal] = useState(false);

    const [toggleGateway, { loading: isLoadingToggle }] = useMutation(TOGGLE_DEVICE_GATEWAY);
    const [updateGateway, { loading: isLoadingUpdate }] = useMutation(UPDATE_DEVICE_GATEWAY);
    const [manageLoraGateway, { loading: isLoadingManageLoraGateway }] = useMutation(MANAGE_LORA_GATEWAY);
    const [deployGateway, { loading: isLoadingDeployGateway }] = useMutation(DEPLOY_DEVICE_GATEWAY);

    const combinedInitialValues = useMemo(() => ({
        expansionBoard: shieldType || initialValues.expansionBoard,
        gatewayId: gatewayId || initialValues.gatewayId,
        loraSettings,
    }), [loraSettings, gatewayId, shieldType]);

    const configurationFile = useMemo(() => (
        JSON.stringify(getGatewayConfigFile(loraSettings.loraRegion, loraSettings.loraServer, gatewayId), null, 2)
    ), [loraSettings, gatewayId]);

    const toggleGatewayActivity = async (isActive) => {
        if (!gatewayId) {
            return;
        }

        try {
            // update gateway's activity in mqtt
            await toggleGateway({
                variables: {
                    deviceToken,
                    status: isActive,
                },
            });

            // update the gateway's activity in DB
            await updateGateway({
                variables: {
                    deviceId,
                    gateway: {
                        active: isActive,
                    },
                },
            });

            setIsGetawayActive(isActive);
            showToastSuccess(`Gateway turned ${isActive ? 'on' : 'off'} successfully`);
        } catch (error) {
            showToastError(error.message);
        }
    };

    const onLoraSettingsChange = (values) => {
        setLoraSettings({
            loraServer: values.loraServer,
            loraRegion: values.loraRegion,
        });
    };

    const onSubmit = async (values) => {
        try {
            // Create a gateway in LoRa server and update it in Pycom's DB
            const { data: { manageLoraGateway: loraGateway } } = await manageLoraGateway({
                variables: {
                    gateway: {
                        deviceId,
                        loraProvider: values.loraSettings.loraServer,
                        loraRegion: values.loraSettings.loraRegion,
                    },
                },
            });

            // Deploy getaway
            await deployGateway({
                variables: {
                    deviceToken,
                },
            });

            // update the gateway's activity in DB
            const { data: { updateGateway: gatewayActivity } } = await updateGateway({
                variables: {
                    deviceId,
                    gateway: {
                        active: true,
                    },
                },
            });

            if (loraGateway) {
                setGatewayId(loraGateway.gatewayId);
                setShowCreateProfileModal(loraGateway.isProfileCreated);
            }

            setIsGetawayActive(gatewayActivity);

            showToastSuccess('Gateway updated and deployed successfully');
        } catch (error) {
            showToastError(error.message);
        }
    };

    return (
        <>
            <Formik
                initialValues={combinedInitialValues}
                enableReinitialize
            >
                {(props) => (
                    <FormContent
                        {...props}
                        isLoading={isLoadingManageLoraGateway && isLoadingDeployGateway && isLoadingToggle}
                        isGatewayActive={isGetawayActive}
                        toggleGatewayActivity={toggleGatewayActivity}
                        isToggleGatewayLoading={isLoadingToggle && isLoadingUpdate}
                        showGatewayConfigFile={() => setIsShowGatewayConfigModal(true)}
                        onLoraSettingsChange={onLoraSettingsChange}
                        onDeploy={onSubmit}
                        isShieldPygate={isShieldPygate}
                    />
                )}
            </Formik>
            <ConfigFileModal
                isShowModal={isShowGatewayConfigModal}
                setIsShowModal={setIsShowGatewayConfigModal}
                configurationFile={configurationFile}
            />
            <CreateChirpstackProfileNotificationModal
                isShowModal={isShowCreateProfileModal}
                setIsShowModal={setShowCreateProfileModal}
            />
        </>
    );
};

DeviceExpansionBoard.propTypes = {
    device: PropTypes.object.isRequired,
};
