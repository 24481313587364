import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
    orderNumber: Yup.number().typeError('Must be a number')
        .required('order number is required')
        .test('len', 'Must be exactly 6 characters', (val) => val?.toString().length === 6),
});

export const initialValues = {
    orderNumber: '',
};
