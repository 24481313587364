import styled from 'styled-components';

export const Box = styled.div`
    display: inline-block;
    padding: 11px 15px 12px;
    position: relative;
    background-color: ${({ theme }) => theme.colors.boxBackground};
    color: ${({ theme }) => theme.colors.title};
    font-size: 22px;
    font-weight: bold;
    
    & div:first-child {
        width: 5px;
        height: 20px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        background-color: ${({ theme }) => theme.colors.active};
    }
`;
