export const confirmEmail = async (token, reCaptchaToken) => {
    const headers = new Headers();
    headers.set('recaptcha-response', reCaptchaToken);
    headers.set('token', token);

    const response = await fetch(`${process.env.REACT_APP_API_URL}/account/confirm-email`, {
        method: 'POST',
        headers,
        credentials: 'include',
    });

    if (response.ok) {
        const res = await response.json();
        return res;
    }

    return { errorMessage: await response.json() };
};

export const resendEmail = async (token, reCaptchaToken) => {
    const headers = new Headers();
    headers.set('recaptcha-response', reCaptchaToken);
    headers.set('token', token);

    const response = await fetch(`${process.env.REACT_APP_API_URL}/account/resend-confirmation`, {
        method: 'POST',
        headers,
        credentials: 'include',
    });

    if (response.ok) {
        const res = await response.json();
        return res;
    }

    return { errorMessage: await response.json() };
};

export const resendVerificationEmail = async (email, reCaptchaToken) => {
    const headers = new Headers();
    headers.set('recaptcha-response', reCaptchaToken);
    headers.set('Content-type', 'application/json');

    const response = await fetch(`${process.env.REACT_APP_API_URL}/account/resend-verification-mail`, {
        method: 'POST',
        headers,
        credentials: 'include',
        body: JSON.stringify({
            email,
        }),
    });

    if (response.ok) {
        const res = await response.json();
        return res;
    }

    return { errorMessage: await response.json() };
};
