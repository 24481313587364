/* eslint-disable no-param-reassign */
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';

export const renameStoredKey = (oldKey, newKey) => {
    const data = localStorage.getItem(oldKey) || '';
    localStorage.removeItem(oldKey);
    localStorage.setItem(newKey, data);
};

export const calculateStringSize = (string) => {
    const size = string ? string.length / (1024 * 1024) : 0; //MB
    return size;
};

export const calculateLocalStorageSize = (condition = null) => {
    let storedData = '';
    Object.keys(localStorage).forEach((key) => {
        if (!condition || key.startsWith(condition)) {
            storedData += localStorage[key];
        }
    });

    return calculateStringSize(storedData);
};

export const clearLocalStorageByCondition = (condition) => {
    Object.keys(localStorage).forEach((key) => {
        if (key.startsWith(condition)) {
            localStorage.removeItem(key);
        }
    });
};

export const updateKeys = (dir, path, key, updateTabs) => {
    dir.forEach((item) => {
        const initialItem = cloneDeep(item);
        item.path = `${path}/${item.name}`;
        item.parent = path;

        if (item.key) {
            const newKey = `${key}-${path}/${item.name}`;
            renameStoredKey(item.key, newKey);
            item.key = newKey;
        }

        if (item.files && item.files.length) {
            updateKeys(item.files, item.path, key, updateTabs);
        }

        if (item.type === 'file') {
            updateTabs(initialItem, item);
        }
    });
};

export const deleteKeysAndTabs = (dir, openedTabs) => {
    let tabs = cloneDeep(openedTabs);

    dir.forEach((dirItem) => {
        localStorage.removeItem(dirItem.key);
        const targetTabIndex = tabs.findIndex((item) => item.key === dirItem.key);

        if (targetTabIndex >= 0) {
            tabs.splice(targetTabIndex, 1);
        }

        if (dirItem.files?.length) {
            tabs = deleteKeysAndTabs(dirItem.files, tabs);
        }
    });

    return tabs;
};

export const removeKeys = (dir, activeTabKey = null, setRefresh = null) => {
    dir.forEach((item) => {
        if (item.key) {
            if (activeTabKey && activeTabKey === item.key) {
                setRefresh();
            }

            localStorage.removeItem(item.key);
        }

        if (item.files && item.files.length) {
            removeKeys(item.files, activeTabKey, setRefresh);
        }
    });
};

export const clearExpiredData = (user, activeTabKey, setRefresh) => {
    Object.keys(localStorage).forEach((key) => {
        if (key.startsWith(user)) {
            try {
                const storedData = JSON.parse(localStorage.getItem(key));
                if (storedData?.expires && moment(storedData.expires).isBefore(moment())) {
                    removeKeys(storedData.hierarchy, activeTabKey, setRefresh);
                }
            } catch {
                // empty catch to skip JSON parsing errors on strings;
            }
        }
    });
};

export const getStoredMeta = (projectKey) => {
    const data = localStorage.getItem(`${projectKey}-meta`);
    if (data) {
        return JSON.parse(data);
    }
    return null;
};

export const setStoredMeta = (projectKey, data) => {
    const currMeta = getStoredMeta(projectKey);
    localStorage.setItem(`${projectKey}-meta`, JSON.stringify({ ...currMeta, ...data }));
};
