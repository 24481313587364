import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import { Input } from 'antd';

import { ReactComponent as EyeClosedIcon } from 'Assets/icons/eye-closed.svg';
import { ReactComponent as EyeOpenedIcon } from 'Assets/icons/eye-open.svg';

import * as Styled from './styled';

export const AuthInput = ({ label, type, SuffixIcon, showPassEye, ...rest }) => {
    const [field, meta] = useField(rest);

    const [showPassword, setShowPassword] = useState(false);

    const showError = meta.touched && !!meta.error;

    const localType = useMemo(() => (showPassword ? 'text' : type), [showPassword, type]);

    const renderedSuffixIcon = useMemo(
        () => {
            if (showPassEye && !showPassword) {
                return <EyeClosedIcon onClick={() => setShowPassword(true)} />;
            }

            if (showPassEye && showPassword) {
                return <EyeOpenedIcon onClick={() => setShowPassword(false)} />;
            }

            return <SuffixIcon />;
        },
        [showPassword, type],
    );

    return (
        <Styled.FieldWrapper
            $interactiveSuffix={showPassEye}
            $error={showError}
        >
            <Input
                {...field}
                type={localType}
                bordered={false}
                suffix={renderedSuffixIcon}
            />
            {label && (
                <Styled.Label
                    $scaled={!!field.value}
                >
                    {label}
                </Styled.Label>
            )}
            {showError && (
                <Styled.Error>
                    {meta.error}
                </Styled.Error>
            )}
        </Styled.FieldWrapper>
    );
};

AuthInput.propTypes = {
    label: PropTypes.string,
    type: PropTypes.string,
    SuffixIcon: PropTypes.elementType,
    showPassEye: PropTypes.bool,
};

AuthInput.defaultProps = {
    label: '',
    type: 'text',
    SuffixIcon: () => null,
    showPassEye: false,
};
