import { COMPILATION_ACTION_TYPES } from './actionTypes';

export const compilationInitialState = {
    compilationId: null,
    compilationStatus: null,
    compilationLogs: [],
    compilationFile: null,
};

export const compilationReducer = (state, action) => {
    switch (action.type) {
        case COMPILATION_ACTION_TYPES.SET_COMPILATION_ID:
            return {
                ...state,
                compilationId: action.payload,
            };
        case COMPILATION_ACTION_TYPES.SET_STATUS:
            return {
                ...state,
                compilationStatus: action.payload,
            };
        case COMPILATION_ACTION_TYPES.SET_COMPILATION_FILE: 
            return {
                ...state,
                compilationFile: action.payload,
            };
        case COMPILATION_ACTION_TYPES.SET_LOGS:
            return {
                ...state,
                compilationLogs: action.payload
                    .split(/\n/)
                    .filter(
                        (item, index, array) => index === array.indexOf(item),
                    ),
            };
        case COMPILATION_ACTION_TYPES.SET_COMPILATTION_INIT_STATE:
            return compilationInitialState;
        default:
            return state;
    }
};
