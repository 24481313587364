import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ReactComponent as PlusIcon } from 'Assets/icons/plus.svg';

import * as Styled from './styled';

export const RedirectView = ({ title, subtitle, route, icon }) => (
    <>
        <Styled.Title>
            {title}
        </Styled.Title>
        <Styled.CreateItem>
            <Link
                to={route}
            >
                <Styled.Icon>
                    {icon}
                    <Styled.NewButton>
                        <PlusIcon />
                    </Styled.NewButton>
                </Styled.Icon>
                <h2>
                    {subtitle}
                </h2>
            </Link>
        </Styled.CreateItem>
    </>
);

RedirectView.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
    icon: PropTypes.element.isRequired,
};
