import PropTypes from 'prop-types';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { useAuthenticate } from 'Hooks';

import { Logo } from '../components';

import * as Styled from './styled';

export const AuthLayout = ({ children }) => {
    const { loader, isLoading } = useAuthenticate({ reverseRedirect: true });

    if (isLoading) {
        return loader;
    }

    const isEnabledRecaptcha = process.env.REACT_APP_PYCOM_ENABLE_RECAPTCHA === 'true';

    return (
        <Styled.Wrapper>
            <Styled.Header>
                <Logo />
            </Styled.Header>
            <Styled.ContentWrapper>
                <Styled.Content>
                    {
                        isEnabledRecaptcha ? (
                            <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_PYCOM_RECAPTCHA_KEY}>
                                {children}
                            </GoogleReCaptchaProvider>
                        ) : children
                    }
                </Styled.Content>
            </Styled.ContentWrapper>
        </Styled.Wrapper>
    );
};

AuthLayout.propTypes = {
    children: PropTypes.node,
};

AuthLayout.defaultProps = {
    children: null,
};
