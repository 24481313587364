/* eslint-disable no-bitwise */
import mqtt from 'mqtt';

import {
    COMMAND_CUSTOM_METHOD,
    TERMINAL_PIN,
    TYPE_PYBYTES,
    TYPE_PING,
    TYPE_MASK,
} from 'Constants';

export const mqttService = (processOption) => {
    const { deviceToken, userName, mqttResponseCallback, pingCallback } = processOption;

    const clientOptions = {
        username: userName,
        password: deviceToken,
        resubscribe: false,
    };

    const client = mqtt.connect(process.env.REACT_APP_MQTT_SERVER_AEDES_WEB_SOCKET_URL, clientOptions);

    client.on('connect', () => {
        client.subscribe(`u${deviceToken}`);
    });

    client.on('message', (topic, message) => {
        const messageType = message[0] & TYPE_MASK;
        if (messageType === TYPE_PYBYTES && message[1] === COMMAND_CUSTOM_METHOD && message[2] === TERMINAL_PIN) {
            mqttResponseCallback(message.toString().slice(3));
        }
        if (messageType === TYPE_PING) {
            pingCallback();
        }
    });

    client.on('error', (error) => {
        console.error(error);
    });

    const sendMessage = (messageType, receivedBody = '') => {
        let body = receivedBody;
        if (messageType === TYPE_PYBYTES) {
            body = new Uint8Array(2 + body.length);
            body[0] = COMMAND_CUSTOM_METHOD;
            body[1] = TERMINAL_PIN;

            for (let i = 0; i < receivedBody.length; i += 1) {
                body[i + 2] = receivedBody.charCodeAt(i);
            }
        }

        const messageLength = 1 + body.length;
        const messagePayload = new Uint8Array(messageLength);

        let header = 0;
        header |= (messageType & TYPE_MASK);
        messagePayload[0] = header;

        for (let i = 0; i < body.length; i++) {
            messagePayload[i + 1] = body[i];
        }

        client.publish(`d${deviceToken}`, Buffer.from(messagePayload), { qos: 0 }, (err) => {
            if (err) {
                console.warn(`MQTT_SERVICE_ERROR: ${err.message}`);
            }
        });
    };

    const ping = () => sendMessage(TYPE_PING);
    const closeMqttClient = () => client.end(true);

    return {
        closeMqttClient,
        ping,
        sendMessage,
    };
};
