import styled from 'styled-components';

export const TableBody = styled.div`
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
`;

export const HeaderBlock = styled.div`
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #ECECEB;
    text-transform: uppercase;
    color: #403F4C;

    span {
        font-size: 12px;
        line-height: 15px;
        padding-bottom: 15px;
        margin: 0 15px;
        width: 20%;
    }

    span:first-child {
        margin: 0;
    }
`;

export const TableRow = styled.div`
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #F9F9F7;
    padding: 20px 0;
    color: #828282;

    span:first-child {
        font-size: 12px;
        line-height: 15px;
        margin: 0;
    }

    span {
        font-size: 14px;
        line-height: 17px;
        margin: 0 15px;
        width: 20%;
    }

    &:last-child {
        border-bottom: none;
    }
`;
