import { EDITOR_ACTION_TYPES } from './actionTypes';

export const setRefreshEditor = (refresh) => ({
    type: EDITOR_ACTION_TYPES.SET_REFRESH_EDITOR,
    payload: { refresh },
});

export const setRefreshStorage = (refresh) => ({
    type: EDITOR_ACTION_TYPES.SET_REFRESH_STORAGE,
    payload: { refresh },
});
