import { Formik } from 'formik';
import PropTypes from 'prop-types';

import { Alert } from 'Components';
import { SAMPLING_TYPES } from 'Constants';

import * as Styled from '../../styled';

import { FormContent } from './formContent';
import { useFormConfig, useSubmitText, useDevicePing, useSampling } from './hooks';

export const Sampling = ({ model, devices, samplingType, refetchMlSamples }) => {
    const { startSampling, isSamplingTimeout, isSampling } = useSampling({
        devices,
        model,
        samplingType,
        refetchMlSamples,
    });

    const { pingDevice, isDeviceTimeout, isDevicePinging } = useDevicePing(startSampling);

    const { initialValues, validationSchema } = useFormConfig(samplingType, devices, model);

    const submitText = useSubmitText(samplingType, isDevicePinging, isSampling);

    const formLoading = isDevicePinging || isSampling;

    const onSubmit = (values) => {
        pingDevice(values);
    };

    return (
        <>
            <Formik
                onSubmit={onSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnMount
            >
                {(props) => (
                    <FormContent
                        {...props}
                        devices={devices}
                        samplingType={samplingType}
                        submitText={submitText}
                        loading={formLoading}
                        disabled={formLoading}
                    />
                )}
            </Formik>
            {isSamplingTimeout && (
                <Styled.FormAlertSection>
                    <Alert
                        type="error"
                        message="Something went wrong during the sampling. Please restart your device and try again"
                    />
                </Styled.FormAlertSection>
            )}
            {isDeviceTimeout && (
                <Styled.FormAlertSection>
                    <Alert
                        type="error"
                        message="The device did not respond"
                    />
                </Styled.FormAlertSection>
            )}
        </>
    );
};

Sampling.propTypes = {
    model: PropTypes.object.isRequired,
    devices: PropTypes.arrayOf(PropTypes.shape({
        description: PropTypes.string,
        token: PropTypes.string,
    })),
    refetchMlSamples: PropTypes.func.isRequired,
    samplingType: PropTypes.number,
};

Sampling.defaultProps = {
    devices: [],
    samplingType: SAMPLING_TYPES.TRAINING,
};
