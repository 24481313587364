export const getColumns = () => [
        {
            title: 'ICCID',
            dataIndex: 'iccid',
            render: (value, originItem) => {
                if (originItem.autoSettings) {
                    return ' ';
                }
                return value;
            },
        },
        {
            title: 'IMSI',
            dataIndex: 'imsi',
            render: (value, originItem) => {
                if (originItem.autoSettings) {
                    return 'Automatically';
                }
                return value;
            },
        },
        {
            title: 'PROVIDER',
            dataIndex: 'provider',
            render: (value, originItem) => {
                if (originItem.autoSettings) {
                    return ' ';
                }
                return value;
            },
        },
        {
            title: 'STATE',
            dataIndex: 'state',
            render: (value, originItem) => {
                if (originItem.autoSettings) {
                    return ' ';
                }
                return value;
            },
        },
    ];
