export const deviceTypeMapper = {
    l01: 'lopy',
    g01: 'gpy',
    w01: 'wipy',
};

export const typeValues = {
    default: 'default',
    custom: 'custom',
};

export const typeOptions = [
    {
        value: typeValues.default,
        label: 'Default',
    },
    {
        value: typeValues.custom,
        label: 'Custom',
    },
];

export const initialValues = {
    changeFirmware: false,
};
