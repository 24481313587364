import styled, { css } from 'styled-components';

const leftAlignStyles = css`
    text-align: left;
`;

export const UnloggedWrapper = styled.div`
    text-align: center;
    max-width: 500px;
    margin-top: 20px;

    ${({ $isAlignedLeft }) => $isAlignedLeft && leftAlignStyles}
`;

export const LoggedWrapper = styled.div`
    max-width: 500px;
    text-align: right;

    ${({ $isAlignedLeft }) => $isAlignedLeft && leftAlignStyles}
`;

export const LogoutButtonWrapper = styled.div`
    margin-top: 20px;
`;
