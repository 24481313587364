import { Formik } from 'formik';
import PropTypes from 'prop-types';

import { initialValues, validationSchema } from './config';
import { FormContent } from './formContent';

export const RegistrySetup = ({
    registries,
    setRegistries,
    googleService,
    prevStep,
    nextStep,
    formValues,
}) => {
    const combinedInitialValues = {
        registry: formValues.registryId || initialValues.registry,
        topic: formValues.topic || initialValues.topic,
    };

    const onSubmit = (values) => {
        const { registry, ...rest } = values;

        nextStep({ ...rest, registryId: registry });
    };

    const addRegistrySuccess = (registry) => {
        setRegistries([...registries, registry]);
    };

    return (
        <Formik
            initialValues={combinedInitialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {(props) => (
                <FormContent
                    {...props}
                    registries={registries}
                    googleService={googleService}
                    addRegistrySuccess={addRegistrySuccess}
                    prevStep={prevStep}
                />
            )}
        </Formik>
    );
};

RegistrySetup.propTypes = {
    googleService: PropTypes.object,
    setRegistries: PropTypes.func.isRequired,
    prevStep: PropTypes.func.isRequired,
    nextStep: PropTypes.func.isRequired,
    registries: PropTypes.array,
    formValues: PropTypes.object,
};

RegistrySetup.defaultProps = {
    googleService: {},
    registries: [],
    formValues: {},
};
