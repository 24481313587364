import styled, { keyframes, css } from 'styled-components';
import { pymakrConnectionStates, pymakrMarkStatus } from 'Constants';

const emissionAnimation = keyframes`
    from {
        opacity: 0;
        padding-right: 30px;
    }
    5% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    to {
        opacity: 0;
        padding-right: 0px;
    }
`;

const receptionAnimation = keyframes`
    from {
        opacity: 0;
        padding-right: 0px;
    }
    5% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    to {
        opacity: 0;
        padding-right: 30px;
    }
`;

const bulletAnimation = keyframes`
    from {
        opacity: 0;
        margin-left: 0px;
    }
    to {
        opacity: 1;
        margin-left: 116px;
    }
`;

const pingAnimation = keyframes`
    from {
        opacity: 1;
        stroke-width: 3;
        r: 6;
    }
    50% {
        opacity: 1;
    }
    to {
        opacity: 0;
        stroke-width: 0;
        r: 18;
    }
`;

const markShake = keyframes`
    from { transform: translateX(0px); }
    20% { transform: translateX(-6px); }
    40% { transform: translateX(5px); }
    60% { transform: translateX(-4px); }
    80% { transform: translateX(3px); }
    to { transform: translateX(0px); }
`;

const markBounce = keyframes`
    from { transform: scale(1, 1); }
    10% { transform: scale(1.2, 1.2); }
    20% { transform: scale(0.8, 0.8); }
    30% { transform: scale(1.16, 1.16); }
    50% { transform: scale(0.87, 0.87); }
    75% { transform: scale(1.1, 1.1); }
    to { transform: scale(1, 1); }
`;

const waitingMarkStyle = css`
    opacity: 0.5;
`;

const noResponseMarkStyle = css`
    svg {
        -webkit-animation: ${markShake} 0.5s ease-out 1;
        animation: ${markShake} 0.5s ease-out 1;
    }
`;

const activeMarkStyle = css`
    svg {
        -webkit-animation: ${markBounce} 0.8s ease-out 1;
        animation: ${markBounce} 0.8s ease-out 1;
    }
`;

const onlineConnectionStateStyling = css`
    color: rgb(0, 179, 0);
`;

const offlineConnectionStateStyling = css`
    color: ${({ theme }) => theme.colors.error};
`;

const onlineMarkStyling = css`
    svg {
        circle {
            fill: rgb(0, 179, 0) !important;
        }
    }
`;

const offlineMarkStyling = css`
    svg {
        circle {
            fill: ${({ theme }) => theme.colors.error} !important;
        }
    }
`;

export const Wrapper = styled.div`
    position: absolute;
    right: 0;
    margin-top: -30px;
    height: 44px;
    width: 200px;
`;

const emissionActiveStyle = css`
    -webkit-animation: ${emissionAnimation} 6s ease-out 1;
    animation: ${emissionAnimation} 6s ease-out 1;
`;

export const EmissionActivity = styled.div`
    white-space: nowrap;
    height: 22px;
    line-height: 22px;
    position: absolute;
    top: 2px;
    right: 100px;
    color: rgb(0, 179, 0);
    opacity: 0;
    text-align: center;

    ${({ $active }) => $active && emissionActiveStyle}
`;

const receptionActiveStyle = css`
    -webkit-animation: ${receptionAnimation} 6s ease-out 1;
    animation: ${receptionAnimation} 6s ease-out 1;
`;

export const ReceptionActivity = styled.div`
    white-space: nowrap;
    height: 22px;
    line-height: 22px;
    position: absolute;
    top: 18px;
    right: 100px;
    color: rgb(0, 179, 0);
    opacity: 0;
    text-align: center;

    ${({ $active }) => $active && receptionActiveStyle}
`;

const activeBullet = css`
    svg {
        -webkit-animation: ${bulletAnimation} 0.4s ease-in 1;
        animation: ${bulletAnimation} 0.4s ease-in 1;
    }
`;

export const Bullet = styled.div`
    position: absolute;
    top: 0;
    right: 58px;
    background: transparent;
    height: 44px;
    width: 160px;

    svg {
        position: absolute;
        height: 44px;
        width: 44px;
        top: 0;
        left: 0;
        opacity: 0;
        padding-left: 0;

        circle {
            fill: rgb(0, 179, 0);
            r: 4
        }
    }

    ${({ $active }) => $active && activeBullet}
`;

export const Mark = styled.div`
    background: transparent;
    position: absolute;
    top: 0;
    right: 58px;
    height: 44px;
    width: 44px;
    z-index: 1;
    padding: 0;
    overflow: hidden;
    opacity: 1;
    transition: opacity 200ms linear;

    svg {
        transform: scale(0.5, 0.5), translateX(0);
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 100%;

        circle {
            fill: ${({ theme }) => theme.colors.warning};
            transition: fill 200ms linear;
            r: 5
        }
    }

    ${({ $connectionState }) => {
        switch ($connectionState) {
            case pymakrConnectionStates.online:
                return onlineMarkStyling;
            case pymakrConnectionStates.offline:
                return offlineMarkStyling;
            default:
                return '';
        }
    }}

    ${({ $status }) => {
        switch ($status) {
            case pymakrMarkStatus.waiting:
                return waitingMarkStyle;
            case pymakrMarkStatus.noResponse:
                return noResponseMarkStyle;
            case pymakrMarkStatus.active:
                return activeMarkStyle;
            default:
                return '';
        }
    }}
`;

const pingWaveActive = css`
    svg {
        -webkit-animation: ${pingAnimation} 6s ease-out 1;
        animation: ${pingAnimation} 6s ease-out 1;
    }
`;

export const PingWave = styled.div`
    background: transparent;
    position: absolute;
    top: 0;
    right: 58px;
    height: 44px;
    width: 44px;
    padding: 0;
    overflow: hidden;

    svg {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;

        circle {
            opacity: 0;
            stroke-width: 0;
            stroke: rgb(0, 179, 0);
            r: 9;
        }
    }

    ${({ $active }) => $active && pingWaveActive}
`;

export const ConnectionState = styled.div`
    width: 62px;
    height: 44px;
    line-height: 43px;
    position: absolute;
    top: 0;
    right: 10px;
    color: ${({ theme }) => theme.colors.warning};
    text-align: center;

    ${({ $connectionState }) => {
        switch ($connectionState) {
            case pymakrConnectionStates.online:
                return onlineConnectionStateStyling;
            case pymakrConnectionStates.offline:
                return offlineConnectionStateStyling;
            default:
                return '';
        }
    }}
`;
