import * as Yup from 'yup';

export const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    termsOfService: false,
};

export const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('This field is required'),
    lastName: Yup.string().required('This field is required'),
    email: Yup.string()
        .email('Please provide an email')
        .required('This field is required'),
    password: Yup
        .string()
        .matches(
            /(?=.*[a-z])(?=.*[A-Z])(?=.*[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~])(?=.*[0-9])(?=.{8,})/,
            'Please enter a valid password',
        )
        .required('This field is required'),
    termsOfService: Yup.bool().required('You must agree to our Terms of Service to continue'),
});
