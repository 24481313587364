import styled from 'styled-components';

export const Badge = styled.div`
    width: 40%;
    padding: 5px 0;
    text-align: center;
    border-radius: 10px;
    color: #fff;
    background-color: ${({ theme }) => theme.colors.error};

    ${({ $active, theme }) => $active && `
        background-color: ${theme.colors.active};
    `}
`;
