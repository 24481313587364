import styled from 'styled-components';

export const Tiles = styled.ul`
    margin-top: 35px;
    display: flex;
    flex-wrap: wrap;

    li {
        margin-bottom: 35px;
    }
`;
