import { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { ReactComponent as WebhookIcon } from 'Assets/tiles/webhook.svg';
import { RequestCode } from 'Components';
import { transformWebhookObjToArr, getWebhookBody } from 'Utils';

import * as Styled from './styled';

export const DetailsBlock = ({ details }) => {
    const {
        eventName,
        endpointAddress,
        deviceTokens = [],
        createdAt,
        requestType,
        headers,
        requestFormat,
        queryParams,
        basicAuth,
        customBody,
    } = details;

    const creationDate = moment(createdAt).format('D MMM YYYY');
    const transformedHeaders = useMemo(() => transformWebhookObjToArr(headers), [headers]);
    const transformedQueryParams = useMemo(
        () => (queryParams ? transformWebhookObjToArr(queryParams) : []),
        [queryParams],
    );

    const requestValues = {
        requestType,
        url: endpointAddress,
        httpHeaders: transformedHeaders,
        requestFormat,
        queryParameters: transformedQueryParams,
        authenticationUsername: basicAuth?.username,
        authenticationPassword: basicAuth?.password,
        body: getWebhookBody(details),
        customBody,
    };

    return (
        <Styled.DetailsWrapper>
            <Styled.FormItem>
                <Styled.ItemLabel>
                    Service
                </Styled.ItemLabel>
                <Styled.IconWrapper>
                    <WebhookIcon />
                    Webhook
                </Styled.IconWrapper>
            </Styled.FormItem>
            <Styled.FormItem>
                <Styled.ItemLabel>
                    WebHook Event Name
                </Styled.ItemLabel>
                {eventName}
            </Styled.FormItem>
            <Styled.FormItem>
                <Styled.ItemLabel>
                    Endpoint Address
                </Styled.ItemLabel>
                {endpointAddress}
            </Styled.FormItem>
            <Styled.FormItem>
                <Styled.ItemLabel>
                    Request
                </Styled.ItemLabel>
                <RequestCode values={requestValues} />
            </Styled.FormItem>
            <Styled.FormItem>
                <Styled.ItemLabel>
                    Devices Count
                </Styled.ItemLabel>
                {deviceTokens.length}
            </Styled.FormItem>
            <Styled.FormItem>
                <Styled.ItemLabel>
                    Creation Date
                </Styled.ItemLabel>
                {creationDate}
            </Styled.FormItem>
        </Styled.DetailsWrapper>
    );
};

DetailsBlock.propTypes = {
    details: PropTypes.object.isRequired,
};
