import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { GET_DEVICE_LAST_MESSAGE } from 'Constants';
import { getDataCountWidgetData } from 'Utils';
import { useMessageSubscription } from 'Hooks';

import { HumidityWidget } from '../../../../widgets';

export const Humidity = ({ chartId, title, deviceToken, pin }) => {
    const { data, loading, refetch } = useQuery(
        GET_DEVICE_LAST_MESSAGE,
        {
            variables: { chartId },
        },
    );
    const lastMessage = data?.getDeviceLastMessage;

    useMessageSubscription([deviceToken], pin, refetch);

    const getWidgetData = () => {
        if (lastMessage) {
            return getDataCountWidgetData([{ lastMessage }]);
        }

        return getDataCountWidgetData([]);
    };

    const widgetData = useMemo(
        () => getWidgetData(),
        [lastMessage],
    );

    return (
        <HumidityWidget
            data={widgetData}
            title={title}
            isEmpty={loading || !lastMessage}
        />
    );
};

Humidity.propTypes = {
    chartId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    deviceToken: PropTypes.string.isRequired,
    pin: PropTypes.number.isRequired,
};
