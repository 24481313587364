import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { ReactComponent as ArrowRight } from 'Assets/icons/arrowRight.svg';

import * as Styled from './styled';

export const TextCard = ({
    title,
    link,
    value,
}) => (
    <Styled.Card>
        <Styled.Content>
            <Styled.Value>
                {value}
            </Styled.Value>
            <Styled.Title>
                {title}
            </Styled.Title>
        </Styled.Content>
        <Link
            to={
                link && typeof link[0] === 'string'
                    ? link[0]
                    : link[0].route
            }
        >
            <Styled.Link>
                See all
                <ArrowRight />
            </Styled.Link>
        </Link>
        
    </Styled.Card>
);

TextCard.propTypes = {
    title: PropTypes.string.isRequired,
    link: PropTypes.array.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

TextCard.defaultProps = {
    value: 0,
};
