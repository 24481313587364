import { toast } from 'react-toastify';

const options = {
    position: 'top-right',
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: 'colored',
};
export function showToastError(message) {
    return toast.error(`Error: ${message}`, { ...options, type: 'error' });
}

export function showToastSuccess(message) {
    return toast.success(message, { ...options, type: 'success' });
}

export function showToastInfo(message) {
    return toast.success(message, { ...options, type: 'info' });
}
