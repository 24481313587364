import isEmpty from 'lodash/isEmpty';

import { ML_RESPONSE_STATUSES } from 'Constants';

export const getChartsData = (preProcessingData, model) => {
    const result = {
        afterFilterData: {
            chartData: [],
            label: 'Time (milliseconds)',
        },
        shortFFTs: {
            chartData: [],
            label: 'Frequency (Hz)',
        },
    };

    const status = preProcessingData?.state;

    if (status !== ML_RESPONSE_STATUSES.SUCCESS) {
        return result;
    }

    const ppfilteredData = preProcessingData?.output?.SP?.samples?.[0].data?.[0].filtered_data;

    if (!isEmpty(ppfilteredData)) {
        const frequency = model.frequency;

        result.afterFilterData.chartData = ppfilteredData.map((item, index) => ({
            xValue: item[0] * 1,
            yValue: item[1] * 1,
            zValue: item[2] * 1,
            xAxis: +((index / frequency) * 1000).toFixed(2),
        }));
    }

    const data = preProcessingData?.output?.SP?.samples?.[0].data?.[0];
    const frequencyBin = data?.frequency_bin;
    const ppShortFFTs = data?.short_ffts;

    if (!isEmpty(ppShortFFTs)) {
        ppShortFFTs.forEach((parentItem) => {
            const transformedData = parentItem.map((item, index) => ({
                xValue: item[0] * 1,
                yValue: item[1] * 1,
                zValue: item[2] * 1,
                xAxis: +(index * frequencyBin).toFixed(2),
            }));
            result.shortFFTs.chartData = [...result.shortFFTs.chartData, ...transformedData];
        });
    }

    return result;
};
