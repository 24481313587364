import * as Styled from '../../styled';

export const NoSignals = () => (
    <>
        <Styled.SignalsHeader data-cy="device-signals-empty-wrapper">
            <h2>No Signals</h2>
            <p>
                <span>There is no signals from this device yet</span>
            </p>
        </Styled.SignalsHeader>
    </>
);
