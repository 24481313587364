import styled from 'styled-components';

export const Wrapper = styled.div`
    max-width: 700px;
`;

export const Header = styled.div`
    width: 100%;
    height: 50px;
    padding: 15px 20px;
    background-color: ${({ theme }) => theme.colors.title};
    color: #fff;
    font-size: 16px;
    font-weight: bold;
`;

export const Content = styled.div`
    padding: 20px 15px;
`;

export const FormItem = styled.div`
    margin-bottom: 10px;

    input {
        width: 100%;
    }
`;

export const ItemLabel = styled.div`
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.title};
    font-size: 16px;
    font-weight: bold;
    line-height: 1.38;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 15px;
    text-align: center;

    & > *:first-child {
        margin-right: 15px;
    }
`;

export const Text = styled.div`
    padding-left: 10px;

    input,
    select {
        width: auto;
        background-color: ${({ theme }) => theme.colors.boxBackground};
        border: 0;
        height: 24px;
        border-radius: 3px;
        padding-left: 5px;
        font-weight: 500;
        margin: 0 5px;
    }
`;
