import PropTypes from 'prop-types';

import * as Styled from './styled';

export const Checkbox = ({
    title,
    value,
    checked,
    field,
    handleCheckbox,
    disabled,
    cyData,
}) => (
    <Styled.Wrapper>
        <Styled.Container disabled={disabled}>
            {title}
            {field && Object.values(field).length ? (
                <input
                    value={field.value}
                    name={field.name}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    type="checkbox"
                    checked={field.value}
                    disabled={disabled}
                    data-cy={cyData}
                />
            ) : (
                <input
                    type="checkbox"
                    value={value}
                    checked={checked}
                    onChange={handleCheckbox}
                    disabled={disabled}
                    data-cy={cyData}
                />
            )}
            <Styled.Fake />
        </Styled.Container>
    </Styled.Wrapper>
);
Checkbox.propTypes = {
    value: PropTypes.string,
    title: PropTypes.node,
    checked: PropTypes.bool,
    field: PropTypes.object,
    handleCheckbox: PropTypes.func,
    disabled: PropTypes.bool,
    cyData: PropTypes.string,
};

Checkbox.defaultProps = {
    value: '',
    checked: false,
    field: {},
    handleCheckbox: () => {},
    title: '',
    disabled: false,
    cyData: '',
};
