import styled, { css } from 'styled-components';
import { hexToRgb } from 'Utils';

export const TableWrapper = styled.div`
    width: 90%;
    margin: 0 auto;
    padding: 30px 30px 50px;
`;

export const Table = styled.div`
    margin-top: 25px;
    padding: 3px;
`;

export const Title = styled.h1`
    text-align: center;
    padding-top: 30px;
    font-weight: 700;
`;

export const CreateItem = styled.div`
    max-width: 200px;
    margin: 20px auto;
    padding-bottom: 20px;

    h2 {
        text-align: center;
    }
`;

export const Icon = styled.div`
    width: 100px;
    height: 100px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.colors.boxBackground};
    cursor: pointer;

    svg {
        font-size: 50px;
        color: ${({ theme }) => theme.colors.title}
    }
`;

export const NewButton = styled.div`
    position: absolute;
    top: 5px;
    right: 5px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.active};
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        font-size: 12px;
        color: ${({ theme }) => theme.colors.activeText};
    }
`;

const loadingStyles = css`
    color: rgba(${({ theme }) => hexToRgb(theme.colors.title)}, 0.8);
    div {
        border: 1px solid ${({ theme }) => theme.colors.title};
    }
`;

const errorStyles = css`
    color: rgba(${({ theme }) => hexToRgb(theme.colors.error)}, 0.8);
    div {
        border: 1px solid ${({ theme }) => theme.colors.error};
    }
`;

export const InfoWrapper = styled.div`
    padding: 10px;
    font-size: 24px;
    font-weight: bold;

    div {
        padding: 20px;
    }

    ${({ $loading }) => $loading && loadingStyles}
    ${({ $error }) => $error && errorStyles}
`;
