import * as Yup from 'yup';

import { ValidationService } from 'Services';

export const initialValues = {
    registry: '',
    topic: '',
};

export const validationSchema = Yup.object().shape({
    registry: Yup.string().required(ValidationService.getRequiredError('registry')),
    topic: Yup.string().required(ValidationService.getRequiredError('topic')),
});
