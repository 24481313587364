import {
    showToastError,
    checkItemExists,
    sortByType,
    updateKeys,
    getDeviceModeInfo,
    compareMD5Hash,
    getStoredMeta,
    setStoredMeta,
} from 'Utils';
import { supportedPymakrExtensions, pymakrFilesExceptions } from 'Constants';
import { useContextSelector } from './useContextSelector';

export const useOnBlur = ({ handlersSet, projectKey }) => {
    const {
        onCancelAction,
        cancelSteps,
        getTarget,
        setDirectory,
        onRename,
    } = handlersSet;

    const tabsSlice = useContextSelector('tabs');
    const { tabs, activeTab } = tabsSlice.state;
    const { openTab, updateTabs } = tabsSlice.handlers;

    const colorizerSlice = useContextSelector('colorizer');
    const { updatedFiles } = colorizerSlice.state;
    const { setUpdatedFile, unsetUpdatedFile } = colorizerSlice.handlers;

    return (value, type, renamingItem = null) => {
        const path = renamingItem?.parent || null;

        if (value === '') {
            return onCancelAction();
        }

        if (type === 'file') {
            const extension = value.slice(value.lastIndexOf('.'));
            if (!pymakrFilesExceptions.includes(value) && !supportedPymakrExtensions.includes(extension)) {
                showToastError(
                    `UNSUPPORTED_EXTENSION. Currently avalable: ${[...supportedPymakrExtensions, ...pymakrFilesExceptions].join(' ')}`,
                );
                return;
            }
        }

        const charCodeMin = 65;
        const inputValue = value ? value.trim() : value;
        const insertDirectory = getTarget(path);

        const isItemExists = checkItemExists(insertDirectory.files, inputValue, type);

        if (isItemExists) {
            showToastError(
                `Creation failed, ${inputValue} ${type} already exists.`,
            );
            return;
        }

        if (inputValue.charCodeAt(0) > 122 || inputValue.charCodeAt(0) < charCodeMin) {
            showToastError(
                `Creation failed, ${inputValue} does not fit naming requirements.`,
            );
            return;
        }

        const insertIndex = insertDirectory.files.findIndex((i) => (i && !i.name));

        const insertPath = insertDirectory.files[insertIndex].path && insertDirectory.files[insertIndex].parent
            ? `${insertDirectory.files[insertIndex].path}/${inputValue}`
            : inputValue;

        const item = {
            ...insertDirectory.files[insertIndex],
            path: insertPath,
            name: inputValue,
        };

        if (type === 'folder') {
            item.files = insertDirectory.files[insertIndex].files || [];
            const updateOpenedTabs = (itemInitial, itemCurrent) => {
                updateTabs(tabs, activeTab, itemInitial, itemCurrent);
            };
            updateKeys(item.files, insertPath, projectKey, updateOpenedTabs);
        } else {
            item.key = `${projectKey}-${insertPath}`;
        }

        insertDirectory.files.splice(insertIndex, 1, item);
        insertDirectory.files = sortByType(insertDirectory.files, type);

        setDirectory(insertDirectory, insertDirectory.path);
        cancelSteps();

        if (type === 'file') {
            if (renamingItem?.key) {
                onRename(renamingItem, item);
            } else {
                const existingContent = localStorage.getItem(item.key);
                if (existingContent === null) {
                    localStorage.setItem(item.key, '');
                }
                openTab(tabs, item);
            }

            const deviceInfo = getDeviceModeInfo(projectKey);

            if (deviceInfo) {
                if (deviceInfo.hierarchy?.includes(item.path) && compareMD5Hash(projectKey, item.path)) {
                    unsetUpdatedFile(updatedFiles, item.path);
                } else {
                    setUpdatedFile(updatedFiles, item.path);
                }

                const renamingItems = getStoredMeta(projectKey)?.renamingItems || [];
                let itemForUpload = {};
                const renamingIndex = renamingItems?.findIndex((uploadItem) => !uploadItem.current);
                if (renamingIndex >= 0) {
                    itemForUpload = {
                        initialPath: renamingItems[renamingIndex].initialPath,
                        current: item.path,
                    };
                    renamingItems[renamingIndex] = itemForUpload;
                    setStoredMeta(projectKey, { renamingItems });
                }
            }
        }
    };
};
