import { Formik } from 'formik';
import PropTypes from 'prop-types';

import { initialValues, getValidationSchema } from './config';
import { FormContent } from './formContent';

import * as Styled from '../styled';

export const ModelDefinition = ({
    nextStep,
    prevStep,
    formValues,
    hideTitle,
    hideBackButton,
    submitText,
    loading,
}) => {
    const onSubmit = (values) => {
        nextStep({ ...formValues, ...values });
    };

    const combinedInitialValues = {
        name: formValues.name || initialValues.name,
        description: formValues.description || initialValues.description,
        frequency: formValues.frequency || initialValues.frequency,
        windowsStep: formValues.windowsStep || initialValues.windowsStep,
        windowsSize: formValues.windowsSize || initialValues.windowsSize,
    };
    
    return (
        <>
            {!hideTitle && (
                <Styled.Title>
                    Model Definition
                </Styled.Title>
            )}
            <Formik
                onSubmit={onSubmit}
                initialValues={combinedInitialValues}
                validationSchema={getValidationSchema()}
                enableReinitialize
                validateOnMount
            >
                {(props) => (
                    <FormContent
                        {...props}
                        onBack={prevStep}
                        hideBackButton={hideBackButton}
                        submitText={submitText}
                        loading={loading}
                    />
                )}
            </Formik>
        </>
    );
};

ModelDefinition.propTypes = {
    nextStep: PropTypes.func.isRequired,
    prevStep: PropTypes.func,
    formValues: PropTypes.object.isRequired,
    hideTitle: PropTypes.bool,
    hideBackButton: PropTypes.bool,
    submitText: PropTypes.string,
    loading: PropTypes.bool,
};

ModelDefinition.defaultProps = {
    hideTitle: false,
    prevStep: () => {},
    hideBackButton: false,
    submitText: 'Next',
    loading: false,
};
