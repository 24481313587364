import styled from 'styled-components';

export const Modal = styled.div`
    width: 700px;
    background-color: #fff;
`;

export const Header = styled.div`
    width: 100%;
    height: 50px;
    padding: 15px 20px;
    background-color: ${({ theme }) => theme.colors.title};
    color: #fff;
    font-size: 16px;
    font-weight: bold;
`;

export const Form = styled.div`
    padding: 30px 0 0 20px;
`;

export const Item = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    & > span {
        display: block;
        width: 52px;
        margin-right: 48px;
        color: ${({ theme }) => theme.colors.title};
        font-size: 16px;
        font-weight: bold;
        line-height: 1.38;
    }
`;

export const Checkbox = styled.div`
    margin-left: 100px;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 50px;
    padding-bottom: 27px;

    & > div {
        width: 216px;
        display: flex;
        justify-content: space-between;
    }
`;
