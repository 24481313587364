import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as _ from 'lodash';

import { showToastError, getStoredMeta, setStoredMeta, setDeviceModeInfo } from 'Utils';
import { ReactComponent as ArrowDown } from 'Assets/icons/arrowRight.svg';
import { ReactComponent as Cloud } from 'Assets/icons/cloud.svg';
import { ReactComponent as Refresh } from 'Assets/icons/refresh.svg';

import { useModal, useContextMenu, useHierarchy, useOnBlur, useContextSelector } from '../../../pymakrHooks';
import * as Styled from './styled';

export const ExplorerContainer = ({
    heading,
    parentRef,
    initialStructure,
    explorerRefreshHandlers,
    projectKey,
    syncHierarchy,
    user,
    title,
    deviceToken,
}) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const [inputValue, setInputValue] = useState('');
    const [isChanging, setIsChanging] = useState(false);
    const structureRef = useRef(null);

    const tabsSlice = useContextSelector('tabs');
    const { tabs } = tabsSlice.state;
    const { openTab } = tabsSlice.handlers;

    const { onRename, onDelete, onRefresh, onClearExpiredData } = explorerRefreshHandlers;

    const expandHierarchy = () => {
        setIsExpanded(!isExpanded);
    };

    const {
        checkFreeSpace,
        setPath,
        setHierarchy,
        getPath,
        getTarget,
        renderHierarchy,
        setDirectory,
    } = useHierarchy(initialStructure, projectKey);

    useEffect(() => {
        onClearExpiredData();
        setHierarchy();
        structureRef.current = _.cloneDeep(initialStructure);
    }, []);

    useEffect(() => {
        if (!_(initialStructure).xorWith(structureRef.current, _.isEqual).isEmpty()) {
            setHierarchy(initialStructure); 
        }
    }, [initialStructure]);

    const createFolder = () => {
        if (!checkFreeSpace()) {
            return;
        }

        const path = getPath();
        const hierarchyLevel = path.split('/').length - 1;

        const newFolder = {
            type: 'folder',
            expanded: false,
            hierarchyLevel: hierarchyLevel + 1,
            path: path || '',
            parent: path || '',
        };
 
        const targetDir = getTarget();

        if (!targetDir.expanded) {
            targetDir.expanded = true;
        }

        targetDir.files.unshift(newFolder);

        setIsChanging(true);
        setDirectory(targetDir, path);
    };

    const expandFolder = (e, path) => {
        e.stopPropagation();
        const changing = isChanging || getStoredMeta(projectKey)?.renamingItem;

        if (!changing) {
            const target = getTarget(path);
            target.expanded = !target.expanded;
            setDirectory(target, target.path);
        }
    };

    const createFile = () => {
        if (!checkFreeSpace()) {
            return;
        }

        const path = getPath();
        const target = getTarget();

        const newFile = {
            type: 'file',
            path: path || '',
            parent: path || '',
        };

        if (!target.expanded) {
            target.expanded = true;
        }

        const insertIndex = target.files.findIndex((i) => i.type === 'file');

        if (insertIndex >= 0) {
            target.files.splice(insertIndex, 0, newFile);
        } else {
            target.files.push(newFile);
        }

        setIsChanging(true);
        setDirectory(target, path);
    };

    const renameItem = () => {
        const target = getTarget();

        if (target.isInitial) {
            showToastError(
                'Items from initial structure are not allowed to be renamed.',
            );
            return;
        }

        setInputValue(target.name);

        const insertPath = target.path;
        target.name = null;
        target.path = target.parent || target.path;

        setPath(target.parent);
        setIsChanging(true);
        setStoredMeta(projectKey, { renamingItem: target });

        if (syncHierarchy && target.type === 'file') {
            const renamingItems = getStoredMeta(projectKey)?.renamingItems || [];
            let itemForUpload = {};
            const existingIndex = renamingItems?.findIndex((item) => item.current === target.path);
            if (existingIndex >= 0) {
                itemForUpload = {
                    initialPath: renamingItems[existingIndex].initialPath,
                    current: null,
                };
                renamingItems[existingIndex] = itemForUpload;
                setStoredMeta(projectKey, { renamingItems });
            } else {
                itemForUpload = {
                    initialPath: target.path,
                    current: null,
                };
                setStoredMeta(projectKey, { renamingItems: [...renamingItems, itemForUpload] });
            }
        }

        setDirectory(target, insertPath);
    };

    const initiateRemoval = () => {
        const target = getTarget();

        if (target.isInitial) {
            showToastError(
                'Not allowed to remove items provided by initial structure.',
            );
            return false;
        }

        return true;
    };

    const removeItem = () => {
        const target = getTarget();
        const parentTarget = getTarget(target.parent);

        parentTarget.files.splice(parentTarget.files.findIndex((i) => i.path === target.path), 1);
        onDelete(target);
        setDirectory(parentTarget, target.parent);
    };

    const fileOnClick = (e, file) => {
        e.stopPropagation();

        if (isChanging) {
            return;
        }

        openTab(tabs, file);
    };

    const refreshExplorer = async () => {
        Object.keys(localStorage).forEach((storageKey) => {
            if (storageKey.startsWith(projectKey)) {
                localStorage.removeItem(storageKey);
            }
        });

        if (syncHierarchy) {
            await syncHierarchy(setHierarchy);
            setDeviceModeInfo(projectKey, title, deviceToken, user);
        } else {
            setHierarchy(initialStructure);
        }

        onRefresh();
    };

    const cancelSteps = () => {
        setIsChanging(false);
        setInputValue('');
        setStoredMeta(projectKey, { renamingItem: null });
    };

    const onCancelAction = () => {
        const renamingItem = getStoredMeta(projectKey)?.renamingItem;

        if (renamingItem) {
            return;
        }

        const target = getTarget();
        target.files.splice(target.files.findIndex((item) => !item.name), 1);

        cancelSteps();
        setDirectory(target, target.path);
    };

    const onBlur = useOnBlur({
        handlersSet: {
            onCancelAction,
            cancelSteps,
            getTarget,
            setDirectory,
            onRename,
        },
        projectKey,
    });

    const inputOnBlur = (type) => {
        const renamingItem = getStoredMeta(projectKey)?.renamingItem;
        onBlur(inputValue, type, renamingItem);
    };

    const onInputKeyDown = (e, type) => {
        if (e.key === 'Enter') {
            return inputOnBlur(type);
        }
        if (e.key === 'Escape') {
            return onCancelAction();
        }
    };

    const onChangeInput = (event) => {
        setInputValue(event.target.value);
    };

    const refreshTitle = syncHierarchy ? 'Synchronize with device' : 'Reset data';
    const refreshDescription = syncHierarchy
        ? 'Synchronization with device will reset all saved structure and data in Pymakr. Make sure, you have saved your changes.'
        : 'This will reset all current structure and data to initial statements. Make sure, you have saved your changes.';
    const refreshSubmitText = syncHierarchy ? 'Synchronize' : 'Reset';

    const { init: initRefresh, modal: refreshModal } = useModal({
        initiationFunc: null,
        title: refreshTitle,
        description: refreshDescription,
        action: refreshExplorer,
        submitText: refreshSubmitText,
    });

    const { init: initDelete, modal: deleteModal } = useModal({
        initiationFunc: initiateRemoval,
        title: 'Delete item',
        description: 'Are you sure you want delete this item?',
        action: removeItem,
    });

    const functionsSet = {
        createFolder,
        createFile,
        renameItem,
        initDelete,
    };

    const { setContext, contextMenu } = useContextMenu({
        parent: parentRef,
        functionsSet,
    });

    const openContext = (e, item = null) => {
        if (isChanging) {
            return;
        }

        e.stopPropagation();
        e.preventDefault();
        let menu = 'primary';

        if (item?.type === 'folder') {
            menu = 'casual';
        }

        if (item?.type === 'file') {
            menu = 'file';
        }

        setContext(menu, { x: e.pageX, y: e.pageY });
        setPath(item?.path);

        return (
            <div style={{ width: '20px', height: '20px', 'background-color': 'red' }} />
        );
    };

    const hierarchyFunctionSet = {
        onChangeInput,
        inputOnBlur,
        onInputKeyDown,
        fileOnClick,
        openContext,
        expandFolder,
    };

    const refreshTip = syncHierarchy ? 'Synchronize' : 'Reset structure';

    return (
        <Styled.Wrapper>
            {contextMenu}
            <Styled.ExplorerHeading>
                <Styled.ExtendedTitle>
                    <Styled.ExpansionArrow
                        $isExpanded={isExpanded}
                        onClick={expandHierarchy}
                    >
                        <ArrowDown />
                    </Styled.ExpansionArrow>
                    <div onContextMenu={(e) => openContext(e, initialStructure[0])}>
                        <Styled.CloudIcon>
                            <Cloud />
                        </Styled.CloudIcon>
                        <span>
                            <b>{heading}</b>
                        </span>
                    </div>
                </Styled.ExtendedTitle>
                <Styled.RefreshIcon
                    onClick={initRefresh}
                    Icon={Refresh}
                />
                <Styled.RefreshTip>
                    {refreshTip}
                </Styled.RefreshTip>
            </Styled.ExplorerHeading>
            {isExpanded && renderHierarchy(inputValue, hierarchyFunctionSet)}
            {refreshModal}
            {deleteModal}
        </Styled.Wrapper>
    );
};

ExplorerContainer.defaultProps = {
    syncHierarchy: null,
    user: '',
    title: '',
    deviceToken: '',
};

ExplorerContainer.propTypes = {
    heading: PropTypes.string.isRequired,
    parentRef: PropTypes.object.isRequired,
    initialStructure: PropTypes.object.isRequired,
    explorerRefreshHandlers: PropTypes.shape({
        onRename: PropTypes.func,
        onDelete: PropTypes.func,
        onRefresh: PropTypes.func,
        onClearExpiredData: PropTypes.func,
    }).isRequired,
    projectKey: PropTypes.string.isRequired,
    syncHierarchy: PropTypes.func,
    user: PropTypes.string,
    title: PropTypes.string,
    deviceToken: PropTypes.string,
};
