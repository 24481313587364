import cloneDeep from 'lodash/cloneDeep';

/* eslint-disable react/jsx-filename-extension */
import { ReactComponent as Home } from 'Assets/icons/home.svg';
import { ReactComponent as Devices } from 'Assets/icons/devices.svg';
import { ReactComponent as MachineLearning } from 'Assets/icons/machine-learning.svg';
import { ReactComponent as Projects } from 'Assets/icons/projects.svg';
import { ReactComponent as Pymakr } from 'Assets/icons/pymakr.svg';
import { ReactComponent as Settings } from 'Assets/icons/settings.svg';
import { ReactComponent as Support } from 'Assets/icons/support.svg';

export const capitalizeFirstLetter = (string) => {
    if (!string?.length) {
        return '';
    }

    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const capitalizeAllLetters = (string) => {
    if (!string?.length) {
        return '';
    }

    return string.split(' ').map((item) => capitalizeFirstLetter(item)).join(' ');
};

export const pascalCaseToString = (string) => {
    if (!string?.length) {
        return '';
    }

    return string.replace(/([a-z])([A-Z])/g, '$1 $2');
};

export const moveItemInArray = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export const hasValueLength = (obj) =>
    !!Object.values(obj).filter((item) => !!item).length;

export const eliminateUnnecessaryKey = (obj, exceptionKey = null) =>
    Object.keys(obj).filter((item) => item !== exceptionKey);

export const getFilledPropertiesWithoutUnnecessaryKey = (
    object,
    exceptionField = null,
) => {
    const arrayOfFilledProperty = [];
    if (!(object instanceof Object)) {
        return false;
    }
    const keys = eliminateUnnecessaryKey(object, exceptionField);
    for (const key of keys) {
        if (object[key] !== null) {
            arrayOfFilledProperty.push(key);
        }
    }
    return arrayOfFilledProperty;
};

export const removeEmptyProperties = (obj) => {
    const result = { ...obj };

    Object.keys(obj).forEach((key) => {
        if (!obj[key]) {
            delete result[key];
        }
    });

    return result;
};

export const fileToBase64 = (file) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
});

export const clamp = (number, min, max) => Math.min(Math.max(number, min), max);

export const generateIcon = (title) => {
    switch (title) {
        case 'home':
            return <Home />;
        case 'devices':
            return <Devices />;
        case 'projects':
            return <Projects />;
        case 'settings':
            return <Settings />;
        case 'pymakr':
            return <Pymakr />;
        case 'machine-learning':
            return <MachineLearning />;
        case 'support':
            return <Support />;
        default:
            return null;
    }
};

/**
 * Automatically format an amount of bytes into Kb or Mb
 * Calculated according the power-of-two logic: 1024 bytes -> 1 Kbyte
 * @param  {int} bytes the amount of bytes
 */
export const formatBytesShort = (bytes, m = 1) => {
    const sizes = ['B', 'Kb', 'Mb', 'Gb', 'Tb'];
    if (bytes === 0) { return '0'; }

    const i = parseInt(Math.floor(Math.log(bytes * m) / Math.log(1024)), 10);
    return (`${Math.round((bytes * m) / (1024 ** i), 2)} ${sizes[i]}`);
};

/**
 * Automatically format an amount of bytes into Kb or Mb
 * Calculated according the power-of-two logic: 1024 bytes -> 1 Kbyte
 * @param  {int} bytes the amount of bytes
 */
export const formatBytes = (bytes) => {
    const sizes = ['Bytes', 'Kb', 'Mb', 'Gb', 'Tb'];
    if (bytes === 0) { return '0 Byte'; }
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (i === 0 && bytes <= 1) {
        return (`${Math.round(bytes / (1024 ** i), 2)} Byte`);
    }
    return (`${Math.round(bytes / (1024 ** i), 2)} ${sizes[i]}`);
};

export const hexToRgb = (hex) => hex
    .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (_, r, g, b) => `#${r}${r}${g}${g}${b}${b}`)
    .substring(1)
    .match(/.{2}/g)
    .map((item) => parseInt(item, 16))
    .join(',');

export const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';

    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }

    return color;
};

/**
 * Automatically convert an amount of minutes into higher scales of time
 * The result is an array that contains the converted value at 0 and the unit at 1
 * @param  {int} minutes the amount of minutes
 */
 export function convertMinutes(minutes) {
    const sizes = ['minute', 'hour', 'day', 'month'];
    if (minutes === 0) { return [0, sizes[0]]; }
    if (minutes >= 60) {
        const hours = minutes / 60;
        if (hours >= 24) {
            const days = hours / 24;
            if (days >= 30) {
                const months = days / 30;
                return [months, sizes[3]];
            }
            return [days, sizes[2]];
        }
        return [hours, sizes[1]];
    }
    return [minutes, sizes[0]];
}

/**
 * Automatically convert an amount of bytes into Kb or Mb
 * Calculated according the power-of-two logic: 1024 bytes -> 1 Kb
 * The result is an array that contains the converted value at 0 and the unit at 1
 * @param  {int} bytes the amount of bytes
 */
 export function convertBytes(bytes) {
    const sizes = ['B', 'K', 'M', 'G', 'T'];
    if (bytes === 0) { return [0, sizes[0]]; }
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    return ([Math.round(bytes / (1024 ** i), 2), sizes[i]]);
}

export function removeGraphQlTypenames(obj) {
    const filteredObj = cloneDeep(obj);

    Object.keys(filteredObj).forEach((key) => {
        if (filteredObj[key] && typeof filteredObj[key] === 'object') {
            filteredObj[key] = removeGraphQlTypenames(filteredObj[key]);
        }

        if (key === '__typename') {
            delete filteredObj[key];
        }
    });

    return filteredObj;
}

export function roundNumberToTwoDigits(number) {
    return Math.round((number + Number.EPSILON) * 100) / 100;
}

export const formatAMPM = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours %= 12;
    hours = hours || 12;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    const strTime = `${hours}:${minutes} ${ampm}`;
    return strTime;
};

export const getSSOUrl = () => {
    let ssoURL = `${process.env.REACT_APP_PYCOM_SSO_URL}/login`;
    ssoURL += `?client_id=${process.env.REACT_APP_PYCOM_SSO_CLIENT_ID}`;
    ssoURL += `&redirect_uri=${process.env.REACT_APP_PYCOM_SSO_REDIRECT_URL}`;
    ssoURL += '&scope=profile';
    ssoURL += '&response_type=code';
    ssoURL += '&state=pybytes-browser';

    return ssoURL;
};
