import PropTypes from 'prop-types';
import { Radio } from 'antd';

import * as Styled from './styled';

export const RadioGroupCloud = ({
    options,
    field,
    form: { errors, touched },
    disabled,
    errorPosition,
    errorClassName,
}) => (
    <>
        <Radio.Group
            {...field}
        >
            {options.map((valueItem) => (
                <Styled.RadioItem
                    value={valueItem.value}
                    key={valueItem.value}
                    disabled={valueItem.disabled}
                >
                    {valueItem.icon}
                    {valueItem.label}
                </Styled.RadioItem>
            ))}
        </Radio.Group>
        {!disabled && touched[field.name] && (
            <Styled.Error $position={errorPosition} className={errorClassName}>
                {errors[field.name]}
            </Styled.Error>
        )}
    </>
);

RadioGroupCloud.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        disabled: PropTypes.bool,
        icon: PropTypes.node,
    })).isRequired,
    field: PropTypes.object,
    disabled: PropTypes.bool,
    form: PropTypes.object,
    errorPosition: PropTypes.string,
    errorClassName: PropTypes.string,
};

RadioGroupCloud.defaultProps = {
    field: null,
    disabled: false,
    form: {},
    errorPosition: 'bottom',
    errorClassName: '',
};
