import { Form, Field } from 'formik';
import PropTypes from 'prop-types';

import { MODAL, TAB } from 'Constants';

import { TextInput } from '../../../inputs';
import { Button } from '../../../buttons';

import * as Styled from './styled';

export const FormContent = ({ typeOfForm, handleClose, isLoading }) => (
    <Form>
        <Styled.Form
            $form={typeOfForm === MODAL}
            $padded={typeOfForm === TAB}
        >
            <Styled.Item>
                <span>Login</span>
                <Field
                    name="login"
                    type="text"
                    component={TextInput}
                />
            </Styled.Item>
            <Styled.Item>
                <span>Password</span>
                <Field
                    name="password"
                    type="text"
                    component={TextInput}
                />
            </Styled.Item>
        </Styled.Form>
        <Styled.ButtonsWrapper
            $normal={typeOfForm === MODAL}
            $left={typeOfForm === TAB}
        >
            <div>
                <Button buttonType="transparent" type="reset" onClick={handleClose}>
                    Cancel
                </Button>
                <Button 
                    type="submit" 
                    loading={isLoading}
                    cyData="sigfox-connect-button"
                >
                    Connect
                </Button>
            </div>
        </Styled.ButtonsWrapper>
    </Form>
);

FormContent.propTypes = {
    handleClose: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
    typeOfForm: PropTypes.oneOf([MODAL, TAB]).isRequired,
};

FormContent.defaultProps = {
    isLoading: false,
};
