import styled from 'styled-components';

export const Clickboard = styled.div`
    border: 1px solid ${({ theme }) => theme.colors.tableHeader};
    border-radius: 5px;
    background-color: ${({ theme }) => theme.colors.tableHeader};
    width: 340px;
    padding: 10px;
    margin-right: 20px;
    word-break: break-all;
`;

export const ClickboardRow = styled.div`
    display: flex;
    align-items: center;
`;
