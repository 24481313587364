import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';
import { Redirect } from 'react-router';

import { Tile } from 'Components';
import {
    ROUTES,
    GET_WIFIS,
    GET_LORA_SETTINGS,
    GET_LTE_SETTINGS,
} from 'Constants';
import { ReactComponent as WiFi } from 'Assets/icons/wifi.svg';

import * as Styled from './styled';

const titleUrls = [
    { title: 'Wi-Fi Credentials', route: ROUTES.settings.network.wifi, cyData: 'network-empty-wifi' },
    { title: 'LTE Settings', route: ROUTES.settings.network.lte, cyData: 'network-empty-lte' },
    { title: 'LoRa Settings', route: ROUTES.settings.network.lora, cyData: 'network-empty-lora' },
    { title: 'Ethernet Settings', route: ROUTES.settings.network.ethernet, cyData: 'network-empty-ethernet' },
];

export const MainSettings = () => {
    const { data: wifisData } = useQuery(GET_WIFIS);
    const { data: loraData } = useQuery(GET_LORA_SETTINGS);
    const { data: lteData } = useQuery(GET_LTE_SETTINGS);
    const wifiList = wifisData?.getSettings.wifiSaved ?? [];
    const loraConfiguration = loraData?.getLoRaSettings ?? {};
    const lteList = lteData?.getLteSettings ?? [];

    const checkIsEmptySettings = useMemo(
        () =>
            isEmpty(wifiList) && isEmpty(loraConfiguration) && isEmpty(lteList),
        [wifisData, loraData, lteData],
    );

    return (
        <>
            {checkIsEmptySettings && (
                <Styled.Settings>
                    <Styled.TitleBox title="Add WiFi" />
                    <Styled.Button>
                        <Tile links={titleUrls} icon={<WiFi />} title="" isNew />
                    </Styled.Button>
                </Styled.Settings>
            )}
            {!checkIsEmptySettings && (
                <Redirect to={ROUTES.settings.network.wifi} />
            )}
        </>
    );
};
