import { initialValues, AUTO_SETTINGS_TYPES } from './config';

export const autoSettingsToString = (network) => {
    if (network.autoSettings === false) { 
        return AUTO_SETTINGS_TYPES.MANUAL;
    }
    return AUTO_SETTINGS_TYPES.AUTOMATIC;
};

export const getInitialValues = (arrayOfNetworks, id = null) => {
    if (!id) {
        return initialValues;
    }
    const network = arrayOfNetworks.find((item) => item.ethernetId === id);
    const formattedNetwork = { ...network, autoSettings: autoSettingsToString(network) };
    return formattedNetwork;
};

export const mask = {
    guide: false,
    placeholderChar: '\u2000',
    mask: (value) => Array(value.length).fill(/[\d.]/),
    pipe: (value) => {
        if (value === '.' || value.endsWith('..')) { return false; }

        const parts = value.split('.');

        if (
            parts.length > 4 ||
            parts.some((part) => part === '00' || part < 0 || part > 255)
        ) {
            return false;
        }

        return value;
    },
};
