import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useMemo } from 'react';

import { getValidationSchema } from './config';
import { FormContent } from './formContent';

import * as Styled from './styled';

export const EditionSignalModal = ({ onSubmit, onClose, currentPin, pins, isLoading }) => {
    const { name, unit, pin: pinNumber, _id } = currentPin;

    const onFormSubmit = (values) => {
        const variables = {
            _id,
            ...values,
        };
        onSubmit(variables);
    };

    const initialValues = { name, unit, pinNumber };
    const validationPins = useMemo(
        () => pins.filter((pin) => pin._id !== _id),
        [pins, currentPin],
    );

    return (
        <Styled.ModalContainer data-cy="edit-signal-modal-wrapper">
            <h3>Edit signal</h3>
            <Styled.ModalContent>
                <Formik
                    onSubmit={onFormSubmit}
                    initialValues={initialValues}
                    validationSchema={getValidationSchema(validationPins)}
                    validateOnMount
                >
                    {(props) => (
                        <FormContent
                            {...props}
                            onClose={onClose}
                            isLoading={isLoading}
                        />
                    )}
                </Formik>
            </Styled.ModalContent>
        </Styled.ModalContainer>
    );
};

EditionSignalModal.defaultProps = {
    isLoading: false,
    currentPin: {},
};

EditionSignalModal.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    pins: PropTypes.array.isRequired,
    currentPin: PropTypes.object,
    isLoading: PropTypes.bool,
};
