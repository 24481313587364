import { Field } from 'formik';

import { TextInput } from '../../../../../inputs';

import * as Styled from '../../styled';

export const HttpAuthentication = () => (
    <Styled.FormContent>
        <Styled.FormItem>
            <Styled.ItemLabel>
                Username
            </Styled.ItemLabel>
            <Field
                name="authenticationUsername"
                component={TextInput}
            />
        </Styled.FormItem>
        <Styled.FormItem>
            <Styled.ItemLabel>
                Password
            </Styled.ItemLabel>
            <Field
                name="authenticationPassword"
                component={TextInput}
            />
        </Styled.FormItem>
    </Styled.FormContent>
);
