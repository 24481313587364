import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { ALL } from 'Constants';

import * as Styled from './styled';

export const SelectWithPopup = ({ values, initValue, handleSelect, cyData }) => {
    const [selectedItem, setSelectedItem] = useState(initValue);
    const [isOpen, setIsOpen] = useState(false);

    const refSelectedValue = useRef(null);

    const closePopup = (event) => {
        if (refSelectedValue.current && !refSelectedValue.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', closePopup);
        return () => {
            document.removeEventListener('click', closePopup);
        };
    }, []);

    const handleModal = () => {
        setIsOpen(!isOpen);
    };

    const selectAction = (item) => {
        setSelectedItem(item);
        setIsOpen(false);
        handleSelect(item);
    };
    return (
        <Styled.Select>
            <Styled.SelectValue
                onClick={handleModal}
                ref={refSelectedValue}
            >
                <Styled.SelectedValue data-cy={cyData}>
                    {selectedItem.label}
                </Styled.SelectedValue>
                <Styled.Triangle
                    $rotate={isOpen}
                />
            </Styled.SelectValue>

            {isOpen && (
                <Styled.Modal>
                    {values.map((item) => (
                        <Styled.Item
                            $active={item.value === selectedItem.value}
                            $all={item.value === ALL}
                            key={item.value}
                            value={item.value}
                            onClick={() => selectAction(item)}
                        >
                            {item.label}
                        </Styled.Item>
                    ))}
                </Styled.Modal>
            )}
        </Styled.Select>
    );
};

SelectWithPopup.defaultProps = {
    initValue: null,
    values: [],
    handleSelect: () => { },
    cyData: '',
};

SelectWithPopup.propTypes = {
    initValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
    values: PropTypes.array,
    handleSelect: PropTypes.func,
    cyData: PropTypes.string,
};
