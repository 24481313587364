import PropTypes from 'prop-types';
import { Form, Field } from 'formik';

import { TextArea, Button } from 'Components';

import * as Styled from './styled';

export const FormContent = ({ prevStep }) => (
    <Form>
        <div>
            <Styled.FormItem>
                <Field
                    name="description"
                    component={TextArea}
                    rows={7}
                />
            </Styled.FormItem>
            <Styled.ButtonsWrapper>
                <Button
                    buttonType="transparent"
                    onClick={prevStep}
                >
                    Back
                </Button>
                <Button type="submit">
                    Next
                </Button>
            </Styled.ButtonsWrapper>
        </div>
    </Form>
);

FormContent.propTypes = {
    prevStep: PropTypes.func.isRequired,
};
