import { useState } from 'react';
import { Modal, DeleteModal } from 'Components';

export const useModal = ({ initiationFunc, title, description, action, submitText }) => {
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);

    const onClose = () => {
        setConfirmModalOpen(false);
    };

    const onAction = () => {
        action();
        setConfirmModalOpen(false);
    };

    const init = () => {
        if (initiationFunc) {
            const openModal = initiationFunc();
            if (!openModal) {
                return;
            }
        }
        setConfirmModalOpen(true);
    };

    const modal = (
        <Modal
            isOpened={confirmModalOpen}
            handleClose={onClose}
        >
            <DeleteModal
                title={title}
                description={description}
                submitText={submitText || 'Delete'}
                cancelAction={onClose}
                deleteAction={onAction}
            />
        </Modal>
    );

    return { init, modal };
};
