import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { GET_DEVICE_ACTIVITY } from 'Constants';
import { useMessageSubscription } from 'Hooks';

import { OnlineOfflineWidget } from '../../../../widgets';

export const OnlineOfflineChart = ({ chartId, title }) => {
    const { data, loading, refetch } = useQuery(GET_DEVICE_ACTIVITY, {
        variables: { chartId },
    });

    const chartData = data?.getDevicesActivity?.countedDevices ?? [];
    const devicesTokens = data?.getDevicesActivity?.devicesTokens ?? [];

    useMessageSubscription(devicesTokens, null, refetch);

    return (
        <>
            <OnlineOfflineWidget
                data={chartData}
                isEmpty={loading || !chartData.length}
                title={title}
            />
        </>
    );
};

OnlineOfflineChart.propTypes = {
    chartId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};
