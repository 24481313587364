import isEmpty from 'lodash/isEmpty';

export const getModelDetails = (model) => {
    const { preProcessingStatus, modelDefinition, training } = model;

    return {
        preProcessingStatus: JSON.parse(preProcessingStatus),
        modelDefinition: JSON.parse(modelDefinition),
        training: JSON.parse(training),
    };
};

export const getNNBlock = (data) => {
    const {
        trainingEpochs,
        learningRate,
        minConfidenceThreshold,
        layers,
    } = data;

    const inputLayerValue = +layers[0].value;
    const outputLayerValue = +layers.slice(-1)[0].value;
    const denseLayersData = layers.slice(1, -1).map((layer) => ({
        type: layer.type,
        number_of_neurons: +layer.value,
    }));

    return {
        block_type: 'nn_block',
        id: 'NN',
        input_blocks: ['SP'],
        command: 'train',
        untrained_nn_model: {
            training_epochs: +trainingEpochs,
            learning_rate: +learningRate,
            minimum_confidence_threshold: +minConfidenceThreshold,
            topology: {
                layers: [
                    {
                        type: 'input_layer',
                        shape: [inputLayerValue],
                    },
                    ...denseLayersData,
                    {
                        type: 'output_layer',
                        number_of_classes: outputLayerValue,
                    },
                ],
            },
        },
    };
};

export const getData = (mlSamples, samples) => {
    const transformedSamples = mlSamples.map((mlSample) => {
        const selectedSamples = samples.filter((sample) => sample.mlSampleId === mlSample._id);

        const payload = selectedSamples.reduce((result, sample) => {
            if (!isEmpty(sample.message)) {
                const data = sample.message.payload;
                const array = data
                    .slice(data.lastIndexOf('[') + 1, data.lastIndexOf(']'))
                    .split(',');
                
                const matchRegex = /^-?\d*(\.\d+)?$/;

                if (array[0].match(matchRegex)
                    && array[1].match(matchRegex)
                    && array[2].match(matchRegex)) {
                    result.push([array[0] * 1, array[1] * 1, array[2] * 1]);
                }
            }

            return result;
        }, []);

        const result = {
            sample_name: mlSample.label,
            label: mlSample.label,
            data: payload,
        };

        return result;
    });

    return {
        samples: transformedSamples.filter((sample) => sample.data.length),
    };
};
