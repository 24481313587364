import PropTypes from 'prop-types';

import * as Styled from './styled';

export const Alert = ({ type, message }) => (
    <Styled.Alert
        type={type}
        message={message}
    />
);

Alert.propTypes = {
    type: PropTypes.oneOf(['success', 'info', 'warning', 'error']).isRequired,
    message: PropTypes.node.isRequired,
};
