import { Form } from 'formik';
import { PropTypes } from 'prop-types';

import { AuthInput } from 'Components';
import { ROUTES } from 'Constants';

import * as RootStyled from '../styled';

import * as Styled from './styled';

export const FormContent = ({ onClickRegister }) => (
    <Form>
        <div>
            <AuthInput
                name="email"
                label="Email"
            />
            <AuthInput
                name="password"
                type="password"
                label="Password"
                showPassEye
            />
        </div>
        <Styled.Options>
            <Styled.Register onClick={onClickRegister}>
                Create new account
            </Styled.Register>
            <Styled.ForgotLink to={ROUTES.auth.forgotPassword}>
                Forgot your password?
            </Styled.ForgotLink>
        </Styled.Options>
        <RootStyled.Button type="submit">
            Log In
        </RootStyled.Button>
    </Form>
);

FormContent.propTypes = {
    onClickRegister: PropTypes.func.isRequired,
};
