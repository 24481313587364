import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

export const Wrapper = styled.div`
    margin-top: 40px;
`;

export const List = styled.ul`
    display: flex;
    flex-wrap: wrap;

    li {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 214px;
        height: 180px;
        flex-grow: 0;
        margin: 10px 50px 0 0;
        border: solid 1px ${({ theme }) => theme.colors.border};

        h3 {
            color: ${({ theme }) => theme.colors.title};
            font-weight: 600;
            padding-top: 10px;
            margin-bottom: 0;
        }
    }
`;

export const Important = styled.div`
    color: ${({ theme }) => theme.colors.active};
`;

const fontStyles = {
    medium: `
        font-size: 3em;
        padding-top: 10px;
    `,
    huge: `
        font-size: 4em;
    `,
    small: `
        font-size: 2em;
        padding-top: 25px;
    `,
    tiny: `
        font-size: 1.2em;
        padding-top: 35px;
    `,
};

export const Count = styled.span`
    display: block;
    height: 70%;
    width: 100%;
    text-align: center;

    ${({ $important, theme }) => $important && `
        color: ${theme.colors.active};
    `}

    ${({ $fontSize }) => fontStyles[$fontSize]}
`;

export const InfoScreen = styled.div`
    background-color: ${({ theme }) => theme.colors.tableHeader};
    border-radius: 20px;
    width: 50%;
    height: 55%;
    color: ${({ theme }) => theme.colors.title};
`;

export const Subject = styled.span`
    display: block;
    height: 30%;
    width: 100%;
    text-align: center;
`;

export const Link = styled(RouterLink)`
    color: ${({ theme }) => theme.colors.active};
`;
