import gql from 'graphql-tag';

export const GET_PYLS_JWT_TOKEN = gql`
    query getJWTToken {
        getJWTToken {
            jwt
        }
    }
`;

export const GET_PYLS_USERS = gql`
    query getUsers($jwt: String!, $limit: Int!) {
        getUsers(jwt: $jwt, limit: $limit)
    }
`;

export const GET_PYLS_USER = gql`
    query getUser($jwt: String!, $userID: String!) {
        getUser(jwt: $jwt, userID: $userID)
    }
`;

export const GET_PYLS_DEVICE_ACTIVATION = gql`
    query getDeviceActivation($jwt: String!, $devEui: String!) {
        getDeviceActivation(jwt: $jwt, devEui: $devEui)
    }
`;

export const GET_PYLS_NETWORK_SERVERS = gql`
    query getNetworkServers($jwt: String!, $limit: Int!) {
        getNetworkServers(jwt: $jwt, limit: $limit)
    }
`;

export const GET_PYLS_ORGANIZATIONS = gql`
    query getOrganizations($jwt: String!, $limit: Int!) {
        getOrganizations(jwt: $jwt, limit: $limit)
    }
`;

export const GET_PYLS_APPLICATIONS = gql`
    query getOrganizations($jwt: String!, $limit: Int!) {
        getOrganizations(jwt: $jwt, limit: $limit)
    }
`;

export const GET_PYLS_USER_BY_USERNAME = gql`
    query getUserByUsername($jwt: String!, $username: String!, $limit: Int!) {
        getUserByUsername(jwt: $jwt, username: $username, limit: $limit)
    }
`;

export const GET_PYLS_ORG_BY_DISPLAYNAME = gql`
    query getOrganizationByDisplayName(
        $jwt: String!
        $displayName: String!
        $limit: Int!
    ) {
        getOrganizationByDisplayName(
            jwt: $jwt
            displayName: $displayName
            limit: $limit
        )
    }
`;

export const GET_PYLS_APPLICATION_BY_ORG_ID = gql`
    query getApplicationByOrgID(
        $jwt: String!
        $organizationID: Int!
        $limit: Int!
    ) {
        getApplicationByOrgID(
            jwt: $jwt
            organizationID: $organizationID
            limit: $limit
        )
    }
`;

export const GET_PYLS_SERVICE_PROFILES_BY_ORG_ID = gql`
    query getServiceProfilesByOrgID(
        $jwt: String!
        $organizationID: Int!
        $limit: Int!
    ) {
        getServiceProfilesByOrgID(
            jwt: $jwt
            organizationID: $organizationID
            limit: $limit
        )
    }
`;

export const GET_PYLS_DEVICE_PROFILES_BY_ORG_ID = gql`
    query getDeviceProfilesByOrgID(
        $jwt: String!
        $organizationID: Int!
        $limit: Int!
    ) {
        getDeviceProfilesByOrgID(
            jwt: $jwt
            organizationID: $organizationID
            limit: $limit
        )
    }
`;

export const GET_PYLS_DEVICE_KEYS = gql`
    query getKeys($jwt: String!, $devEui: String!) {
        getKeys(jwt: $jwt, devEui: $devEui)
    }
`;

export const DELETE_PYLS_API_ENTITIES = gql`
    mutation deletePycomLoraServerAPIEntities(
        $jwt: String!
        $id: String!
        $method: String!
    ) {
        deletePycomLoraServerAPIEntities(jwt: $jwt, id: $id, method: $method)
    }
`;

export const CREATE_PYLS_ORGANIZATION = gql`
    mutation createOrganization($jwt: String!, $organization: Organization!) {
        createOrganization(jwt: $jwt, organization: $organization) {
            id
        }
    }
`;

export const CREATE_PYLS_USER = gql`
    mutation createUser($jwt: String!, $user: User!, $organizationID: String!) {
        createUser(jwt: $jwt, user: $user, organizationID: $organizationID) {
            id
        }
    }
`;

export const CREATE_PYLS_SERVICE_PROFILE = gql`
    mutation createServiceProfile(
        $jwt: String!
        $serviceProfile: ServiceProfile!
    ) {
        createServiceProfile(jwt: $jwt, serviceProfile: $serviceProfile) {
            id
        }
    }
`;

export const CREATE_PYLS_APPLICATION = gql`
    mutation createPycomLoraServerApplication(
        $jwt: String!
        $application: PycomLoraServerApplication!
    ) {
        createPycomLoraServerApplication(jwt: $jwt, application: $application) {
            id
        }
    }
`;

export const CREATE_PYLS_DEVICE_PROFILE = gql`
    mutation createDeviceProfile(
        $jwt: String!
        $deviceProfile: DeviceProfile!
    ) {
        createDeviceProfile(jwt: $jwt, deviceProfile: $deviceProfile) {
            id
        }
    }
`;

export const CREATE_PYLS_DEVICE = gql`
    mutation createPycomLoraServerDevice(
        $jwt: String!
        $device: PycomLoraServerDevice!
    ) {
        createPycomLoraServerDevice(jwt: $jwt, device: $device)
    }
`;

export const CREATE_PYLS_DEVICE_KEYS = gql`
    mutation createPycomLoraServerDeviceKeys(
        $jwt: String!
        $deviceKeys: DeviceKeys!
    ) {
        createPycomLoraServerDeviceKeys(jwt: $jwt, deviceKeys: $deviceKeys)
    }
`;

export const ACTIVATE_ABP_DEVICE = gql`
    mutation activatePycomLoraServerDevice(
        $jwt: String!
        $deviceActivation: DeviceActivation!
    ) {
        activatePycomLoraServerDevice(
            jwt: $jwt
            deviceActivation: $deviceActivation
        )
    }
`;
