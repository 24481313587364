import PropTypes from 'prop-types';
import { PageHeader } from 'Components';
import * as Styled from './styled';

export const RootSettingsMenu = ({ children, links }) => {
    const allowedFeatures = JSON.parse(process.env.REACT_APP_ALLOWED_FEATURES);

    const activeLinks = links.filter((link) => {
        if (link.title === 'Sim Cards' && !allowedFeatures.includes('sim-cards')) {
            return false;
        }
        return true;
    });

    return (
        <Styled.Wrapper>
            <Styled.HeaderWrapper>
                <PageHeader title="Settings" />
                <Styled.List>
                    {activeLinks &&
                        activeLinks.map((link) => {
                            const Icon = link.icon;

                            return (
                                <Styled.Item
                                    key={link.title}
                                    to={link.route}
                                    data-cy={link.cyData}
                                >
                                    <Icon />
                                    <div>{link.title}</div>
                                </Styled.Item>
                            );
                        })}
                </Styled.List>
            </Styled.HeaderWrapper>
            <div>{children}</div>
        </Styled.Wrapper>
    );
};

RootSettingsMenu.propTypes = {
    children: PropTypes.node,
    links: PropTypes.array.isRequired,
};

RootSettingsMenu.defaultProps = {
    children: [],
};
