import styled from 'styled-components';

export const Button = styled.button`
    background: transparent;
    cursor: pointer;
    font-size: 1rem;
    margin: 2rem 0 1rem 0;
    &:hover {
        color: ${({ theme }) => theme.colors.active};
    }
`;
