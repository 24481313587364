import { Field, Form } from 'formik';
import PropTypes from 'prop-types';

import { TextInput, Checkbox } from '../../../inputs';
import { Select } from '../../../select';

import { regionOptions } from './config';
import * as Styled from './styled';

export const FormContent = ({ loading, isValid, dirty }) => (
    <Form>
        <Styled.FormContent>    
            <Styled.FormItem>
                <Styled.ItemLabel>
                    Region
                </Styled.ItemLabel>
                <Field
                    name="region"
                    component={Select}
                    values={regionOptions}
                    placeholder="Select Region"
                />
            </Styled.FormItem>
            <Styled.FormItem>
                <Styled.ItemLabel>
                    Access Key ID
                </Styled.ItemLabel>
                <Field
                    name="accessKey"
                    component={TextInput}
                />
            </Styled.FormItem>
            <Styled.FormItem>
                <Styled.ItemLabel>
                    Secret Access Key
                </Styled.ItemLabel>
                <Field
                    name="secretAccessKey"
                    component={TextInput}
                />
            </Styled.FormItem>
            <Styled.CheckboxWrapper>
                <Field
                    name="saveLocally"
                    component={Checkbox}
                    title="Save these settings encrypted on this computer for future use"
                />
            </Styled.CheckboxWrapper>
            <Styled.Button
                type="submit"
                loading={loading}
                disabled={!isValid || !dirty}
            >
                Login
            </Styled.Button>
        </Styled.FormContent>
    </Form>
);

FormContent.propTypes = {
    loading: PropTypes.bool.isRequired,
    isValid: PropTypes.bool.isRequired,
    dirty: PropTypes.bool.isRequired,
};
