import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

import { SearchInput } from '../inputs';
import { Pagination } from './pagination';
import { RangeDisplayItems } from '../rangeDisplayItems';

import * as Styled from './styled';

export const Table = ({
    columns,
    data,
    size,
    onRowClick,
    onSelect,
    searchOptions,
    pagination,
    onPaginationChange,
    rowKey,
    selectedRowKeys,
    maxBodyHeight,
    selectionType,
    isRangeShow,
    labelOfRange,
    initialRange,
    cyData,
    addClassToRow,
}) => {
    const [range, setRange] = useState({ type: '', value: `${initialRange}` });
    const [cursor, setCursor] = useState(null);

    const rowSelection = onSelect
        ? {
            onChange: onSelect,
            selectedRowKeys,
            type: selectionType,
        } : null;

    const onRow = onRowClick ? (record) => ({ onClick: () => onRowClick(record) }) : undefined;

    useEffect(() => {
        if (cursor || +range.value !== initialRange) {
            onPaginationChange({ cursors: { ...cursor }, items: +range.value });
        }
    }, [cursor, range]);

    return (
        <>
            {searchOptions && (
                <Styled.SearchWrapper>
                    <SearchInput
                        placeholder={searchOptions.placeholder}
                        onSearch={searchOptions.onSearch}
                    />
                </Styled.SearchWrapper>
            )}
            <Styled.TableWrapper
                $clickableRows={!!onRowClick}
                columns={columns}
                dataSource={data}
                pagination={false}
                size={size}
                rowSelection={rowSelection}
                onRow={onRow}
                rowKey={rowKey}
                scroll={{ y: maxBodyHeight }}
                data-cy={cyData}
                rowClassName={addClassToRow}
            />
            <Styled.TableFooter>
                {pagination && (
                    <Pagination {...pagination} onChange={setCursor} />
                )}
                {isRangeShow && (
                    <RangeDisplayItems
                        title={labelOfRange}
                        handleRange={setRange}
                        selectedItem={+range.value}
                    />
                )}
            </Styled.TableFooter>
        </>
    );
};

Table.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array,
    size: PropTypes.oneOf(['small', 'default', 'middle']),
    onRowClick: PropTypes.func,
    onSelect: PropTypes.func,
    searchOptions: PropTypes.shape({
        onSearch: PropTypes.func,
        placeholder: PropTypes.string,
    }),
    pagination: PropTypes.shape({
        hasNextPage: PropTypes.bool,
        hasPreviousPage: PropTypes.bool,
        firstCursor: PropTypes.string,
        lastCursor: PropTypes.string,
    }),
    onPaginationChange: PropTypes.func,
    rowKey: PropTypes.string,
    selectedRowKeys: PropTypes.array,
    maxBodyHeight: PropTypes.number,
    selectionType: PropTypes.string,
    handleRange: PropTypes.func,
    isRangeShow: PropTypes.bool,
    selectedItem: PropTypes.shape({
        type: PropTypes.string,
        value: PropTypes.string,
    }),
    labelOfRange: PropTypes.string,
    initialRange: PropTypes.number,
    cyData: PropTypes.string,
    addClassToRow: PropTypes.func,
};

Table.defaultProps = {
    data: [],
    size: 'small',
    onRowClick: null,
    onSelect: null,
    searchOptions: null,
    pagination: null,
    onPaginationChange: () => { },
    handleRange: () => { },
    rowKey: '_id',
    selectedRowKeys: undefined,
    maxBodyHeight: undefined,
    selectionType: 'checkbox',
    isRangeShow: false,
    selectedItem: {},
    labelOfRange: '',
    initialRange: 10,
    cyData: '',
    addClassToRow: null,
};
