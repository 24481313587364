import { WIFI, LORA, LTE, ETHERNET, SIGFOX } from 'Constants';

const networksConfigInitial = [
    {
        label: 'Wi-Fi',
        key: WIFI,
        checked: false,
    },
    {
        label: 'LoRa',
        key: LORA,
        checked: false,
    },
    {
        label: 'LTE',
        key: LTE,
        checked: false,
    },
    {
        label: 'Ethernet',
        key: ETHERNET,
        checked: false,
    },
    {
        label: 'Sigfox',
        key: SIGFOX,
        checked: false,
    },
];

export const initializeNetworks = (networks, allowedNetworks = []) => {
    const initialNetworks = allowedNetworks.length
        ? [...networksConfigInitial].filter((item) =>
              allowedNetworks.includes(item.key.toLowerCase()))
        : [...networksConfigInitial];

    if (!networks || !networks.length) {
        return initialNetworks;
    }

    const networksConfigCopy = [...initialNetworks];
    const result = networks.map((networkKey) => {
        const selectedIndex = networksConfigCopy.findIndex(
            (network) => network.key === networkKey,
        );
        return {
            ...networksConfigCopy.splice(selectedIndex, 1)[0],
            checked: true,
        };
    });

    return [...result, ...networksConfigCopy];
};
