import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    margin-top: 20px;
`;

export const TableHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
`;

export const IconWrapper = styled.span`
    font-size: 20px;
    vertical-align: middle;
    cursor: pointer;
`;
