import PropTypes from 'prop-types';

import * as Styled from './styled';

export const SimCardWidgetBox = ({ title, children }) => {
    const transformedTitle = title.toUpperCase();

    return (
        <Styled.Wrapper>
            <Styled.Header>
                {transformedTitle}
            </Styled.Header>
            <Styled.BoxWrapper>
                {children}
            </Styled.BoxWrapper>
        </Styled.Wrapper>
    );
};

SimCardWidgetBox.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};
