/* eslint-disable no-bitwise */
export class SoftServerService {
    static async getDeviceInfo(wmac, smac) {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        const options = {
            method: 'POST',
            headers,
            body: JSON.stringify({
                wmac,
                smac,
                toolversion:
                    process.env.REACT_APP_PYCOM_FIRMWARE_UPDATER_TOOL_VERSION,
            }),
        };
        try {
            const response = await fetch(
                `${process.env.REACT_APP_PYCOM_FIRMWARE_UPDATER_BASE_URL}/device/info`,
                options,
            );
            if (!response.ok) {
                throw new Error('Http error status');
            }
            const result = await response.json();
            return result;
        } catch (error) {
            console.warn(error);
        }
    }

    static async getDeviceType(wmac, smac = 'FFFFFFFFFFFFFFFF') {
        const deviceInfo = await this.getDeviceInfo(wmac, smac);
        if (deviceInfo && deviceInfo.firmware_type) {
            const deviceType = deviceInfo.firmware_type.split('.')[0];
            return {
                deviceType,
                deviceLongName: deviceInfo.firmware_type,
                remoteConfig: deviceInfo.binary,
                smac: deviceInfo.smac,
                binary: deviceInfo.binary,
            };
        }
        return {};
    }

    static async getListOfFirmwares(deviceInfo, firmwareType) {
        const options = {
            method: 'GET',
        };
        const query = `tool_version=${process.env.REACT_APP_PYCOM_FIRMWARE_UPDATER_TOOL_VERSION}&key=${deviceInfo}&type=${firmwareType}`;

        try {
            const response = await fetch(
                `${process.env.REACT_APP_PYCOM_FIRMWARE_UPDATER_BASE_URL}/findupgrade?redirect=false&strict=true&version=list&${query}`,
                options,
            );
            if (!response.ok) {
                throw new Error('Http error status');
            }
            const result = await response.json();
            return result;
        } catch (error) {
            console.warn(error);
        }

        return [];
    }

    static async getFirmwareDownloadDetails(
        device,
        firmwareType,
        firmwareVersion,
    ) {
        const option = {
            method: 'GET',
        };
        const query = `module=${device}&type=${firmwareType.toLowerCase()}&version=${firmwareVersion}`;
        try {
            const response = await fetch(
                `${process.env.REACT_APP_PYCOM_FIRMWARE_UPDATER_BASE_URL}/findupgrade?${query}`,
                option,
            );
            if (!response.ok) {
                throw new Error('Http error status');
            }
            const result = await response.json();
            return result;
        } catch (error) {
            console.warn(error);
        }

        return [];
    }

    static versionToInt(version) {
        const knownTypes = ['a', 'b', 'rc', 'r'];
        const versionParts = version.split('.');
        const dots = versionParts.length - 1;
        if (dots !== 2 && dots !== 3) {
            return null;
        }
        if (dots === 2) {
            versionParts[3] = 'r0';
        }
        const re = /([\D]+)([\d]+)/;
        const releaseTypeNumber = versionParts[3].match(re);
        let type = releaseTypeNumber[1];
        versionParts[3] = releaseTypeNumber[2]; // put the number in the array
        type = knownTypes.indexOf(type);
        if (type === -1) {
            return null;
        }
        // convert the numbers to integers
        versionParts.forEach((val, idx, arr) => {
            /*eslint no-param-reassign: "error"*/
            arr[idx] = parseInt(val, 10);
        });
        // number of bits per position: 6.7.10.2.7
        let intVersion = versionParts[0];

        intVersion = (intVersion << 7) | versionParts[1];
        intVersion = (intVersion << 10) | versionParts[2];
        intVersion = (intVersion << 2) | type;
        intVersion = (intVersion << 7) | versionParts[3];
        return intVersion;
    }
}
