import PropTypes from 'prop-types';

import { ReactComponent as InfoIcon } from 'Assets/network/support.svg';
import { Button } from 'Components';

import * as Styled from './styled';

export const WizardComponentBox = ({
    title,
    description,
    children,
    nextStep,
    previousStep,
    isFirstStep,
    isLastStep,
    disableNext,
    submit,
}) => {
    const transformedTitle = title.toUpperCase();
    const backButtonTitle = isFirstStep ? 'Cancel' : 'Back';
    const nextButtonTitle = isLastStep ? 'Save' : 'Next';

    const nextButtonProps = submit ? { type: 'submit' } : { onClick: nextStep };

    return (
        <Styled.Wrapper>
            <Styled.BodyWrapper>
                <Styled.Header>
                    {transformedTitle}
                </Styled.Header>
                <Styled.DescriptionWrapper>
                    <InfoIcon />
                    <Styled.Description>
                       {description}
                    </Styled.Description>
                </Styled.DescriptionWrapper>
                {children}
            </Styled.BodyWrapper>
            <Styled.NavButtons>
                <Button onClick={previousStep} buttonType="transparent">
                    {backButtonTitle}
                </Button>
                <Button {...nextButtonProps} disabled={disableNext}>
                    {nextButtonTitle}
                </Button>
            </Styled.NavButtons>
        </Styled.Wrapper>
    );
};

WizardComponentBox.defaultProps = {
    isFirstStep: false,
    isLastStep: false,
    disableNext: false,
    nextStep: () => {},
    submit: false,
};

WizardComponentBox.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    nextStep: PropTypes.func,
    previousStep: PropTypes.func.isRequired,
    isFirstStep: PropTypes.bool,
    isLastStep: PropTypes.bool,
    disableNext: PropTypes.bool,
    submit: PropTypes.bool,
};
