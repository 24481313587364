import { useState, useEffect } from 'react';

import { mqttService } from 'Services';
import { TYPE_PYBYTES } from 'Constants';

export const useBash = (processOptions) => {
    const [commands, setCommands] = useState([]);
    const [commandIndex, setCommandIndex] = useState(0);
    const [mqttInstance, setMqttInstance] = useState(null);

    useEffect(() => {
        if (processOptions.deviceToken) {
            const mqtt = mqttService(processOptions);
            mqtt.ping();
            setMqttInstance(mqtt);
        }

        return () => {
            if (mqttInstance) {
                mqttInstance.closeMqttClient();
            }
        };
    }, [processOptions.deviceToken]);

    if (!mqttInstance) {
        return null;
    }

    return {
        execute: (input, currentState) => {
            const commandsSet = [...commands];
            commandsSet.push(input);

            setCommands(commandsSet);
            setCommandIndex(commandsSet.length);

            mqttInstance.sendMessage(TYPE_PYBYTES, input);
            processOptions.mqttResponseCallback('');
            return {
                ...currentState,
                currentHistory: currentState.currentHistory.concat({
                    isCommand: true,
                    value: input,
                }),
            };
        },
        getPreviousCommand: () => {
            const newIndex = commandIndex - 1;
            setCommandIndex(newIndex);
            return commands[newIndex];
        },
        getNextCommand: () => {
            const newIndex = commandIndex + 1;
            setCommandIndex(newIndex);
            return commands[newIndex];
        },
        hasPreviousCommand: () => commandIndex !== 0,
        hasNextCommand: () => commands.length - 1 !== commandIndex,
    };
};
