import { Tooltip } from 'antd';

import { ReactComponent as WarningIcon } from 'Assets/icons/support.svg';

import * as Styled from '../styled';

export const NotValidWarning = () => (
    <Tooltip title="Not all data is valid. Invalid data is hidden">
        <Styled.NotValidWarning>
            <WarningIcon />
        </Styled.NotValidWarning>
    </Tooltip>
);
