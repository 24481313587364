import * as Yup from 'yup';

import { GOOGLE_CLOUD_REGIONS } from 'Constants';
import { ValidationService } from 'Services';

export const regionOptions = GOOGLE_CLOUD_REGIONS.map((region) => ({ value: region.code, label: region.name }));

export const initialValues = {
    region: '',
    projectId: '',
};

export const validationSchema = Yup.object().shape({
    region: Yup.string().required(ValidationService.getRequiredError('region')),
    projectId: Yup.string().required(ValidationService.getRequiredError('project ID')),
});
