import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    height: 40px;
    position: relative;
`;

export const Field = styled.input`
    width: 293px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    padding: 9px 35px 9px 15px;
    color: ${({ theme }) => theme.colors.text};
    font-size: 14px;
    line-height: 1.57;

    &:disabled {
        border: transparent;
        cursor: not-allowed;
        user-select: none;
    }
`;

export const Icon = styled.div`
    position: absolute;
    top: 50%;
    left: 254px;
    height: 34px;
    width: 34px;
    transform: translateY(-50%);
    cursor: pointer;

    svg {
        height: 18px;
        width: 18px;
        margin: 8px;
    }

    &_disabled {
        cursor: not-allowed;
        pointer-events: none;
    }
`;
