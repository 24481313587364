import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import { DISABLED_FEATURES } from 'Constants';
import { FeatureDisabled } from '../featureDisabled';

export const RouteWrapper = ({
    exact,
    path,
    component,
    render,
}) => {
    const disabledFeature = DISABLED_FEATURES.filter(
        (feature) => path.match(new RegExp(`/${feature}(/)?`)),
    )[0];

    if (disabledFeature) {
        return (
            <Route
                exact={exact}
                path={path}
                key={path}
                render={() => <FeatureDisabled featureName={disabledFeature} />}
            />
        );
    }

    return (
        <Route
            exact={exact}
            path={path}
            key={path}
            component={component}
            render={render}
        />
    );
};

RouteWrapper.propTypes = {
    exact: PropTypes.bool,
    path: (PropTypes.string || PropTypes.arrayOf(PropTypes.string)).isRequired,
    component: PropTypes.func,
    render: PropTypes.func,
};

RouteWrapper.defaultProps = {
    component: undefined,
    render: undefined,
    exact: undefined,
};
