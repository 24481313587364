import { COLORIZER_ACTION_TYPES } from './actionTypes';

export const setUpdatedFiles = (files) => ({
    type: COLORIZER_ACTION_TYPES.SET_UPDATED_FILES,
    payload: { files },
});

export const setResetMD5 = (reset) => ({
    type: COLORIZER_ACTION_TYPES.SET_RESET_MD5,
    payload: { reset },
});
