import styled from 'styled-components';

export const FormContent = styled.div`
    max-width: 650px;
`;

export const Title = styled.div`
    color: ${({ theme }) => theme.colors.title};
    margin-top: 30px;
    margin-bottom: 35px;
    font-size: 22px;
    font-weight: bold;
`;

export const FormItem = styled.div`
    display: flex;
    margin-bottom: 10px;

    & > span {
        color: ${({ theme }) => theme.colors.title};
        padding-top: 8px;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.38;
    }

    & > div {
        flex: 1;
    }

    input,
    textarea {
        width: 100%;
    }
`;

export const ItemLabel = styled.span`
    width: 112px;
    margin-right: 15px;
`;

export const NotificationWrapper = styled.div`
    display: flex;
    margin-top: 40px;
`;

export const NotificationIcon = styled.div`
    margin-right: 14px;
    font-size: 20px;
    color: ${({ theme }) => theme.colors.title};
    line-height: 0;
`;

export const NotificationLabel = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-size: 16px;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    margin-top: 55px;

    
    & > * {
        margin-right: 30px;
    }
`;
