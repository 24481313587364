import PropTypes from 'prop-types';
import moment from 'moment';

import { units, icons } from './config';
import { EmptyWidget } from '../emptyWidget';

import * as StyledGeneral from '../styled';
import * as Styled from './styled';

export const WeatherWidget = ({ data, isEmpty, unit, title }) => {
    if (!data || isEmpty) {
        return <EmptyWidget title={title} />;
    }

    const { current, hourly } = data;

    const renderForecast = () => (
        <>
            <Styled.ForecastItemNow>
                Now
                <div>
                    {icons[current.icon]}
                </div>
                <Styled.ForecastTemperatureNow>
                    {current.temp}°
                </Styled.ForecastTemperatureNow>
            </Styled.ForecastItemNow>
            {hourly.map((hour) => (
                <Styled.ForecastItem key={hour.time}>
                    {moment(hour.time).format('ha')}
                    <div>
                        {icons[hour.icon]}
                    </div>
                    <Styled.ForecastTemperature>
                        {current.temp}°
                    </Styled.ForecastTemperature>
                </Styled.ForecastItem>
            ))}
        </>
    );

    return (
        <>
            <StyledGeneral.ChartTitle>
                {title}
            </StyledGeneral.ChartTitle>
            <Styled.Container>
                <Styled.Now>
                    <div>
                        <Styled.NowTemperature>
                            {current.temp}
                            {units[unit] ?? units.imperial}
                        </Styled.NowTemperature>
                        {current.description}
                    </div>
                    {icons[current.icon]}
                </Styled.Now>
                <Styled.Forecast>
                    {renderForecast()}
                </Styled.Forecast>
            </Styled.Container>
        </>
    );
};

WeatherWidget.propTypes = {
    data: PropTypes.object.isRequired,
    unit: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    isEmpty: PropTypes.bool,
};

WeatherWidget.defaultProps = {
    isEmpty: false,
};
