import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { ALTITUDE_UNITS, GET_APPLICATION_LAST_MESSAGES } from 'Constants';
import { getStringifiedPayload, calculateDataForMeters, calculateDataForYards } from 'Utils';
import { useMessageSubscription } from 'Hooks';

import { AltitudeWidget } from '../../../../widgets';

export const Altitude = ({ chartId, title, chartSettings }) => {
    const { data, loading, refetch } = useQuery(
        GET_APPLICATION_LAST_MESSAGES,
        {
            variables: { chartId },
        },
    );
    const lastMessages = data?.getApplicationLastMessages ?? [];

    const subscribeData = useMemo(() => {
        const deviceTokens = lastMessages.map((messageObj) => messageObj.device.token);
        const messagePin = lastMessages[0]?.lastMessage?.messagePin;

        return { deviceTokens, messagePin };
    }, [lastMessages]);

    useMessageSubscription(subscribeData.deviceTokens, subscribeData.messagePin, refetch);

    const chartData = useMemo(() => {
        if (!lastMessages?.length) {
            return;
        }

        const rawValue = getStringifiedPayload(lastMessages[0].lastMessage.payload);

        // recalculate the value depending on the number size
        switch (chartSettings.unit) {
            case ALTITUDE_UNITS.METER:
                return calculateDataForMeters(rawValue);
            case ALTITUDE_UNITS.YARD:
                return calculateDataForYards(rawValue);
            default:
                break;
        }
    }, [lastMessages]);

    return (
        <AltitudeWidget
            data={chartData}
            isEmpty={loading || !lastMessages.length}
            title={title}
        />
    );
};

Altitude.propTypes = {
    chartId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    chartSettings: PropTypes.object.isRequired,
};
