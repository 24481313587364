export const getSessionFormApi = async () => {
    const headers = new Headers();

    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/authenticated`, {
            headers,
            credentials: 'include',
        });
        if (response.ok) {
            const res = await response.json();
            return res;
        }
        throw new Error('Http error status');
    } catch (error) {
        console.warn(error);
    }
};
