import { useMemo } from 'react';

import { SAMPLING_TYPES } from 'Constants';

export const useSubmitText = (samplingType, isPinging, isSampling) => {
    const result = useMemo(
        () => {
            if (isPinging) {
                return 'Ping';
            }

            if (isSampling) {
                return 'Creating samples';
            }

            if (samplingType === SAMPLING_TYPES.TEST) {
                return 'Create testing sample';
            }
        
            return 'Create sample';
        },
        [samplingType, isPinging, isSampling],
    );

    return result;
};
