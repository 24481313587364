import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { ROUTES } from 'Constants';
import { Button } from 'Components';

import * as Styled from './styled';

export const ProjectConfiguration = ({ nextStep, prevStep, project }) => {
    const location = useLocation();

    const configurationLink = location.pathname.split('/').slice(0, -2).join('/')
        + ROUTES.projects.projectDetails.configuration;

    const config = { networks: project.networks };

    if (project.wifi) {
        const { ssid, password, secureType } = project.wifi;
        config.wifi = { ssid, password, secureType };
    }

    if (project.lte) {
        const { apn, carrier, cid, protocol, reset } = project.lte;
        config.lte = { apn, carrier, cid, protocol, reset };
    }

    if (project.loraServer) {
        config.lora = { loraServer: project.loraServer, loraType: project.loraType };

        if (project.loraRegion) {
            config.lora.loraRegion = project.loraRegion;
        }
    }

    if (project.ethernet) {
        const { name, autoSettings, ipAddress, subnetMask, router, dnsServer, dnsServer2 } = project.ethernet;
        if (autoSettings) {
            config.ethernet = { name, autoSettings };
        } else {
            config.ethernet = { name, autoSettings, ipAddress, subnetMask, router, dnsServer, dnsServer2 };
        }
    }
    return (
        <>
            <Styled.Title>
                Project configuration
            </Styled.Title>
            <Styled.NotificationWrapper>
                <p>
                    This is your current read only project configuration.
                    From these values pybytes_config.json will be created.
                </p>
                <p>
                    You can change this values on
                    <Styled.Link to={configurationLink}>
                        Project Configuration Page
                    </Styled.Link>
                </p>
            </Styled.NotificationWrapper>
            <Styled.Code>
                {JSON.stringify(config, null, 2)}
            </Styled.Code>
            <Styled.ButtonsWrapper>
                <Button
                    buttonType="transparent"
                    onClick={prevStep}
                >
                    Back
                </Button>
                <Button onClick={nextStep}>
                    Next
                </Button>
            </Styled.ButtonsWrapper>
        </>
    );
};

ProjectConfiguration.propTypes = {
    prevStep: PropTypes.func.isRequired,
    nextStep: PropTypes.func.isRequired,
    project: PropTypes.object,
};

ProjectConfiguration.defaultProps = {
    project: {},
};
