import { useState, useRef, useEffect } from 'react';
import { useQuery, useApolloClient, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import {
    GET_ALL_DEVICES_QUERY,
    GET_INTEGRATION_BY_ID_QUERY,
    GET_GOOGLE_DEVICE_BY_INTEGRATION_ID,
    SAVE_GOOGLE_DEVS_MUTATION,
    REMOVE_GOOGLE_DEVICES_MUTATION,
    SEND_GOOGLE_TEST_MESSAGE,
} from 'Constants';
import {
    showToastError,
    showToastSuccess,
} from 'Utils';
import {
    Button,
    Modal,
    DeleteModal,
    GoogleIntegrationDefinition,
    GoogleAuth,
} from 'Components';

import { DevicesTable } from './devicesTable';
import { DetailsBlock } from './detailsBlock';

import * as Styled from './styled';

const DEFAULT_TEST_MESSAGE = 'Send test message';

export const GoogleDetails = ({
    details,
    deleteIntegration,
    refetchIntegrationDetails,
    isDeleteLoading,
}) => {
    const apolloClient = useApolloClient();
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [accountData, setAccountData] = useState({});
    const [testLoading, setTestLoading] = useState(false);
    const [testMessage, setTestMessage] = useState(DEFAULT_TEST_MESSAGE);
    const [deleteModalOpened, toggleDeleteModal] = useState(false);
    const [isEditionMode, setIsEditionMode] = useState(false);
    const timeoutRef = useRef(null);

    const { data: allDevicesData } = useQuery(
        GET_ALL_DEVICES_QUERY,
        { fetchPolicy: 'cache-and-network' },
    );
    const allDevices = allDevicesData?.device ?? [];

    const { data, refetch: refetchGoogleDevices } = useQuery(
        GET_GOOGLE_DEVICE_BY_INTEGRATION_ID,
        {
            variables: { integrationId: details?._id },
            skip: !details._id,
            fetchPolicy: 'cache-and-network',
        },
    );
    const googleDevices = data?.getGoogleDevicesByIntegrationId || [];

    const [saveGoogleDevicesMutation] = useMutation(
        SAVE_GOOGLE_DEVS_MUTATION,
        { refetchQueries: [GET_GOOGLE_DEVICE_BY_INTEGRATION_ID, GET_INTEGRATION_BY_ID_QUERY] },
    );

    const [removeGoogleDevicesMutation] = useMutation(
        REMOVE_GOOGLE_DEVICES_MUTATION,
        { refetchQueries: [GET_GOOGLE_DEVICE_BY_INTEGRATION_ID, GET_INTEGRATION_BY_ID_QUERY] },
    );

    useEffect(() => () => clearTimeout(timeoutRef.current), []);

    const sendTestMessage = async () => {
        setTestLoading(true);

        try {
            const { data: { sendGoogleTestMessage } } = await apolloClient.query({
                query: SEND_GOOGLE_TEST_MESSAGE,
                variables: { integrationId: details?._id },
                fetchPolicy: 'network-only',
            });

            setTestMessage(sendGoogleTestMessage.message);

            timeoutRef.current = setTimeout(() => setTestMessage(DEFAULT_TEST_MESSAGE), 5000);
        } catch (error) {
            showToastError(error.message);
        } finally {
            setTestLoading(false);
        }
    };

    const deleteIntegrationHandler = async () => {
        try {
            await deleteIntegration({ variables: { data: { accessToken: accountData.accessToken } } });

            showToastSuccess('Google Cloud integration deleted successfully');
        } catch (error) {
            showToastError(error.message);
        }
    };

    const onEdit = async (values) => {
        setIsLoading(true);
        const { devices } = values;
        const { region, projectId, registryId, topic } = googleDevices[0] ?? {};

        const devicesToAdd = [];
        const devicesToRemove = [];

        allDevices.forEach((device, index) => {
            const { token, description } = device;

            const relatedGoogleDevice = googleDevices.find(
                (item) => item.deviceToken === device.token,
            );

            if (relatedGoogleDevice && !devices[index]) {
                devicesToRemove.push(token);
            }

            if (!relatedGoogleDevice && devices[index]) {
                devicesToAdd.push({
                    metadata: {
                        deviceName: description,
                    },
                    deviceToken: token,
                    projectId,
                    registryId,
                    region,
                    topic,
                });
            }
        });

        try {
            if (devicesToAdd.length) {
                await saveGoogleDevicesMutation({
                    variables: {
                        devices: devicesToAdd,
                        integrationId: details?._id,
                        accessToken: accountData.accessToken,
                    },
                });
            }

            if (devicesToRemove.length) {
                await removeGoogleDevicesMutation({
                    variables: {
                        tokens: devicesToRemove,
                        integrationId: details?._id,
                        accessToken: accountData.accessToken,
                    },
                });
            }
        } catch (error) {
            // TODO: change success and error messages after fix on BE side
            console.log(error);
            // showToastError(error.message);
        } finally {
            refetchGoogleDevices();
            refetchIntegrationDetails();

            showToastSuccess('Integration edited successfully');
            setIsLoading(false);
            setIsEditionMode(false);
        }
    };

    if (isEditionMode) {
        const initialValues = {
            deviceTokens: googleDevices.map((item) => item.deviceToken),
        };
        const firstDevice = googleDevices[0] ?? {};
        const integrationData = {
            registryId: firstDevice.registryId,
            projectId: firstDevice.projectId,
            region: firstDevice.region,
        };

        return (
            <GoogleIntegrationDefinition
                formValues={integrationData}
                allDevices={allDevices}
                onSubmit={onEdit}
                initialValues={initialValues}
                submitText="Edit"
                prevStep={() => setIsEditionMode(false)}
                isLoading={isLoading}
            />
        );
    }

    return (
        <>
            <DevicesTable devicesArr={googleDevices} />
            {!!googleDevices.length && <DetailsBlock devicesArr={googleDevices} />}
            <GoogleAuth
                isSignedIn={isSignedIn}
                setIsSignedIn={setIsSignedIn}
                accountData={accountData}
                setAccountData={setAccountData}
                isAlignedLeft
                hasOnlyButtons
            />
            <Styled.ButtonsWrapper>
                <Button
                    disabled={!isSignedIn}
                    buttonType="transparent"
                    onClick={() => setIsEditionMode(true)}
                >
                    Edit
                </Button>
                <Button
                    disabled={!isSignedIn}
                    onClick={() => toggleDeleteModal(true)}
                >
                    Delete
                </Button>
            </Styled.ButtonsWrapper>
            <Styled.BigButton
                loading={testLoading}
                onClick={sendTestMessage}
            >
                {testMessage}
            </Styled.BigButton>
            <Modal
                isOpened={deleteModalOpened}
                handleClose={() => toggleDeleteModal(false)}
            >
                <DeleteModal
                    deleteAction={deleteIntegrationHandler}
                    cancelAction={() => toggleDeleteModal(false)}
                    title="Are you sure you want to delete this Integration?"
                    description={`This integration will be deleted from Pybytes AND Google IoT,
                        that means that the messages sent from your
                        devices won't be transmitted to Google anymore.
                        All the devices inside this registry will be deleted,
                        the registry won't be removed.`}
                    loading={isDeleteLoading}
                />
            </Modal>
        </>
    );
};

GoogleDetails.defaultProps = {
    isDeleteLoading: false,
};

GoogleDetails.propTypes = {
    details: PropTypes.object.isRequired,
    deleteIntegration: PropTypes.func.isRequired,
    refetchIntegrationDetails: PropTypes.func.isRequired,
    isDeleteLoading: PropTypes.bool,
};
