import { useContext } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';

import { Map as ReactGoogleMap } from '../../map';
import { EmptyWidget } from '../emptyWidget';
import { NotValidWarning } from '../notValidWarning';

import * as StyledGeneral from '../styled';
import * as Styled from './styled';

export const MapWidget = ({
    location,
    locations,
    isEmpty,
    title,
    isDataBroken,
    isSomeDataBroken,
    onUpdateLocation,
}) => {
    const styledTheme = useContext(ThemeContext);
    const color = styledTheme.colors.error;
    const isMapShown = (!!location || !!locations.length) && !isEmpty && !isDataBroken;

    return (
        <>
            <StyledGeneral.ChartTitle>
                {title}
                {isSomeDataBroken && <NotValidWarning />}
            </StyledGeneral.ChartTitle>
            <Styled.MapContainer className="mapContainer extended heightExtended noMargin">
                {isMapShown && (
                    <ReactGoogleMap locations={locations} location={location} colorOfDefaultMarker={color} />
                )}
                {!isMapShown && (
                    <EmptyWidget isDataBroken={isDataBroken} />
                )}
                <Styled.UpdateButton onClick={onUpdateLocation}>Update Location</Styled.UpdateButton>
            </Styled.MapContainer>

        </>
    );
};

MapWidget.propTypes = {
    title: PropTypes.string.isRequired,
    isEmpty: PropTypes.bool,
    location: PropTypes.object,
    locations: PropTypes.array,
    onUpdateLocation: PropTypes.func,
    isDataBroken: PropTypes.bool,
    isSomeDataBroken: PropTypes.bool,
};

MapWidget.defaultProps = {
    isEmpty: false,
    location: null,
    locations: [],
    onUpdateLocation: null,
    isDataBroken: false,
    isSomeDataBroken: false,
};
