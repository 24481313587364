import styled, { css } from 'styled-components';
import { Button as AntButton } from 'antd';

const buttonColors = {
    background: {
        filled: 'active',
        transparent: 'transparent',
    },
    border: {
        filled: 'active',
        transparent: 'border',
    },
    text: {
        filled: 'activeText',
        transparent: 'title',
    },
};

const colorStyles = css`
    background-color: ${({ theme, $buttonType }) => theme.colors[buttonColors.background[$buttonType]]
        || $buttonType};
    border: 1px solid ${({ theme, $buttonType }) => theme.colors[buttonColors.border[$buttonType]]};
    color: ${({ theme, $buttonType }) => theme.colors[buttonColors.text[$buttonType]]};
`;

export const Button = styled(AntButton)`
    height: auto;
    display: flex;
    align-items: center;
    padding: 10px 30px;
    border-radius: 4px;
    cursor: pointer;
    justify-content: center;
    font-size: 14px;
    ${colorStyles}

    &:disabled {
        opacity: 0.5;
        ${colorStyles}

        &:hover {
            ${colorStyles}
        }
    }

    &:hover,
    &:focus {
        ${colorStyles}
    }
`;
