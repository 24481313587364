import { useEffect, useState, useContext } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { AwsService } from 'Services';
import { AWS_REGIONS } from 'Constants';
import { showToastError, showToastSuccess, encrypt, decrypt } from 'Utils';
import { GlobalContext } from 'Context';

import { initialValues, validationSchema } from './config';
import { FormContent } from './formContent';

export const AwsLogin = ({ setConnectionMask, setAwsService, nextStep, initialEndpointAddress }) => {
    const globalContext = useContext(GlobalContext);
    const session = globalContext.session;
    const [loading, setLoading] = useState(false);

    const onSubmit = async (values) => {
        setLoading(true);

        const options = {
            accessKeyId: values.accessKey,
            secretAccessKey: values.secretAccessKey,
            region: values.region,
        };

        try {
            const awsService = new AwsService(options);
            const result = await awsService.awsiot.describeEndpoint().promise();
            
            if (!result.endpointAddress) {
                showToastError('Unable to get end point address, unable to connect to AWS.');
                return;
            }

            showToastSuccess('Connected to AWS');

            const selectedRegion = AWS_REGIONS.find((region) => region.code === values.region);

            const connectionMask = {
                hubCode: result.endpointAddress.split('.')[0],
                region: selectedRegion.code,
                regionName: selectedRegion.name,
                endpointAddress: result.endpointAddress,
                accessKeyId: values.accessKey,
                secretAccessKey: values.secretAccessKey,
            };

            setConnectionMask(connectionMask);
            setAwsService(awsService);

            if (values.saveLocally) {
                const { region, accessKeyId, secretAccessKey, endpointAddress } = connectionMask;

                const credentials = {
                    region,
                    accessKeyId,
                    secretAccessKey,
                };
                const encrypted = await encrypt(btoa(JSON.stringify(credentials)), session.id);
                localStorage.setItem(`AWSlogin-${endpointAddress}`, encrypted);
            }

            nextStep();
        } catch (error) {
            showToastError(`Connection failed: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    useEffect(async () => {
        const rawCredentials = localStorage.getItem(`AWSlogin-${initialEndpointAddress}`);

        if (!isEmpty(rawCredentials)) {
            try {
                const decrypted = await decrypt(rawCredentials, session.id);
                const { region, accessKeyId, secretAccessKey } = JSON.parse(atob(decrypted));

                onSubmit({
                    region,
                    accessKey: accessKeyId,
                    secretAccessKey,
                });
            } catch (error) {
                localStorage.removeItem(`AWSlogin-${initialEndpointAddress}`);
            }
        }
    }, []);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {(props) => <FormContent {...props} loading={loading} />}
        </Formik>
    );
};

AwsLogin.propTypes = {
    setConnectionMask: PropTypes.func.isRequired,
    setAwsService: PropTypes.func.isRequired,
    nextStep: PropTypes.func.isRequired,
    initialEndpointAddress: PropTypes.string,
};

AwsLogin.defaultProps = {
    initialEndpointAddress: '',
};
