import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { ROUTES } from 'Constants';

import * as Styled from './styled';

export const DevicesTable = ({ thingsArr }) => {
    const history = useHistory();

    const deviceOnClick = (token) => {
        if (!token) {
            return;
        }
        const { main, deviceDetails } = ROUTES.devices;
        history.push(`${main}/${token}${deviceDetails.dashboard}`);
    };

    if (!thingsArr.length) {
        return null;
    }

    return (
        <Styled.DetailsWrapper>
            <Styled.DetailsHead disabled>
                <span>Details</span>
                <span>Token</span>
            </Styled.DetailsHead>
            {thingsArr.map((item) => (
                <Styled.DetailsLine
                    disabled={!item.device}
                    key={item.thing._id}
                    onClick={() => deviceOnClick(item.device?.token)}
                >
                    <span>
                        {item.device?.description ?? 'Deleted device'}
                    </span>
                    <span>
                        {item.device?.token ?? item.thing.deviceToken}
                    </span>
                </Styled.DetailsLine>
            ))}
        </Styled.DetailsWrapper>
    );
};

DevicesTable.propTypes = {
    thingsArr: PropTypes.array.isRequired,
};
