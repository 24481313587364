import styled from 'styled-components';

export const ResultsWrapper = styled.div`
    margin: 30px 0;
    display: flex;
    align-items: center;
`;

export const Result = styled.div`
    margin-left: 20px;
    display: flex;
    align-items: center;

    &:first-child {
        margin-left: 0;
    }
`;

export const ResultValue = styled.div`
    color: ${({ theme }) => theme.colors.active};
`;

export const ResultLabel = styled.div`
    text-transform: uppercase;
    margin-left: 5px;
`;

export const LoaderWrapper = styled.div`
    position: relative;
    margin-left: 10px;
    width: 20px;
    height: 20px;
`;

export const LogsTitle = styled.h2`
    display: flex;
    align-items: center;
`;

export const LogsWrapper = styled.div`
    margin-bottom: 30px;
`;
