export const columns = [
    {
        title: 'Device name',
        dataIndex: 'description',
    },
    {
        title: 'Type',
        dataIndex: 'deviceType',
        render: (data) => data.toUpperCase(),
    },
];

export const mergeValues = (formValues, selectedIds, allDevices) => ({
    ...formValues,
    devices: selectedIds
        .map((id) => (
            {
                deviceId: id,
                token: allDevices.find((device) => (device._id === id))?.token,
            })),
});
