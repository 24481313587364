import * as Yup from 'yup';
import { v4 } from 'uuid';

import { ValidationService } from 'Services';

export const getInitialValues = (mlSamplesLength, modelDefinition, preProcessingStatus) => {
    const result = {
        trainingEpochs: 100,
        learningRate: 0.0001,
        minConfidenceThreshold: 0.8,
        layers: [
            {
                id: 0,
                layer: 'Input Layer',
                value: `${preProcessingStatus?.output?.SP?.samples?.[0]?.data?.[0]?.features.length * 3}`,
                type: 'input_layer',
                unit: 'features',
                edit: false,
            },
            {
                id: 2,
                layer: 'Dense Layer',
                value: '16',
                type: 'dense_layer',
                unit: 'neurons',
                edit: true,
            },
            {
                id: 3,
                layer: 'Dense Layer',
                value: '8',
                type: 'dense_layer',
                unit: 'neurons',
                edit: true,
            },
            {
                id: 1,
                layer: 'Output Layer',
                value: `${mlSamplesLength}`,
                type: 'output_layer',
                unit: 'classes',
                edit: false,
            },
        ],
    };

    const nn = modelDefinition?.blocks?.find((block) => block.id === 'NN');

    if (nn) {
        const untrainedModel = nn.untrained_nn_model;
        const denseLayers = untrainedModel.topology.layers
            .filter((layer) => layer.type === 'dense_layer')
            .map((layer) => ({
                id: v4(),
                layer: 'Dense Layer',
                value: layer.number_of_neurons,
                type: 'dense_layer',
                unit: 'neurons',
                edit: true,
            }));

        result.layers = [
            result.layers[0],
            ...denseLayers,
            result.layers.slice(-1)[0],
        ];
        result.trainingEpochs = untrainedModel.training_epochs;
        result.learningRate = untrainedModel.learning_rate;
        result.minConfidenceThreshold = untrainedModel.minimum_confidence_threshold;
    }

    return result;
};

export const validationSchema = Yup.object().shape({
    trainingEpochs: Yup.number()
        .typeError('Should be a number')
        .positive('Should be positive')
        .required(ValidationService.getRequiredError('training epochs')),
    learningRate: Yup.number()
        .typeError('Should be a number')
        .positive('Should be positive')
        .required(ValidationService.getRequiredError('learning rate')),
    minConfidenceThreshold: Yup.number()
        .typeError('Should be a number')
        .positive('Should be positive')
        .required(ValidationService.getRequiredError('confidence threshold')),
});
