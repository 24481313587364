const OPTIONAL_FEATURES = [
    'sim-cards',
    'pymakr',
    'machine-learning',
    'integrations',
    'public-api',
    'compilation',
];

const ALLOWED_FEATURES = process.env.REACT_APP_ALLOWED_FEATURES
    ? JSON.parse(process.env.REACT_APP_ALLOWED_FEATURES)
    : OPTIONAL_FEATURES;

export const DISABLED_FEATURES = OPTIONAL_FEATURES.filter((feature) => !ALLOWED_FEATURES.includes(feature));
