import PropTypes from 'prop-types';
import { Formik } from 'formik';

import { FormContent } from './formContent';
import { validationSchema } from './config';

import * as Styled from './styled';

export const ReleaseDescription = ({ formValues, prevStep, nextStep }) => {
    const onSubmit = (values) => {
        nextStep({ ...formValues, ...values });
    };

    const initialValues = {
        description: formValues.description || '',
    };

    return (
        <>
            <Styled.Title>
                Release description
            </Styled.Title>
            <Styled.NotificationWrapper>
                Create a short text summary, e.g. what code changes were done.
            </Styled.NotificationWrapper>
            <Styled.List>
                <li>optional</li>
                <li>max 512 characters</li>
            </Styled.List>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {(props) => <FormContent {...props} prevStep={prevStep} />}
            </Formik>
        </>
    );
};

ReleaseDescription.propTypes = {
    formValues: PropTypes.object.isRequired,
    prevStep: PropTypes.func.isRequired,
    nextStep: PropTypes.func.isRequired,
};
