import {
    MAJOR_CODE_SUCCESS,
    MINOR_CODE_SUCCESS,
    MAJOR_CODE_REQUEST_FAILED,
    MAJOR_CODE_VODAFONE_ERROR,
    MINOR_CODE_SIM_SAME_STATE,
    MINOR_CODE_STATE_CHANGE_FAILED,
} from 'Constants';

export const getSimUpdateStatusAndMessage = ({ majorReturnCode, minorReturnCode }) => {
    let message;
    let status = false;

    if (majorReturnCode === MAJOR_CODE_SUCCESS && minorReturnCode === MINOR_CODE_SUCCESS) {
        message = 'SIM card state successfully changed';
        status = true;
    }
    if (majorReturnCode === MAJOR_CODE_REQUEST_FAILED && minorReturnCode === MINOR_CODE_SIM_SAME_STATE) {
        message = 'SIM card is already in this state';
    } else if (majorReturnCode === MAJOR_CODE_REQUEST_FAILED && minorReturnCode === MINOR_CODE_STATE_CHANGE_FAILED) {
        message = 'Cannot change SIM card to this state. See the documentation for more details.';
    } else if (majorReturnCode === MAJOR_CODE_VODAFONE_ERROR) {
        message = `Vodafone API internal processing error: majorReturnCode: ${majorReturnCode} minorReturnCode: ${minorReturnCode}`;
    } else if (majorReturnCode === 'PYBYTES_ERROR') {
        message = 'You are not allowed to Change the SIM Status at this stage';
    } else {
        message = `Vodafone API error: majorReturnCode: ${majorReturnCode} and minorReturnCode: ${minorReturnCode}`;
    }

    return { message, status };
};

export const getDeviceInformationItem = (prop, list) => {
    let listArray = list;
    let value = '';

    if (typeof listArray === 'string') {
        try {
            listArray = JSON.parse(listArray);
            if (!Array.isArray(listArray.deviceInformationItem)) {
                return false;
            }
            listArray = listArray.deviceInformationItem;
        } catch (e) {
            return false;
        }
    }

    if (!Array.isArray(listArray)) {
        return false;
    }

    listArray.some((item) => {
        if (prop === item.itemName && item.itemValue) {
            value = item.itemValue;
            return true;
        }
        return false;
    });

    return value;
};

export const yesNoValue = (value) => {
    switch (value) {
        case 'Y':
        case 'y':
            return 'Yes';
        case 'N':
        case 'n':
            return 'No';
        default:
            return 'N/A';
    }
};

export const formatBytes = (bytes, multiplier = 1) => {
    const sizes = ['B', 'Kb', 'Mb', 'Gb', 'Tb'];

    if (bytes === 0) {
        return '0';
    }

    const i = parseInt(Math.floor(Math.log(bytes * multiplier) / Math.log(1024)), 10);
    return (`${Math.round((bytes * multiplier) / (1024 ** i), 2)} ${sizes[i]}`);
};

export function getSimCardStateName(stateKey) {
    const simCardStates = {
        T: 'Active.Test',
        R: 'Active.Ready',
        A: 'Active.Live',
        S: 'Active.Suspend',
        U: 'Active.Standby',
        C: 'Active.Sleep',
        D: 'Inactive.Stopped',
    };

    return simCardStates[stateKey];
}

export const checkNullablePerformance = (record) => !record || (
    record.data?.volume === '0'
        && record.data?.volume_rx === '0'
        && record.data?.volume_tx === '0'
);
