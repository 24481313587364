import PropTypes from 'prop-types';

import { ReactComponent as DeviceIcon } from 'Assets/icons/device.svg';
import { ReactComponent as UsbIcon } from 'Assets/icons/usb.svg';

import * as Styled from './styled';

export const NoDevicesScreen = ({ openAddDeviceModal, redirectToDeviceUsbAdd }) => (
    <Styled.Wrapper data-cy="no-project-devices-wrapper">
        <Styled.Title>
            Add devices
        </Styled.Title>
        <Styled.Description>
            Add an existing device to the project or add a new device.
        </Styled.Description>
        <Styled.Button
            onClick={openAddDeviceModal}
            Icon={DeviceIcon}
            buttonType="transparent"
            cyData="project-add-existing-device-empty"
            bordered
        >
            Add existing
        </Styled.Button>
        <Styled.Button
            onClick={redirectToDeviceUsbAdd}
            Icon={UsbIcon}
            buttonType="filled"
        >
            Add via USB
        </Styled.Button>
    </Styled.Wrapper>
);

NoDevicesScreen.propTypes = {
    openAddDeviceModal: PropTypes.func.isRequired,
    redirectToDeviceUsbAdd: PropTypes.func.isRequired,
};
