import { useMemo, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Gauge from '@ant-design/plots/es/components/gauge';

import { EmptyWidget } from '../emptyWidget';
import * as StyledGeneral from '../styled';

import {
    getChartValue,
    getConfig,
    getSignalConditionInformation,
} from './helpers';
import * as Styled from './styled';

export const SignalQualityWidget = ({ data, title, isEmpty }) => {
    const isDataBroken = Number.isNaN(+data);

    const chartWrapperRef = useRef(null);
    const [statisticFontSize, setStatisticFontSize] = useState(40);

    useEffect(() => {
        if (chartWrapperRef.current) {
            setStatisticFontSize(chartWrapperRef.current.offsetWidth / 10);
        }
    }, []);

    const chartValue = useMemo(() => getChartValue(data), [data]);

    const signalInfo = useMemo(() => getSignalConditionInformation(data), [data]);

    const config = useMemo(
        () => getConfig({
            chartValue,
            statisticFontSize,
            strength: signalInfo?.strength,
        }),
        [chartValue, statisticFontSize, signalInfo],
    );

    if (isEmpty || isDataBroken) {
        return <EmptyWidget title={title} isDataBroken={isDataBroken} />;
    }

    return (
        <Styled.Container>
            <Styled.Info>
                <StyledGeneral.ChartTitle>
                    {title}
                </StyledGeneral.ChartTitle>
                <Styled.UnitsWrapper>
                    <Styled.Unit>
                        {data} dBm
                    </Styled.Unit>
                </Styled.UnitsWrapper>
            </Styled.Info>
            <Styled.Chart ref={chartWrapperRef}>
                <Gauge {...config} />
            </Styled.Chart>
        </Styled.Container>
    );
};

SignalQualityWidget.propTypes = {
    data: PropTypes.number,
    title: PropTypes.node.isRequired,
    isEmpty: PropTypes.bool,
};

SignalQualityWidget.defaultProps = {
    isEmpty: false,
    data: null,
};
