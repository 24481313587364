import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { GET_APPLICATION_LAST_MESSAGES } from 'Constants';
import { getStringifiedPayload } from 'Utils';
import { useMessageSubscription } from 'Hooks';

import { AirQualityWidget } from '../../../../widgets';

export const AirQuality = ({ chartId, title }) => {
    const { data, loading, refetch } = useQuery(
        GET_APPLICATION_LAST_MESSAGES,
        {
            variables: { chartId },
        },
    );
    const lastMessages = data?.getApplicationLastMessages ?? [];

    const subscribeData = useMemo(() => {
        const deviceTokens = lastMessages.map((messageObj) => messageObj.device.token);
        const messagePin = lastMessages[0]?.lastMessage?.messagePin;

        return { deviceTokens, messagePin };
    }, [lastMessages]);

    useMessageSubscription(subscribeData.deviceTokens, subscribeData.messagePin, refetch);

    const value = useMemo(() => {
        if (!loading && lastMessages.length) {
            return getStringifiedPayload(lastMessages[0].lastMessage.payload);
        }
    }, [lastMessages]);

    return (
        <AirQualityWidget
            data={value}
            title={title}
            isEmpty={loading || (!value && value !== 0)}
        />
    );
};

AirQuality.propTypes = {
    chartId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};
