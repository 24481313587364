import PropTypes from 'prop-types';
import { Form, Field } from 'formik';

import { TextInput, TextArea } from 'Components';

import * as Styled from './styled';

export const FormContent = ({ dirty, isValid, isLoading }) => (
    <Form>
        <Styled.FormContent>    
            <Styled.FormItem>
                <Styled.ItemLabel>
                    Project Name
                </Styled.ItemLabel>
                <Field
                    name="name"
                    type="text"
                    component={TextInput}
                />
            </Styled.FormItem>
            <Styled.FormItem>
                <Styled.ItemLabel>
                    Project Description
                </Styled.ItemLabel>
                <Field
                    name="description"
                    component={TextArea}
                    rows={5}
                />
            </Styled.FormItem>
        </Styled.FormContent>
        <Styled.Button
            buttonType="transparent"
            type="submit"
            disabled={!(dirty && isValid)}
            loading={isLoading}
        >
            Save
        </Styled.Button>
    </Form>
);

FormContent.defaultProps = {
    isLoading: false,
};

FormContent.propTypes = {
    dirty: PropTypes.bool.isRequired,
    isValid: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool,
};
