import styled from 'styled-components';

export const Customization = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 50px;
    color: ${({ theme }) => theme.colors.text};
`;

export const Header = styled.div`
    margin-bottom: 35px;
`;

export const Title = styled.div`
    margin-bottom: 10px;
    color: ${({ theme }) => theme.colors.title};
    font-size: 22px;
    font-weight: bold;
    line-height: 27px;
`;

export const Description = styled.div`
    font-size: 16px;
    line-height: 22px;
`;

export const Buttons = styled.div`
    display: flex;
    margin-top: 84px;

    & > div {
        margin-right: 20px;
    }
`;

export const Form = styled.div`
    display: flex;
    flex-direction: column;
    width: 430px;
`;

export const FormItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
`;

export const Right = styled.div`
    padding-left: 116px;
`;
