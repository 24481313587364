/* eslint-disable react/jsx-filename-extension */
const { REACT_APP_GOOGLE_MAPS_API_KEY } = process.env;

export const googleMapConfig = {
    googleMapURL:
        `https://maps.googleapis.com/maps/api/js?key=${REACT_APP_GOOGLE_MAPS_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div className="googleMapContainer" />,
    mapElement: <div style={{ height: '100%', borderRadius: '10px' }} />,
};

export const defaultVisualOptions = {
    clickableIcons: false,
    zoomControl: true,
    mapTypeControl: true,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
};
