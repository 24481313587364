import PropTypes from 'prop-types';

import { PageHeader } from 'Components';

import {
    SimCardActivator,
    SimCardInformation,
    SimCardLocation,
    SimCardSessions,
    ConsumptionTabular,
    ConsumptionChart,
} from '../../components';

import * as Styled from '../styled';

export const SimCardWidgetsContainer = ({
    iccid,
    simCardStateActive,
    handleActivate,
    simInfo,
    sessionInfo,
    geoData,
    simHistoryInfo,
    simReportsInfo,
    consumptionChartInfo,
}) => (
    <Styled.Wrapper>
        <PageHeader
            title={`ICCID: ${iccid}`}
        />
        <SimCardActivator simCardStateActive={simCardStateActive} handleActivate={handleActivate} />
        <SimCardInformation simInfo={simInfo} />
        {(sessionInfo || geoData) && (
            <SimCardSessions columns={sessionInfo?.columns} tableData={sessionInfo?.tableData} geoData={geoData} />
        )}
        {simHistoryInfo && <SimCardLocation history={simHistoryInfo} />}
        {simReportsInfo && <ConsumptionTabular simReportsInfo={simReportsInfo} />}
        {consumptionChartInfo && <ConsumptionChart consumptionChartInfo={consumptionChartInfo} />}
    </Styled.Wrapper>
);

SimCardWidgetsContainer.defaultProps = {
    simCardStateActive: false,
    handleActivate: () => {},
    sessionInfo: null,
    geoData: null,
    simHistoryInfo: null,
    simReportsInfo: null,
    consumptionChartInfo: null,
};

SimCardWidgetsContainer.propTypes = {
    iccid: PropTypes.string.isRequired,
    simCardStateActive: PropTypes.bool,
    handleActivate: PropTypes.func,
    simInfo: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([
                PropTypes.string, PropTypes.number,
            ]).isRequired,
        }),
    ).isRequired,
    sessionInfo: PropTypes.shape({
        columns: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string.isRequired,
                dataIndex: PropTypes.string.isRequired,
            }),
        ).isRequired,
        tableData: PropTypes.array.isRequired,
    }),
    geoData: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
    }),
    simHistoryInfo: PropTypes.shape({
        columns: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string.isRequired,
                dataIndex: PropTypes.string.isRequired,
            }),
        ).isRequired,
        items: PropTypes.array.isRequired,
        isLoading: PropTypes.bool.isRequired,
    }),
    simReportsInfo: PropTypes.shape({
        columns: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string.isRequired,
                dataIndex: PropTypes.string.isRequired,
            }),
        ).isRequired,
        reports: PropTypes.array.isRequired,
        total: PropTypes.string,
    }),
    consumptionChartInfo: PropTypes.shape({
        types: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string.isRequired,
                dropDownTitle: PropTypes.string.isRequired,
                dataIndex: PropTypes.string.isRequired,
            }),
        ).isRequired,
        graphData: PropTypes.array.isRequired,
        tickFormatter: PropTypes.func,
    }),
};
