import PropTypes from 'prop-types';
import { Formik } from 'formik';

import { DeviceCard } from 'Components';
import { DEVICE_TEMPLATE_TYPES } from 'Constants';
import { getDeviceTemplateDescription } from 'Utils';

import { FormContent } from './formContent';
import { validationSchema, getInitialValues } from './config';

import * as Styled from './styled';

export const DeviceCustomization = ({
    backStep,
    deviceConfig,
    handleCustomData,
}) => {
    const renderCard = () => {
        const deviceTemplate = deviceConfig.device ?? deviceConfig.coModule;
        const deviceType = deviceConfig.device
            ? DEVICE_TEMPLATE_TYPES.DEVICES
            : DEVICE_TEMPLATE_TYPES.CO_MODULES;

        return (
            <DeviceCard
                device={deviceTemplate}
                descriptions={getDeviceTemplateDescription(deviceType, deviceTemplate)}
            />
        );
    };

    const setCustomInfo = (values) => {
        handleCustomData(values);
    };

    return (
        <Styled.Customization data-cy="add-device-customization-step">
            <div>
                <Styled.Header>
                    <Styled.Title>
                        Customize your device
                    </Styled.Title>
                    <Styled.Description>
                        Enter a name and description for your device, or use the ones we generated for you.
                    </Styled.Description>
                </Styled.Header>
                <Formik
                    initialValues={getInitialValues()}
                    validationSchema={validationSchema}
                    onSubmit={setCustomInfo}
                >
                    {(props) => <FormContent {...props} goBack={backStep} />}
                </Formik>
            </div>
            <Styled.Right>
                {renderCard()}
            </Styled.Right>
        </Styled.Customization>
    );
};

DeviceCustomization.propTypes = {
    backStep: PropTypes.func.isRequired,
    deviceConfig: PropTypes.object.isRequired,
    handleCustomData: PropTypes.func.isRequired,
};
