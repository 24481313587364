import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
    color: #828282;    
`;

export const Now = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    svg {
        font-size: 70px;
    }
`;

export const NowTemperature = styled.span`
    font-size: 40px;
    color: #000;
    line-height: 1;
    &::after {
        content: " ";
    }
`;

export const Forecast = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    text-align: center;
    svg {
        font-size: 18px;
        margin-top: 5px;
    }
`;

export const ForecastItem = styled.div`
    flex-grow: 1;
    padding: 10px 0px;
`;

export const ForecastItemNow = styled.div`
    flex-grow: 1;
    background: #F5F5F5;
    border-radius: 10px;
    padding: 10px 0px;
    color: #333333;
`;

export const ForecastTemperatureNow = styled.div`
    color: #000;
`;

export const ForecastTemperature = styled.div`
    font-size: 14px;
    color: #4F4F4F;
`;
