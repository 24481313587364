import { useMemo } from 'react';
import { Form, Field } from 'formik';
import PropTypes from 'prop-types';

import { Button, TextInput, Select } from 'Components';

import { NetworksTitleForLabel } from './config';

import * as Styled from './styled';

export const FormContent = ({ goBack, values, allNetworks, isLoading }) => {
    const wifiOptions = useMemo(
        () => allNetworks.wifi?.map((item) => ({ value: item._id, label: item.ssid })),
        [allNetworks.wifi],
    );

    const lteOptions = useMemo(
        () => allNetworks.lte?.map((item) => ({ value: item._id, label: item.name })),
        [allNetworks.lte],
    );

    return (
        <Form>
            <Styled.Form>
                <Styled.FormItem>
                    <span>Device Name</span>
                    <Field
                        name="name"
                        type="text"
                        component={TextInput}
                        errorPosition="bottom"
                    />
                </Styled.FormItem>
                <Styled.FormItem>
                    <span>Description</span>
                    <Field
                        name="description"
                        type="text"
                        component={TextInput}
                        errorPosition="bottom"
                    />
                </Styled.FormItem>
                {values.wifiId && (
                    <Styled.FormItem>
                        <span>{NetworksTitleForLabel.wifi}</span>
                        <Field
                            name="wifiId"
                            component={Select}
                            values={wifiOptions}
                            errorPosition="bottom"
                        />
                    </Styled.FormItem>
                )}
                {values.lteId && (
                    <Styled.FormItem>
                        <span>{NetworksTitleForLabel.lte}</span>
                        <Field
                            name="lteId"
                            component={Select}
                            values={lteOptions}
                            errorPosition="bottom"
                        />
                    </Styled.FormItem>
                )}
                {values.lora && (
                    <Styled.FormItem>
                        <span>{NetworksTitleForLabel.lora}</span>
                        <Field
                            disabled
                            name="lora"
                            type="text"
                            component={TextInput}
                            errorPosition="bottom"
                        />
                    </Styled.FormItem>
                )}
            </Styled.Form>
            <Styled.Buttons>
                <div>
                    <Button buttonType="transparent" onClick={goBack}>
                        Back
                    </Button>
                </div>
                <div>
                    <Button
                        type="submit"
                        disabled={isLoading}
                        cyData="device-summary-submit"
                    >
                        Finish
                    </Button>
                </div>
            </Styled.Buttons>
        </Form>
    );
};

FormContent.propTypes = {
    values: PropTypes.object.isRequired,
    goBack: PropTypes.func.isRequired,
    allNetworks: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
};

FormContent.defaultProps = {
    isLoading: false,
};
