import styled from 'styled-components';

export const Generation = styled.div`
    display: flex;
    align-items: center;
    margin-top: 15px;
`;

export const Text = styled.div`
    margin-right: 20px;
    font-size: 16px;
    font-weight: bold;
`;
