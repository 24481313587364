import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { BASE_DEVICE_WIZARD_FILTERS, DEVICE_TEMPLATE_TYPES } from 'Constants';
import { Button } from 'Components';

import { DeviceRow } from '../deviceRow';
import * as Styled from './styled';

export const DeviceConfiguration = ({
    deviceConfig,
    deviceTemplates,
    handleDevice,
    resetDeviceTemplateSelection,
}) => {
    const filters = useMemo(() => {
        if (deviceConfig.shield) {
            return [
                BASE_DEVICE_WIZARD_FILTERS[0],
                ...(deviceTemplates.deviceTemplateTopics || []),
            ];
        }

        if (deviceConfig.device) {
            return BASE_DEVICE_WIZARD_FILTERS;
        }

        const locaFilters = [...BASE_DEVICE_WIZARD_FILTERS];
        locaFilters.splice(1, 0, ...(deviceTemplates.deviceTemplateTopics || []));

        return locaFilters;
    }, [deviceConfig, deviceTemplates.deviceTemplateTopics]);

    const [activeFilter, setActiveFilter] = useState(filters[0]);
    const [isSomethingSelected, setIsSomethingSelected] = useState(false);

    useEffect(() => {
        if (deviceConfig.device || deviceConfig.shield) {
            setIsSomethingSelected(true);
        }

        setActiveFilter(filters[0]);
    }, [deviceConfig]);

    const resetSelection = () => {
        setIsSomethingSelected(false);
        resetDeviceTemplateSelection();
    };

    const renderFilter = (filter) => (
        <Styled.Filter key={filter.name}>
            <Button
                onClick={() => setActiveFilter(filter)}
                buttonType={
                    activeFilter.name === filter.name ? 'filled' : 'transparent'
                }
            >
                {filter.label}
            </Button>
        </Styled.Filter>
    );

    const renderRow = (filter) => {
        if ((activeFilter.name !== 'all' && activeFilter.name !== filter.name)
            || filter.name === 'all') {
            return null;
        }

        let devices = [];

        switch (filter.name) {
            case DEVICE_TEMPLATE_TYPES.SHIELDS:
                devices = deviceTemplates.shieldTemplates;
                break;
            default:
                devices = deviceTemplates.deviceTemplates?.filter(
                    (device) => device.topic === filter.name,
                ) ?? [];
        }

        return (
            <DeviceRow
                key={filter.name}
                title={filter.label}
                type={filter.name}
                devices={devices}
                deviceOnClick={handleDevice}
                supportedTypes={deviceConfig.shield?.supportedDevices}
            />
        );
    };

    return (
        <div data-cy="add-device-configuration-step">
            <Styled.Content>
                <span>Choose your device </span>
                <Styled.FiltersWrapper>
                    {filters.map(renderFilter)}
                </Styled.FiltersWrapper>
                {filters.map(renderRow)}
                {isSomethingSelected && (
                    <Styled.Buttons>
                        <Button
                            buttonType="transparent"
                            onClick={resetSelection}
                        >
                            Back
                        </Button>
                    </Styled.Buttons>
                )}
            </Styled.Content>
        </div>
    );
};

DeviceConfiguration.propTypes = {
    deviceConfig: PropTypes.object.isRequired,
    deviceTemplates: PropTypes.object.isRequired,
    handleDevice: PropTypes.func.isRequired,
    resetDeviceTemplateSelection: PropTypes.func.isRequired,
};
