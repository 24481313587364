import md5 from 'md5';
import { getStoredMeta, setStoredMeta } from './storage';

const pathToCamel = (path) => {
    const arrayToCamel = (arr) => arr.map((item, index) => {
        let newWord = item;
        if (index) {
            newWord = `${item[0].toUpperCase()}${item.slice(1)}`;
        }
        return newWord;
    }).join('');

    let camelPath = path.split('.');
    camelPath = arrayToCamel(camelPath);
    camelPath = camelPath.split('/');
    camelPath = arrayToCamel(camelPath);
    return camelPath;
};

export const saveMD5ContentSums = (projectKey, files) => {
    const md5Hashes = {};

    if (files?.length) {
        files.forEach(({ path, content }) => {
            const md5Key = pathToCamel(path);
            md5Hashes[md5Key] = md5(content);
        });
    }

    setStoredMeta(projectKey, { md5Hashes });
};

export const updateMD5ContentSum = (projectKey, path) => {
    const meta = getStoredMeta(projectKey)?.md5Hashes;
    const content = localStorage.getItem(`${projectKey}-${path}`);

    if (!meta || (content === null)) {
        return;
    }

    const md5Key = pathToCamel(path);
    const contentHash = md5(content);

    meta[md5Key] = contentHash;

    setStoredMeta(projectKey, { md5Hashes: meta });
};

export const compareMD5Hash = (projectKey, path) => {
    const hashes = getStoredMeta(projectKey)?.md5Hashes;
    const content = localStorage.getItem(`${projectKey}-${path}`);
    if (!hashes || (content === null)) {
        return;
    }

    const md5Key = pathToCamel(path);
    const contentHash = md5(content);
    return hashes[md5Key] === contentHash;
};

export const getMD5Hash = (projectKey, path) => {
    const meta = getStoredMeta(projectKey)?.md5Hashes;
    const content = localStorage.getItem(`${projectKey}-${path}`);

    if (!meta || (content === null)) {
        return null;
    }

    const md5Key = pathToCamel(path);
    return meta[md5Key];
};
