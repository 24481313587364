import { useMemo } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

import {
    CHART_TYPES,
    WEATHER_UNITS,
    TEMPERATURE_UNITS,
    ALTITUDE_UNITS,
} from 'Constants';

import { initialValues, getValidationSchema, settingsByField } from './config';
import { FormContent } from './formContent';

import * as Styled from './styled';

export const DeviceWidgetDescription = ({
    nextStep,
    prevStep,
    widgetInfo,
    widgetType,
    submitText,
    loading,
    isNameIncluded,
    editMode,
}) => {
    const onSubmit = (values) => {
        const returnedValues = { name: values.name, type: values.type, settings: {} };

        if (settingsByField.pin.includes(widgetType)) {
            returnedValues.settings.pin = values.settings.pin;
        }

        if (settingsByField.timePeriod.includes(widgetType)) {
            returnedValues.settings.timePeriod = parseInt(values.settings.timePeriod, 10);
        }

        if (settingsByField.limit.includes(widgetType)) {
            returnedValues.settings.limit = parseInt(values.settings.limit, 10);
        }

        if (settingsByField.unit.includes(widgetType)) {
            returnedValues.settings.unit = values.settings.unit;
        }

        nextStep({ ...widgetInfo, ...returnedValues });
    };

    const defineModeExistence = (type) => settingsByField.type.includes(type);

    const transformedInitialValues = useMemo(() => {
        const result = {
            name: widgetInfo.name || initialValues.name,
            type: widgetInfo.type || initialValues.type,
            settings: {
                ...initialValues.settings,
                ...widgetInfo.settings,
            },
        };

        if (!result.settings.unit) {
            switch (widgetType) {
                case CHART_TYPES.WEATHER:
                    result.settings.unit = WEATHER_UNITS.CELSIUS;
                    break;
                case CHART_TYPES.TEMPERATURE:
                    result.settings.unit = TEMPERATURE_UNITS.CELSIUS;
                    break;
                case CHART_TYPES.ALTITUDE:
                    result.settings.unit = ALTITUDE_UNITS.METER;
                    break;
                default:
                    break;
            }
        }

        return result;
    }, []);

    const hasModes = defineModeExistence(widgetType);
    const validationSchema = useMemo(() => getValidationSchema(widgetType, isNameIncluded, hasModes), [widgetType]);

    return (
        <>
            <Styled.Title>
                Please fill the data:
            </Styled.Title>
            <Formik
                onSubmit={onSubmit}
                initialValues={transformedInitialValues}
                validationSchema={validationSchema}
                validateOnMount
            >
                {(props) => (
                    <FormContent
                        {...props}
                        onBack={prevStep}
                        baseWidgetType={widgetType}
                        submitText={submitText}
                        loading={loading}
                        isNameIncluded={isNameIncluded}
                        hasModes={hasModes}
                        editMode={editMode}
                    />
                )}
            </Formik>
        </>
    );
};

DeviceWidgetDescription.propTypes = {
    nextStep: PropTypes.func.isRequired,
    prevStep: PropTypes.func.isRequired,
    widgetType: PropTypes.string.isRequired,
    submitText: PropTypes.string,
    widgetInfo: PropTypes.object,
    loading: PropTypes.bool,
    isNameIncluded: PropTypes.bool,
    setWidgetType: PropTypes.func,
    editMode: PropTypes.bool,
};

DeviceWidgetDescription.defaultProps = {
    loading: false,
    widgetInfo: {},
    submitText: 'Save',
    isNameIncluded: true,
    setWidgetType: () => {},
    editMode: false,
};
