import * as Yup from 'yup';

import { ValidationService } from 'Services';
import { SAMPLING_TYPES, ML_SENSORS } from 'Constants';

export const getInitialValues = ({ samplingType, deviceToken, frequency }) => {
    const baseFields = {
        deviceToken,
        sensor: ML_SENSORS[0],
        length: 5000,
        frequency: frequency || 10,
    };

    switch (samplingType) {
        case SAMPLING_TYPES.TEST:
            return { ...baseFields, sampleName: `sample-${Math.round(Math.random() * 100)}` };

        case SAMPLING_TYPES.TRAINING:
        default:
            return { ...baseFields, label: '' };
    }
};

const textRegExp = /^[a-zA-Z0-9-_\s]*$/;

export const getValidationSchema = (samplingType) => Yup.object().shape({
    frequency: Yup.number()
        .min(10, 'Should be between 10 and 350 Hz')
        .max(350, 'Should be between 10 and 350 Hz'),
    length: Yup.number()
        .min(500, 'Should be between 500 and 20000 ms')
        .max(20000, 'Should be between 500 and 20000 ms')
        .required(ValidationService.getRequiredError('length')),
    // frequency check is added just to run `when` rule
    sampleName: Yup.string().when('frequency', {
        is: () => samplingType === SAMPLING_TYPES.TEST,
        then: Yup.string()
            .matches(textRegExp, 'Should be a text')
            .max(200, 'Should have less than 200 symbols')
            .required(ValidationService.getRequiredError('sample name')),
    }),
    label: Yup.string().when('frequency', {
        is: () => samplingType === SAMPLING_TYPES.TRAINING,
        then: Yup.string()
            .matches(textRegExp, 'Should be a text')
            .max(200, 'Should have less than 200 symbols')
            .required(ValidationService.getRequiredError('label')),
    }),
});
