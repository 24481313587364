import { useMemo } from 'react';
import { Formik } from 'formik';
import { useLocation, useHistory } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { resetPassword } from 'Api';
import { ROUTES } from 'Constants';
import { showToastError, showToastInfo, showToastSuccess } from 'Utils';

import * as RootStyled from '../styled';

import { validationSchema, initialValues } from './config';
import { FormContent } from './formContent';
import * as Styled from './styled';

export const ResetPassword = () => {
    const history = useHistory();
    const { search } = useLocation();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const query = useMemo(() => new URLSearchParams(search), [search]);

    const onSubmit = async (values) => {
        const resetToken = query.get('token');

        if (!resetToken) {
            showToastError('Reset token is missing. Please check the link from email');
            return;
        }

        try {
            let result;
            if (process.env.REACT_APP_PYCOM_ENABLE_RECAPTCHA === 'true') {
                const recaptchaResult = await executeRecaptcha('resetPassword');
                result = await resetPassword(values.password, resetToken, recaptchaResult);
            } else {
                result = await resetPassword(values.password, resetToken);
            }

            if (result?.status === 'Success') {
                showToastSuccess('Password was successfully changed');
                history.push(ROUTES.auth.login);
            } else if (result?.errorMessage?.message === 'EXPIRED') {
                showToastInfo('Your link has expired, request a new one please');
                history.push(ROUTES.auth.login);
            } else {
                showToastError('Change password request has failed');
            }
        } catch (error) {
            showToastError('System error');
        }
    };

    return (
        <>
            <RootStyled.Title>
                Reset password
            </RootStyled.Title>
            <Styled.Subtitle>
                Please enter your new password.
            </Styled.Subtitle>
            <Formik
                onSubmit={onSubmit}
                initialValues={initialValues}
                validationSchema={validationSchema}
            >
                {(props) => <FormContent {...props} />}
            </Formik>
        </>
    );
};
