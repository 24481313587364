import { useMemo, useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useQuery } from '@apollo/client';

import { GET_SIM_CARDS, ROUTES } from 'Constants';
import { Table, Modal, Button, Loader } from 'Components';
import { ReactComponent as ExternalLink } from 'Assets/icons/externalLink.svg';

import { AddSimCardModal } from '../components/simCardModal';
import { NoData } from '../noData';
import { getColumns } from './columns';

import * as Styled from './styled';

export const SimCardsTable = () => {
    const history = useHistory();
    const description = 'In this section you can see all your purchased SIM cards.';
    const APP_LINK_WEBSHOP = process.env.REACT_APP_LINK_WEBSHOP;

    const [addSimCardModalOpen, setAddSimCardModalOpen] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);

    const { data: arrayOfSimCards, loading } = useQuery(GET_SIM_CARDS);

    const simCards = arrayOfSimCards?.getSimCards ?? [];

    useEffect(
        () => {
            if (initialLoading && !loading) {
                setInitialLoading(false);
            }
        },
        [loading],
    );

    const columns = useMemo(
        () => getColumns({}),
        [],
    );

    const openAddSimCardModal = () => {
        setAddSimCardModalOpen(true);
    };

    const openSimCardDetails = async (sim) => {
        history.push(`${ROUTES.settings.simCards.details}/${sim.iccid}`);
    };

    const handleClose = () => {
        setAddSimCardModalOpen(false);
    };

    if (initialLoading) {
        return <Loader />;
    }

    return (
        <Styled.Wrapper data-cy="simcards-list-main-wrapper">
            <Styled.HeaderText>
                {description}
            </Styled.HeaderText>
            {
                simCards.length
                    ? (
                        <Table
                            data-cy="simcards-table"
                            rowKey="iccid"
                            columns={columns}
                            data={simCards}
                            onRowClick={openSimCardDetails}
                        />
                    ) : (
                        <Styled.NoSimCardsBlock data-cy="noSimCard-block">
                            <NoData
                                caption="Oooops, looks like there is no Sim card here !"
                                text=""
                            />
                        </Styled.NoSimCardsBlock>
                    )
            }
            <Styled.ButtonsWrapper data-cy="simcards-buttons-wrapper">
                <Styled.BuySimCardLink
                    target="_blank"
                    rel="noopener noreferrer"
                    href={APP_LINK_WEBSHOP}
                    data-cy="link-buy-simCard"
                >
                    Buy Sim Card(s)
                    <ExternalLink />
                </Styled.BuySimCardLink>

                <Button
                    onClick={openAddSimCardModal}
                    cyData="button-add-simCard-from-list"
                >
                    Add SIM Card
                </Button>
            </Styled.ButtonsWrapper>
            <Modal
                handleClose={() => setAddSimCardModalOpen(false)}
                isOpened={addSimCardModalOpen}
            >
                <AddSimCardModal handleClose={handleClose} />
            </Modal>
        </Styled.Wrapper>
    );
};
