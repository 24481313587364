import * as Yup from 'yup';

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

export const initialValues = {
    password: '',
    passwordConfirm: '',
};

export const validationSchema = Yup.object().shape({
    password: Yup.string()
        .min(8, 'Must be at least 8 characters long')
        .matches(passwordRegex, 'Must contain one uppercase, one lowercase, one number, one special character')
        .required('This field is required'),
    passwordConfirmation: Yup.string()
        .required('This field is required')
        .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});
