/* eslint-disable no-bitwise, operator-assignment, max-len, no-cond-assign */
import cloneDeep from 'lodash/cloneDeep';
import { CHARTS_BOUNDARIES } from 'Constants';

function getCustomWidgetParams(widgetType) {
    return CHARTS_BOUNDARIES[widgetType];
}

function _testAreaVacancy(dashboardConfiguration, area, fixedIndexes, currentIndex) {
    const blockingIndexes = [];
    for (let i = 0; i < dashboardConfiguration.length; i++) {
        if (i !== currentIndex && dashboardConfiguration[i].widget !== null && dashboardConfiguration[i].widget !== undefined) {
            const widget = dashboardConfiguration[i].widget;
            if (widget.moved !== undefined || fixedIndexes.includes(i) || fixedIndexes.length === 0) {
                if ((widget.x >= area.x & widget.x <= area.x + (area.width - 1))
                    || (widget.x + (widget.width - 1) >= area.x & widget.x + (widget.width - 1) <= area.x + (area.width - 1))) {
                    if ((widget.y >= area.y & widget.y <= area.y + (area.height - 1))
                        || (widget.y + (widget.height - 1) >= area.y & widget.y + (widget.height - 1) <= area.y + (area.height - 1))) {
                        blockingIndexes.push(i);
                    } else if ((area.y >= widget.y & area.y <= widget.y + (widget.height - 1))
                        || (area.y + (area.height - 1) >= widget.y & area.y + (area.height - 1) <= widget.y + (widget.height - 1))) {
                        blockingIndexes.push(i);
                    }
                } else if ((area.x >= widget.x & area.x <= widget.x + (widget.width - 1))
                    || (area.x + (area.width - 1) >= widget.x & area.x + (area.width - 1) <= widget.x + (widget.width - 1))) {
                    if ((widget.y >= area.y & widget.y <= area.y + (area.height - 1))
                        || (widget.y + (widget.height - 1) >= area.y & widget.y + (widget.height - 1) <= area.y + (area.height - 1))) {
                        blockingIndexes.push(i);
                    } else if ((area.y >= widget.y & area.y <= widget.y + (widget.height - 1))
                        || (area.y + (area.height - 1) >= widget.y & area.y + (area.height - 1) <= widget.y + (widget.height - 1))) {
                        blockingIndexes.push(i);
                    }
                }
            }
        }
    }
    return blockingIndexes;
}

/**
 * Calculates the current height of the grid that contains widgets + 1
 * @param  {array} widgetConfigurations */
export function getGridHeight(dashboardConfigurations) {
    let bestY = 0;
    for (let i = 0; i < dashboardConfigurations.length; i++) {
        if (dashboardConfigurations[i]?.widget !== null && dashboardConfigurations[i]?.widget !== undefined) {
            const y = dashboardConfigurations[i].widget.y + dashboardConfigurations[i].widget.height;
            if (y > bestY) { bestY = y; }
        }
    }
    bestY += 1;
    return bestY;
}

export function findClosestRelativePosition(config, origin, fixedIndexes, currentIndex) {
    if (_testAreaVacancy(config, origin, fixedIndexes, currentIndex).length === 0) {
        return origin;
    }

    const newWidget = cloneDeep(origin);
    let valid = false;
    let testRange = 1;
    const validPositions = [];

    while (valid === false) {
        let _x = origin.x - testRange;
        while (_x - origin.x <= testRange) {
            let _y = origin.y - testRange;
            if (_x >= 0 && _x + (origin.width - 1) <= 11) {
                while (_y - origin.y <= testRange) {
                    if (_y >= 0) {
                        if (Math.abs(_x - origin.x) + Math.abs(_y - origin.y) === testRange) {
                            newWidget.x = _x;
                            newWidget.y = _y;
                            if (_testAreaVacancy(config, newWidget, fixedIndexes, currentIndex).length === 0) {
                                validPositions.push({ x: _x, y: _y });
                            }
                        }
                    }
                    _y += 1;
                }
            }
            _x += 1;
        }

        if (validPositions.length === 0) {
            testRange += 1;
        } else {
            if (validPositions.length > 1) {
                let smallestY = validPositions[0].y;
                let bestIndex = 0;
                for (let i = 0; i < validPositions.length; i++) {
                    if (validPositions[i].y < smallestY) {
                        smallestY = validPositions[i];
                        bestIndex = i;
                    } else if (validPositions[i].y === smallestY && validPositions[i].x < validPositions[bestIndex].x) {
                        bestIndex = i;
                    }
                }
                validPositions[0] = validPositions[bestIndex];
            }
            newWidget.x = validPositions[0].x;
            newWidget.y = validPositions[0].y;
            valid = true;
        }
    }
    return newWidget;
}

export function clearEmptySpace(dashboardConfiguration) {
    const config = cloneDeep(dashboardConfiguration);
    const gridSize = getGridHeight(config);
    const validIndexes = [];
    for (let i = 0; i < gridSize - 1; i++) {
        const testingErea = {
            width: 12,
            height: 1,
            x: 0,
            y: i,
        };
        let blockingIndexes = _testAreaVacancy(config, testingErea, [], -1);
        if (blockingIndexes.length === 0) {
            let height = 1;
            testingErea.height += 1;
            while ((blockingIndexes = _testAreaVacancy(config, testingErea, [], -1)).length === 0 && i + height < gridSize) {
                testingErea.height += 1;
                height += 1;
            }
            for (let j = 0; j < config.length; j += 1) {
                if (!validIndexes.includes(j)) {
                    config[j].widget.y -= height;
                }
            }
        } else {
            for (let j = 0; j < blockingIndexes.length; j += 1) {
                validIndexes.push(blockingIndexes[j]);
            }
        }
    }

    return config;
}

export function generateWidgetSpace(dashboardConfiguration, widgetType, widgetDimensions) {
    const customMetrics = getCustomWidgetParams(widgetType);

    const newWidget = {
        width: widgetDimensions?.width || customMetrics?.width || 6,
        height: widgetDimensions?.height || customMetrics?.height || 2,
        x: 0,
        y: 0,
    };

    const gridSize = getGridHeight(dashboardConfiguration) - 1;
    if (!customMetrics?.fullSize) {
        for (let y = 0; y < gridSize; y += 1) {
            newWidget.y = y;
            for (let x = 0; x < 9; x += 1) {
                newWidget.x = x;
                if (_testAreaVacancy(dashboardConfiguration, newWidget, [], -1).length === 0) {
                    return (newWidget);
                }
            }
        }
    }

    newWidget.x = 0;
    newWidget.y = gridSize;
    return (newWidget);
}

export function computeGridChange(dashboardConfiguration, changedWidget, fixedIndexes) {
    const config = cloneDeep(dashboardConfiguration);
    const _fixedIndexes = fixedIndexes;
    config[fixedIndexes[0]].widget._id = changedWidget._id;
    config[fixedIndexes[0]].widget.deviceToken = changedWidget.deviceToken;
    config[fixedIndexes[0]].widget.width = changedWidget.width;
    config[fixedIndexes[0]].widget.height = changedWidget.height;
    config[fixedIndexes[0]].widget.x = changedWidget.x;
    config[fixedIndexes[0]].widget.y = changedWidget.y;
    config[fixedIndexes[0]].widget.moved = true;

    const blockingIndexes = _testAreaVacancy(config, changedWidget, fixedIndexes, -1);
    if (blockingIndexes.length > 0) {
        for (let i = 0; i < config.length; i++) {
            if (_fixedIndexes.indexOf(i) === -1) {
                config[i].widget = findClosestRelativePosition(config, config[i].widget, _fixedIndexes, i);
                _fixedIndexes.push(i);
            }
        }
    }
    config[fixedIndexes[0]].widget.moved = undefined;

    return (config);
}
