import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import * as Styled from './styled';

export const ContextMenu = ({ menu, anchorY, anchorX, className, parentRef, onClose }) => {
    const style = { top: anchorY, left: anchorX };
    const buttonOnClick = (e, action) => {
        action();
        onClose(e);
    };

    return createPortal(
        (
            <>
                <Styled.Wrapper
                    className={className}
                    onClick={onClose}
                    tabIndex={0}
                    role="button"
                    onKeyDown={() => {}}
                    onContextMenu={(e) => onClose(e)}
                >
                    {}
                </Styled.Wrapper>
                <Styled.Menu style={style}>
                    {menu.map((item) => (
                        <Styled.MenuElement key={item.title}>
                            <button
                                onClick={(e) => buttonOnClick(e, item.action)}
                            >
                                {item.title}
                            </button>
                        </Styled.MenuElement>
                    ))}
                </Styled.Menu>
            </>
            
        ),
        parentRef.current,
    );
};

ContextMenu.defaultProps = {
    menu: [],
    className: '',
};

ContextMenu.propTypes = {
    menu: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            action: PropTypes.func.isRequired,
        }),
    ),
    anchorY: PropTypes.number.isRequired,
    anchorX: PropTypes.number.isRequired,
    className: PropTypes.string,
    onClose: PropTypes.func.isRequired,
};
