import * as Yup from 'yup';

import { ValidationService } from 'Services';

export const machineLearningDocumentationLink = process.env.REACT_APP_MACHINE_LEARNING_DOCUMENTATION_LINK;

export const initialValues = {
    name: '',
    description: '',
    frequency: 50,
    windowsStep: 50,
    windowsSize: 2000,
};

export const getValidationSchema = () => Yup.object().shape({
    name: Yup.string()
        .required(ValidationService.getRequiredError('name')),
    frequency: Yup.number()
        .min(10, 'Should be between 10 and 100 Hz')
        .max(100, 'Should be between 10 and 100 Hz'),
    windowsStep: Yup.number()
        .min(50, 'Should be between 50 ms and the windows size')
        .max(Yup.ref('windowsSize'), (ref) => `Should be between 50 ms and the windows size ${ref.max} ms`),
    windowsSize: Yup.number()
        .min(500, 'Should be between 500 ms and the sample size'),
});
