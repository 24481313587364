import { useState, useMemo, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Spin, Table, BorderRouterModal, getPymeshDeviceSelectionColumns, Loader } from 'Components';
import { PYMESH_SUPPORTED_DEVICE_TYPES, GET_DEVICES_QUERY, BR_ENABLED_VALUES } from 'Constants';

import { WizardComponentBox } from '../wizardComponentBox';

import * as Styled from './styled';

export const PymeshDeviceSelection = ({ nextStep, previousStep, saveValues, values }) => {
    const history = useHistory();

    const predefinedDevices = values?.devices || [];
    const defaultBrDevicesDisabled = predefinedDevices
        .filter((device) => !device.brEnable)
        .map((device) => device.token);

    const [selectedDeviceIds, setSelectedDeviceIds] = useState(values.selectedDeviceIds || []);
    const [filter, setFilter] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [disabledBrDevices, setDisabledBrDevices] = useState(defaultBrDevicesDisabled);
    const [initialLoading, setInitialLoading] = useState(true);

    const { data: devicesData, loading: isLoadingDevices } = useQuery(GET_DEVICES_QUERY);
    const edges = devicesData?.getDevices?.edges ?? [];
    const pageInfo = devicesData?.getDevices?.pageInfo || {};

    useEffect(
        () => {
            if (initialLoading && !isLoadingDevices) {
                setInitialLoading(false);
            }
        },
        [isLoadingDevices],
    );

    const handleModalClose = () => setShowModal(false);

    const processedDeviceEdges = useMemo(
        () => edges.filter((edge) => {
            const device = edge.node;
            return device.mac !== null && PYMESH_SUPPORTED_DEVICE_TYPES.includes(device.deviceType);
        }),
        [devicesData],
    );

    const resultData = useMemo(
        () => processedDeviceEdges.map((edge) => {
            const device = { ...edge.node };
            device.brEnable = !disabledBrDevices.includes(device.token);
            return device;
        }),
        [processedDeviceEdges, disabledBrDevices],
    );

    const filteredData = useMemo(
        () => resultData.filter((device) => device.description.toUpperCase().includes(filter.toUpperCase())),
        [resultData, filter],
    );

    const onSelect = (ids) => setSelectedDeviceIds(ids);

    const handleNext = () => {
        const validationSuccess = resultData.find((device) => (device.brEnable && selectedDeviceIds.includes(device._id)));
        if (validationSuccess) {
            const selectedDevices = resultData.filter((device) => selectedDeviceIds.includes(device._id));

            saveValues({ devices: selectedDevices, selectedDeviceIds });
            nextStep();
        } else {
            setShowModal(true);
        }
    };

    const getBrValueByToken = (token) => !disabledBrDevices.find((deviceToken) => deviceToken === token);

    const handleBRChange = (token, changeObject) => {
        const deviceWithDisableBr = [...disabledBrDevices];
        const tokenIndex = deviceWithDisableBr.findIndex((item) => item === token);

        if (changeObject.value === BR_ENABLED_VALUES.ENABLED && tokenIndex >= 0) {
            deviceWithDisableBr.splice(tokenIndex, 1);
        } else if (changeObject.value === BR_ENABLED_VALUES.DISABLED && tokenIndex < 0) {
            deviceWithDisableBr.push(token);
        }

        if (deviceWithDisableBr.length !== resultData.length) {
            setDisabledBrDevices(deviceWithDisableBr);
        } else {
            setShowModal(true);
        }
    };

    const columns = getPymeshDeviceSelectionColumns({ getBrValueByToken, handleBRChange });

    if (initialLoading) {
        return <Loader />;
    }

    if (!processedDeviceEdges.length && !isLoadingDevices) {
        return (
            <WizardComponentBox
                isFirstStep
                disableNext
                title="Choose devices for pymesh"
                description="In the table below you will find all the Devices that were provisioned to the Pybytes before."
                previousStep={() => history.goBack()}
            >
                <Styled.NoDevicesAvailable>
                    <h3>No devices available for Pymesh</h3>
                    <p>The device should match the requirements below to be added to the Pymesh.</p>
                    <ul>
                        <li>Device type should be: Fipy, Lopy4, L01 or L04</li>
                        <li>Device should be previously activated in Pybytes</li>
                    </ul>
                </Styled.NoDevicesAvailable>
            </WizardComponentBox>
        );
    }

    return (
        <WizardComponentBox
            title="Choose devices for pymesh"
            description="In the table below you will find all the Devices that were provisioned to the Pybytes before."
            nextStep={handleNext}
            previousStep={previousStep}
            disableNext={!selectedDeviceIds.length}
        >
            <Spin spinning={isLoadingDevices}>
                <Table
                    selectedRowKeys={selectedDeviceIds}
                    searchOptions={{ placeHolder: 'Search for device', onSearch: (key) => setFilter(key) }}
                    columns={columns}
                    data={filteredData}
                    onSelect={onSelect}
                    pagination={pageInfo}
                />
            </Spin>
            <BorderRouterModal showModal={showModal} onClose={handleModalClose} />
        </WizardComponentBox>
    );
};

PymeshDeviceSelection.propTypes = {
    nextStep: PropTypes.func.isRequired,
    previousStep: PropTypes.func.isRequired,
    saveValues: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
};
