import styled from 'styled-components';

import ArrowImage from 'Assets/images/down.png';

export const Select = styled.div`
    display: flex;
    align-items: center;
`;

export const Field = styled.select`
    width: 293px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    padding: 9px 15px;
    color: ${({ theme }) => theme.colors.text};
    cursor: pointer;
    font-size: 14px;
    line-height: 1.57;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: white;
    background-image: url("${ArrowImage}");
    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: 50%;
    text-overflow: ellipsis;
`;

export const Error = styled.div`
    margin-left: 0;
    transition: margin-left 0.4ms linear;
    color: ${({ theme }) => theme.colors.error};
    font-size: 12px;

    ${({ $isAnimated }) =>
        $isAnimated &&
        `
        margin-left: 10px;
    `}
`;
