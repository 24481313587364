import { useMemo } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { Loader, NoData, Alert } from 'Components';
import { ML_RESPONSE_STATUSES } from 'Constants';

import * as StyledGlobal from '../../../../styled';

import { getChartsData } from './helpers';
import { Chart } from './chart';
import * as Styled from './styled';

export const SpectralAnalysisCharts = ({ preProcessingData, loading, model }) => {
    const isFailure = preProcessingData?.state === ML_RESPONSE_STATUSES.FAILURE
        || preProcessingData?.state === ML_RESPONSE_STATUSES.PENDING;

    const { afterFilterData, shortFFTs } = useMemo(
        () => getChartsData(preProcessingData, model),
        [preProcessingData, model],
    );

    if (loading) {
        return <Loader overlay />;
    }

    if (isEmpty(preProcessingData)) {
        return (
            <NoData
                caption="No data was processed"
                text="Select the sample, the windows size and run the sprectral analysis."
            />
        );
    }

    if (isFailure) {
        return (
            <StyledGlobal.FormAlertSection>
                <Alert
                    type="error"
                    message="Process signal again"
                />
            </StyledGlobal.FormAlertSection>
        );
    }

    return (
        <>
            {!!afterFilterData.chartData.length && (
                <Styled.Wrapper>
                    <Styled.Title>
                        After Filter
                    </Styled.Title>
                    <Styled.ChartWrapper>
                        <Chart data={afterFilterData} />
                    </Styled.ChartWrapper>
                </Styled.Wrapper>
            )}
            {!!shortFFTs.chartData.length && (
                <Styled.Wrapper>
                    <Styled.Title>
                        Frequency Domains Analysis
                    </Styled.Title>
                    <Styled.SubTitle>
                        Interval - 1
                    </Styled.SubTitle>
                    <Styled.ChartWrapper>
                        <Chart data={shortFFTs} />
                    </Styled.ChartWrapper> 
                </Styled.Wrapper>
            )}
        </>
    );
};

SpectralAnalysisCharts.propTypes = {
    model: PropTypes.object.isRequired,
    preProcessingData: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
};
