import styled, { createGlobalStyle } from 'styled-components';

export const SelectWithPopup = styled.div`    
    div {
        width: auto !important; 
        span {
            max-height: none !important;
        }
    }
`;

export const CheckboxWrapper = styled.div`    
    label {
        white-space: nowrap;
        color: ${({ theme, $checked }) =>
        ($checked ? theme.colors.active : '#828282')};
    }
    padding-bottom: 7px;    
`;

export const CustomTooltipStyle = createGlobalStyle`
    .chart-layout-settings-tooltip {
        .ant-tooltip-arrow {
            display: none !important;
        }
    }
`;

export const Menu = styled.div`
    display: flex;
    position: absolute;
    right: 78px;
    top: 21.5px;
    height: 17px;
    font-size: 14px;
    color: #525B5C;
    gap: 44px;
    align-items: center;
`;

export const Icon = styled.div`
    display: flex;

    svg {
        font-size: 18px;
        color: ${({ theme, $chartSettingsSelected }) =>
        ($chartSettingsSelected ? '#6A747B' : theme.colors.active)};
        cursor: pointer;
    }
`;
