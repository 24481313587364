import styled from 'styled-components';

export const Title = styled.div`
    font-size: 22px;
    color: ${({ theme }) => theme.colors.title};
    margin-bottom: 1rem;
`;

export const List = styled.div`
    max-width: 1122px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
`;

export const WidgetWrapper = styled.div`
    position: relative;
`;

export const SuccessCheck = styled.div`
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: 22px;
    color: ${({ theme }) => theme.colors.active};
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 50px;
    padding-bottom: 10px;
    
    & > *:first-child {
        margin-right: 30px;
    }
`;

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-bottom: 1.5rem;
`;
