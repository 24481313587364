import { useMemo, useState, useRef, useEffect } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/client';

import {
    SET_MODEL_DEFINITION,
    SET_FEATURES,
    SET_TRAINING,
    SET_TESTING,
    SET_LAST_PROCESSED_DATA,
    RUN_MODEL,
    PROCESSING_STATUS,
    SET_PRE_PROCESSING_STATUS,
    GET_ML_MODEL_BY_ID_QUERY,
    GET_ML_SAMPLES_BY_MODEL,
    ML_RESPONSE_STATUSES,
} from 'Constants';

import { getInitialValues, validationSchema } from './config';
import { FormContent } from './formContent';
import {
    getPreProcessingBlock,
    getSignalProcessingBlock,
    getSampleData,
} from './helpers';

export const SpectralAnalysisForm = ({ model, mlSample, samples, modelDefinition }) => {
    const apolloClient = useApolloClient();

    const intervalRef = useRef(null);

    const [loading, setLoading] = useState(false);

    const initialValues = useMemo(() => getInitialValues(modelDefinition), [modelDefinition]);

    const clearIntervalRef = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
    };

    useEffect(() => () => clearIntervalRef(), []);

    const processingStatusHandler = async (taskId) => {
        const { data: { processingStatus } } = await apolloClient.query({
            query: PROCESSING_STATUS,
            variables: { taskId },
            fetchPolicy: 'network-only',
        });

        const preProcessingStatusObj = JSON.parse(processingStatus);

        if (preProcessingStatusObj.state === ML_RESPONSE_STATUSES.SUCCESS) {
            clearIntervalRef();

            await apolloClient.mutate({
                mutation: SET_PRE_PROCESSING_STATUS,
                variables: {
                    modelId: model._id,
                    preProcessingStatus: processingStatus,
                },
                refetchQueries: [GET_ML_SAMPLES_BY_MODEL, GET_ML_MODEL_BY_ID_QUERY],
            });

            setLoading(false);
            return;
        }

        if (preProcessingStatusObj.state === ML_RESPONSE_STATUSES.FAILURE
            || preProcessingStatusObj.state === ML_RESPONSE_STATUSES.RETRY) {
            clearIntervalRef();
        }
    };

    const onSubmit = async (values) => {
        const preProcessingBlock = getPreProcessingBlock(model, mlSample);
        const signalProcessingBlock = getSignalProcessingBlock(mlSample, values, modelDefinition);
        const requestModelDefinition = {
            name: 'model_definition',
            blocks: [preProcessingBlock, signalProcessingBlock],
        };
        const data = getSampleData(samples, mlSample, model);

        if (!data.samples[0].data.length) {
            return;
        }

        setLoading(true);

        await apolloClient.mutate({
            mutation: SET_MODEL_DEFINITION,
            variables: {
               modelId: model._id,
               modelDefinition: JSON.stringify(requestModelDefinition),
            },
        });

        await apolloClient.mutate({
            mutation: SET_FEATURES,
            variables: {
               modelId: model._id,
               features: JSON.stringify({}),
            },
        });

        await apolloClient.mutate({
            mutation: SET_TRAINING,
            variables: {
               modelId: model._id,
               training: JSON.stringify({}),
            },
        });

        await apolloClient.mutate({
            mutation: SET_TESTING,
            variables: {
               modelId: model._id,
               testing: JSON.stringify({}),
            },
        });

        await apolloClient.mutate({
            mutation: SET_LAST_PROCESSED_DATA,
            variables: {
               modelId: model._id,
               lastProcessedData: {
                   sampleId: mlSample._id,
                   t0: model.lastProcessedData.t0 || 0,
                },
            },
        });

        const { data: { runModel } } = await apolloClient.query({
            query: RUN_MODEL,
            variables: {
               modelDefinition: JSON.stringify(requestModelDefinition),
               data: JSON.stringify(data),
            },
            fetchPolicy: 'network-only',
        });
        const result = JSON.parse(runModel);

        intervalRef.current = setInterval(() => processingStatusHandler(result.task_id), 1000);
    };

    return (
        <>
            <h2>Spectral Analysis Parameters</h2>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {(props) => (
                    <FormContent {...props} loading={loading} />
                )}
            </Formik>
        </>
    );
};

SpectralAnalysisForm.propTypes = {
    model: PropTypes.object.isRequired,
    mlSample: PropTypes.object.isRequired,
    samples: PropTypes.array.isRequired,
    modelDefinition: PropTypes.object.isRequired,
};
