export const vodafoneSimSessionColumns = [
    {
        title: 'Started',
        dataIndex: 'started',
    },
    {
        title: 'Terminated',
        dataIndex: 'terminated',
    },
    {
        title: 'Bytes In',
        dataIndex: 'bytesIn',
    },
    {
        title: 'Bytes Out',
        dataIndex: 'bytesOut',
    },
    {
        title: 'Location',
        dataIndex: 'location',
    },
    {
        title: 'Active',
        dataIndex: 'active',
    },
];
