import PropTypes from 'prop-types';
import { Formik } from 'formik';

import { LORA_CONSTANTS } from 'Constants';

import { FormContent } from './formContent';

export const LoraSelectForm = ({
    initialValues,
    loraSettings,
    onChange,
    disableLora,
    showLoraType,
}) => {
    const combinedInitialValues = {
        loraServer: initialValues?.loraServer || loraSettings.loraServer,
        loraType: initialValues?.loraType
            || (loraSettings.usingABP
                ? LORA_CONSTANTS.RADIO_BUTTONS[1].value
                : LORA_CONSTANTS.RADIO_BUTTONS[0].value),
        loraRegion: initialValues?.loraRegion || loraSettings.loraRegion,
    };

    return (
        <Formik
            onSubmit={onChange}
            initialValues={combinedInitialValues}
        >
            {(props) => (
                <FormContent
                    {...props}
                    disableLora={disableLora}
                    showLoraType={showLoraType}
                />
              )}
        </Formik>
    );
};

LoraSelectForm.propTypes = {
    onChange: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({
        loraServer: PropTypes.string,
        loraType: PropTypes.string,
        loraRegion: PropTypes.string,
    }),
    loraSettings: PropTypes.shape({
        loraServer: PropTypes.string,
        usingABP: PropTypes.bool,
        loraRegion: PropTypes.string,
    }),
    disableLora: PropTypes.bool,
    showLoraType: PropTypes.bool,
};

LoraSelectForm.defaultProps = {
    initialValues: {},
    loraSettings: {},
    disableLora: false,
    showLoraType: true,
};
