import { Form, Field } from 'formik';
import PropTypes from 'prop-types';

import { TextInput, TextArea, Button } from 'Components';
import { ReactComponent as SupportIcon } from 'Assets/icons/support.svg';

import * as Styled from './styled';

export const FormContent = ({ isValid, onBack }) => (
    <Form>
        <Styled.FormContent>    
            <Styled.FormItem>
                <Styled.ItemLabel>
                    Name
                </Styled.ItemLabel>
                <Field
                    name="name"
                    type="text"
                    component={TextInput}
                />
            </Styled.FormItem>
            <Styled.FormItem>
                <Styled.ItemLabel>
                    Description
                </Styled.ItemLabel>
                <Field
                    name="description"
                    component={TextArea}
                    rows={5}
                />
            </Styled.FormItem>
        </Styled.FormContent>
        <Styled.NotificationWrapper>
            <Styled.NotificationIcon>
                <SupportIcon />
            </Styled.NotificationIcon>
            <Styled.NotificationLabel>
                You can change the Project’s name and Description in the project Settings any time.
            </Styled.NotificationLabel>
        </Styled.NotificationWrapper>
        <Styled.ButtonsWrapper>
            <Button
                buttonType="transparent"
                onClick={onBack}
            >
                Back
            </Button>
            <Button
                type="submit"
                disabled={!isValid}
                cyData="add-project-description-submit"
            >
                Next
            </Button>
        </Styled.ButtonsWrapper>
    </Form>
);

FormContent.propTypes = {
    isValid: PropTypes.bool.isRequired,
    onBack: PropTypes.func.isRequired,
};
