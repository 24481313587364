import PropTypes from 'prop-types';

import { ReactComponent as ArrowLeft } from 'Assets/icons/arrowLeft.svg';
import { ReactComponent as ArrowRight } from 'Assets/icons/arrowRight.svg';

import * as Styled from './styled';

export const Pagination = ({ hasNextPage, hasPreviousPage, firstCursor, lastCursor, onChange }) => {
    const onPrev = () => {
        onChange({ before: firstCursor });
    };

    const onNext = () => {
        onChange({ after: lastCursor });
    };

    return (
        <Styled.PaginationWrapper>
            <Styled.PaginationButton
                disabled={!hasPreviousPage}
                onClick={onPrev}
                data-cy="pagination-prev-button"
            >
                <ArrowLeft />
                Prev
            </Styled.PaginationButton>
            <Styled.PaginationButton
                disabled={!hasNextPage}
                onClick={onNext}
                data-cy="pagination-next-button"
            >
                Next
                <ArrowRight />
            </Styled.PaginationButton>
        </Styled.PaginationWrapper>
    );
};

Pagination.propTypes = {
    hasNextPage: PropTypes.bool,
    hasPreviousPage: PropTypes.bool,
    firstCursor: PropTypes.string,
    lastCursor: PropTypes.string,
    onChange: PropTypes.func,
};

Pagination.defaultProps = {
    hasNextPage: false,
    hasPreviousPage: false,
    firstCursor: '',
    lastCursor: '',
    onChange: () => {},
};
