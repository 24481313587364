import { useQuery } from '@apollo/client';
import { useMemo, useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ThemeContext } from 'styled-components';

import { PageHeader, DevicesStarterPage, Loader } from 'Components';
import { ROUTES, GET_DEVICES_QUERY, GET_VALID_CERTIFICATES_BY_DEVICE_IDS } from 'Constants';
import { hasValueLength } from 'Utils';

import { DeviceTable } from './components';
import * as Styled from './styled';

export const AllDevices = () => {
    const styledTheme = useContext(ThemeContext);
    const history = useHistory();

    const [initialLoading, setInitialLoading] = useState(true);

    const { data: arrayOfDevices, loading, refetch: refetchDevices } = useQuery(
        GET_DEVICES_QUERY,
        {
            variables: {
                paging: { items: 10, cursors: {} },
            },
            fetchPolicy: 'cache-and-network',
        },
    );

    const devices = useMemo(
        () => arrayOfDevices?.getDevices?.edges.map((item) => item.node) ?? [],
        [arrayOfDevices],
    );

    const deviceIds = useMemo(
        () => devices?.map((obj) => obj._id) ?? [],
        [devices],
    );

    const { data: validCertificatesData, loading: isLoadingCertificates } = useQuery(
        GET_VALID_CERTIFICATES_BY_DEVICE_IDS,
        {
            variables: { deviceIds },
            fetchPolicy: 'cache-and-network',
        },
    );
    const validCertificates = validCertificatesData?.getDevicesCertificatesByDeviceIds ?? [];

    useEffect(() => {
        if (initialLoading && !loading && !isLoadingCertificates) {
            setInitialLoading(false);
        }
    }, [loading, isLoadingCertificates]);

    const devicesWithCertificates = useMemo(
        () => devices?.map(
            (device) => {
                const certificate = validCertificates?.find((obj) => device._id === obj.deviceId);
                return { ...device, hasValidCertificate: certificate?.status };
            },
        ), [devices, validCertificates],
    );

    const cursorPagination = useMemo(
        () => arrayOfDevices?.getDevices?.pageInfo ?? {},
        [arrayOfDevices],
    );

    const withDevices = useMemo(
        () => hasValueLength(devices),
        [devices],
    );

    const description = withDevices
        ? 'The place  to manage all your IoT devices'
        : `Start your journey with ${styledTheme.whiteLabelCompany} by provisioning your devices now
            via USB or the ${styledTheme.whiteLabelCompany} mobile app.`;

    const onRowClick = (record) => {
        const { main, deviceDetails } = ROUTES.devices;
        history.push(`${main}/${record.token}${deviceDetails.dashboard}`);
    };

    const paginationHandler = ({ cursors, items }) => {
        refetchDevices({ paging: { items, cursors } });
    };

    if (initialLoading) {
        return <Loader />;
    }

    return (
        <Styled.Wrapper data-cy="devices-page-wrapper">
            <PageHeader title="All Devices" description={[description]} />
            <Styled.ContentWrapper>
                {withDevices ? (
                    <DeviceTable
                        onRowClick={onRowClick}
                        data={devicesWithCertificates}
                        pagination={cursorPagination}
                        paginationHandler={paginationHandler}
                        deviceLoading={loading}
                    />
                ) : (
                    <DevicesStarterPage />
                )}
            </Styled.ContentWrapper>
        </Styled.Wrapper>
    );
};
