import gql from 'graphql-tag';

export const ALERTS_QUERY = gql`
    query getDeviceAlerts($deviceToken: String!) {
        getAlertsByDevice(deviceToken: $deviceToken) {
          _id
          name
          userId
          deviceToken
          type
          input {
            period
            dataUsage {
              amount
              quantity
            }
            battery {
              quantity
              operator
            }
          }
        }
    }
`;

export const SAVE_ALERT = gql`
    mutation saveAlert(
        $name: String!,
        $userId: String!,
        $deviceToken: String!,
        $type: AlertEnum!,
        $active: Boolean!,
        $input: AlertTypeInput!,
        $_id: String
    ) {
        saveAlert(alert: {
            name: $name,
            userId: $userId,
            _id: $_id,
            deviceToken: $deviceToken,
            type: $type,
            active: $active,
            input: $input
        }) {
          _id
          name
        }
    }
`;

export const DELETE_ALERT_MUTATION = gql`
    mutation DeleteAlert($id: String!) {
        deleteAlert(id: $id)
    }
`;
