export const ALERT_TYPES = {
    LAST_SEEN: 'LS',
    DATA_USAGE: 'DU',
    BATTERY_LEVEL: 'B',
};

export const BATTERY_OPERATORS = {
    GREATER_THAN: 'GT',
    LOWER_THAN: 'LT',
    GREATE_THAN_EQUAL: 'GTE',
    LOWER_THAN_EQUAL: 'LTE',
};

export const BATTERY_OPERATOR_LABELS = {
    [BATTERY_OPERATORS.GREATER_THAN]: 'Greater Than',
    [BATTERY_OPERATORS.LOWER_THAN]: 'Lower Than',
    [BATTERY_OPERATORS.GREATE_THAN_EQUAL]: 'Greater Than | Equal',
    [BATTERY_OPERATORS.LOWER_THAN_EQUAL]: 'Lower Than | Equal',
};

export const NOTIFICATIONS_TYPENAMES = {
    LAST_SEEN: 'LastSeenNotification',
    DATA_USAGE: 'DataUsageNotification',
    BATTERY: 'BatteryNotification',
};
