import styled from 'styled-components';

import { hexToRgb } from 'Utils';

export const Title = styled.div`
    margin-bottom: 10px;
    color: rgba(${({ theme }) => hexToRgb(theme.colors.title)}, 0.89);
    font-size: 22px;
    line-height: 27px;
    font-weight: bold;
`;

export const Content = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 10px;
`;

export const Item = styled.div`
    display: flex;
    align-items: center;

    & span:first-child {
        margin-right: 5px;
        font-size: 18px;
        line-height: 24px;
        font-weight: bold;
    }

    & span:last-child {
        font-size: 18px;
        line-height: 24px;
    }
`;

export const ContentLeft = styled.div`
    margin-right: 20%;
    margin-bottom: 20px;
`;

export const ContentRight = styled.div`
    margin-bottom: 20px;
`;

export const ContentTitle = styled.div`
    font-size: 18px;
    line-height: 24px;
    font-weight: bold;
`;

export const Device = styled.div`
    margin-top: 20px;
`;

export const Logs = styled.div`
    width: fit-content;
    max-height: 550px;
    background-color: ${({ theme }) => theme.colors.boxBackground};
    border-radius: 4px;

    & p {
        padding: 5px 10px;
        margin-bottom: 0;
        font-size: 16px;
        line-height: 20px;
    }
`;
