import styled from 'styled-components';

export const Wrapper = styled.div`
    margin-top: 36px;
`;

export const HeaderText = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-size: 16px;
`;
