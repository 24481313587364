import gql from 'graphql-tag';

export const FIRMWARE_QUERY = gql`
  query getFirmware {
    getLatestFirmware {
        version
        intVersion
        lastCheck
    }
  }
`;

export const DEVICE_FIRMWARE_QUERY = gql`
  query getDeviceFirmware($deviceToken: String!) {
    getFirmware(deviceToken: $deviceToken) {
        version
        intVersion
    }
  }
`;

export const FIRMWARE_UPDATED_SUBSCRIPTION = gql`
  subscription newFirmwareUpdated($deviceToken: String!) {
    newFirmwareUpdated(deviceToken: $deviceToken) {
        version,
        intVersion,
        deviceToken
    }
  }
`;

export const GET_FIRMWARE_UPDATES_AMOUNT = gql`
  query getFirmwareUpdatesAmount {
      getFirmwareUpdatesAmount
  }
`;

export const UPLOAD_FW_FILE = gql`
   mutation uploadFile($fileDetails: fileDetailsInput!){
         uploadFile(fileDetails: $fileDetails)
   }
`;
