import styled from 'styled-components';
import { Button } from 'Components';
import { hexToRgb } from 'Utils';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 25px 15px;

    h2 {
        margin: 10px auto;
        font-weight: 700;
        color: ${({ theme }) => theme.colors.title};
    }
`;

export const OptionBtn = styled(Button)`
    width: 80%;
    margin: 5px auto;

    &:hover {
        background-color: rgba(${({ theme }) => hexToRgb(theme.colors.title)}, 0.05);
    }
`;
