import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'formik';

import { Select } from '../../select';

export const FormContent = ({ ethernetOptions, values, handleSubmit, setFieldValue }) => {
    useEffect(() => {
        if (!values.ethernet && ethernetOptions.length) {
            setFieldValue('ethernet', ethernetOptions[0]?.value);
        }

        handleSubmit();
    }, [values.ethernet]);

    return (
        <Form>
            <Field
                name="ethernet"
                component={Select}
                values={ethernetOptions}
                cyData="ethernet-id-select"
            />
        </Form>
    );
};

FormContent.propTypes = {
    ethernetOptions: PropTypes.arrayOf(PropTypes.object),
    values: PropTypes.shape({
        ethernet: PropTypes.string,
    }).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
};

FormContent.defaultProps = {
    ethernetOptions: [],
};
