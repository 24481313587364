import styled from 'styled-components';
import { Alert as AntAlert } from 'antd';

export const Alert = styled(AntAlert)`
    border-radius: 4px;

    .ant-alert-error {
        border-color: ${({ theme }) => theme.colors.error};
    }

    .ant-alert-success {
        border-color: ${({ theme }) => theme.colors.success};
    }
`;
