import { useState, useEffect, useRef } from 'react';
import md5 from 'md5';
import PropTypes from 'prop-types';

import { syntaxHighlighting, pymakrStorageLimits } from 'Constants';
import {
    showToastInfo,
    showToastError,
    calculateLocalStorageSize,
    calculateStringSize,
    getMD5Hash,
    getDeviceModeInfo,
} from 'Utils';

import { useContextSelector, useResets } from '../../../../pymakrHooks';
import * as Styled from './styled';

export const CodeContainerEditor = ({ projectKey }) => {
    const [initialMD5, setInitialMD5] = useState(null);

    const colorizerSlice = useContextSelector('colorizer');
    const { setUpdatedFile, unsetUpdatedFile } = colorizerSlice.handlers;
    const { updatedFiles } = colorizerSlice.state;

    const tabsSlice = useContextSelector('tabs');
    const { activeTab } = tabsSlice.state;

    const { name: openedFile, key: storageKey } = activeTab;
    const deviceModeInfo = getDeviceModeInfo(projectKey);

    const placeholder = `${openedFile} --start writing your code here!`;
    const extension = openedFile?.indexOf('.') >= 0 ? openedFile?.slice(openedFile.lastIndexOf('.')) : '.make';
    const storedData = localStorage.getItem(storageKey) || '';
    
    const [fileData, setFileData] = useState(storedData);
    const [localStorageMemoryOccupied, setLocalStorageMemoryOccupied] = useState(null);
    const [memoryOccupied, setMemoryOccupied] = useState(null);
    const [warningShowed, setWarningShowed] = useState(false);
    const [containerHeight, setContainerHeight] = useState('100%');
    const [containerWidth, setContainerWidth] = useState('100%');

    const aceContainerRef = useRef(null);

    const updateMemoryLimiter = () => {
        const localStorageSize = calculateLocalStorageSize();
        const storageSize = calculateLocalStorageSize(projectKey);
        setLocalStorageMemoryOccupied(localStorageSize - calculateStringSize(storedData));
        setMemoryOccupied(storageSize - calculateStringSize(storedData));
    };

    useResets(projectKey, storageKey, initialMD5, {
        setInitialMD5,
        updateMemoryLimiter,
        setFileData,
    });

    useEffect(() => {
        let resizeObserver;

        if (deviceModeInfo && !initialMD5) {
            setInitialMD5(getMD5Hash(projectKey, activeTab.path));
        }

        if (aceContainerRef.current) {
            resizeObserver = new ResizeObserver((entries) => {
                const targetHeight = entries[0].contentRect.height - 12;
                const targetWidth = entries[0].contentRect.width;
                setContainerHeight(`${targetHeight}px`);
                setContainerWidth(`${targetWidth}px`);
            });
            resizeObserver.observe(aceContainerRef.current);
        }
    
        return () => {
            if (resizeObserver && aceContainerRef.current) {
                resizeObserver.unobserve(aceContainerRef.current);
            } else {
                resizeObserver.disconnect();
            }
        };
    }, []);

    useEffect(() => {
        if (fileData && initialMD5) {
            if (updatedFiles.includes(activeTab.path) && md5(fileData) === initialMD5) {
                unsetUpdatedFile(updatedFiles, activeTab.path);
            } else if (!updatedFiles.includes(activeTab.path) && md5(fileData) !== initialMD5) {
                setUpdatedFile(updatedFiles, activeTab.path);
            }
        }
    }, [fileData]);

    const handleEditorChange = (text) => {
        const currValueSize = calculateStringSize(text);
        const totalMemoryOccupied = memoryOccupied + currValueSize;
        const localStorageFillSize = localStorageMemoryOccupied + currValueSize;

        if (totalMemoryOccupied > pymakrStorageLimits.error || localStorageFillSize > pymakrStorageLimits.localStorage) {
            showToastError(
                'Error!. No memory left. Data will not be stored! Please, save your changes and refresh an editor.',
            );
            return;
        }

        if (totalMemoryOccupied > pymakrStorageLimits.warning && !warningShowed) {
            showToastInfo(
                'Warning! Memory is about to finish. Please, make sure to save changes, or try to free some memory.',
            );
            setWarningShowed(true);
        } else if (totalMemoryOccupied <= pymakrStorageLimits.warning && warningShowed) {
            setWarningShowed(false);
        }

        setFileData(text);
        localStorage.setItem(storageKey, text);
    };

    const mode = syntaxHighlighting[extension] || 'text';

    return (
        <Styled.Wrapper ref={aceContainerRef}>
            <Styled.Ace
                mode={mode}
                placeholder={placeholder}
                onChange={(value) => {
                    handleEditorChange(value);
                }}
                value={fileData}
                width={containerWidth}
                height={containerHeight}
                editorProps={{ $blockScrolling: false }}
                setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: true,
                    highlightActiveLine: true,
                    showLineNumbers: true,
                    autoScrollEditorIntoView: true,
                    behavioursEnabled: false,
                }}
            />
        </Styled.Wrapper>
    );
};

CodeContainerEditor.propTypes = {
    projectKey: PropTypes.string.isRequired,
};
