import styled from 'styled-components';

import { Button } from 'Components';

export const ModalWrapper = styled.div`
    padding: 32px 0;
    background-color: ${({ theme }) => theme.colors.boxBackground};

    h1 {
        color: ${({ theme }) => theme.colors.text};
        font-family: MainFont;
        line-height: 46.97px;
        text-align: center;
        margin-bottom: 48px;
        font-size: 30px;
    }
`;

export const ModalButtonsWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

export const ButtonWrapper = styled.div`
    background-color: #fff;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    width: 312px;
    max-width: 100%;
    height: 240px;
    max-height: 100%;
    padding: 48px 20px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
    letter-spacing: 0.36px;
    justify-content: space-between;
    cursor: pointer;

    &:first-child {
        margin-right: 57px;
    }
`;

export const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.colors.boxBackground};
    cursor: pointer;

    svg {
        font-size: 50px;
        color: ${({ theme }) => theme.colors.title}
    }
`;

export const NewButton = styled.div`
    position: absolute;
    top: 5px;
    right: 5px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.active};
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        font-size: 12px;
        color: ${({ theme }) => theme.colors.activeText};
    }
`;

export const ModalCloseBtn = styled(Button)`
    width: 20%;
    margin: 60px auto 0;
`;
