import { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { showToastError } from 'Utils';
import { ReactComponent as Download } from 'Assets/icons/download.svg';

import * as Styled from './styled';

export const BaseFileUploader = ({
    expectedExtensions,
    disabled,
    maxSize,
    defaultName,
    pymakrUpload,
    onChange,
}) => {
    const fileRef = useRef(null);
    const [selectedFileName, setSelectedFileName] = useState(null);
    const buttonOnClick = () => {
        fileRef?.current?.click();
    };

    useEffect(() => {
        if (pymakrUpload) {
            setSelectedFileName(defaultName);
        }
    }, [pymakrUpload]);

    const fileOnChange = (event) => {
        const file = event.target.files?.[0];
        
        const matchResult = file.name.match(/\.([^.]*)$/);
        console.log(matchResult);
        
        if (!file || !matchResult) {
            return;
        }

        const fileExtension = matchResult[1];

        if (maxSize && file.size >= maxSize) {
            const mbFileSize = Math.floor(file.size / 1000000);
            const mbMaxSize = Math.floor(maxSize / 1000000);
            showToastError(
                `You have selected a file of ${mbFileSize}MB. Your file should be less than ${mbMaxSize}MB`,
            );
        }
        if (!expectedExtensions.includes(fileExtension)) {
            showToastError(
                `You have selected a file of .${fileExtension}. But your file should be ${expectedExtensions}`,
            );
            return;
        }

        setSelectedFileName(file.name || defaultName);
        onChange(file);
    };

    const label =
        selectedFileName ||
        `No ${
            expectedExtensions ? `${expectedExtensions} ` : ''
        } file was selected`;

    return (
        <Styled.Wrapper>
            <Styled.FileInput
                type="file"
                ref={fileRef}
                onChange={fileOnChange}
                accept={expectedExtensions}
            />
            <Styled.Button
                isTextBold
                buttonType="filled"
                disabled={disabled}
                onClick={buttonOnClick}
                Icon={Download}
            >
                Upload
            </Styled.Button>
            <Styled.Label>{label}</Styled.Label>
        </Styled.Wrapper>
    );
};

BaseFileUploader.propTypes = {
    expectedExtensions: PropTypes.string,
    disabled: PropTypes.bool,
    maxSize: PropTypes.number,
    defaultName: PropTypes.string,
    onChange: PropTypes.func,
    pymakrUpload: PropTypes.bool,
};

BaseFileUploader.defaultProps = {
    expectedExtensions: '',
    disabled: false,
    maxSize: 0,
    defaultName: 'file.zip',
    onChange: () => {},
    pymakrUpload: false,
};
