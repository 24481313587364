import PropTypes from 'prop-types';
import { AlertBordered } from '../alertBordered';
import { Checkbox } from '../inputs';

import * as Styled from './styled';

export const Warning = ({ warn, handleCheckbox, title, checked }) => (
    <Styled.Wrapper>
        <AlertBordered 
            message={warn}
            title="Beware" 
        />
        <Checkbox
            value={title}
            title={title}
            checked={checked}
            handleCheckbox={handleCheckbox}
        />
    </Styled.Wrapper>
);

Warning.propTypes = {
    warn: PropTypes.string.isRequired,
    handleCheckbox: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
};
