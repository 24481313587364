import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { GET_APPLICATION_LAST_MESSAGES } from 'Constants';
import { getDataCountWidgetData } from 'Utils';
import { useMessageSubscription } from 'Hooks';

import { PressureWidget } from '../../../../widgets';

export const Pressure = ({ chartId, title }) => {
    const { data, loading, refetch } = useQuery(
        GET_APPLICATION_LAST_MESSAGES,
        {
            variables: { chartId },
        },
    );
    const projectMessages = data?.getApplicationLastMessages ?? [];

    const subscribeData = useMemo(() => {
        const deviceTokens = projectMessages.map((messageObj) => messageObj.device.token);
        const messagePin = projectMessages[0]?.lastMessage?.messagePin;

        return { deviceTokens, messagePin };
    }, [projectMessages]);

    useMessageSubscription(subscribeData.deviceTokens, subscribeData.messagePin, refetch);

    const widgetData = useMemo(
        () => getDataCountWidgetData(projectMessages),
        [projectMessages],
    );

    return (
        <PressureWidget
            isEmpty={loading || !projectMessages.length}
            data={widgetData.avg}
            devicesCount={widgetData.devicesCount}
            title={title}
        />
    );
};

Pressure.propTypes = {
    chartId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};
