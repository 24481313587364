import { useMemo, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';

import { Breadcrumbs, PageHeader, DeviceWidgetDescription } from 'Components';
import {
    BREADCRUMBS_LABELS,
    ROUTES,
    CREATE_DEVICE_CHART,
    GET_DEVICE_CHARTS_QUERY,
    CREATE_DEVICE_WIDGET,
} from 'Constants';
import { generateWidgetSpace, showToastError, showToastSuccess } from 'Utils';

import { DeviceWidgetList } from './deviceWidgetList';

const createWidgetSteps = {
    WIDGET_LIST: 0,
    WIDGET_DESCRIPTION: 1,
};

export const CreateDeviceWidget = () => {
    const history = useHistory();
    const [createDeviceWidget] = useMutation(CREATE_DEVICE_WIDGET);
    const [createChartMutation, { loading }] = useMutation(CREATE_DEVICE_CHART);
    const { deviceToken } = useParams();
    const chartsQuery = useQuery(
        GET_DEVICE_CHARTS_QUERY,
        { variables: { deviceToken } },
    );
    const dashboard = chartsQuery?.data?.getDeviceCharts
        ?.filter((chartItem) => !!chartItem.widget).map((chartItem) => cloneDeep(chartItem))
        ?? [];

    // Widget configuration
    const [widgetType, setWidgetType] = useState(null);

    // Steps
    const [step, setStep] = useState(createWidgetSteps.WIDGET_LIST);
    const goList = () => {
        setStep(createWidgetSteps.WIDGET_LIST);
    };

    const goDescription = () => {
        setStep(createWidgetSteps.WIDGET_DESCRIPTION);
    };

    // Submit
    const publishWidget = async (values) => {
        try {
            const { name, settings, type } = values;

            const widgetSpecificData = generateWidgetSpace(dashboard, widgetType);
            const widgetCreated = await createDeviceWidget({
                variables: { widget: { ...widgetSpecificData } },
            });
            const widgetId = widgetCreated?.data.createDeviceWidget._id;

            await createChartMutation({
                variables: {
                    chartInput: {
                        name,
                        settings,
                        deviceToken,
                        type: type || widgetType,
                        widget: widgetId,
                    },
                },
            });
            showToastSuccess('Widget created successfully');
            history.push(`${ROUTES.devices.main}/${deviceToken}`);
        } catch (err) {
            showToastError('Something went wrong during widget creation');
        }
    };

    const widgetDescriptionSubmit = (values) => publishWidget(values);

    const content = useMemo(() => {
        switch (step) {
            case createWidgetSteps.WIDGET_LIST:
                return (
                    <DeviceWidgetList
                        nextStep={goDescription}
                        setWidgetType={setWidgetType}
                    />
                );
            case createWidgetSteps.WIDGET_DESCRIPTION:
                return (
                    <DeviceWidgetDescription
                        nextStep={widgetDescriptionSubmit}
                        prevStep={goList}
                        widgetType={widgetType}
                        submitText="Add Widget"
                        loading={loading}
                    />
                );
            default:
                return null;
        }
    }, [step]);

    return (
        <>
            <Breadcrumbs
                labels={BREADCRUMBS_LABELS.createWidget}
            />
            <PageHeader
                title="Create Widget"
                step={step + 1}
                maxStep={2}
                stepper
            />
            {content}
        </>
    );
};
