import styled from 'styled-components';

import { TitleBox as TitleBoxComponent } from 'Components';

export const Description = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-size: 16px;
`;

export const TitleBox = styled(TitleBoxComponent)`
    margin-top: 35px;
`;

export const Tiles = styled.ul`
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;

    li {
        margin-bottom: 25px;
    }
`;
