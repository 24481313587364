import styled from 'styled-components';

export const Wrapper = styled.div`
    margin-top: 40px;
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 440px;
    margin-bottom: 10px;
`;

export const Network = styled.span`
    font-size: 24px;
    color: ${({ theme }) => theme.colors.title};
    line-height: 0;
`;
