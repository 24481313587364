import { ReactComponent as MapIcon } from 'Assets/widgets/preview/map.svg';
import { ReactComponent as MapHistoryIcon } from 'Assets/widgets/preview/map-history.svg';
import { ReactComponent as CountIcon } from 'Assets/widgets/preview/count.svg';
import { ReactComponent as HumidityIcon } from 'Assets/widgets/preview/humidity.svg';
import { ReactComponent as WeatherIcon } from 'Assets/widgets/preview/weather.svg';
import { ReactComponent as GroupedDevicesIcon } from 'Assets/widgets/preview/grouped-devices.svg';
import { ReactComponent as TemperatureIcon } from 'Assets/widgets/preview/temperature.svg';
import { ReactComponent as TableIcon } from 'Assets/widgets/preview/table.svg';
import { ReactComponent as PressureIcon } from 'Assets/widgets/preview/pressure.svg';
import { ReactComponent as AirQualityIcon } from 'Assets/widgets/preview/air-quality.svg';
import { ReactComponent as HeartbeatIcon } from 'Assets/widgets/preview/heartbeat.svg';
import { ReactComponent as LineIcon } from 'Assets/widgets/preview/line.svg';
import { ReactComponent as BarIcon } from 'Assets/widgets/preview/bar.svg';
import { ReactComponent as MountainIcon } from 'Assets/widgets/preview/mountain.svg';
import { ReactComponent as BatteryIcon } from 'Assets/widgets/preview/battery-status.svg';

export const COMMON_CHART_TYPES = {
    TABLE: 'TABLE',
    HUMIDITY: 'HUMIDITY',
    TEMPERATURE: 'TEMPERATURE',
    SIGNAL_QUALITY: 'SIGNAL_QUALITY',
    MAP: 'MAP',
    MAP_HISTORY: 'MAP_HISTORY',
    WEATHER: 'WEATHER',
    AIR_QUALITY: 'AIR_QUALITY',
    HEART_RATE: 'HEART_RATE',
    PRESSURE: 'PRESSURE',
    LINE_CHART: 'LINE_CHART',
    BAR_CHART: 'BAR_CHART',
    ALTITUDE: 'ALTITUDE',
    BATTERY_LEVEL: 'BATTERY_LEVEL',
};

export const CHART_TYPES = {
    ...COMMON_CHART_TYPES,
    DATA_RECEPTION_HOUR: 'DATA_RECEPTION_HOUR',
    DATA_RECEPTION_LINE_CHART: 'DATA_RECEPTION_LINE_CHART',
    DATA_RECEPTION_PER_PIN_LINE_CHART: 'DATA_RECEPTION_PER_PIN_LINE_CHART',
    DATA_RECEPTION_DAY: 'DATA_RECEPTION_DAY',
    DATA_RECEPTION_BAR_CHART: 'DATA_RECEPTION_BAR_CHART',
    DATA_RECEPTION_PER_PIN_BAR_CHART: 'DATA_RECEPTION_PER_PIN_BAR_CHART',
    DEVICE_LOCATION: 'DEVICE_LOCATION',
};

export const PROJECT_CHART_TYPES = {
    ...COMMON_CHART_TYPES,
    ONLINE_OFFLINE: 'ONLINE_OFFLINE',
    GROUPED_DEVICES: 'GROUPED_DEVICES',
    COUNT: 'COUNT',
};

export const CHARTS_BOUNDARIES = {
    [COMMON_CHART_TYPES.BAR_CHART]: {
        height: 3,
    },
    [COMMON_CHART_TYPES.LINE_CHART]: {
        height: 3,
    },
    [CHART_TYPES.DATA_RECEPTION_BAR_CHART]: {
        height: 3,
    },
    [CHART_TYPES.DATA_RECEPTION_LINE_CHART]: {
        height: 3,
    },
    [CHART_TYPES.DATA_RECEPTION_DAY]: {
        height: 3,
    },
    [CHART_TYPES.DATA_RECEPTION_HOUR]: {
        height: 3,
    },
    [CHART_TYPES.DATA_RECEPTION_PER_PIN_LINE_CHART]: {
        height: 3,
    },
    [CHART_TYPES.DATA_RECEPTION_PER_PIN_BAR_CHART]: {
        height: 3,
    },
    [COMMON_CHART_TYPES.TABLE]: {
        height: 3,
        width: 8,
        fullSize: true,
    },
    [COMMON_CHART_TYPES.MAP_HISTORY]: {
        height: 4,
        width: 8,
        fullSize: true,
    },
    [COMMON_CHART_TYPES.MAP]: {
        height: 4,
        width: 8,
        fullSize: true,
    },
    [COMMON_CHART_TYPES.ALTITUDE]: {
        height: 3,
    },
    [COMMON_CHART_TYPES.WEATHER]: {
        height: 3,
    },
    [CHART_TYPES.DATA_RECEPTION_DAY]: {
        height: 4,
        width: 4,
    },
    [CHART_TYPES.DATA_RECEPTION_HOUR]: {
        height: 4,
        width: 4,
    },
    [CHART_TYPES.DEVICE_LOCATION]: {
        height: 4,
        width: 8,
        fullSize: true,
    },
};

export const PROJECT_CHARTS_DEFAULT = [PROJECT_CHART_TYPES.ONLINE_OFFLINE];

export const DIMENSION_MULTIPLIERS = {
    width: 106.5,
    height: 106.5,
};

export const CHART_PREVIEW_DATA = {
    [CHART_TYPES.DATA_RECEPTION_PER_PIN_LINE_CHART]: {
        title: 'Data',
        textNoData: 'No data was received. Check if your device is connected.',
    },
    [CHART_TYPES.DATA_RECEPTION_LINE_CHART]: {
        title: 'Data',
        textNoData: 'No data was received. Check if your device is connected.',
    },
    [CHART_TYPES.DATA_RECEPTION_HOUR]: {
        title: 'Data',
        textNoData: 'No data was received during the last 60 minutes. Check if your device is connected.',
    },
    [CHART_TYPES.DATA_RECEPTION_PER_PIN_BAR_CHART]: {
        title: 'Data',
        textNoData: 'No data was received. Check if your device is connected.',
    },
    [CHART_TYPES.DATA_RECEPTION_BAR_CHART]: {
        title: 'Data',
        textNoData: 'No data was received. Check if your device is connected.',
    },
    [CHART_TYPES.DATA_RECEPTION_DAY]: {
        title: 'Data',
        textNoData: 'No data was received during the last day. Check if your device is connected.',
    },
    [CHART_TYPES.MAP]: {
        title: 'Map',
        textNoData: 'No location data. Please make sure your device is connected.',
    },
    [CHART_TYPES.DEVICE_LOCATION]: {
        title: 'Device location',
        textNoData: 'No location data. Please make sure your device is connected.',
    },
    [CHART_TYPES.LINE_CHART]: {
        title: 'Device status',
        textNoData: 'No data was received during the last 60 minutes. Check if your device is connected.',
    },
    [CHART_TYPES.BAR_CHART]: {
        title: 'Device status',
        textNoData: 'No data was received during the last 60 minutes. Check if your device is connected.',
    },
    [CHART_TYPES.TABLE]: {
        title: 'Data',
        textNoData: 'No data was received. Check if your device is connected.',
    },
    [CHART_TYPES.MAP_HISTORY]: {
        title: 'Device location history',
        textNoData: 'No location data.',
    },
    [CHART_TYPES.HUMIDITY]: {
        title: 'Humidity widget',
        textNoData: 'No device data.',
    },
    [CHART_TYPES.TEMPERATURE]: {
        title: 'CPU temperature',
        textNoData: 'No device data.',
    },
    [CHART_TYPES.WEATHER]: {
        title: 'Weather forecast',
        textNoData: 'No weather data.',
    },
    [CHART_TYPES.AIR_QUALITY]: {
        title: 'Air quality',
        textNoData: 'No air quality data.',
    },
    [CHART_TYPES.HEART_RATE]: {
        title: 'Heart rate',
        textNoData: 'No heart rate data.',
    },
    [CHART_TYPES.PRESSURE]: {
        title: 'Pressure',
        textNoData: 'No pressure data.',
    },
    [CHART_TYPES.ALTITUDE]: {
        title: 'Altitude',
        textNoData: 'No altitude data.',
    },
    [CHART_TYPES.SIGNAL_QUALITY]: {
        title: 'Signal Quality',
        textNoData: 'No signal quality data',
    },
    [CHART_TYPES.BATTERY_LEVEL]: {
        title: 'Battery Level',
        textNoData: 'No battery level data',
    },
};

export const PROJECT_CHART_PREVIEW_DATA = {
    [PROJECT_CHART_TYPES.MAP]: {
        title: 'Devices location',
        textNoData: 'No location data.',
    },
    [PROJECT_CHART_TYPES.MAP_HISTORY]: {
        title: 'Devices location history',
        textNoData: 'No location data.',
    },
    [PROJECT_CHART_TYPES.ONLINE_OFFLINE]: {
        title: 'Online/Offline',
        textNoData: 'No devices data.',
    },
    [PROJECT_CHART_TYPES.GROUPED_DEVICES]: {
        title: 'By criteria',
        textNoData: 'No devices data.',
    },
    [PROJECT_CHART_TYPES.COUNT]: {
        title: 'Devices Count',
        textNoData: 'No devices data',
    },
    [PROJECT_CHART_TYPES.TABLE]: {
        title: 'Last values',
        textNoData: 'No devices data.',
    },
    [PROJECT_CHART_TYPES.HUMIDITY]: {
        title: 'Humidity widget',
        textNoData: 'No devices data.',
    },
    [PROJECT_CHART_TYPES.TEMPERATURE]: {
        title: 'CPU temperature',
        textNoData: 'No devices data.',
    },
    [PROJECT_CHART_TYPES.SIGNAL_QUALITY]: {
        title: 'Signal Quality',
        textNoData: 'No devices data.',
    },
    [PROJECT_CHART_TYPES.WEATHER]: {
        title: 'Weather forecast',
        textNoData: 'No weather data.',
    },
    [PROJECT_CHART_TYPES.AIR_QUALITY]: {
        title: 'Air quality',
        textNoData: 'No air quality data.',
    },
    [PROJECT_CHART_TYPES.HEART_RATE]: {
        title: 'Heart rate',
        textNoData: 'No heart rate data.',
    },
    [PROJECT_CHART_TYPES.PRESSURE]: {
        title: 'Pressure',
        textNoData: 'No pressure data.',
    },
    [PROJECT_CHART_TYPES.LINE_CHART]: {
        title: 'Line chart',
        textNoData: 'No data.',
    },
    [PROJECT_CHART_TYPES.BAR_CHART]: {
        title: 'Bar chart',
        textNoData: 'No data.',
    },
    [PROJECT_CHART_TYPES.ALTITUDE]: {
        title: 'Altitude',
        textNoData: 'No altitude data.',
    },
    [PROJECT_CHART_TYPES.BATTERY_LEVEL]: {
        title: 'Battery level',
        textNoData: 'No battery level data.',
    },
};

export const DEFAULT_TABLE_CONFIGURATION = {
    name: 'Table',
    pullSize: 20,
    chart: 'TABLE',
    type: null,
    minTimeUnit: null,
    stacked: null,
    drawGridOnGraph: null,
    steppedLine: null,
    fill: null,
    displayPoints: null,
};

export const DEFAULT_BAR_CHART_CONFIGURATION = {
    name: 'Bar Chart',
    pullSize: 20,
    chart: 'BAR_CHART',
    type: 'time',
    minTimeUnit: 'second',
    stacked: false,
    drawGridOnGraph: false,
    steppedLine: null,
    fill: null,
    displayPoints: null,
};

export const DEFAULT_LINE_CHART_CONFIGURATION = {
    name: 'Line Chart',
    pullSize: 20,
    chart: 'LINE_CHART',
    type: 'time',
    minTimeUnit: 'second',
    stacked: false,
    drawGridOnGraph: false,
    steppedLine: false,
    fill: true,
    displayPoints: true,
};

export const GRID_SIZE = 12;

export const GRID_SCREEN_WIDTH = 1280;

export const TIME_OPTIONS = {
    FIVE_MINUTES: 300000,
    THIRTY_MUNITES: 1800000,
    ONE_HOUR: 3600000,
    SIX_HOURS: 21600000,
    TWELVE_HOURS: 43200000,
    TWENTY_FOUR_HOURS: 86400000,
};

export const TIME_SCALE = {
    SECOND: 'second',
    MINUTE: 'minute',
    HOUR: 'hour',
    DAY: 'day',
    WEEK: 'week',
    MONTH: 'month',
    QUARTER: 'quarter',
    YEAR: 'year',
};

export const LINE_CHART_TYPES = {
    LINE_CHART: 'Line chart',
    DATA_RECEPTION_HOUR: 'Past hour data',
    DATA_RECEPTION_LINE_CHART: 'Data received',
    DATA_RECEPTION_PER_PIN_LINE_CHART: 'Data received per pin',
};

export const BAR_CHART_TYPES = {
    BAR_CHART: 'Bar chart',
    DATA_RECEPTION_DAY: 'Past day data',
    DATA_RECEPTION_BAR_CHART: 'Data received',
    DATA_RECEPTION_PER_PIN_BAR_CHART: 'Data received per pin',
};

export const MAP_CHART_TYPES = {
    MAP: 'Map',
    DEVICE_LOCATION: 'Device location',
};

export const TIME_OPTIONS_LABELED = [
    {
        value: TIME_OPTIONS.FIVE_MINUTES,
        label: '5 minutes',
    },
    {
        value: TIME_OPTIONS.THIRTY_MUNITES,
        label: '30 minutes',
    },
    {
        value: TIME_OPTIONS.ONE_HOUR,
        label: '1 hour',
    },
    {
        value: TIME_OPTIONS.SIX_HOURS,
        label: '6 hours',
    },
    {
        value: TIME_OPTIONS.TWELVE_HOURS,
        label: '12 hours',
    },
    {
        value: TIME_OPTIONS.TWENTY_FOUR_HOURS,
        label: '24 hours',
    },
];

export const TIME_SCALE_LABELED = [
    {
        value: TIME_SCALE.SECOND,
        label: 'Second',
    },
    {
        value: TIME_SCALE.MINUTE,
        label: 'Minute',
    },
    {
        value: TIME_SCALE.HOUR,
        label: 'Hour',
    },
    {
        value: TIME_SCALE.DAY,
        label: 'Day',
    },
    {
        value: TIME_SCALE.WEEK,
        label: 'Week',
    },
    {
        value: TIME_SCALE.MONTH,
        label: 'Month',
    },
    {
        value: TIME_SCALE.QUARTER,
        label: 'Quarter',
    },
    {
        value: TIME_SCALE.YEAR,
        label: 'Year',
    },
];

export const PULL_SIZE_OPTIONS = {
    TWENTY: 20,
    FIFTY: 50,
    ONE_HUNDRED: 100,
    TWO_HUNDRED: 200,
};

export const PULL_SIZE_LABELED = [
    {
        value: PULL_SIZE_OPTIONS.TWENTY,
        label: 20,
    },
    {
        value: PULL_SIZE_OPTIONS.FIFTY,
        label: 50,
    },
    {
        value: PULL_SIZE_OPTIONS.ONE_HUNDRED,
        label: 100,
    },
    {
        value: PULL_SIZE_OPTIONS.TWO_HUNDRED,
        label: 200,
    },
];

export const ALTITUDE_UNITS = {
    METER: 'meter',
    YARD: 'yard',
};

export const TEMPERATURE_UNITS = {
    FAHRENHEIT: 'F',
    CELSIUS: 'C',
};

export const WEATHER_UNITS = {
    KELVIN: 'standard',
    CELSIUS: 'metric',
    FAHRENHEIT: 'imperial',
};

export const WEATHER_UNIT_OPTIONS = [
    {
        value: WEATHER_UNITS.KELVIN,
        label: 'K',
    },
    {
        value: WEATHER_UNITS.CELSIUS,
        label: 'C',
    },
    {
        value: WEATHER_UNITS.FAHRENHEIT,
        label: 'F',
    },
];

export const PREVIEW_COMMON_WIDGET_CONFIG = [
    {
        type: COMMON_CHART_TYPES.MAP_HISTORY,
        name: 'Map History',
        icon: MapHistoryIcon,
    },
    {
        type: COMMON_CHART_TYPES.HUMIDITY,
        name: 'Humidity',
        icon: HumidityIcon,
        cyData: 'project-widget-preview-humidity',
    },
    {
        type: COMMON_CHART_TYPES.WEATHER,
        name: 'Weather',
        icon: WeatherIcon,
        cyData: 'project-widget-preview-weather',
    },
    {
        type: COMMON_CHART_TYPES.TEMPERATURE,
        name: 'Temperature',
        icon: TemperatureIcon,
        cyData: 'project-widget-preview-temperature',
    },
    {
        type: PROJECT_CHART_TYPES.SIGNAL_QUALITY,
        name: 'Signal Quality',
        icon: TemperatureIcon,
        cyData: 'project-widget-preview-signal-quality',
    },
    {
        type: COMMON_CHART_TYPES.TABLE,
        name: 'Table',
        icon: TableIcon,
        cyData: 'project-widget-preview-table',
    },
    {
        type: COMMON_CHART_TYPES.PRESSURE,
        name: 'Pressure',
        icon: PressureIcon,
        cyData: 'project-widget-preview-pressure',
    },
    {
        type: COMMON_CHART_TYPES.AIR_QUALITY,
        name: 'Air Quality',
        icon: AirQualityIcon,
        cyData: 'project-widget-preview-air-quality',
    },
    {
        type: COMMON_CHART_TYPES.HEART_RATE,
        name: 'Heart Rate',
        icon: HeartbeatIcon,
        cyData: 'project-widget-preview-heart-rate',
    },
    {
        type: COMMON_CHART_TYPES.LINE_CHART,
        name: 'Line chart',
        icon: LineIcon,
        cyData: 'project-widget-preview-line-chart',
    },
    {
        type: COMMON_CHART_TYPES.BAR_CHART,
        name: 'Bar chart',
        icon: BarIcon,
        cyData: 'project-widget-preview-bar-chart',
    },
    {
        type: COMMON_CHART_TYPES.ALTITUDE,
        name: 'Altitude',
        icon: MountainIcon,
        cyData: 'project-widget-preview-altitude',
    },
    {
        type: PROJECT_CHART_TYPES.BATTERY_LEVEL,
        name: 'Battery level',
        icon: BatteryIcon,
        cyData: 'project-widget-preview-battery-level',
    },
];

export const PREVIEW_DEVICE_WIDGET_CONFIG = [
    ...PREVIEW_COMMON_WIDGET_CONFIG,
    {
        type: CHART_TYPES.MAP,
        name: 'Map',
        icon: MapIcon,
    },
];

export const PREVIEW_PROJECT_WIDGET_CONFIG = [
    ...PREVIEW_COMMON_WIDGET_CONFIG,
    {
        type: PROJECT_CHART_TYPES.GROUPED_DEVICES,
        name: 'Grouped Devices',
        icon: GroupedDevicesIcon,
    },
    {
        type: PROJECT_CHART_TYPES.COUNT,
        name: 'Count',
        icon: CountIcon,
    },
    {
        type: PROJECT_CHART_TYPES.MAP,
        name: 'Map',
        icon: MapIcon,
    },
];
