import { Form, Field } from 'formik';
import PropTypes from 'prop-types';

import { Button } from 'Components/buttons';
import { TextInput } from 'Components/inputs';
import * as Styled from './styled';

export const FormContent = ({ handleClose, isLoading }) => (
    
        <Form>
            <Styled.Form>
                <Styled.Item>
                    <Styled.InputTitle>
                        Order number
                    </Styled.InputTitle>
                    <Field 
                        name="orderNumber"
                        type="text"
                        component={TextInput}
                        errorPosition="bottom"
                    />
                </Styled.Item>
            </Styled.Form>

            <Styled.ButtonsWrapper>
                <div>
                    <Button
                        buttonType="transparent"
                        onClick={handleClose}
                        cyData="add-simcard-cancel"
                    >
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        loading={isLoading}
                        cyData="add-simcard-submit"
                    >
                        Add sim card
                    </Button>
                </div>
            </Styled.ButtonsWrapper>
        </Form> 
);

FormContent.propTypes = {
    handleClose: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
};

FormContent.defaultProps = {
    isLoading: false,
};
