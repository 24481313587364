import { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { Warning } from 'Components';
import { DELETE_APPLICATION, GET_APPLICATIONS, ROUTES } from 'Constants';
import { showToastSuccess } from 'Utils';

import * as Styled from './styled';

export const ProjectDeleteForm = ({ project }) => {
    const history = useHistory();
    const [deleteProject, { loading: isLoading }] = useMutation(DELETE_APPLICATION, { refetchQueries: [GET_APPLICATIONS] });

    const title = `I understand that project ${project.name} and all its data will be deleted`;
    const warningMessage = 'Beware that this project and all devices with its received signal data will be deleted. '
        + 'This action cannot be undone!';

    const [agreedForDeletion, setAgreedForDeletion] = useState(false);

    const handleCheckbox = (event) => {
        const checked = event.target.checked;

        setAgreedForDeletion(checked);
    };

    const onClick = () => {
        deleteProject({
            variables: { id: project._id },
            update: () => {
                history.push(ROUTES.projects.main);
                showToastSuccess('Project deleted successfully');
            },
        });
    };

    return (
        <>
            <Warning
                warn={warningMessage}
                checked={agreedForDeletion}
                handleCheckbox={handleCheckbox}
                title={title}
            />
            <Styled.Button
                disabled={!agreedForDeletion}
                onClick={onClick}
                loading={isLoading}
            >
                Delete project
            </Styled.Button>
        </>
    );
};

ProjectDeleteForm.propTypes = {
    project: PropTypes.object.isRequired,
};
