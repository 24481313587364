import * as Yup from 'yup';

import { LORA_CONSTANTS } from 'Constants';

export const validationSchema = Yup.object().shape({
    loraServer: Yup.string(),
    usingABP: Yup.string(),
    loraRegion: Yup.string(),
});

export const initialValues = {
    loraServer: LORA_CONSTANTS.SERVERS.TTN,
    usingABP: LORA_CONSTANTS.RADIO_BUTTONS[0].value,
    loraRegion: LORA_CONSTANTS.REGIONS.CHIRPSTACK[0].value,
};
