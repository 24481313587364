import styled from 'styled-components';

import { NavLink } from 'react-router-dom';

export const Wrapper = styled.div`
    display: flex;
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 17px;

    & div {
        margin-right: 15px;
    }
`;

export const ActiveBreadcrumb = styled(NavLink)`
    color: ${({ theme }) => theme.colors.active};
    margin-right: 15px;
`;

export const InactiveBreadcrumb = styled.div`
    color: ${({ theme }) => theme.colors.title};
`;
