import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Button } from 'Components';
import { ROUTES } from 'Constants';

import * as Styled from './styled';

export const CreatePymesh = ({ applicationId, isEmpty }) => {
    const history = useHistory();

    const handleCreatePymesh = () => history.push(`${ROUTES.pymesh.add}/${applicationId}`);

    const message = isEmpty
        ? 'The Pymesh cannot be created yet, there are no devices added in the project.'
        : (
            <>
                <div>There is no Mesh created for the current Project</div>
                <div>Press Create Mesh button to set up your first Mesh.</div>
            </>
        );

    return (
        <Styled.Wrapper>
            <Styled.Header>
                Create your first pymesh
            </Styled.Header>
            <Styled.Message>
                {message}
            </Styled.Message>
            {!isEmpty && (
                <Button onClick={handleCreatePymesh}>
                    Create Pymesh
                </Button>
            )}
        </Styled.Wrapper>
    );
};

CreatePymesh.defaultProps = {
    isEmpty: false,
};

CreatePymesh.propTypes = {
    applicationId: PropTypes.string.isRequired,
    isEmpty: PropTypes.bool,
};
