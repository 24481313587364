/* eslint-disable react/jsx-filename-extension */
import { ReactComponent as BrokenCloudsIcon } from 'Assets/widgets/weather/brokenClouds.svg';
import { ReactComponent as ScatteredCloudsIcon } from 'Assets/widgets/weather/scatteredClouds.svg';
import { ReactComponent as FewCloudsIcon } from 'Assets/widgets/weather/fewClouds.svg';
import { ReactComponent as ClearSkyIcon } from 'Assets/widgets/weather/clearSky.svg';
import { ReactComponent as ThunderstormIcon } from 'Assets/widgets/weather/thunderstorm.svg';
import { ReactComponent as ShowerRainIcon } from 'Assets/widgets/weather/showerRain.svg';
import { ReactComponent as RainIcon } from 'Assets/widgets/weather/rain.svg';
import { ReactComponent as SnowIcon } from 'Assets/widgets/weather/snow.svg';
import { ReactComponent as MistIcon } from 'Assets/widgets/weather/mist.svg';

export const units = {
    standard: 'K',
    metric: '°C',
    imperial: '°F',
};

export const icons = {
    '01d': <ClearSkyIcon />,
    '01n': <ClearSkyIcon />,
    '02d': <FewCloudsIcon />,
    '02n': <FewCloudsIcon />,
    '03d': <ScatteredCloudsIcon />,
    '03n': <ScatteredCloudsIcon />,
    '04d': <BrokenCloudsIcon />,
    '04n': <BrokenCloudsIcon />,
    '09d': <ShowerRainIcon />,
    '09n': <ShowerRainIcon />,
    '10d': <RainIcon />,
    '10n': <RainIcon />,
    '11d': <ThunderstormIcon />,
    '11n': <ThunderstormIcon />,
    '13d': <SnowIcon />,
    '13n': <SnowIcon />,
    '50d': <MistIcon />,
    '50n': <MistIcon />,
};
