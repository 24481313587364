import PropTypes from 'prop-types';

import * as Styled from './styled';

export const EmptyPymeshView = ({ title, message }) => (
    <Styled.EmptyPymesh>
        <h3>
            {title}
        </h3>
        <p>
            {message}
        </p>
    </Styled.EmptyPymesh>
);

EmptyPymeshView.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
};
