import { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Warning, Button, AccessKeysModal, Modal } from 'Components';
import { GET_ACCOUNT_ACCESS_ID, GENERATE_ACCOUNT_ACCESS_KEYS } from 'Constants';

import * as Styled from './styled';

const warningMessage = 'Beware that by regenerating the key you will permanently erase previous key pair.';
const title = 'I understand all received to this project data will be deleted';

export const AccountAccessKey = () => {
    const [warningConfirmed, setWarningConfirmed] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalError, setModalError] = useState(null);
    const [keys, setKeys] = useState({});

    const { data: accessToken } = useQuery(GET_ACCOUNT_ACCESS_ID);
    const token = accessToken?.getAccountAccessToken?.accessId;

    const buttonTitle = token ? 'Regenerate' : 'Generate';

    const [generateAccessKeys, { loading: isLoading }] = useMutation(GENERATE_ACCOUNT_ACCESS_KEYS, {
        refetchQueries: [GET_ACCOUNT_ACCESS_ID],
        awaitRefetchQueries: true,
    });

    const onConfirmWarning = () => {
        setWarningConfirmed(!warningConfirmed);
    };

    const onModalClose = () => {
        setWarningConfirmed(false);
        setModalOpen(false);
    };

    const handleGenerateAccessKeys = async () => {
        try {
            await generateAccessKeys({
                update: (cache, results) => {
                    const { data: { generateAccountAccessKeys: accessKeys } } = results;
                    setKeys(accessKeys);
                },
            });
        } catch (error) {
            setModalError(error.message);
        } finally {
            setModalOpen(true);
        }
    };

    return (
        <>
            {token && (
                <Warning
                    warn={warningMessage}
                    checked={warningConfirmed}
                    handleCheckbox={onConfirmWarning}
                    title={title}
                />
            )}
            <Styled.Wrapper>
                <p>Access ID:</p>
                <Styled.TokenWrapper>
                    <Styled.TokenField>
                        {token}
                    </Styled.TokenField>
                    <Button
                        disabled={token && !warningConfirmed}
                        onClick={handleGenerateAccessKeys}
                        loading={isLoading}
                    >
                        {buttonTitle}
                    </Button>
                </Styled.TokenWrapper>
            </Styled.Wrapper>
            <Modal isOpened={modalOpen} handleClose={onModalClose}>
                <AccessKeysModal 
                    keys={keys}
                    error={modalError}
                    source="account"
                    onClose={onModalClose}
                />
            </Modal>
        </>
    );
};
