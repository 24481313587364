import styled from 'styled-components';

export const Pre = styled.pre`
    padding: 1rem;
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    background-color: rgba(7, 1, 19, 1);
    color: #66BB6A;
    max-width: 750px;
`;
