import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { ROUTES } from 'Constants';

import * as Styled from './styled';

export const DevicesTable = ({ devicesArr }) => {
    const history = useHistory();

    const deviceOnClick = (token) => {
        if (!token) {
            return;
        }
        const { main, deviceDetails } = ROUTES.devices;
        history.push(`${main}/${token}${deviceDetails.dashboard}`);
    };

    if (!devicesArr.length) {
        return null;
    }

    return (
        <Styled.DetailsWrapper>
            <Styled.DetailsHead disabled>
                <span>Details</span>
                <span>Token</span>
            </Styled.DetailsHead>
            {devicesArr.map((item) => (
                <Styled.DetailsLine
                    disabled={!item._id}
                    key={item._id}
                    onClick={() => deviceOnClick(item.token)}
                >
                    <span>
                        {item.metadata?.deviceName ?? 'Deleted device'}
                    </span>
                    <span>
                        {item.deviceToken ?? ''}
                    </span>
                </Styled.DetailsLine>
            ))}
        </Styled.DetailsWrapper>
    );
};

DevicesTable.propTypes = {
    devicesArr: PropTypes.array.isRequired,
};
