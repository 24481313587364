import isEmpty from 'lodash/isEmpty';

export const getPayload = (samples) => {
    const payload = samples.reduce((acc, item) => {
        if (!isEmpty(item.message)) {
            const data = JSON.parse(item.message.payload).data;
            acc.push([data[0] * 1, data[1] * 1, data[2] * 1]);
        }

        return acc;
    }, []);

    return payload;
};

export const getSampleTestData = ({ mlSamples, samples, mlSampleId }) => {
    const result = mlSamples.filter((item) => item._id === mlSampleId)
        .map((rootItem) => {
            const sample = {
               sample_name: rootItem.sampleName,
               label: '',
               data: getPayload(samples.filter((item) => item.mlSampleId === rootItem._id)),
            };

            return sample;
        })
        .filter((item) => item.data.length);

    return { samples: result };
};
