import { useMemo, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useApolloClient } from '@apollo/client';

import {
    RUN_MODEL,
    PROCESSING_STATUS,
    SET_MODEL_DEFINITION,
    SET_TRAINING,
    SET_TESTING,
    GET_ML_MODEL_BY_ID_QUERY,
    ML_RESPONSE_STATUSES,
} from 'Constants';
import { showToastError } from 'Utils';

import { getInitialValues, validationSchema } from './config';
import { FormContent } from './formContent';
import { getNNBlock, getData } from './helpers';

export const NeuralNetworkSettings = ({
    mlSamples,
    samples,
    model,
    logsList,
    setLogsList,
    preProcessingStatus,
    modelDefinition,
    loading,
    setLoading,
}) => {
    const apolloClient = useApolloClient();
    const intervalRef = useRef(null);

    const [logIndex, setLogIndex] = useState(0);

    const initialValues = useMemo(
        () => getInitialValues(mlSamples.length, modelDefinition, preProcessingStatus),
        [mlSamples.length, modelDefinition, preProcessingStatus],
    );

    const clearIntervalRef = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
    };

    useEffect(() => () => clearIntervalRef(), []);

    const processingStatusHandler = async (taskId, nnBlock) => {
        const regNumPositive = new RegExp(/^[0-9][0-9]*/);

        const { data: { processingStatus } } = await apolloClient.query({
            query: PROCESSING_STATUS,
            variables: {
                taskId,
                logIndex,
            },
            fetchPolicy: 'network-only',
        });

        const preProcessingStatusObj = JSON.parse(processingStatus);
        const logs = preProcessingStatusObj.logs;
        const lastLog = logs?.[logs.length - 1];

        if (lastLog) {
            const splittedlastLog = lastLog.split(',');

            if (splittedlastLog !== logIndex) {
                const newLogIndex = regNumPositive.test(splittedlastLog[0]) ? splittedlastLog[0] : 0;

                setLogIndex(newLogIndex);

                const newLogs = logs.map((log) => log.split(','));
                const combinedLogs = [...logsList, ...newLogs];

                const uniqueLogs = [...new Map(combinedLogs.map((log) => [log[0], log])).values()];

                setLogsList(uniqueLogs);
            }
        }

        if (preProcessingStatusObj.state === ML_RESPONSE_STATUSES.SUCCESS) {
            const trainedNnModel = preProcessingStatusObj.output.NN.model;
            const localModelDefinition = { ...modelDefinition };

            // define trained_nn_model
            const localNNBlock = { ...nnBlock };
            localNNBlock.trained_nn_model = trainedNnModel;

            // remove old NN and add new
            localModelDefinition.blocks = localModelDefinition.blocks.filter((block) => block.id !== 'NN');
            localModelDefinition.blocks.push(localNNBlock);

            try {
                await apolloClient.mutate({
                    mutation: SET_MODEL_DEFINITION,
                    variables: {
                        modelId: model._id,
                        modelDefinition: JSON.stringify(localModelDefinition),
                    },
                });
    
                await apolloClient.mutate({
                    mutation: SET_TRAINING,
                    variables: {
                        modelId: model._id,
                        training: processingStatus,
                    },
                });
    
                await apolloClient.mutate({
                    mutation: SET_TESTING,
                    variables: {
                       modelId: model._id,
                       testing: JSON.stringify({}),
                    },
                    refetchQueries: [GET_ML_MODEL_BY_ID_QUERY],
                });
            } catch (error) {
                console.log(error);
                showToastError(error.message);
            } finally {
                clearIntervalRef();
                setLogIndex(0);
                setLoading(false);
            }
        }

        if (preProcessingStatusObj.state === ML_RESPONSE_STATUSES.FAILURE
            || preProcessingStatusObj.state === ML_RESPONSE_STATUSES.RETRY) {
            clearIntervalRef();
            setLogIndex(0);
            setLoading(false);
        }
    };

    const onSubmit = async (values) => {
        setLoading(true);

        const localModelDefinition = { ...modelDefinition };

        const nnBlock = getNNBlock(values);

        const data = getData(mlSamples, samples);

        const pp = localModelDefinition.blocks.find((block) => block.id === 'PP');
        const sp = localModelDefinition.blocks.find((block) => block.id === 'SP');
        sp.features.fft.request_fft = false;
        localModelDefinition.blocks = [pp, sp, nnBlock];

        const { data: { runModel } } = await apolloClient.query({
            query: RUN_MODEL,
            variables: {
                modelDefinition: JSON.stringify(localModelDefinition),
                data: JSON.stringify(data),
                withLog: true,
            },
        });

        const result = JSON.parse(runModel);
        intervalRef.current = setInterval(
            () => processingStatusHandler(result.task_id, nnBlock),
            1000,
        );
    };

    return (
        <>
            <h2>Spectral Analysis Parameters</h2>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {(props) => <FormContent {...props} loading={loading} />}
            </Formik>
        </>
    );
};

NeuralNetworkSettings.propTypes = {
    model: PropTypes.object.isRequired,
    mlSamples: PropTypes.array,
    samples: PropTypes.array,
    logsList: PropTypes.array,
    setLogsList: PropTypes.func.isRequired,
    preProcessingStatus: PropTypes.object.isRequired,
    modelDefinition: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    setLoading: PropTypes.func.isRequired,
};

NeuralNetworkSettings.defaultProps = {
    mlSamples: [],
    samples: [],
    logsList: [],
};
