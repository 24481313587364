import { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useSubscription } from '@apollo/client';

import { GET_DEVICE_STATUS, LAST_SEEN_SUBSCRIPTION } from 'Constants';
import { getTimeDiff } from 'Utils';

export const LastConnectionDevice = ({ deviceToken }) => {
    const [currentTimeText, setCurrentTimeText] = useState('');

    const { data: getDeviceStatusData } = useQuery(GET_DEVICE_STATUS, {
        variables: { deviceToken },
    });
    const { data: lastSeenSubscriptionData } = useSubscription(LAST_SEEN_SUBSCRIPTION, {
        variables: {
            deviceToken,
        },
    });

    const lastSeenListData = getDeviceStatusData?.getDeviceStatus?.lastSeen;
    const lastSeenSubData = lastSeenSubscriptionData?.lastSeenUpdated?.lastSeen ?? '';

    const dateToUse = useMemo(() => {
        if (!lastSeenSubData) {
            return +lastSeenListData;
        }
        return lastSeenSubData;
    }, [lastSeenSubData, lastSeenListData]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const newTimeText = getTimeDiff(dateToUse, 'Never connected');
            setCurrentTimeText(newTimeText);
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [dateToUse]);

    return <div>{currentTimeText}</div>;
};

LastConnectionDevice.propTypes = {
    deviceToken: PropTypes.string.isRequired,
};
