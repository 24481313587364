import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 40px;
    height: 5px;
    display: flex;
`;

export const ColoredSpan = styled.span`
    width: 20px;
    background-color: ${({ theme }) => theme.colors.active};
`;

export const BackgroundSpan = styled.span`
    width: 20px;
    background-color: ${({ theme }) => theme.colors.border};
`;
