import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Polyline } from 'react-google-maps';

import { getRandomColor } from 'Utils';

import { CustomMarker } from './customMarker';

export const CustomPath = ({ locations }) => {
    const currentColor = getRandomColor();
    const lastLocation = locations.slice(-1)[0];
    const firstLocation = locations[0];

    const intermediateMarkers = useMemo(() => {
        const itermediatePoints = locations.slice(1, -1);
        return itermediatePoints.map((marker, index) =>
            (
                <CustomMarker
                    position={marker}
                    typeOfIcon="between"
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    color={currentColor}
                />
            ));
    }, [locations]);

    return (
        <>
            <CustomMarker
                position={firstLocation}
                typeOfIcon="start"
                color={currentColor}
            />
            <Polyline
                options={{
                    strokeColor: currentColor,
                    strokeOpacity: 1.0,
                    strokeWeight: 2,
                }}
                path={locations}
            />
            {intermediateMarkers}
            <CustomMarker
                position={lastLocation}
                typeOfIcon="end"
                color={currentColor}
            />
        </>
    );
};

CustomPath.propTypes = {
    locations: PropTypes.array,
};

CustomPath.defaultProps = {
    locations: [],
};
