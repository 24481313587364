import { Form, Field } from 'formik';
import PropTypes from 'prop-types';

import {
    ML_LEARNING_BLOCK_TECHNIQUE_LIST,
    ML_PROCESSING_BLOCK_TYPE_LIST,
} from 'Constants';

import { Button } from '../../../buttons';
import { Select } from '../../../select';
import * as Styled from '../styled';

export const FormContent = ({
    isValid,
    values,
    onBack,
    hideBackButton,
    submitText,
    loading,
    dirty,
    disabledOnPristine,
}) => (
    <Form>
        <Styled.FormContent>
            <Styled.Fieldset>
                <Styled.Legend>
                    Processing Block
                </Styled.Legend>
                <Styled.ItemLabel>
                    Type
                </Styled.ItemLabel>
                <Styled.InputSelect>
                    <Field
                        name="processingType"
                        component={Select}
                        values={ML_PROCESSING_BLOCK_TYPE_LIST}
                        disabled
                    />
                </Styled.InputSelect>
            </Styled.Fieldset>

            <Styled.Fieldset>
                <Styled.Legend>
                    Learning Block
                </Styled.Legend>
                <Styled.ItemLabel>
                    Technique
                </Styled.ItemLabel>
                <Styled.InputSelect>
                    <Field
                        name="learningTechnique"
                        component={Select}
                        values={ML_LEARNING_BLOCK_TECHNIQUE_LIST}
                        disabled
                    />
                </Styled.InputSelect>
            </Styled.Fieldset>
        </Styled.FormContent>
        <Styled.ButtonsWrapper>
            {!hideBackButton && (
                <Button
                    buttonType="transparent"
                    onClick={() => onBack(values)}
                >
                    Back
                </Button>
            )}
            <Button
                type="submit"
                disabled={!isValid || (disabledOnPristine && !dirty)}
                loading={loading}
            >
                {submitText}
            </Button>
        </Styled.ButtonsWrapper>
    </Form>
);

FormContent.propTypes = {
    isValid: PropTypes.bool.isRequired,
    onBack: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
    hideBackButton: PropTypes.bool.isRequired,
    submitText: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    dirty: PropTypes.bool.isRequired,
    disabledOnPristine: PropTypes.bool.isRequired,
};
