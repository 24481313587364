import moment from 'moment';

import { Dropdown } from 'Components';

export const getColumns = ({ deployRelease, deleteRelease }) => {
    const dropdownActions = [
        {
            executedFunction: deployRelease,
            title: 'Deploy',
        },
        {
            executedFunction: deleteRelease,
            title: 'Delete',
        },
    ];

    return (
        [
            {
                title: 'Release version',
                dataIndex: 'releaseVersion',
            },
            {
                title: 'Time and Dates',
                dataIndex: 'createdAt',
                render: (data) => (data ? moment(+data).format('HH:mm DD.MM.YYYY') : '-'),
            },
            {
                title: 'FW',
                dataIndex: 'firmware',
                render: (data) => data || 'Custom',
            },
            {
                title: 'Summary',
                dataIndex: 'summary',
            },
            {
                title: 'Actions',
                dataIndex: '_id',
                render: (id) => (
                    <Dropdown
                        actions={dropdownActions}
                        id={id}
                    />
                ),
            },
        ]
    );
};
