import { useState } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { GET_DEVICES_STANDALONE, ROUTES } from 'Constants';
import { deviceColumns, useLastConnection } from 'Components';
import { ReactComponent as USB } from 'Assets/devices/usb.svg';

import { ExportUploadDeviceAction } from './exportUploadDeviceAction';
import { RedirectView, TableView, ErrorView, LoadingView } from './tableViews';

export const ExportUploadDevice = ({ cleanStorage, files, onClose }) => {
    const [selectedDevice, setSelectedDevice] = useState('');
    const { data, error, loading } = useQuery(GET_DEVICES_STANDALONE);
    const devices = data?.getDevicesStandalone ?? [];
    const { data: tableData, loading: loadingTable } = useLastConnection(devices);

    if (error) {
        return <ErrorView />;
    }

    if (loading) {
        return <LoadingView />;
    }

    if (selectedDevice) {
        return (
            <ExportUploadDeviceAction
                cleanStorage={cleanStorage}
                files={files}
                device={selectedDevice.token}
                onClose={onClose}
            />
        );
    }

    if (!devices.length) {
        return (
            <RedirectView
                icon={<USB />}
                title="Add Devices"
                subtitle="Add Device Via USB"
                route={ROUTES.devices.add}
            />
        );
    }

    return (
        <TableView
            title="Choose a device"
            columns={deviceColumns}
            items={tableData}
            onSelect={setSelectedDevice}
            loading={loadingTable}
        />
    );
};

ExportUploadDevice.propTypes = {
    files: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    cleanStorage: PropTypes.func.isRequired,
};
