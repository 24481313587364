import { useState, useEffect, useRef } from 'react';
import { useSubscription, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import { Checkbox } from 'Components';
import {
    UPDATE_FCOTA_FILE_MUTATION_NO_RESET,
    FCOTA_PING_SUBSCRIPTION,
    PING_FCOTA_MUTATION,
    pymakrConnectionStates,
} from 'Constants';

import * as Styled from './styled';

export const ExportUploadDeviceAction = ({ cleanStorage, files, device, onClose }) => {
    const [connectionState, setConnectionState] = useState(pymakrConnectionStates.offline);
    const [hasFinished, setHasFinished] = useState(false);
    const [receptionActivity, setReceptionActivity] = useState('');
    const [currentUploadFile, setCurrentUploadFile] = useState('');
    const [emissionActivity, setEmissionActivity] = useState('');
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [hasPinged, setHasPinged] = useState(false);

    const connectionStateTimeout = useRef(null);
    const emissionActivityTimeout = useRef(null);
    const receptionActivityTimeout = useRef(null);
    const pingTimeout = useRef(null);

    const [pingFCOTAMutation] = useMutation(PING_FCOTA_MUTATION);
    const [updateFCOTAFileNoUpdate] = useMutation(UPDATE_FCOTA_FILE_MUTATION_NO_RESET);

    const waitingForResponse = () => {
        if (connectionState !== pymakrConnectionStates.restarting) {
            clearTimeout(connectionStateTimeout.current);
            connectionStateTimeout.current = setTimeout(() => {
                setConnectionState(pymakrConnectionStates.offline);
            }, 8000);
        }
    };

    const closeWithCleanUp = () => {
        onClose();

        if (hasFinished) {
            cleanStorage();
        }    
    };

    const pingSent = (activity) => {
        if (hasPinged) {
            setHasPinged(false);
        }

        if (activity && (activity !== 'ping' || !emissionActivity)) {
            clearTimeout(emissionActivityTimeout.current);
            setEmissionActivity('Waiting for device');

            emissionActivityTimeout.current = setTimeout(() => {
                setEmissionActivity('Waiting for device');
            }, 6000);
        }

        waitingForResponse();
    };

    const updateFileContent = (path, newContent) => {
        if (!path.includes('.pymakr')) {
            pingSent('update file');
            waitingForResponse();
        }

        updateFCOTAFileNoUpdate({ variables: { deviceToken: device, path, content: newContent } });
    };

    const uploadFirstFile = (file) => {
        if (file) {
            updateFileContent(file.path, file.content);
            setCurrentUploadFile(file.path);
        } else {
            setHasFinished(true);
        }
    };

    const pingReceived = (activity) => {
        clearTimeout(connectionStateTimeout.current);

        let newIndex = currentIndex;

        if (!activity.includes('acquiring hierarchy') || (activity.includes('acquiring hierarchy') && !hasPinged)) {
            newIndex += 1;
            if (hasFinished) {
                setHasFinished(newIndex >= filesToUpload.length);
            } 
            if (currentIndex <= filesToUpload.length) {
                uploadFirstFile(filesToUpload[currentIndex]);
            }
            setConnectionState(pymakrConnectionStates.online);
            setHasPinged(true);
            setEmissionActivity('File uploaded');
            setCurrentIndex(newIndex);          
        }
        if (activity) {
            clearTimeout(receptionActivityTimeout.current);
            setReceptionActivity(activity);

            receptionActivityTimeout.current = setTimeout(() => {
                setReceptionActivity('');
            }, 6000);
        }
    };

    const pingFCOTA = () => {
        clearTimeout(pingTimeout.current);
        pingTimeout.current = setTimeout(() => {
            pingSent('ping');
            pingFCOTA();
        }, 6000);
    };

    const { data } = useSubscription(FCOTA_PING_SUBSCRIPTION, {
        variables: { deviceToken: device },
    });

    const getUploadStatus = () => {
        if (hasFinished) {
            return 'Upload complete';
        } 
        
        if (receptionActivity) {
            return receptionActivity.includes('updating file') ? `Uploading ${currentUploadFile}` : emissionActivity;
        }

        return 'Waiting for device...';
    };

    useEffect(() => {
        if (data) {
            pingReceived(data?.pingSubscription?.activity);
        }
    }, [data]);

    useEffect(() => {
        setFilesToUpload(files);
        pingFCOTAMutation({ variables: { deviceToken: device } });
        pingFCOTA();

        return () => {
            clearTimeout(connectionStateTimeout.current);
            clearTimeout(emissionActivityTimeout.current);
            clearTimeout(receptionActivityTimeout.current);
            clearTimeout(pingTimeout.current);
        };
    }, []);

    const filesToUploadValue = `${hasFinished ? filesToUpload.length : currentIndex} from ${filesToUpload.length}`;

    const filesToUploadMapped = filesToUpload.map((file, index) => (
        <div key={file.path}>
            <Checkbox
                value={file.path}
                title={file.path}
                checked={index < currentIndex}
            />
        </div>
    ));

    return (
        <Styled.Wrapper>
            <Styled.Header>Uploading...</Styled.Header>
            <Styled.Status>
                <span>
                    {getUploadStatus()}
                </span>
                <Styled.StatusLabel>
                    <span>Device Status:</span> 
                    <div>
                        <Styled.Label>
                            {connectionState}
                        </Styled.Label>
                        <Styled.LightBtn $status={connectionState} />
                    </div>
                </Styled.StatusLabel>
            </Styled.Status>
            <Styled.Files>
                <span>Files to Upload:</span>
                <span>
                    {filesToUploadValue}
                </span>
            </Styled.Files>
            <Styled.FilesTable>
                {filesToUploadMapped}
            </Styled.FilesTable>
            <Styled.CloseBtn
                buttonType="transparent"
                onClick={closeWithCleanUp}
            >
                {hasFinished ? 'Close' : 'Cancel'}
            </Styled.CloseBtn>
        </Styled.Wrapper>
    );
};

ExportUploadDeviceAction.propTypes = {
    files: PropTypes.array.isRequired,
    device: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    cleanStorage: PropTypes.func.isRequired,
};
