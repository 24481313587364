import { useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useApolloClient, useMutation } from '@apollo/client';

import { Button, Table } from 'Components';
import { showToastError } from 'Utils';
import {
    RUN_MODEL,
    PROCESSING_STATUS,
    SET_FEATURES,
    GET_ML_SAMPLES_BY_MODEL,
    GET_ML_MODEL_BY_ID_QUERY,
    GET_SAMPLES_BY_MODEL_ID,
    ML_RESPONSE_STATUSES,
} from 'Constants';

import * as Styled from './styled';
import { getMlSamplePayload } from './helpers';
import { columns } from './columns';

export const TrainingSet = ({ model, datasets, mlSampleNames, mlSamples, samples }) => {
    const apolloClient = useApolloClient();
    const [setFeatures] = useMutation(
        SET_FEATURES,
        { refetchQueries: [GET_ML_SAMPLES_BY_MODEL, GET_ML_MODEL_BY_ID_QUERY, GET_SAMPLES_BY_MODEL_ID] },
    );

    const [isLoading, setIsLoading] = useState(false);
    const intervalRef = useRef(null);

    const clearIntervalHandler = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
    };

    const transformedMlSamples = useMemo(
        () => mlSamples.map((mlSample) => ({
            sample_name: mlSample.label,
            label: mlSample.label,
            data: getMlSamplePayload(samples.filter((sample) => sample.mlSampleId === mlSample._id)),
        })),
        [mlSamples, samples],
    );

    const processingStatusHandler = async (taskId) => {
        const { data: { processingStatus } } = await apolloClient.query({
            query: PROCESSING_STATUS,
            variables: {
               taskId,
            },
            fetchPolicy: 'network-only',
        });

        const preProcessingStatusObj = JSON.parse(processingStatus);

        if (preProcessingStatusObj.state === ML_RESPONSE_STATUSES.SUCCESS) {
            clearIntervalHandler();

            try {
                await setFeatures({
                    variables: {
                        modelId: model._id,
                        features: JSON.stringify(preProcessingStatusObj),
                    },
                });
            } catch (error) {
                showToastError(error.message);
            } finally {
                setIsLoading(false);
            }
        }

        if (preProcessingStatusObj.state === ML_RESPONSE_STATUSES.FAILURE ||
            preProcessingStatusObj.state === ML_RESPONSE_STATUSES.RETRY) {
            showToastError(preProcessingStatusObj.failure_cause);
            clearIntervalHandler();
            setIsLoading(false);
        }
    };

    const processFeatures = async () => {
        setIsLoading(true);

        const data = { samples: transformedMlSamples };
        const modelDefinition = JSON.parse(model.modelDefinition);
        const blocks = modelDefinition.blocks.filter((block) => block.id === 'PP' || block.id === 'SP');

        const { data: { runModel } } = await apolloClient.query({
            query: RUN_MODEL,
            variables: {
               modelDefinition: JSON.stringify({ name: 'model_definition', blocks }),
               data: JSON.stringify(data),
               withLog: true,
            },
            fetchPolicy: 'network-only',
        });

        const result = JSON.parse(runModel);
        intervalRef.current = setInterval(
            () => processingStatusHandler(result.task_id),
            2000,
        );
    };

    return (
        <>
            <h2>Training Set</h2>
            {!datasets.length && (
                <div>
                    Classes:
                    {mlSampleNames.map((name, index) => (
                        <Styled.SampleName key={name}>
                            {`${index ? ',' : ''} ${name}`}
                        </Styled.SampleName>
                    ))}
                </div>
            )}
            {!!datasets.length && (
                <Table
                    columns={columns}
                    data={datasets}
                    rowKey="sample"
                />
            )}
            <Styled.ButtonWrapper>
                <Button
                    onClick={processFeatures}
                    loading={isLoading}
                >
                    Process features
                </Button>
            </Styled.ButtonWrapper>
        </>
    );
};

TrainingSet.propTypes = {
    model: PropTypes.object.isRequired,
    datasets: PropTypes.array,
    mlSampleNames: PropTypes.array,
    mlSamples: PropTypes.array,
    samples: PropTypes.array,
};

TrainingSet.defaultProps = {
    datasets: [],
    mlSampleNames: [],
    mlSamples: [],
    samples: [],
};
