import styled from 'styled-components';

export const DoughnutContainer = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
    height: 100%;
`;

export const Info = styled.div`
    display: flex;
    flex-direction: column;
`;

export const LabelsWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-grow: 1;
`;

export const InTotal = styled.div`
    font-weight: 500;
    font-size: 27px;
    line-height: 33px;
`;

export const Doughnut = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;

    canvas {
        width: 100% !important;
    }
`;

export const Labels = styled.div`
    font-size: 16px;
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 5px;
    align-items: center;
`;

export const LabelMarker = styled.div`
    height: 13px;
    width: 13px;
    border: 1 px solid  ${({ $color }) => $color};
    background: ${({ $color }) => $color};
`;

export const LabelText = styled.div`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;
