import PropTypes from 'prop-types';
import moment from 'moment';

import { ReactComponent as AzureIcon } from 'Assets/tiles/azure.svg';

import * as Styled from './styled';

export const DetailsBlock = ({ azureDevices, details }) => {
    const { name, createdAt } = details;
    const { topic = '', hostName = '' } = azureDevices[0]?.azureDevice ?? {};

    const creationDate = moment(createdAt).format('D MMM YYYY');

    return (
        <Styled.DetailsWrapper>
            <Styled.FormItem>
                <Styled.ItemLabel>
                    Service
                </Styled.ItemLabel>
                <Styled.IconWrapper>
                    <AzureIcon />
                    Microsoft Azure
                </Styled.IconWrapper>
            </Styled.FormItem>
            <Styled.FormItem>
                <Styled.ItemLabel>
                    Azure IoT Hub
                </Styled.ItemLabel>
                {name}
            </Styled.FormItem>
            <Styled.FormItem>
                <Styled.ItemLabel>
                    Hostname
                </Styled.ItemLabel>
                {hostName}
            </Styled.FormItem>
            <Styled.FormItem>
                <Styled.ItemLabel>
                    Messages Topic
                </Styled.ItemLabel>
                {topic}
            </Styled.FormItem>
            <Styled.FormItem>
                <Styled.ItemLabel>
                    Devices Count
                </Styled.ItemLabel>
                {azureDevices.length}
            </Styled.FormItem>
            <Styled.FormItem>
                <Styled.ItemLabel>
                    Creation Date
                </Styled.ItemLabel>
                {creationDate}
            </Styled.FormItem>
        </Styled.DetailsWrapper>
    );
};

DetailsBlock.propTypes = {
    azureDevices: PropTypes.array.isRequired,
    details: PropTypes.object.isRequired,
};
