import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Row = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    gap: 25px;
`;

export const Fingerprint = styled.div`
    font-size: 1rem;
    margin-bottom: 1rem;
    span {
        font-weight: bold;
    }
`;
