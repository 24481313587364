import styled from 'styled-components';

export const Wrapper = styled.div`
    margin-top: 15px;
    width: 100%;
    height: 50vh;

    .googleMapContainer {
        height: 100%;
    }
`;
