import * as Yup from 'yup';

export const WIFI_SECURE_TYPES = {
    OPEN: 'OPEN',
    PASSWORD: 'PASSWORD',
    CERTIFICATE: 'CERTIFICATE',
};

export const DISABLED_PASSWORD = [
    WIFI_SECURE_TYPES.OPEN,
    WIFI_SECURE_TYPES.CERTIFICATE,
];

export const validationSchema = Yup.object().shape({
    ssid: Yup.string().required('SSID is required.'),
    secureType: Yup.string(),
    password: Yup.string().when('secureType', {
        is: (value) => !DISABLED_PASSWORD.includes(value),
        then: Yup.string().required('Password is required.'),
    }),
});

export const WIFI_NETWORKS_TYPE = [
    'Open Network',
    'Network secured with Password',
    'Network secured with certificate (Radius)',
];

export const initialValues = {
    ssid: '',
    password: '',
    secureType: WIFI_SECURE_TYPES.OPEN,
};
