import PropTypes from 'prop-types';
import * as Styled from '../styled';

export const EmptyWidget = ({ title, isDataBroken }) => (
    <>
        <Styled.ChartTitle>
            {title}
            {isDataBroken && (
                <Styled.DataNotValidLabel
                    data-cy="empty-widget-not-valid-data"
                >
                    (data not valid)
                </Styled.DataNotValidLabel>
            )}
        </Styled.ChartTitle>
        <Styled.ChartEmpty
            data-cy="empty-widget"
        >
            <p>No data</p>
        </Styled.ChartEmpty>
    </>
);

EmptyWidget.propTypes = {
    title: PropTypes.string,
    isDataBroken: PropTypes.bool,
};

EmptyWidget.defaultProps = {
    title: '',
    isDataBroken: false,
};
