import PropTypes from 'prop-types';

import { Modal, Button } from 'Components';

import * as Styled from './styled';

export const ConfigFileModal = ({
    isShowModal,
    setIsShowModal,
    configurationFile,
}) => (
    <Modal
        isOpened={isShowModal}
        handleClose={() => setIsShowModal(false)}
        destroyOnClose={true}
    >
        <Styled.ModalTitle>
            Gateway configuration
        </Styled.ModalTitle>

        <Styled.ModalContent>
            <Styled.ModalDescription>
                This configuration will be deployed to your device
            </Styled.ModalDescription>
            <Styled.Code
                data-cy="configuration-file-code"
            >
                {configurationFile}
            </Styled.Code>
            <Button
                onClick={() => setIsShowModal(false)}
                cyData="configuration-file-modal-button"
            >
                Git it
            </Button>
        </Styled.ModalContent>
    </Modal>
);

ConfigFileModal.propTypes = {
    isShowModal: PropTypes.bool.isRequired,
    setIsShowModal: PropTypes.func.isRequired,
    configurationFile: PropTypes.string.isRequired,
};
