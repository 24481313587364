import { setRefreshEditor, setRefreshStorage } from './actionCreators';

export const getEditorHandlers = (dispatch) => ({
    refreshEditor: () => {
        dispatch(setRefreshEditor(true));
    },
    refreshStorage: () => {
        dispatch(setRefreshStorage(true));
    },
    refreshComplete: () => {
        dispatch(setRefreshEditor(true));
        dispatch(setRefreshStorage(true));
    },
    stopRefreshing: () => {
        dispatch(setRefreshEditor(false));
        dispatch(setRefreshStorage(false));
    },
});
