import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import moment from 'moment';

import { Table } from 'Components';

import { SimCardWidgetBox } from '../simCardWidgetBox';
import { SimCardEmptyWidget } from '../simCardEmptyWidget';

import * as Styled from './styled';

export const ConsumptionTabular = ({ simReportsInfo }) => {
    const { columns, reports, total } = simReportsInfo;

    const lastMonthReports = useMemo(
        () => reports.filter((report) => {
            const oneMonthAgoDate = moment(new Date()).subtract(1, 'M');
            const targetDate = moment(new Date(report.date));

            return targetDate.isAfter(oneMonthAgoDate);
        }),
        [reports],
    );

    const csvDataReport = lastMonthReports.map((reportData) => {
        const row = {};

        Object.entries(reportData).forEach(([key, value]) => {
            const mappedKey = columns.find((item) => item.dataIndex === key);
            if (mappedKey) {
                row[mappedKey.title] = value;
            }
        });

        return row;
    });

    return (
        <SimCardWidgetBox title="Consumption Tabular">
                {!lastMonthReports.length && <SimCardEmptyWidget title="No Reports are available" />}
                {!!lastMonthReports.length && (
                    <Styled.Wrapper>
                        <Table columns={columns} data={lastMonthReports} maxBodyHeight={300} />
                        <Styled.InfoContainer>
                            <CSVLink data={csvDataReport}>
                                <Styled.DownloadCsv>
                                    Download CSV
                                </Styled.DownloadCsv>
                            </CSVLink>
                            {total && (
                                <Styled.InfoSection>
                                    <Styled.Total>Total Consumption:</Styled.Total>
                                    <span>
                                        {total}
                                    </span>
                                </Styled.InfoSection>
                            )}
                        </Styled.InfoContainer>
                    </Styled.Wrapper>
                )}
        </SimCardWidgetBox>
    );
};

ConsumptionTabular.propTypes = {
    simReportsInfo: PropTypes.shape({
        columns: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string.isRequired,
                dataIndex: PropTypes.string.isRequired,
            }),
        ).isRequired,
        reports: PropTypes.array.isRequired,
        total: PropTypes.string,
    }).isRequired,
};
