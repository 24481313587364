import styled from 'styled-components';
import { Collapse } from 'antd';

export const Collapsible = styled(Collapse)`
    border: none;
    background: transparent;
`;

export const Panel = styled(Collapse.Panel)`
    margin-bottom: 10px;
    border: 1px solid ${({ theme }) => theme.colors.border} !important;
    border-radius: 8px !important;
    overflow: hidden;
    background-color: white;

    &:last-child {
        margin-bottom: 0;
    }

    & > *:nth-child(1) {
        padding: 15px 40px 15px 22px !important;
    }

    & > *:nth-child(2) {
        border-top: none;

        & > .ant-collapse-content-box {
            padding: 15px 50px 15px 22px;
        }
    }
`;

export const Title = styled.div`
    font-size: 16px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.title};
`;

export const Description = styled.div`
    margin-top: 7px;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.title};
`;
