import PropTypes from 'prop-types';

import { Map as ReactGoogleMap } from 'Components';

import * as Styled from './styled';

export const SessionMap = ({ geoData }) => {
    const { lat, lng } = geoData;

    return (
        <Styled.Wrapper>
            <ReactGoogleMap
                location={{ lat, lng }}
            />
        </Styled.Wrapper>
    );
};

SessionMap.propTypes = {
    geoData: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
    }).isRequired,
};
