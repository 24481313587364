import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useQuery } from '@apollo/client';
import uniq from 'lodash/uniq';

import { Alert } from 'Components';
import { GET_FIRMWARE_QUERY, LIST_OF_DEVICES_BY_APP_ID } from 'Constants';
import { showToastError } from 'Utils';

import { FormContent } from './formContent';
import { initialValues, typeValues, deviceTypeMapper } from './config';

import * as Styled from './styled';

export const FirmwareVersion = ({ prevStep, nextStep, formValues, projectId }) => {
    const [customFiles, setCustomFiles] = useState([]);
    const { data: firmwareData } = useQuery(GET_FIRMWARE_QUERY);
    const firmwareList = firmwareData?.getListOfFirmware ?? [];

    const { data: devicesData } = useQuery(
        LIST_OF_DEVICES_BY_APP_ID,
        {
            variables: { applicationId: projectId },
            fetchPolicy: 'cache-and-network',
        },
    );
    const deviceTypes = useMemo(
        () => {
            const result = devicesData?.getDevicesByApplicationID.map(
                (device) => deviceTypeMapper[device.deviceType] || device.deviceType,
            ) ?? [];

            return uniq(result);
        },
        [devicesData],
    );

    const onSubmit = (values) => {
        const newValues = { ...formValues };

        if (values.type === typeValues.custom) {
            if (!deviceTypes.length) {
                showToastError('To add custom firmware please add at least one device to a project');
                return;
            }

            if (customFiles.length !== deviceTypes.length) {
                showToastError('Please add custom firmware for each type of device added to the project');
                return;
            }

            if (!customFiles.every((fileData) => fileData.file.name.endsWith('bin'))) {
                showToastError('Every custom firmware file should be of type "bin"');
                return;
            }
            newValues.customFirmwares = customFiles;
        }
        delete newValues.firmware;

        if (values.version) {
            newValues.firmware = values.version;
        }

        nextStep(newValues);
    };

    const defaultValues = formValues.compilationFile
        ? { ...initialValues, changeFirmware: true, type: typeValues.custom }
        : initialValues;

    const combinedInitialValues = formValues.firmware
        ? { changeFirmware: true, type: typeValues.default, version: formValues.firmware }
        : defaultValues;

    return (
        <>
            <Styled.Title>
                Firmware version
            </Styled.Title>
            <Styled.NotificationWrapper>
                You can change a firmware version you want to deploy in this release or keep it without changes.
            </Styled.NotificationWrapper>
            <Alert
                type="success"
                message="To use releases your devices need to run the firmware 1.20.r2 or above."
            />
            <Formik
                onSubmit={onSubmit}
                initialValues={combinedInitialValues}
            >
                {(props) => (
                    <FormContent
                        {...props}
                        prevStep={prevStep}
                        firmwareList={firmwareList}
                        deviceTypes={deviceTypes}
                        customFiles={customFiles}
                        setCustomFiles={setCustomFiles}
                        compiledFileProvided={!!formValues.compilationFile}
                    />
                )}
            </Formik>
        </>
    );
};

FirmwareVersion.propTypes = {
    prevStep: PropTypes.func.isRequired,
    nextStep: PropTypes.func.isRequired,
    formValues: PropTypes.object,
    projectId: PropTypes.string,
};

FirmwareVersion.defaultProps = {
    formValues: {},
    projectId: '',
};
