import { useEffect } from 'react';
import { Form, Field } from 'formik';
import PropTypes from 'prop-types';

import { LORA_CONSTANTS, SERVER_OPTIONS } from 'Constants';
import { getRegionOptions } from 'Utils';

import { Select } from '../../../select';
import { RadioButton } from '../../../radioButton';
import { Button } from '../../../buttons';

import * as Styled from './styled';

export const FormContent = ({ isShowCancel, handleClose, values, setFieldValue, isLoading }) => {
    useEffect(() => {
        if (values.loraServer === LORA_CONSTANTS.SERVERS.TTN) {
            setFieldValue('loraRegion', LORA_CONSTANTS.REGIONS.TTN[0].value);
        } else {
            setFieldValue('loraRegion', LORA_CONSTANTS.REGIONS.CHIRPSTACK[0].value);
        }
    }, [values.loraServer]);

    return (
        <Form
            data-cy="lora-settings-form"
        >
            <Styled.Form
                $form={isShowCancel}
                $padded={!isShowCancel}
            >
                <div>
                    <Styled.Item>
                        <span>Server</span>
                        <Field
                            name="loraServer"
                            component={Select}
                            values={SERVER_OPTIONS}
                        />
                    </Styled.Item>
                </div>
                <Styled.Radio role="presentation">
                    <span>Activation Type:</span>
                    {LORA_CONSTANTS.RADIO_BUTTONS.map((item) => (
                        <Field
                            key={item.value}
                            name="usingABP"
                            title={item.label}
                            value={item.value}
                            type="radio"
                            component={RadioButton}
                        />
                    ))}
                </Styled.Radio>
                <Styled.Item>
                    <span>Region</span>
                    <Field
                        name="loraRegion"
                        component={Select}
                        values={getRegionOptions(values.loraServer)}
                    />
                </Styled.Item>
            </Styled.Form>
            <Styled.ButtonsWrapper
                $normal={isShowCancel}
                $left={!isShowCancel}
            >
                <div>
                    {isShowCancel && (
                        <Button buttonType="transparent" onClick={handleClose}>
                            Cancel
                        </Button>
                    )}
                    <Button 
                        type="submit" 
                        loading={isLoading}
                        cyData="lora-settings-add-button"
                    >
                        Save
                    </Button>
                </div>
            </Styled.ButtonsWrapper>
        </Form>
    );
};

FormContent.propTypes = {
    isShowCancel: PropTypes.bool,
    values: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
};

FormContent.defaultProps = {
    isShowCancel: false,
    isLoading: false,
};
