import gql from 'graphql-tag';

export const GET_PYMESH_BY_APPLICATION_ID = gql`
    query getPymeshByApplicationId($applicationId: String!) {
        getPymeshByApplicationId(applicationId: $applicationId) {
            _id
            applicationId
            region
            frequency
            bandwidth
            spreadFactor
            joinKey
            devices {
                token
                brEnable
            }
        }
    }
`;

export const SAVE_PYMESH = gql`
    mutation savePymesh($newPymesh: PymeshInputType!) {
        savePymesh(newPymesh: $newPymesh) {
            _id
            applicationId
            region
            frequency
            bandwidth
            spreadFactor
            joinKey
            devices {
                token
                brEnable
            }
        }
    }
`;

export const ADD_DEVICES_TO_PYMESH = gql`
    mutation addDeviceToPymesh($devices: [PymeshDeviceInputType], $pymeshID: String) {
        addDeviceToPymesh(devices:$devices, pymeshID: $pymeshID) {
            devices {
                token
                brEnable
            }
        }
    }
`;

export const SAVE_LICENSE_AGREEMENT = gql`
    mutation saveLicenseAgreement($licenseAgreement: LicenseAgreementInputType!) {
        saveLicenseAgreement(licenseAgreement: $licenseAgreement) {
            _id
            user
            ip
            licenseProduct
            createdAt
        }
    }
`;

export const AUTHORIZE_PYMESH_DEVICE = gql`
    mutation authorizePymeshDevice($wmac: String, $token: String) {
        authorizePymeshDevice(wmac: $wmac, token: $token)
    }
`;

export const JOIN_KEY_EXISTS_QUERY = gql`
    query joinKeyExist($joinKey: String!) {
            joinKeyExist(joinKey: $joinKey)
    }
`;

export const GET_PYMESH_DATA_BY_DEVICE_LIST_QUERY = gql`
    query getPymeshDataByDeviceList($deviceTokenList: [String]) {
        getPymeshDataByDeviceList(deviceTokenList:$deviceTokenList) {
            _id,
            token,
            brToken,
            name,
            mac,
            ip,
            role,
            nNum,
            nEnum {
                mac,
                ip,
                role,
                rssi,
                age,
            },
            location {
                longitude,
                latitude
            },
            age
        }
    }
`;

export const SET_BORDER_ROUTER = gql`
    mutation setBorderRouter($device: PymeshDeviceInputType, $pymeshID: String) {
        setBorderRouter(device:$device, pymeshID: $pymeshID) {
            devices {
                token
                brEnable
            }
        }
    }
`;

export const UPDATE_PYMESH_MUTATION = gql`
     mutation updatePymesh($pymesh: PymeshInputType!) {
        updatePymesh(pymesh: $pymesh) {
            _id
            region
            frequency
            bandwidth
            spreadFactor
            joinKey
            devices {
                token
                brEnable
            }
        }
    }
`;

export const REMOVE_DEVICES_FROM_PYMESH = gql`
    mutation removeDeviceFromPymesh($devices: [String], $pymeshID: String) {
        removeDeviceFromPymesh(devices:$devices, pymeshID: $pymeshID) {
            devices {
                token
                brEnable
            }
        }
    }
`;

export const DELETE_PYMESH_MUTATION = gql`
    mutation deletePymesh($pymeshID: String) {
        deletePymesh(pymeshID: $pymeshID) {
            ok
            n
        }
    }
`;
