import styled from 'styled-components';
import { List } from 'react-virtualized';

import { messageEffectRowMixin } from 'Styles';

export const Chart = styled.div`
    height: 100%;
    padding: 10px 0 40px;
    position: relative;
`;

export const ListVirtual = styled.div`
    display: block;

    .messageRow {
        border-bottom: 1px solid ${({ theme }) => theme.colors.border};
        background-color: #fff;
        transition: background-color 200ms linear;
        white-space: nowrap;
        .column {
            &.start {
                border-right: 1px solid ${({ theme }) => theme.colors.border};
            }

            &.average {
                width: 200px;
                padding-left: 18px;
            }

            &.extended {
                width: calc(100% - 200px);
                overflow: hidden;
            }
        }

        .column,
        .columnTime,
        .columnValue,
        .columnSize,
        .headerSize,
        .headerValue,
        .columnPin,
        .columnNetworkType {
            display: inline-block;
            padding-left: 10px;
            padding-right: 10px;
            height: 100%;
            min-height: 30px;
            line-height: 30px;
            white-space: normal;
            vertical-align: top;
        }

        .columnPin {
            min-width: 80px;
            text-align: center;

            .pinText {
                font-weight: 500;
                border: 2px solid ${({ theme }) => theme.colors.border};
                padding-left: 2px;
                padding-right: 2px;
            }
        }

        .columnNetworkType {
            width: 80px;
            border-right: 1px solid ${({ theme }) => theme.colors.border};
            text-align: center;
            svg {
                position: relative;
                top: 5px;
                font-size: 20px;
            }
        }

        .columnTime {
            position: relative;
            width: 200px;
            padding-left: 18px;
            border-right: 1px solid ${({ theme }) => theme.colors.border};
            .timeButton {
                position: absolute;
                background: #fff;
                position: absolute;
                top: 4px;
                right: 15px;
                font-size: 20px;
                cursor: pointer;
                &:hover {
                    opacity: 0.5;
                }
            }
        }

        .headerValue {
            width: calc(100% - 372px);
            border-right: 1px solid ${({ theme }) => theme.colors.border};
            overflow: hidden;
        }

        .columnValue {
            width: calc(100% - 360px);
            border-right: 1px solid ${({ theme }) => theme.colors.border};
            overflow: hidden;
        }

        .headerSize {
            min-width: 92px;
            text-align: center;
        }

        .columnSize {
            min-width: 80px;
            text-align: center;
        }

        .MessageEffectRow {
            ${messageEffectRowMixin}
        }
    }

    .header {
        background-color: white;
        border: 1px solid ${({ theme }) => theme.colors.border};
        border-bottom: 0;
        overflow: hidden;
        .columnStart {
            padding-left: 10px;
        }
    }

    .columnSettings {
        display: inline-block;
        position: relative;
        margin: 0;
        padding: 0;
        height: 30px;
        line-height: 30px;
        cursor: pointer;
        font-size: 20px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        input {
            opacity: 0;
            position: absolute;
            height: 30px;
            width: 30px;
            cursor: pointer;
        }
        .checkmark:after {
            content: "";
            display: none;
        }
        .checkmark {
            font-family: "Material Icons";
            border: none;
            border-radius: 0 !important;
            color: ${({ theme }) => theme.colors.border};
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
        }

        input:hover ~ .checkmark:before {
            color: ${({ theme }) => theme.colors.title};
        }

        .checkmark.timeDisplay:before {
            content: '\00E8B3';
        }

        input:checked ~ .checkmark.timeDisplay:before {
            content: '\00E8B5';
        }

        .checkmark.floatDisplay:before {
            content: '\00E3D1';
        }

        input:checked ~ .checkmark.floatDisplay:before {
            content: '\00E3DA';
        }
    }
`;

export const ListVirtualized = styled(List)`
    background-color: ${({ theme }) => theme.colors.boxBackground};
    border-style: solid;
    border-width: 1px;
    border-color: ${({ theme }) => theme.colors.border};
    padding: 0;
    overflow-y: scroll !important;
    outline: 0 !important;
    height: 95%;
    &::-webkit-scrollbar {
        width: 12px !important;
    }
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0,0,0,0.3) !important;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3) !important;
    }
    &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.4) !important;
        border-radius: 6px;
        border: 1px solid rgb(172, 172, 172);
    }
`;
