import styled, { css } from 'styled-components';
import { Button } from 'Components';
import { hexToRgb } from 'Utils';

export const Wrapper = styled.div`
    padding: 15px 10px;
`;

export const Header = styled.h1`
    background-color: ${({ theme }) => theme.colors.title};
    width: 100%;
    color: #fff;
    padding: 15px 10px;
`;

export const Status = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.active};
    padding-bottom: 10px;
`;

export const StatusLabel = styled.div`
    display: flex;
`;

export const Label = styled.span`
    margin-left: 20px;
    margin-right: 5px;
`;

export const onlineStatus = css`
    background-color: ${({ theme }) => theme.colors.success};
`;

export const restartingStatus = css`
    background-color: ${({ theme }) => theme.colors.info};
`;

export const offlineStatus = css`
    background-color: ${({ theme }) => theme.colors.active};
`;

export const LightBtn = styled.div`
    display: inline-block;
    width: 10px;
    margin-right: 5px;
    height: 10px;
    border-radius: 50%;
    color: ${({ theme }) => theme.colors.boxBackground};

    ${({ $status }) => {
        switch ($status) {
            case 'online':
                return onlineStatus;
            case 'restarting':
                return restartingStatus;
            case 'offline':
                return offlineStatus;
            default:
                return '';
        }
    }}
`;

export const Files = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 20px 10% 0;
`;

export const FilesTable = styled.div`
    margin: 5px 10% 20px;
    padding: 20px;
    border: 1px solid ${({ theme }) => theme.colors.title};
    background-color: rgba(${({ theme }) => hexToRgb(theme.colors.border)}, 0.3);
`;

export const CloseBtn = styled(Button)`
    margin: 0 auto;
`;
