import styled from 'styled-components';

import { ReactComponent as Plus } from 'Assets/icons/plus.svg';

export const Wrapper = styled.div`
    button {
        text-transform: uppercase;
    }
`;

export const ModalWrapper = styled.div`
    padding: 25px 30px;
`;

export const AddIcon = styled(Plus)`
    margin-right: 15px;
`;

export const ModalContentWrapper = styled.div`
    box-shadow: 0 2px 5px 0 rgba(50, 50, 50, 0.2);
    padding: 15px 10px;
`;

export const DevicesModalTitle = styled.h3`
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 15px;
`;

export const ModalButtonsSection = styled.div`
    display: flex;
    margin-top: 20px;

    button {
        margin-right: 5px;
        text-transform: uppercase;
    }
`;
