import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { BATERRY_LEVEL_CHARGING, COUNT_OF_BAR } from 'Utils';

import { EmptyWidget } from '../emptyWidget';

import * as Styled from './styled';
import * as StyledGeneral from '../styled';

export const BatteryLevelWidget = ({ data, title, isEmpty }) => {
    const isDataBroken = !isEmpty && Number.isNaN(+data?.value);

    const typeOfStateBattery = useMemo(
        () => {
            const { value } = data;
            if (value > 0 && value <= 20) {
                return BATERRY_LEVEL_CHARGING.ALERT;
            }
            if (value > 20 && value <= 30) {
                return BATERRY_LEVEL_CHARGING.WARN;
            }
            return BATERRY_LEVEL_CHARGING.NORMAL;
        },
        [data],
    );

    const countsOfBar = useMemo(
        () => {
            const { value } = data;
            const result = [];
            const countOfBar = Math.ceil(value / 20);

            for (let count = 1; count <= COUNT_OF_BAR; count++) {
                result.push(
                    <Styled.BatteryLevel
                        key={count}
                        $opacity={count <= countOfBar ? 1 : 0}
                        $status={typeOfStateBattery}
                    />,
                );
            }
            return result;
        },
        [data],
    );

    if (isEmpty || isDataBroken) {
        return <EmptyWidget title={title} isDataBroken={isDataBroken} />;
    }

    return (
        <Styled.BatteryLevelContainer>
            <Styled.Info>
                <div>
                    <StyledGeneral.ChartTitle>
                        {title}
                    </StyledGeneral.ChartTitle>
                    {data.countDevice} device
                </div>
                <div>
                    <Styled.InTotal>
                        {data.value}
                        <Styled.Unit>
                            %
                        </Styled.Unit>
                    </Styled.InTotal>
                </div>
            </Styled.Info>
            <Styled.BatteryVesselContainer>
                <Styled.Battery $borderColor={data.value === 100}>
                    {countsOfBar}
                </Styled.Battery>
            </Styled.BatteryVesselContainer>
        </Styled.BatteryLevelContainer>
    );
};

BatteryLevelWidget.propTypes = {
    title: PropTypes.string.isRequired,
    data: PropTypes.shape({
        value: PropTypes.number,
        countDevice: PropTypes.number,
    }).isRequired,
    isEmpty: PropTypes.bool,
};

BatteryLevelWidget.defaultProps = {
    isEmpty: false,
};
