import PropTypes from 'prop-types';

import * as Styled from './styled';

export const TooltipDeviceMarker = ({ title, time, location, colorOfTime }) => (
    <Styled.TooltipOfDevice>
        <Styled.Title>
            {title}
        </Styled.Title>
        <Styled.Time $color={colorOfTime}>
            {time}
        </Styled.Time>
        <Styled.Location>
            {location}
        </Styled.Location>
    </Styled.TooltipOfDevice>
);

TooltipDeviceMarker.defaultProps = {
    title: '',
    time: null,
    location: '',
    colorOfTime: '',
};

TooltipDeviceMarker.propTypes = {
    title: PropTypes.string,
    time: PropTypes.string,
    location: PropTypes.string,
    colorOfTime: PropTypes.string,
};
