import PropTypes from 'prop-types';

import { Button } from 'Components';

import * as Styled from './styled';

export const RemoveDeviceFromMesh = ({ onClick, disabled }) => (
    <Styled.Wrapper>
        <Button
            onClick={onClick}
            disabled={disabled}
            buttonType="transparent"
        >
            <Styled.RemoveIcon />
            Remove device
        </Button>
    </Styled.Wrapper>
);

RemoveDeviceFromMesh.defaultProps = {
    disabled: false,
};

RemoveDeviceFromMesh.propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};
