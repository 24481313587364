import PropTypes from 'prop-types';

import { Modal, Button } from 'Components';

import * as Styled from './styled';

export const CreateChirpstackProfileNotificationModal = ({
    isShowModal,
    setIsShowModal,
}) => (
    <Modal
        isOpened={isShowModal}
        handleClose={() => setIsShowModal(false)}
        destroyOnClose={true}
    >
        <Styled.ModalTitle>
            ChirpStack profile was created
        </Styled.ModalTitle>

        <Styled.ModalContent>
            Your ChirpStack profile was successfully created.
            <br />
            {/* eslint-disable-next-line max-len*/}
            Please check your email. We have sent you the login credentials for <a href="https://loraserver.pycom.io/" rel="noopener noreferrer" target="_blank">Pycom ChirpStack</a> there.

            <Button
                onClick={() => setIsShowModal(false)}
                cyData="chirpstack-profile-was-created-modal-button"
            >
                Git it
            </Button>
        </Styled.ModalContent>
    </Modal>
);

CreateChirpstackProfileNotificationModal.propTypes = {
    isShowModal: PropTypes.bool.isRequired,
    setIsShowModal: PropTypes.func.isRequired,
};
