import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { useQuery, useMutation } from '@apollo/client';

import { GET_APPLICATIONS, UPDATE_APPLICATION_MUTATION, GET_APPLICATION_QUERY } from 'Constants';
import { showToastSuccess } from 'Utils';

import { initialValues, getValidationSchema } from './config';
import { FormContent } from './formContent';

export const ProjectSettingsForm = ({ project }) => {
    const { data: projectsData } = useQuery(GET_APPLICATIONS);
    const projects = useMemo(() => (projectsData?.getApplications ?? []), [projectsData]);

    const [updateProject, { loading: isLoading }] = useMutation(
        UPDATE_APPLICATION_MUTATION,
        { refetchQueries: [GET_APPLICATIONS, GET_APPLICATION_QUERY] },
    );

    const checkIsNameNotDuplicated = (name) => !projects.some(
        (item) => (item.name === name && item._id !== project._id),
    );

    const onSubmit = (values) => {
        updateProject({
            variables: { application: { _id: project._id, ...values } },
            update: () => {
                showToastSuccess('Project settings updated successfully');
            },
        });
    };

    const combinedInitialValues = {
        name: project.name || initialValues.name,
        description: project.description || initialValues.description,
    };

    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={combinedInitialValues}
            validationSchema={getValidationSchema(checkIsNameNotDuplicated)}
            component={FormContent}
            isLoading={isLoading}
            enableReinitialize
        />
    );
};

ProjectSettingsForm.propTypes = {
    project: PropTypes.object.isRequired,
};
