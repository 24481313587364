import styled from 'styled-components';

import { IconButton } from 'Components';

export const Wrapper = styled.div`
    width: 15%;
    min-width: 200px;
    background-color: #fff;
    border-radius: 0 10px 0 0;
    overflow-x: hidden;
    overflow-y: auto;
    white-space: nowrap;
`;

export const ExpansionArrow = styled.button`
    width: 20px;
    height: 30px;
    display: inline-block;
    margin-right: 10px;
    margin-top: -4px;
    background-color: transparent;
    cursor: pointer;

    ${({ $isExpanded }) => ($isExpanded
        ? `
            transform: rotate(90deg);
        `
        : `
            padding-top: 8px;
            transform: rotate(-90deg);
        `)}

    svg {
        width: 20px;
        height: 20px;
    }
`;

export const ExplorerHeading = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
`;

export const ExtendedTitle = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-top: 3px;
    cursor: pointer;

    span {
        white-space: nowrap;
        vertical-align: text-bottom;
    }
`;

export const CloudIcon = styled.div`
    opacity: 30%;
    display: inline-block;
    margin-right: 7px;

    svg {
        margin-top: 3px;
        width: 18px;
        height: 15px;
    }
`;

export const RefreshTip = styled.span`
    opacity: 80%;
    z-index: 10;
    position: absolute;
    top: 30px;
    right: 15px;
    visibility: hidden;
    background-color: ${({ theme }) => theme.colors.tableHeader};
    padding: 2px;
`;

export const RefreshIcon = styled(IconButton)`
    opacity: 60%;
    background-color: transparent;
    padding: 0;
    
    svg {
        width: 14px;
        height: 14px;
        margin-left: 5px;
    }

    &:hover + ${RefreshTip} {
        visibility: visible;
    }
`;
