import { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { ThemeContext } from 'styled-components';

import { ReactComponent as HomeIcon } from 'Assets/widgets/airQuality/home.svg';
import { ReactComponent as InfoIcon } from 'Assets/widgets/airQuality/info.svg';

import { getAirConditionInformation } from './config';
import { EmptyWidget } from '../emptyWidget';

import * as StyledGeneral from '../styled';
import * as Styled from './styled';

export const AirQualityWidget = ({ data, isEmpty, title }) => {
    const styledTheme = useContext(ThemeContext);

    const airInfo = useMemo(() => getAirConditionInformation(data), [data]);

    if (!airInfo || isEmpty) {
        return <EmptyWidget title={title} isDataBroken={!!data} />;
    }

    const Icon = airInfo.icon;

    return (
        <Styled.Wrapper>
            <Styled.Header>
                <Styled.Actions
                    svgColor={airInfo.color}
                >
                    <Tooltip
                        placement="left"
                        trigger={['click', 'hover']}
                        color="white"
                        overlayInnerStyle={{
                            color: styledTheme.colors.text,
                            borderRadius: '5px',
                            padding: '10px 20px',
                            fontSize: '14px',
                        }}
                        getPopupContainer={(triggerNode) => triggerNode.parentNode}
                        title={() => (
                            <>
                                <Styled.TooltipHeader>
                                    Suggested action
                                </Styled.TooltipHeader>
                                {airInfo.suggestedAction}
                            </>
                        )}
                    >
                        <InfoIcon /> Actions
                    </Tooltip>
                </Styled.Actions>
                <Styled.HomeIcon>
                    <HomeIcon />
                </Styled.HomeIcon>
                <StyledGeneral.ChartTitle>
                    {title}
                </StyledGeneral.ChartTitle>
            </Styled.Header>
            <Styled.InfoWrapper
                svgColor={airInfo.color}
            >
                <Icon />
                <div>
                    <Styled.Quality
                        color={airInfo.color}
                    >
                        {airInfo.airQuality}
                    </Styled.Quality>
                    IAQ Index: {airInfo.index}
                </div>
            </Styled.InfoWrapper>
            <div>
                {airInfo.impact}
            </div>
        </Styled.Wrapper>
    );
};

AirQualityWidget.propTypes = {
    data: PropTypes.number,
    title: PropTypes.string.isRequired,
    isEmpty: PropTypes.bool,
};

AirQualityWidget.defaultProps = {
    isEmpty: false,
    data: null,
};
