import { css } from 'styled-components';

import { hexToRgb } from 'Utils';

export const dropdownFocus = css`
    background-color: rgba(${({ theme }) => hexToRgb(theme.colors.boxBackground)}, 0.5);
    color: ${({ theme }) => theme.colors.text};
    border: 1px solid rgba(${({ theme }) => hexToRgb(theme.colors.active)}, 0.6);
    box-shadow: rgba(${({ theme }) => hexToRgb(theme.colors.active)}, 0.6) 0px 1px 2px 0px;
`;

export const messageEffectRowMixin = css`
    background: linear-gradient(to bottom, rgba(0, 204, 150, 0.4) 0%, rgba(0, 204, 150, 0) 100%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    pointer-events: none;
    position: absolute;
    width: 100%;
    left: 0;
    height: 100%;
    top: 0;
    opacity: 0;
`;

export const shadowSm = css`
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04),
                0px 0px 2px rgba(0, 0, 0, 0.02);
`;

export const box = css`
    padding: 2rem;
    border-radius: 0.5rem;
    border: 1px solid ${({ theme }) => theme.colors.border};
    background-color: white;
    ${shadowSm}
`;
