import { useState, useEffect, useMemo, useCallback } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import cloneDeep from 'lodash/cloneDeep';

import {
    GET_DEVICE_CHARTS_QUERY,
    GET_DEVICE_BY_TOKEN_QUERY,
    SAVE_DEVICE_WIDGETS_MUTATION,
    DIMENSION_MULTIPLIERS,
    GRID_SCREEN_WIDTH,
    ROUTES,
} from 'Constants';
import { getGridHeight } from 'Utils';
import { Loader } from 'Components';

import DashboardView from './dashboardView';
import DashboardEdit from './dashboardEdit';
import * as Styled from './styled';

export const Dashboard = ({ deviceToken }) => {
    const history = useHistory();
    const [isEditMode, setIsEditMode] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.document.documentElement.clientWidth);
    const [dashboardData, setDashboardData] = useState([]);
    const [originalDashboardData, setOriginalDashboardData] = useState([]);
    const [initialLoading, setInitialLoading] = useState(true);

    const { data, loading: chartsLoading } = useQuery(
        GET_DEVICE_CHARTS_QUERY,
        {
            variables: { deviceToken },
            fetchPolicy: 'network-only',
        },
    );

    const { data: deviceInfo, loading: deviceLoading } = useQuery(
        GET_DEVICE_BY_TOKEN_QUERY,
        {
            variables: { deviceToken },
        },
    );

    const device = deviceInfo?.device[0];

    useEffect(
        () => {
            if (initialLoading && !chartsLoading && !deviceLoading) {
                setInitialLoading(false);
            }
        },
        [chartsLoading, deviceLoading],
    );

    const [isLoading, setIsLoading] = useState(false);
    const [editMultipleWidgetsMutation] = useMutation(SAVE_DEVICE_WIDGETS_MUTATION);

    const goCreateWidgetPage = useCallback(() => {
        history.push(`${ROUTES.devices.main}/${deviceToken}${ROUTES.devices.createWidget.add}`);
    }, []);

    const startEdition = useCallback(() => {
        setIsEditMode(true);
        setOriginalDashboardData(dashboardData);
    }, [setIsEditMode, setOriginalDashboardData, dashboardData]);

    const cancelEdition = useCallback(() => {
        setIsEditMode(false);
        setOriginalDashboardData([]);
    }, [setIsEditMode, setOriginalDashboardData]);

    const saveEdition = useCallback((dashboard) => {
        setIsLoading(true);

        if (dashboard !== originalDashboardData) {
            setDashboardData(dashboard);
            const widgets = dashboard
                .filter((widgetData) => !!widgetData?.widget)
                .map((widgetData) => {
                    const { _id, deviceToken: dToken, width, height, x, y } = widgetData.widget;
                    return {
                        _id,
                        deviceToken: dToken,
                        width,
                        height,
                        x,
                        y,
                    };
                });
            editMultipleWidgetsMutation({
                variables: { widgets },
            }).catch(() => { });
        }
        cancelEdition([]);
        setIsLoading(false);
    }, [
        editMultipleWidgetsMutation,
        originalDashboardData,
        cancelEdition,
    ]);
    const chartData = data?.getDeviceCharts || [];

    useEffect(() => {
        if (chartData?.length) {
            const dashboard = chartData
                .filter((chartItem) => !!chartItem.widget)
                .map((chartItem) => cloneDeep(chartItem));
            setDashboardData(dashboard);
        }
    }, [chartData]);

    const updateDimensions = () => setWindowWidth(window.document.documentElement.clientWidth);

    useEffect(() => {
        window.addEventListener('resize', updateDimensions);
        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    const dashboardStyles = useMemo(() => {
        const gridSize = getGridHeight(dashboardData);
        let dashboardHeight = gridSize * DIMENSION_MULTIPLIERS.height;
        const defaultPadding = 65;
        const defaultScale = 0.6;
        if (((windowWidth - defaultPadding)) <= GRID_SCREEN_WIDTH) {
            let scaleRatio = ((windowWidth - defaultPadding)) / GRID_SCREEN_WIDTH;
            if (scaleRatio < defaultScale) {
                scaleRatio = defaultScale;
            }
            dashboardHeight *= scaleRatio;
        }
        return { height: dashboardHeight };
    }, [dashboardData]);

    if (initialLoading) {
        return <Loader />;
    }

    if (!device) {
        return null;
    }

    return (
        <Styled.DashboardTab className="dashboardTab" style={dashboardStyles}>
            {isEditMode && (
                <DashboardEdit
                    dashboardData={dashboardData}
                    onCancel={cancelEdition}
                    onSave={saveEdition}
                    isLoading={isLoading}
                />
            )}
            {!isEditMode && (
                <DashboardView
                    device={device}
                    dashboardData={dashboardData}
                    setEditMode={startEdition}
                    goCreateWidgetPage={goCreateWidgetPage}
                />
            )}
        </Styled.DashboardTab>
    );
};

Dashboard.propTypes = {
    deviceToken: PropTypes.string.isRequired,
};
