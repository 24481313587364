import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { GET_DEVICE_LAST_MESSAGE } from 'Constants';
import { getDataCountWidgetData } from 'Utils';
import { useMessageSubscription } from 'Hooks';

import { TemperatureWidget } from '../../../../widgets';

export const Temperature = ({ chartId, title, chartSettings, deviceToken }) => {
    const { pin } = chartSettings;

    const { data, loading, refetch } = useQuery(
        GET_DEVICE_LAST_MESSAGE,
        {
            variables: { chartId },
        },
    );

    useMessageSubscription([deviceToken], pin, refetch);

    const lastMessage = data?.getDeviceLastMessage;

    const getWidgetData = () => {
        if (lastMessage) {
            return getDataCountWidgetData([{ lastMessage }]);
        }

        return null;
    };

    const widgetData = useMemo(
        () => getWidgetData(),
        [lastMessage],
    );

    return (
        <TemperatureWidget
            data={widgetData?.avg}
            unit={chartSettings.unit}
            devicesCount={widgetData?.devicesCount}
            title={title}
            isEmpty={loading || !widgetData}
        />
    );
};

Temperature.propTypes = {
    chartId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    chartSettings: PropTypes.object.isRequired,
    deviceToken: PropTypes.string.isRequired,
};
