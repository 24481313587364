import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const Image = styled.img`
    width: 96px;
    height: 96px;
`;

export const InfoWrapper = styled.div`
    p {
        margin-bottom: 3px;
        text-align: left;
    }
`;

export const Title = styled.span`
    font-weight: bold;
    margin-right: 5px;
`;
