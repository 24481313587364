import { FieldArray } from 'formik';

import { ObjectBuilder } from '../../../../../inputs';

import * as Styled from '../../styled';

export const HttpHeaders = () => (
    <Styled.FormContent>
        <FieldArray
            name="httpHeaders"
        >
            {(props) => (
                <ObjectBuilder
                    {...props}
                />
            )}
        </FieldArray>
    </Styled.FormContent>
);
