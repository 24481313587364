import { ROUTES } from 'Constants';

import {
    WifiSettings,
    LoraSettings,
    LTESettings,
    EthernetSettings,
    SigfoxSettings,
} from './components';

export const baseLinks = [
    {
        title: 'Wi-Fi Credentials',
        route: ROUTES.settings.network.wifi,
        name: 'wifi',
        component: WifiSettings,
        cyData: 'network-button-wifi',
    },
    {
        title: 'LTE Settings',
        route: ROUTES.settings.network.lte,
        name: 'lte',
        component: LTESettings,
        cyData: 'network-button-lte',
    },
    {
        title: 'LoRa Settings',
        route: ROUTES.settings.network.lora,
        name: 'lora',
        component: LoraSettings,
        cyData: 'network-button-lora',
    },
    {
        title: 'Ethernet Settings',
        route: ROUTES.settings.network.ethernet,
        name: 'ethernet',
        component: EthernetSettings,
        cyData: 'network-button-ethernet',
    },
    {
        title: 'Sigfox API',
        route: ROUTES.settings.network.sigfox,
        name: 'sigfox',
        component: SigfoxSettings,
        cyData: 'network-button-sigfox',
    },
];
