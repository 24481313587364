import * as Yup from 'yup';

import { ValidationService, GoogleService } from 'Services';
import { GOOGLE_CLOUD_STACKDRIVER_LOGGING } from 'Constants';

export const stackdriverOptions = GOOGLE_CLOUD_STACKDRIVER_LOGGING.map((item) => ({
    value: item.value,
    label: item.title,
}));

export const initialValues = {
    registryId: '',
    newTopic: '',
    subfolder: '',
    stackdriver: stackdriverOptions[0].value,
};

export const validationSchema = Yup.object().shape({
    registryId: Yup.string().required(ValidationService.getRequiredError('registry ID')),
    newTopic: Yup.string().required(ValidationService.getRequiredError('topic')),
    subfolder: Yup.string()
        .test(
            'subfolderValid',
            `Subfolder must contain only letters, numbers, hyphens, and the
                following characters: + . % _ ~`,
            GoogleService.isValidSubfolderName,
        ),
});
