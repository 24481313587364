import styled from 'styled-components';
import { Table as AntTable } from 'antd';

import { hexToRgb } from 'Utils';

import { Dropdown } from '../dropdown';

export const TableWrapper = styled(AntTable)`
    font-size: 14px;

    ${({ $clickableRows }) => $clickableRows && `
        .ant-table-row {
           cursor: pointer;
       }
    `}

    table {
        border: none;
        border-bottom: none;
        color: ${({ theme }) => theme.colors.text};
    }

    .ant-table-row,
    .ant-table-thead {
        .ant-table-cell {
            border: none;
        }
    }

    tr.ant-table-row:hover {
        td {
            background: rgba(${({ theme }) => hexToRgb(theme.colors.active)}, 0.1);
        }
    }

    .ant-table-row-selected {
        &:hover .ant-table-cell {
            background: rgba(${({ theme }) => hexToRgb(theme.colors.active)}, 0.1);
        }

        .ant-table-cell {
            background: rgba(${({ theme }) => hexToRgb(theme.colors.active)}, 0.1);
        }

    }

    .ant-table-thead {
        .ant-table-cell {
            background-color: ${({ theme }) => theme.colors.tableHeader};
            font-weight: bold;

            &:before {
                display: none;
            }
        }
    }

    .ant-checkbox {
        .ant-checkbox-inner {
            border-color: ${({ theme }) => theme.colors.border};
        }
    }

    .ant-checkbox-input {
        &:hover + .ant-checkbox-inner,
        &:focus + .ant-checkbox-inner {
            border-color: ${({ theme }) => theme.colors.border};
        }

        &:checked + .ant-checkbox-inner {
            border-color: ${({ theme }) => theme.colors.active};
        }
    }

    .ant-checkbox-checked {
        .ant-checkbox-inner {
            background: ${({ theme }) => theme.colors.active};
            border-color: ${({ theme }) => theme.colors.active};

            &::after {
                border-color: ${({ theme }) => theme.colors.activeText};
            }
        }

        &::after {
            border-color: ${({ theme }) => theme.colors.active};
        }
    }

    .ant-radio {
        .ant-radio-inner {
            border-color: ${({ theme }) => theme.colors.border};
        }
    }

    .ant-radio-input {
        &:hover + .ant-radio-inner,
        &:focus + .ant-radio-inner {
            border-color: ${({ theme }) => theme.colors.border};
        }

        &:checked + .ant-radio-inner {
            border-color: ${({ theme }) => theme.colors.active};
        }
    }

    .ant-radio-checked {
        .ant-radio-inner {
            border-color: ${({ theme }) => theme.colors.active};

            &::after {
                background-color: ${({ theme }) => theme.colors.active};
            }
        }

        &::after {
            border-color: ${({ theme }) => theme.colors.active};
        }
    }

    .ant-checkbox-indeterminate .ant-checkbox-inner {
        background-color: ${({ theme }) => theme.colors.activeText};

        &::after {
            background-color: ${({ theme }) => theme.colors.active};
        }
    }
`;

export const SearchWrapper = styled.div`
    margin-bottom: 22px;
`;

export const PaginationWrapper = styled.div`
    padding: 16px 0;
    display: flex;
`;

export const PaginationButton = styled.button`
    font-size: 14px;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.title};
    cursor: pointer;
    background: #fff;

    &:first-child {
        margin-right: 15px;
    }

    svg {
        font-size: 24px;
        line-height: 0;
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

export const PageSizeWrapper = styled.div`
    position: absolute;
    right: 0;

    span {
        margin-right: 10px;
    }
`;

export const DropdownButton = styled(Dropdown)`
    background-color: transparent;
    min-width: 30px;
    border: 1px solid rgba(${({ theme }) => hexToRgb(theme.colors.text)}, 0.2);
    display: inline-block;
`;

export const TableFooter = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
