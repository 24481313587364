import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { PageHeader, Tile } from 'Components';
import { ROUTES, ADD_PROJECT_TYPES } from 'Constants';
import { ReactComponent as AddProjectIcon } from 'Assets/icons/computer.svg';

import { AddProjectSelection } from '../addProjectSelection';

import * as Styled from './styled';

export const AddScreen = () => {
    const history = useHistory();
    const [addProjectModalOpen, setAddProjectModalOpen] = useState(false);

    const handleAddProjectSelectionClick = (type) => {
        switch (type) {
            case ADD_PROJECT_TYPES.FAST_IOT:
                history.push(ROUTES.projects.createFastIot);
                break;
            case ADD_PROJECT_TYPES.DEFAULT:
                history.push(ROUTES.projects.create);
                break;
            default:
                break;
        }
    };

    return (
        <div data-cy="project-starter-page-wrapper">
            <PageHeader
                title="My Projects"
                description={[
                    'A project allows you to manage multiple devices with minimum effort.',
                ]}
                stepper={false}
            />
            <Styled.Content>
                <Styled.TitleBox title="Add project" />
                <Styled.Button>
                    <Tile
                        onClick={() => setAddProjectModalOpen(true)}
                        icon={<AddProjectIcon />}
                        title=""
                        cyData="add-project-button-empty"
                        isNew
                    />
                </Styled.Button>
            </Styled.Content>
            <AddProjectSelection
                modalOpen={addProjectModalOpen}
                onModalClose={() => setAddProjectModalOpen(false)}
                handleOnModalBtnClick={handleAddProjectSelectionClick}
            />
        </div>
    );
};
