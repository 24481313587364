import { Form } from 'formik';

import { AuthInput } from 'Components';

import * as RootStyled from '../styled';

import * as Styled from './styled';

export const FormContent = () => (
    <Form>
        <div>
            <AuthInput
                name="email"
                label="Email"
            />
        </div>
        <Styled.ButtonWrapper>
            <RootStyled.Button type="submit">
                Reset password
            </RootStyled.Button>
        </Styled.ButtonWrapper>
    </Form>
);
