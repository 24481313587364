import styled, { css } from 'styled-components';

import { BATERRY_LEVEL_CHARGING } from 'Utils';

export const BatteryLevelContainer = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    flex-grow: 1;
`;

export const BatteryVesselContainer = styled.div`
    margin-top: 10px;
    margin-right: 55px;
`;

export const InTotal = styled.div`
    font-size: 60px;
    line-height: 1;
`;

export const Info = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
`;

export const Unit = styled.span`
    &::before {
        content: " ";
    }
    font-size: 26px;
    line-height: 26px;
`;

export const Battery = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    width: 64px;
    height: 100px;
    border: 4px solid ${({ $borderColor, theme }) => ($borderColor ? theme.colors.success : '#444444')};
    border-radius: 10px;
    padding: 4px;
    position: relative;

    &:before {
        content: '';
        height: 10px;
        width: 30px;
        background: ${({ $borderColor, theme }) => ($borderColor ? theme.colors.success : '#444444')};
        display: block;
        position: absolute;
        top: -10px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 4px 4px 0 0;
    }
`;

const normalStatus = css`
    background-color: ${({ theme }) => theme.colors.success};
`;
const alertStatus = css`
    background-color: ${({ theme }) => theme.colors.error};
`;
const warnStatus = css`
    background-color: ${({ theme }) => theme.colors.warning};
`;

export const BatteryLevel = styled.div`
    display: flex;
    width: 100%;
    height: 19%;
    border-radius: 1px;
    opacity: ${({ $opacity }) => $opacity};
    ${({ $status }) => {
        switch ($status) {
            case BATERRY_LEVEL_CHARGING.NORMAL:
                return normalStatus;
            case BATERRY_LEVEL_CHARGING.WARN:
                return warnStatus;
            case BATERRY_LEVEL_CHARGING.ALERT:
                return alertStatus;
            default:
                return '';
        }
    }}
`;
