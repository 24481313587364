import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const Input = styled.div`
    display: flex;
    align-items: center;
    width: 293px;
    height: 40px;
    padding: 0 10px;
    position: relative;
    background: #fff;
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 5px;
    cursor: pointer !important;
`;

export const Border = styled.div`
    width: 20px;
    height: 5px;
    position: absolute;
    left: -13px;
    background: ${({ theme }) => theme.colors.active};
    transform: rotate(90deg) translateY(-50%);
`;

export const Content = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    & > div {
        display: flex;
        align-items: center;
    }
`;

export const Drag = styled.div`
    width: 20px;
    height: 20px;
    margin-left: auto;
    cursor: move;
`;
