import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Modal, ProjectWidgetDescription } from 'Components';
import { PREVIEW_PROJECT_WIDGET_CONFIG } from 'Constants';

import * as Styled from './styled';

export const WidgetSettingsModal = ({ isOpen, onClose, onSubmit, widgetType }) => {
    const widgetPreview = useMemo(
        () => PREVIEW_PROJECT_WIDGET_CONFIG.find((item) => item.type === widgetType),
        [widgetType],
    );

    const onSubmitHandler = (values) => {
        onSubmit({
            ...values,
            name: widgetPreview?.name,
        });
    };

    return (
        <Modal isOpened={isOpen} handleClose={onClose}>
            <Styled.Modal>
                <Styled.Title>
                    {`Set up ${widgetPreview?.name} widget`}
                </Styled.Title>
                <Styled.Wrapper>
                    <ProjectWidgetDescription
                        nextStep={onSubmitHandler}
                        prevStep={onClose}
                        widgetType={widgetType}
                        submitText="Save"
                        isNameIncluded={false}
                    />
                </Styled.Wrapper>
            </Styled.Modal>
        </Modal>
    );
};

WidgetSettingsModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    widgetType: PropTypes.string.isRequired,
};
