import { useState } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

import { showToastError, showToastSuccess } from 'Utils';

import { initialValues, validationSchema } from './config';
import { FormContent } from './formContent';

export const CreateRegistry = ({ googleService, addRegistrySuccess }) => {
    const [loading, setLoading] = useState(false);

    const onSubmit = async (values) => {
        try {
            setLoading(true);

            const { registryId, newTopic, subfolder, stackdriver } = values;

            const result = await googleService.createRegistry({
                id: registryId,
                pubsubTopicName: newTopic,
                subfolderMatches: subfolder,
                logLevel: stackdriver,
            });

            showToastSuccess('Registry was created successfully');

            addRegistrySuccess(result);
        } catch (error) {
            showToastError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {(props) => <FormContent {...props} loading={loading} />}
        </Formik>
    );
};

CreateRegistry.propTypes = {
    googleService: PropTypes.object.isRequired,
    addRegistrySuccess: PropTypes.func.isRequired,
};

CreateRegistry.defaultProps = {
};
