import styled from 'styled-components';

export const MapHistoryContainer = styled.div``;

export const MapHistorySelectWrapper = styled.div`
    margin-bottom: 10px;
`;

export const MapHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
`;

export const MapHistoryOptions = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 300px;
    width: 100%;
`;

export const MapMarginEnd = styled.div`
    margin-right: 49px;
`;
