import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';

import { ReactComponent as Support } from 'Assets/network/support.svg';
import { GET_ETHERNETS, CREATE_ETHERNET_MUTATION, UPDATE_ETHERNET_MUTATION } from 'Constants';

import { showToastError, showToastSuccess } from 'Utils';
import {
    AUTO_SETTINGS_TYPES,
    validationSchema,
} from './config';
import { FormContent } from './formContent';
import { getInitialValues } from './helpers';

import * as Styled from './styled';

export const EthernetModal = ({ handleClose, handleSuccess, id }) => {
    const [createEthernet, { loading: isLoadingCreate }] = useMutation(CREATE_ETHERNET_MUTATION, {
        refetchQueries: [GET_ETHERNETS],
    });
    const [updateEthernet, { loading: isLoadingUpdate }] = useMutation(UPDATE_ETHERNET_MUTATION, {
        refetchQueries: [GET_ETHERNETS],
    });
    const { data: arrayOfEthernet } = useQuery(GET_ETHERNETS);
    const ethernets = arrayOfEthernet?.getAllEthernet ?? [];

    const updateEthernetSettings = async ({ 
        ethernetId,
        name, 
        autoSettings, 
        ipAddress, 
        subnetMask, 
        router, 
        dnsServer, 
        dnsServer2,
     }) => {
        try {
            updateEthernet({
                variables: {
                    ethernet: {
                        ethernetId,
                        name,
                        autoSettings: autoSettings === AUTO_SETTINGS_TYPES.AUTOMATIC,
                        ipAddress,
                        subnetMask,
                        router,
                        dnsServer,
                        dnsServer2,
                    },
                },
                update: () => {
                    showToastSuccess('Ethernet settings updated successfully');
                    const finalFunc = handleSuccess || handleClose;
                    finalFunc();
                },
            });
        } catch (error) {
            showToastError(error.message);
        }
    };

    const createEthernetSettings = async ({ 
        name, 
        autoSettings, 
        ipAddress, 
        subnetMask, 
        router, 
        dnsServer, 
        dnsServer2,
     }) => {
        try {
            createEthernet({
                variables: {
                    ethernet: {
                        name,
                        autoSettings: autoSettings === AUTO_SETTINGS_TYPES.AUTOMATIC,
                        ipAddress,
                        subnetMask,
                        router,
                        dnsServer,
                        dnsServer2,
                        default: true,
                    },
                },
                update: () => {
                    showToastSuccess('Ethernet network added successfully');
                    const finalFunc = handleSuccess || handleClose;
                    finalFunc();
                },
            });
        } catch (error) {
            showToastError(error.message);
        }
    };

    const submitClickHandler = id ? updateEthernetSettings : createEthernetSettings;

    return (
        <Styled.Modal>
            <Styled.Header>
                Add a new Ethernet
            </Styled.Header>
            <Styled.Description>
                <Support />
                <span>
                    A new Ethernet Credential will be automatically saved in the
                    General Network Settings.
                </span>
            </Styled.Description>
            <Formik
                enableReinitialize
                initialValues={getInitialValues(ethernets, id)}
                validationSchema={validationSchema}
                onSubmit={submitClickHandler}
            >
                {(props) => (
                    <FormContent
                        {...props}
                        handleClose={handleClose}
                        isLoading={isLoadingCreate || isLoadingUpdate}
                    />
                )}
            </Formik>
        </Styled.Modal>
    );
};

EthernetModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSuccess: PropTypes.func,
    id: PropTypes.string,
};

EthernetModal.defaultProps = {
    handleSuccess: null,
    id: '',
};
