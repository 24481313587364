import * as Yup from 'yup';

import { ValidationService } from 'Services';

const checkName = (pins, nameToCheck) => !pins.some(
    (pin) => (pin?.name?.toUpperCase() === nameToCheck?.toUpperCase()),
);

export const initialValues = {
    name: '',
    unit: '',
};

export const getValidationSchema = (pins) => Yup.object().shape({
    name: Yup.string()
        .test('name-unique', 'Signal with this name already exists', (value) => checkName(pins, value))
        .required(ValidationService.getRequiredError('name')),
});
