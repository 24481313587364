import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import { GET_APPLICATION_LAST_MESSAGES_COUNT_BY_CRITERIA } from 'Constants';
import { useMessageSubscription } from 'Hooks';

import { CountWidget } from '../../../../widgets';

export const Count = ({
    chartId,
    title,
    chartSettings,
}) => {
    const { pin } = chartSettings;

    const { data, error, loading, refetch } = useQuery(
        GET_APPLICATION_LAST_MESSAGES_COUNT_BY_CRITERIA,
        {
            variables: { chartId },
        },
    );
    const messagesCount = data?.getApplicationLastMessagesCountByCriteria?.messagesCount ?? 0;
    const devicesTokens = data?.getApplicationLastMessagesCountByCriteria?.devicesTokens ?? [];

    useMessageSubscription(devicesTokens, pin, refetch);

    return (
        <CountWidget
            data={messagesCount}
            isEmpty={loading || error}
            title={title}
        />
    );
};

Count.propTypes = {
    chartId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    chartSettings: PropTypes.object,
};

Count.defaultProps = {
    chartSettings: {},
};
