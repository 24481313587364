import { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { ThemeContext } from 'styled-components';

import { ReactComponent as Plus } from 'Assets/devices/plus.svg';
import { DeviceCard } from 'Components';
import { DEVICE_TEMPLATE_TYPES } from 'Constants';
import { getDeviceTemplateDescription } from 'Utils';

import { validationSchema } from './config';
import { FormContent } from './formContent';
import { getInitialValues } from './helpers';

import * as Styled from './styled';

export const DeviceSummary = ({
    backStep,
    saveDevice,
    allNetworks,
    deviceConfig,
    isLoading,
}) => {
    const styledTheme = useContext(ThemeContext);

    const deviceTemplateCards = useMemo(() => {
        const deviceTemplate = deviceConfig.device ?? deviceConfig.coModule;
        const deviceType = deviceConfig.device
            ? DEVICE_TEMPLATE_TYPES.DEVICES
            : DEVICE_TEMPLATE_TYPES.CO_MODULES;

        return (
            <div data-cy="add-device-summary-step">
                <DeviceCard
                    device={deviceTemplate}
                    descriptions={getDeviceTemplateDescription(deviceType, deviceTemplate)}
                />
                {deviceConfig.shield && (
                    <>
                        <Styled.DeviceComponentPlus>
                            <Plus />
                        </Styled.DeviceComponentPlus>
                        <DeviceCard
                            device={deviceConfig.shield}
                            descriptions={
                                getDeviceTemplateDescription(
                                    DEVICE_TEMPLATE_TYPES.SHIELDS,
                                    deviceConfig.shield,
                                )
                            }
                        />
                    </>
                )}
            </div>
        );
    }, [deviceConfig]);

    const generateTitle = useMemo(() => {
        const deviceTemplate = deviceConfig.device ?? deviceConfig.coModule;
        const firstPartOfTitle = `You are about to connect a ${deviceTemplate.name}`;
        const secondPartOfTitle = deviceConfig.shield
            ? ` to a ${deviceConfig.shield.name}.`
            : '.';

        return firstPartOfTitle + secondPartOfTitle;
    }, [deviceConfig]);

    const combinedInitialValues = useMemo(() => getInitialValues(deviceConfig), [deviceConfig]);

    const onSubmit = (values) => {
        const { name, description, wifiId, lteId } = values;

        const newDeviceValues = {
            customData: { name, description },
            networksConfig: { ...deviceConfig.networksConfig },
        };

        if (wifiId) {
            newDeviceValues.networksConfig.wifiId = wifiId;
        }

        if (lteId) {
            newDeviceValues.networksConfig.lteId = lteId;
        }

        saveDevice(newDeviceValues);
    };

    const descriptionText = `After clicking the Finish button, you will be redirected to
        the device page. Go to provisioning to connect it to ${styledTheme.whiteLabelCompany}.`;

    return (
        <Styled.Wrapper>
            <Styled.Header>
                <Styled.Title>
                    Summary
                </Styled.Title>
                <Styled.Description>
                    <p>{generateTitle}</p>
                    <p>
                        {descriptionText}
                    </p>
                </Styled.Description>
            </Styled.Header>
            <Styled.Content>
                <div>
                    <Formik
                        enableReinitialize
                        initialValues={combinedInitialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {(props) => (
                            <FormContent
                                {...props}
                                allNetworks={allNetworks}
                                goBack={backStep}
                                isLoading={isLoading}
                            />
                        )}
                    </Formik>
                </div>
                <Styled.Right>
                    {deviceTemplateCards}
                </Styled.Right>
            </Styled.Content>
        </Styled.Wrapper>
    );
};

DeviceSummary.propTypes = {
    backStep: PropTypes.func.isRequired,
    saveDevice: PropTypes.func.isRequired,
    allNetworks: PropTypes.object.isRequired,
    deviceConfig: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
};

DeviceSummary.defaultProps = {
    isLoading: false,
};
