import gql from 'graphql-tag';

export const SAVE_LORA_SETTINGS = gql`
    mutation saveLoRaSettings($lora: LoRaSettingsInput!) {
        saveLoRaSettings(lora: $lora)
    }
`;

export const GET_LORA_SETTINGS = gql`
    query getLoRaSettings {
        getLoRaSettings {
            usingABP
            loraServer
            loraRegion
        }
    }
`;

export const CHECK_OR_CREATE_PYLS_PROFILE = gql`
    mutation checkOrCreatePycomLoraServerProfile($loraRegion: String!) {
        checkOrCreatePycomLoraServerProfile(loraRegion: $loraRegion)
    }
`;
