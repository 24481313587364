import { 
    setPort,
    setInfo,
    setRegionList, 
    setRemoteConfig, 
    downloadFirmware, 
    writeFirmware, 
    setInitState,
    setError,
} from './actionsCreators';

export const getFirmwareSocketHandlers = (dispatch) => ({
    setFirmwareSocketPorts: (ports) => dispatch(setPort(ports)),
    setFirmwareSocketInfo: (info) => dispatch(setInfo(info)),
    setFirmwareSocketRegionList: (regionList) => dispatch(setRegionList(regionList)),
    setFirmwareSocketRemoteConfig: (remoteConfig) => dispatch(setRemoteConfig(remoteConfig)),
    downloadFirmware: (downloadedFirmware) => dispatch(downloadFirmware(downloadedFirmware)),
    writeFirmware: (firmware) => dispatch(writeFirmware(firmware)),
    setFirmwareSocketInit: () => dispatch(setInitState()),
    setFirmwareSocketError: (error) => dispatch(setError(error)),
});
