import * as Yup from 'yup';

export const initialValues = {
    baudRate: '',
    fileSystem: '',
    eraseFs: false,
    eraseNvs: false,
};

export const validationSchema = Yup.object().shape({
    baudRate: Yup.string().required('Speed is required.'),
    fileSystem: Yup.string().required('File system is required.'),
});
