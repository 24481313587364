import PropTypes from 'prop-types';
import {
    ComposedChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
} from 'recharts';

import { xLabelData } from '../../../chartHelpers';

export const Chart = ({ data }) => {
    const xLabel = {
        ...xLabelData,
        value: data.label,
    };

    return (
        <ResponsiveContainer
            width="100%"
            height={300}
            className="line-chart-responsive-container-wrapper"
        >
            <ComposedChart data={data.chartData} margin={{ left: 10, bottom: 10 }}>
                <CartesianGrid
                    strokeDasharray="4 3"
                    stroke="#ECF0F4"
                />
                <XAxis
                    dataKey="xAxis"
                    type="number"
                    domain={['dataMin', 'dataMax']}
                    stroke="#ECF0F4"
                    tickLine={false}
                    tick={{ fill: '#C0C7CE', fontSize: 14 }}
                    scale="time"
                    label={xLabel}
                />
                <YAxis
                    stroke="#ECF0F4"
                    tickLine={false}
                    tick={{ fill: '#C0C7CE', fontSize: 14 }}
                />
                <Line
                    dataKey="xValue"
                    dot={null}
                    stroke="rgb(66,75,90)"
                />
                <Line
                    dataKey="yValue"
                    dot={null}
                    stroke="rgb(0,215,237)"
                />
                <Line
                    dataKey="zValue"
                    dot={null}
                    stroke="rgb(0,204,150)"
                />
            </ComposedChart>
        </ResponsiveContainer>
    );
};

Chart.propTypes = {
    data: PropTypes.shape({
        chartData: PropTypes.array,
        label: PropTypes.string,
    }).isRequired,
};
