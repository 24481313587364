import PropTypes from 'prop-types';

import { ReactComponent as MountainIcon } from 'Assets/widgets/mountain.svg';

import { EmptyWidget } from '../emptyWidget';

import * as StyledGeneral from '../styled';
import * as Styled from './styled';

export const AltitudeWidget = ({ data, isEmpty, title }) => {
    const isDataBroken = Number.isNaN(+data?.value);

    if (isEmpty || isDataBroken) {
        return (
            <EmptyWidget
                title={title}
                isDataBroken={isDataBroken}
            />
        );
    }

    return (
        <>
            <StyledGeneral.ChartTitle>
                {title}
            </StyledGeneral.ChartTitle>
            <Styled.AltitudeContainer>
                <Styled.AltitudeUnit>
                    {data.unit}
                </Styled.AltitudeUnit>
                <Styled.AltitudeValue>
                    <div>
                        {data.value}
                        <span>
                            {data.unit}
                        </span>
                    </div>
                    <MountainIcon />
                </Styled.AltitudeValue>
            </Styled.AltitudeContainer>
        </>
    );
};

AltitudeWidget.propTypes = {
    data: PropTypes.object,
    title: PropTypes.string.isRequired,
    isEmpty: PropTypes.bool,
};

AltitudeWidget.defaultProps = {
    isEmpty: false,
    data: null,
};
