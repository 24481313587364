import styled, { css } from 'styled-components';

export const Container = styled.div`
    display: grid;
    grid-template-columns: 30% 70%;
    height: 100%;
`;

export const Info = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
`;

export const Chart = styled.div`
    height: 100%;
    padding: 10px 0 40px;
    position: relative;
`;

export const Value = styled.div`
    margin-right: 7px;
    font-size: 30px;
`;

export const UnitsWrapper = styled.div`
    display: flex;
    color: #000;
    line-height: 1;
    align-items: flex-end;
`;

export const Unit = styled.span`
    cursor: pointer;
    transition: color 0.3s;
    font-size: 18px;
    padding-bottom: 2px;

    ${({ $active }) => $active && `
        color: #000;
    `}

    &:hover {
        color: #000;
    }
`;

export const gaugeLabelStyles = css`
    position: absolute;
    font-size: 12px;
    line-height: 15px;
    color: #BDBDBD;

    &::before {
        position: absolute;
        content: ' ';
        background: #BDBDBD;
    }
`;

export const GaugeLabel = styled.div`
    position: absolute;
    font-size: 12px;
    color: #bdbdbd;
    ${({ $top, $left, $right }) => `
        top: ${$top}px;
        left: ${$left}px;
        right: ${$right}px;
    `}
`;
