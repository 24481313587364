import { ReactComponent as AppStoreBtn } from 'Assets/images/appstore_download_logo.svg';
import GooglePlayBtn from 'Assets/images/google_play_badge.png';
import AppImg1 from 'Assets/images/pybytes_screen1.png';
import AppImg2 from 'Assets/images/pybytes_screen2.png';
import AppImg3 from 'Assets/images/pybytes_screen3.png';

import * as Styled from './styled';

export const AppModal = () => (
    <>
        <Styled.Wrapper>
            <Styled.Title>Download the future</Styled.Title>
            <Styled.Subtitle>
                Pybytes App will help you to provision one or one thousand Pycom devices with just a few clicks!
            </Styled.Subtitle>
            <Styled.Images>
                <img src={AppImg1} alt="Pybytes App Login" />
                <img src={AppImg2} alt="Pybytes App Start" />
                <img src={AppImg3} alt="Pybytes App Devices List" />
            </Styled.Images>
            <Styled.Links>
                <Styled.ExtLink href={process.env.REACT_APP_IOS_APP_URL} target="_blank" rel="noreferrer">
                    <AppStoreBtn />
                </Styled.ExtLink>
                <Styled.ExtLink href={process.env.REACT_APP_GOOGLE_STORE_APP_URL} target="_blank" rel="noreferrer">
                    <img src={GooglePlayBtn} alt="Google Play Badge" />
                </Styled.ExtLink>
            </Styled.Links>
            <Styled.Links>
                <Styled.ExtLink href={process.env.REACT_APP_PYBYTES_DOC} target="_blank" rel="noreferrer">
                    <Styled.Doc>Read Pybytes Documentation</Styled.Doc>
                </Styled.ExtLink>
            </Styled.Links>
        </Styled.Wrapper>
    </>
);
