import PropTypes from 'prop-types';

import { Loader } from '../loader';

import * as Styled from './styled';

export const Spin = ({ spinning, children, customSize }) => (
    <Styled.Spin>
        {children}
        {spinning && <Loader customSize={customSize} overlay />}
    </Styled.Spin>
);

Spin.propTypes = {
    spinning: PropTypes.bool,
    children: PropTypes.node,
    customSize: PropTypes.string,
};

Spin.defaultProps = {
    spinning: false,
    children: <div />,
    customSize: null,
};
