import PropTypes from 'prop-types';

import { ReactComponent as Support } from 'Assets/icons/support.svg';

import * as Styled from './styled';

export const AccessKeysModal = ({
    keys,
    error,
    source,
    onClose,
}) => {
    const title = error ? 'Regeneration failed' : 'Regenerate Access Keys';
    const info = 'You can get access to your project from Cloud Platforms by using AccessID and Secret Key.';
    const description = error 
        ? 'Access keys pair generation process has been failed'
            : 'Access keys pair has been successfully generated';

    const downloadKeys = () => {
        const elem = document.createElement('a');
        
        const content = `Downloaded on: ${new Date().toDateString()};\n\nAccess ID:   ${keys.accessId};\nSecret Key:  ${keys.secret};`;
    
        const file = new Blob([content], { type: 'text/plain' });
        elem.href = URL.createObjectURL(file);
        elem.download = `${source}_access_keys.txt`;
        elem.click();
        onClose();
    };

    return (
        <Styled.ModalWrapper>
            <Styled.ModalHeader>
                {title}
            </Styled.ModalHeader>
            <Styled.ModalBody>
                <Styled.ModalDescription>
                    <span>{description}</span>
                </Styled.ModalDescription>
                {!error && (
                    <Styled.ContentWrapper>
                        <Styled.RowWrapper>
                            <Styled.ModalKey>Access ID: </Styled.ModalKey>
                            <Styled.ModalValue>
                                {keys.accessId}
                            </Styled.ModalValue>
                        </Styled.RowWrapper>
                        <Styled.RowWrapper>
                            <Styled.ModalKey>Secret Key: </Styled.ModalKey>
                            <Styled.ModalValue>
                                {keys.secret}
                            </Styled.ModalValue>
                        </Styled.RowWrapper>
                        <Styled.InfoBlock>
                            <Support />
                            <Styled.InfoMessage>
                                {info}
                            </Styled.InfoMessage>
                        </Styled.InfoBlock>
                        <Styled.DownloadButton onClick={downloadKeys}>
                            Save and Download Keys
                        </Styled.DownloadButton>
                    </Styled.ContentWrapper>
                )}
            </Styled.ModalBody>
        </Styled.ModalWrapper>
    );
};

AccessKeysModal.propTypes = {
    keys: PropTypes.object,
    error: PropTypes.string,
    source: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,

};

AccessKeysModal.defaultProps = {
    keys: {},
    error: null,
};
