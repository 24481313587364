import { gql } from 'graphql-tag';

export const GET_ACTIVE_SYSTEM_NOTIFICATIONS = gql`
    query getActiveSystemNotifications {
        getActiveSystemNotifications {
            title
            content
            type
            active
            systemNotificationId
        }
    }
`;
