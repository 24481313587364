import { Popover } from 'antd';
import { useQuery } from '@apollo/client';

import { GET_ACTIVE_SYSTEM_NOTIFICATIONS } from 'Constants';
import { ReactComponent as NotificationIcon } from 'Assets/icons/bell.svg';

import * as Header from '../../styled';

import * as Styled from './styled';

export const Notifications = () => {
    const { data } = useQuery(GET_ACTIVE_SYSTEM_NOTIFICATIONS);
    const notifications = data?.getActiveSystemNotifications ?? [];

    const content = notifications.length ? (
        <Styled.Popover>
            {notifications?.map((notification) => (
                <Styled.Notification key={notification.systemNotificationId} $type={notification.type}>
                    <Styled.Title>
                        {notification.title}
                    </Styled.Title>
                    <Styled.Content>
                        {notification.content}
                    </Styled.Content>
                </Styled.Notification>
            ))}
        </Styled.Popover>
    ) : (
        <Styled.Notification>
            No notifications
        </Styled.Notification>
    );
    return (
        <Popover content={content}>
            <Header.CircleIcon>
                <NotificationIcon />
                {notifications.length > 0 && (
                    <Styled.Badge>
                        <span>{notifications.length}</span>
                    </Styled.Badge>
                )}
            </Header.CircleIcon>
        </Popover>
    );
};
