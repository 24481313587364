import PropTypes from 'prop-types';
import { Field } from 'formik';
import { Tooltip } from 'antd';

import { ReactComponent as QuestionMark } from 'Assets/icons/questions.svg';

import * as Styled from './styled';

export const SelectSectionField = ({ title, tooltip, extension, ...props }) => (
    <Styled.SelectSectionRow>
        <Styled.SelectionRowTitle>
            <Styled.Title>
                {title}
            </Styled.Title>
            <Tooltip placement="right" title={tooltip}>
                <Styled.ToolTipIcon>
                    <QuestionMark />
                </Styled.ToolTipIcon>
            </Tooltip>
        </Styled.SelectionRowTitle>
        <Styled.FieldWrapper>
           <Field {...props} />
            {extension && (
                <Styled.Extension>
                    {extension}
                </Styled.Extension>
            )}
        </Styled.FieldWrapper>
    </Styled.SelectSectionRow>
);

SelectSectionField.propTypes = {
    title: PropTypes.string.isRequired,
    tooltip: PropTypes.node.isRequired,
    extension: PropTypes.string,
};

SelectSectionField.defaultProps = {
    extension: null,
};
