import styled from 'styled-components';
import { shadowSm } from 'Styles';

export const Page = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-width: 75rem;
    
    margin-top: 1rem;
`;

export const Title = styled.h1`
    font-size: 2rem;
    font-weight: 600;
    margin-top: -0.5rem;
    color: ${({ theme }) => theme.colors.title};
`;

export const Box = styled.div`
    padding: 2rem;
    border-radius: 0.5rem;
    background-color: white;
    border: 1px solid ${({ theme }) => theme.colors.border};
    ${shadowSm}
`;

export const CardsRow = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    
    @media (max-width: 1400px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 1050px) {
        grid-template-columns: 1fr;
    }
`;

export const MiddleRow = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;

    @media (max-width: 1200px) {
        grid-template-columns: 1fr;
    }
`;

export const Devices = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
`;

export const Support = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
`;
