export const columns = [
    {
        title: 'Node',
        dataIndex: 'description',
    },
    {
        title: 'Border Router',
        dataIndex: 'borderName',
    },
    {
        title: 'Last Connection',
        dataIndex: 'lastConnection',
    },
    {
        title: 'Number Of Neighbors',
        dataIndex: 'neighborsNum',
    },
];
