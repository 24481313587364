import PropTypes from 'prop-types';

import { PREVIEW_PROJECT_WIDGET_CONFIG } from 'Constants';

import { WidgetDummyContainer, WidgetDummyContent, Title, IconWrapper } from './styled';

export const ProjectWidgetDummy = ({ type, onClick, active }) => {
    const widgetConfig = PREVIEW_PROJECT_WIDGET_CONFIG.find((config) => config.type === type);

    if (!widgetConfig) {
        return null;
    }

    const Icon = widgetConfig.icon;

    return (
        <WidgetDummyContainer
            $active={active}
            onClick={() => onClick(type)}
            data-cy={widgetConfig.cyData}
        >
            <WidgetDummyContent>
                <Title>{widgetConfig.name}</Title>
                <IconWrapper>
                    <Icon />
                </IconWrapper>
            </WidgetDummyContent>
        </WidgetDummyContainer>
    );
};

ProjectWidgetDummy.propTypes = {
    type: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    active: PropTypes.bool,
};

ProjectWidgetDummy.defaultProps = {
    active: false,
};
