import PropTypes from 'prop-types';

import { Table } from 'Components';

import * as Styled from './styled';

export const SessionTable = ({ columns, tableData }) => (
    <Styled.Wrapper>
        <Table columns={columns} data={tableData} />
    </Styled.Wrapper>
);

SessionTable.propTypes = {
    columns: PropTypes.array.isRequired,
    tableData: PropTypes.array.isRequired,
};
