import { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 } from 'uuid';

import * as Styled from './styled';

export const LayersInput = ({ form, name, insert, remove }) => {
    const layers = form.values[name];

    const [inputValue, setInputValue] = useState('0');

    const onChange = (event) => {
        setInputValue(event.target.value);
    };

    const onAddLayer = () => {
        const value = +inputValue;

        if (value > 0) {
            const layerValue = {
                id: v4(),
                layer: 'Dense Layer',
                value,
                type: 'dense_layer',
                unit: 'neurons',
                edit: true,
            };

            insert(layers.length - 1, layerValue);
        }
    };

    const onDeleteLayer = (index) => {
        remove(index);
    };

    return (
        <>
            {layers.map((layer, index) => (
                <Styled.Layer key={layer.id}>
                    {`${layer.layer} (${layer.value} ${layer.unit})`}
                    {layer.edit && (
                        <Styled.RemoveButton
                            onClick={() => onDeleteLayer(index)}
                        />
                    )}
                </Styled.Layer>
            ))}
            <Styled.AddLayerWrapper>
                <Styled.Input
                    value={inputValue}
                    onChange={onChange}
                    type="number"
                    min={0}
                />
                <Styled.AddButton
                    disabled={layers.length >= 5}
                    onClick={onAddLayer}
                >
                    Add Dense Layer
                </Styled.AddButton>
            </Styled.AddLayerWrapper>
        </>
    );
};

LayersInput.propTypes = {
    form: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    insert: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
};
