import PropTypes from 'prop-types';

import { Button } from 'Components';

import * as Styled from './styled';

export const FirmwareUpdaterError = ({ actionClick }) => (
    <Styled.Error>
        <Styled.Title>
            Error
        </Styled.Title>
        <Styled.Description>
            Firmware Updater Tool is not up running in your system. In order to
            use the Online Firmware Updater, you must open Firmware Updater
            Tool.
        </Styled.Description>
        <Button onClick={actionClick}>
            Try again
        </Button>
    </Styled.Error>
);

FirmwareUpdaterError.propTypes = {
    actionClick: PropTypes.func.isRequired,
};
