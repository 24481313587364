import PropTypes from 'prop-types';

import * as Styled from './styled';

export const Select = ({
    values,
    field,
    handleSelect,
    form,
    custom,
    type,
    selectedItem,
    placeholder,
    disabled,
    cyData,
}) => {
    const currentHandleSelect = (e) => {
        const value = e.target.value;
        if (field) {
            field.onChange(e);
        }
        handleSelect({ type, value });
    };

    const showError = () => {
        if (field && form.errors.length) {
            return (
                <Styled.Error $isAnimated={!!form.errors[field.name]}>
                    {form.errors[field.name]}
                </Styled.Error>
            );
        }
        return null;
    };

    const handleValues = () => {
        if (values) {
            if (typeof values[0] === 'object') {
                switch (type) {
                    case 'WiFi':
                        return values.map((item) => (
                            <option key={item._id} value={item._id}>
                                {item.ssid}
                            </option>
                        ));
                    case 'LTE':
                        return values.map((item) => (
                            <option key={item._id} value={item._id}>
                                {item.name}
                            </option>
                        ));
                    default:
                        const result = values.map((item) => (
                            <option key={item.value} value={item.value}>
                                {item.label}
                            </option>
                        ));

                        if (placeholder) {
                            result.unshift(
                                <option
                                    key="placeholder"
                                    value=""
                                    disabled
                                    hidden
                                >
                                    {placeholder}
                                </option>,
                            );
                        }
                        return result;
                }
            } else {
                return values.map((item) => (
                    <option key={item} value={item}>
                        {item}
                    </option>
                ));
            }
        }
    };

    const onClick = (e) => {
        e.stopPropagation();
    };

    return (
        <Styled.Select>
            <Styled.Field
                value={field ? field.value : selectedItem}
                name={field ? field.name : ''}
                onBlur={field ? field.onBlur : () => {}}
                onChange={custom ? currentHandleSelect : field.onChange}
                disabled={disabled}
                data-cy={cyData}
                onClick={onClick}
            >
                {handleValues()}
            </Styled.Field>
            <div>{showError()}</div>
        </Styled.Select>
    );
};

Select.propTypes = {
    values: PropTypes.array.isRequired,
    custom: PropTypes.bool,
    handleSelect: PropTypes.func,
    field: PropTypes.object,
    form: PropTypes.object,
    type: PropTypes.string,
    selectedItem: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.number,
        PropTypes.string,
    ]),
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    cyData: PropTypes.string,
};

Select.defaultProps = {
    custom: false,
    handleSelect: () => {},
    field: null,
    form: null,
    type: '',
    selectedItem: '',
    placeholder: '',
    disabled: false,
    cyData: '',
};
