import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { ROUTES } from 'Constants';

import * as Styled from './styled';

export const DevicesTable = ({ devicesArr, tokens }) => {
    const history = useHistory();

    const deviceOnClick = (token) => {
        if (!token) {
            return;
        }
        const { main, deviceDetails } = ROUTES.devices;
        history.push(`${main}/${token}${deviceDetails.dashboard}`);
    };

    if (!tokens.length) {
        return null;
    }

    return (
        <Styled.DetailsWrapper>
            <Styled.DetailsHead disabled>
                <span>Details</span>
                <span>Token</span>
            </Styled.DetailsHead>
            {tokens.map((token) => {
                const device = devicesArr.find((deviceItem) => deviceItem.token === token);

                return (
                    <Styled.DetailsLine
                        disabled={!device?._id}
                        key={token}
                        onClick={() => deviceOnClick(device?.token)}
                        data-cy={`webhook-device-${token}`}
                    >
                        <span>
                            {device?.description ?? 'Deleted device'}
                        </span>
                        <span>
                            {token}
                        </span>
                    </Styled.DetailsLine>
                );
            })}
        </Styled.DetailsWrapper>
    );
};

DevicesTable.propTypes = {
    devicesArr: PropTypes.array.isRequired,
    tokens: PropTypes.array,
};

DevicesTable.defaultProps = {
    tokens: [],
};
