import { useMemo, useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import {
    UPDATE_DEVICE_CHART,
    DELETE_DEVICE_CHART,
    GET_DEVICE_CHARTS_QUERY,
    DIMENSION_MULTIPLIERS,
    CHART_TYPES,
    DEVICE_MESSAGES_PER_RANGE_QUERY,
    DEVICE_MESSAGES_BY_CHART_WITH_PIN_QUERY,
} from 'Constants';
import { showToastSuccess, removeGraphQlTypenames } from 'Utils';

import { DeviceWidgetEdit } from '../deviceWidgetEdit';
import {
    BarChartDataReceived,
    LineChartDataReceived,
    VirtualList,
    Map,
    BarChart,
    LineChart,
    AirQuality,
    Altitude,
    HeartRate,
    Humidity,
    MapHistory,
    Pressure,
    Temperature,
    Weather,
    DeviceLocation,
    SignalQuality,
    BatteryLevel,
} from './components';
import { WidgetMenu, EmptyWidget } from '../../../widgets';

import * as Styled from './styled';

export const WidgetContainer = (props) => {
    const { chartId, title, chartType, widgetConfig, settings, device } = props;
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    const [updateDeviceChart] = useMutation(UPDATE_DEVICE_CHART);

    const [deleteWidgetMutation] = useMutation(DELETE_DEVICE_CHART, {
        refetchQueries: [GET_DEVICE_CHARTS_QUERY],
    });

    const widgetStyles = useMemo(() => {
        const { width: widthMultiplier, height: heightMultiplier } = DIMENSION_MULTIPLIERS;
        const height = (heightMultiplier * widgetConfig.height) - 12;
        const width = ((widthMultiplier * widgetConfig.width) - 12);
        const left = widgetConfig.x * widthMultiplier;
        const top = widgetConfig.y * heightMultiplier;
        return { height, width, left, top };
    }, [widgetConfig]);

    const renderEmptyWidget = useCallback(() => <EmptyWidget title={title} />, []);

    const onWidgetDelete = (id) => {
        deleteWidgetMutation({
            variables: {
                id,
            },
            update: () => {
                showToastSuccess('Widget removed successfully');
            },
        });
    };

    const onEdit = (values) => {
        const filteredValues = removeGraphQlTypenames(values);

        const { name, settings: editSettings } = filteredValues;

        updateDeviceChart({
            variables: {
                chartInput: {
                    _id: chartId,
                    name,
                    settings: editSettings,
                },
            },
            refetchQueries: [DEVICE_MESSAGES_BY_CHART_WITH_PIN_QUERY, DEVICE_MESSAGES_PER_RANGE_QUERY, GET_DEVICE_CHARTS_QUERY],
            update: () => {
                setIsEditModalOpen(false);
                showToastSuccess('Widget edited successfully');
            },
        });
    };

    const updateChartSettingsLimit = (limitObj) => {
        const filteredSettings = removeGraphQlTypenames(settings);

        updateDeviceChart({
            variables: {
                chartInput: {
                    _id: chartId,
                    settings: {
                        ...filteredSettings,
                        limit: +limitObj.value,
                    },
                },
            },
        });
    };

    const renderWidget = () => {
        switch (chartType) {
            case CHART_TYPES.DATA_RECEPTION_PER_PIN_BAR_CHART:
                return (
                    <BarChartDataReceived
                        label={title}
                        timePeriod={settings.timePeriod}
                        renderEmptyWidget={renderEmptyWidget}
                        deviceToken={device.token}
                        pin={settings.pin}
                    />
                );
            case CHART_TYPES.DATA_RECEPTION_BAR_CHART:
                return (
                    <BarChartDataReceived
                        label={title}
                        timePeriod={settings.timePeriod}
                        renderEmptyWidget={renderEmptyWidget}
                        deviceToken={device.token}
                        pin={settings.pin}
                    />
                );
            case CHART_TYPES.DATA_RECEPTION_DAY:
                return (
                    <BarChartDataReceived
                        label={title}
                        renderEmptyWidget={renderEmptyWidget}
                        deviceToken={device.token}
                        pin={settings.pin}
                    />
                );
            case CHART_TYPES.DATA_RECEPTION_PER_PIN_LINE_CHART:
                return (
                    <LineChartDataReceived
                        label={title}
                        timePeriod={settings.timePeriod}
                        renderEmptyWidget={renderEmptyWidget}
                        deviceToken={device.token}
                        pin={settings.pin}
                    />
                );
            case CHART_TYPES.DATA_RECEPTION_LINE_CHART:
                return (
                    <LineChartDataReceived
                        label={title}
                        timePeriod={settings.timePeriod}
                        renderEmptyWidget={renderEmptyWidget}
                        deviceToken={device.token}
                        pin={settings.pin}
                    />
                );
            case CHART_TYPES.DATA_RECEPTION_HOUR:
                return (
                    <LineChartDataReceived
                        label={title}
                        renderEmptyWidget={renderEmptyWidget}
                        deviceToken={device.token}
                        pin={settings.pin}
                    />
                );
            case CHART_TYPES.MAP:
                return (
                    <Map
                        chartId={chartId}
                        title={title}
                        device={device}
                        pin={settings.pin}
                    />
                );
            case CHART_TYPES.DEVICE_LOCATION:
                return (
                    <DeviceLocation
                        device={device}
                        title={title}
                    />
                );
            case CHART_TYPES.TABLE:
                return (
                    <VirtualList
                        deviceToken={device.token}
                        title={title}
                        pin={settings.pin}
                        widgetStyles={widgetStyles}
                        chartId={chartId}
                    />
                );
            case CHART_TYPES.BAR_CHART:
                return (
                    <BarChart
                        title={title}
                        chartSettings={settings}
                        updateChartSettingsLimit={updateChartSettingsLimit}
                        device={device}
                        chartId={chartId}
                    />
                );
            case CHART_TYPES.LINE_CHART:
                return (
                    <LineChart
                        title={title}
                        chartSettings={settings}
                        updateChartSettingsLimit={updateChartSettingsLimit}
                        device={device}
                        chartId={chartId}
                    />
                );
            case CHART_TYPES.AIR_QUALITY:
                return (
                    <AirQuality
                        title={title}
                        chartId={chartId}
                        deviceToken={device.token}
                        pin={settings.pin}
                    />
                );
            case CHART_TYPES.ALTITUDE:
                return (
                    <Altitude
                        title={title}
                        chartSettings={settings}
                        deviceToken={device.token}
                        chartId={chartId}
                    />
                );
            case CHART_TYPES.HEART_RATE:
                return (
                    <HeartRate
                        title={title}
                        chartId={chartId}
                        deviceToken={device.token}
                        pin={settings.pin}
                    />
                );
            case CHART_TYPES.HUMIDITY:
                return (
                    <Humidity
                        title={title}
                        chartId={chartId}
                        deviceToken={device.token}
                        pin={settings.pin}
                    />
                );
            case CHART_TYPES.SIGNAL_QUALITY:
                return (
                    <SignalQuality
                        title={title}
                        chartId={chartId}
                        deviceToken={device.token}
                        pin={settings.pin}
                    />
                );
            case CHART_TYPES.BATTERY_LEVEL:
                return (
                    <BatteryLevel
                        title={title}
                        chartId={chartId}
                        deviceToken={device.token}
                        pin={settings.pin}
                    />
                );
            case CHART_TYPES.MAP_HISTORY:
                return (
                    <MapHistory
                        title={title}
                        chartSettings={settings}
                        updateDeviceChart={updateDeviceChart}
                        chartId={chartId}
                        device={device}
                    />
                );
            case CHART_TYPES.PRESSURE:
                return (
                    <Pressure
                        title={title}
                        chartId={chartId}
                        deviceToken={device.token}
                        pin={settings.pin}
                    />
                );
            case CHART_TYPES.TEMPERATURE:
                return (
                    <Temperature
                        title={title}
                        chartId={chartId}
                        chartSettings={settings}
                        deviceToken={device.token}
                    />
                );
            case CHART_TYPES.WEATHER:
                return (
                    <Weather
                        title={title}
                        chartSettings={settings}
                    />
                );
            default:
                return renderEmptyWidget();
        }
    };

    return (
        <Styled.WidgetContainer style={widgetStyles}>
            <div className="tabPanel extended heightExtended noMargin">
                <WidgetMenu
                    id={chartId}
                    onDelete={onWidgetDelete}
                    title={title}
                    onEdit={() => setIsEditModalOpen(true)}
                />
                {renderWidget()}
            </div>
            <DeviceWidgetEdit
                isOpen={isEditModalOpen}
                onClose={() => setIsEditModalOpen(false)}
                onSubmit={onEdit}
                widgetType={chartType}
                initialValues={{ name: title, type: chartType, settings }}
            />
        </Styled.WidgetContainer>
    );
};

WidgetContainer.propTypes = {
    title: PropTypes.string.isRequired,
    chartType: PropTypes.string.isRequired,
    widgetConfig: PropTypes.object.isRequired,
    chartId: PropTypes.string.isRequired,
    settings: PropTypes.object.isRequired,
    device: PropTypes.shape({
        token: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
    }).isRequired,
};
