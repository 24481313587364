import PropTypes from 'prop-types';

import { Modal } from 'Components';

import { ModalContent } from './modalContent';

export const AddDevicesModal = ({ isOpen, handleClose, projectId }) => (
    <Modal
        isOpened={isOpen}
        handleClose={handleClose}
        destroyOnClose={false}
    >
        <ModalContent handleClose={handleClose} projectId={projectId} />
    </Modal>
);

AddDevicesModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    projectId: PropTypes.string.isRequired,
};
