import PropTypes from 'prop-types';
import { Table, Spin, useLastConnection, Button } from 'Components';
import * as Styled from './styled';

export const TableView = ({
    title,
    columns,
    items,
    onSelect,
    onPagination,
    pagination,
    onClick,
    disabledButton,
}) => {
    const { data, loading } = useLastConnection(items);
    return (
        <Styled.TableWrapper>
            <h2>{title}</h2>
            <Styled.Table>
                <Spin spinning={loading}>
                    <Table
                        columns={columns}
                        data={data}
                        onSelect={onSelect}
                        onPaginationChange={onPagination}
                        pagination={pagination}
                    />
                </Spin>
            </Styled.Table>
            <Styled.Button>
                <Button onClick={onClick} disabled={disabledButton}>
                    Continue
                </Button>
            </Styled.Button>
        </Styled.TableWrapper>
    );
};

TableView.defaultProps = {
    onPagination: () => {},
    pagination: {},
    onClick: () => {},
    disabledButton: false,
};

TableView.propTypes = {
    title: PropTypes.string.isRequired,
    columns: PropTypes.array.isRequired,
    items: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired,
    onPagination: PropTypes.func,
    pagination: PropTypes.object,
    onClick: PropTypes.func,
    disabledButton: PropTypes.bool,
};
