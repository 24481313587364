import gql from 'graphql-tag';

export const SAMPLES_BY_DEVICE_TOKEN_SUBSCRIPTION = gql`
   subscription samplesByDeviceToken($deviceTokenList: [String!]){
     samplesByDeviceToken(deviceTokenList:$deviceTokenList){
        _id
        deviceId
        deviceToken
        label
        type
        modelId
        message {
           payload
           createdAt
        }
     }
   }
`;

export const ADD_ML_MODEL_MUTATION = gql`
   mutation addMlModel($model: MlModelInputType!){
         addMlModel(model:$model){
          _id
          name
          description
          processingType
          learningTechnique
          frequency
          windowsStep
          windowsSize
          devices {
           token
           deviceId
         }
     }
   }
`;

export const SET_ML_MODEL_MUTATION = gql`
   mutation setMlModel($model: MlModelInputType!){
         setMlModel(model:$model){
          _id
          name
          description
          processingType
          learningTechnique
          frequency
          windowsStep
          windowsSize
          devices {
           token
           deviceId
         }
     }
   }
`;

export const SET_MODEL_DEFINITION = gql`
   mutation setModelDefinition($modelId: String!, $modelDefinition: String!){
         setModelDefinition(modelId: $modelId, modelDefinition:$modelDefinition){
          _id
          name
          description
          processingType
          learningTechnique
          frequency
          modelDefinition
          preProcessingStatus
          features
          training
          windowsStep
          windowsSize
          devices {
           token
           deviceId
         }
     }
   }
`;

export const SET_PRE_PROCESSING_STATUS = gql`
   mutation setPreProcessingStatus($modelId: String!, $preProcessingStatus: String!){
         setPreProcessingStatus(modelId: $modelId, preProcessingStatus:$preProcessingStatus){
          _id
          name
          description
          processingType
          learningTechnique
          frequency
          modelDefinition
          preProcessingStatus
          features
          training
          windowsStep
          windowsSize
          devices {
           token
           deviceId
         }
     }
   }
`;

export const SET_FEATURES = gql`
   mutation setFeatures($modelId: String!, $features: String!){
         setFeatures(modelId: $modelId, features:$features){
          _id
          name
          description
          processingType
          learningTechnique
          frequency
          modelDefinition
          preProcessingStatus
          features
          training
          windowsStep
          windowsSize
          devices {
           token
           deviceId
         }
     }
   }
`;

export const SET_TRAINING = gql`
   mutation setTraining($modelId: String!, $training: String!){
         setTraining(modelId: $modelId, training:$training){
          _id
          name
          description
          frequency
          processingType
          learningTechnique
          modelDefinition
          preProcessingStatus
          features
          training
          windowsStep
          windowsSize
          devices {
           token
           deviceId
         }
     }
   }
`;

export const SET_TESTING = gql`
   mutation setTesting($modelId: String!, $testing: String!){
      setTesting(modelId: $modelId, testing:$testing){
          _id
          name
          description
          frequency
          processingType
          learningTechnique
          modelDefinition
          preProcessingStatus
          features
          training
          windowsStep
          windowsSize
          devices {
           token
           deviceId
         }
     }
   }
`;

export const SET_LAST_PROCESSED_DATA = gql`
   mutation setLastProcessedData($modelId: String!, $lastProcessedData: LastProcessedDataInput!){
      setLastProcessedData(modelId: $modelId, lastProcessedData:$lastProcessedData){
          _id
          name
          description
          frequency
          processingType
          learningTechnique
          modelDefinition
          preProcessingStatus
          features
          training
          windowsStep
          windowsSize
          lastProcessedData {
             sampleId
             t0
          }
          devices {
             token
             deviceId
         }
      }
   }
`;

export const GET_ML_MODEL_QUERY = gql`
   query getMlModels{
         getMlModels{
          _id
          name
          description
          frequency
          processingType
          learningTechnique
          modelDefinition
          preProcessingStatus
          features
          training
          windowsStep
          windowsSize
          lastProcessedData {
             sampleId
             t0
          }
          devices {
           token
           deviceId
         }
     }
   }
`;

export const GET_ML_MODEL_BY_ID_QUERY = gql`
   query getMlModelById($modelId: String!){
         getMlModelById(modelId: $modelId){
          _id
          name
          description
          frequency
          processingType
          learningTechnique
          modelDefinition
          preProcessingStatus
          features
          training
          windowsStep
          windowsSize
          lastProcessedData {
             sampleId
             t0
          }
          testing
          devices {
           token
           deviceId
         }
     }
   }
`;

export const DEL_ML_MODEL_MUTATION = gql`
   mutation removeMlModel($modelId: String!){
         removeMlModel(modelId:$modelId){
            ok
            n
      }
   }
`;

export const GET_SAMPLE_BY_DEVICE_TOKENS = gql`
   query getSamplesByDeviceToken($deviceTokenList: [String!], $type: Int!){
        getSamplesByDeviceToken(deviceTokenList:$deviceTokenList, type:$type){
            _id
            deviceId
            deviceToken
            label
            sampleName
            type
            modelId
            mlSampleId
            message {
               payload
               createdAt
            }
       }
   }
`;

export const GET_SAMPLE_BY_ML_SAMPLE_ID = gql`
   query getSamplesByMLSampleId($mlSampleId: String!){
        getSamplesByMLSampleId(mlSampleId:$mlSampleId){
            _id
            deviceId
            deviceToken
            label
            sampleName
            type
            modelId
            mlSampleId
            message {
               payload
               createdAt
            }
       }
   }
`;

export const GET_SAMPLES_BY_MODEL_ID = gql`
   query getSamplesByModelId($modelId: String!, $type: Int!){
        getSamplesByModelId(modelId:$modelId, type:$type){
            _id
            deviceToken
            label
            type
            sampleName
            mlSampleId
            message {
               payload
            }
       }
   }
`;

export const ADD_ML_SAMPLE_MUTATION = gql`
   mutation addMLSample($sample: MLSampleType!){
     addMLSample(sample:$sample){
        _id
       owner
       sensor
       type
       sampleName
       label
       length
       frequency
       device
       model
     }
   }
`;

export const GET_ML_SAMPLE_MUTATION = gql`
   query getMLSamplesById($id: String!){
        getMLSamplesById(id:$id){
           _id
          createdAt
          owner
          sensor
          type
          sampleName
          label
          length
          frequency
          device
          model
       }
   }
`;

export const GET_ML_SAMPLES_BY_MODEL = gql`
   query getMLSamplesByModelId($model: String!, $type: Int!){
        getMLSamplesByModelId(model:$model, type:$type){
           _id
          createdAt
          owner
          sensor
          type
          sampleName
          label
          length
          frequency
          device
          model
       }
   }
`;

export const DELETE_SAMPLES_BY_ID = gql`
   mutation deleteMLSampleById($mlSampleId: String!){
        deleteMLSampleById(mlSampleId:$mlSampleId){
          ok
          n
       }
   }
`;

// Machine Learning server
export const GET_ML_SERVER = gql`
   query getMLServer{
     getMLServer
   }
`;

export const PROCESSING_STATUS = gql`
   query processingStatus($taskId: String!, $logIndex: Int){
     processingStatus(taskId: $taskId, logIndex: $logIndex)
   }
`;

export const RUN_MODEL = gql`
   query runModel($modelDefinition: String!, $data: String!, $withLog: Boolean){
     runModel(modelDefinition: $modelDefinition, data: $data, withLog: $withLog)
   }
`;
