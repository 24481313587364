import { useState, useEffect, useMemo } from 'react';
import { useQuery, useMutation, useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';
import moment from 'moment';

import {
    GET_SIM_CARD_DETAIL,
    GET_SIM_CARD_HISTORY,
    GET_DATA_REPORTS,
    GET_COUNTRY_NAME_QUERY,
    SET_SIM_CARD_DETAIL,
    VODAFONE_CONSUMPTION_CHART_TYPES,
    notAvailableStatus,
} from 'Constants';
import { Loader, vodafoneSimConsumptionColumns, vodafoneSimLocationColumns, vodafoneSimSessionColumns } from 'Components';
import { getTimeDiff, showToastError, showToastInfo, showToastSuccess } from 'Utils';

import {
    getDeviceInformationItem,
    yesNoValue,
    formatBytes,
    getSimCardStateName,
    getSimUpdateStatusAndMessage,
} from './helpers';
import { SimCardWidgetsContainer } from '../widgetsContainer';

import * as Styled from './styled';

const simCardActiveMarker = 'Active.Live';

export const VodafoneSimDetails = ({ iccid }) => {
    const client = useApolloClient();

    const [countryName, setCountryName] = useState(notAvailableStatus);
    const [isToggling, setIsToggling] = useState(false);
    const [activeSim, setActiveSim] = useState(null);
    const [initialLoading, setInitialLoading] = useState(true);

    const { data, loading } = useQuery(GET_SIM_CARD_DETAIL, { variables: { iccid } });
    const { data: historyData, loading: historyLoading } = useQuery(GET_SIM_CARD_HISTORY, { variables: { iccid } });
    const { data: reportsData, loading: reportsLoading } = useQuery(GET_DATA_REPORTS, { variables: { iccid } });
    const [setSimCardDetailMutation] = useMutation(SET_SIM_CARD_DETAIL);

    const simCardDetails = data?.getSimCardDetail ?? {};
    const simCardHistory = historyData?.getSimCardHistory ?? [];
    const simCardReports = reportsData?.getDataReports ?? [];

    useEffect(
        () => {
            if (initialLoading && !loading && !reportsLoading) {
                setInitialLoading(false);
            }
        },
        [loading, reportsLoading],
    );

    const isSimActive = (simState) => getSimCardStateName(simState) === simCardActiveMarker;

    useEffect(() => {
        if (simCardDetails.state !== activeSim) {
            setActiveSim(isSimActive(simCardDetails.state));
        }
    }, [simCardDetails.state]);

    const saveSimCardState = async (state) => {
        const response = await setSimCardDetailMutation({
            variables: {
                simCardDetails: {
                    deviceId: iccid,
                    state,
                },
            },
            refetchQueries: [GET_SIM_CARD_DETAIL],
        });

        const { data: { setSimCardDetail } } = response;
        const { message, status } = getSimUpdateStatusAndMessage(setSimCardDetail);

        const notificationExecutor = status ? showToastSuccess : showToastError;

        notificationExecutor(message);
        return status;
    };

    const handleActivate = async (toggleValue) => {
        if (isToggling) {
            return showToastInfo('Request is processing');
        }

        setIsToggling(true);

        if (!toggleValue) {
            showToastError('You cannot change the state of the SIM to inactive');
        } else {
            const result = await saveSimCardState('A');
            if (result) {
                setActiveSim('A');
            }
        }
        setIsToggling(false);
    };

    const setFullCountryName = async (code) => {
        const { data: { getCountryNameByCode: receivedCountryName } } = await client.query({
            query: GET_COUNTRY_NAME_QUERY,
            variables: { code },
        });

        if (receivedCountryName) {
            setCountryName(receivedCountryName);
        }
    };

    const getDateValue = (value) => {
        const date = moment(value).format('DD MMMM YYYY - hh:mm A');
        return date !== 'Invalid Date' ? date : notAvailableStatus;
    };

    useEffect(() => {
        if (simCardDetails.baseCountry) {
            setFullCountryName(simCardDetails.baseCountry);
        }
    }, [simCardDetails.baseCountry]);

    const simInfo = useMemo(() => ([
        {
            label: 'SIM ID',
            value: simCardDetails.deviceId,
        },
        {
            label: 'Customer service profile',
            value: simCardDetails.customerServiceProfile,
        },
        {
            label: 'IMEI',
            value: simCardDetails.imei,
        },
        {
            label: 'last Detected IMEI',
            value: getDeviceInformationItem('lastDetectedImei', simCardDetails.deviceInformationList) || notAvailableStatus,
        },
        {
            label: 'APN',
            value: getDeviceInformationItem('apnName', simCardDetails.deviceInformationList) || notAvailableStatus,
        },
        {
            label: 'First Used On',
            value: getDateValue(getDeviceInformationItem('firstUsedTimestamp', simCardDetails.deviceInformationList)),
        },
        {
            label: 'Last Change On',
            value: getDateValue(getDeviceInformationItem('lastChangeTimestamp', simCardDetails.deviceInformationList)),
        },
        {
            label: 'Base Country',
            value: countryName,
        },
        {
            label: 'Has Been Active Test',
            value: `
                ${yesNoValue(getDeviceInformationItem('hasBeenActiveTest', simCardDetails.deviceInformationList))}
                -
                ${getDateValue(getDeviceInformationItem('startedActiveTest', simCardDetails.deviceInformationList))}`,
        },
        {
            label: 'Bundle',
            value: getDeviceInformationItem('targetCustomerServiceProfileName', simCardDetails.deviceInformationList) || notAvailableStatus,
        },
    ]), [simCardDetails, countryName]);

    let totalConsumptions = 0;
    const reports = useMemo(() => simCardReports.map((reportData) => {
        totalConsumptions += parseInt(reportData.Sum_Total_Kbytes, 10);

        return {
            _id: reportData._id,
            date: moment(new Date(reportData.Day)).format('D MMM YYYY'),
            download: formatBytes(reportData.Sum_Bytes_download),
            upload: formatBytes(reportData.Sum_Bytes_upload),
            total: formatBytes(reportData.Sum_Total_Kbytes, 1024),
        };
    }), [simCardReports]);

    const simHistoryItems = useMemo(() => simCardHistory.map((item) => ({
        _id: item._id,
        timestamp: moment(new Date(item.timestamp)).format('D MMM YYYY'),
        customerServiceProfileName: item.customerServiceProfile || notAvailableStatus,
        state: getSimCardStateName(item.state) || notAvailableStatus,
        imei: item.imei || notAvailableStatus,
        baseCountry: item.baseCountry || notAvailableStatus,
    })), [simCardHistory]);

    const activeSessionMarker = yesNoValue(getDeviceInformationItem('sessionActive', simCardDetails.deviceInformationList));
    const sessions = [{
        _id: 1,
        started: getTimeDiff(getDeviceInformationItem('sessionLastStartedTimestamp', simCardDetails.deviceInformationList)),
        terminated: getTimeDiff(getDeviceInformationItem('sessionLastTerminatedTimestamp', simCardDetails.deviceInformationList)),
        bytesIn: formatBytes(getDeviceInformationItem('sessionBytesIn', simCardDetails.deviceInformationList)),
        bytesOut: formatBytes(getDeviceInformationItem('sessionBytesOut', simCardDetails.deviceInformationList)),
        location: simCardDetails.geolocation?.address || notAvailableStatus,
        active: (
            <Styled.Badge $active={activeSessionMarker === 'Yes'}>
                {activeSessionMarker}
            </Styled.Badge>
        ),
    }];

    const processedGraphData = useMemo(() => {
        const reportArray = simCardReports.map(
            (report) => ({
                ...report,
                date: new Date(report.Day),
                Sum_Total_Kbytes: report.Sum_Total_Kbytes * 1024, // as it will be formatted as bytes
            }),
        ).sort((a, b) => a.date - b.date);

        if (!moment(new Date(reportArray[reportArray.length - 1]).date).isSame(new Date(), 'day')) {
            reportArray.push({
                _id: '_1',
                date: new Date(),
                Sum_Bytes_download: 0,
                Sum_Bytes_download_Test: 0,
                Sum_Bytes_upload: 0,
                Sum_Bytes_upload_Test: 0,
                Sum_Total_Kbytes: 0,
                Sum_Total_KbytesTest: 0,
            });
        }
        return reportArray;
    }, [simCardReports]);

    const tableData = getDeviceInformationItem('sessionLastStartedTimestamp', simCardDetails.deviceInformationList) ? sessions : [];
    const sessionInfo = { columns: vodafoneSimSessionColumns, tableData };

    const geoData = {
        lat: parseFloat(simCardDetails.geolocation?.location?.lat),
        lng: parseFloat(simCardDetails.geolocation?.location?.lng),
    };

    const tickFormatter = (value) => formatBytes(value);

    const simHistoryInfo = { columns: vodafoneSimLocationColumns, items: simHistoryItems, isLoading: historyLoading };
    const simReportsInfo = { columns: vodafoneSimConsumptionColumns, reports, total: formatBytes(totalConsumptions, 1024) };
    const consumptionChartInfo = { types: VODAFONE_CONSUMPTION_CHART_TYPES, graphData: processedGraphData, tickFormatter };

    if (initialLoading) {
        return <Loader />;
    }

    return (
        <SimCardWidgetsContainer
            iccid={iccid}
            simCardStateActive={activeSim}
            handleActivate={handleActivate}
            simInfo={simInfo}
            sessionInfo={sessionInfo}
            geoData={geoData}
            simHistoryInfo={simHistoryInfo}
            simReportsInfo={simReportsInfo}
            consumptionChartInfo={consumptionChartInfo}
        />
    );
};

VodafoneSimDetails.propTypes = {
    iccid: PropTypes.string.isRequired,
};
