import styled, { css } from 'styled-components';

export const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
`;

export const ModelMargined = styled.div`
    margin-top: 30px;
`;

export const ModelTabWrapper = styled.div`
    display: flex;
`;

export const ModelTabLeft = styled.div`
    width: 335px;
    padding: 20px 20px 45px 20px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 5px;
    background-color: white;
    button {
        width: 100%;
    }
`;

export const ModelTabRight = styled.div`
    flex: 1;
    padding-top: 10px;
    padding-left: 25px;
    position: relative;
`;

const itemLabelStyle = css`
    color: ${({ theme }) => theme.colors.title};
    font-size: 16px;
    font-weight: bold;
    line-height: 1.38;
`;

export const ItemLabelDiv = styled.div`
    ${itemLabelStyle}
`;

export const ItemLabel = styled.label`
    ${itemLabelStyle}
`;

export const ItemInput = styled.div`
    margin-bottom: 20px;

    &:last-of-type {
        margin-bottom: 30px;
    }
`;

export const SubInputs = styled.div`
    padding-left: 20px;
`;

export const InfoSection = styled.div`
    width: 293px;
    margin-bottom: 20px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 5px;
    padding: 10px;
    background-color: ${({ theme }) => theme.colors.boxBackground};
`;

export const ContentWrapper = styled.div`
    padding-top: 20px;
    
    a {
        color: ${({ theme }) => theme.colors.active};
        font-weight: bold;

        &:hover {
            color: ${({ theme }) => theme.colors.active};
        }
    }

    ${ModelTabWrapper}
    ${ModelTabLeft}
    ${ModelTabRight}
    ${ItemLabel}
    ${ItemInput}
    ${InfoSection}
`;

export const FormAlertSection = styled.div`
    width: 100%;
    margin-top: 20px;
`;

export const TooltipLabelWrapper = styled.span`
    display: inline-flex;
    align-items: center;
`;

export const TooltipLabel = styled.span`
    margin-right: 10px;
`;

export const InfoWrapper = styled.p`
    svg {
        transform: translateY(2px);
        margin-right: 5px;
    }
`;
