import styled from 'styled-components';

export const Signals = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
`;

export const SignalsHeader = styled.div`
    margin-top: 49px;

    & > h2 {
        font-size: 22px;
        color: ${({ theme }) => theme.colors.title};
    }

    & > p {
        font-size: 14px;
        font-weight: 400;
    }
`;

export const SignalsContent = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 49px;
    justify-content: center;
    align-items: center;

    & > button {
        margin-top: 49px;
    }
`;

export const SignalsList = styled.ul`
    margin-top: 19px;
    list-style: none;
    width: 615px;
`;

export const SignalsListItem = styled.li`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    padding: 10px;
    background-color: #fff;
    line-height: 24px;
    cursor: pointer;
    border-radius: 3px;
    box-shadow: 0 2px 5px 0 rgba(50, 50, 50, .2);

    & > span:first-child {
        font-size: 30px;
        padding: 0 0 0 20px;
    }

    & > div {
        border: 1px solid;
        margin-right: 30px;
        font-size: 16px;
        min-width: 250px;
        font-weight: normal;
        text-align: center;
    }

    & > div > div {
        display: flex;
        justify-content: space-around;

        & > span {
            word-break: break-word;
        }
    }

    & h2,
    & b {
        font-size: 16px;
        font-weight: normal;
    }

    & h2 {
        margin: 0;
        min-width: 150px;
        text-align: center;
    }

    & b {
        padding: 0 5px;
        min-width: 54px;
        border: 2px solid ${({ theme }) => theme.colors.title};
        border-top: none;
    }
`;

export const SignalsContainer = styled.div`
    padding-top: 27px;
    max-width: 950px;
`;

export const SignalsListEditor = styled.div`
    position: relative;
    height: fit-content;
    min-width: 300px;
    margin-top: 29px;
    margin-left: 50px;
    padding: 10px;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 2px 5px 0 rgba(50, 50, 50, .2);
    h3 {
        text-transform: uppercase;
    }
    ul {
        list-style: none;
        li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 10px;
            h4 {
                padding: 0 10px;
                border: 2px solid ${({ theme }) => theme.colors.title};
            }
        }
    }
`;

export const IconWrapper = styled.div`
    font-size: 30px;
    line-height: 0;
`;
