import { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    ComposedChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    ResponsiveContainer,
    Brush,
} from 'recharts';

import { Alert, Loader } from 'Components';

import { transformDataForMap } from '../../../chartHelpers';
import * as StyledGlobal from '../../../../styled';

import * as Styled from './styled';

export const Charts = ({ samples, selectedMlSample, windowSize, loading }) => {
    const chartData = useMemo(
        () => transformDataForMap(samples, selectedMlSample._id),
        [samples, selectedMlSample._id],
    );

    const brushEndIndex = useMemo(
        () => chartData.findIndex((item) => item.milliseconds > windowSize) - 1,
        [chartData, windowSize],
    );

    if (!chartData.length && !loading) {
        return (
            <StyledGlobal.FormAlertSection>
                <Alert
                    type="warning"
                    message={`The sample contains no data, it happens when it is an old sample.
                        Please make another sampling.`}
                />
            </StyledGlobal.FormAlertSection>
        );
    }

    return (
        <Styled.ChartWrapper>
            {loading && <Loader overlay />}
            <ResponsiveContainer
                width="100%"
                height={200}
                className="line-chart-responsive-container-wrapper"
            >
                <ComposedChart data={chartData} margin={{ bottom: 10 }}>
                    <CartesianGrid
                        strokeDasharray="4 3"
                        stroke="#ECF0F4"
                    />
                    <XAxis
                        dataKey="milliseconds"
                        type="number"
                        domain={['dataMin', 'dataMax']}
                        stroke="#ECF0F4"
                        tickLine={false}
                        tick={{ fill: '#C0C7CE', fontSize: 14 }}
                        scale="time"
                    />
                    <YAxis
                        stroke="#ECF0F4"
                        tickLine={false}
                        tick={{ fill: '#C0C7CE', fontSize: 14 }}
                    />
                    <Line
                        dataKey="xValue"
                        dot={null}
                        stroke="rgb(66,75,90)"
                    />
                    <Line
                        dataKey="yValue"
                        dot={null}
                        stroke="rgb(0,215,237)"
                    />
                    <Line
                        dataKey="zValue"
                        dot={null}
                        stroke="rgb(0,204,150)"
                    />
                    <Brush
                        height={3}
                        travellerWidth={1}
                        endIndex={brushEndIndex}
                        fill="currentColor"
                        stroke="currentColor"
                        fillOpacity="1 !important"
                        tickFormatter={() => { }}
                    />
                </ComposedChart>
            </ResponsiveContainer>
        </Styled.ChartWrapper>
    );
};

Charts.propTypes = {
    samples: PropTypes.array,
    selectedMlSample: PropTypes.object,
    windowSize: PropTypes.number,
    loading: PropTypes.bool,
};

Charts.defaultProps = {
    samples: [],
    selectedMlSample: {},
    windowSize: 0,
    loading: false,
};
