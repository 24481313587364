import PropTypes from 'prop-types';
import { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import { Warning, AccessKeysModal, Modal } from 'Components';
import { GET_APPLICATION_TOKEN, GENERATE_APPLICATION_KEYS } from 'Constants';

import * as Styled from './styled';

const warningMessage = 'Attention. By pressing Regenerate button you will delete the previous Keys pair and Generate the new one';
const title = 'I understand that old keys pair will be deleted';

export const AdvancedSettings = ({ project }) => {
    const [warningConfirmed, setWarningConfirmed] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalError, setModalError] = useState(null);
    const [keys, setKeys] = useState({});

    const { data: accessToken } = useQuery(GET_APPLICATION_TOKEN, { variables: { id: project._id } });
    const [generateAccessKeys, { loading: isLoading }] = useMutation(GENERATE_APPLICATION_KEYS, {
        refetchQueries: [GET_APPLICATION_TOKEN],
        awaitRefetchQueries: true,
    });

    const token = accessToken?.getApplicationToken?.accessId;
    const buttonTitle = token ? 'Regenerate' : 'Generate';

    const onConfirmWarning = () => {
        setWarningConfirmed(!warningConfirmed);
    };

    const handleKeysGeneration = async () => {
        try {
            await generateAccessKeys({
                variables: { applicationId: project._id },
                update: (cache, results) => {
                    const { data: { generateApplicationKeys: accessKeys } } = results;
                    setKeys(accessKeys);
                },
            });
        } catch (error) {
            setModalError(error.message);
        } finally {
            setModalOpen(true);
        }
    };

    const onModalClose = () => {
        setWarningConfirmed(false);
        setModalOpen(false);
    };
    
    return (
        <>
            {token && (
                <Warning
                    warn={warningMessage}
                    checked={warningConfirmed}
                    handleCheckbox={onConfirmWarning}
                    title={title}
                />
            )}
            <span>Token</span>
            <Styled.Wrapper>
                <Styled.TokenWrapper>
                    <Styled.TokenField>
                        {token}
                    </Styled.TokenField>
                    <Styled.GenerateBtn
                        disabled={token && !warningConfirmed}
                        onClick={handleKeysGeneration}
                        loading={isLoading}
                    >
                        {buttonTitle}
                    </Styled.GenerateBtn>
                </Styled.TokenWrapper>
                <Styled.InfoBlockWrapper>
                    <Styled.InfoIcon />
                    <Styled.InfoMessage>
                        You can get access to your project from Cloud<br />
                        Platforms by using AccessID and Secret Key.
                    </Styled.InfoMessage>
                </Styled.InfoBlockWrapper>
            </Styled.Wrapper>
            <Modal isOpened={modalOpen} handleClose={onModalClose}>
                <AccessKeysModal
                    keys={keys}
                    error={modalError}
                    source="project"
                    onClose={onModalClose}
                />
            </Modal>
        </>
    );
};

AdvancedSettings.propTypes = {
    project: PropTypes.object.isRequired,
};
