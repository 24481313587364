import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import { PINS_BY_DEVICE_QUERY, GET_DEVICE_LAST_MESSAGES } from 'Constants';
import { useMessageSubscription } from 'Hooks';
import { Loader } from 'Components';

import { SignalsTable, NoSignals } from './components';

import * as Styled from './styled';

export const Signals = ({ deviceToken }) => {
    const [initialLoading, setInitialLoading] = useState(true);

    const { data: pinsData, loading: pinsLoading, refetch: refetchPins } = useQuery(
        PINS_BY_DEVICE_QUERY,
        { variables: { deviceToken } },
    );
    const pins = pinsData?.getPinsByDevice ?? [];

    const { data: lastMessagesData, loading: lastMessagesLoading, refetch: refetchLastMessages } = useQuery(
        GET_DEVICE_LAST_MESSAGES,
        { variables: { deviceToken } },
    );
    const lastMessages = lastMessagesData?.getDeviceLastMessages ?? [];

    const refetchQueries = () => {
        refetchPins();
        refetchLastMessages();
    };

    useMessageSubscription([deviceToken], null, refetchQueries);

    useEffect(
        () => {
            if (initialLoading && !pinsLoading && !lastMessagesLoading) {
                setInitialLoading(false);
            }
        },
        [pinsLoading, lastMessagesLoading],
    );

    if (initialLoading) {
        return <Loader />;
    }

    return (
        <Styled.Signals>
            {!!pins.length && (
                <SignalsTable
                    pins={pins}
                    lastMessages={lastMessages}
                    deviceToken={deviceToken}
                />
            )}
            {!pins.length && (
                <NoSignals />
            )}
        </Styled.Signals>
    );
};

Signals.propTypes = {
    deviceToken: PropTypes.string.isRequired,
};
