import * as Yup from 'yup';

export const NetworksTitleForLabel = {
    wifi: 'WiFi',
    lora: 'LoRa',
    lte: 'LTE',
};

export const TitlesOfNetworks = {
    wifi: 'ssid',
    lora: 'loraServer',
    lte: 'name',
};

export const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required.'),
    description: Yup.string().required('Description is required.'),
});
