import styled from 'styled-components';

import { Button } from '../../../buttons';

export const EndpointLabel = styled.div`
    font-size: 16px;
    font-weight: bold;
`;

export const EndpointValue = styled.div`
    font-size: 14px;
    margin-top: 5px;
`;

export const FormContent = styled.div`
    margin-top: 30px;
    max-width: 500px;
`;

export const FormItem = styled.div`
    display: flex;
    margin-bottom: 10px;

    & > span {
        color: ${({ theme }) => theme.colors.title};
        padding-top: 8px;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.38;
    }

    & > div {
        flex: 1;
    }

    input[type=text],
    select {
        width: 100%;
    }
`;

export const ItemLabel = styled.span`
    width: 180px;
    margin-right: 15px;
`;

export const CheckboxesWrapper = styled.div`
    flex-direction: column;

    & > * {
        margin-bottom: 10px;
    }
`;

export const ConnectionLinesWrapper = styled.div`
    margin-top: 20px;
`;

export const ConnectionLine = styled.div`
    display: flex;
    align-items: center;
`;

export const ConnectionLabel = styled.div`
    margin-right: 10px;
`;

export const ConnectionValue = styled.div`
    display: inline-block;
    padding: 0 5px;
    color: ${({ theme }) => theme.colors.active};
    background-color: ${({ theme }) => theme.colors.boxBackground};
`;

export const ButtonsWrapper = styled.div`
    margin-top: 45px;
    display: flex;
`;

export const ButtonBack = styled(Button)`
    margin-right: 20px;
`;

export const ButtonSubmit = styled(Button)`
    width: 190px;
    max-width: 100%;
`;
