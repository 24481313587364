import styled, { css } from 'styled-components';

export const FormContent = styled.div`
    display: grid;
    grid-template-columns: max-content max-content;
    gap: 15px 120px;
`;

export const Title = styled.div`
    color: ${({ theme }) => theme.colors.title};
    margin-top: 30px;
    margin-bottom: 35px;
    font-size: 22px;
    font-weight: bold;
`;

const itemLabelStyles = css`
    color: ${({ theme }) => theme.colors.title};
    font-size: 16px;
    font-weight: bold;
    line-height: 1.38;
`;

export const ItemLabel = styled.div`
    display: flex;
    align-items: center;
    
    ${itemLabelStyles}

    svg {
        margin-left: 5px;
    }
`;

export const InputSelect = styled.div``;

export const Fieldset = styled.fieldset`
    padding: 10px 65px 20px 10px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 5px;

    ${ItemLabel},
    ${InputSelect} {
        margin-left: 20px;
    }
`;

export const Legend = styled.legend`
    ${itemLabelStyles}
    width: auto;
    padding: 5px;
`;

export const NotificationWrapperBottom = styled.div`
    display: flex;
    margin-top: 40px;
`;

export const NotificationWrapperTop = styled.div`
    margin-bottom: 35px;
`;

export const NotificationIcon = styled.div`
    margin-right: 14px;
    font-size: 20px;
    color: ${({ theme }) => theme.colors.title};
    line-height: 0;
`;

export const NotificationLabel = styled.div`
    color: ${({ theme }) => theme.colors.text};
    font-size: 16px;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    margin-top: 55px;

    
    & > * {
        margin-right: 30px;
    }
`;

export const DevicesTableWrapper = styled.div`
    max-width: 615px;
`;
