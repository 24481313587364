import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSubscription } from '@apollo/client';
import isEmpty from 'lodash/isEmpty';

import { SIGFOX_REGISTRATION_STATUS_SUBSCRIPTION } from 'Constants';

import * as Styled from './styled';

export const SigfoxCustom = ({ device }) => {
    const { data } = useSubscription(
        SIGFOX_REGISTRATION_STATUS_SUBSCRIPTION,
        { variables: { deviceToken: device.token } },
    );
    const registrationData = data?.sigfoxRegistrationStatus__api__ ?? {};

    const registrationDotData = useMemo(
        () => {
            const {
                sigfoxRegistrationStatus: {
                    jobDone: initialJobDone,
                    status: initialStatus,
                },
                sigfoxInfo: initialSigfoxInfo,
            } = device;
            const {
                jobDone: subscriptionJobDone,
                sigfoxInfo: subscriptionSigfoxInfo,
                status: subscriptionStatus,
            } = registrationData;

            const jobDone = subscriptionJobDone ?? initialJobDone;
            const sigfoxInfo = initialSigfoxInfo ?? subscriptionSigfoxInfo;
            const { errors, success } = initialStatus ?? subscriptionStatus;

            let status = null;
            let text = 'This device is not registered to Sigfox backend';

            if (jobDone !== null) {
                if (jobDone) {
                    if (success === 1) {
                        status = 'success';
                        text = 'This device is registered to Sigfox backend';
                    } else {
                        status = 'error';
                        text = !isEmpty(errors) && errors[0].includes('Invalid PAC code')
                            ? `Error PAC code ${sigfoxInfo.pac} for Sigfox ID ${sigfoxInfo.id} is invalid`
                            : errors[0];
                    }
                } else {
                    status = 'warning';
                    text = 'Sigfox backend is registering this device';
                }
            }

            return { status, text };
        },
        [device.sigfoxRegistrationStatus, registrationData],
    );

    return (
        <>
            <Styled.Title>
                Sigfox Registration
            </Styled.Title>
            <Styled.SubTitle>
                Device added added using Sigfox Custom contract
            </Styled.SubTitle>
            <Styled.DotWrapper>
                <Styled.Dot $status={registrationDotData.status} />
                <Styled.DotLabel>
                    {registrationDotData.text}
                </Styled.DotLabel>
            </Styled.DotWrapper>
            <Styled.ButtonsWrapper>
                <Styled.DocumentationLink
                    href={process.env.REACT_APP_LINK_DOCS_SIGFOX}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Go to sigfox documentation
                </Styled.DocumentationLink>
            </Styled.ButtonsWrapper>
        </>
    );
};

SigfoxCustom.propTypes = {
    device: PropTypes.object.isRequired,
};
