import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { register } from 'Api';
import { showToastError, showToastInfo, showToastSuccess } from 'Utils';
import { ROUTES } from 'Constants';

import * as RootStyled from '../styled';

import { FormContent } from './formContent';
import { initialValues, validationSchema } from './config';

export const RegistrationPage = () => {
    const history = useHistory();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const redirectToLogin = () => history.push(ROUTES.auth.login);

    const onRegister = async (values) => {
        let result;

        const fullName = `${values.firstName} ${values.lastName}`;

        try {
            if (process.env.REACT_APP_PYCOM_ENABLE_RECAPTCHA === 'true') {
                const recaptchaResult = await executeRecaptcha('registration');
                result = await register(values.email, values.password, fullName, recaptchaResult);
            } else {
                result = await register(values.email, values.password, fullName);
            }

            if (!result.errorMessage) {
                showToastSuccess('Successfully registered');
                showToastInfo('Check your email to complete registration');
                redirectToLogin();
            } else {
                showToastError('Registration has failed');
            }
        } catch (error) {
            showToastError('System error');
        }
    };

    return (
        <>
            <RootStyled.Title>
                Create a new account
            </RootStyled.Title>
            <Formik
                onSubmit={onRegister}
                initialValues={initialValues}
                validationSchema={validationSchema}
            >
                {(props) => <FormContent {...props} handleRedirectToLogin={redirectToLogin} />}
            </Formik>
        </>
    );
};
