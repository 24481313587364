import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { GET_DEVICE_LAST_MESSAGE } from 'Constants';
import { getDataBatteryCount } from 'Utils';
import { useMessageSubscription } from 'Hooks';

import { BatteryLevelWidget } from '../../../../widgets';

export const BatteryLevel = ({ chartId, title, deviceToken, pin }) => {
    const { data, loading, refetch } = useQuery(
        GET_DEVICE_LAST_MESSAGE,
        {
            variables: { chartId },
        },
    );
    const lastMessage = data?.getDeviceLastMessage;

    useMessageSubscription([deviceToken], pin, refetch);

    const widgetData = useMemo(
        () => (lastMessage?.payload
            ? getDataBatteryCount(lastMessage.payload)
            : {}),
        [lastMessage],
    );

    return (
        <BatteryLevelWidget
            title={title}
            data={widgetData}
            isEmpty={loading || isEmpty(widgetData)}
        />
    );
};

BatteryLevel.propTypes = {
    chartId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    deviceToken: PropTypes.string.isRequired,
    pin: PropTypes.number.isRequired,
};
