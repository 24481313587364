import styled from 'styled-components';

export const Wrapper = styled.div`
    box-shadow: 0 2px 5px 0 rgba(50, 50, 50, 0.2);
    padding: 20px 15px;
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ChartTitle = styled.h3`
    color: ${({ theme }) => theme.colors.title};
    font-size: 23px;
    font-weight: bold;
    opacity: 0.89;
    text-transform: uppercase;
`;

export const ContentWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 5px 0 rgba(50, 50, 50, 0.2);
    padding: 20px 15px;
    width: 100%;
    height: 100%;
    margin-top: 20px;
`;

export const EmptyChart = styled.div`
    vertical-align: middle;
    border: 1px solid lightgrey;
    text-align: center;
    width: 50%;
    padding: 10px 5px;
`;
