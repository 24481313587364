import { useMemo, useEffect } from 'react';
import { Form, Field } from 'formik';
import { Collapse } from 'antd';
import PropTypes from 'prop-types';

import { Select, Button } from 'Components';

import { CreateRegistry } from '../createRegistry';
import * as Styled from './styled';

const { Panel } = Collapse;

export const FormContent = ({
    googleService,
    addRegistrySuccess,
    registries,
    values,
    setFieldValue,
    isValid,
    prevStep,
    dirty,
}) => {
    useEffect(() => {
        if (values.topic && dirty) {
            setFieldValue('topic', '');
        }
    }, [values.registry]);

    const registryOptions = useMemo(
        () => registries.map((item) => ({
                value: item.id,
                label: item.name.substring(item.name.lastIndexOf('/') + 1),
            })),
        [registries],
    );

    const topicOptions = useMemo(
        () => {
            const selectedRegistry = registries.find((item) => item.id === values.registry);

            if (!selectedRegistry) {
                return [];
            }

            return selectedRegistry.eventNotificationConfigs.map((item) => {
                const { pubsubTopicName, subfolderMatches } = item;
                const fullName = subfolderMatches
                    ? `${pubsubTopicName}/${subfolderMatches}`
                    : pubsubTopicName;

                return { value: fullName, label: fullName };
            });
        },
        [values.registry],
    );

    const collapseCreateRegistryHeader = (
        <Styled.CollapseHeader>
            Create a new registry
        </Styled.CollapseHeader>
    );

    return (
        <Form>
            <Styled.Title>
                Project Details
            </Styled.Title>
            <Styled.FormContent>    
                <Styled.FormItem>
                    <Styled.ItemLabel>
                        Registry
                    </Styled.ItemLabel>
                    <Field
                        name="registry"
                        component={Select}
                        values={registryOptions}
                        placeholder="Select a registry"
                    />
                </Styled.FormItem>
                <Styled.FormItem>
                    <Styled.ItemLabel>
                        Default telemetry topic
                    </Styled.ItemLabel>
                    <Field
                        name="topic"
                        component={Select}
                        values={topicOptions}
                        disabled={!values.registry}
                        placeholder="Select a registry"
                    />
                </Styled.FormItem>
                <Styled.Collapse ghost>
                    <Panel
                        header={collapseCreateRegistryHeader}
                        showArrow={false}
                        key="1"
                    >
                        <CreateRegistry
                            googleService={googleService}
                            addRegistrySuccess={addRegistrySuccess}
                        />
                    </Panel>
                </Styled.Collapse>
                <Styled.ButtonsWrapper>
                    <Button
                        buttonType="transparent"
                        onClick={prevStep}
                    >
                        Back
                    </Button>
                    <Styled.ButtonSubmit
                        type="submit"
                        disabled={!isValid}
                    >
                        Next
                    </Styled.ButtonSubmit>
                </Styled.ButtonsWrapper>
            </Styled.FormContent>
        </Form>
    );
};

FormContent.propTypes = {
    googleService: PropTypes.object.isRequired,
    addRegistrySuccess: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    isValid: PropTypes.bool.isRequired,
    registries: PropTypes.array,
    prevStep: PropTypes.func.isRequired,
    dirty: PropTypes.bool.isRequired,
};

FormContent.defaultProps = {
    registries: [],
};
