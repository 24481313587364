import gql from 'graphql-tag';

export const GET_ACCOUNT_ACCESS_ID = gql`
    query getAccountAccessToken {
        getAccountAccessToken {
            accessId
        }
    }
`;

export const GENERATE_ACCOUNT_ACCESS_KEYS = gql`
    mutation generateAccountAccessKeys {
        generateAccountAccessKeys {
           accessId,
           secret,
       }
    }
`;
