import PropTypes from 'prop-types';
import { useState, useEffect, useContext } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { ThemeContext } from 'styled-components';

import { 
    FIRMWARE_QUERY,
    GET_USER_PROFILE_QUERY,
    GENERATE_DEVICE_CERTIFICATE,
    GET_DEVICE_CERTIFICATE_BY_ID,
    REVOKE_DEVICE_CERTIFICATE,
    CERTIFICATE_STATUS,
    PUBLISH_DEVICE_CERTIFICATES_MUTATION,
} from 'Constants';
import {
    getDeviceCertificateTagColor,
    getDeviceCertificateTagTitle,
    showToastSuccess,
} from 'Utils';
import { 
    Collapsible, 
    CollapsibleTag,
} from 'Components';

import * as Styled from './styled';
import { 
    DeviceSettingsForm, 
    DeviceNetworksForm, 
    DeviceDeleteForm, 
    DeviceFirmware, 
    DeviceExpansionBoard,
    DeviceCertificate,
} from './components';

import { 
    FIRMWARE_STATUS, 
    getDeviceFirmwareStatus,
} from './config';

export const DeviceConfiguration = ({ device }) => {
    const styledTheme = useContext(ThemeContext);
    const { data: userData } = useQuery(GET_USER_PROFILE_QUERY);
    const user = userData?.getUserProfile;
    const { data: firmwareQueryData, loading: firmwareLoading } = useQuery(FIRMWARE_QUERY);
    const firmwareLatestVersion = firmwareQueryData?.getLatestFirmware;
    const [firmwareStatus, setFirmwareStatus] = useState({ text: '', type: '' });
    const [currentFirmwareVersion, setCurrentFirmwareVersion] = useState(device.firmware);
    
    const { data: deviceCertificateData }
        = useQuery(GET_DEVICE_CERTIFICATE_BY_ID, { variables: { deviceId: device._id } });
    const deviceCertificate = deviceCertificateData?.getCertificateByDeviceId ?? {};

    const [generateCertificate, { loading: isLoadingCertificate }] = useMutation(GENERATE_DEVICE_CERTIFICATE);
    const [deployCertificateChanges, { loading: isLoading }] = useMutation(PUBLISH_DEVICE_CERTIFICATES_MUTATION);
    const onClickGenerateCertificate = () => {
        generateCertificate({
            variables: {
                input: {
                    owner: user.owner,
                    deviceId: device._id,
                },
            },
            update: () => {
                showToastSuccess('Certificate generated successfully');
                deployCertificateChanges({
                    variables: {
                        deviceToken: device.token,
                    },
                    update: () => {
                        showToastSuccess('Deploy request sent successfully');
                    },
                });
            },
            refetchQueries: [GET_DEVICE_CERTIFICATE_BY_ID],
        });
    };

    const [revokeCertificate] = useMutation(REVOKE_DEVICE_CERTIFICATE);
    const onClickRevokeCertificate = () => {
        revokeCertificate({
            variables: {
                input: {
                    deviceId: device._id,
                    fingerprint: deviceCertificate.fingerprint,
                },
            },
            refetchQueries: [GET_DEVICE_CERTIFICATE_BY_ID],
        });
    };
    
    const updateFirmwareStatus = (status) => {
        let type = '';
        switch (status) {
            case FIRMWARE_STATUS.NO_INFORMATION:
                type = 'warning';
                break;
            case FIRMWARE_STATUS.UPDATING:
                type = 'info';
                break;
            case FIRMWARE_STATUS.UP_TO_DATE:
                type = 'success';
                break;
            case FIRMWARE_STATUS.UPDATE_AVAILABLE:
                type = 'error';
                break;
            default:
                break;
        }

        if (type) {
            setFirmwareStatus({ text: status, type });
        }
    };

    useEffect(() => {
        updateFirmwareStatus(getDeviceFirmwareStatus(currentFirmwareVersion, firmwareLatestVersion));
    }, [device, firmwareLatestVersion]);

    if (firmwareLoading) {
        return null;
    }

    const updateCurrentFirmwareVersion = (version) => {
        setCurrentFirmwareVersion(version);
    };

    const collapsibleLabels = [
        {
            title: 'Device settings',
            description: 'Manage your device name on the one that suitable for you most',
        },
        {
            title: 'Expansion Board',
            description: 'By changing the expansion board you will change the functionality of your device depends on the sensors and '
                + 'trackers that are placed on the new expansion board.',
        },
        {
            title: 'Networks',
            description: 'Setup and change your connectivity settings',
        },
        {
            title: (
                <span>Device Certificate
                    <CollapsibleTag
                        title={getDeviceCertificateTagTitle(deviceCertificate)}
                        subTitle={deviceCertificate?.status === CERTIFICATE_STATUS.SECURED ? deviceCertificate.fingerprint : ''}
                        type={getDeviceCertificateTagColor(deviceCertificate?.status)}
                    />
                </span>
            ),
            description: 'The device security can be assured with a Certificate which Pybytes can automatically generate here.',
        },
        {
            title: (
                <span>OTA firmware {currentFirmwareVersion.version}
                    <CollapsibleTag
                        title={firmwareStatus.text}
                        type={firmwareStatus.type}
                    />
                </span>
            ),
            description: 'Update your firmware Over the Air',
        },
        {
            title: 'Remove device',
            description: `Here you can delete your device from your ${styledTheme.whiteLabelCompany} account`,
        },
    ];

    return (
        <Styled.Wrapper
            data-cy="device-configuration-collapsible-items"
        >   
            <Collapsible labels={collapsibleLabels} forceRenderIndexes={[2]}>
                <DeviceSettingsForm device={device} />
                <DeviceExpansionBoard device={device} />
                <DeviceNetworksForm device={device} />
                <DeviceCertificate
                    onClickGenerate={onClickGenerateCertificate}
                    onClickRevoke={onClickRevokeCertificate}
                    certificate={deviceCertificate}
                    isLoadingCertificate={isLoadingCertificate || isLoading}
                />
                <DeviceFirmware 
                    device={device} 
                    updateFirmwareStatus={updateFirmwareStatus} 
                    updateCurrentFirmwareVersion={updateCurrentFirmwareVersion} 
                    currentFirmwareVersion={currentFirmwareVersion}
                />
                <DeviceDeleteForm device={device} />
            </Collapsible>
        </Styled.Wrapper>
    );
};

DeviceConfiguration.propTypes = {
    device: PropTypes.object,
};

DeviceConfiguration.defaultProps = {
    device: {},
};
