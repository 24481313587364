import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import { Modal, DeleteModal } from 'Components';
import { showToastSuccess } from 'Utils';
import {
    GET_DEVICES_QUERY,
    GET_DEVICES_STANDALONE,
    GET_APPLICATIONS,
    DELETE_DEVICES_MUTATION,
} from 'Constants';

export const DeleteDevicesModal = ({ isOpen, handleClose, selectedIds }) => {
    const [deleteDevices, { loading: isLoading }] = useMutation(
        DELETE_DEVICES_MUTATION,
        {
            refetchQueries: [
                GET_DEVICES_STANDALONE,
                GET_DEVICES_QUERY,
                GET_APPLICATIONS,
            ],
        },
    );

    const onDelete = () => {
        deleteDevices({
            variables: { idList: selectedIds },
            update: () => {
                showToastSuccess('Device(s) deleted successfully');
                handleClose();
            },
        });
    };

    return (
        <Modal
            isOpened={isOpen}
            handleClose={handleClose}
        >
            <DeleteModal
                title="Delete Device(s)"
                description="Are you sure you want delete this device(s)?"
                cancelAction={handleClose}
                deleteAction={onDelete}
                isLoading={isLoading}
            />
        </Modal>
    );
};

DeleteDevicesModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    selectedIds: PropTypes.arrayOf(PropTypes.string),
};

DeleteDevicesModal.defaultProps = {
    selectedIds: [],
};
