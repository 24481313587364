import { useEffect, useState } from 'react';
import { useParams, useRouteMatch, Switch } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import {
    ROUTES,
    BREADCRUMBS_LABELS,
    GET_ML_MODEL_BY_ID_QUERY,
    GET_DEVICES_BY_TOKEN,
} from 'Constants';
import { Breadcrumbs, Menu, PageHeader, RouteWrapper, Loader } from 'Components';

import {
    DataAcquisition,
    Processing,
    FeatureAnalysis,
    Training,
    Testing,
    Deployment,
    Configuration,
} from './components';
import * as Styled from './styled';

const getLinks = (basePath) => {
    const { machineLearning: { modelDetails } } = ROUTES;
    return [
        { title: 'Data Acquisition', route: `${basePath}${modelDetails.dataAcquisition}` },
        { title: 'Processing', route: `${basePath}${modelDetails.processing}` },
        { title: 'Feature Analysis', route: `${basePath}${modelDetails.featureAnalysis}` },
        { title: 'Training', route: `${basePath}${modelDetails.training}` },
        { title: 'Testing', route: `${basePath}${modelDetails.testing}` },
        { title: 'Deployment', route: `${basePath}${modelDetails.deployment}` },
        { title: 'Configuration', route: `${basePath}${modelDetails.configuration}` },
    ];
};

export const ModelDetails = () => {
    const { modelId } = useParams();
    const { path, url } = useRouteMatch();

    const [initialLoading, setInitialLoading] = useState(true);

    const { data: modelData, loading: modelLoading } = useQuery(GET_ML_MODEL_BY_ID_QUERY, { variables: { modelId } });
    const model = modelData?.getMlModelById;

    const { data: devicesData, loading: devicesLoading } = useQuery(
        GET_DEVICES_BY_TOKEN,
        {
            variables: { tokens: model?.devices.map((device) => device.token) },
            skip: !model,
        },
    );
    const devices = devicesData?.getDevicesByTokens ?? [];

    useEffect(
        () => {
            if (initialLoading && !modelLoading && !devicesLoading) {
                setInitialLoading(false);
            }
        },
        [modelLoading, devicesLoading],
    );

    if (initialLoading) {
        return <Loader />;
    }

    if (!model) {
        return 'Machine learning model does not exist';
    }

    return (
        <>
            <Breadcrumbs
                labels={BREADCRUMBS_LABELS.machineLearning}
                entityName={model.name}
            />
            <Styled.HeaderWrapper>
                <PageHeader title={model.name} withIcon={false} />
            </Styled.HeaderWrapper>
            <Menu links={getLinks(url)}>
                <Styled.ContentWrapper>
                    <Switch>                    
                        <RouteWrapper
                            path={`${path}${ROUTES.machineLearning.modelDetails.dataAcquisition}`}
                            render={() => <DataAcquisition model={model} devices={devices} />}
                        />
                        <RouteWrapper
                            path={`${path}${ROUTES.machineLearning.modelDetails.processing}`}
                            render={() => <Processing model={model} />}
                        />
                        <RouteWrapper
                            path={`${path}${ROUTES.machineLearning.modelDetails.featureAnalysis}`}
                            render={() => <FeatureAnalysis model={model} />}
                        />
                        <RouteWrapper
                            path={`${path}${ROUTES.machineLearning.modelDetails.training}`}
                            render={() => <Training model={model} />}
                        />
                        <RouteWrapper
                            path={`${path}${ROUTES.machineLearning.modelDetails.testing}`}
                            render={() => <Testing model={model} devices={devices} />}
                        />
                        <RouteWrapper
                            path={`${path}${ROUTES.machineLearning.modelDetails.deployment}`}
                            render={() => <Deployment model={model} devices={devices} />}
                        />
                        <RouteWrapper
                            path={`${path}${ROUTES.machineLearning.modelDetails.configuration}`}
                            render={() => <Configuration model={model} />}
                        />
                    </Switch>
                </Styled.ContentWrapper>
            </Menu>
        </>
    );
};
