export const SPEEDS_RATES = [57600, 115200, 230400, 921600];
export const FIRMWARE_TYPES = ['Pybytes', 'Pygate', 'Development', 'Custom'];
export const FILESYSTEM_TYPES = ['LittleFS', 'FatFS'];
export const FIRMWARE_TYPES_VERSIONS = ['Pybytes', 'Pygate', 'Development'];
export const FILESYSTEM_TYPES_OBJECT = {
    FATFS: 'FatFS',
    LFS: 'LittleFS',
};

export const firmwareTypesOptions = FIRMWARE_TYPES.map((item) => ({
    label: item,
    value: item,
}));

export const fileSystemOptions = FILESYSTEM_TYPES.map((item) => ({
    label: item,
    value: item,
}));

export const speedRatesOptions = SPEEDS_RATES.map((item) => ({
    label: item,
    value: item,
}));
