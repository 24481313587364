import styled from 'styled-components';

export const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Header = styled.div`
    width: 100%;
    height: 50px;
    padding: 15px 20px;
    background-color: ${({ theme }) => theme.colors.title};
    color: #fff;
    font-size: 16px;
    font-weight: bold;
`;

export const FormContent = styled.div`
    padding: 10px;
`;

export const FormItem = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 15px;
    width: 60%;

    div:first-child {
        width: 100px;
    }
`;

export const FormTitle = styled.div`
    color: ${({ theme }) => theme.colors.title};
    font-size: 16px;
    font-weight: bold;
`;

export const FormButton = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 10px;
    width: 100%;

    button:first-child {
        margin-right: 10px;
    }
`;

export const Checkboxes = styled.div`
    display: flex;
    flex-direction: column;

    div:first-child {
        margin-bottom: 10px;
    }
`;

export const TableWrapper = styled.div`
    width: 90%;
    margin: 0 auto;
    padding: 30px 30px 50px;
`;

export const Table = styled.div`
    margin-top: 25px;
    padding: 3px;
`;

export const Title = styled.h1`
    text-align: center;
    padding-top: 30px;
    font-weight: 700;
`;

export const Button = styled.div`
    position: absolute;
    right: 70px;
    bottom: 60px;
`;
