import styled from 'styled-components';

export const Wrapper = styled.div`
    margin-top: 15px;

    button {
        margin-bottom: 20px;
        text-transform: uppercase;
    }
`;
