import styled from 'styled-components';

import { hexToRgb } from 'Utils';

export const Wrapper = styled.div`
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    right: 0;
    z-index: 10;
`;

export const Menu = styled.ul`
    position: fixed;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 3px;
    background-color: #fff;
    z-index: 15;
`;

export const MenuElement = styled.li`
    padding: 0;
    margin: 0;
    white-space: pre-wrap;

    border-bottom: 1px solid ${({ theme }) => theme.colors.border};

    &:nth-last-child() {
        border-bottom: none;
    }

    &:hover {
        background-color: rgba(${({ theme }) => hexToRgb(theme.colors.border)}, 0.4);
    }

    button {
        padding: 10px 15px 5px;
        text-align: start;
        width: 100%;
        height: 100%;
        background-color: transparent;
    }
`;
