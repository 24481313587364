import { Field, Form } from 'formik';
import PropTypes from 'prop-types';

import { TextInput, Checkbox } from '../../../inputs';
import { AlertBordered } from '../../../alertBordered';

import * as Styled from './styled';

export const FormContent = ({
    values,
    allDevices,
    onCancel,
    submitText,
    alreadyUsedDevices,
    editMode,
    isLoading,
}) => {
    const getIsDeviceDisabled = (token) => {
        if (editMode) {
            return false;
        }
        
        return alreadyUsedDevices.includes(token);
    };

    return (
        <Form>
            <Styled.FormContent>    
                <Styled.FormItem>
                    <Styled.ItemLabel>
                        AWS Thing Group Name
                    </Styled.ItemLabel>
                    <Field
                        name="thingGroupName"
                        component={TextInput}
                        disabled={editMode}
                    />
                </Styled.FormItem>
                <Styled.FormItem>
                    <Styled.ItemLabel>
                        Messages Topic
                    </Styled.ItemLabel>
                    <Field
                        name="messagesTopic"
                        component={TextInput}
                    />
                </Styled.FormItem>
                <Styled.FormItem $checkboxesWrapper>
                    <Styled.ItemLabel>
                        Devices
                    </Styled.ItemLabel>
                    {!!alreadyUsedDevices.length && !editMode && (
                        <AlertBordered
                            type="warning"
                            title="Warning"
                            message={`Another AWS integration uses the same AWS Endpoint Address.
                                You won't be able to select the devices already connected that AWS Hub and Region.`}
                        />
                    )}
                    {values.devices.map((_, index) => (
                        <Field
                            // eslint-disable-next-line react/no-array-index-key
                            key={index}
                            name={`devices.${index}`}
                            component={Checkbox}
                            title={allDevices[index].description}
                            disabled={getIsDeviceDisabled(allDevices[index].token)}
                        />
                    ))}
                </Styled.FormItem>
                <Styled.ButtonsWrapper>
                    {onCancel && (
                        <Styled.ButtonBack
                            buttonType="transparent"
                            onClick={onCancel}
                        >
                            Cancel
                        </Styled.ButtonBack>
                    )}
                    <Styled.ButtonSubmit type="submit" loading={isLoading}>
                        {submitText}
                    </Styled.ButtonSubmit>
                </Styled.ButtonsWrapper>
            </Styled.FormContent>
        </Form>
    );
};

FormContent.propTypes = {
    values: PropTypes.object.isRequired,
    allDevices: PropTypes.arrayOf(PropTypes.object).isRequired,
    onCancel: PropTypes.func,
    submitText: PropTypes.string.isRequired,
    alreadyUsedDevices: PropTypes.array.isRequired,
    editMode: PropTypes.bool,
    isLoading: PropTypes.bool,
};

FormContent.defaultProps = {
    onCancel: null,
    editMode: false,
    isLoading: false,
};
