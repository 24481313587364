import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import { Modal, DeleteModal } from 'Components';
import { showToastSuccess } from 'Utils';
import {
    GET_DEVICES_QUERY,
    UPDATE_APPLICATION_ID,
    GET_DEVICES_STANDALONE,
    GET_APPLICATIONS,
} from 'Constants';

export const UnlinkDevicesModal = ({ isOpen, handleClose, selectedIds }) => {
    const [removeDevicesFromProject, { loading: isLoading }] = useMutation(
        UPDATE_APPLICATION_ID,
        {
            refetchQueries: [
                GET_DEVICES_STANDALONE,
                GET_DEVICES_QUERY,
                GET_APPLICATIONS,
            ],
        },
    );

    const onUnlink = () => {
        removeDevicesFromProject({
            variables: {
                _ids: selectedIds,
                applicationId: null,
            },
            update: () => {
                showToastSuccess('Device(s) unlinked successfully');
                handleClose();
            },
        });
    };

    return (
        <Modal
            isOpened={isOpen}
            handleClose={handleClose}
        >
            <DeleteModal
                submitText="Unlink"
                title="Unlink Device(s)"
                description={'The unlinked devices from the project will remain in Pybytes. '
                    + 'You will find them in the Device section → All Devices table.'}
                cancelAction={handleClose}
                deleteAction={onUnlink}
                isLoading={isLoading}
            />
        </Modal>
    );
};

UnlinkDevicesModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    selectedIds: PropTypes.arrayOf(PropTypes.string),
};

UnlinkDevicesModal.defaultProps = {
    selectedIds: [],
};
