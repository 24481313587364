import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { ReactComponent as ArrowRight } from 'Assets/icons/arrowRight.svg';

import * as Styled from './styled';

export const IconCard = ({
    icon,
    title,
    link,
    linkTitle,
}) => (
    <Styled.Card>
        <Styled.Icon>
            {icon}
        </Styled.Icon>
        <Styled.Content>
            <Styled.Title>
                {title}
            </Styled.Title>
            <Link
                to={
                    link && typeof link[0] === 'string'
                        ? link[0]
                        : link[0].route
                }
            >
                <Styled.Link>
                    {linkTitle}
                    <ArrowRight />
                </Styled.Link>
            </Link>
        </Styled.Content>
    </Styled.Card>
);

IconCard.propTypes = {
    icon: PropTypes.element.isRequired,
    title: PropTypes.string.isRequired,
    link: PropTypes.array.isRequired,
    linkTitle: PropTypes.string.isRequired,
};
