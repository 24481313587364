import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { DeviceCard } from 'Components';
import { getDeviceTemplateDescription } from 'Utils';

import * as Styled from './styled';

export const DeviceRow = ({ title, type, devices, deviceOnClick, supportedTypes }) => {
    const deviceOnClickHandler = (device) => {
        deviceOnClick(type, device);
    };

    const filteredDevices = useMemo(() => {
        if (supportedTypes.length) {
            return devices.filter((device) => supportedTypes.includes(device.type));
        }

        return devices;
    }, [supportedTypes, devices]);

    if (!filteredDevices.length) {
        return null;
    }

    return (
        <Styled.Row>
            <Styled.Title>
                {title}
            </Styled.Title>
            <Styled.Devices>
                {filteredDevices?.map((device) => (
                    <Styled.Device key={device.name}>
                        <DeviceCard
                            device={device}
                            deviceOnClick={deviceOnClickHandler}
                            descriptions={getDeviceTemplateDescription(type, device)}
                            cardType={type}
                        />
                    </Styled.Device>
                ))}
            </Styled.Devices>
        </Styled.Row>
    );
};

DeviceRow.propTypes = {
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    devices: PropTypes.array,
    deviceOnClick: PropTypes.func,
    supportedTypes: PropTypes.array,
};

DeviceRow.defaultProps = {
    devices: [],
    deviceOnClick: () => {},
    supportedTypes: [],
};
