import * as Yup from 'yup';

import { ValidationService } from 'Services';

export const CARRIER = ['Standard', 'Verizon'];
export const DEFAULT_APN = 'internet';
export const BAND = [3, 4, 5, 8, 12, 13, 20, 28];
export const DEFAULT_CID = 1;

export const validationSchema = Yup.object().shape({
    name: Yup.string().required('This field is required.'),
    carrier: Yup.string(),
    band: Yup.string().required('Invalid Band'),
    cid: Yup.string().required('Invalid CID'),
    apn: Yup.string(),
    protocol: Yup.string(),
    reset: Yup.bool(),
    checkbox: Yup.bool(),
});

export const initialValues = {
    _id: null,
    name: '',
    carrier: 'Standard',
    band: 3,
    cid: DEFAULT_CID,
    apn: DEFAULT_APN,
    protocol: '',
    reset: false,
    default: false,
    created: new Date(),
    checkbox: false,
};

export const getValidationSchema = (checkIsNameNotDuplicated, allValidationSchema) =>
    Yup.object().shape({
        name: Yup.string()
            .test(
                'name-duplication',
                ValidationService.duplicatedNameError,
                checkIsNameNotDuplicated,
            )
            .required(ValidationService.getRequiredError('name')),
        ...allValidationSchema.fields,
    });
