import { Form, Field } from 'formik';
import PropTypes from 'prop-types';

import { useEffect } from 'react';
import { TextInput, PasswordInput } from '../../../inputs';
import { RadioButton } from '../../../radioButton';
import { Button } from '../../../buttons';
import {
    WIFI_NETWORKS_TYPE,
    WIFI_SECURE_TYPES,
    DISABLED_PASSWORD,
} from './config';

import * as Styled from './styled';

export const FormContent = ({ handleClose, setFieldValue, values, isLoading }) => {
    useEffect(() => {
        if (DISABLED_PASSWORD.includes(values.secureType)) {
            setFieldValue('password', '');
        }
    }, [values.secureType]);

    return (
        <Form>
            <Styled.Form>
                <Styled.Title>
                    <Styled.MainTitle>
                        New WiFi Credentials
                    </Styled.MainTitle>
                    <Styled.Credentials>
                        Pay attention! New WiFi credentials will not show up on
                        the devices until you will deploy them into those
                        devices.
                    </Styled.Credentials>
                </Styled.Title>
                <div>
                    <Styled.Item>
                        <Styled.InputTitle>
                            SSID
                        </Styled.InputTitle>
                        <Field
                            name="ssid"
                            type="text"
                            component={TextInput}
                            errorPosition="bottom"
                        />
                    </Styled.Item>

                    <Styled.Radio role="presentation">
                        <Field
                            name="secureType"
                            type="radio"
                            component={RadioButton}
                            value={WIFI_SECURE_TYPES.OPEN}
                            title={WIFI_NETWORKS_TYPE[0]}
                        />
                        <Field
                            name="secureType"
                            type="radio"
                            component={RadioButton}
                            value={WIFI_SECURE_TYPES.PASSWORD}
                            title={WIFI_NETWORKS_TYPE[1]}
                        />
                        <Field
                            name="secureType"
                            type="radio"
                            component={RadioButton}
                            value={WIFI_SECURE_TYPES.CERTIFICATE}
                            title={WIFI_NETWORKS_TYPE[2]}
                        />
                    </Styled.Radio>

                    <Styled.Item>
                        <Styled.InputTitle>
                            Password
                        </Styled.InputTitle>
                        <Field
                            name="password"
                            component={PasswordInput}
                            disabled={DISABLED_PASSWORD.includes(
                                values.secureType,
                            )}
                        />
                    </Styled.Item>
                </div>
            </Styled.Form>
            <Styled.ButtonsWrapper>
                <div>
                    <Button
                        buttonType="transparent"
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        loading={isLoading}
                        cyData="add-wifi-submit"
                    >
                        Save
                    </Button>
                </div>
            </Styled.ButtonsWrapper>
        </Form>
    );
};

FormContent.propTypes = {
    handleClose: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
};

FormContent.defaultProps = {
    isLoading: false,
};
