import PropTypes from 'prop-types';

import * as Styled from './styled';

export const RadioButton = ({ title, checked, field, value, actionClick }) => (
    <Styled.RadioButton>
        <Styled.Container htmlFor={title}>
            {title}
            {field && Object.values(field).length ? (
                <input
                    type="radio"
                    id={title}
                    value={field.value}
                    name={field.name}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    checked={field.checked}
                />
            ) : (
                <input
                    type="radio"
                    name="radio"
                    checked={checked}
                    id={title}
                    value={value}
                    onChange={actionClick}
                />
            )}
            <span />
        </Styled.Container>
    </Styled.RadioButton>
);

RadioButton.propTypes = {
    title: PropTypes.string.isRequired,
    checked: PropTypes.bool,
    field: PropTypes.object,
    value: PropTypes.string.isRequired,
    actionClick: PropTypes.func,
};

RadioButton.defaultProps = {
    checked: false,
    field: {},
    actionClick: () => {},
};
