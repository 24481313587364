import PropTypes from 'prop-types';
import { useState } from 'react';
import { Formik } from 'formik';

import { FormContent } from './formContent';
import { Button } from '../../buttons';

import * as Styled from './styled';
import { initialValues, validationSchema } from './config';

export const DeleteModal = ({
    title,
    description,
    deleteAction,
    cancelAction,
    submitText,
    includeInput,
    checkingValue,
    isLoading,
}) => {
    const [isDisabled, setIsDisabled] = useState(false);

    return (
        <Styled.Modal>
            <Styled.Title>
                {title}
            </Styled.Title>
            <Styled.Wrapper>
                <Styled.Description>
                    {description}
                </Styled.Description>
                {includeInput && (
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                    >
                        {(props) => (
                            <FormContent
                                {...props}
                                checkingValue={checkingValue}
                                handleDisable={setIsDisabled}
                            />
                        )}
                    </Formik>
                )}
                <Styled.ButtonsWrapper>
                    <div>
                        <Button
                            buttonType="transparent"
                            onClick={cancelAction}
                        >
                            Cancel
                        </Button>
                    </div>
                    <div>
                        <Button
                            disabled={isDisabled}
                            onClick={deleteAction}
                            loading={isLoading}
                            cyData="delete-button"
                        >
                            {submitText}
                        </Button>
                    </div>
                </Styled.ButtonsWrapper>
            </Styled.Wrapper>
        </Styled.Modal>
    );
};

DeleteModal.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.node.isRequired,
    deleteAction: PropTypes.func.isRequired,
    cancelAction: PropTypes.func.isRequired,
    submitText: PropTypes.string,
    includeInput: PropTypes.bool,
    checkingValue: PropTypes.string,
    isLoading: PropTypes.bool,
};

DeleteModal.defaultProps = {
    submitText: 'Delete',
    includeInput: false,
    checkingValue: '',
    isLoading: false,
};
