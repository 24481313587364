import styled from 'styled-components';

export const Wrapper = styled.div`
    margin-top: 50px;
    color: ${({ theme }) => theme.colors.text};
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Title = styled.div`
    color: ${({ theme }) => theme.colors.title};
    font-size: 22px;
    font-weight: bold;
    line-height: 27px;
`;

export const Description = styled.div`
    margin-top: 10px;

    & p {
        font-size: 16px;
        line-height: 20px;
    }
`;

export const Content = styled.div`
    display: flex;
    margin-top: 36px;
`;

export const Right = styled.div`
    display: flex;
    align-items: flex-start;
    margin-left: 50px;
`;

export const Form = styled.div`
    display: flex;
    flex-direction: column;
    width: 430px;
`;

export const FormItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    & > span {
        color: ${({ theme }) => theme.colors.title};
        font-size: 16px;
        line-height: 22px;
        font-weight: bold;
    }
`;

export const DeviceComponent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

export const DeviceComponentPlus = styled.div`
    margin: 0 13px;
`;

export const Buttons = styled.div`
    display: flex;
    margin-top: 75px;

    & > div {
        margin-right: 20px;
    }
`;
