import styled from 'styled-components';

import { Button as ButtonComponent } from 'Components';

export const FormLine = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    gap: 45px;
    
    select {
        background-color: #eeefef;
    }

    & > span {
        color: ${({ theme }) => theme.colors.title};
        white-space: nowrap;
        padding-top: 8px;
        font-size: 16px;
        font-weight: bold;
        line-height: 1.38;
    }
`;

export const AlertItem = styled.div`
    flex: 1;
`;

export const Button = styled(ButtonComponent)`
    margin-top: 11px;
`;
