import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import { Modal } from 'Components';
import {
    UPDATE_DEVICE_TYPE_ID,
    GET_SIGFOX_CREDENTIALS,
} from 'Constants';
import { showToastError, showToastSuccess } from 'Utils';

import { FormContent } from './formContent';
import { getInitialValues } from '../config';
import * as Styled from './styled';

export const SigfoxContractSettingsModal = ({
    isShowModal,
    setIsShowModal,
    deviceTypeId,
    contractType,
    deviceTypeIdOptions,
}) => {
    const [updateDeviceTypeId, { loading: isLoading }] = useMutation(UPDATE_DEVICE_TYPE_ID, {
        refetchQueries: [GET_SIGFOX_CREDENTIALS],
        awaitRefetchQueries: true,
    });

    const save = async (value) => {
        const selectedDeviceTypeId = deviceTypeIdOptions
            .find((deviceType) => deviceType.value === value.deviceTypeId);

        try {
            await updateDeviceTypeId({
                variables: {
                    deviceTypeId: selectedDeviceTypeId.value,
                    deviceTypeName: selectedDeviceTypeId.label,
                    contractType: value.contractType,
                },
            });
            showToastSuccess('Sigfox contract type changed successfully');
            setIsShowModal(false);
        } catch (error) {
            showToastError(error.message);
        }
    };

    return (
        <Modal
            isOpened={isShowModal}
            handleClose={() => setIsShowModal(false)}
            destroyOnClose={true}
        >
            <Styled.ModalTitle>
                Sigfox Integration Settings
            </Styled.ModalTitle>

            <Styled.ModalContent>
                <Formik
                    enableReinitialize
                    initialValues={getInitialValues(contractType, deviceTypeId ?? deviceTypeIdOptions[0]?.value)}
                    onSubmit={save}
                >
                    {(props) => (
                        <FormContent
                            {...props}
                            deviceTypeIdOptions={deviceTypeIdOptions}
                            closeForm={() => setIsShowModal(false)}
                            isLoading={isLoading}
                        />
                    )}
                </Formik>
            </Styled.ModalContent>
        </Modal>
    );
};

SigfoxContractSettingsModal.propTypes = {
    isShowModal: PropTypes.bool.isRequired,
    setIsShowModal: PropTypes.func.isRequired,
    deviceTypeId: PropTypes.string,
    contractType: PropTypes.string.isRequired,
    deviceTypeIdOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

SigfoxContractSettingsModal.defaultProps = {
    deviceTypeId: '',
};
