import isEmpty from 'lodash/isEmpty';

export const getMlSamplePayload = (samples) => {
    const payload = [];

    samples.forEach((sample) => {
        if (!isEmpty(sample.message)) {
            const data = sample.message.payload;
            
            const startIndex = data.lastIndexOf('[') + 1;
            const endIndex = data.lastIndexOf(']');
            const array = data.slice(startIndex, endIndex).split(',');

            const regex = /^-?\d*(\.\d+)?$/;

            if (array.every((item) => item.match(regex))) {
                payload.push([array[0] * 1, array[1] * 1, array[2] * 1]);
            }
        }
    });

    return payload;
};
