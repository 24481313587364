import { SIGNAL_TYPES, SIGNAL_NETWORK_TYPES } from 'Constants';
import { ReactComponent as LTE } from 'Assets/devices/lte.svg';
import { ReactComponent as WiFi } from 'Assets/devices/wifi.svg';
import { ReactComponent as LoRa } from 'Assets/devices/lora.svg';
import { ReactComponent as DefineSignal } from 'Assets/devices/signals/defineSignal.svg';

/**
 * It extract the real value sent by a signal. The first three bytes must be
 * @param {Buffer} message the binary payload
 */
export const getStringifiedPayload = (message) => {
    const bufferedMessage = Buffer.from(message, 'ascii');
    const lastByte = bufferedMessage[bufferedMessage.length - 1];
    const PIN_FIRST_INDEX = 3;

    switch (parseInt(lastByte, 10)) {
        case SIGNAL_TYPES.INTEGER:
            return bufferedMessage.readIntBE(PIN_FIRST_INDEX, 4);

        case SIGNAL_TYPES.FLOAT: {
            const buf = Buffer.from([bufferedMessage[6], bufferedMessage[5], bufferedMessage[4], bufferedMessage[PIN_FIRST_INDEX]]);
            return buf.readFloatBE(0);
        }

        case SIGNAL_TYPES.STRING:
        case SIGNAL_TYPES.TUPLE: {
            let result = '';
            for (let i = PIN_FIRST_INDEX; i < bufferedMessage.length - 1; i++) {
                result += String.fromCharCode(parseInt(bufferedMessage[i], 10));
            }
            return result;
        }
        default:
            // in this case, instead of throwing an exception, we assume that the device
            // has an old library version, so we give a string representation of the
            // buffer
            return JSON.stringify(bufferedMessage.toJSON());
    }
};

export const getNetworkIcon = (type) => {
    const typeNumber = parseInt(type, 10);
    switch (typeNumber) {
        case SIGNAL_NETWORK_TYPES.wifi:
            return WiFi;
        case SIGNAL_NETWORK_TYPES.lora:
            return LoRa;
        case SIGNAL_NETWORK_TYPES.lte:
            return LTE;
        default:
            return DefineSignal;
    }
};
