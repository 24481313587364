import gql from 'graphql-tag';
import { MESSAGE_DIRECTION_UPLINK } from '../messages';

export const MESSAGES_BY_DEVICE_QUERY = gql`
    query getMessagesByDevice($deviceToken: String!, $messageType: [String], $messagePin: Int!, $limit: Int) {
      getMessagesByDevice(deviceToken: $deviceToken, direction: "${MESSAGE_DIRECTION_UPLINK}",
                          messageType: $messageType, messagePin: $messagePin, limit: $limit) {
        _id
        createdAt
        messageType
        networkType
        messageSize
        messagePin
        payload
      }
    }
`;

export const DEVICE_MESSAGES_PER_RANGE_QUERY = gql`
    query getDeviceMessagesPerRange($chartId: String!) {
        getDeviceMessagesPerRange(chartId: $chartId) {
            _id
            createdAt
            payload
            messagePin
        }
    }
`;

export const DEVICE_MESSAGES_BY_CHART_WITH_PIN_QUERY = gql`
    query getDeviceMessagesByChartWithPin($chartId: String!) {
        getDeviceMessagesByChartWithPin(chartId: $chartId) {
            _id
            createdAt
            payload
            messageSize
            networkType
        }
    }
`;

export const MESSAGE_BY_DEVICE_SUBSCRIPTION = gql`
    subscription messageAddedByDevice($deviceToken: String!, $messageType: [String], $messagePin: Int!) {
        messageAddedByDevice(deviceToken: $deviceToken, messageType: $messageType, messagePin: $messagePin) {
            _id
            createdAt
            messageType
            networkType
            messageSize
            messagePin
            payload
        }
    }
`;

export const MESSAGE_BY_DEVICE_TOKENS_SUBSCRIPTION = gql`
    subscription messageByDeviceTokens($deviceTokens: [String!], $messagePin: Int) {
        messageByDeviceTokens(deviceTokens: $deviceTokens, messagePin: $messagePin) {
            _id
        }
    }
`;

export const DEVICE_UNDEFINED_MESSAGES_QUERY = gql`
    query getUndefinedMessagesByDevice($deviceToken: String!) {
        getUndefinedMessagesByDevice(deviceToken: $deviceToken) {
            _id
            messagePin
            payload
            createdAt
        }
    }
`;

export const LASTDAY_DATAUSAGE_QUERY = gql`
    query getLastDayDataUsage($deviceToken: String!, $interval: MessageInterval!, $pin: Int) {
        getDataUsageByInterval(deviceToken: $deviceToken, interval: $interval, pin: $pin) {
            Data
            Labels
        }
    }
`;

export const GET_APPLICATION_LAST_MESSAGES = gql`
    query getApplicationLastMessages($chartId: String!) {
        getApplicationLastMessages(chartId: $chartId) {
            device {
                token
                description
            }
            lastMessage {
                _id
                payload
                createdAt
                messagePin
            }
        }
    }
`;

export const GET_APPLICATION_LAST_MESSAGES_COUNT_BY_CRITERIA = gql`
    query getApplicationLastMessagesCountByCriteria($chartId: String!) {
        getApplicationLastMessagesCountByCriteria(chartId: $chartId) {
            messagesCount
            devicesTokens
        }
    }
`;

export const GET_APPLICATION_MESSAGES = gql`
    query getApplicationMessages($chartId: String!) {
        getApplicationMessages(chartId: $chartId) {
            device {
                token
                description
            }
            messages {
                _id
                createdAt
                payload
                messagePin
            }
        }
    }
`;

export const GET_DEVICE_LAST_MESSAGES = gql`
    query getDeviceLastMessages($deviceToken: String!) {
        getDeviceLastMessages(deviceToken: $deviceToken) {
            _id
            payload
            messagePin
            createdAt
            networkType
        }
    }
`;

export const GET_DEVICE_MESSAGES = gql`
    query getDeviceMessages($deviceToken: String!, $messagePin: Int!, $paging: Paging) {
        getDeviceMessages(deviceToken: $deviceToken, messagePin: $messagePin, paging: $paging) {
            edges {
                cursor
                node {
                    _id
                    createdAt
                    messagePin
                    payload
                }
            }
            pageInfo {
                hasPreviousPage
                hasNextPage
                lastCursor
                firstCursor
            }
        }
    }
`;
