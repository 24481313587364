import { Popover } from 'antd';
import styled from 'styled-components';

import { hexToRgb } from 'Utils';

export const Select = styled.div`
    position: relative;
    height: 20px;
    cursor: pointer;
    width: 120px;
`;

export const SelectValue = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const SelectedValue = styled.div`
    font-size: 14px;
    line-height: 17px;
    color: ${({ theme }) => theme.colors.title};
    margin-right: 14px;
    max-width: 100px;
    width: fit-content;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const Triangle = styled.div`
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid ${({ theme }) => theme.colors.active};
    transform: ${({ $rotate }) => (!$rotate ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

export const Modal = styled(Popover)`
    position: absolute;
    top: 30px;
    left: 0;
    max-height: 237px;
    padding: 20px 0;
    border-radius: 10px;
    background: ${({ theme }) => theme.colors.menu.text};
    box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.15);
    z-index: 1;
`;

export const Item = styled.div`
    display: flex;
    width: inherit;
    justify-content: flex-start;
    align-items: center;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    padding: 7px 20px;
    text-transform: ${({ $all }) => ($all ? 'none' : 'uppercase')};
    cursor: pointer;
    white-space: nowrap;

    &:hover {
        background: rgba(${({ theme }) => hexToRgb(theme.colors.active)}, 0.1);
    }
    color: ${({ $all, theme }) => ($all ? theme.colors.active : 'inherit')};

    background: ${({ $active, theme }) => (!$active ? theme.colors.menu.text : `rgba(${hexToRgb(theme.colors.active)}, 0,1)`)};
`;
