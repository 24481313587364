import PropTypes from 'prop-types';
import moment from 'moment';

import * as Styled from './styled';

export const GoogleAccountInfo = ({ accountData }) => (
    <Styled.Wrapper>
        <Styled.Image src={accountData.imageUrl} />
        <Styled.InfoWrapper>
            <p>
                Logged in as:
            </p>
            <p>
                <Styled.Title>
                    Name:
                </Styled.Title>
                {accountData.name}
            </p>
            <p>
                <Styled.Title>
                    Email:
                </Styled.Title>
                {accountData.email}
            </p>
            <p>
                <Styled.Title>
                    Session expire time:
                </Styled.Title>
                {moment(accountData.expireTime).format('MM/DD/YYYY HH:mm:ss')}
            </p>
        </Styled.InfoWrapper>
    </Styled.Wrapper>
);

GoogleAccountInfo.propTypes = {
    accountData: PropTypes.object,
};

GoogleAccountInfo.defaultProps = {
    accountData: {},
};
