export const logout = async () => {
    const headers = new Headers();

    const response = await fetch(`${process.env.REACT_APP_API_URL}/account/logout`, {
        method: 'POST',
        headers,
        credentials: 'include',
    });
    if (response.ok) {
        const res = await response.json();
        return res;
    }

    return { errorMessage: await response.json() };
};
