import { useState, useMemo, useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import { Tooltip } from 'antd';

import { ALERTS_QUERY, SAVE_ALERT, ALERT_TYPES, DELETE_ALERT_MUTATION } from 'Constants';
import { Modal, Loader } from 'Components';
import { showToastError, showToastSuccess } from 'Utils';
import { GlobalContext } from 'Context';
import { ReactComponent as CloseIcon } from 'Assets/icons/close.svg';
import { ReactComponent as LastSeenIcon } from 'Assets/icons/pho-redeye-off.svg';
import { ReactComponent as DataUsageIcon } from 'Assets/icons/db-database.svg';
import { ReactComponent as BatteryLevelIcon } from 'Assets/icons/ui-battery-high.svg';

import { AlertForm } from '../alertForm';
import * as Styled from '../../styled';

import { getAlertTooltip } from './helpers';

export const AlertsList = ({ deviceToken, deviceName }) => {
    const globalContext = useContext(GlobalContext);
    const session = globalContext.session;

    const [initialLoading, setInitialLoading] = useState(true);
    const [shownDeleteButton, setShownDeleteButton] = useState(null);
    const [isModalOpened, setIsModalOpened] = useState(false);
    const [selectedAlert, setSelectedAlert] = useState(null);
    const timeoutRef = useRef(null);

    const { data, loading } = useQuery(ALERTS_QUERY, { variables: { deviceToken } });
    const alerts = data?.getAlertsByDevice ?? [];

    const [saveAlert, { loading: isSaveLoading }] = useMutation(SAVE_ALERT, { refetchQueries: [ALERTS_QUERY] });
    const [deleteAlert, { loading: isDeleteLoading }] = useMutation(DELETE_ALERT_MUTATION, { refetchQueries: [ALERTS_QUERY] });

    useEffect(() => () => clearTimeout(timeoutRef.current), []);

    useEffect(
        () => {
            if (initialLoading && !loading) {
                setInitialLoading(false);
            }
        },
        [loading],
    );

    const allowedAlertTypes = useMemo(
        () => {
            const allowedAlertTypesArr = Object.values(ALERT_TYPES);

            alerts.forEach((alert) => {
                const selectedTypeIndex = allowedAlertTypesArr.findIndex((type) => type === alert.type);

                if (selectedTypeIndex !== -1) {
                    allowedAlertTypesArr.splice(selectedTypeIndex, 1);
                }
            });

            return allowedAlertTypesArr;
        },
        [alerts],
    );

    const deleteAlertHandler = async (id) => {
        try {
            await deleteAlert({ variables: { id } });

            showToastSuccess('Alert deleted successfully');
        } catch (error) {
            showToastError(error.message);
        }
    };

    const deleteButtonOnClick = (index) => {
        if (shownDeleteButton === index) {
            deleteAlertHandler(alerts[index]._id);
        } else {
            setShownDeleteButton(index);
            timeoutRef.current = setTimeout(() => {
                setShownDeleteButton(null);
            }, 5000);
        }
    };

    const openEditModal = (alert) => {
        setSelectedAlert(alert);
        setIsModalOpened(true);
    };

    const closeModal = () => {
        setSelectedAlert(null);
        setIsModalOpened(false);
    };

    const formSubmit = async (values) => {
        const { type, name, input } = values;

        try {
            const variables = {
                name,
                userId: session.name,
                deviceToken,
                type,
                active: true,
                input,
            };

            if (selectedAlert) {
                variables._id = selectedAlert._id;
            }

            await saveAlert({ variables });

            const successMessage = selectedAlert
                ? 'Alert edited successfully'
                : 'Alert created successfully';
    
            showToastSuccess(successMessage);

            setIsModalOpened(false);
            setSelectedAlert(null);
        } catch (error) {
            showToastError(error.message);
        }
    };

    const getAlertIcon = (alert) => {
        switch (alert.type) {
            case ALERT_TYPES.LAST_SEEN:
                return <LastSeenIcon />;
            case ALERT_TYPES.DATA_USAGE:
                return <DataUsageIcon />;
            case ALERT_TYPES.BATTERY_LEVEL:
                return <BatteryLevelIcon />;
            default:
                return '';
        }
    };

    if (initialLoading) {
        return <Loader />;
    }

    return (
        <>
            <Styled.AlertsWrapper>
                <Styled.Title>
                    Alerts
                </Styled.Title>
                {alerts.map((alert, index) => (
                    <Styled.AlertLine key={alert._id}>
                        <Tooltip title={getAlertTooltip(alert, deviceName)} placement="topLeft">
                            <Styled.AlertName>
                                {getAlertIcon(alert)}
                                {alert.name}
                            </Styled.AlertName>
                        </Tooltip>
                        <Styled.EditIcon onClick={() => openEditModal(alert)} />
                        <Styled.DeleteButton
                            onClick={() => deleteButtonOnClick(index)}
                            $active={shownDeleteButton === index}
                            loadind={isDeleteLoading}
                        >
                            <CloseIcon />
                        </Styled.DeleteButton>
                    </Styled.AlertLine>
                ))}
                {!alerts.length && (
                    <Styled.EmptyLabel>
                        No alerts were created
                    </Styled.EmptyLabel>
                )}
                <Styled.AlertsButton
                    onClick={() => setIsModalOpened(true)}
                >
                    Create New Alert
                </Styled.AlertsButton>
            </Styled.AlertsWrapper>
            <Modal
                isOpened={isModalOpened}
                handleClose={closeModal}
            >
                <AlertForm
                    onClose={closeModal}
                    onSubmit={formSubmit}
                    selectedAlert={selectedAlert}
                    allowedAlertTypes={allowedAlertTypes}
                    isLoading={isSaveLoading}
                />
            </Modal>
        </>
    );
};

AlertsList.propTypes = {
    deviceToken: PropTypes.string.isRequired,
    deviceName: PropTypes.string.isRequired,
};
