import gql from 'graphql-tag';

export const CREATE_ETHERNET_MUTATION = gql`
    mutation createEthernetSettings($ethernet: EthernetSettingsCreateInput!) {
        createEthernetSettings(ethernet: $ethernet)
    }
`;

export const UPDATE_ETHERNET_MUTATION = gql`
    mutation updateEthernetSettings($ethernet: EthernetSettingsUpdateInput!) {
        updateEthernetSettings(ethernet: $ethernet)
    }
`;

export const GET_ETHERNETS = gql`
   query getAllEthernet {
     getAllEthernet {
        ethernetId,
        name,
        autoSettings,
        ipAddress,
        subnetMask,
        router,
        dnsServer,
        dnsServer2,
        default
      }
   }
`;

export const GET_ETHERNET = gql`
   query getEthernetById($ethernetId: ID!) {
     getEthernetById(ethernetId: $ethernetId) {
        ethernetId,
        name,
        autoSettings,
        ipAddress,
        subnetMask,
        router,
        dnsServer,
        dnsServer2,
        default
      }
   }
`;

export const DELETE_ETHERNET_MUTATION = gql`
   mutation deleteEthernetSettings($ethernetId: ID!) {
     deleteEthernetSettings(ethernetId: $ethernetId)
   }
`;

export const DEFAULT_ETHERNET_MUTATION = gql`
   mutation setEthernetDefault($ethernetId: ID!) {
     setEthernetDefault(ethernetId: $ethernetId) {
        ethernetId,
        name,
        autoSettings,
        ipAddress,
        subnetMask,
        router,
        dnsServer,
        dnsServer2,
        default
     }
   }
`;
