import PropTypes from 'prop-types';

import { IconButton, WidgetContainer } from 'Components';
import { ReactComponent as DeviceIcon } from 'Assets/icons/tile.svg';
import { ReactComponent as OrganizeIcon } from 'Assets/icons/settings.svg';

import * as Styled from './styled';

const DashboardView = ({ device, dashboardData, setEditMode, goCreateWidgetPage }) => (
    <>
        <Styled.DashboardHeaderPanel>
            <IconButton
                onClick={goCreateWidgetPage}
                buttonType="transparent"
                Icon={DeviceIcon}
            >
                Add Widget
            </IconButton>
            <IconButton
                onClick={setEditMode}
                buttonType="transparent"
                Icon={OrganizeIcon}
            >
                Organize
            </IconButton>
        </Styled.DashboardHeaderPanel>
        <Styled.DashboardContent>
            {dashboardData.map((chartData) => (
                <WidgetContainer
                    key={chartData._id}
                    chartId={chartData._id}
                    chartType={chartData.type}
                    widgetConfig={chartData.widget}
                    title={chartData.name}
                    settings={chartData.settings}
                    device={device}
                />
            ))}
        </Styled.DashboardContent>
    </>
);

DashboardView.propTypes = {
    dashboardData: PropTypes.array.isRequired,
    setEditMode: PropTypes.func.isRequired,
    goCreateWidgetPage: PropTypes.func.isRequired,
    device: PropTypes.shape({
        token: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
    }).isRequired,
};

export default DashboardView;
