export const getColors = (activeTheme, activeFont, mainFont) => [
    {
        background: activeTheme,
        text: activeFont,
    },
    {
        background: 'rgba(82, 91, 92, 1)',
        text: activeFont,
    },
    {
        background: 'rgba(219, 222, 222, 1)',
        text: mainFont,
    },
    {
        background: 'rgba(179, 255, 231, 1)',
        text: mainFont,
    },
    {
        background: 'rgba(226, 211, 74, 0.66)',
        text: mainFont,
    },
];
