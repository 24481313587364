import PropTypes from 'prop-types';

import { generateDeviceNetworkIcon } from 'Utils';

import * as Styled from './styled';

export const DeviceCard = ({ device, deviceOnClick, descriptions, cardType }) => {
    const { name, supportedNetworks, imgUrl } = device;
    // TODO: remove default value after saving imgUrl on BE
    const imgSrc = imgUrl || '/images/devices/f01.png';

    const handleClick = () => {
        deviceOnClick(device);
    };

    return (
        <Styled.Card
            onClick={handleClick}
            role="presentation"
            data-cy={`template-card-${cardType}-${device.type}`}
        >
            <Styled.Title>
                {name}
            </Styled.Title>
            <Styled.Description>
                <Styled.List>
                    <img src={imgSrc} alt="device" />
                    <ul>
                        {descriptions.map((item) => (
                            <Styled.ListItem key={item}>
                                {item}
                            </Styled.ListItem>
                        ))}
                    </ul>
                </Styled.List>
            </Styled.Description>
            <Styled.Networks>
                {supportedNetworks?.map((network) => (
                    <Styled.Network key={network}>
                        {generateDeviceNetworkIcon(network)}
                    </Styled.Network>
                ))}
            </Styled.Networks>
        </Styled.Card>
    );
};

DeviceCard.propTypes = {
    device: PropTypes.shape({
        type: PropTypes.string,
        name: PropTypes.string,
        imgUrl: PropTypes.string,
        supportedNetworks: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
    deviceOnClick: PropTypes.func,
    descriptions: PropTypes.array,
    cardType: PropTypes.string,
};

DeviceCard.defaultProps = {
    deviceOnClick: () => {},
    descriptions: [],
    cardType: 'deviceTemplate',
};
