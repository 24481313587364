import { COMPILATION_ACTION_TYPES } from './actionTypes';

export const setId = (compilationId) => ({
    type: COMPILATION_ACTION_TYPES.SET_COMPILATION_ID,
    payload: compilationId,
});

export const setStatus = (status) => ({
    type: COMPILATION_ACTION_TYPES.SET_STATUS,
    payload: status,
});

export const setLogs = (logs) => ({
    type: COMPILATION_ACTION_TYPES.SET_LOGS,
    payload: logs,
});

export const setFile = (file) => ({
    type: COMPILATION_ACTION_TYPES.SET_COMPILATION_FILE,
    payload: file,
});

export const setInitialState = () => ({
    type: COMPILATION_ACTION_TYPES.SET_COMPILATTION_INIT_STATE,
});
