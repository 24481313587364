import { useState, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { PageHeader } from 'Components';

import { PymeshLicense, PymeshDeviceSelection, PymeshNetworkSettings, PymeshSummary } from './wizardComponents';

const createPymeshSteps = {
    LICENSE_AGREEMENT: 0,
    SELECT_DEVICES: 1,
    NETWORK_SETTINGS: 2,
    PYMESH_SUMMARY: 3,
};

export const PymeshWizard = () => {
    const history = useHistory();
    const { applicationId } = useParams();

    const [step, setStep] = useState(createPymeshSteps.LICENSE_AGREEMENT);
    const [values, setValues] = useState({});

    const nextStep = () => setStep(step + 1);
    const previousStep = () => {
        if (step === 0) {
            history.goBack();
        } else {
            setStep(step - 1);
        }
    };

    const saveValues = (valuesToSave) => setValues({ ...values, ...valuesToSave });

    const content = useMemo(() => {
        switch (step) {
            case createPymeshSteps.LICENSE_AGREEMENT:
                return (
                    <PymeshLicense
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        values={values}
                    />
                );
            case createPymeshSteps.SELECT_DEVICES:
                return (
                    <PymeshDeviceSelection
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        values={values}
                    />
                );
            case createPymeshSteps.NETWORK_SETTINGS:
                return (
                    <PymeshNetworkSettings
                        nextStep={nextStep}
                        previousStep={previousStep}
                        saveValues={saveValues}
                        values={values}
                    />
                );
            case createPymeshSteps.PYMESH_SUMMARY:
                return (
                    <PymeshSummary
                        applicationId={applicationId}
                        previousStep={previousStep}
                        values={values}
                    />
                );
            default:
                return null;
        }
    }, [step]);

    return (
        <>
            <PageHeader
                title="Create Pymesh"
                step={step + 1}
                maxStep={Object.keys(createPymeshSteps).length}
                stepper
            />
            {content}
        </>
    );
};
