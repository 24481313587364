import styled from 'styled-components';

export const Row = styled.div`
    padding-top: 35px;
`;

export const Title = styled.div`
    margin-bottom: 10px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.title};
    font-size: 16px;
    font-weight: bold;
    font-weight: 20px;
`;

export const Device = styled.div`
    margin:0 20px 10px 0;
    display: flex;
`;

export const Devices = styled.div`
    display: flex;
    flex-wrap: wrap;
`;
