import styled, { css } from 'styled-components';

import { hexToRgb } from 'Utils';

export const FolderRow = styled.div`
    display: flex;
    padding: 10px 10px 5px 5px;
    height: 30px;
    cursor: pointer;

    &:hover {
        background-color: ${({ theme }) => theme.colors.boxBackground};
    }
`;

export const FolderIcon = styled.div`
    margin-right: 7px;

    svg {
        width: 15px;
        height: 15px;
        opacity: 50%;
    }
`;

export const ExpansionArrow = styled.div`
    width: 20px;
    height: 30px;
    display: inline-block;
    margin-right: 10px;
    margin-top: -4px;
    background-color: transparent;
    cursor: pointer;

    ${({ $expanded }) => $expanded && `
        transform: rotate(90deg);
        margin-top: -8px;
        margin-left: -5px;
        margin-right: 15px;
    `}

    svg {
        width: 20px;
        height: 20px;
    }
`;

export const FolderTitle = styled.div`
    position: relative;

    span {
        position: absolute;
        bottom: -5px;
        color: ${({ theme }) => theme.colors.title};
        white-space: nowrap;
    }
`;

export const FolderInput = styled.input`
    border: 1px solid rgba(${({ theme }) => hexToRgb(theme.colors.title)}, 0.5);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    height: 18px;
    position: absolute;
    max-width: 100px;
    top: -1px; 
`;

const updatedStyling = css`
    color: #c46200 !important;
`;

export const FolderName = styled.span`
    ${({ $updated }) => $updated && updatedStyling}
`;
