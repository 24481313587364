import { useMemo, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Gauge from '@ant-design/plots/es/components/gauge';

import { TEMPERATURE_UNITS } from 'Constants';

import {
    getChartValue,
    getRange,
    getConfig,
    celciusToFarenheit,
    farenheitToCelsius,
    chartLabels,
} from './helpers';
import { EmptyWidget } from '../emptyWidget';

import * as StyledGeneral from '../styled';
import * as Styled from './styled';

export const TemperatureWidget = ({ data, isEmpty, devicesCount, unit, title }) => {
    const isDataBroken = Number.isNaN(+data);

    const [activeUnit, setActiveUnit] = useState(unit);
    const chartWrapperRef = useRef(null);
    const [statisticFontSize, setStatisticFontSize] = useState(40);

    useEffect(() => {
        if (chartWrapperRef.current) {
            setStatisticFontSize(chartWrapperRef.current.offsetWidth / 10);
        }
    }, [data]);

    const value = useMemo(
        () => {
            if (activeUnit === TEMPERATURE_UNITS.FAHRENHEIT && unit === TEMPERATURE_UNITS.CELSIUS) {
                return celciusToFarenheit(data);
            }

            if (activeUnit === TEMPERATURE_UNITS.CELSIUS && unit === TEMPERATURE_UNITS.FAHRENHEIT) {
                return farenheitToCelsius(data);
            }

            return data;
        },
        [data, activeUnit],
    );

    const chartValue = useMemo(() => getChartValue(value, activeUnit), [value, activeUnit]);
    const range = useMemo(() => getRange(chartValue), [chartValue]);
    const config = useMemo(
        () => getConfig({ value, range, chartValue, statisticFontSize }),
        [range, value, chartValue, statisticFontSize],
    );

    if (!data || isEmpty || isDataBroken) {
        return <EmptyWidget title={title} isDataBroken={isDataBroken} />;
    }

    const renderChartLabels = () => chartLabels[activeUnit].map((label) => (
        <Styled.GaugeLabel key={label}>
            {`${label}°`}
        </Styled.GaugeLabel>
    ));

    return (
        <Styled.Container>
            <Styled.Info>
                <div>
                    <StyledGeneral.ChartTitle>
                        {title}
                    </StyledGeneral.ChartTitle>
                    {devicesCount} device
                    {devicesCount > 1 && 's'}
                </div>
                <Styled.UnitsWrapper>
                    <Styled.Unit
                        $active={activeUnit === TEMPERATURE_UNITS.CELSIUS}
                        onClick={() => setActiveUnit(TEMPERATURE_UNITS.CELSIUS)}
                    >
                        {`°${TEMPERATURE_UNITS.CELSIUS}`}
                    </Styled.Unit>
                    <Styled.UnitSplit />
                    <Styled.Unit
                        $active={activeUnit === TEMPERATURE_UNITS.FAHRENHEIT}
                        onClick={() => setActiveUnit(TEMPERATURE_UNITS.FAHRENHEIT)}
                    >
                        {`°${TEMPERATURE_UNITS.FAHRENHEIT}`}
                    </Styled.Unit>
                </Styled.UnitsWrapper>
            </Styled.Info>
            <Styled.Chart ref={chartWrapperRef}>
                {renderChartLabels()}
                <Gauge {...config} />
            </Styled.Chart>
        </Styled.Container>
    );
};

TemperatureWidget.propTypes = {
    data: PropTypes.string,
    devicesCount: PropTypes.number,
    title: PropTypes.string.isRequired,
    unit: PropTypes.string,
    isEmpty: PropTypes.bool,
};

TemperatureWidget.defaultProps = {
    unit: '',
    isEmpty: false,
    data: null,
    devicesCount: null,
};
