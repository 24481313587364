export const AWS_REGIONS = [
    { name: 'US East (Ohio)', code: 'us-east-2' },
    { name: 'US East (N. Virginia)', code: 'us-east-1' },
    { name: 'US West (Oregon)', code: 'us-west-2' },
    { name: 'Asia Pacific (Mumbai)', code: 'ap-south-1' },
    { name: 'Asia Pacific (Singapore)', code: 'ap-southeast-1' },
    { name: 'Asia Pacific (Sydney)', code: 'ap-southeast-2' },
    { name: 'Asia Pacific (Tokyo)', code: 'ap-northeast-1' },
    { name: 'Asia Pacific (Seoul)', code: 'ap-northeast-2' },
    { name: 'EU (Frankfurt)', code: 'eu-central-1' },
    { name: 'EU (Ireland)', code: 'eu-west-1' },
    { name: 'EU (London)', code: 'eu-west-2' },
    { name: 'China (Beijing)', code: 'cn-north-1' },
];

export const AWS_API_VERSION = '2015-05-28';

export const AWS_DEFAULT_POLICY_NAME = 'pybytes-policy';

// note that API_VERSION and POLICY_VERSION differ
export const AWS_DEFAULT_POLICY_DOCUMENT = {
    Version: '2012-10-17',
    Statement: [
        {
            Effect: 'Allow',
            Action: ['iot:Connect'],
            Resource: ['*'],
            Condition: {
                Bool: {
                    'iot:Connection.Thing.IsAttached': ['true'],
                },
            },
        },
        {
            Effect: 'Allow',
            Action: ['iot:Publish'],
            Resource: ['*'],
        },
        {
            Effect: 'Allow',
            Action: ['iot:Subscribe'],
            Resource: ['*'],
        },
    ],
};

export const INTEGRATION_SERVICE_NAMES = {
    AWS: 'AWS',
    WEBHOOK: 'WebHook',
    Azure: 'Azure',
    Google: 'Google',
};

export const HTTP_METHODS = {
    POST: 'POST',
    GET: 'GET',
    PUT: 'PUT',
    PATCH: 'PATCH',
    DELETE: 'DELETE',
};

export const REQUEST_FORMATS = {
    JSON: 'JSON',
    WebForm: 'WebForm',
    Custom: 'Custom',
};

export const GOOGLE_LOGIN_URL = 'https://www.googleapis.com';
export const GOOGLE_LOGIN_SCOPES = [
    `${GOOGLE_LOGIN_URL}/auth/cloud-platform`,
    `${GOOGLE_LOGIN_URL}/auth/cloudiot`,
    'email',
    'profile',
];

export const GOOGLE_LOGIN_ERRORS = {
    ACCESS_DENIED: {
        TYPE: 'access_denied',
        TEXT: "You're not allowed to manage Google Cloud IoT registries",
    },
    CLOSED_BY_USER: {
        TYPE: 'popup_closed_by_user',
        TEXT: "You've closed the window without proceeding with the authentication, so it's not possible to continue",
    },
    BASE: {
        TEXT: `There were some issues in authenticating your Google account.
            Please verify you have the privileges to operate with Google cloud IoT`,
    },
};

export const GOOGLE_DOMAIN = 'https://content-cloudiot.googleapis.com/v1';

export const GOOGLE_LOGIN_DOCS = 'https://cloudiot.googleapis.com/$discovery/rest?version=v1';

export const GOOGLE_LOGIN_STORAGE_KEY = 'gcloud_integration';

export const GOOGLE_CLOUD_REGIONS = [
    { name: 'US Central', code: 'us-central1' },
    { name: 'Europe West', code: 'europe-west1' },
    { name: 'Asia East', code: 'asia-east1' },
];

export const GOOGLE_CLOUD_STACKDRIVER_LOGGING = [
    {
        value: 'NONE',
        text: 'No device data stored',
        title: 'Disabled',
    },
    {
        value: 'ERROR',
        text: `Captures devices errors, such as failed connection attempts and
            failed publishes. Does not include authentication errors`,
        title: 'Error events will be logged',
    },
    {
        value: 'INFO',
        text: `Captures devices errors (except authentication errors) and
            include all lifecycle events, such as device connections and disconnections`,
        title: 'Informational events will be logged',
    },
    {
        value: 'DEBUG',
        text: `Captures all device activity in a highly verbose log statement.
            Recommended for device troubleshooting`,
        title: 'All events will be logged',
    },
];
