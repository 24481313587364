import styled from 'styled-components';

export const Title = styled.div`
    color: ${({ theme }) => theme.colors.title};
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: bold;
`;

export const NotificationWrapper = styled.div`
    margin-bottom: 10px;

    p {
        margin-bottom: 0;
    }
`;

export const FormWrapper = styled.div`
    margin-top: 30px;
`;

export const FormItem = styled.div`
    margin-bottom: 10px;
`;

export const ItemLabel = styled.span`
    color: ${({ theme }) => theme.colors.title};
    padding-top: 8px;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.38;
    margin-bottom: 15px;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    margin-top: 55px;

    
    & > * {
        margin-right: 30px;
    }
`;
