import { capitalizeFirstLetter } from 'Utils';

import { initialValues } from './config';

export const getInitialValues = (arrayOfNetworks, id) => {
    let result = initialValues;
    if (id && id.length) {
        const selectedLTE = arrayOfNetworks.find((item) => item._id === id);
        const { __typename, ...currentLTE } = selectedLTE;
        result = {
            ...result,
            ...currentLTE,
            carrier: capitalizeFirstLetter(currentLTE.carrier),
        };
    }

    return result;
};
