import PropTypes from 'prop-types';
import { Form, Field } from 'formik';

import { TextInput } from 'Components';

import * as Styled from './styled';

export const FormContent = ({ dirty, isValid, isLoading }) => (
    <Form>
        <Styled.FormItem>
            <Styled.ItemLabel>
                Device MAC
            </Styled.ItemLabel>
            <Field
                name="mac"
                type="text"
                component={TextInput}
                disabled
            />
        </Styled.FormItem>
        <Styled.FormItem>
            <Styled.ItemLabel>
                Device Name
            </Styled.ItemLabel>
            <Field
                name="name"
                type="text"
                component={TextInput}
            />
        </Styled.FormItem>
        <Styled.Button
            buttonType="transparent"
            type="submit"
            loading={isLoading}
            disabled={!(dirty && isValid)}
        >
            Save
        </Styled.Button>
    </Form>
);

FormContent.propTypes = {
    dirty: PropTypes.bool.isRequired,
    isValid: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool,
};

FormContent.defaultProps = {
    isLoading: false,
};
