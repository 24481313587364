import styled from 'styled-components';

import { hexToRgb } from 'Utils';

export const Title = styled.div`
    margin-bottom: 10px;
    color: rgba(${({ theme }) => hexToRgb(theme.colors.title)}, 0.89);
    font-size: 22px;
    line-height: 27px;
    font-weight: bold;
`;

export const Form = styled.div`
    display: flex;
`;

export const FormLeft = styled.div`
    margin-right: 200px;
`;

export const FormItem = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
`;

export const FormTitle = styled.div`
    color: ${({ theme }) => theme.colors.title};
    font-size: 16px;
    font-weight: bold;
`;

export const FormButton = styled.div`
    display: flex;
            
    & div:first-child {
        margin-right: 10px;
    }
`;

export const Checkboxes = styled.div`
    display: flex;
    flex-direction: column;

    & div:first-child {
        margin-bottom: 10px;
    }
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 200px;
    margin-top: 25px;
`;
