import { useMemo, useState, useContext } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';

import { GET_APPLICATION_MESSAGES, ALL_DEVICES } from 'Constants';
import { getStringifiedPayload, removeGraphQlTypenames, formatAMPM } from 'Utils';
import { useMessageSubscription } from 'Hooks';

import { TooltipDeviceMarker } from '../../../../tooltipDeviceMarker';
import { MapHistoryWidget } from '../../../../widgets';

export const MapHistory = ({
    chartId,
    chartSettings,
    updateApplicationChart,
    title,
}) => {
    const initialTimeOption = chartSettings?.timePeriod;
    const [selectedDevice, setSelectedDevice] = useState(ALL_DEVICES);
    const styledTheme = useContext(ThemeContext);
    const color = styledTheme.colors.error;

    const updateChartSettingsTime = (timePeriod) => {
        const filteredSettings = removeGraphQlTypenames(chartSettings);

        updateApplicationChart({
            variables: {
                chartInput: {
                    _id: chartId,
                    settings: {
                        ...filteredSettings,
                        timePeriod: +timePeriod,
                    },
                },
            },
        });
    };

    const { data, error, loading, refetch } = useQuery(
        GET_APPLICATION_MESSAGES,
        {
            variables: { chartId },
        },
    );
    const projectMessages = data?.getApplicationMessages ?? [];

    const subscribeData = useMemo(() => {
        const deviceTokens = projectMessages.map((messageObj) => messageObj.device.token);
        const messagePin = projectMessages[0]?.messages?.[0].messagePin;

        return { deviceTokens, messagePin };
    }, [projectMessages]);

    useMessageSubscription(subscribeData.deviceTokens, subscribeData.messagePin, refetch);

    const parsedMessages = useMemo(
        () => {
            let filteredMessages;
            if (selectedDevice.value !== ALL_DEVICES.value) {
                filteredMessages = projectMessages.filter((item) => item.device.token === selectedDevice.value);
            } else {
                filteredMessages = projectMessages;
            }
            return filteredMessages.map((item) => {
                const messages = item.messages.map((message) => ({
                    ...message,
                    value: getStringifiedPayload(message.payload),
                    date: new Date(message.createdAt),
                    device: item.device,
                }));

                return messages;
            });
        },
        [projectMessages, selectedDevice],
    );

    const parsedDevices = useMemo(
        () => [
            ALL_DEVICES,
            ...projectMessages.map(
                (message) => ({ label: message.device.description, value: message.device.token }),
            ),
        ],
        [projectMessages],
    );

    const renderMetaInfo = (renderData) => <TooltipDeviceMarker {...renderData} />;

    const { locations, isSomeDataBroken } = useMemo(
        () => {
            let isSomeDataBrokenResult = false;
            const resultLocations = parsedMessages.map((array) => array
                .reduce((accumulator, message) => {
                    const matched = (/((-)?([0-9.]*))##((-)?([0-9.]*))/).test(message.value);

                    if (matched) {
                        const positions = message.value.split('##');
                        accumulator.push({
                            lat: +positions[0],
                            lng: +positions[1],
                            renderMetaInfo: renderMetaInfo.bind(null, {
                                title: message.device.description,
                                time: formatAMPM(message.date),
                                colorOfTime: color,
                            }),
                        });
                    } else {
                        isSomeDataBrokenResult = true;
                    }

                    return accumulator;
                }, [])
                .reverse());

            return { locations: resultLocations, isSomeDataBroken: isSomeDataBrokenResult };
        },
        [parsedMessages],
    );

    return (
        <MapHistoryWidget
            data={locations}
            updateTimePeriod={updateChartSettingsTime}
            deviceOptions={parsedDevices}
            updateSelectedDevice={setSelectedDevice}
            title={title}
            initialTimeOption={initialTimeOption}
            isEmpty={loading || error}
            isSomeDataBroken={isSomeDataBroken}
            isDataBroken={!locations.length && isSomeDataBroken}
        />
    );
};

MapHistory.propTypes = {
    chartId: PropTypes.string.isRequired,
    chartSettings: PropTypes.shape({
        timePeriod: PropTypes.number.isRequired,
    }).isRequired,
    updateApplicationChart: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
};
