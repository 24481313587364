import styled from 'styled-components';

import { hexToRgb } from 'Utils';

export const Title = styled.div`
    margin-bottom: 10px;
    color: rgba(${({ theme }) => hexToRgb(theme.colors.title)}, 0.89);
    font-size: 22px;
    line-height: 27px;
    font-weight: bold;
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;

    & > div:first-child {
        margin-right: 20%;
    }
`;

export const InfoItem = styled.div`
    display: flex;
    align-items: center;

    & span:first-child {
        margin-right: 5px;
        font-size: 18px;
        line-height: 24px;
        font-weight: bold;
    }

    & span:last-child {
        font-size: 18px;
        line-height: 24px;
    }
`;

export const ButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 200px;
    margin-top: 25px;
`;

export const Log = styled.div`
    width: 100%;
    min-height: 50px;
    max-height: 550px;
    overflow-y: scroll;
    background-color: ${({ theme }) => theme.colors.boxBackground};
    border-radius: 4px;
`;

export const LogItem = styled.div`
    padding: 5px 10px;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 20px;
`;
