import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import { FormContent } from './formContent';

export const EthernetSelectForm = ({ initialValue, ethernetList, onChange }) => {
    const ethernetOptions = useMemo(
        () => ethernetList.map((item) => ({
            value: item.ethernetId,
            label: item.name,
        })),
        [ethernetList],
    );

    const onSubmit = (values) => {
        onChange(values);
    };

    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={{ ethernet: initialValue || '' }}
        >
            {(props) => <FormContent ethernetOptions={ethernetOptions} {...props} />}
        </Formik>
    );
};

EthernetSelectForm.propTypes = {
    onChange: PropTypes.func.isRequired,
    initialValue: PropTypes.string,
    ethernetList: PropTypes.arrayOf(PropTypes.object),
};

EthernetSelectForm.defaultProps = {
    initialValue: '',
    ethernetList: [],
};
