import { useContext, useMemo } from 'react';
import { useHistory, useParams } from 'react-router';
import isEmpty from 'lodash/isEmpty';
import { useQuery } from '@apollo/client';

import { Button, DeviceCard, Loader } from 'Components';
import { displayDuration, getDeviceTemplateDescription } from 'Utils';
import { ROUTES, GET_DEVICE_TEMPLATES_FOR_FIRMWARE, DEVICE_TEMPLATE_TYPES } from 'Constants';

import { FirmwareContext } from '../../context';

import * as Styled from './styled';

export const FirmwareSummary = () => {
    const history = useHistory();
    const { firmwareData } = useContext(FirmwareContext);
    const { deviceId: deviceToken } = useParams();

    const { data, loading } = useQuery(GET_DEVICE_TEMPLATES_FOR_FIRMWARE);
    const deviceTemplates = data?.getDeviceTemplates ?? {};

    const { device, deviceDescriptionArr } = useMemo(() => {
        if (!isEmpty(deviceTemplates)) {
            let deviceTemplate = deviceTemplates.deviceTemplates?.find(
                (item) => item.type === firmwareData?.deviceType,
            );
            let descriptionArr = [];

            if (deviceTemplate) {
                descriptionArr = getDeviceTemplateDescription(DEVICE_TEMPLATE_TYPES.DEVICES, deviceTemplate);
            } else {
                deviceTemplate = deviceTemplates.deviceTemplates?.find(
                    (item) => item.type === firmwareData?.deviceType && !!item?.selfContained,
                );
                descriptionArr = getDeviceTemplateDescription(DEVICE_TEMPLATE_TYPES.DEVICES, deviceTemplate);
            }

            return {
                device: deviceTemplate,
                deviceDescriptionArr: descriptionArr,
            };
        }
        return {
            device: '',
            deviceDescriptionArr: '',
        };
    }, [deviceTemplates]);

    const status = `${firmwareData?.deviceType.toUpperCase()} was successfully updated and provisioned!`;
    const version = `${
        isEmpty(firmwareData?.customFile)
            ? firmwareData?.firmwareVersionObject?.version
            : 'Custom'
    } - ${firmwareData?.deviceType.toUpperCase()}`;

    const goToDevice = () => {
        history.push(
            `${ROUTES.devices.main}/${deviceToken}/dashboard`,
        );
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <div>
            <Styled.Title>Summary</Styled.Title>
            <Styled.Content>
                <Styled.ContentLeft>
                    <Styled.Item>
                        <span>Status: </span>
                        <span>{status}</span>
                    </Styled.Item>
                    <Styled.Item>
                        <span>Version: </span>
                        <span>{version}</span>
                    </Styled.Item>
                    <Styled.Item>
                        <span>Total time: </span>
                        <span>
                            {displayDuration(firmwareData?.firmwareTime)}
                        </span>
                    </Styled.Item>
                    {device && (
                        <Styled.Device>
                            <DeviceCard
                                device={device}
                                descriptions={deviceDescriptionArr}
                            />
                        </Styled.Device>
                    )}
                </Styled.ContentLeft>
                <Styled.ContentRight>
                    <Styled.ContentTitle>
                        Firmware Updater Details
                    </Styled.ContentTitle>
                    <Styled.Logs>
                        {firmwareData?.logs.map((log) => (
                            <p key={log}>{log}</p>
                        ))}
                    </Styled.Logs>
                </Styled.ContentRight>
            </Styled.Content>
            <Button onClick={goToDevice}>
                Device page
            </Button>
        </div>
    );
};
