import styled from 'styled-components';

import { hexToRgb } from 'Utils';

export const Content = styled.div`
    max-width: 580px;
    width: 100%;
    margin-top: 18px;
    padding-left: 22px;
    color: ${({ theme }) => theme.colors.title};
    font-size: 14px;
    line-height: 17px;
`;

export const Header = styled.div`
    margin: 15px 0 30px;
`;

export const Title = styled.div`
    color: rgba(${({ theme }) => hexToRgb(theme.colors.title)}, 0.89);
    font-size: 22px;
    line-height: 27px;
    font-weight: bold;
`;

export const Description = styled.div`
    margin-top: 10px;
`;

export const DescriptionHeader = styled.div`
    font-size: 16px;
    font-weight: bold;
`;

export const DescriptionClickboard = styled.div`
    margin-top: 10px;
`;

export const List = styled.div`
    padding-right: 34px;
`;

export const ListItem = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
`;

export const ListNumber = styled.span`
    width: fit-content;
    margin-right: 10px;
`;

export const ListDescription = styled.div`
    display: block;

    & > span {
        display: block;
    }
`;

export const Text = styled.div`
    display: flex;
    flex-direction: row;
`;

export const Link = styled.a`
    margin: 0 10px;
    color: ${({ theme }) => theme.colors.active};
    font-size: 16px;
`;
