import * as Yup from 'yup';

import { TIME_OPTIONS, PROJECT_CHART_TYPES, PULL_SIZE_OPTIONS } from 'Constants';

export const settingsByField = {
    pin: [
        PROJECT_CHART_TYPES.GROUPED_DEVICES,
        PROJECT_CHART_TYPES.HUMIDITY,
        PROJECT_CHART_TYPES.TEMPERATURE,
        PROJECT_CHART_TYPES.SIGNAL_QUALITY,
        PROJECT_CHART_TYPES.MAP,
        PROJECT_CHART_TYPES.MAP_HISTORY,
        PROJECT_CHART_TYPES.LINE_CHART,
        PROJECT_CHART_TYPES.BAR_CHART,
        PROJECT_CHART_TYPES.TABLE,
        PROJECT_CHART_TYPES.COUNT,
        PROJECT_CHART_TYPES.PRESSURE,
        PROJECT_CHART_TYPES.AIR_QUALITY,
        PROJECT_CHART_TYPES.HEART_RATE,
        PROJECT_CHART_TYPES.ALTITUDE,
        PROJECT_CHART_TYPES.BATTERY_LEVEL,
    ],
    criteria: [PROJECT_CHART_TYPES.COUNT],
    unit: [
        PROJECT_CHART_TYPES.WEATHER,
        PROJECT_CHART_TYPES.TEMPERATURE,
        PROJECT_CHART_TYPES.ALTITUDE,
    ],
    position: [PROJECT_CHART_TYPES.WEATHER],
    timePeriod: [
        PROJECT_CHART_TYPES.MAP_HISTORY,
        PROJECT_CHART_TYPES.LINE_CHART,
        PROJECT_CHART_TYPES.BAR_CHART,
    ],
    limit: [
        PROJECT_CHART_TYPES.LINE_CHART,
        PROJECT_CHART_TYPES.BAR_CHART,
    ],
};

export const initialValues = {
    name: '',
    settings: {
        pin: 0,
        criteria: '',
        unit: '',
        position: {
            lat: '',
            lng: '',
        },
        timePeriod: TIME_OPTIONS.FIVE_MINUTES,
        limit: PULL_SIZE_OPTIONS.TWENTY,
    },
};

export const getValidationSchema = (widgetType, isNameIncluded) => {
    console.log('IS PIN VALIDATED', settingsByField.pin.includes(widgetType));

    return Yup.object().shape({
        name: Yup.string().when('settings', {
            is: () => isNameIncluded,
            then: Yup.string().required(),
        }),
        settings: Yup.object().shape({
            // name check is added just to run `when` rule
            pin: Yup.number().nullable().when('name', {
                is: () => settingsByField.pin.includes(widgetType),
                then: Yup.number().required(),
            }),
            criteria: Yup.string().nullable().when('name', {
                is: () => settingsByField.criteria.includes(widgetType),
                then: Yup.string().required(),
            }),
            unit: Yup.string().nullable().when('name', {
                is: () => settingsByField.unit.includes(widgetType),
                then: Yup.string().required(),
            }),
            position: Yup.object().nullable().when('name', {
                is: () => settingsByField.position.includes(widgetType),
                then: Yup.object().shape({
                    lat: Yup.string().required(),
                    lng: Yup.string().required(),
                }),
            }),
            timePeriod: Yup.number().nullable().when('name', {
                is: () => settingsByField.timePeriod.includes(widgetType),
                then: Yup.number().required(),
            }),
            limit: Yup.number().nullable().when('name', {
                is: () => settingsByField.limit.includes(widgetType),
                then: Yup.number().required(),
            }),
        }),
    });
};
