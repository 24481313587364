import styled from 'styled-components';

export const HeaderWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
`;

export const Title = styled.div`
    font-size: 36px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.title};
`;

export const HeaderRightBlock = styled.div`
    font-size: 14px;
    color: ${({ theme }) => theme.colors.title};
    display: flex;
    align-items: center;
`;

export const NetworksWrapper = styled.div`
    font-size: 20px;
    line-height: 0;
    margin-left: 5px;

    svg {
        margin: 0 5px;
    }
`;
