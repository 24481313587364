import styled from 'styled-components';

export const Wrapper = styled.div`
    margin-top: 30px;

    &:first-child {
        margin-top: 0;
    }
`;

export const ChartWrapper = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    margin-top: 20px;
`;

export const Title = styled.div`
    font-size: 20px;
`;

export const SubTitle = styled.div`
    font-size: 16px;
    margin-top: 20px;
`;
