import { useMemo } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';

import { SAVE_LTE_SETTINGS, GET_LTE_SETTINGS } from 'Constants';
import { showToastError, showToastSuccess } from 'Utils';

import { FormContent } from './formContent';
import { getInitialValues } from './helpers';
import { validationSchema, getValidationSchema } from './config';

import * as Styled from './styled';

export const LTEModal = ({ handleClose, handleSuccess, id }) => {
    const { data: lteSettings } = useQuery(GET_LTE_SETTINGS);

    const arrayOfSettings = useMemo(
        () => lteSettings?.getLteSettings ?? [],
        [lteSettings],
    );

    const [saveLTE, { loading: isLoading }] = useMutation(SAVE_LTE_SETTINGS, {
        refetchQueries: [GET_LTE_SETTINGS],
    });

    const checkIsNameNotDuplicated = (name) =>
        !arrayOfSettings.some((settings) => settings.name === name);

    const updateLTESetting = async (values) => {
        const { checkbox, band, ...mainValues } = values;
        const payload = {
            ...mainValues,
            band: parseInt(band, 10),
            carrier: mainValues.carrier.toLowerCase(),
        };

        try {
            await saveLTE({
                variables: {
                    lteSettings: payload,
                },
                update: () => {
                    showToastSuccess('LTE settings updated successfully');
                    const finalFunc = handleSuccess || handleClose;
                    finalFunc();
                },
            });
        } catch (error) {
            showToastError(error.message);
            handleClose();
        }
    };

    const saveLTESettings = async (values) => {
        const { checkbox, band, ...mainValues } = values;
        try {
            await saveLTE({
                variables: {
                    lteSettings: {
                        ...mainValues,
                        band: parseInt(band, 10),
                    },
                },
                update: () => {
                    showToastSuccess('LTE settings added successfully');
                    const finalFunc = handleSuccess || handleClose;
                    finalFunc();
                },
            });
        } catch (error) {
            showToastError(error.message);
            handleClose();
        }
    };

    const submitClickHandler = id ? updateLTESetting : saveLTESettings;
    const selectedValidationSchema = id
        ? validationSchema
        : getValidationSchema(checkIsNameNotDuplicated, validationSchema);

    return (
        <Styled.Modal>
            <Styled.Header>
                Add a new LTE
            </Styled.Header>
            <Formik
                enableReinitialize
                validateOnMount
                initialValues={getInitialValues(arrayOfSettings, id)}
                validationSchema={selectedValidationSchema}
                onSubmit={submitClickHandler}
            >
                {(props) => (
                    <FormContent {...props} handleClose={handleClose} isLoading={isLoading} />
                )}
            </Formik>
        </Styled.Modal>
    );
};

LTEModal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSuccess: PropTypes.func,
    id: PropTypes.string,
};

LTEModal.defaultProps = {
    handleSuccess: null,
    id: '',
};
