export class ValidationService {
    static duplicatedNameError = 'Name is duplicated';

    static getRequiredError(name) {
        return `${name[0].toUpperCase() + name.slice(1)} is required`;
    }

    static getMaxLength(max) {
        return `Field length should be less than ${max}`;
    }
}
