import PropTypes from 'prop-types';

import * as Styled from './styled';

export const CardLink = ({
    icon,
    title,
    link,
}) => (
    <Styled.Card onClick={() => window.open(link, '_blank').focus()}>
        <Styled.Icon>
            {icon}
        </Styled.Icon>
        <Styled.Title>
            {title}
        </Styled.Title>
    </Styled.Card>
);

CardLink.propTypes = {
    icon: PropTypes.element.isRequired,
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
};
