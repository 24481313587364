import { uniqueNamesGenerator, NumberDictionary } from 'unique-names-generator';
import * as Yup from 'yup';

import { flowers, trees, birds, positiveAdjectives } from 'Constants';

const nouns = [...flowers, ...trees, ...birds];
const numbers = NumberDictionary.generate({ length: 4 });
const nameOptions = {
    length: 3,
    style: 'capital',
    separator: '-',
    dictionaries: [positiveAdjectives, nouns, numbers],
};
const descriptionOptions = {
    length: 2,
    style: 'capital',
    separator: ' ',
    dictionaries: [positiveAdjectives, nouns],
};

export const getInitialValues = () => ({
    name: uniqueNamesGenerator(nameOptions),
    description: uniqueNamesGenerator(descriptionOptions),
});

export const validationSchema = Yup.object().shape({
    name: Yup.string()
        .max(64, 'Device name cannot be longer than 64 characters')
        .required('Device name cannot be empty'),
    description: Yup.string()
        .max(64, 'Device description cannot be longer than 64 characters')
        .required('Device description cannot be empty'),
});
