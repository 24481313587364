import PropTypes from 'prop-types';

import * as Styled from './styled';

export const AlertBordered = ({ message, title, type }) => (
    <Styled.Wrapper $type={type}>
        <h3>{title}</h3>
        <p>{message}</p>
    </Styled.Wrapper>
);

AlertBordered.propTypes = {
    message: PropTypes.node.isRequired,
    title: PropTypes.string,
    type: PropTypes.oneOf(['success', 'info', 'warning', 'error']),
};

AlertBordered.defaultProps = {
    title: '',
    type: 'error',
};
