import { useMemo, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { Breadcrumbs, PageHeader, ProjectAddNetworksStep } from 'Components';
import { showToastSuccess } from 'Utils';
import {
    BREADCRUMBS_LABELS,
    GET_APPLICATIONS,
    CREATE_APPLICATION_MUTATION,
    ROUTES,
} from 'Constants';

import { ProjectDescription } from './components';

export const CreateProject = () => {
    const history = useHistory();

    const [formValues, setFormValues] = useState({});
    const [step, setStep] = useState(1);

    const { data: projectsData } = useQuery(GET_APPLICATIONS);
    const projects = useMemo(() => (projectsData?.getApplications ?? []), [projectsData]);

    const [createProject, { loading: isLoading }] = useMutation(CREATE_APPLICATION_MUTATION, { refetchQueries: [GET_APPLICATIONS] });

    const redirectToPrevPage = () => history.goBack();

    const stepBack = () => {
        setStep(step - 1);
    };
    const stepForward = (values) => {
        setFormValues({
            ...formValues,
            ...values,
        });
        setStep(step + 1);
    };

    const onProjectCreate = (values) => {
        const newProjectData = {
            ...formValues,
            ...values,
        };

        createProject({
            variables: { applicationInput: newProjectData },
            update: () => {
                showToastSuccess('Project created successfully');
                history.push(ROUTES.projects.main);
            },
        });
    };

    let content = null;

    switch (step) {
        case 2:
            content = (
                <ProjectAddNetworksStep
                    nextStep={onProjectCreate}
                    prevStep={stepBack}
                    formValues={formValues}
                    isLoading={isLoading}
                />
            );
            break;

        case 1:
        default:
            content = (
                <ProjectDescription
                    projects={projects}
                    nextStep={stepForward}
                    prevStep={redirectToPrevPage}
                    formValues={formValues}
                />
            );
    }

    return (
        <>
            <Breadcrumbs labels={BREADCRUMBS_LABELS.projects} />
            <PageHeader
                title="Create Project"
                step={step}
                maxStep={2}
                stepper
            />
            {content}
        </>
    );
};
