import { useMemo } from 'react';
import PropTypes from 'prop-types';
import URI from 'urijs';

import { HTTP_METHODS, REQUEST_FORMATS } from 'Constants';

import * as Styled from './styled';

export const RequestCode = ({ values }) => {
    const {
        requestType,
        url,
        httpHeaders,
        requestFormat,
        queryParameters,
        authenticationUsername,
        authenticationPassword,
        body,
        customBody,
    } = values;

    const uri = useMemo(() => {
        const baseUri = URI(url);

        queryParameters.forEach(({ propName, value }) => {
            if (!(propName && value)) {
                return;
            }
    
            baseUri.setQuery(propName, value);
        });

        return baseUri;
    }, [url, queryParameters]);

    const headers = useMemo(
        () => {
            const baseHeaders = httpHeaders
                .filter(({ propName, value }) => propName && value)
                .map(({ propName, value }) => (
                    <span key={propName}>
                        {propName}: {value} <br />
                    </span>
                ));

            if (authenticationUsername && authenticationPassword) {
                baseHeaders.push((
                    <span key="authentication">
                        Authorization: Basic &lt;HIDDEN&gt; <br />
                    </span>
                ));
            }

            return baseHeaders;
        },
        [httpHeaders, authenticationUsername, authenticationPassword],
    );

    const payload = useMemo(() => {
        if (requestType === HTTP_METHODS.GET) {
            return null;
        }

        if (requestFormat === REQUEST_FORMATS.JSON && body.length) {
            return JSON.stringify(
                body.reduce((acc, { propName, value }) => {
                    if (!(propName && value)) {
                        return acc;
                    }
                    return { ...acc, [propName]: value };
                }, {}),
                null,
                2,
            );
        }

        if (requestFormat === REQUEST_FORMATS.WebForm && body.length) {
            return body
                .filter(({ propName, value }) => (propName && value))
                .map(({ propName, value }) => `${propName}=${value}`)
                .join('&');
        }

        return customBody;
    }, [requestType, requestFormat, body]);

    const uriResource = uri.resource() || '/';
    const protocol = uri.protocol().toUpperCase() || 'HTTP';

    return (
        <Styled.Pre>
            {requestType} {uriResource} {protocol}/1.1 <br />
            Host: {uri.host()} <br />
            {headers}
            {requestType !== HTTP_METHODS.GET && (
                <div>
                    <br />
                    Message Payload: {payload}<br />
                </div>
            )}
        </Styled.Pre>
    );
};

RequestCode.propTypes = {
    values: PropTypes.object.isRequired,
};
