import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import { IconButton, Table, Modal, DeleteModal } from 'Components';
import { ROUTES, DEL_ML_MODEL_MUTATION, GET_ML_MODEL_QUERY } from 'Constants';
import { showToastError, showToastSuccess } from 'Utils';
import { ReactComponent as ML } from 'Assets/icons/machine-learning.svg';

import { getColumns } from './columns';

import * as Styled from './styled';

export const ModelTable = ({ data, onRowClick }) => {
    const history = useHistory();
    const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
    const [selectedMachineLearningId, setSelectedMachineLearningId] = useState(null);

    const [deleteMlMutation, { loading }] = useMutation(
        DEL_ML_MODEL_MUTATION,
        { refetchQueries: [GET_ML_MODEL_QUERY] },
    );

    const deleteMachineLearning = (id) => {
        setSelectedMachineLearningId(id);
        setIsDeleteModalOpened(true);
    };

    const closeDeleteModal = () => {
        setIsDeleteModalOpened(false);
        setSelectedMachineLearningId(null);
    };

    const deleteHandler = () => {
        try {
            deleteMlMutation({
                variables: {
                    modelId: selectedMachineLearningId,
                },
                update: () => {
                    showToastSuccess('Model deleted successfully');
                    setIsDeleteModalOpened(false);
                },
            });
        } catch (err) {
            showToastError(err.message);
        }
    };

    const columns = useMemo(() => getColumns({ deleteMachineLearning }), []);

    const redirectToCreateModel = () => {
        history.push(ROUTES.machineLearning.createModel);
    };

    return (
        <>
            <Styled.Table>
                <Styled.Header>
                    <IconButton
                        onClick={redirectToCreateModel}
                        Icon={ML}
                    >
                        Add ML Model
                    </IconButton>
                </Styled.Header>
                <div>
                    <Table
                        columns={columns}
                        data={data}
                        onRowClick={onRowClick}
                    />
                </div>
            </Styled.Table>
            <Modal
                isOpened={isDeleteModalOpened}
                handleClose={closeDeleteModal}
            >
                <DeleteModal
                    title="Delete Model"
                    description="Are you sure you want to delete the model?"
                    cancelAction={closeDeleteModal}
                    deleteAction={deleteHandler}
                    isLoading={loading}
                />
            </Modal>
        </>
    );
};

ModelTable.propTypes = {
    data: PropTypes.array.isRequired,
    onRowClick: PropTypes.func.isRequired,
};
