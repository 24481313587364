import { Dropdown } from 'Components';

export const getColumns = ({ deleteMachineLearning }) => {
    const dropdownActions = [
        {
            executedFunction: deleteMachineLearning,
            title: 'Delete',
        },
    ];

    return [
        {
            title: 'Model Name',
            dataIndex: 'name',
        },
        {
            title: 'Actions',
            dataIndex: '_id',
            width: '10%',
            render: (id) => (
                <Dropdown
                    actions={dropdownActions}
                    id={id}
                />
            ),
        },
    ];
};
