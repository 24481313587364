import styled from 'styled-components';

import { dropdownFocus } from 'Styles';
import { Dropdown as DropdownComponent } from 'Components';
import { hexToRgb } from 'Utils';

export const Table = styled.div`
    width: 90%;
    margin-top: 10px;
    position: relative;
`;

export const FiltersBar = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    width: 90%;
    position: relative;
`;

export const FilterWrapper = styled.div`
    vertical-align: bottom;
    min-width: 240px;
    padding: 5px 5px;
    margin-right: 25px;
`;

export const FilterTitle = styled.div`
    margin: 2px 15px 2px 0;
    width: 25%;
    display: inline-block;
    vertical-align: bottom;

    h3 {
        margin: 0;
        font-weight: bold;
        font-size: medium;
    }
`;

export const SortButton = styled.div`
    position: absolute;
    right: 0;
`;

export const Dropdown = styled(DropdownComponent)`
    background-color: transparent;
    width: 150px;
    border-radius: 5px;
    border: 1px solid rgba(${({ theme }) => hexToRgb(theme.colors.text)}, 0.2);
    display: inline-block;

    &:hover {
        border-radius: 5px;
        ${dropdownFocus}
    }

    &:focus {
        border-radius: 5px;
        ${dropdownFocus}
    }
`;

export const DropdownIcon = styled.div`
    width: 100%;
`;

export const DropdownTitle = styled.div`
    width: 80%;
    text-align: center;
    vertical-align: bottom;
    display: inline-block;
    padding: 0 10px 5px;
`;

export const ArrowDown = styled.div`
    width: 20%;
    transform: rotate(90deg);
    display: inline-block;
    padding-right: 15px;
`;

export const SortArrow = styled.div`
    max-height: 20px;
    display: inline-block;

    svg {
        width: 30px;
        height: 30px;
        color: #fff;
    }
`;

export const SortArrowUp = styled.div`
    margin-left: 9px;
    transform: rotate(-90deg);
`;

export const SortArrowDown = styled.div`
    transform: rotate(90deg);
`;
