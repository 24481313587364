import { useMemo } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

import { ALERT_TYPES } from 'Constants';
import { convertMinutes, convertBytes } from 'Utils';

import { FormContent } from './formContent';
import {
    initialValues,
    validationSchema,
    periodMultiplierOptions,
    dataUsageMultiplierOptions,
} from './config';
import * as Styled from './styled';

export const AlertForm = ({ onClose, onSubmit, selectedAlert, allowedAlertTypes, isLoading }) => {
    const combinedInitialValues = useMemo(
        () => {
            if (!selectedAlert) {
                return initialValues;
            }
            const { type, name } = selectedAlert;
            let additionValues = {};

            switch (selectedAlert.type) {
                case ALERT_TYPES.LAST_SEEN: {
                    const [period, size] = convertMinutes(selectedAlert.input.period);
                    additionValues = {
                        period,
                        periodMultiplier: periodMultiplierOptions.find(
                            (option) => option.label.includes(size),
                        ).value,
                    };
                    break;
                }
                case ALERT_TYPES.DATA_USAGE: {
                    const [period, size] = convertMinutes(selectedAlert.input.period);
                    const [bytes, bytesSize] = convertBytes(selectedAlert.input.dataUsage.quantity);
                    additionValues = {
                        period,
                        periodMultiplier: periodMultiplierOptions.find(
                            (option) => option.label.includes(size),
                        ).value,
                        dataUsage: bytes,
                        dataUsageMultiplier: dataUsageMultiplierOptions.find(
                            (option) => option.label.includes(bytesSize),
                        ).value,
                    };
                    break;
                }
                case ALERT_TYPES.BATTERY_LEVEL: {
                    additionValues = {
                        batteryOperator: selectedAlert.input.battery.operator,
                        batteryQuantity: selectedAlert.input.battery.quantity,
                    };
                    break;
                }
                default:
                    break;
            }

            return { type, name, ...additionValues };
        },
        [selectedAlert],
    );

    const onSubmitLocal = (values) => {
        const {
            type,
            name,
            period,
            periodMultiplier,
            dataUsage,
            dataUsageMultiplier,
            batteryOperator,
            batteryQuantity,
        } = values;
        let alertInput = {};

        switch (values.type) {
            case ALERT_TYPES.LAST_SEEN:
                alertInput = {
                    period: period * periodMultiplier,
                };
                break;
            case ALERT_TYPES.DATA_USAGE:
                alertInput = {
                    period: period * periodMultiplier,
                    dataUsage: {
                        quantity: dataUsage * dataUsageMultiplier,
                    },
                };
                break;
            case ALERT_TYPES.BATTERY_LEVEL:
                alertInput = {
                    period: 10,
                    battery: {
                        operator: batteryOperator,
                        quantity: batteryQuantity,
                    },
                };
                break;
            default:
                break;
        }

        onSubmit({ type, name, input: alertInput });
    };

    return (
        <Styled.Wrapper>
            <Styled.Header>
                {selectedAlert ? 'Edit Alert' : 'Create Alert'}
            </Styled.Header>
            <Styled.Content>
                <Formik
                    initialValues={combinedInitialValues}
                    onSubmit={onSubmitLocal}
                    validationSchema={validationSchema}
                >
                    {(props) => (
                        <FormContent
                            {...props}
                            onClose={onClose}
                            isEditMode={!!selectedAlert}
                            allowedAlertTypes={allowedAlertTypes}
                            isLoading={isLoading}
                        />
                    )}
                </Formik>
            </Styled.Content>
        </Styled.Wrapper>
    );
};

AlertForm.propTypes = {
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    selectedAlert: PropTypes.object,
    allowedAlertTypes: PropTypes.array.isRequired,
    isLoading: PropTypes.bool,
};

AlertForm.defaultProps = {
    selectedAlert: null,
    isLoading: false,
};
