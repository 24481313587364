export const tooltipTitles = {
    region: (
        <div style={{ padding: '5px 10px' }}>
            <p>REGION:</p>
            <p>
                LoRa protocol uses frequencies that have a longer range, this comes with more restrictions that are often country-specific.
            </p>
            <p>As a result, Pymesh over LoRa is specified for a number of bands for these regions.</p>
            <p>Available options are:</p>
            <div>- Europe-EU868,</div>
            <div>- USA-US915,</div>
            <div>- Australia-AU915,</div>
            <div>- Asia-AS923</div>
            <div>- India-IN865.</div>
        </div>
    ),
    frequency: (
        <>
            <p>
                Once the Region was chosen, next the actual central frequency for the LoRa
                radio communication has to be selected, expressed in MHz.
            </p>
            <p>By default the lower value is preselected.</p>
        </>
    ),
    bandwidth: (
        <div style={{ padding: '5px 10px' }}>
            <p>BANDWIDTH:</p>
            <p>Lower value means lower datarate, but increased range.</p>
            <p>Bandwidth of 125KHz makes datarate 4 times lower than bandwidth of 500KHz.</p>
            <p>Available options are:</p>
            <div>- 125 KHz,</div>
            <div>- 250 KHz,</div>
            <div>- 500 KHz.</div>
            <br />
            <p>
                Bandwidth and spreading factor (SF - next parameter that you can set) determine the datarate (and range).
                For example, the maximum datarate (of 18.7Kbps) is reached at 500Hkz bandwidth and SF7,
                while the lowest datarate (of 293bps) is reached for 125KHz bandwidth and SF12.
            </p>

        </div>
    ),
    spreadingFactor: (
        <div style={{ padding: '5px 10px' }}>
            <p>SPREADING FACTOR:</p>
            <p>Spreading factor (SF) is datarate parameter of LoRa radio transmission.</p>
            <p>Value 7 represents the highest datarate, value 8 is twice as slow, and so on.</p>
            <p>Maximum value of 12 has the highest range, but the actual datarate goes as low as 293 bps (using 125KHz bandwidth).</p>
        </div>
    ),
};
