import { PYMESH_REGIONS, PYMESH_BANDWIDTHS, PYMESH_SPREAD_FACTORS } from 'Constants';

export const getInitialValues = (valuesNext) => {
    const region = valuesNext?.region || PYMESH_REGIONS[0].region.name;
    const interval = PYMESH_REGIONS.find((regionInfo) => regionInfo.region.name === region).frequencies_interval;

    return {
        region,
        frequency: valuesNext?.frequency || interval[0].min.toFixed(0),
        bandwidth: valuesNext?.bandwidth || PYMESH_BANDWIDTHS[0].label,
        spreadFactor: valuesNext?.spreadFactor || PYMESH_SPREAD_FACTORS[0],
        joinKey: valuesNext?.joinKey || '',
    };
};
