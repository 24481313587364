import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { ProjectWidgetDummy, Button } from 'Components';
import { 
    COMMON_RECOMMENDED_WIDGETS,
    PREVIEW_PROJECT_WIDGET_CONFIG,
    VECTOR_PREDEFINED_WIDGETS,
    VECTOR_RECOMMENDED_WIDGETS,
} from 'Constants';
import { ReactComponent as CheckMarkIcon } from 'Assets/icons/check-round.svg';

import { WidgetSettingsModal } from '../widgetSettingsModal';

import * as Styled from './styled';

const defaultWidgetModalSettings = { isOpen: false, widgetType: '' };

export const WidgetsSelection = ({ skipStep, nextStep, loading, vector }) => {
    const [recommendedActive, setRecommendedActive] = useState(true);
    const [widgetSelections, setWidgetSelections] = useState(VECTOR_PREDEFINED_WIDGETS[vector] || {});
    const [widgetModalSettings, setWidgetModalSettings] = useState(defaultWidgetModalSettings);

    const orderedWidgets = useMemo(
        () => {
            const startingWidgetList = [...PREVIEW_PROJECT_WIDGET_CONFIG];
            const selectedList = Object.keys(VECTOR_PREDEFINED_WIDGETS[vector]);

            const preselectedWidgets = startingWidgetList.filter((widget) => selectedList.includes(widget.type));
            const notPreselectedWidgets = startingWidgetList.filter((widget) => !selectedList.includes(widget.type));
            
            return [...preselectedWidgets, ...notPreselectedWidgets];
        },
        [vector],
    );

    const widgetsList = useMemo(
        () => { 
            const recommendedWidgets = [...COMMON_RECOMMENDED_WIDGETS, ...VECTOR_RECOMMENDED_WIDGETS[vector]];
            if (recommendedActive) {
                return orderedWidgets.filter((obj) => recommendedWidgets.includes(obj.type));
            }
            return orderedWidgets;
        },
        [vector, recommendedActive, orderedWidgets],
    );

    const onSelect = (type) => {
        if (!widgetSelections[type]) {
            setWidgetModalSettings({ isOpen: true, widgetType: type });
            return;
        }

        const newWidgetsSelection = { ...widgetSelections };

        delete newWidgetsSelection[type];

        setWidgetSelections(newWidgetsSelection);
    };

    const onSettingsSubmit = (values) => {
        setWidgetSelections({
            ...widgetSelections,
            [widgetModalSettings.widgetType]: values,
        });
        setWidgetModalSettings(defaultWidgetModalSettings);
    };

    const onSubmit = () => {
        nextStep(widgetSelections);
    };

    return (
        <>
            <Styled.Title>
                Select the widgets you wish to add to your Dashboard
            </Styled.Title>
            <Styled.Row>
                <Button
                    buttonType={recommendedActive ? 'filled' : 'transparent'}
                    onClick={() => setRecommendedActive(true)}
                >
                    Recommended
                </Button>
                <Button
                    buttonType={recommendedActive ? 'transparent' : 'filled'}
                    onClick={() => setRecommendedActive(false)}
                >
                    All Widgets
                </Button>
            </Styled.Row>
            <Styled.List>
                {widgetsList?.map(({ type }) => (
                    <Styled.WidgetWrapper key={type}>
                        <ProjectWidgetDummy
                            type={type}
                            onClick={onSelect}
                            active={!!widgetSelections[type]}
                        />
                        {!!widgetSelections[type] && (
                            <Styled.SuccessCheck>
                                <CheckMarkIcon />
                            </Styled.SuccessCheck>
                        )}
                    </Styled.WidgetWrapper>
                ))}
            </Styled.List>
            <Styled.ButtonsWrapper>
                <Button
                    buttonType="transparent"
                    onClick={skipStep}
                >
                    Set up later
                </Button>
                <Button
                    onClick={onSubmit}
                    loading={loading}
                    type="submit"
                >
                    Create
                </Button>
            </Styled.ButtonsWrapper>
            <WidgetSettingsModal
                isOpen={widgetModalSettings.isOpen}
                onClose={() => setWidgetModalSettings(defaultWidgetModalSettings)}
                onSubmit={onSettingsSubmit}
                widgetType={widgetModalSettings.widgetType}
            />
        </>
    );
};

WidgetsSelection.propTypes = {
    skipStep: PropTypes.func.isRequired,
    nextStep: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    vector: PropTypes.string,
};

WidgetsSelection.defaultProps = {
    vector: '',
};
