import { useState, useEffect, useRef } from 'react';
import { useQuery } from '@apollo/client';
import { useParams, useHistory } from 'react-router-dom';

import { GET_USER_PROFILE_QUERY, ROUTES } from 'Constants';
import { VaultService } from 'Services';
import { PymakrEditor } from '../pymakrEditor';

export const PymakrGitPreprocessor = () => {
    const history = useHistory();
    const { repoName } = useParams();
 
    const [project, setProject] = useState(null);
    const [projectKey, setProjectKey] = useState(null);
    const { data: userData, loading } = useQuery(GET_USER_PROFILE_QUERY);
    const user = userData?.getUserProfile?.owner;
    const storageRef = useRef();

    useEffect(() => {
        if (!storageRef.current) {
            storageRef.current = new VaultService();
        }
    }, []);

    useEffect(() => {
        if (!loading) {
            setProjectKey(`${user}-${repoName}`);
        }
    }, [loading]);

    useEffect(() => {
        if (projectKey) {
            const storedProject = storageRef.current.getItem(projectKey);
            if (storedProject) {
                setProject(storedProject?.hierarchy);
            } else {
                history.push(ROUTES.pymakr.github);
            }
        }
    }, [projectKey]);

    if (loading || !project) {
        return null;
    }
    
    return (
        <PymakrEditor
            projectKey={projectKey}
            title={project?.name}
            initialStructure={project}
            user={user}
        />
    );
};
