import isEmpty from 'lodash/isEmpty';
import assignIn from 'lodash/assignIn';
import {
    ApolloClient,
    ApolloLink,
    HttpLink,
    InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import fetch from 'unfetch';

import { setExpiredSession } from 'Utils';

import introspectionQueryResultData from '../fragmentTypes.json';

let session;
let client;

const {
    REACT_APP_API_URL_PYCONFIG_APOLLO_SUBSCRIPTIONS,
    REACT_APP_API_URL_MQTT_APOLLO_SUBSCRIPTIONS,
    REACT_APP_API_URL_API_APOLLO_SUBSCRIPTIONS,
    REACT_APP_API_URL,
} = process.env;

function createClient(sessionData, cookie) {
    session = sessionData;

    const options = {
        uri: `${REACT_APP_API_URL}/graphql`,
        credentials: 'include',
    };

    // if we're not in the browser, we want to send the cookie for
    // authenticating to graphql server
    if (!isEmpty(cookie)) {
        assignIn(options, {
            headers: {
                cookie,
            },
            // important, otherwise the cookie gets removed
            // https://github.com/apollographql/apollo-link/tree/master/packages/apollo-link-http#fetch-polyfill
            fetch,
        });
    }

    // Create an http link:
    let httpLink = new HttpLink(options);

    const errorLink = onError(
        ({ operation, response, graphQLErrors, networkError }) => {
            console.error(operation, response);
            if (graphQLErrors) {
                graphQLErrors.forEach(({ message, locations, path, extensions }) => {
                    console.log('code', extensions);
                    if (extensions.code === 'UNAUTHENTICATED') {
                        if (process.env.REACT_APP_CUSTOM_LOGIN === 'true') {
                            setExpiredSession();
                            window.location.href = '/login';
                        } else {
                            window.location.href = `${process.env.REACT_APP_PYCOM_SSO_URL}/logout`;
                        }
                    }
                    console.error(`[GraphQL error]: Message: ${message},
                        Location: ${locations}, Path: ${path}`);
                });
            }

            if (networkError && networkError.statusCode === 403) {
                window.location.href = '/login';
            } else {
                console.warn(networkError);
            }
        },
    );

    httpLink = errorLink.concat(httpLink);

    const pyconfigWsLink = new WebSocketLink({
        uri: REACT_APP_API_URL_PYCONFIG_APOLLO_SUBSCRIPTIONS,
        options: { reconnect: true, connectionParams: sessionData },
    });

    const mqttServerWsLink = new WebSocketLink({
        uri: REACT_APP_API_URL_MQTT_APOLLO_SUBSCRIPTIONS,
        options: { reconnect: true, connectionParams: sessionData },
    });

    const pybytesApiWsLink = new WebSocketLink({
        uri: REACT_APP_API_URL_API_APOLLO_SUBSCRIPTIONS,
        options: { reconnect: true, connectionParams: sessionData },
    });

    const PYCONFIG_SUBSCRIPTION = 'PYCONFIG_SUBSCRIPTION';
    const MQTTSERVER_SUBSCRIPTION = 'MQTTSERVER_SUBSCRIPTION';
    const PYBYTES_API_SUBSCRIPTION = 'PYBYTES_API_SUBSCRIPTION';
    const PYBYTES_API_QUERY_STITCHED_SCHEMA =
        'PYBYTES_API_QUERY_STITCHED_SCHEMA';

    function split(testQuery, mqttServerWs, pybytesApiWs, pyconfigWs, stitchedSchemaLink) {
        return new ApolloLink((operation, forward) => {
            let result;
            switch (testQuery(operation)) {
                case PYCONFIG_SUBSCRIPTION:
                    result = pyconfigWs.request(operation, forward);
                    break;
                case MQTTSERVER_SUBSCRIPTION:
                    result = mqttServerWs.request(operation, forward);
                    break;
                case PYBYTES_API_SUBSCRIPTION:
                    result = pybytesApiWs.request(operation, forward);
                    break;
                case PYBYTES_API_QUERY_STITCHED_SCHEMA:
                    result = stitchedSchemaLink.request(operation, forward);
                    break;
                default:
                    break;
            }
            return result;
        });
    }

    /**
     * when we do graphQL operations we check what type it is,
     * if it is subscription look at the subscription name to choose what web-socket link use
     * if it is query use http-link from pybytes-api stitched schema
     *
     * then pass this function to `switch` of above split function
     */
    const link = split(
        ({ query }) => {
            let result;
            const {
                operation: operationType,
                name: { value: operationName },
            } = getMainDefinition(query);

            if (operationType === 'subscription') {
                const ops = operationName.toLowerCase();

                if (ops.includes('__api__')) {
                    result = PYBYTES_API_SUBSCRIPTION;
                } else if (ops.includes('__config__')) {
                    result = PYCONFIG_SUBSCRIPTION;
                } else {
                    result = MQTTSERVER_SUBSCRIPTION;
                }
            } else {
                // operation should be query/mutation
                result = PYBYTES_API_QUERY_STITCHED_SCHEMA;
            }
            return result;
        },
        mqttServerWsLink,
        pybytesApiWsLink,
        pyconfigWsLink,
        httpLink,
    );

    return new ApolloClient({
        link,
        cache: new InMemoryCache({
            introspectionQueryResultData,
        }),
    });
}

export function getClient(sessionData, cookie) {
    if (
        session === undefined ||
        client === undefined ||
        session !== sessionData
    ) {
        client = createClient(sessionData, cookie);
    }

    return client;
}
