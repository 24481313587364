import { LORA_CONSTANTS } from 'Constants';
import {
    getGatewayConfigUe,
    getGatewayConfigNorthAmerica,
    getGatewayConfigAustralia,
} from './loraConfigFiles';

const { REACT_APP_PYCOM_LORA_SERVER_ADDRESS } = process.env;

export const initialValues = {
    expansionBoard: 'unknown',
    gatewayId: '',
};

export const getGatewayConfigFile = (loraRegion, loraServer, gatewayId) => {
    let configFile;
    switch (loraRegion) {
        // eu
        case LORA_CONSTANTS.REGIONS.TTN[0].value:
        case LORA_CONSTANTS.REGIONS.CHIRPSTACK[0].value:
            configFile = getGatewayConfigUe(
                gatewayId,
                loraServer === LORA_CONSTANTS.SERVERS.CHIRPSTACK ? REACT_APP_PYCOM_LORA_SERVER_ADDRESS : loraRegion,
            );
            break;
        // North America
        case LORA_CONSTANTS.REGIONS.TTN[1].value:
        case LORA_CONSTANTS.REGIONS.CHIRPSTACK[1].value:
            configFile = getGatewayConfigNorthAmerica(
                gatewayId,
                loraServer === LORA_CONSTANTS.SERVERS.CHIRPSTACK ? REACT_APP_PYCOM_LORA_SERVER_ADDRESS : loraRegion,
            );
            break;
        // Australia
        case LORA_CONSTANTS.REGIONS.TTN[2].value:
        case LORA_CONSTANTS.REGIONS.CHIRPSTACK[2].value:
            configFile = getGatewayConfigAustralia(
                gatewayId,
                loraServer === LORA_CONSTANTS.SERVERS.CHIRPSTACK ? REACT_APP_PYCOM_LORA_SERVER_ADDRESS : loraRegion,
            );
            break;
        default:
            break;
    }

    return configFile;
};
