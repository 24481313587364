import cloneDeep from 'lodash/cloneDeep';
import { setUpdatedFiles, setResetMD5 } from './actionCreators';

export const getColorizerHandlers = (dispatch) => ({
    setUpdatedFile: (updatedFiles, fileToAdd, reset = false) => {
        if (!updatedFiles.includes(fileToAdd)) {
            const newUpdatedList = cloneDeep(updatedFiles);
            newUpdatedList.push(fileToAdd);
            dispatch(setUpdatedFiles(newUpdatedList));

            if (reset) {
                dispatch(setResetMD5(true));
            }
        }
    },
    unsetUpdatedFile: (updatedFiles, fileToRemove, reset = false) => {
        if (updatedFiles.includes(fileToRemove)) {
            const newUpdatedList = cloneDeep(updatedFiles);
            newUpdatedList.splice(newUpdatedList.indexOf(fileToRemove), 1);
            dispatch(setUpdatedFiles(newUpdatedList));

            if (reset) {
                dispatch(setResetMD5(true));
            }
        }
    },
    finishUpdate: () => {
        dispatch(setResetMD5(false));
    },
});
