import { useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useContext, useState, useEffect } from 'react';
import { ThemeContext } from 'styled-components';

import { PageHeader, Loader } from 'Components';
import { GET_ML_MODEL_QUERY, ROUTES } from 'Constants';

import * as Styled from './styled';
import { NoModels, ModelTable } from './components';

export const AllModels = () => {
    const styledTheme = useContext(ThemeContext);
    const history = useHistory();

    const [initialLoading, setInitialLoading] = useState(true);

    const { data, loading } = useQuery(GET_ML_MODEL_QUERY, {
        fetchPolicy: 'cache-and-network',
    });
    const allModels = data?.getMlModels ?? [];

    useEffect(
        () => {
            if (initialLoading && !loading) {
                setInitialLoading(false);
            }
        },
        [loading],
    );

    if (initialLoading) {
        return <Loader />;
    }

    const description = allModels.length
        ? (
            <Styled.PageTitle>
                Here is the place to collect and manage all your Machine Learning Models.
                Visit {styledTheme.whiteLabelCompany}&nbsp;
                <a href={process.env.REACT_APP_LINK_DOCS} target="_blank" rel="noreferrer">
                    documentation
                </a> for details.
            </Styled.PageTitle>
        )
        : 'Machine Learning will help you to analyze the data from your devices ' +
        'on a go! Create the first Model and upload it to your devices.';

    const onRowClick = (record) => {
        const { modelDetails: { main, dataAcquisition } } = ROUTES.machineLearning;
        history.push(`${main}/${record._id}${dataAcquisition}`);
    };

    return (
        <>
            <PageHeader title="Machine Learning" description={[description]} />
            <div>
                {allModels.length ? (
                    <ModelTable
                        data={allModels}
                        onRowClick={onRowClick}
                    />
                ) : (
                    <NoModels />
                )}
            </div>
        </>
    );
};
