import { FieldArray, Field } from 'formik';
import PropTypes from 'prop-types';

import { HTTP_METHODS, REQUEST_FORMATS } from 'Constants';

import { ObjectBuilder, TextArea } from '../../../../../inputs';

import * as Styled from '../../styled';
import { presetOptions } from '../../config';

export const BodyDefinition = ({ values }) => {
    if (values.requestType === HTTP_METHODS.GET) {
        return 'A GET request does not handle data enclosed in a body, use Query Parameters instead.';
    }

    if (values.requestFormat === REQUEST_FORMATS.Custom) {
        return (
            <Styled.FormContent>
                <Styled.FormItem>
                    <Styled.ItemLabel>
                        Custom Body
                    </Styled.ItemLabel>
                    <Field
                        name="customBody"
                        component={TextArea}
                        rows={5}
                    />
                </Styled.FormItem>
                <Styled.TipLabel>
                    You can use these variables:
                </Styled.TipLabel>
                <Styled.TipValues>
                    {presetOptions.join(', ')}
                </Styled.TipValues>
            </Styled.FormContent>
        );
    }

    return (
        <Styled.FormContent>
            <FieldArray
                name="body"
            >
                {(props) => (
                    <ObjectBuilder
                        {...props}
                        presetOptions={presetOptions}
                    />
                )}
            </FieldArray>
        </Styled.FormContent>
    );
};

BodyDefinition.propTypes = {
    values: PropTypes.object.isRequired,
};
