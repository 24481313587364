import PropTypes from 'prop-types';

import { ReactComponent as DropIcon } from 'Assets/widgets/drop.svg';

import { EmptyWidget } from '../emptyWidget';

import * as StyledGeneral from '../styled';
import * as Styled from './styled';

export const HumidityWidget = ({ isEmpty, data, title }) => {
    const isDataBroken = Number.isNaN(+data?.avg);

    if (!data || isEmpty || isDataBroken) {
        return <EmptyWidget title={title} isDataBroken={isDataBroken} />;
    }

    return (
        <Styled.HumidityContainer>
            <Styled.Info>
                <div>
                    <StyledGeneral.ChartTitle>
                        {title}
                    </StyledGeneral.ChartTitle>
                    {data.devicesCount} devices
                </div>
                <div>
                    <Styled.InTotal>
                        {data.avg}
                        <Styled.Unit>
                            %
                        </Styled.Unit>
                    </Styled.InTotal>
                </div>
            </Styled.Info>
            <Styled.HumidityVesselContainer>
                <Styled.HumidityVessel>
                    <Styled.DropIcon
                        value={data.avg}
                    >
                        <DropIcon />
                    </Styled.DropIcon>
                    <Styled.HumidityLevel
                        value={data.avg}
                    />
                </Styled.HumidityVessel>
            </Styled.HumidityVesselContainer>
        </Styled.HumidityContainer>
    );
};

HumidityWidget.propTypes = {
    data: PropTypes.object,
    title: PropTypes.string.isRequired,
    isEmpty: PropTypes.bool,
};

HumidityWidget.defaultProps = {
    data: null,
    isEmpty: false,
};
