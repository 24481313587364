import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const ButtonWrapper = styled.div`
    margin-top: 40px;
`;

export const LinkWrapper = styled.div`
    margin-top: 40px;
    font-size: 16px;
`;

export const LoginLink = styled(Link)`
    margin-left: 5px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.text};

    &:hover {
        color: ${({ theme }) => theme.colors.active};
    }
`;
