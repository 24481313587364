import PropTypes from 'prop-types';
import moment from 'moment';

import { ReactComponent as GoogleIcon } from 'Assets/tiles/google-cloud.svg';

import * as Styled from './styled';

export const DetailsBlock = ({ devicesArr }) => {
    const {
        registryId,
        projectId,
        region,
        topic,
        createdAt,
    } = devicesArr[0] ?? {};

    const creationDate = moment(createdAt).format('D MMM YYYY');

    return (
        <Styled.DetailsWrapper>
            <Styled.FormItem>
                <Styled.ItemLabel>
                    Service
                </Styled.ItemLabel>
                <Styled.IconWrapper>
                    <GoogleIcon />
                    Google Cloud
                </Styled.IconWrapper>
            </Styled.FormItem>
            <Styled.FormItem>
                <Styled.ItemLabel>
                    Registry
                </Styled.ItemLabel>
                {registryId}
            </Styled.FormItem>
            <Styled.FormItem>
                <Styled.ItemLabel>
                    Project
                </Styled.ItemLabel>
                {projectId}
            </Styled.FormItem>
            <Styled.FormItem>
                <Styled.ItemLabel>
                    Region
                </Styled.ItemLabel>
                {region}
            </Styled.FormItem>
            <Styled.FormItem>
                <Styled.ItemLabel>
                    Default telemetry topic
                </Styled.ItemLabel>
                {topic}
            </Styled.FormItem>
            <Styled.FormItem>
                <Styled.ItemLabel>
                    Devices Count
                </Styled.ItemLabel>
                {devicesArr.length}
            </Styled.FormItem>
            <Styled.FormItem>
                <Styled.ItemLabel>
                    Creation Date
                </Styled.ItemLabel>
                {creationDate}
            </Styled.FormItem>
        </Styled.DetailsWrapper>
    );
};

DetailsBlock.propTypes = {
    devicesArr: PropTypes.array.isRequired,
};
