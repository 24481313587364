import styled from 'styled-components';

export const Wrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

export const TableWrapper = styled.div`
    margin-top: 20px;
`;

export const TableControls = styled.div`
    margin-bottom: 10px;
    display: flex;

    & > * {
        margin-right: 15px;
    }

    & > *:last-child {
        margin-right: 0;
    }
`;

export const Network = styled.span`
    font-size: 24px;
    color: ${({ theme }) => theme.colors.title};
    line-height: 0;
`;
