import { generateDeviceNetworkIcon } from 'Utils';

import * as Styled from './styled';

export const deviceColumns = [
    {
        title: 'DeviceName',
        dataIndex: 'description',
    },
    {
        title: 'Type',
        dataIndex: 'deviceType',
        render: (data) => data.toUpperCase(),
    },
    {
        title: 'Project',
        dataIndex: 'application',
        render: (data) => data?.name || 'N/A',
    },
    {
        title: 'FW',
        dataIndex: 'firmware',
        render: (data) => data.version,
    },
    {
        title: 'Last online',
        dataIndex: 'lastConnection',
        width: '150px',
    },
    {
        title: 'Networks',
        dataIndex: 'networks',
        render: (data) =>
            data.map((network) => (
                <Styled.Network key={network}>
                    {generateDeviceNetworkIcon(network)}
                </Styled.Network>
            )),
    },
];
