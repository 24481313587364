import styled from 'styled-components';

export const Wrapper = styled.div`
    padding-top: 30px;
`;

export const Title = styled.div`
    font-size: 22px;
    font-weight: bold;
`;

export const SubTitle = styled.div``;

export const DotWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 25px;
`;

export const Dot = styled.div`
    background: ${({ $status, theme }) => theme.colors[$status] ?? theme.colors.boxBackground};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    width: 30px;
    height: 30px;
    border-radius: 50%;
`;

export const DotLabel = styled.div`
    margin-left: 15px;
`;

export const ButtonsWrapper = styled.div`
    margin-top: 30px;
    display: flex;
    align-items: center;

    button:disabled {
        pointer-events: none;
    }
`;

export const DocumentationLink = styled.a`
    margin-left: 30px;
    color: ${({ theme }) => theme.colors.active};
`;
