import moment from 'moment';

import { getStringifiedPayload } from 'Utils';

export const getColumns = (pin) => ([
    {
        title: 'Pin',
        dataIndex: 'messagePin',
        width: '20%',
    },
    {
        title: 'Message',
        dataIndex: 'payload',
        width: '40%',
        render: (value) => {
            const valueContent = getStringifiedPayload(value);

            return `${valueContent} ${pin.unit}`;
        },
    },
    {
        title: 'Received',
        dataIndex: 'createdAt',
        width: '40%',
        render: (value) => {
            const time = moment(new Date(value));

            return time.format('HH:mm:ss DD.MM.YYYY');
        },
    },
]);
