import * as Yup from 'yup';

import { ALERT_TYPES, BATTERY_OPERATORS, BATTERY_OPERATOR_LABELS } from 'Constants';
import { ValidationService } from 'Services';

export const typeOptions = [
    {
        value: ALERT_TYPES.LAST_SEEN,
        label: 'Last Seen',
    },
    {
        value: ALERT_TYPES.DATA_USAGE,
        label: 'Data Usage',
    },
    {
        value: ALERT_TYPES.BATTERY_LEVEL,
        label: 'Battery Level',
    },
    {
        value: 'F',
        label: 'Firmware Version',
        disabled: true,
    },
    {
        value: 'N',
        label: 'Network',
        disabled: true,
    },
    {
        value: 'L',
        label: 'Location',
        disabled: true,
    },
    {
        value: 'M',
        label: 'Movement Detection',
        disabled: true,
    },
    {
        value: 'C',
        label: 'Custom',
        disabled: true,
    },
];

export const periodMultiplierOptions = [
    {
        value: 1,
        label: 'minute(s)',
    },
    {
        value: 60,
        label: 'hour(s)',
    },
    {
        value: 1440,
        label: 'day(s)',
    },
    {
        value: 43200,
        label: 'month(s)',
    },
];

export const dataUsageMultiplierOptions = [
    {
        value: 1,
        label: 'Byte(s)',
    },
    {
        value: 1024,
        label: 'Kb',
    },
    {
        value: 1048576,
        label: 'Mb',
    },
];

export const batteryOperatorOptions = [
    {
        value: BATTERY_OPERATORS.GREATER_THAN,
        label: BATTERY_OPERATOR_LABELS[BATTERY_OPERATORS.GREATER_THAN],
    },
    {
        value: BATTERY_OPERATORS.LOWER_THAN,
        label: BATTERY_OPERATOR_LABELS[BATTERY_OPERATORS.LOWER_THAN],
    },
    {
        value: BATTERY_OPERATORS.GREATE_THAN_EQUAL,
        label: BATTERY_OPERATOR_LABELS[BATTERY_OPERATORS.GREATE_THAN_EQUAL],
    },
    {
        value: BATTERY_OPERATORS.LOWER_THAN_EQUAL,
        label: BATTERY_OPERATOR_LABELS[BATTERY_OPERATORS.LOWER_THAN_EQUAL],
    },
];

export const lastSeenInitial = {
    period: 30,
    periodMultiplier: periodMultiplierOptions[0].value,
};

export const dataUsageInitial = {
    dataUsage: 1,
    dataUsageMultiplier: dataUsageMultiplierOptions[1].value,
    period: 30,
    periodMultiplier: periodMultiplierOptions[0].value,
};

export const batteryInitial = {
    batteryOperator: batteryOperatorOptions[1].value,
    batteryQuantity: 20,
};

export const initialValues = {
    name: '',
    type: '',
};

export const validationSchema = Yup.object().shape({
    name: Yup.string().required(ValidationService.getRequiredError('name')),
    type: Yup.string().required(ValidationService.getRequiredError('type')),
});
