import { ReactComponent as CarIcon } from 'Assets/icons/car.svg';
import { ReactComponent as CityIcon } from 'Assets/icons/city.svg';
import { ReactComponent as TractorIcon } from 'Assets/icons/tractor.svg';
import { ReactComponent as SickBedIcon } from 'Assets/icons/sickbed.svg';
import { ReactComponent as IndustrialIcon } from 'Assets/icons/industrial.svg';
import { ReactComponent as HealthcareIcon } from 'Assets/icons/healthcare.svg';
import { ReactComponent as PresentationIcon } from 'Assets/icons/presentation.svg';
import { ReactComponent as TruckIcon } from 'Assets/icons/truck.svg';
import { ReactComponent as BuildingIcon } from 'Assets/icons/building.svg';
import { ReactComponent as CartIcon } from 'Assets/icons/cart.svg';
import { PROJECT_CHART_TYPES, TIME_OPTIONS, WEATHER_UNITS, TEMPERATURE_UNITS } from './charts';

export const ADD_PROJECT_TYPES = {
    FAST_IOT: 'fast-iot',
    DEFAULT: 'default',
};

export const VECTORS = {
    FLEET_MANAGEMENT: 'fleet-management',
    SMART_CITIES: 'smart-cities',
    AGRICULTURE: 'agriculture',
    HOSPITALITY: 'hospitality',
    INDUSTRIAL: 'industrial',
    HEALTHCARE: 'healthcare',
    SMART_CAMPUS: 'smart-campus',
    TRANSPORT: 'transport',
    SMART_BUILDING: 'smart-building',
    RETAIL: 'retail',
};

export const VECTOR_LABELS = {
    [VECTORS.FLEET_MANAGEMENT]: {
        label: 'Fleet Management',
        icon: CarIcon,
    },
    [VECTORS.SMART_CITIES]: {
        label: 'Smart Cities',
        icon: CityIcon,
    },
    [VECTORS.AGRICULTURE]: {
        label: 'Agriculture',
        icon: TractorIcon,
    },
    [VECTORS.HOSPITALITY]: {
        label: 'Hospitality',
        icon: SickBedIcon,
    },
    [VECTORS.INDUSTRIAL]: {
        label: 'Industrial',
        icon: IndustrialIcon,
    },
    [VECTORS.HEALTHCARE]: {
        label: 'Healthcare',
        icon: HealthcareIcon,
    },
    [VECTORS.SMART_CAMPUS]: {
        label: 'Smart Campus',
        icon: PresentationIcon,
    },
    [VECTORS.TRANSPORT]: {
        label: 'Transport & Logistics',
        icon: TruckIcon,
    },
    [VECTORS.SMART_BUILDING]: {
        label: 'Smart Building',
        icon: BuildingIcon,
    },
    [VECTORS.RETAIL]: {
        label: 'Retail',
        icon: CartIcon,
    },
};

const VECTOR_PREDEFINED_WIDGETS_CONFIG = {
    [PROJECT_CHART_TYPES.MAP]: {
        name: 'Latest devices location',
        settings: {
            pin: 5,
        },
        width: 8,
        height: 3,
    },
    [PROJECT_CHART_TYPES.MAP_HISTORY]: {
        name: 'Devices path',
        settings: {
            pin: 5,
            timePeriod: TIME_OPTIONS.FIVE_MINUTES,
        },
        width: 12,
        height: 5,
    },
    [PROJECT_CHART_TYPES.TEMPERATURE]: {
        name: 'Temperature',
        settings: {
            pin: 4,
            unit: TEMPERATURE_UNITS.CELSIUS,
        },
        width: 4,
        height: 3,
    },
    [PROJECT_CHART_TYPES.HUMIDITY]: {
        name: 'Humidity',
        settings: {
            pin: 3,
        },
        width: 4,
        height: 3,
    },
    [PROJECT_CHART_TYPES.WEATHER]: {
        name: 'Weather',
        settings: {
            unit: WEATHER_UNITS.CELSIUS,
            position: {
                // Eindhoven location
                lat: '51.43958052547282',
                lng: '5.471917826127922',
            },
        },
        width: 5,
        height: 4,
    },
};

export const VECTOR_PREDEFINED_WIDGETS = {
    [VECTORS.FLEET_MANAGEMENT]: {
        [PROJECT_CHART_TYPES.MAP]: VECTOR_PREDEFINED_WIDGETS_CONFIG[PROJECT_CHART_TYPES.MAP],
        [PROJECT_CHART_TYPES.MAP_HISTORY]: VECTOR_PREDEFINED_WIDGETS_CONFIG[PROJECT_CHART_TYPES.MAP_HISTORY],
    },
    [VECTORS.AGRICULTURE]: {
        [PROJECT_CHART_TYPES.HUMIDITY]: VECTOR_PREDEFINED_WIDGETS_CONFIG[PROJECT_CHART_TYPES.HUMIDITY],
        [PROJECT_CHART_TYPES.TEMPERATURE]: VECTOR_PREDEFINED_WIDGETS_CONFIG[PROJECT_CHART_TYPES.TEMPERATURE],
        [PROJECT_CHART_TYPES.MAP]: VECTOR_PREDEFINED_WIDGETS_CONFIG[PROJECT_CHART_TYPES.MAP],
        [PROJECT_CHART_TYPES.WEATHER]: VECTOR_PREDEFINED_WIDGETS_CONFIG[PROJECT_CHART_TYPES.WEATHER],
    },
    [VECTORS.SMART_CITIES]: {
        [PROJECT_CHART_TYPES.WEATHER]: VECTOR_PREDEFINED_WIDGETS_CONFIG[PROJECT_CHART_TYPES.WEATHER],
        [PROJECT_CHART_TYPES.TEMPERATURE]: VECTOR_PREDEFINED_WIDGETS_CONFIG[PROJECT_CHART_TYPES.TEMPERATURE],
        [PROJECT_CHART_TYPES.HUMIDITY]: VECTOR_PREDEFINED_WIDGETS_CONFIG[PROJECT_CHART_TYPES.HUMIDITY],
        [PROJECT_CHART_TYPES.MAP]: VECTOR_PREDEFINED_WIDGETS_CONFIG[PROJECT_CHART_TYPES.MAP],
    },
    [VECTORS.HOSPITALITY]: {
        [PROJECT_CHART_TYPES.TEMPERATURE]: VECTOR_PREDEFINED_WIDGETS_CONFIG[PROJECT_CHART_TYPES.TEMPERATURE],
        [PROJECT_CHART_TYPES.MAP]: VECTOR_PREDEFINED_WIDGETS_CONFIG[PROJECT_CHART_TYPES.MAP],
    },
    [VECTORS.INDUSTRIAL]: {
        [PROJECT_CHART_TYPES.TEMPERATURE]: VECTOR_PREDEFINED_WIDGETS_CONFIG[PROJECT_CHART_TYPES.TEMPERATURE],
        [PROJECT_CHART_TYPES.MAP]: VECTOR_PREDEFINED_WIDGETS_CONFIG[PROJECT_CHART_TYPES.MAP],
    },
    [VECTORS.HEALTHCARE]: {
        [PROJECT_CHART_TYPES.MAP]: VECTOR_PREDEFINED_WIDGETS_CONFIG[PROJECT_CHART_TYPES.MAP],
    },
    [VECTORS.SMART_CAMPUS]: {
        [PROJECT_CHART_TYPES.TEMPERATURE]: VECTOR_PREDEFINED_WIDGETS_CONFIG[PROJECT_CHART_TYPES.TEMPERATURE],
        [PROJECT_CHART_TYPES.HUMIDITY]: VECTOR_PREDEFINED_WIDGETS_CONFIG[PROJECT_CHART_TYPES.HUMIDITY],
    },
    [VECTORS.TRANSPORT]: {
        [PROJECT_CHART_TYPES.MAP]: VECTOR_PREDEFINED_WIDGETS_CONFIG[PROJECT_CHART_TYPES.MAP],
        [PROJECT_CHART_TYPES.WEATHER]: VECTOR_PREDEFINED_WIDGETS_CONFIG[PROJECT_CHART_TYPES.WEATHER],
    },
    [VECTORS.SMART_BUILDING]: {
        [PROJECT_CHART_TYPES.WEATHER]: VECTOR_PREDEFINED_WIDGETS_CONFIG[PROJECT_CHART_TYPES.WEATHER],
        [PROJECT_CHART_TYPES.TEMPERATURE]: VECTOR_PREDEFINED_WIDGETS_CONFIG[PROJECT_CHART_TYPES.TEMPERATURE],
        [PROJECT_CHART_TYPES.HUMIDITY]: VECTOR_PREDEFINED_WIDGETS_CONFIG[PROJECT_CHART_TYPES.HUMIDITY],
    },
    [VECTORS.RETAIL]: {
        [PROJECT_CHART_TYPES.MAP]: VECTOR_PREDEFINED_WIDGETS_CONFIG[PROJECT_CHART_TYPES.MAP],
        [PROJECT_CHART_TYPES.TEMPERATURE]: VECTOR_PREDEFINED_WIDGETS_CONFIG[PROJECT_CHART_TYPES.TEMPERATURE],
        [PROJECT_CHART_TYPES.HUMIDITY]: VECTOR_PREDEFINED_WIDGETS_CONFIG[PROJECT_CHART_TYPES.HUMIDITY],
    },
};

export const COMMON_RECOMMENDED_WIDGETS = [
    PROJECT_CHART_TYPES.LINE_CHART,
    PROJECT_CHART_TYPES.BAR_CHART,
    PROJECT_CHART_TYPES.TABLE,
    PROJECT_CHART_TYPES.COUNT,
    PROJECT_CHART_TYPES.BATTERY_LEVEL,
    PROJECT_CHART_TYPES.GROUPED_DEVICES,
    PROJECT_CHART_TYPES.SIGNAL_QUALITY,
    PROJECT_CHART_TYPES.ONLINE_OFFLINE,
];

export const VECTOR_RECOMMENDED_WIDGETS = {
    [VECTORS.FLEET_MANAGEMENT]: [
        PROJECT_CHART_TYPES.MAP,
        PROJECT_CHART_TYPES.MAP_HISTORY,
    ],
    [VECTORS.SMART_CITIES]: [
        PROJECT_CHART_TYPES.WEATHER,
        PROJECT_CHART_TYPES.AIR_QUALITY,
        PROJECT_CHART_TYPES.TEMPERATURE,
        PROJECT_CHART_TYPES.HUMIDITY,
        PROJECT_CHART_TYPES.PRESSURE,
    ],
    [VECTORS.AGRICULTURE]: [
        PROJECT_CHART_TYPES.WEATHER,
        PROJECT_CHART_TYPES.HUMIDITY,
        PROJECT_CHART_TYPES.TEMPERATURE,
        PROJECT_CHART_TYPES.PRESSURE,
        PROJECT_CHART_TYPES.MAP,
        PROJECT_CHART_TYPES.MAP_HISTORY,
    ],
    [VECTORS.HOSPITALITY]: [
        PROJECT_CHART_TYPES.HUMIDITY,
        PROJECT_CHART_TYPES.TEMPERATURE,
        PROJECT_CHART_TYPES.AIR_QUALITY,
        PROJECT_CHART_TYPES.MAP,
    ],
    [VECTORS.INDUSTRIAL]: [
        PROJECT_CHART_TYPES.TEMPERATURE,
        PROJECT_CHART_TYPES.HUMIDITY,
        PROJECT_CHART_TYPES.PRESSURE,
        PROJECT_CHART_TYPES.MAP,
    ],
    [VECTORS.HEALTHCARE]: [
        PROJECT_CHART_TYPES.HUMIDITY,
        PROJECT_CHART_TYPES.TEMPERATURE,
        PROJECT_CHART_TYPES.HEART_RATE,
        PROJECT_CHART_TYPES.AIR_QUALITY,
        PROJECT_CHART_TYPES.MAP,
    ],
    [VECTORS.SMART_CAMPUS]: [
        PROJECT_CHART_TYPES.AIR_QUALITY,
        PROJECT_CHART_TYPES.TEMPERATURE,
        PROJECT_CHART_TYPES.HUMIDITY,
        PROJECT_CHART_TYPES.PRESSURE,
    ],
    [VECTORS.TRANSPORT]: [
        PROJECT_CHART_TYPES.MAP,
        PROJECT_CHART_TYPES.MAP_HISTORY,
        PROJECT_CHART_TYPES.ALTITUDE,
        PROJECT_CHART_TYPES.WEATHER,
    ],
    [VECTORS.SMART_BUILDING]: [
        PROJECT_CHART_TYPES.WEATHER,
        PROJECT_CHART_TYPES.TEMPERATURE,
        PROJECT_CHART_TYPES.AIR_QUALITY,
        PROJECT_CHART_TYPES.HUMIDITY,
        PROJECT_CHART_TYPES.ALTITUDE,
    ],
    [VECTORS.RETAIL]: [
        PROJECT_CHART_TYPES.TEMPERATURE,
        PROJECT_CHART_TYPES.AIR_QUALITY,
        PROJECT_CHART_TYPES.HUMIDITY,
        PROJECT_CHART_TYPES.MAP,
    ],
};
