import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'formik';

import { Select } from '../../select';

export const FormContent = ({ lteList, values, handleSubmit, setFieldValue }) => {
    useEffect(() => {
        if (!values.lteId && lteList.length) {
            setFieldValue('lteId', lteList[0]?._id);
        }

        handleSubmit();
    }, [values.lteId]);
    
    return (
        <Form>
            <Field
                name="lteId"
                component={Select}
                type="LTE"
                values={lteList}
            />
        </Form>
    );
};

FormContent.propTypes = {
    lteList: PropTypes.arrayOf(PropTypes.object),
    values: PropTypes.shape({
        lteId: PropTypes.string,
    }).isRequired,
    handleSubmit: PropTypes.func.isRequired,
    setFieldValue: PropTypes.func.isRequired,
};

FormContent.defaultProps = {
    lteList: [],
};
