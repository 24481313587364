import PropTypes from 'prop-types';

import { SIGFOX_CONTRACT_TYPE_DEVKIT } from 'Constants';

import { SigfoxDevkit } from './sigfoxDevkit';
import { SigfoxCustom } from './sigfoxCustom';
import * as Styled from './styled';

export const SigfoxInfo = ({ device }) => {
    let content = null;

    if (device.sigfoxContractType === SIGFOX_CONTRACT_TYPE_DEVKIT) {
        content = <SigfoxDevkit device={device} />;
    } else {
        content = <SigfoxCustom device={device} />;
    }

    return (
        <Styled.Wrapper>
            {content}
        </Styled.Wrapper>
    );
};

SigfoxInfo.propTypes = {
    device: PropTypes.object.isRequired,
};
