import { Switch, useLocation, matchPath } from 'react-router-dom';

import { MainLayout, PymakrLayout, AuthLayout } from 'Layout';
import { LAYOUTS } from 'Constants';

import { RouteWrapper } from 'Components';
import { routesConfig } from './routesConfig';

const dummy = ({ children }) => (children || null);

const useLayout = () => {
    const location = useLocation();
    const routeType = routesConfig.find((route) => matchPath(
        location.pathname,
        { path: route.path, exact: route.exact },
    ));
    let layout = dummy;

    switch (routeType?.layout) {
        case LAYOUTS.PYMAKR:
            layout = PymakrLayout;
            break;
        case LAYOUTS.MAIN:
            layout = MainLayout;
            break;
        case LAYOUTS.AUTH:
            layout = AuthLayout;
            break;
        default:
            break;
    }

    return { layout, isRouteExist: !!routeType };
};

const routeRender = (route) => {
    const Component = route.component;

    return (
        <RouteWrapper
            exact={route.exact}
            path={route.path}
            key={route.path}
            component={Component}
        />
    );
};

export const Routes = () => {
    const { layout: Layout } = useLayout();

    return (
        <Layout>
            <Switch>
                {routesConfig.map(routeRender)}
            </Switch>
        </Layout>
    );
};
