import { useMemo, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';

import { Breadcrumbs, PageHeader, ProjectWidgetDescription } from 'Components';
import {
    BREADCRUMBS_LABELS,
    ROUTES,
    CREATE_APPLICATION_CHART,
    PROJECT_CHARTS_QUERY,
    CREATE_APPLICATION_WIDGET,
    PROJECT_CHART_TYPES,
} from 'Constants';
import { generateWidgetSpace, showToastError, showToastSuccess } from 'Utils';

import { WidgetList, WidgetDevices } from './components';

const createWidgetSteps = {
    WIDGET_LIST: 0,
    WIDGET_DESCRIPTION: 1,
    WIDGET_DEVICES: 2,
};

export const CreateWidget = () => {
    const history = useHistory();
    const [createApplicationWidget] = useMutation(CREATE_APPLICATION_WIDGET);
    const [createChartMutation, { loading }] = useMutation(CREATE_APPLICATION_CHART);
    const { id: applicationId } = useParams();
    const chartsQuery = useQuery(
        PROJECT_CHARTS_QUERY,
        { variables: { applicationId } },
    );
    const dashboard = chartsQuery?.data?.getApplicationCharts
        ?.filter((chartItem) => !!chartItem.widget).map((chartItem) => cloneDeep(chartItem))
        ?? [];

    // Widget configuration
    const [widgetType, setWidgetType] = useState(null);
    const [widgetInfo, setWidgetInfo] = useState({});

    // update the logic in case of more two-steps widgets
    const isTwoSteps = useMemo(() => widgetType === PROJECT_CHART_TYPES.WEATHER, [widgetType]);

    // Steps
    const [step, setStep] = useState(createWidgetSteps.WIDGET_LIST);

    const goList = () => {
        setStep(createWidgetSteps.WIDGET_LIST);
    };

    const goDescription = () => {
        setStep(createWidgetSteps.WIDGET_DESCRIPTION);
    };

    const goDevices = () => {
        setStep(createWidgetSteps.WIDGET_DEVICES);
    };

    // Submit
    const publishWidget = async (values) => {
        try {
            const { name, settings } = values;

            const widgetSpecificData = generateWidgetSpace(dashboard, widgetType);
            const widgetCreated = await createApplicationWidget({
                variables: { widget: { ...widgetSpecificData } },
            });
            const widgetId = widgetCreated?.data.createApplicationWidget._id;
            await createChartMutation({
                variables: {
                    chartInput: {
                        name,
                        settings,
                        applicationId,
                        type: widgetType,
                        widget: widgetId,
                    },
                },
            });
            showToastSuccess('Widget created successfully');
            history.push(`${ROUTES.projects.main}/${applicationId}`);
        } catch (err) {
            showToastError('Something went wrong during widget creation');
        }
    };

    const widgetDescriptionSubmit = (values) => {
        if (isTwoSteps) {
            publishWidget({ ...widgetInfo, ...values });
            return;
        }

        setWidgetInfo({ ...widgetInfo, ...values });
        goDevices();
    };

    const content = useMemo(() => {
        switch (step) {
            case createWidgetSteps.WIDGET_LIST:
                return (
                    <WidgetList
                        nextStep={goDescription}
                        setWidgetType={setWidgetType}
                    />
                );
            case createWidgetSteps.WIDGET_DESCRIPTION:
                return (
                    <ProjectWidgetDescription
                        nextStep={widgetDescriptionSubmit}
                        prevStep={goList}
                        widgetInfo={widgetInfo}
                        widgetType={widgetType}
                        submitText={isTwoSteps ? 'Add Widget' : 'Next'}
                        loading={loading}
                    />
                );
            case createWidgetSteps.WIDGET_DEVICES:
                return (
                    <WidgetDevices
                        prevStep={goDescription}
                        nextStep={publishWidget}
                        widgetInfo={widgetInfo}
                        widgetType={widgetType}
                        projectId={applicationId}
                        loading={loading}
                    />
                );
            default:
                return null;
        }
    }, [step]);

    return (
        <>
            <Breadcrumbs
                labels={BREADCRUMBS_LABELS.createWidget}
            />
            <PageHeader
                title="Create Widget"
                step={step + 1}
                maxStep={isTwoSteps ? 2 : 3}
                stepper
            />
            {content}
        </>
    );
};
