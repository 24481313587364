export const initialDeviceConfig = {
    device: null,
    coModule: null,
    shield: null,
    networksConfig: {
        networks: [],
        wifiId: null,
        lteId: null,
        loraServer: null,
        loraType: null,
        loraRegion: null,
    },
    customData: {},
    pygateLora: null,
};
