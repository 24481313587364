import { useEffect, useMemo, useState, useCallback } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import {
    DEPLOY_CUSTOM_FIRMWARE,
    GET_DEVICES_QUERY,
    pymakrCompilationStatuses,
} from 'Constants';
import { Button, Loader } from 'Components';

import { useContextSelector } from '../../../../pymakrHooks';
import * as Styled from '../styled';

const { REACT_APP_COMPILATION_URL_FOR_AWS } = process.env;

export const UploadToDevice = ({ deviceIds, onClose }) => {
    const [selectedDevices, setSelectedDevices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dataOfUploading, setDataOfUploading] = useState(null);
    const [initialLoading, setInitialLoading] = useState(true);
    const compilationSlice = useContextSelector('compilation');
    const { compilationId } = compilationSlice.state;

    const { data: arrayOfDevices } = useQuery(GET_DEVICES_QUERY);
    const devices = useMemo(
        () => arrayOfDevices?.getDevices?.edges.map((item) => item.node) ?? [],
        [arrayOfDevices],
    );

    useEffect(
        () => {
            if (initialLoading && !loading) {
                setInitialLoading(false);
            }
        },
        [loading],
    );

    const [deployCustomFirmware] = useMutation(DEPLOY_CUSTOM_FIRMWARE);

    const deployCompilationFile = async (deviceToken) => {
        try {
            const { data } = await deployCustomFirmware({
                variables: {
                    vars: {
                        deviceToken,
                        firmwareUrl: `${REACT_APP_COMPILATION_URL_FOR_AWS}/${compilationId}/app.dup`,
                    },
                },
            });
            const result = data?.deployCustomFirmware || false;
            return result
                ? pymakrCompilationStatuses.succeeded
                : pymakrCompilationStatuses.failed;
        } catch (error) {
            console.warn(error);
        }
    };

    const getSelectedDevice = useCallback(() => {
        if (!devices.length) {
            return;
        }
        const foundedDevices = devices.filter((device) => deviceIds.includes(device._id));
        setSelectedDevices(foundedDevices);
    }, [devices]);

    const getStatusOfUploading = useCallback(async () => {
        try {
            const requestsArray = selectedDevices.map((device) => deployCompilationFile(device.token));
            const result = await Promise.all(requestsArray);
            const statusesOfCompilation = result.map((status, index) =>
                ({ status, name: selectedDevices[index].description }));
            setDataOfUploading(statusesOfCompilation);
        } catch (error) {
            console.warn(error);
        }
    }, [selectedDevices]);

    useEffect(() => {
        if (!isEmpty(selectedDevices)) {
            getStatusOfUploading();
        }
    }, [getStatusOfUploading]);

    useEffect(() => {
        if (!isEmpty(devices)) {
            getSelectedDevice();
        }
    }, [getSelectedDevice]);

    useEffect(() => {
        if (!isEmpty(dataOfUploading)) {
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [dataOfUploading]);

    if (initialLoading) {
        return <Loader />;
    }

    return (
        <Styled.Wrapper>
            <Styled.Title>Results:</Styled.Title>
            {dataOfUploading.map((item) => (
                <Styled.Content key={item.name}>
                    <Styled.Content>
                        <div>Device: </div>
                        <Styled.ContentTitle>
                            {item.name}
                        </Styled.ContentTitle>
                    </Styled.Content>
                    <Styled.Content>
                        Updating device initialized
                        <Styled.Status $status={item.status}>
                            {item.status}
                        </Styled.Status>
                    </Styled.Content>
                </Styled.Content>
            ))}
            <Styled.Row>
                <Button onClick={onClose}>Close</Button>
            </Styled.Row>
        </Styled.Wrapper>
    );
};

UploadToDevice.propTypes = {
    deviceIds: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
};
