import gql from 'graphql-tag';

export const NOTIFICATIONS_QUERY = gql`
    query getNotificationsByToken($deviceToken: String!) {
        getNotificationsByDeviceToken(deviceToken: $deviceToken) {
            _id
            notificationSeen
            deviceToken
            createdAt
            ... on LastSeenNotification {
                period
                lastSeenId
                lastSeen
            }
            ... on DataUsageNotification {
                period
                dataUsageId
                amount
            }
            ... on BatteryNotification {
                batteryId
                value
            }
        }
    }
`;

export const NOTIFICATIONS_SUBSCRIPTION = gql`
    subscription getNotifications($deviceToken: String!) {
        getNotifications(deviceToken: $deviceToken) {
            _id
            notificationSeen
            deviceToken
            createdAt
            ... on LastSeenNotification {
                lastSeenId,
                lastSeen
            }
            ... on DataUsageNotification {
                dataUsageId,
                amount
            }
            ... on BatteryNotification {
                batteryId
                value
            }
        }
    }
`;

export const MARK_AS_SEEN = gql`
    mutation switchNotificationSeenStatus($id: String!, $type: String!, $notificationSeen: Boolean) {
        switchNotificationSeenStatus(id: $id, type: $type, notificationSeen: $notificationSeen) {
            _id
            __typename
            notificationSeen
        }
    }
`;

export const MARK_ALL_AS_SEEN = gql`
    mutation markNotificationsAsSeen($list: [NotificationId]!) {
        markNotificationsAsSeen(list: $list) 
    }
`;
