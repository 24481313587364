import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2 {
        margin-top: 50px;
    }

    & > button {
        margin-top: 49px;
    }
`;
