import { useMemo, useContext } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';

import { DEVICE_MESSAGES_PER_RANGE_QUERY } from 'Constants';
import { getStringifiedPayload, removeGraphQlTypenames, formatAMPM } from 'Utils';
import { useMessageSubscription } from 'Hooks';

import { TooltipDeviceMarker } from '../../../../tooltipDeviceMarker';
import { MapHistoryWidget } from '../../../../widgets';

export const MapHistory = ({
    chartId,
    chartSettings,
    updateDeviceChart,
    title,
    device,
}) => {
    const deviceToken = device.token;
    const initialTimeOption = chartSettings.timePeriod;
    const pin = chartSettings.pin;

    const styledTheme = useContext(ThemeContext);
    const color = styledTheme.colors.error;

    const updateChartSettingsTime = (timePeriod) => {
        const filteredSettings = removeGraphQlTypenames(chartSettings);

        updateDeviceChart({
            variables: {
                chartInput: {
                    _id: chartId,
                    settings: {
                        ...filteredSettings,
                        timePeriod: +timePeriod,
                    },
                },
            },
        });
    };

    const { data: messagesData, loading, refetch } = useQuery(
        DEVICE_MESSAGES_PER_RANGE_QUERY,
        {
            variables: {
                chartId,
            },
        },
    );

    useMessageSubscription([deviceToken], pin, refetch);

    const messages = messagesData?.getDeviceMessagesPerRange;
    const parsedMessages = useMemo(
        () => {
            if (messages?.length) {
                return messages?.map((item, index) => ({
                    ...item,
                    value: getStringifiedPayload(item.payload),
                    date: new Date(item.createdAt),
                    device,
                    key: index,
                }));
            }

            return [];
        },
        [messages],
    );
    const renderMetaInfo = (renderData) => <TooltipDeviceMarker {...renderData} />;

    const { locations, isSomeDataBroken } = useMemo(
        () => {
            let isSomeDataBrokenResult = false;
            const resultLocations = parsedMessages
                .reduce((accumulator, message) => {
                    const matched = (/((-)?([0-9.]*))##((-)?([0-9.]*))/).test(message.value);

                    if (matched) {
                        const positions = message.value.split('##');
                        accumulator.push({
                            key: message.key,
                            location: {
                                lat: +positions[0],
                                lng: +positions[1],
                                renderMetaInfo: renderMetaInfo.bind(null, {
                                    title: message.device.description,
                                    time: formatAMPM(message.date),
                                    colorOfTime: color,
                                }),
                            },
                        });
                    } else {
                        isSomeDataBrokenResult = true;
                    }

                    return accumulator;
                }, [])
                .reverse();

            return { locations: resultLocations, isSomeDataBroken: isSomeDataBrokenResult };
        },
        [parsedMessages],
    );

    return (
        <MapHistoryWidget
            data={locations}
            updateTimePeriod={updateChartSettingsTime}
            title={title}
            initialTimeOption={initialTimeOption}
            isEmpty={loading}
            isSomeDataBroken={isSomeDataBroken}
            isDataBroken={!locations.length && isSomeDataBroken}
        />
    );
};

MapHistory.propTypes = {
    chartId: PropTypes.string.isRequired,
    chartSettings: PropTypes.shape({
        timePeriod: PropTypes.number.isRequired,
        pin: PropTypes.number,
        limit: PropTypes.number,
    }).isRequired,
    updateDeviceChart: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    device: PropTypes.shape({
        token: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
    }).isRequired,
};
