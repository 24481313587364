import styled from 'styled-components';

import { Button as ButtonComponent } from '../../../buttons';

export const Wrapper = styled.div`
    padding: 13px 0;
`;

export const Content = styled.div`
    margin-top: 30px;
`;

export const FormContent = styled.div`
    max-width: 750px;
`;

export const FormItem = styled.div`
    display: flex;
    margin-bottom: 10px;

    & > span {
        color: ${({ theme }) => theme.colors.title};
        padding-top: 8px;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.38;
    }

    & > div {
        flex: 1;
    }

    input,
    textarea,
    select {
        width: 100%;
    }
`;

export const ItemLabel = styled.span`
    width: 180px;
    margin-right: 15px;
`;

export const Pre = styled.pre`
    padding: 10px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    max-width: 750px;
`;

export const ButtonsWrapper = styled.div`
    margin-top: 26px;
    display: flex;

    & > *:first-child {
        margin-right: 20px;
    }
`;

export const Button = styled(ButtonComponent)`
    width: 190px;
    max-width: 100%;
`;

export const TipLabel = styled.div`
    margin: 10px 0;
`;

export const TipValues = styled.div`
    display: inline-block;
    padding: 0 5px;
    color: ${({ theme }) => theme.colors.active};
    background-color: ${({ theme }) => theme.colors.boxBackground};
`;
