import styled from 'styled-components';

export const Wrapper = styled.div`
    margin-top: 20px;
`;

export const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;

    margin-bottom: 0.5rem;
`;
