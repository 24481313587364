import { useMemo } from 'react';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useQuery } from '@apollo/client';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';
import moment from 'moment';

import { LASTDAY_DATAUSAGE_QUERY } from 'Constants';
import { formatBytesShort } from 'Utils';
import { useMessageSubscription } from 'Hooks';

import * as Styled from './styled';
import * as StyledGeneral from '../styled';

export const BarChartDataReceived = ({ renderEmptyWidget, label, deviceToken, timePeriod, pin }) => {
    const defaultTimePeriod = 23;
    const timePeriodHours = timePeriod / 3600000;

    const searchingInterval = timePeriodHours || defaultTimePeriod;
    const groupBy = searchingInterval > 1 ? 'HOURS' : 'MINUTES';

    const { data: queryData, loading, error, refetch } = useQuery(LASTDAY_DATAUSAGE_QUERY, {
        variables: {
            deviceToken,
            interval: { hours: searchingInterval, groupBy },
            pin,
        },
    });

    useMessageSubscription([deviceToken], pin, refetch);

    const { getDataUsageByInterval } = queryData || {};
    const { Data, Labels } = getDataUsageByInterval || { Data: [], Labels: [] };

    const barData = useMemo(
        () => Data.map((dataItem, index) => {
            const t = moment(Labels[index], 'ddd MMM DD YYYY HH:mm:ss ZZ');
            const y = dataItem;
            return { t, y };
        }),
        [Data, Labels],
    );
    const hasData = useMemo(
        () => Data.filter((dataItem) => dataItem === 0).length !== Data.length,
        [Data],
    );

    if (loading || error || !hasData) {
        return renderEmptyWidget();
    }

    return (
        <Styled.Chart>
            <StyledGeneral.ChartTitle>
                {label}
            </StyledGeneral.ChartTitle>
            <Bar
                plugins={[ChartDataLabels]}
                data={{
                    datasets: [{
                        label,
                        backgroundColor: 'rgba(5,140,199,0.4)',
                        borderColor: 'rgba(5,140,199,0.4)',
                        borderWidth: 1,
                        hoverBackgroundColor: 'rgba(5,140,199,1)',
                        hoverBorderColor: 'rgba(5,140,199,1)',
                        data: barData,
                    }],
                }}
                options={{
                    plugins: {
                        datalabels: {
                            display: false,
                        },
                    },
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            left: 12,
                            right: 12,
                            top: 16,
                            bottom: 5,
                        },
                    },
                    animation: {
                        duration: 400,
                    },
                    legend: {
                        display: false,
                    },
                    tooltips: {
                        displayColors: false,
                        callbacks: {
                            label: (tooltipItem, chartData) => {
                                const initialLabel = chartData.datasets[tooltipItem.datasetIndex].label || '';
                                const resultLabel = `${initialLabel}${initialLabel ? ': ' : ''}${formatBytesShort(tooltipItem.yLabel)}`;

                                return resultLabel;
                            },
                        },
                    },
                    scales: {
                        yAxes: [{
                            gridLines: {
                                tickMarkLength: 8,
                                drawOnChartArea: true,
                                lineWidth: 1,
                            },
                            ticks: {
                                min: 0,
                                padding: 6,
                                autoSkip: true,
                                maxTicksLimit: 3,
                                maxRotation: 0,
                                callback: (value) => formatBytesShort(value),
                            },
                        }],
                        xAxes: [{
                            type: 'time',
                            distribution: 'linear',
                            gridLines: {
                                tickMarkLength: 4,
                                drawOnChartArea: false,
                                lineWidth: 1,
                            },
                            ticks: {
                                fontSize: 11,
                                padding: 2,
                                autoSkip: true,
                                maxRotation: 0,
                                source: 'auto',
                            },
                            time: {
                                minUnit: 'minute',
                                tooltipFormat: 'D MMM H A',
                            },
                        }],
                    },
                }}
            />
        </Styled.Chart>
    );
};

BarChartDataReceived.defaultProps = {
    pin: null,
    timePeriod: null,
};

BarChartDataReceived.propTypes = {
    label: PropTypes.string.isRequired,
    renderEmptyWidget: PropTypes.func.isRequired,
    deviceToken: PropTypes.string.isRequired,
    timePeriod: PropTypes.number,
    pin: PropTypes.number,
};
