import PropTypes from 'prop-types';
import { useState } from 'react';

import { ReactComponent as Search } from 'Assets/inputs/search.svg';

import * as Styled from './styled';

export const SearchInput = ({ placeholder, onSearch }) => {
    const [searchString, setSearchString] = useState('');

    const onChange = (e) => {
        const {
            target: { value },
        } = e;
        setSearchString(value);
        onSearch(value);
    };

    const onIconClick = () => {
        onSearch(searchString);
    };

    return (
        <Styled.Wrapper>
            <Styled.Field
                type="text"
                value={searchString}
                placeholder={placeholder}
                onChange={onChange}
            />
            <Styled.Icon
                onClick={onIconClick}
                role="presentation"
            >
                <Search />
            </Styled.Icon>
        </Styled.Wrapper>
    );
};

SearchInput.propTypes = {
    placeholder: PropTypes.string,
    onSearch: PropTypes.func,
};

SearchInput.defaultProps = {
    placeholder: '',
    onSearch: () => {},
};
