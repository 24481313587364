export const SOCKET_OPERATIONS = {
    SCAN_PORTS: 'scan_ports',
    INFO: 'infos',
    ERROR: 'error',
    REMOTE_CONFIG: 'remote_config',
    REGION_LIST: 'region_list',
    DOWNLOAD_FIRMWARE: 'download_firmware_locally',
    WRITE_FIRMWARE: 'write_firmware',
};

export const SOCKET_CONNECTION_ERROR = `An Error occurred while trying to connect to the Local service.
    Maybe Firmware Updater Tool is not up running in your system `;
