import styled from 'styled-components';

export const HumidityContainer = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    flex-grow: 1;
`;

export const Info = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;
`;

export const InTotal = styled.div`
    font-size: 60px;
    line-height: 1;
`;

export const DropIcon = styled.div`
    text-align: center;
    font-size: 26px;
    color:  ${({ value }) => (value > 25 ? 'white' : '#00AFDB')};
    bottom: ${({ value }) => (value > 25 ? '10px' : `${value}%`)};
    width: 100%;
    position: absolute;
`;

export const Unit = styled.span`
    &::before {
        content: " ";
    }
    font-size: 26px;
    line-height: 26px;
`;

export const HumidityVesselContainer = styled.div`
    margin-top: 10px;
    margin-right: 55px;
`;

export const HumidityLevel = styled.div`
    height: ${({ value }) => value}%;
    width: 100%;
    background-color: #00AFDB;
    border-radius: 5px;
`;

export const HumidityVessel = styled.div`
    height: 100%;
    width: 90px;
    background-color: #F5F5F5;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
`;
