import { Children } from 'react';
import PropTypes from 'prop-types';

import * as Styled from './styled';

export const Collapsible = ({ children, labels, initialOpenedKeys, forceRenderIndexes }) => {
    const childrenArr = Children.toArray(children);

    const renderHeader = (labelObj) => (
        <div data-cy={labelObj.cyData}>
            <Styled.Title>
                {labelObj.title}
            </Styled.Title>
            {labelObj.description && (
                <Styled.Description>
                    {labelObj.description}
                </Styled.Description>
            )}
        </div>
    );

    return (
        <Styled.Collapsible
            expandIconPosition="right"
            destroyInactivePanel
            defaultActiveKey={initialOpenedKeys.length ? initialOpenedKeys : null}
        >
            {childrenArr.map((child, index) => (
                <Styled.Panel
                    // eslint-disable-next-line react/no-array-index-key
                    key={index + 1}
                    header={renderHeader(labels[index])}
                    forceRender={forceRenderIndexes.find((item) => item === index) !== undefined}
                >
                    {child}
                </Styled.Panel>
            ))}
        </Styled.Collapsible>
    );
};

Collapsible.defaultProps = {
    initialOpenedKeys: [],
    forceRenderIndexes: [],
};

Collapsible.propTypes = {
    children: PropTypes.node.isRequired,
    labels: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.node.isRequired,
        description: PropTypes.string,
        cyData: PropTypes.string,
    })).isRequired,
    initialOpenedKeys: PropTypes.arrayOf(PropTypes.string),
    forceRenderIndexes: PropTypes.arrayOf(PropTypes.number),
};
