import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as Drag } from 'Assets/inputs/drag.svg';

import { Checkbox } from '../inputs';
import * as Styled from './styled';

export const NetworkDraggable = ({
    title,
    id,
    checkedAction,
    checked: outsideChecked,
    disabled,
    cyData,
}) => {
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        setChecked(outsideChecked);
    }, [outsideChecked]);

    const handleChecked = (e) => {
        e.preventDefault();

        if (disabled) {
            return;
        }

        if (e.target.tagName !== 'svg' || e.target.tagName !== 'path') {
            setChecked((prevState) => !prevState);
        }

        checkedAction(id, title);
    };

    return (
        <>
            <Styled.Wrapper>
                <Styled.Input
                    data-cy={cyData}
                    onClick={(e) => handleChecked(e)}
                    role="presentation"
                >
                    <Styled.Border />
                    <Styled.Content>
                        <Checkbox
                            value={title}
                            title={title}
                            checked={checked}
                            handleCheckbox={handleChecked}
                            id={id}
                            disabled={disabled}
                        />
                        <Styled.Drag
                            draggable="true"
                        >
                            <Drag />
                        </Styled.Drag>
                    </Styled.Content>
                </Styled.Input>
            </Styled.Wrapper>
        </>
    );
};

NetworkDraggable.propTypes = {
    title: PropTypes.string.isRequired,
    checkedAction: PropTypes.func,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    cyData: PropTypes.string,
};

NetworkDraggable.defaultProps = {
    checkedAction: () => {},
    id: 0,
    checked: false,
    disabled: false,
    cyData: '',
};
