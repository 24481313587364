import PropTypes from 'prop-types';

import { InfoTooltip } from 'Components';

import * as Styled from '../../../../styled';

export const LabelWithTooltip = ({ label, tooltipText }) => (
    <Styled.TooltipLabelWrapper>
        <Styled.TooltipLabel>
            {label}
        </Styled.TooltipLabel>
        <InfoTooltip
            text={tooltipText}
        />
    </Styled.TooltipLabelWrapper>
);

LabelWithTooltip.propTypes = {
    label: PropTypes.string.isRequired,
    tooltipText: PropTypes.string.isRequired,
};
