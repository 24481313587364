import PropTypes from 'prop-types';

import * as Styled from './styled';

export const Loader = ({ title, overlay, size, customSize }) => (
    <Styled.Wrapper $overlay={overlay}>
        <div>
            <Styled.Title>
                {title}
            </Styled.Title>
            <Styled.Spinner $size={size} $customSize={customSize} />
        </div>
    </Styled.Wrapper>
);

Loader.propTypes = {
    title: PropTypes.string,
    overlay: PropTypes.bool,
    size: PropTypes.number,
    customSize: PropTypes.string,
};

Loader.defaultProps = {
    title: '',
    overlay: false,
    size: 100,
    customSize: '10em',
};
