import {
    setSynchronizing,
    setSynchronizedFiles,
    setFilesCount,
    setDeviceOnline,
} from './actionCreators';

export const getHierarchyHandlers = (dispatch) => ({
    startSynchronization: () => {
        dispatch(setSynchronizing(true));
    },
    setDeviceStatusOnline: () => {
        dispatch(setDeviceOnline(true));
    },
    setDeviceStatusOffline: () => {
        dispatch(setDeviceOnline(false));
    },
    setFilesCount: (filesCount) => {
        dispatch(setFilesCount(filesCount));
    },
    setFilesSynchronized: (synchronizedFiles) => {
        dispatch(setSynchronizedFiles(synchronizedFiles));
    },
    finishSynchronization: () => {
        dispatch(setSynchronizing(false));
        dispatch(setSynchronizedFiles([]));
        dispatch(setFilesCount(0));
    },
});
