import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';

import { Table, Modal, DeleteModal, Alert } from 'Components';
import { GET_ML_SAMPLES_BY_MODEL, DELETE_SAMPLES_BY_ID, SAMPLING_TYPES } from 'Constants';
import { showToastSuccess, showToastError } from 'Utils';

import * as Styled from '../../styled';
import { SamplesChart } from '../samplesChart';

import { getColumns } from './columns';
import { useSampleTest } from './useSampleTest';

export const DataCollection = ({ mlSamples, samples, samplingType, devices, model }) => {
    const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
    const [selectedDeletionSampleId, setSelectedDeletionSampleId] = useState(null);

    const [selectedChartSample, setSelectedChartSample] = useState(null);

    const [deleteSampleMutation, { loading }] = useMutation(
        DELETE_SAMPLES_BY_ID,
        { refetchQueries: [GET_ML_SAMPLES_BY_MODEL] },
    );

    const { testSample } = useSampleTest({ model, mlSamples, samples });

    const closeDeleteModal = () => {
        setIsDeleteModalOpened(false);
        setSelectedDeletionSampleId(null);
    };

    const deleteSample = (id) => {
        setSelectedDeletionSampleId(id);
        setIsDeleteModalOpened(true);
    };

    const deleteHandler = async () => {
        try {
            await deleteSampleMutation({
                variables: {
                    mlSampleId: selectedDeletionSampleId,
                },
                update: () => {
                    showToastSuccess('Sample deleted successfully');
                },
            });
        } catch (err) {
            showToastError(err.message);
        }
    };

    const onRowClick = (data) => {
        setSelectedChartSample(data);
    };

    const columns = useMemo(
        () => getColumns({ samplingType, devices, deleteSample, testSample, samples }),
        [samplingType, devices, samples],
    );

    const samplesChartRender = selectedChartSample
        ? (
            <SamplesChart
                mlSample={selectedChartSample}
                devices={devices}
            />
        ) : (
            <Styled.FormAlertSection>
                <Alert
                    type="warning"
                    message="Select a sample in the Data Collection above to see the graph"
                />
            </Styled.FormAlertSection>
        );

    return (
        <>
            <Table
                data={mlSamples}
                columns={columns}
                onRowClick={onRowClick}
            />
            {samplingType === SAMPLING_TYPES.TRAINING && samplesChartRender}
            <Modal
                isOpened={isDeleteModalOpened}
                handleClose={closeDeleteModal}
            >
                <DeleteModal
                    title="Delete Sample"
                    description="Are you sure you want to delete the sample?"
                    cancelAction={closeDeleteModal}
                    deleteAction={deleteHandler}
                    isLoading={loading}
                />
            </Modal>
        </>
    );
};

DataCollection.propTypes = {
    mlSamples: PropTypes.arrayOf(PropTypes.object).isRequired,
    samples: PropTypes.arrayOf(PropTypes.object).isRequired,
    samplingType: PropTypes.number.isRequired,
    devices: PropTypes.arrayOf(PropTypes.object),
    model: PropTypes.object.isRequired,
};

DataCollection.defaultProps = {
    devices: [],
};
