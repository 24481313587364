import moment from 'moment';
import momentFormater from 'moment-duration-format';

import { ALERT_TYPES, BATTERY_OPERATOR_LABELS } from 'Constants';
import { formatBytes } from 'Utils';

momentFormater(moment);

export const getAlertTooltip = (alert, deviceName) => {
    const periodDuration = moment
        .duration(alert.input.period, 'minutes')
        .format(
            'y [years], M [months], w [weeks], d [days], h [hours], m [minutes]',
            { trim: 'both mid' },
        );

    switch (alert.type) {
        case ALERT_TYPES.LAST_SEEN:
            return `Alerts you when ${deviceName} was
                inactive for longer than ${periodDuration}.`;
        case ALERT_TYPES.DATA_USAGE:
            return `Alerts you when ${deviceName} sent
                more than ${formatBytes(alert.input.dataUsage.quantity)} during the past ${periodDuration}.`;
        case ALERT_TYPES.BATTERY_LEVEL:
            return `Alerts you when the battery level of ${deviceName} is
                ${BATTERY_OPERATOR_LABELS[alert.input.battery.operator]} ${alert.input.battery.quantity}.`;
        default:
            return '';
    }
};
