import { useMemo } from 'react';
import PropTypes from 'prop-types';

import { Select, Alert } from 'Components';

import * as StyledGlobal from '../../../../styled';

import { Charts } from './charts';
import * as Styled from './styled';

export const RawData = ({
    model,
    mlSamples,
    selectedMlSample,
    samples,
    loading,
    setSelectedMlSample,
}) => {
    const selectOptions = useMemo(
        () => mlSamples.map((mlSample, index) => ({
            value: mlSample._id,
            label: `(${index + 1}) - ${mlSample.label}`,
        })),
        [mlSamples],
    );

    const mlSampleOnChange = ({ value }) => {
        const mlSample = mlSamples.find((item) => item._id === value);
        setSelectedMlSample(mlSample);
    };

    return (
        <>
            <Styled.SelectWrapper>
                <Select
                    selectedItem={selectedMlSample._id}
                    values={selectOptions}
                    handleSelect={mlSampleOnChange}
                    custom
                />
            </Styled.SelectWrapper>
            <Charts
                samples={samples}
                selectedMlSample={selectedMlSample}
                windowSize={model.windowsSize}
                windowStep={model.windowStep}
                loading={loading}
            />
            <StyledGlobal.FormAlertSection>
                <Alert
                    type="warning"
                    message="The Windows Size and Windows Step parameters are used for the entire module."
                />
            </StyledGlobal.FormAlertSection>
        </>
    );
};

RawData.propTypes = {
    model: PropTypes.object.isRequired,
    selectedMlSample: PropTypes.object.isRequired,
    setSelectedMlSample: PropTypes.func.isRequired,
    mlSamples: PropTypes.array,
    samples: PropTypes.array,
    loading: PropTypes.bool,
};

RawData.defaultProps = {
    mlSamples: [],
    samples: [],
    loading: false,
};
