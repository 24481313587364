import PropTypes from 'prop-types';

import * as Styled from './styled';

export const FlashAnimation = ({ isLoaded }) => (
    <Styled.Wrapper>
        <Styled.DivImage src="/images/devices/files.png" alt="files" />
        {isLoaded ? <Styled.AnimationDots /> : <Styled.Dots />}
        <Styled.DivImage src="/images/devices/pychip.png" />
    </Styled.Wrapper>
);

FlashAnimation.propTypes = {
    isLoaded: PropTypes.bool.isRequired,
};
