export const ML_RESPONSE_STATUSES = {
    SUCCESS: 'SUCCESS',
    FAILURE: 'FAILURE',
    RETRY: 'RETRY',
    PENDING: 'PENDING',
};

export const ML_PROCESSING_BLOCK_TYPE_LIST = ['Spectral Analysis'];
export const ML_LEARNING_BLOCK_TECHNIQUE_LIST = ['Neural Network'];

export const initialModelConfigurationValues = {
   processingType: ML_PROCESSING_BLOCK_TYPE_LIST[0],
   learningTechnique: ML_LEARNING_BLOCK_TECHNIQUE_LIST[0],
};

export const ML_SENSORS = ['Accelerometer'];
