import { useMemo } from 'react';
import { useSubscription } from '@apollo/client';
import { MESSAGE_BY_DEVICE_TOKENS_SUBSCRIPTION } from 'Constants';

export const useMessageSubscription = (deviceTokens, messagePin, callback) => {
    const creationTime = useMemo(() => new Date(), []);

    const { data } = useSubscription(
        MESSAGE_BY_DEVICE_TOKENS_SUBSCRIPTION,
        {
            variables: { deviceTokens, messagePin, time: creationTime },
            skip: !deviceTokens.length,
            onSubscriptionData: () => callback(),
        },
    );

    return data?.messageByDeviceTokens;
};
