import styled from 'styled-components';

import { ReactComponent as SettingsIcon } from 'Assets/icons/settings.svg';
import { hexToRgb } from 'Utils';

import { Button } from 'Components';

export const Wrapper = styled.div`
    display: grid;
    grid-template-columns: 350px 1fr;
    padding-top: 35px;
    width: 100%;
    gap: 25px;
`;

export const NotificationsWrapper = styled.div`
    .notification-unread {
        font-weight: 900;
    }
`;

export const Title = styled.div`
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.title};
`;

export const EmptyLabel = styled.div`
    color: ${({ theme }) => theme.colors.text};
`;

export const AlertsWrapper = styled.div`
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 15px;
    height: fit-content;
    background-color: #fff;
`;

export const AlertsButton = styled(Button)`
    width: 100%;
    margin-top: 15px;
`;

export const AlertLine = styled.div`
    display: flex;
    align-items: center;
    padding: 5px;
    transition: background-color 0.3s;

    &:hover {
        background-color: rgba(${({ theme }) => hexToRgb(theme.colors.active)}, 0.1);
    }
`;

export const AlertName = styled.div`
    flex: 1;
    display: flex;
    align-items: center;

    svg {
        height: 20px;
        width: auto;
        margin-right: 5px;
    }
`;

export const EditIcon = styled(SettingsIcon)`
    font-size: 14px;
    transition: color 0.3s;
    line-height: 1;
    margin: 0 5px;
    cursor: pointer;

    &:hover {
        color: ${({ theme }) => theme.colors.active}
    }
`;

export const DeleteButton = styled.div`
    font-size: 24px;
    transition: color 0.3s;
    line-height: 1;
    cursor: pointer;
    display: flex;
    align-items: center;
    overflow: hidden;
    transition: color 0.3s;
    ${({ $active, theme }) => $active && `
        color: ${theme.colors.active};
    `}

    &:hover {
        color: ${({ theme }) => theme.colors.active}
    }

    &::after {
        width: 0;
        transition: width 0.3s;
        font-size: 12px;
        white-space: nowrap;
        position: relative;
        content: ' Sure ?';
        ${({ $active }) => $active && `
            width: 40px;
        `}
    }
`;
