import styled from 'styled-components';

import { hexToRgb } from 'Utils';
import { Button as ButtonComponent } from 'Components';
import { ReactComponent as DownloadIcon } from 'Assets/icons/download.svg';
import { ReactComponent as UsbIcon } from 'Assets/icons/usb-filled.svg';

export const Content = styled.div`
    max-width: 580px;
    width: 100%;
    margin-top: 18px;
    padding-left: 22px;
    color: ${({ theme }) => theme.colors.title};
    font-size: 14px;
    line-height: 17px;
`;

export const Header = styled.div`
    margin: 20px 0;
`;

export const Title = styled.div`
    color: rgba(${({ theme }) => hexToRgb(theme.colors.title)}, 0.89);
    font-size: 22px;
    line-height: 27px;
    font-weight: bold;
`;

export const Description = styled.div`
    margin-top: 5px;
`;

export const List = styled.div`
    padding-right: 34px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const ListItem = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const ListNumber = styled.span`
    width: fit-content;
    margin-right: 10px;
`;

export const ListDescription = styled.div`
    display: block;
    & > a {
        color: ${({ theme }) => theme.colors.active};
        font-weight: bold;

        &:hover {
            color: ${({ theme }) => theme.colors.active};
        }
    }
`;

export const Alert = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 7px 12px;
    border-radius: 4px;
    position: relative;
    border: 1px solid ${({ theme }) => theme.colors.warning};
    &:before {
        content: '';
        position: absolute;
        inset: 0;
        z-index: -1;

        background-color: ${({ theme }) => theme.colors.warning};
        opacity: 0.3;
    }
`;

export const Text = styled.div`
    display: flex;
    flex-direction: row;
`;

export const Firmware = styled.p`
    margin: 5px;

    & > a {
        margin-left: 15px;
        color: ${({ theme }) => theme.colors.active};
        font-weight: bold;

        &:hover {
            color: ${({ theme }) => theme.colors.active};
        }
    }
`;

export const Connection = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
`;

export const IconWrapper = styled.div`
    width: 63px;
    height: 63px;
    background-color: ${({ theme }) => theme.colors.boxBackground};
    border-radius: 5px;
    color: ${({ theme }) => theme.colors.title};
    font-size: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ConnectionUsb = styled(UsbIcon)`
    margin: 0 20px;
    font-size: 30px;
    color: ${({ theme }) => theme.colors.title};
`;

export const Button = styled(ButtonComponent)`
    margin-top: 20px;
`;

export const Download = styled(DownloadIcon)`
    width: 24px;
    height: 24px;
`;
