import PropTypes from 'prop-types';

import * as Styled from './styled';

export const TitleBox = ({ title, className }) => (
    <Styled.Box className={className}>
        <div />
        <div>{title}</div>
    </Styled.Box>
);

TitleBox.propTypes = {
    title: PropTypes.string.isRequired,
    className: PropTypes.string,
};

TitleBox.defaultProps = {
    className: '',
};
