import { useState, useMemo, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';

import {
    GET_DEVICES_QUERY,
    GET_PYMESH_BY_APPLICATION_ID,
    PYMESH_SUPPORTED_DEVICE_TYPES,
    ADD_DEVICES_TO_PYMESH,
    AUTHORIZE_PYMESH_DEVICE,
    PYMESH_AUTHORIZATION_STATUSES,
    BR_ENABLED_VALUES,
} from 'Constants';
import { Button, Table, Modal, Spin, getPymeshDeviceSelectionColumns, Loader } from 'Components';
import { showToastSuccess, showToastError, showToastInfo } from 'Utils';

import * as Styled from './styled';

export const AddDeviceToMesh = ({ pymeshDevices, disabled, pymeshID }) => {
    const defaultBrDevicesEnabled = pymeshDevices
        .filter((device) => device.brEnable)
        .map((device) => device.token);

    const [showDevicesSelectionModal, setShowDevicesSelectionModal] = useState(false);
    const [selectedDevicesIds, setSelectedDeviceIds] = useState([]);
    const [filter, setFilter] = useState('');
    const [enabledBrDevices, setEnabledBrDevices] = useState(defaultBrDevicesEnabled);
    const [initialLoading, setInitialLoading] = useState(true);

    const { data, loading } = useQuery(GET_DEVICES_QUERY);
    const devicesData = data?.getDevices || {};

    const { edges, pageInfo } = devicesData;

    useEffect(
        () => {
            if (initialLoading && !loading) {
                setInitialLoading(false);
            }
        },
        [loading],
    );

    const [authorizePymeshDeviceMutation] = useMutation(AUTHORIZE_PYMESH_DEVICE);
    const [addDevicesToPymeshMutation] = useMutation(ADD_DEVICES_TO_PYMESH, {
        refetchQueries: [GET_PYMESH_BY_APPLICATION_ID],
    });

    const onClick = () => setShowDevicesSelectionModal(true);
    const handleDevicesModalClose = () => setShowDevicesSelectionModal(false);

    const getBrValueByToken = (token) => !!enabledBrDevices.find((deviceToken) => deviceToken === token);

    const handleBRChange = (token, changeObject) => {
        const devicesWithEnableBr = [...enabledBrDevices];
        const tokenIndex = devicesWithEnableBr.findIndex((item) => item === token);

        if (changeObject.value === BR_ENABLED_VALUES.ENABLED && tokenIndex < 0) {
            devicesWithEnableBr.push(token);
        } else if (changeObject.value === BR_ENABLED_VALUES.DISABLED && tokenIndex >= 0) {
            devicesWithEnableBr.splice(tokenIndex, 1);
        }

        setEnabledBrDevices(devicesWithEnableBr);
    };

    const onSelect = (ids) => setSelectedDeviceIds(ids);

    const processedDevices = useMemo(
        () => {
            if (edges) {
                return edges.filter((edge) => {
                    const device = edge.node;
                    return device.mac !== null
                        && PYMESH_SUPPORTED_DEVICE_TYPES.includes(device.deviceType)
                        && !pymeshDevices.find((pymeshDevice) => pymeshDevice.token === device.token);
                });
            }

            return [];
        },
        [edges],
    );

    const resultData = useMemo(
        () => processedDevices.map((edge) => {
            const device = { ...edge.node };
            device.brEnable = enabledBrDevices.includes(device.token);
            return device;
        }),
        [processedDevices, enabledBrDevices],
    );

    const filteredData = useMemo(
        () => resultData.filter((device) => device.description.toUpperCase().includes(filter.toUpperCase())),
        [resultData, filter],
    );

    const saveDevicesToPymesh = async () => {
        const selectedDevices = resultData.filter((device) => selectedDevicesIds.includes(device._id));

        const devicesToSave = selectedDevices.map((device) => ({
            token: device.token,
            brEnable: device.brEnable,
        }));

        try {
            await addDevicesToPymeshMutation({
                variables: { devices: devicesToSave, pymeshID },
                update: (() => {
                    showToastSuccess('Devices added successfully');
                    handleDevicesModalClose();
                }),
            });
        } catch (error) {
            console.error(error);
            showToastError('Failed to save devices to Pymesh');
        }

        const authorizeDevicesPromises = selectedDevices.map((device) => authorizePymeshDeviceMutation({
            variables: {
                token: device.token,
                wmac: device.mac.toUpperCase() || undefined,
            },
        }));

        const authorizeDeviceResult = await Promise.allSettled(authorizeDevicesPromises);
        authorizeDeviceResult.forEach((item) => {
            const status = item.value?.data?.authorizePymeshDevice && JSON.parse(item.value?.data?.authorizePymeshDevice) === 200
                ? PYMESH_AUTHORIZATION_STATUSES.AUTHORIZED
                : PYMESH_AUTHORIZATION_STATUSES.FAILED;

            showToastInfo(`authorization status: ${status}`);
        });
    };

    const columns = getPymeshDeviceSelectionColumns({ getBrValueByToken, handleBRChange });

    if (initialLoading) {
        return <Loader />;
    }

    return (
        <Styled.Wrapper>
            <Button
                onClick={onClick}
                disabled={disabled}
                buttonType="transparent"
            >
                <Styled.AddIcon />
                Add device
            </Button>
            <Modal
                handleClose={handleDevicesModalClose}
                isOpened={showDevicesSelectionModal}
            >
                <Styled.ModalWrapper>
                    <Styled.DevicesModalTitle>
                        Choose devices for pymesh
                    </Styled.DevicesModalTitle>
                    <Styled.ModalContentWrapper>
                        <Spin spinning={loading}>
                            <Table
                                searchOptions={{ placeHolder: 'Search for device', onSearch: (key) => setFilter(key) }}
                                columns={columns}
                                data={filteredData}
                                onSelect={onSelect}
                                pagination={pageInfo || {}}
                            />
                        </Spin>
                    </Styled.ModalContentWrapper>
                    <Styled.ModalButtonsSection>
                        <Button disabled={!selectedDevicesIds.length} onClick={saveDevicesToPymesh}>
                            Save
                        </Button>
                        <Button onClick={handleDevicesModalClose} buttonType="transparent">
                            Cancel
                        </Button>
                    </Styled.ModalButtonsSection>
                </Styled.ModalWrapper>
            </Modal>
        </Styled.Wrapper>
    );
};

AddDeviceToMesh.defaultProps = {
    disabled: false,
};

AddDeviceToMesh.propTypes = {
    pymeshDevices: PropTypes.arrayOf(PropTypes.object).isRequired,
    pymeshID: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
};
