import styled from 'styled-components';

import { hexToRgb } from 'Utils';

export const Wrapper = styled.div`
    width: 100%;
    min-height: 56px;
    background-color: rgba(
        ${({ checked, theme }) =>
            (checked
                ? `${hexToRgb(theme.colors.active)}, 0.2`
                : `${hexToRgb(theme.colors.boxBackground)},0.6`)}
    );
    padding: 10px 15px;
    display: flex;
    align-items: center;
    border-left: 3px solid
        ${({ checked, theme }) =>
            (checked ? theme.colors.active : theme.colors.boxBackground)};
    margin-bottom: 8px;

    &:hover {
        background-color: rgba(
            ${({ checked, theme }) =>
                (checked
                    ? 'transparent'
                    : `${hexToRgb(theme.colors.boxBackground)},0.6`)}}
        );
    }

    cursor: pointer;
`;

export const Image = styled.img`
    width: 35px;
    height: 35px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 17px;
`;

export const Content = styled.div`
    width: calc(100% - 35px);
`;

export const Title = styled.a``;

export const Description = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
`;

export const Branch = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;

    & select {
        width: 160px;
        padding: 10px 25px 9px 5px;
        border-radius: 0;
        border: 1px solid
        rgba(${({ theme }) => (hexToRgb(theme.colors.active))}, 0.5);
        background-color: ${({ theme }) => theme.colors.activeText};
    }
`;

export const BranchTitle = styled.div`
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    margin-right: 8px;
`;
